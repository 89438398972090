import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { CommonModule } from '@angular/common';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { ParticipantLeadInfo } from '../../../../shared/interfaces/participant-lead-info';
import { LeadDetailsService } from '../../../../services/lead-details.service';
import { LookupService } from '../../../../services/lookup.service';
import { performValidation } from '../../../../shared/utils/validation.util';
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { UserService } from '../../../../services/user.service';
import { DateTimeFormatterService } from '../../../../shared/utils/date-time-formatter.service';
import { AuthorizeDirective } from '../../../../shared/authorization/authorize.directive.';
import { AuthorizeFieldDirective } from '../../../../shared/authorization/authorizeField.directive';
import { AuthorizeFieldViewDirective } from '../../../../shared/authorization/authorizeFieldView.directive';
import { ENROLLMENT_TYPE_UI_TO_BACKEND_MAPPINGS, RADIO_YES_NO_OPTIONS, STATIC_DATA } from '../../../../shared/constants/static-data.constants';
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import { LookupValueItem } from '../../../../interfaces/lookup.interface';

@Component({
  selector: 'ppl-lead-detail-participant-info',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NzDatePickerModule,
    CommonModule,
    NgZorroModule,
    AuthorizeDirective,
    AuthorizeFieldDirective,
    AuthorizeFieldViewDirective,
    ButtonComponent
],
  templateUrl: './lead-detail-participant-info.component.html',
  styleUrl: './lead-detail-participant-info.component.css',
})
export class LeadDetailParticipantInfoComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  mode = 'read';
  filledInfo!: ParticipantLeadInfo;
  infoForm!: FormGroup;
  contactMethods: any;
  statesList: any;
  genderList: any;
  prefList: any;
  savedData!: ParticipantLeadInfo;
  steps: any;
  approvedStatus: string = STATIC_DATA.APPROVED;
  leadStatus = '';

  waiver: any;
  fiEntries: any;
  mcoEntries: any;
  ldssOfficeValues: LookupValueItem[] = [];
  currentFIValues: LookupValueItem[] = [];
  
  sectionName = '';
  participantRegisterMode: any[] = [];

  @Input() set sectionData(data: any) {
    this.steps = data
  }

  @Input() enrollmentType !: string;

  @Input() entry !: string;
  @Input() pageId = '';
  @Input() tabId = '';
  @Input() programCode !: string;
  @Input() selectedLead = 0;
  radioOptions = RADIO_YES_NO_OPTIONS;
  relationshipOptions: any;
  eyeColors: any;
  hairColors: any;
  raceOptions:any;
  taxExemptOptions:any;


 


  @Output() leadUpdated = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private lookupService: LookupService,
    private leadDetailsService : LeadDetailsService,
    private userService : UserService,
    private dateTimeFormatterService : DateTimeFormatterService
  ) {
    super();
  }
  

  ngOnInit(): void {
    
    this.createForm();
    this.getLookupValues();
    this.leadDetailsService.getLeadStatus().subscribe((status: string) => {
      this.leadStatus = status;
      console.log('Lead Status', status);
    })
    
    if (this.mode === 'read') {
      this.leadDetailsService.getUpdatedLeadData().subscribe(
        (data)=> {
          if(data?.responsedata?.infoData){
            this.filledInfo = data?.responsedata?.infoData;
            this.filledInfo.registerPref = data?.responsedata?.communicationPreferences?.registerPref;
            this.savedData = JSON.parse(JSON.stringify(this.filledInfo));
            // this.savedData.registerPref = data?.responsedata?.communicationPreferences?.registerPref;
          }else{
            this.filledInfo = {};
            this.savedData = null as any;
          }
          
        }
      )
    }
    
    const program = JSON.parse(localStorage.getItem('selected_program')!);

    this.lookupService.getOrgData(program.program_code,).subscribe((data: any)=>{
      if(data.status === 200) {
        this.fiEntries = data.responsedata.filter((item:any) => item.orgTypeLookup === 'FI');
        this.mcoEntries = data.responsedata.filter((item:any) => item.orgTypeLookup === 'MCO');
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    try{

      if(changes['sectionData'] && changes['sectionData'].currentValue){
        this.secData = this.steps['participant_info'];
      }
    }catch(e){
      console.log('Error in getting section data and updating latest info', e);
    }
    
  }

  createForm(): void {
    this.infoForm = this.fb.group({
      businessName: [''],
      fname: [''],
      mname: [''],
      lname: [''],
      maiden_name: [''],
      dob: [null],
      eligiblityStartDate: [null],
      eligiblityEndDate: [null],
      gender: [null],
      participant_id: [''],
      leadId : [],
      state: [''],
      waiver: [null],
      ldssOffice:[],
      currentFI:[],
      mcoOrgName: [null],
      fiOrgName: [null],
      isShowOnDirectory : [],
      isProvidingServices : [],
      relationToParticipant : [],
      additionalDemographics : [],
      race : [],
      heightFeet : [],
      heightInches : [],
      weight : [],
      eyeColor : [],
      hairColor : [],
      birthPlace : [],
      birthCity : [],
      birthState : [],
      birthCountry : [],
      registerPref : [],
      taxExempt : [],
      nprftDocumentationReceived : [],
      yearsInState: []
    });
  }

  getLookupValues(): void {
    this.statesList = this.lookupService.getLookupValue('STATE');
    this.genderList = this.lookupService.getLookupValue('Gender');
    this.prefList = this.lookupService.getLookupValue('Participant_Register_Mode');
    this.waiver = this.getLookup('Waiver');
    this.participantRegisterMode = this.getLookup('Participant_Register_Mode');
    this.relationshipOptions = this.getLookup('Relationship');
    this.eyeColors = this.getLookup('Eye Color');
    this.hairColors = this.getLookup('Hair Color');
    this.raceOptions = this.getLookup('Race');
    this.taxExemptOptions = this.getLookup('Tax Exemption');
    this.ldssOfficeValues = this.getLookup('LDSS_Office');
    this.currentFIValues = this.getLookup('CFI');
  }

  editPersonalInfo() {
    this.mode = 'edit';
    this.infoForm.controls['leadId'].setValue(this.filledInfo.leadId);


    if(this.filledInfo){
      this.infoForm.patchValue(this.filledInfo);
      const updateObj: any = {
        'dob' : this.dateTimeFormatterService.formatStringToDate(this.filledInfo.dob as string),
        'isShowOnDirectory' : this.filledInfo?.isShowOnDirectory === true ? 'Yes' : 'No',
        'isProvidingServices' : this.filledInfo?.isProvidingServices === true ? 'Yes' : 'No',
      };
      if(this.filledInfo?.eligiblityStartDate) {
        updateObj['eligiblityStartDate'] = this.dateTimeFormatterService.formatStringToDate(this.filledInfo.eligiblityStartDate as string);
      }
      if(this.filledInfo?.eligiblityEndDate) {
        updateObj['eligiblityEndDate'] = this.dateTimeFormatterService.formatStringToDate(this.filledInfo.eligiblityEndDate as string);
      }
      this.infoForm.patchValue(updateObj);
    }
  }

  cancelEdit() {
    this.mode = 'read';
    this.validationErrors = {};
    this.infoForm.reset();
  }

  save(){
    let formaData = {...this.infoForm.value};

    formaData.dob = this.dateTimeFormatterService.formatDateToString(this.infoForm.value.dob as Date);
    if (this.infoForm.value?.eligiblityStartDate) {
      formaData.eligiblityStartDate = this.dateTimeFormatterService.formatDateToString(this.infoForm.value.eligiblityStartDate as Date);
    }
    if (this.infoForm.value?.eligiblityEndDate) {
      formaData.eligiblityEndDate = this.dateTimeFormatterService.formatDateToString(this.infoForm.value.eligiblityEndDate as Date);
    }
    try{
      this.validationErrors = performValidation(this.infoForm.value, this.secData, 'Next');
    } catch {
      console.log("Error in validations");
    }
    
    // save data to server if no validation errors
    if(Object.keys(this.validationErrors).length === 0){
      let mcoOrgId = null;
      let fiOrgId = null;
      if(this.infoForm.value.mcoOrgName) {
        mcoOrgId = this.mcoEntries.find((entry : any) => entry.orgName === this.infoForm.value.mcoOrgName).orgId;
      }
      if(this.infoForm.value.fiOrgName) {
        fiOrgId = this.fiEntries.find((entry : any) => entry.orgName === this.infoForm.value.fiOrgName).orgId || null;
      }
      formaData = {
        ...formaData,
        mcoOrgId,
        fiOrgId,
        modifiedDate: this.filledInfo.modifiedDate
      };

      const payload = this.preparePayload(formaData);
      // save data to server 
      this.leadDetailsService.updateLeadDemographics(payload, this.entry).subscribe({
        next: () => {
          this.leadUpdated.emit(true);
          this.cancelEdit();
        },
        error: () => {
          this.leadUpdated.emit(true);
          this.cancelEdit();
        }
      })
    }
  }
  
  preparePayload(source:any) {
  const isShowOnDirectory = source.isShowOnDirectory === 'Yes' ? true : false;
  const isProvidingServices = source.isProvidingServices ===  'Yes' ? true : false;
    let payload: any = {};
    payload = {...source, id: this.selectedLead, programCode : this.programCode, isShowOnDirectory : isShowOnDirectory, isProvidingServices : isProvidingServices};

    if(this.entry === STATIC_DATA.PROVIDER_ENROLLMENT){
      payload = { ...payload ,providerType: ENROLLMENT_TYPE_UI_TO_BACKEND_MAPPINGS[this.enrollmentType]}
    }

    return payload;
  }
}
