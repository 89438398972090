
export const STATIC_DATA = {
    SELECTED_PROGRAM : "selected_program",
    SELECTED_LEAD : "selected_lead",
    LEAD_ENTRY : "lead_entry",
    PARTICIPANT_INFO :"participant_info",
    SERVICE_DETAILS :"service_details",
    COMMUNICATION_PREFERENCES:"comm_preferences",
    CONTACT_DETAILS:"contact_details",
    ASSOCIATIONS:"associations",
    FORMS:"forms",
    SIGNATURES:"signatures",
    HORIZONTAL_FIRST_MENU : "horizontal_first_menu",
    SHARED_PARTICIPANT_LEADS_DATA: "shared_participant_leads_data",
    SHARED_PROVIDER_LEADS_DATA: "shared_provider_leads_data",
    SHARED_PARTICIPANT_ENROLLMENT_DATA: "shared_participant_enrollment_data",
    SHARED_PROVIDER_ENROLLMENT_DATA: "shared_provider_enrollment_data",
    SHARED_ENROLLMENT_DATA: "shared_enrollment_data",
    SHARED_REFERRAL_DATA: "shared_referral_data",
    SHARED_GLOBAL_CONSUMER_SEARCH_DATA: "shared_global_consumer_search_data",
    SHARED_PROCESS_CODE_DATA: "shared_process_code_data",
    SHARED_SERVICE_CODE_DATA: "shared_service_code_data",
    SHARED_PARTICIPANT_PROVIDER_RATES_DATA: "shared_participant_provider_rates_data",
    SHARED_INDIVIDUAL_PROVIDER_DATA: "shared_individual_provider_data",
    SHARED_FILE_TRAGE_DATA: "shared_file_triage_data",
    SHARED_FILE_UPLOAD_NOTIFICATION_DATA: "shared_file_upload_notification_data",
    LEAD_PROGRAM: "Pre_Ref",
    PROSPECTIVE_PARTICIPANTS_DATA: "lead_details_prospective_participants_data",
    LEAD_DETAILS_CONTACT_LIST_HEADERS: "lead_details_contact_list_headers",
    LEAD_PROF_LICENCES :"prof_licences",
    LEAD_SERVICES_RENDERED :"service_rendered",
    APPROVED:"Approved",
    PROVIDER: "provider",
    REFERRAL: "referral",
    PARTICIPANT: "participant",
    PARTICIPANT_ENROLLMENT : "participantEnrollment",
    PROVIDER_ENROLLMENT : "providerEnrollment",
    PARTICIPANT_ENROLLMENT_LIST : "participantEnrollmentList",
    PROVIDER_ENROLLMENT_LIST : "providerEnrollmentList",
    ASSOCIATION_EMPLOYER: "employer",
    ASSOCIATION_VENDOR: "vendor",
    ASSOCIATION_AUTHORIZED_REPRESENTATIVE: "authorized_representative",
    ASSOCIATION_INDEPENDENT_PROVIDER: "independent_provider",
    ASSOCIATION_INDEPENDENT_CONTRACTOR: "independent_contractor",
    ASSOCIATION_LEGAL_GUARDIAN: "legal_guardian",
    ASSOCIATION_POWER_OF_ATTORNEY: "poa",
    DOCUMENT_PARTICIPANT_ENROLLMENT_TYPE : "participantenrlmnt",
    DOCUMENT_PROVIDER_ENROLLMENT_TYPE : "providerenrlmnt",
    IN_PPL_REVIEW: "In PPL Review",
    ENROLLMENT_ACTIVE: "Registration Active",
    URL_FIRST_SEGMENT : "first_segment",
}


export const DETAIL_PAGE_BACKBUTTON = {
  [STATIC_DATA.PROVIDER] : "Back to Personal Assistant Interest",
  [STATIC_DATA.PARTICIPANT] : "Back to Consumer Interest",
  [STATIC_DATA.REFERRAL] : "Back to Referrals",
  [STATIC_DATA.PARTICIPANT_ENROLLMENT] : "Back to Consumer Registration",
  [STATIC_DATA.PROVIDER_ENROLLMENT] : "Back to Personal Assistant Registration"
}

export const ASSOCIATIONS_ADD_BUTTON = {
  [STATIC_DATA.ASSOCIATION_EMPLOYER]: "Add Employer",
  [STATIC_DATA.ASSOCIATION_AUTHORIZED_REPRESENTATIVE] : "Add Designated Representative",
  [STATIC_DATA.ASSOCIATION_INDEPENDENT_PROVIDER] : "Add Personal Assistant",
  [STATIC_DATA.ASSOCIATION_VENDOR] : "Add Vendor",
  [STATIC_DATA.ASSOCIATION_INDEPENDENT_CONTRACTOR] : "Add Independent Contractor",
  [STATIC_DATA.ASSOCIATION_LEGAL_GUARDIAN]: "Add Legal Guardian",
  [STATIC_DATA.ASSOCIATION_POWER_OF_ATTORNEY]: "Add POA"
}

export const PROVIDER_TYPES : Record<string, string>= {
  AR: 'Designated Representative',    
  EMP: 'Employer',    
  IC: 'Independent Contractor',    
  IR: 'Personal Assistant',    
  LG: 'Legal Guardian',    
  POA: 'POA',    
  Vendor: 'Vendor'
}

export const ASSOCIATION_TYPE_UI_MAPPINGS = {
  [STATIC_DATA.ASSOCIATION_EMPLOYER]: "EMP",
  [STATIC_DATA.ASSOCIATION_AUTHORIZED_REPRESENTATIVE] : "AR",
  [STATIC_DATA.ASSOCIATION_INDEPENDENT_PROVIDER] : "IR",
  [STATIC_DATA.ASSOCIATION_VENDOR] : "Vendor",
  [STATIC_DATA.ASSOCIATION_INDEPENDENT_CONTRACTOR] : "IC",
  [STATIC_DATA.ASSOCIATION_LEGAL_GUARDIAN]: "LG",
  [STATIC_DATA.ASSOCIATION_POWER_OF_ATTORNEY]: "POA"
}

export const STATIC_DATA_BASE_PATH = "../../assets/data/programs" 

export const STATIC_JSON_DATA_PATH = {
  EXTERNAL_PARTICIPANT_MENU: `${STATIC_DATA_BASE_PATH}/{code}/lead_details/external/{flow}/horizontal_menu.json`,
  LEAD_DETAILS_MENU: `${STATIC_DATA_BASE_PATH}/{code}/lead_details/{flow}/horizontal_menu.json`,
  LEAD_LIST_PAGE_HEADERS: `${STATIC_DATA_BASE_PATH}/{code}/lead_details/{flow}/contact-list-headers.json`,
  LEAD_DETAILS_SECTION_DATA: `${STATIC_DATA_BASE_PATH}/{code}/lead_details/{flow}/sections.json`,
  LEAD_DETAILS_PROSPECTIVE_PARTICIPANTS_DATA: `${STATIC_DATA_BASE_PATH}/{code}/lead_details/{flow}/prospective-participants.json`,
  REFERRAL_FORM: `${STATIC_DATA_BASE_PATH}/{code}/sections.json`,
  LEAD_DETAILS_PROVIDER_ENROLLMENT_SECTION_DATA: `${STATIC_DATA_BASE_PATH}/{code}/lead_details/{flow}/basicInfo/{comp}_sections.json`,
  LEAD_DETAILS_CONTACT_LIST_HEADERS: `${STATIC_DATA_BASE_PATH}/{code}/lead_details/{flow}/contact-list-headers.json`,
  LEAD_DETAILS_VIEWER_HEADERS: `${STATIC_DATA_BASE_PATH}/{code}/lead_details/{flow}/participant_viewer.json`,
  LEAD_DETAILS_ENROLLMENT_HEADERS: `${STATIC_DATA_BASE_PATH}/{code}/lead_details/{flow}/enrollment_status.json`,
  LEAD_DETAILS_DOCUMENT_LIST_HEADERS: `${STATIC_DATA_BASE_PATH}/{code}/lead_details/{flow}/document-file-headers.json`,
  FILE_UPLOAD_HEADERS: `${STATIC_DATA_BASE_PATH}/{code}/list_pages/uploaded_list_headers.json`,
  PARTICIPANT_ASSOCIATIONS_HEADERS: `${STATIC_DATA_BASE_PATH}/{code}/list_pages/list_participant_associations.json`,
  LEAD_DETAILS_PROVIDERS_SECTION_DATA: `${STATIC_DATA_BASE_PATH}/{code}/lead_details/participantEnrollment/associations/{comp}.json`,
  ADDITIONAL_ATTRIBUTES: `${STATIC_DATA_BASE_PATH}/{code}/lead_details/participantEnrollment/additional-attributes.json`,
  ASSOCIATIONS_DETAILS_HEADERS: `${STATIC_DATA_BASE_PATH}/{code}/lead_details/participantEnrollment/associations_details_headers.json`,
  ALL_ASSOCIATIONS_DETAILS_HEADERS: `${STATIC_DATA_BASE_PATH}/{code}/lead_details/participantEnrollment/all_associations_details_headers.json`,
  ENROLLMENT_SUMMARY_ITEMS:  `${STATIC_DATA_BASE_PATH}/{code}/lead_details/{flow}/summary-items.json`,
  PROVIDER_ASSOCIATIONS_HEADERS: `${STATIC_DATA_BASE_PATH}/{code}/lead_details/providerEnrollment/associations_details_headers.json`,
  PROVIDER_ASSOCIATIONS_STATUS_HEADERS: `${STATIC_DATA_BASE_PATH}/{code}/lead_details/providerEnrollment/enrollment_status.json`,
  AUTHORIZATIONS_CONSUMER_LIST_HEADERS: `${STATIC_DATA_BASE_PATH}/{code}/forms/payroll/{flow}/consumer-list.json`,
  AUTHORIZATIONS_LIST_HEADERS: `${STATIC_DATA_BASE_PATH}/{code}/forms/payroll/{flow}/authorizations-list-headers.json`,
  AUTHORIZATIONS_PARTICIPANT_DETAILS_LIST_HEADERS: `${STATIC_DATA_BASE_PATH}/{code}/lead_details/{flow}/authorizations-list-headers.json`,
  SERVICE_CODE_RATES_LIST_HEADERS: `${STATIC_DATA_BASE_PATH}/{code}/forms/payroll/{flow}/rate-list.json`,
  AUTHORIZATIONS_SECTION_DATA: `${STATIC_DATA_BASE_PATH}/{code}/forms/payroll/{flow}/form.json`,
  CREATE_CONSUMER_ASSOCIATION: `${STATIC_DATA_BASE_PATH}/{code}/forms/participantEnrollment/associations/{flow}.json`
};


export const APP_REGEX = {
  PREFERRED_TIME : /(\d{1,2}:\d{2} (?:AM|PM)) - (\d{1,2}:\d{2} (?:AM|PM))/,
  PREFERRED_DAYS : /\[(.*?)\]/
}

export const RADIO_YES_NO_OPTIONS = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' }
];

export const FLOW_MAPPINGS: Record<string, string> = {
  'participants': 'Consumer Interest',
  'providers': 'Personal Assistant Interest',
  'referral': 'Consumer Referral',
  'newEnrollment': 'Registration',
  'participantEnrollment': 'Consumer Registration',
  'provider': 'Personal Assistant',
  'authRepresentative': 'Designated Representative',
  'employer': 'Employer',
  'vendor': 'Vendor',
  'contractor': 'Independent Contractor',
  'poa': 'POA',
  "legalGuardian": 'Legal Guardian'
};

export const ENROLLMENT_TYPE_MAPPINGS: Record<string, string> = {
  'newProvider': 'provider',
  'newAuthRep': 'authRepresentative',
  'newEmployer': 'employer',
  'newVendor': 'vendor',
  'newContractor': 'contractor',
  'newPOA': 'poa',
  'newLegalGuardian': 'legalGuardian'
};

export const ENROLLMENT_TYPE_BACKEND_MAPPINGS: Record<string, string> = {
  'IR': 'provider',
  'AR': 'authRepresentative',
  'EMP': 'employer',
  'Vendor': 'vendor',
  'IC': 'contractor',
  'LG': 'legalGuardian',
  'POA': 'poa'
};

export const ENROLLMENT_TYPE_UI_TO_BACKEND_MAPPINGS: Record<string, string> = {
  'provider': 'IR',
  'authRepresentative': 'AR',
  'employer': 'EMP',
  'vendor': 'Vendor',
  'contractor': 'IC',
  'legalGuardian': 'LG',
  'poa': 'POA',
  'legalguardian': 'LG',
  'authrep': 'AR',
};


export const ENROLLMENT_TITLE_MAPPINGS: Record<string, string> = {
  'provider': 'Personal Assistant',
  'authRepresentative': 'Designated Representative',
  'employer': 'Employer',
  'vendor': 'Vendor',
  'contractor': 'Independent Contractor',
  'poa': 'POA',
  "legalGuardian": 'Legal Guardian'
};

export const DAYS_OF_WEEK = ['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'];

export const PARTICIPANT_LIMITED_ACCESS_PAGES = ['enrollmentInformation'] 
