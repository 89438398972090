<div [ngClass]="{'dark-background': isDarkTheme, 'light-background': !isDarkTheme, 'dark-theme': isDarkTheme}"
    class="card-container">
    <div class="card-action" (click)="selectProgram()">
       
        <div class="head">
            <div class="title" >
                {{ programData.program_name}}
            </div>

            <button class="light-switch" (click)="toggleBackground($event, programData.program_name); $event.stopPropagation()">
                <img class="light" src="/assets/images/sunny_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg" height="24px" alt="Toggle Background">
              </button>
             
        </div>
        <div class="action" (click)="selectProgram()">
            <div class="select-text">
                Click to select
            </div>
            <div class="select-arrow">
                <img class="arrow" src="/assets/images/arrow_forward_ios_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg"
                    height="24px" alt="Toggle Background">
            </div>
        </div>
    </div>

</div>