import { Component } from '@angular/core';
import { STATIC_DATA } from '../../../shared/constants/static-data.constants';
import { SharedDataService } from '../../../shared/services/shared-data-service';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'ppl-referrals',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './referrals.component.html',
  styleUrl: './referrals.component.css'
})
export class ReferralsComponent {
  constructor(private sharedDataService: SharedDataService) {
    this.sharedDataService = sharedDataService;
  }

  clearData() {
    this.sharedDataService.clearData(STATIC_DATA.SHARED_REFERRAL_DATA);
  }
}
