import { Injectable } from "@angular/core";
import { PageConfig, PageSectionConfig } from "./auth.config";
import { AppStateService } from "../../services/app-state.service";

@Injectable({
    providedIn: 'root'
})   
export class AuthorizationUtility {

    constructor(
        private appStateService: AppStateService
    ) {}

    public getPageConfig(pageId: string): PageConfig | undefined {
        const userDetails = this.appStateService.getUserDetails();
        if (userDetails) {
            return userDetails.pageAuthorization && userDetails.pageAuthorization[pageId];
        }

        return;
    }

    public isTabVisible(pageId: string, tabTitle: string): boolean {
        const pageConfig = this.getPageConfig(pageId);
        if (!pageConfig || !pageConfig.restrictedTabs) return true;
        const tabConfig = pageConfig.restrictedTabs.find(tab => tab.toLowerCase() === tabTitle.toLowerCase());
        return tabConfig ? false : true;
    }

    public isButtonVisible(pageId: string, buttonLabel: string): boolean {
        const pageConfig = this.getPageConfig(pageId);
        if (!pageConfig || !pageConfig.restrictedButtons) return true;
        const buttonConfig = pageConfig.restrictedButtons.find(button => button.toLowerCase() === buttonLabel.toLowerCase());
        return buttonConfig ? false : true;
    }

    public isFieldReadable(pageId: string, tabId: string, sectionName: string, fieldLabel: string): boolean {
        const sectionConfig = this.getSectionConfig(pageId, tabId, sectionName);
        if (!sectionConfig || !sectionConfig.notViewableByRole) return true;
        const fieldConfig = sectionConfig.notViewableByRole.find(field => field.toLowerCase() === fieldLabel.toLowerCase());
        return fieldConfig ? false : true;
    }

    public isFieldEditable(pageId: string, tabId: string, sectionName: string, fieldLabel: string): boolean {
        const sectionConfig = this.getSectionConfig(pageId, tabId, sectionName);
        if (!sectionConfig || !sectionConfig.notEditableByRole) return true;
        const fieldConfig = sectionConfig.notEditableByRole.find(field => field.toLowerCase() === fieldLabel.toLowerCase());
        return fieldConfig ? false : true;
    }

    public isFieldDeletable(pageId: string, tabId: string, sectionName: string, fieldLabel: string): boolean {
        const sectionConfig = this.getSectionConfig(pageId, tabId, sectionName);
        if (!sectionConfig || !sectionConfig.notDeleteableByRole) return true;
        const fieldConfig = sectionConfig.notDeleteableByRole.find(field => field.toLowerCase() === fieldLabel.toLowerCase());
        return fieldConfig ? false : true;
    }

    public isSectionVisible(pageId: string, tabId: string, sectionName: string): boolean {
        const sectionConfig = this.getSectionConfig(pageId, tabId, sectionName);
        return sectionConfig && sectionConfig.canView != null ? sectionConfig.canView : true;
    }

    public isSectionEditable(pageId: string, tabId: string, sectionName: string): boolean {
        const sectionConfig = this.getSectionConfig(pageId, tabId, sectionName);
        return sectionConfig && sectionConfig.canEdit != null ? sectionConfig.canEdit : true;
    }

    public isSectionDeletable(pageId: string, tabId: string, sectionName: string): boolean {
        const sectionConfig = this.getSectionConfig(pageId, tabId, sectionName);
        return sectionConfig && sectionConfig.canDelete  != null? sectionConfig.canDelete : true;
    }

    public getHiddenColumns(config: PageConfig, pageId: string): string[] {
        const pageConfig = config ? config : this.getPageConfig(pageId);
        if (!pageConfig || !pageConfig.restrictedColumns) return [];
        return pageConfig.restrictedColumns;
    }

    public getHiddenButtons(config: PageConfig, pageId: string): string[] {
        const pageConfig = config ? config : this.getPageConfig(pageId);
        if (!pageConfig || !pageConfig.restrictedButtons) return [];
        return pageConfig.restrictedButtons;
    }

    public getHiddenActions(config: PageConfig, pageId: string): string[] {
        const pageConfig = config ? config : this.getPageConfig(pageId);
        if (!pageConfig || !pageConfig.restrictedActions) return [];
        return pageConfig.restrictedActions;
    }

    public getHiddenTabs(config: PageConfig, pageId: string): string[] {
        const pageConfig = config ? config : this.getPageConfig(pageId);
        if (!pageConfig || !pageConfig.restrictedTabs) return [];
        return pageConfig.restrictedTabs;
    }

    private getSectionConfig(pageId: string, tabId: string, sectionName: string): PageSectionConfig | undefined {
        const pageConfig = this.getPageConfig(pageId);
        if (!pageConfig || !pageConfig.tabs) return undefined;
    
        // Find the tab by tabId
        const tab = pageConfig.tabs.find(t => t.id === tabId);
        if (!tab || !tab.sections) return undefined;
    
        // Find the section within the found tab
        const sectionConfig = tab.sections.find(section => section.id.toLowerCase() === sectionName.toLowerCase());
        return sectionConfig;
    }
}