import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AccordionModel } from '../../interfaces/accordion.model';

@Component({
  selector: 'ppl-accordion',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './accordion.component.html',
  styleUrl: './accordion.component.css'
})
export class AccordionComponent {

  accordion : AccordionModel = {
    isOpen: false,
    title : 'Accordion',
    additionalText : ''
  }

  @Input() set isOpen(data : boolean){
    this.accordion.isOpen = data
  };

  @Input() set title(data : string){
    this.accordion.title = data
  }

  @Input() set additionalText(data : string){
    this.accordion.additionalText = data
  }
  

  toggleAccordion(accordion: AccordionModel, event: MouseEvent) {
    event.stopPropagation();
    this.accordion.isOpen = !accordion.isOpen
  }
}
