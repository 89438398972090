{
    "columns": [
      {
        "header_id": "timesheetNumber",
        "header_label": "Timesheet #",
        "jsonpath": "timesheetNumber",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "providerId",
        "header_label": "Provider ID",
        "jsonpath": "providerId",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "date",
        "header_label": "Date",
        "jsonpath": "date",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "authAmount",
        "header_label": "Auth Amount",
        "jsonpath": "authAmount",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      }
    ],
    "buttons": [],
    "apiDetails": "authTimesheetsList.json",
    "pageName": "addAuthorizationsList",
    "orderByColumn": ["timesheetNumber"],
    "orderBy": ["desc"]
  }
  