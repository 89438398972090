import { CommonModule } from "@angular/common";
import { ParticipantInformationComponent } from "../../../leads/participant-information/participant-information.component";
import { Router, RouterModule } from "@angular/router";
import { NgZorroModule } from "../../../../shared/modules/ng-zorro/ng-zorro.module";
import { LeadCommentsComponent } from "../../../lead-details/lead-comments/lead-comments.component";
import { LeadDetailsHeaderComponent } from "../../../lead-details/lead-details-header/lead-details-header.component";
import { ActionsComponent } from "../../../../shared/components/actions/actions.component";
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import { DropdownButtonComponent } from "../../../../shared/components/dropdown-button/dropdown-button.component";
import { NotificationSMSComponent } from "../../../../shared/modals/notification-sms/notification-sms.component";
import { DrawerComponent } from "../../../../shared/components/drawer/drawer.component";
import { TimelineComponent } from "../../../../shared/components/timeline/timeline.component";
import { SideDrawerComponent } from "../../../../shared/components/side-drawer/side-drawer.component";
import { AuthorizeDirective } from "../../../../shared/authorization/authorize.directive.";
import { CommunicationLogsComponent } from "../../../lead-details/communication-logs/communication-logs.component";
import { ListService } from "../../../../shared/services/list.service";
import { BaseComponent } from "../../../../shared/utils/base.component";
import { LeadDetailsService } from "../../../../services/lead-details.service";
import { SectionsService } from "../../../../services/sections.service";
import { LookupService } from "../../../../services/lookup.service";
import { LoggerService } from "../../../../shared/services/logger.service";
import { AuthorizationUtility } from "../../../../shared/authorization/auth.utility";
import { AuthorizationService } from "../../../../shared/authorization/authorization.service";
import { environment } from "../../../../../environments/environment";
import { PARTICIPANT_LIMITED_ACCESS_PAGES, STATIC_DATA, STATIC_JSON_DATA_PATH } from "../../../../shared/constants/static-data.constants";
import { MEMBER_VIEW_PAGE_IDS } from "../../../../shared/constants/page.constants";
import { MainMenuItem } from "../../../../interfaces/menu.interface";
import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";


@Component({
  selector: 'ppl-authorization-member-view',
  standalone: true,
  imports: [
    CommonModule,
    ParticipantInformationComponent,
    RouterModule,
    NgZorroModule,
    LeadCommentsComponent,
    LeadDetailsHeaderComponent,
    ActionsComponent,
    ButtonComponent,
    DropdownButtonComponent,
    NotificationSMSComponent,
    DrawerComponent,
    TimelineComponent,
    SideDrawerComponent,
    AuthorizeDirective,
    CommunicationLogsComponent
],
  providers: [ListService],
  templateUrl: './authorization-member-view.component.html',
  styleUrl: './authorization-member-view.component.css',
})
export class AuthorizationMemberViewComponent extends BaseComponent implements OnInit, OnDestroy {
  horizontalMenuItemstoDisplay: any;
  selectedMenuItem: any;
  enableComments = false;
  changedCLass = '';
  filledInfo: any;
  id: any;
  applicationData: any[] = [];
  listButton: any[] = [];

  applicationStatusList: any;
  entry: any;
  sectionDataPath: string = STATIC_JSON_DATA_PATH.LEAD_DETAILS_SECTION_DATA;
  program: any;
  pageId = '';
  tabId = '';
  tabsToBeHidden: string[] = [];
  buttonsToBeHidden: string[] = [];
  actionToBeHidden: string[] = [];
  steps: any;
  showSMSModal: any = '';
  notificationType = '';
  mobileNumber: string[] = [];
  emailId: string[] = [];
  isPageRestrictionLoaded = false;
  auditHistory: any;
  backLabel ="Back";
  leadData: any;
  isCommunicationLogs  = false;
  detailPageBaseUrl = '';
  isExternalEnvironment  = false;
  limitedAccess = false;
  authData: any;
  selectedLead: any;

  constructor(
    private leadDetailsService: LeadDetailsService,
    private router: Router,
    private sectionsService: SectionsService,
    private lookupService: LookupService,
    private loggerService : LoggerService,
    private authUtility: AuthorizationUtility,
    private listService: ListService,
    private authService: AuthorizationService,

  ) {
    super();
  }
  ngOnDestroy(): void {
    this.sectionsService.setEnrollmentType('');
    localStorage.removeItem('limitedAccess');
    
  }

  ngOnInit(): void {

    this.isExternalEnvironment  = environment.isExternal;

    this.limitedAccess = localStorage.getItem('limitedAccess') as unknown == true ? true : false;

    this.detailPageBaseUrl = this.router.url;

    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    this.entry = localStorage.getItem('lead_entry')!;

    this.id = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!);


    this.pageId = MEMBER_VIEW_PAGE_IDS[localStorage.getItem(STATIC_DATA.URL_FIRST_SEGMENT)!];
    this.setPageAccess();

    /* this.backLabel = DETAIL_PAGE_BACKBUTTON[this.entry]; */
    /* if(this.entry == STATIC_DATA.REFERRAL){
      this.backLabel = "Back to Referrals";
    }
    else if(this.entry == 'participant'){
      this.backLabel = "Back to Participant Lead";
    }
    else if(this.entry == 'participantEnrollment'){
      this.backLabel = "Back to Participant Enrollment";
    }
    else{
      this.backLabel = "Back to Provider Lead";
    } */
    this.leadDetailsService.getUpdatedLeadData().subscribe((data) => {
      this.leadData = data;
      this.filledInfo =
          data?.responsedata?.infoData;
      
    })
  }

  private loadData() {
    try {
      if (this.program.program_code) {
        this.getMenuItems();
      }

      this.leadDetailsService.getUpdatedLeadData().subscribe((data) => {
        const communicationData = data?.responsedata?.communicationData;
        if (communicationData?.contactMethods) {
          this.mobileNumber = communicationData.contactMethods
            .filter((contact: any) => contact.contactMethod === 'mobile')
            .map((contact: any) => contact.value);
          this.emailId = communicationData.contactMethods
            .filter((contact: any) => contact.contactMethod === 'email')
            .map((contact: any) => contact.value);
        }
        this.filledInfo =
          data?.responsedata?.infoData;
        /* this.id = (this.entry == STATIC_DATA.REFERRAL) ? this.filledInfo?.referralId : this.filledInfo?.leadId; */

        if (this.filledInfo) {
          this.applicationData = [
            {
              application_status_lkp: this.filledInfo?.applicationStatus,
              leadId: this.id,
              mobile_number: this.mobileNumber || [],
              email_id: this.emailId || [],
            },
          ];
          this.listService.setApplicationData(this.applicationData);
            if (this.filledInfo?.applicationStatus?.toLowerCase() == 'approved' || this.filledInfo?.applicationStatus?.toLowerCase() == 'enrollment complete') {
              this.listButton = [  {
                button_id:"send_sms",
                button_label:"Send SMS",
                buttonAction : "sendSMS",
                className: "primary-btn-color"
              },
              {
                button_id:"send_email",
                button_label:"Send Email",
                buttonAction : "sendEmail",
                className: "primary-btn-color"
              }];
            } else if (
              this.filledInfo?.applicationStatus?.toLowerCase() == 'denied' || this.filledInfo?.applicationStatus?.toLowerCase() == 'disenrolled'
            ) {
              this.listButton = [
                {
                  button_id: 'Resurrect',
                  button_label: 'Resurrect',
                  buttonAction: 'Resurrect',
                  className: 'primary-btn-color',
                },
                {
                  button_id:"send_sms",
                  button_label:"Send SMS",
                  buttonAction : "sendSMS",
                  className: "primary-btn-color"
                },
                {
                  button_id:"send_email",
                  button_label:"Send Email",
                  buttonAction : "sendEmail",
                  className: "primary-btn-color"
                }
              ];
          } else {
            this.listButton = [
              {
                button_id: 'assign_to_user',
                button_label: 'Assign to User',
                buttonAction: 'assign_to_user',
                className: 'primary-btn-color',
              },
              {
                button_id: 'assign_to_me',
                button_label: 'Assign to Me',
                buttonAction: 'assign_to_me',
                className: 'primary-btn-color',
              },
              {
                button_id: 'Approve',
                button_label: 'Approve',
                buttonAction: 'approve',
                className: 'primary-btn-color',
              },
              {
                button_id: 'Deny',
                button_label: 'Deny',
                buttonAction: 'Deny',
                className: 'primary-btn-color',
              },
              {
                button_id:"send_sms",
                button_label:"Send SMS",
                buttonAction : "sendSMS",
                className: "primary-btn-color"
              },
              {
                button_id:"send_email",
                button_label:"Send Email",
                buttonAction : "sendEmail",
                className: "primary-btn-color"
              }
              
            ];
          }

          if(this.entry === STATIC_DATA.PARTICIPANT_ENROLLMENT){
            this.listButton = this.listButton.filter(button => button.button_id !== 'Approve' && button.button_id !== 'Deny' && button.button_id !== 'Resurrect');
            this.listButton.push(
              {
                button_id:"send_registration",
                button_label:"Resend Registration Link",
                buttonAction : "send_registration",
                className: "primary-btn-color"
              },
              // {
              //   button_id:"update_status",
              //   button_label:"Update Status",
              //   buttonAction : "update_status",
              //   className: "primary-btn-color"
              // }
            );
          }else if(this.entry ===  STATIC_DATA.PROVIDER_ENROLLMENT){
            this.listButton.push(
              {
                button_id:"send_registration",
                button_label:"Resend Registration Link",
                buttonAction : "send_registration",
                className: "primary-btn-color"
              });
          }
         this.listButton = [...this.listButton];
         this.listButton = this.getAuthorizedActions(this.listButton);
        }
      })
    } catch (e) {
      this.loggerService.info('Error in loading data', e);
    }

    this.getAuthDetails()
  }

  private setPageAccess() {
    const pageConfig = this.authUtility.getPageConfig(this.pageId);
    if(!pageConfig || !pageConfig.pageName) {
      this.authService.fetchPageAuthorization(this.program.program_code, this.pageId, "DETAIL").subscribe(response => {
        this.setRestrictions(response);
      })
    } else {
      this.setRestrictions(pageConfig);
    }
  }

  private setRestrictions(pageConfig: any) {
    if (pageConfig) {
      this.tabsToBeHidden = this.authUtility.getHiddenTabs(pageConfig, this.pageId);
      this.actionToBeHidden = this.authUtility.getHiddenActions(pageConfig, this.pageId);
      this.buttonsToBeHidden = this.authUtility.getHiddenButtons(pageConfig, this.pageId);
      console.log('restricted tabs', this.tabsToBeHidden);
      console.log('restricted actions', this.actionToBeHidden);
      console.log('restricted buttons', this.buttonsToBeHidden);
    }
    this.isPageRestrictionLoaded = true;
    this.loadData();
  }

  getAuthorizedTabs(tabs: any[]) {
    return tabs.filter(c => this.tabsToBeHidden.find(hc => hc.toLowerCase() === c.menu_id.toLowerCase()) === undefined);
  }

  getAuthorizedButtons(buttons: any[]) {
    return buttons.filter(c => this.buttonsToBeHidden.find(hc => hc.toLowerCase() === c.button_id.toLowerCase()) === undefined);
  }

  getAuthorizedActions(actions: any[]) {
    return actions.filter(c => this.actionToBeHidden.find(hc => hc.toLowerCase() === c.button_id.toLowerCase()) === undefined);
  }

  async getMenuItems() {
    try {
      if (this.entry) {
        const menu = await this.sectionsService.getJsonConfigurationData(
          STATIC_JSON_DATA_PATH.LEAD_DETAILS_MENU,
          this.entry
        );
        this.horizontalMenuItemstoDisplay = this.getAuthorizedTabs(this.filterHorizontalMenuItems(
          menu.horizontal_menu
        ));
      }
    } catch (e) {
      console.log('error fetching menu items: ', e);
    }

    this.selectedMenuItem = this.horizontalMenuItemstoDisplay[0].menu_id;
    localStorage.setItem('horizontal_first_menu', this.selectedMenuItem);
    await this.getSectionData();


    this.router.navigate([
      `${this.detailPageBaseUrl}/${this.horizontalMenuItemstoDisplay[0].landing_component}`,
    ]);

    /* if (this.entry == 'participant') {
      this.router.navigate([
        `/home/participantLeadsList/leadDetails/${this.horizontalMenuItemstoDisplay[0].landing_component}`,
      ]);
    } else {
      this.router.navigate([
        `/home/providerLeadsList/leadDetails/${this.horizontalMenuItemstoDisplay[0].landing_component}`,
      ]);
    } */
  }

  async getSectionData() {
    try {
      if (this.entry) {
        this.steps = await this.sectionsService.getJsonConfigurationData(
          this.sectionDataPath,
          this.entry
        );
        this.secData = this.steps['participant_info'];
      }
    } catch (e) {
      console.log('error fetching section data: ', e);
    }
  }

  filterHorizontalMenuItems = (menu: MainMenuItem[]) => {
    return menu.filter((menuItem: MainMenuItem) => {
      return this.limitedAccess === true ? menuItem.display == 'y' && PARTICIPANT_LIMITED_ACCESS_PAGES.includes(menuItem.menu_id) : menuItem.display == 'y';
    });
  };

  onMenuSelection = (menuSelected: any) => {
    this.selectedMenuItem = menuSelected.menu_id;

    this.router.navigate([
      `${this.detailPageBaseUrl}/${menuSelected.landing_component}`,
    ]);
    
    /* if (this.entry == 'participant')
      this.router.navigate([
        `/home/participantLeadsList/leadDetails/${menuSelected.landing_component}`,
      ]);
    else {
      this.router.navigate([
        `/home/providerLeadsList/leadDetails/${menuSelected.landing_component}`,
      ]);
    } */
    /* this.menuService.setSelectedMenu(menuSelected); */
  };


  getUrlForBackButton() : string {
    // Get the current URL
    const currentUrl = this.router.url;
    const urlSegments = currentUrl.split('/'); // Split the URL by '/' and remove the last segment
    urlSegments.splice(-2); // Remove the last two segment
    const newUrl = urlSegments.join('/'); // Join the remaining segments to form the URL
    console.log(newUrl); // Logs the URL without the last segment
    return newUrl;
  }

  comments() {
    this.enableComments = !this.enableComments;
  }

  getValue(code: string, valueOf: string) {
    let check;
    if (valueOf == 'status') {
      check = this.applicationStatusList;
    } else if (valueOf == 'stage') {
      check = this.applicationStatusList;
    }

    return this.lookupService.getLookupName(check, code);
  }

  // new UI changes

  isTempZActive = false;
  isAuditHistory = false;

  toggleTempZ() {
    this.isTempZActive = !this.isTempZActive; // Toggle the state when the button is clicked
  }

  closeDropdown() {
    this.isTempZActive = false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const target = event.target as HTMLElement;
    if (!target.closest('.dropdown-container')) {
      this.closeDropdown();
    }
  } 
  leadId: string | null = null;

  navigateToLeads() {
    if (environment.isExternal) {
      this.router.navigate([
        `/`,
      ]);
      return;
    }

    this.router.navigate([
      `${this.getUrlForBackButton()}`,
    ]);
    
    /* if ( this.entry == 'participant') {
      this.router.navigate([
        `/home/participantLeadsList/`,
      ]);
    } 
    else if(this.entry == 'referral'){
      this.router.navigate([
        `/home/referralList/`,
      ]);
    }else {
      this.router.navigate([
        `/home/providerLeadsList/`,
      ]);
    } */
  } 

  getUpdatedLeadData = (event: boolean) => {
    if (event) {
      this.getLeadData(true);
    }
  };

  getLeadHistory() {
    
    const entry = this.leadDetailsService.getEntry(this.entry); // this is a special case where the url path parameter is not the same as the UI entry variable
    this.leadDetailsService.getLeadAuditHistory(this.program.program_code, entry,
      this.id).subscribe(data =>{
        this.auditHistory = data.responsedata;
        this.isAuditHistory = true;
      });
  }

  communicationLogs(){
    this.isCommunicationLogs = true;
  }
  // sendNotification(type: string) {
  //   const values = type == 'sms' ? this.mobileNumber : this.emailId;
  //   this.notificationType = type == 'sms' ? 'mobile number' : 'email address';
  //   if (!values.length || (values.length && values.some((val) => val === ''))) {
  //     const errorMessage = `Note that the ${this.notificationType} is currently absent in the Contact Details section of profile. Please specify the ${this.notificationType} before continuing.`;
  //     this.errorAlertService.openModal('error', 'Error', errorMessage, '');
  //   } else {
  //     this.showSMSModal = 'sms';
  //   }
  // }

  onHandleCancel(event: any) {
    this.showSMSModal = event;
  }

  

  getLeadData(isAfterUpdate = false) {
    /* const id = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!); */
    this.leadDetailsService
      .fetchLeadDetailsApi(
        STATIC_DATA.PARTICIPANT_ENROLLMENT,
        this.program.program_code,
        this.selectedLead,
        isAfterUpdate
      )
      .subscribe((data: any) => {
        if(this.entry === STATIC_DATA.PROVIDER_ENROLLMENT){
          this.filledInfo = data?.responsedata?.basicInfo;
        }else{
          this.filledInfo = data?.responsedata?.infoData;
        }
      });
  }


  getAuthDetails() {
    // this.authorizationService.getAuthorizationDetails(this.programName, this.selectedAuthId).subscribe((data: any) => {
    //   console.log('details', data);
    // })

    const payload = this.buildRequestPayload();
    const from = '';
      this.listService.getListData(payload, from).subscribe((data: any) => {
        console.log('search data', data);
        this.authData = data.responsedata[0];
        this.selectedLead = this.authData.participantId;
        this.getLeadData();
        //this.gridData = data.responsedata;
      });

  }

  buildRequestPayload(event?: any) {
    //const formData = this.authorizationsForm.value;
    const payload: any = {
      pageName: 'consumer-auth',
      programCode: this.program.program_code,
      condition: 'and',
      recordsPerPage: 20,
      startingPageNumber: '1',
      searchParam: [{
        jsonPath: 'authorizationId',
        value:  this.id,
        operator: 'equal'
      }],
      orderByColumn: [],
      orderBy: [],
    }

    return payload;
  }

}
