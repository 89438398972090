import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SectionsService } from '../../services/sections.service';
import { ENDPOINTS } from '../constants/url.constants';
import { ListGridRequestPayload } from '../interfaces/list.interface';
import { formatUrl } from '../utils/format-url-util';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { HttpRequestService } from './http-request.service';
import { NotificationService } from './notification.service';
import { SharedDataService } from './shared-data-service';
import { EnrollmentDataService } from '../../services/enrollment-data.service';
import { FileUploadNotificationService } from '../../services/file-upload-notification.service';

@Injectable({
  providedIn: 'root',
})
export class ListService {
  private apiUrl = environment.apiUrl;
  applicationDataSub = new Subject();

  constructor(
    private httpService: HttpClient,
    private http: HttpRequestService,
    private sharedService: SharedDataService,
    private sectionService: SectionsService,
    private errorHandler: GlobalErrorHandlerService,
    private notificationService: NotificationService,
    private enrollmentService: EnrollmentDataService,
    private fileUploadNotificationService: FileUploadNotificationService
  ) {}
  //constructor(private http: HttpClient) { }

  getListData(
    requestPayload: ListGridRequestPayload,
    from?: string,
    hasUpdatedEntry?: boolean
  ): Observable<any> {
   
    let url; 
    if(from === 'claims'){
      url = `${this.apiUrl}/clmapi/v1/getListPageData`
    }
    else{
      url = `${this.apiUrl}/api/v1/getListPageData`
    }
    if (requestPayload.pageName === 'fileTriageList') {
      return this.enrollmentService.getFileTriageList(requestPayload.programCode);
    }
    if (requestPayload.pageName === 'fileUploadNotificationList') {
      return this.fileUploadNotificationService.getFileNotificationList(requestPayload.programCode, hasUpdatedEntry);
    }
    return this.http.post(
      url,
      requestPayload,
      hasUpdatedEntry
    );
    //return this.httpService.get(`../../../assets/data/referral.json`);
  }

  getListConfigData(code: string, listpage: string) {
    return require(`../../../assets/data/programs/${code}/list_pages/${listpage}.json`);
  }

  getUsers(requestPayload: any): Observable<any> {
    return this.http.post(ENDPOINTS.GET_USERS, requestPayload);
  }

  assignUser(requestPayload: any): Observable<any> {
    return this.http.post(ENDPOINTS.ASSIGN_LEAD, requestPayload, true);
  }
  
  sendRegistrationLink(requestPayload: any,sourcePage: string): Observable<any> {
    let url:any;
    if (sourcePage === 'participantenrlmnt') {
      url = ENDPOINTS.ENROLLMENT.SEND_PARTICIPANT_REGISTRATION;
    } else {
      url = ENDPOINTS.ENROLLMENT.SEND_PROVIDER_REGISTRATION;
    }
    return this.http.post(url, requestPayload);
  }
  updateParticipantStatus(requestPayload: any, rfrwRequest = false): Observable<any> {
    const url = ENDPOINTS.UPDATE_PARTICIPANT_STATUS;
    return this.http.post(url, requestPayload, rfrwRequest);
  }
  
  updateUserStatus(requestPayload: any, sourcePage: string): Observable<any> {
    let url;
    if (sourcePage === 'participant' || sourcePage === 'provider') {
      const flow = sourcePage.charAt(0).toUpperCase() + sourcePage.slice(1);
      url = formatUrl(ENDPOINTS.UPDATE_LEAD_STATUS, { flow });
    } else if (sourcePage === 'participantenrlmnt') {
      url = ENDPOINTS.ENROLLMENT.UPDATE_PARTICIPANT_ASSOCIATIONS_STATUS;
    } else if (sourcePage === 'providerenrlmnt') {
      url = ENDPOINTS.ENROLLMENT.UPDATE_PROVIDER_ASSOCIATIONS_STATUS;
    }
    else{
      url = ENDPOINTS.UPDATE_REFERRAL_STATUS;
    }
    return this.http.post(url, requestPayload, true);
  }

  sendNotification(from:string, requestPayload: any): Observable<any> {
    const url = from === 'details' ? ENDPOINTS.SEND_NOTIFICATION : ENDPOINTS.SEND_NOTIFICATION_WITH_IDS;
    return this.http.post(url, requestPayload, true);
  }

  setApplicationData(data: any) {
    this.applicationDataSub.next(data);
  }

  getPrograms() {
    return this.http.get(ENDPOINTS.GET_PROGRAMS);
  }

  getProgramTypes(program_code: string) {
    const url = formatUrl(ENDPOINTS.GET_PROGRAM_TYPES, { program_code });
    return this.http.get(url);
  }
}
