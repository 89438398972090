<!-- <h2>Steps</h2> -->
<div class="steps-card">
  <div class="steps-head">
    <nz-steps [nzCurrent]="currentStep" nzDirection="vertical" class="main-steps">
      @if(getSteps('participant_info')?.isAvailable === "Y"){
      <nz-step [nzTitle]="getSteps('participant_info')?.step_name" [nzStatus]="getStepStatus('participant_info')"
        (click)="goToStep('participant_info')"></nz-step>
      }
      @if(getSteps('service_details')?.isAvailable === "Y"){
      <nz-step [nzTitle]="getSteps('service_details')?.step_name" [nzStatus]="getStepStatus('service_details')"
        (click)="goToStep('service_details')"></nz-step>
      }
      @if(getSteps('contact_details')?.isAvailable === "Y"){
      <nz-step [nzTitle]="getSteps('contact_details')?.step_name" [nzStatus]="getStepStatus('contact_details')"
        (click)="goToStep('contact_details')"></nz-step>
      }
      @if(getSteps('comm_preferences')?.isAvailable === "Y"){
      <nz-step [nzTitle]="getSteps('comm_preferences')?.step_name" [nzStatus]="getStepStatus('comm_preferences')"
        (click)="goToStep('comm_preferences')"></nz-step>
      }
      @if(getSteps('associations')?.isAvailable === "Y"){
        <nz-step [nzTitle]="getSteps('associations')?.step_name" [nzStatus]="getStepStatus('associations')"
          (click)="goToStep('associations')"></nz-step>
      }
      @if(getSteps('signatures')?.isAvailable === "Y"){
        <nz-step [nzTitle]="getSteps('signatures')?.step_name" [nzStatus]="getStepStatus('signatures')"
          (click)="goToStep('signatures')"></nz-step>
      }
      @if(getSteps('forms')?.isAvailable === "Y"){
        <nz-step [nzTitle]="getSteps('forms')?.step_name" [nzStatus]="getStepStatus('forms')"
          (click)="goToStep('forms')"></nz-step>
      }
      @if(getSteps('checklist')?.isAvailable === "Y"){
        <nz-step [nzTitle]="getSteps('checklist')?.step_name" [nzStatus]="getStepStatus('checklist')"
          (click)="goToStep('checklist')"></nz-step>
      }
      @if(getSteps('upload_docs')?.isAvailable === "Y"){
      <nz-step [nzTitle]="getSteps('upload_docs')?.step_name" [nzStatus]="getStepStatus('upload_docs')"
        (click)="goToStep('upload_docs')"></nz-step>
      }
      @if(getSteps('payment_details')?.isAvailable === "Y"){
      <nz-step [nzTitle]="getSteps('payment_details')?.step_name" [nzStatus]="getStepStatus('payment_details')"
        (click)="goToStep('payment_details')"></nz-step>
      }
      @if(getSteps('summary')?.isAvailable === "Y"){
      <nz-step [nzTitle]="getSteps('summary')?.step_name" [nzStatus]="getStepStatus('summary')"
        (click)="goToStep('summary')"></nz-step>
      }
    </nz-steps>
  </div>
  <div class="steps-footer">
    <p class="steps-tips">
      <i class="fa fa-info-circle" aria-hidden="true"></i> Once you have completed a section you can go back to view or
      edit from here. Fields marked with an <span style="color: red;">*</span> are required to move on to the next
      section.
    </p>

  @if(!isFormValid() && onSummary){
    <p><strong>Please ensure all mandatory steps are filled out before submitting.</strong></p>
  }
    @if(currentStep>0) {
    <ppl-button label="Previous" icon="fa fa-chevron-left" [customClass]="'ghost-button'" [iconPosition]="'left'"
      (click)="prev()"></ppl-button>
    }
    @if(!onSummary) {
      @if(flow === 'participantEnrollment' || flow === 'providerEnrollment'){
        <ppl-button label="Save and Next" icon="fa fa-chevron-right" [customClass]="'alt-button'" [iconPosition]="'right'"
        (click)="next()"></ppl-button>
      }
      @else{
        <ppl-button label="Next" icon="fa fa-chevron-right" [customClass]="'alt-button'" [iconPosition]="'right'"
        (click)="next()"></ppl-button>
      }
    }
    @else {
    @if(flow === 'referral') {
    <ppl-button label="Submit" icon="fa fa-check-circle" [customClass]="'primary-button'" [iconPosition]="'right'"
      [disabled]="!isFormValid()" (click)="submit('SAVE')"></ppl-button>
    <ppl-button label="Submit and Approve" icon="fa fa-check-circle" [customClass]="'primary-button'" [iconPosition]="'right'"
      [disabled]="!isFormValid()" (click)="submit('SUBMIT_APPROVE')"></ppl-button>
    }
    @else {
    <ppl-button label="Submit" icon="fa fa-check-circle" [customClass]="'primary-button'" [iconPosition]="'right'"
      [disabled]="!isFormValid()" (click)="submit()"></ppl-button>
    }
    }
  </div>
</div>