import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  private data: any = {};

  setData(key: string, value: any) {
    this.data[key] = value;
  }

  getData(key: string): any {
    return this.data[key];
  }

  clearData(key: string) {
    this.data[key] = null;
  }

  getType(source: any) {
    const type = source.toLowerCase();
    if (type.includes('participant') && type.includes('enrollment')) {
      return 'participantenrlmnt';
    } else if (type.includes('provider') && type.includes('enrollment')) {
      return 'providerenrlmnt';
    } else if (type.includes('participant')) {
      return 'participant';
    } else if (type.includes('provider')) {
      return 'provider';
    } else if (type.includes('referral')) {
      return 'referral';
    } else if (type.includes('triage')) {
      return 'filetriage';
    } else {
      return 'unknown';
    }
  }
}