{
  "sectionId": "process_code",
  "header": "Process Codes",
  "available_actions": null,
  "attributes": [
    {
      "attribute_ID": "code",
      "fieldName": "code",
      "label": "Process Code",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please enter process code",
      "isAvailable": "Y",
      "placeholder": "Process Code"
    },
    {
      "attribute_ID": "subcategoryLkp",
      "fieldName": "subcategoryLkp",
      "label": "Sub Category",
      "type": "select",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select the category",
      "isAvailable": "Y",
      "placeholder": "Category"
    },
    {
      "attribute_ID": "description",
      "fieldName": "description",
      "label": "Description",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please add description",
      "isAvailable": "Y",
      "placeholder": "Description"
    },
    {
      "attribute_ID": "internalMessage",
      "fieldName": "internalMessage",
      "label": "Internal Message",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please add internal message",
      "isAvailable": "Y",
      "placeholder": "Internal Message"
    },
    {
      "attribute_ID": "externalMessage",
      "fieldName": "externalMessage",
      "label": "External Message",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please add external message",
      "isAvailable": "Y",
      "placeholder": "External Message"
    },
    {
      "attribute_ID": "disposition",
      "fieldName": "disposition",
      "label": "Disposition",
      "type": "select",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select disposition",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "allowOverride",
      "fieldName": "allowOverride",
      "label": "Override Allowed",
      "type": "radio",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select if override is allowed",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "effectiveFromDate",
      "fieldName": "effectiveFromDate",
      "label": "Effective Start Date",
      "type": "datepicker",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select start date",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "effectiveEndDate",
      "fieldName": "effectiveEndDate",
      "label": "Effective End Date",
      "type": "datepicker",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select end date",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "providerTypes",
      "fieldName": "providerTypes",
      "label": "Personal Assistant Type",
      "type": "select",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select Personal Assistant type",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "resolutionText",
      "fieldName": "resolutionText",
      "label": "Resolution Text",
      "type": "select",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please enter resolution text",
      "isAvailable": "Y",
      "placeholder": "Resolution Text"
    },
    {
      "attribute_ID": "serviceCodes",
      "fieldName": "serviceCodes",
      "label": "Service Code",
      "type": "select",
      "mandatory": "Y",
      "validator": null,
      "tooltip": "Please select service code",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "applicableForAuth",
      "fieldName": "applicableForAuth",
      "label": "Applicable for Authorization",
      "type": "select",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select an option",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "applicableForTs",
      "fieldName": "applicableForTs",
      "label": "Applicable for Timesheet",
      "type": "select",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select an option",
      "isAvailable": "Y"
    }
  ],
  "validations": [
    {
      "whatToCheck": "atLeastXEntered",
      "clientOrServer": "C",
      "attributes": [
        {
          "validatorid": 1,
          "attribute_ID": ["serviceCodes"],
          "functionParams": {
            "ErrMsg": "Please select Service Code"
          }
        }
      ]
    },
    {
      "whatToCheck": "checkFieldValue",
      "clientOrServer": "C",
      "attributes": []
    },
    {
      "whatToCheck": "conditionalCheck",
      "clientOrServer": "C",
      "attributes": []
    },
    {
      "whatToCheck": "customValidator",
      "clientOrServer": "C",
      "attributes": []
    }
  ],
  "actions": [
    {
      "Name": "Previous",
      "Validate": null
    },
    {
      "Name": "Next",
      "Validate": [
        {
          "checkType": "atLeastXEntered",
          "validatorid": [1]
        },
        {
          "checkType": "checkFieldValue",
          "validatorid": []
        },
        {
          "checkType": "conditionalCheck",
          "validatorid": []
        },
        {
          "checkType": "customValidator",
          "validatorid": []
        }
      ]
    }
  ]
}