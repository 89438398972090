<div class="ppl-container-body">
  <div class="ppl-container">
    <div class="ppl-content-container" style="padding: 8px; max-height: calc(100vh - 140px); box-sizing: border-box;">
      <div class="line1">
        <div class="action">
          <ppl-button [label]="'Back'" icon="fa fa-chevron-left" (click)="goBack()"
            [customClass]="'primary-button semi-rounded'" [iconPosition]="'left'"></ppl-button>
        </div>
      </div>
      <div class="line2">
        {{pageTitle}}
      </div>
      <div style="width: 100%">
        <ppl-accordion [title]="'Basic Information'" [isOpen]="true">
          <div class="wiz-body">
            <div class="ppl-mt24">
              <div class="custom-row">
                @if((mode==="edit") && !this.isNew){
                <ppl-button label="Cancel" icon="fa fa-ban" [customClass]="'alt-button'" [iconPosition]="'left'"
                  (click)="onCancel()"></ppl-button>
                <ppl-button label="Save" icon="fa fa-floppy-o" [customClass]="'alt-button'" [iconPosition]="'left'"
                  (click)="onUpdate()"></ppl-button>
                }
                @if(mode==="read" && !this.isNew){
                <ppl-button label="Edit" icon="fa fa-pencil" [customClass]="'ghost-button'" [iconPosition]="'left'"
                  (click)="onEdit()"></ppl-button>
                }
              </div>
              @if(mode==="edit"){
              <form nz-form [formGroup]="billablePARatesForm" class="ppl-p0 form-container">
                @if(isAttrAvailable('serviceAdminName')){
                <nz-form-item nz-col class="ppl-form-item nz-padding-left">
                  <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="serviceAdminName"
                    class="ppl-form-label">{{getAttributes('serviceAdminName').label}}
                    @if(getAttributes('serviceAdminName').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('serviceAdminName')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      <nz-select formControlName="serviceAdminName" nzMode='default' class="ppl-select"
                        id="serviceAdminName" nzShowSearch nzAllowClear
                        [ngClass]="{'error': validationErrors['serviceAdminName'] }" [nzDisabled]="">
                        @for(opt of waiver; track $index){
                        <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                        }
                      </nz-select>
                    </div>
                    @if (validationErrors['serviceAdminName']) {
                    <span class="pl-1 error-message">{{validationErrors['serviceAdminName']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }
                @if(isAttrAvailable('mcoId')){
                <nz-form-item nz-col class="ppl-form-item">
                  <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="mcoId"
                    class="ppl-form-label">{{getAttributes('mcoId').label}}
                    @if(getAttributes('mcoId').mandatory === 'Y'){
                    <span>*</span>
                    }</nz-form-label>
                  <nz-form-control  [nzSm]="12" [nzXs]="24"  class="ppl-form-control" nz-tooltip
                    nzTooltipTitle="{{getAttributes('mcoId').tooltip}}" nzTooltipPlacement="topRight">
                    <nz-select formControlName="mcoId" class="ppl-select" nzId="mcoId"
                      nzPlaceHolder="{{getAttributes('mcoId').tooltip}}"
                      [ngClass]="{'error': validationErrors['mcoId'] }" nzShowSearch nzAllowClear>
                      @for(opt of mcoEntries; track $index){
                      <nz-option [nzLabel]="opt.orgName" [nzValue]="opt.orgId"></nz-option>
                      }
                    </nz-select>
                    @if (validationErrors['mcoId']) {
                    <span class="pl-1 error-message">{{validationErrors['mcoId']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }

                @if(isAttrAvailable('regionId')){
                  <nz-form-item nz-col class="ppl-form-item">
                    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="regionId"
                                   class="ppl-form-label">{{getAttributes('regionId').label}}
                      @if(getAttributes('regionId').mandatory === 'Y'){
                        <span>*</span>
                      }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                      <div nz-tooltip [nzTooltipTitle]="getAttributes('regionId')?.tooltip || ''"
                           nzTooltipPlacement="topRight">
                        <nz-select (ngModelChange)="updateCountiesList($event)" formControlName="regionId" nzMode='default' class="ppl-select" nzId="regionId"
                                   nzShowSearch nzAllowClear [ngClass]="{'error': validationErrors['regionId'] }" [nzDisabled]="">
                          @for(opt of regions; track $index){
                            <nz-option [nzLabel]="opt.regionName" [nzValue]="opt.payRegionId"></nz-option>
                          }
                        </nz-select>
                      </div>
                      @if (validationErrors['regionId']) {
                        <span class="pl-1 error-message">{{validationErrors['regionId']}}</span>
                      }
                    </nz-form-control>
                  </nz-form-item>
                }

                @if(isAttrAvailable('county')){
                <nz-form-item nz-col class="ppl-form-item">
                    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="county"
                      class="ppl-form-label">{{getAttributes('county').label}}
                      @if(getAttributes('county').mandatory === 'Y'){
                      <span>*</span>
                      }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                      <div nz-tooltip [nzTooltipTitle]="getAttributes('county')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="countyId" nzMode='default' class="ppl-select" nzId="county"
                          nzShowSearch nzAllowClear [ngClass]="{'error': validationErrors['county'] }" [nzDisabled]="">
                          @for(opt of counties; track $index){
                          <nz-option [nzLabel]="opt.regionCountyName" [nzValue]="opt.payRegionCountyId"></nz-option>
                          }
                        </nz-select>
                      </div>
                      @if (validationErrors['county']) {
                      <span class="pl-1 error-message">{{validationErrors['county']}}</span>
                      }
                    </nz-form-control>
                </nz-form-item>
                }
                @if(isAttrAvailable('serviceCodeDetailId')){
                <nz-form-item nz-col class="ppl-form-item nz-padding-left">
                  <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="serviceCodeDetailId"
                    class="ppl-form-label">{{getAttributes('serviceCodeDetailId').label}}
                    @if(getAttributes('serviceCodeDetailId').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('serviceCodeDetailId')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      <nz-select formControlName="serviceCodeDetailId" nzMode='default' class="ppl-select" id="serviceCodeDetailId"
                        nzShowSearch nzAllowClear [ngClass]="{'error': validationErrors['serviceCodeDetailId'] }" [nzDisabled]="">
                        @for(opt of serviceCodes; track $index){
                        <nz-option [nzLabel]="opt.code" [nzValue]="opt.serviceCodeDetailId"></nz-option>
                        }
                      </nz-select>
                    </div>
                    @if (validationErrors['serviceCodeDetailId']) {
                    <span class="pl-1 error-message">{{validationErrors['serviceCodeDetailId']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }
                @if(isAttrAvailable('modifier')){
                <nz-form-item nz-col class="ppl-form-item">
                    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="modifier"
                      class="ppl-form-label">{{getAttributes('modifier').label}}
                      @if(getAttributes('modifier').mandatory === 'Y'){
                      <span>*</span>
                      }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                      <div nz-tooltip [nzTooltipTitle]="getAttributes('modifier')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="modifierDetailId" nzMode='default' class="ppl-select"
                          nzId="modifier" nzShowSearch nzAllowClear [ngClass]="{'error': validationErrors['modifier'] }"
                          [nzDisabled]="">
                          @for(opt of modifiers; track $index){
                          <nz-option [nzLabel]="opt.code" [nzValue]="opt.modifierDetailId"></nz-option>
                          }
                        </nz-select>
                      </div>
                      @if (validationErrors['modifier']) {
                      <span class="pl-1 error-message">{{validationErrors['modifier']}}</span>
                      }
                    </nz-form-control>
                </nz-form-item>
                }

                @if(isAttrAvailable('fromDate')){
                <nz-form-item nz-col class="ppl-form-item nz-padding-left">
                  <nz-form-label [nzSm]="6" [nzXs]="24" zFor="fromDate"
                    class="ppl-form-label">{{getAttributes('fromDate').label}}
                    @if(getAttributes('fromDate').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('fromDate')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      <nz-date-picker formControlName="fromDate" id="fromDate" [nzFormat]="'MM/dd/yyyy'"
                        [nzPlaceHolder]="getAttributes('fromDate').placeholder" [nzDisabledDate]="disabledStartDate"
                        [ngClass]="{'error': validationErrors['fromDate'] }"></nz-date-picker>
                    </div>
                    @if (validationErrors['fromDate']) {
                    <span class="pl-1 error-message">{{validationErrors['fromDate']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }
                @if(isAttrAvailable('toDate')){
                <nz-form-item nz-col class="ppl-form-item nz-padding-left">
                  <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="toDate"
                    class="ppl-form-label">{{getAttributes('toDate').label}}
                    @if(getAttributes('toDate').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('toDate')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      <nz-date-picker formControlName="toDate" id="toDate" [nzFormat]="'MM/dd/yyyy'"
                        [nzPlaceHolder]="getAttributes('toDate').placeholder" [nzDisabledDate]="disabledEndDate"
                        [ngClass]="{'error': validationErrors['toDate'] }"></nz-date-picker>
                    </div>
                    @if (validationErrors['toDate']) {
                    <span class="pl-1 error-message">{{validationErrors['toDate']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }

                @if(isAttrAvailable('rate')){
                <nz-form-item nz-col class="ppl-form-item nz-padding-left">
                  <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="rate"
                    class="ppl-form-label">{{getAttributes('rate').label}}
                    @if(getAttributes('rate').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('rate')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      <input nz-input formControlName="rate" id="rate"
                        placeholder="{{getAttributes('rate').placeholder}}"
                        [ngClass]="{'error': validationErrors['rate'] }" />
                    </div>
                    @if (validationErrors['rate']) {
                    <span class="pl-1 error-message">{{validationErrors['rate']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }



              </form>


              @if(isNew){
              <div class="custom-row">
                <ppl-button [label]="'Cancel'" [icon]="'fa fa-close'" style="margin-right:4px; font-size:0.857rem;"
                  [customClass]="'ghost-button'" [iconPosition]="'right'" (click)="goBack()"></ppl-button>
                <ppl-button label="Submit" icon="fa fa-check-circle" [customClass]="'primary-button'"
                  [iconPosition]="'right'" (click)="onAdd()"></ppl-button>
              </div>
              }
              }
              @else if (mode==="read") {
              <div nz-row>
                @if(isAttrAvailable('serviceAdminName')){
                  <div nz-col nzSpan="12">
                    <div nz-row>
                      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("serviceAdminName").label }}
                      </div>
                      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.serviceAdminName }}</div>
                    </div>
                  </div>
                  }
                  @if(isAttrAvailable('mcoId')){
                    <div nz-col nzSpan="12">
                      <div nz-row>
                        <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                          {{ getAttributes("mcoId").label }}
                        </div>
                        <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.mcoName }}</div>
                      </div>
                    </div>
                    }
                    @if(isAttrAvailable('regionId')){
                      <div nz-col nzSpan="12">
                        <div nz-row>
                          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                            {{ getAttributes("regionId").label }}
                          </div>
                          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.regionName }}</div>
                        </div>
                      </div>
                    }
                    @if(isAttrAvailable('county')){
                      <div nz-col nzSpan="12">
                        <div nz-row>
                          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                            {{ getAttributes("county").label }}
                          </div>
                          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.countyName }}</div>
                        </div>
                      </div>
                      }
                @if(isAttrAvailable('serviceCodeDetailId')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("serviceCodeDetailId").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.serviceCode }}</div>
                  </div>
                </div>
                }

                @if(isAttrAvailable('modifier')){
                  <div nz-col nzSpan="12">
                    <div nz-row>
                      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("modifier").label }}
                      </div>
                      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.modifierCode }}</div>
                    </div>
                  </div>
                  }

                @if(isAttrAvailable('fromDate')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("fromDate").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.fromDate }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('toDate')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("toDate").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.toDate }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('rate')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("rate").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.rate }}</div>
                  </div>
                </div>
                }
              </div>
              }
            </div>
          </div>
        </ppl-accordion>
      </div>
    </div>
  </div>
</div>
