import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import {
  NzUploadChangeParam,
  NzUploadModule,
  NzUploadXHRArgs,
} from 'ng-zorro-antd/upload';
import { SectionsService } from '../../../services/sections.service';
import { ListService } from '../../services/list.service';
import { SharedDataService } from '../../services/shared-data-service';
import { SharedService } from '../../services/shared.service';
import { BaseComponent } from '../../utils/base.component';
import { NzAriaLabelDirective } from '../../directives/nz-aria-label.directive';

@Component({
  selector: 'ppl-upload',
  standalone: true,
  providers: [ListService],
  imports: [
    NzUploadModule,
    NzFormModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzIconModule,
    CommonModule,
    NzToolTipModule,
    NzSelectModule,
    NzAriaLabelDirective
  ],
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
})
export class UploadComponent extends BaseComponent implements OnInit {
  uploadForm!: FormGroup;
  @Input() fileList?: any;
  @Input() docTypeList?: any;
  @Output() filesUploaded = new EventEmitter();
  @ViewChild('inputName') inputName!: ElementRef<HTMLInputElement>;
  @Input() flow: any;
  @Input() leadId?: string | null = '';
  @Input() showFileList?: boolean = true;
  fileDetailsArr: any[] = [];
  position = 'center';
  program: any;

  constructor(
    private msg: NzMessageService,
    private listService: ListService,
    private fb: FormBuilder,
    private sectionsService: SectionsService,
    private sharedDataService: SharedDataService,
    private sharedService: SharedService
  ) {
    super();
  }

  ngOnInit(): void {
    this.uploadForm = this.fb.group({
      document_type: [''],
      other_type: [''],
    });
    this.fileDetailsArr = this.fileList ? this.fileList : [];
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    this.flow = this.sectionsService.getFlow();
  }

  confirmPosition(position: string, fileId: any) {
    // this.position = position;
    // this.modalService.confirm({
    //   nzTitle: 'Are you sure you want to proceed?',
    //   nzOkText: 'Yes',
    //   nzOkType: 'primary',
    //   nzCancelText: 'No',
    //   nzOnOk: () => {
        this.removeFile(parseInt(fileId.id));
    //   },
    //   nzOnCancel: () => {
    //     this.msg.info('Action canceled.');
    //   },
    // });
  }

  handleChange(info: NzUploadChangeParam): void {
    let inputValue = this.uploadForm.value.document_type;
    inputValue =
      inputValue === 'other' ? this.uploadForm.value.other_type : inputValue;
    const fileDetails: any = {};

    if (info.file) {
      const allowedExtensions = [
        'txt',
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'csv',
        'png',
        'jpg',
        'jpeg',
      ];
      const fileExtension = info.file.name.split('.').pop()?.toLowerCase();
      console.log('fileExtension', fileExtension);
      if (allowedExtensions.includes(fileExtension || '')) {
        if (info.file.status === 'done') {
          const lookupValue = this.getLookupValue(this.docTypeList, inputValue);
          fileDetails.name = info.file.name;
          fileDetails.size = info.file.size;
          fileDetails.type =
            lookupValue !== null &&
            lookupValue !== undefined &&
            lookupValue !== ''
              ? lookupValue
              : inputValue;
          fileDetails.id =
            info.file.response.responsedata.file_upload_tracking_number;
          this.fileDetailsArr.push(fileDetails);
          // this.msg.success(`${info.file.name} file uploaded successfully`);
          this.filesUploaded.emit(this.fileDetailsArr);
          this.uploadForm.controls['document_type'].setValue('');
        } else if (info.file.status === 'error') {
          // this.msg.error(`${info.file.name} file upload failed.`);
        }
      } else {
        console.log(info.file.status);
        if (info.file.status === 'done') {
          this.msg.error(
            `Invalid file type selected. Please select a valid file.`
          );
        }
      }
    }
  }

  customRequest = (item: NzUploadXHRArgs): any => {
    let inputValue = this.uploadForm.value.document_type;
    inputValue =
      inputValue === 'other' ? this.uploadForm.value.other_type : inputValue;
    const formData = new FormData();
    formData.append('file', item.file as any);
    const allowedExtensions = [
      'txt',
      'pdf',
      'doc',
      'docx',
      'xls',
      'xlsx',
      'csv',
      'png',
      'jpg',
      'jpeg',
    ];
    const fileExtension = item.file.name.split('.').pop()?.toLowerCase();
    console.log('fileExtension', fileExtension);
    if (allowedExtensions.includes(fileExtension || '')) {
      const type = this.flow || localStorage.getItem('lead_entry');
      const flowName = this.sharedDataService.getType(type);
      const requestPayload = {
        fileType: item.file.name.split('.').pop(),
        otherType: inputValue || '',
        program: this.program.program_code,
        type: flowName,
        leadId: this.leadId,
      };

      formData.append(
        'json',
        new Blob([JSON.stringify(requestPayload)], { type: 'application/json' })
      );

      this.sharedService.uploadFile(formData).subscribe({
        next: (response: any) => {
          if (item.onSuccess) {
            item.onSuccess(response, item.file, null);
          }
        },
        error: (error: any) => {
          if (item.onError) {
            item.onError(error, item.file);
          }
        },
      });
    } else {
        this.msg.error(
          `Invalid file type selected. Please select a valid file.`
        );
    }
  };

  removeFile(fileId: any): void {
    const createType = this.leadId === '' ? 'new' : 'detail';
    const flow = (this.flow === 'providers') ? 'provider' : this.flow;
    this.sharedService
      .deleteFile(this.program.program_code, fileId, createType, flow)
      .subscribe((data: any) => {
        // this.msg.success(`File deleted successfully`);
        this.fileDetailsArr = this.fileDetailsArr.filter(
          (f) => f.id !== fileId
        );
        this.filesUploaded.emit(this.fileDetailsArr);
      });
  }
}
