<ppl-accordion [title]="'Application Summary'" [isOpen]="true" *authorize="[pageId, tabId, 'Summary', 'section']">
    <div class="tile-grid">

        @for (appInfo of applicationInfoItems; track appInfo; let idx = $index ) {
            <div class="tile">
                <h4>{{appInfo.summaryItemDisplayName}}</h4>
                <p class="count">{{appInfo.summaryItemValue}}</p>
            </div>
            }


        @for (summaryItem of summaryItems; track summaryItem; let idx = $index ) {
        <div class="tile">
            <h4>{{summaryItem.summaryItemDisplayName}}</h4>
            <p class="count">{{summaryItem.summaryItemValue}}</p>
        </div>
        }

        @for (auth of authorizationItems; track auth; let idx = $index ) {
            <div class="tile">
                <h4>{{auth.summaryItemDisplayName}}</h4>
                <p class="count">{{auth.summaryItemValue}}</p>
            </div>
            }
            
    </div>
</ppl-accordion>

<ppl-accordion [title]="'Earnings Summary'" [isOpen]="true" *authorize="[pageId, tabId, 'EarningsSummary', 'section']">
    <div class="row">
        <div class="col-8 d-flex justify-content-center align-items-center chart-box">
            <div class="chart-container">
                <p-chart type="doughnut" [data]="chartData" [options]="chartOptions"></p-chart>
            </div>
            
        </div>
        <div class="col-4">

            <div class="container row justify-content-center align-items-center chart-box ml-2" style="height: 500px;">
                <div class="row">
                    <div class="col-12 d-flex flex-column align-items-center">
                        <p class="filter-title"> Filter Criteria</p>
                    </div>
                    <div class="col-12 d-flex flex-column align-items-center">

                        <nz-form-item class="ppl-form-item">
                            <nz-form-label nzFor="service" [nzSpan]="24" nzFor="service" class="ppl-form-label">
                              Service
                            </nz-form-label>
                            <nz-form-control [nzSpan]="24" class="ppl-form-control">
                              <nz-select formControlName="gender" class="ppl-select"  nzShowSearch
                      nzAllowClear nzId="service" nzPlaceHolder="Support Broker Services" >
                      @for(opt of services; track $index){
                      <nz-option [nzLabel]="opt.label" [nzValue]="opt.value"></nz-option>
                      }
                    </nz-select>
                              
                            </nz-form-control>
                          </nz-form-item>
                        </div>

                        <div class="col-12 d-flex flex-column align-items-center">
                    
                            <nz-form-item class="ppl-form-item">
                              <nz-form-label nzFor="gender" [nzSpan]="24" class="ppl-form-label">
                                Participant
                              </nz-form-label>
                              <nz-form-control [nzSpan]="24" class="ppl-form-control">
                                <nz-select nzId="gender" formControlName="gender" class="ppl-select" nzPlaceHolder="Nikhil"   nzShowSearch
                        nzAllowClear >
                        @for(opt of participants; track $index){
                        <nz-option [nzLabel]="opt.label" [nzValue]="opt.value"></nz-option>
                        }
                      </nz-select>
                                
                              </nz-form-control>
                            </nz-form-item>
                         
                    </div>

                    <div class="col-12 d-flex flex-column align-items-center">
                    
                        <nz-form-item class="ppl-form-item">
                          <nz-form-label [nzSpan]="24" nzFor="firstName" class="ppl-form-label">
                            Pay Period
                          </nz-form-label>
                          <nz-form-control [nzSpan]="24" class="ppl-form-control">
                            <nz-select nzId="firstName" formControlName="gender" class="ppl-select" nzPlaceHolder="Nov 2, 2024 - Nov 14, 2024" nzShowSearch
                    nzAllowClear >
                    @for(opt of payperiods; track $index){
                    <nz-option [nzLabel]="opt.label" [nzValue]="opt.value"></nz-option>
                    }
                  </nz-select>
                            
                          </nz-form-control>
                        </nz-form-item>
                     
                </div>

                    </div>



            
    
                
    
                
                
                    
                
            </div>

         
            
        </div>
    </div>

</ppl-accordion>