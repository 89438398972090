{
  "sectionId": "service_code",
  "header": "Service Codes",
  "available_actions": null,
  "attributes": [
    {
      "attribute_ID": "code",
      "fieldName": "code",
      "label": "Service Code",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please enter service code",
      "isAvailable": "Y",
      "placeholder": "Service Code"
    },
    {
      "attribute_ID": "description",
      "fieldName": "description",
      "label": "Description",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please add description",
      "isAvailable": "Y",
      "placeholder": "Description"
    },
    {
      "attribute_ID": "effectiveFromDate",
      "fieldName": "effectiveFromDate",
      "label": "Effective Start Date",
      "type": "datepicker",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select start date",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "effectiveEndDate",
      "fieldName": "effectiveEndDate",
      "label": "Effective End Date",
      "type": "datepicker",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select end date",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "program_code_lkp",
      "fieldName": "program_code_lkp",
      "label": "Program Code",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select program code",
      "isAvailable": "Y",
      "placeholder": "Program Code"
    },
    {
      "attribute_ID": "mod1",
      "fieldName": "mod1",
      "label": "Modifier 1",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select mode 1",
      "isAvailable": "Y",
      "placeholder": "Modifier 1"
    },
    {
      "attribute_ID": "mod2",
      "fieldName": "mod2",
      "label": "Modifier 2",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select mode 2",
      "isAvailable": "Y",
      "placeholder": "Mode 2"
    },
    {
      "attribute_ID": "mod3",
      "fieldName": "mod3",
      "label": "Modifier 3",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select mode 3",
      "isAvailable": "Y",
      "placeholder": "Mode 3"
    },
    {
      "attribute_ID": "mod4",
      "fieldName": "mod4",
      "label": "Modifier 4",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select mode 4",
      "isAvailable": "Y",
      "placeholder": "Mode 4"
    },
    {
      "attribute_ID": "comment",
      "fieldName": "comment",
      "label": "Comment",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please add Comments",
      "isAvailable": "Y",
      "placeholder": "Comments"
    },
    {
      "attribute_ID": "providerType",
      "fieldName": "providerType",
      "label": "Personal Assistant Type",
      "type": "select",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select Personal Assistant type",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "doc_eligible",
      "fieldName": "doc_eligible",
      "label": "Doc Eligible",
      "type": "radio",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select Doc Eligible",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "ot_eligible",
      "fieldName": "ot_eligible",
      "label": "OT Eligible",
      "type": "radio",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select OT Eligible",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "county_lkp",
      "fieldName": "county_lkp",
      "label": "County",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select County",
      "isAvailable": "Y",
      "placeholder": "County"
    },
    {
      "attribute_ID": "city_lkp",
      "fieldName": "city_lkp",
      "label": "City",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select city",
      "isAvailable": "Y",
      "placeholder": "City"
    },
    {
      "attribute_ID": "unit_measurement_lkp",
      "fieldName": "unit_measurement_lkp",
      "label": "Measurement Unit",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select Measurement Unit",
      "isAvailable": "Y",
      "placeholder": "Unit Increment"
    },
    {
      "attribute_ID": "unit_increment_lkp",
      "fieldName": "unit_increment_lkp",
      "label": "Increment Unit",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select Increment Unit ",
      "isAvailable": "Y",
      "placeholder": "Increment Unit "
    },
    {
      "attribute_ID": "servicegroup_lkp",
      "fieldName": "servicegroup_lkp",
      "label": "Service Group",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select Service Group",
      "isAvailable": "Y",
      "placeholder": "Service Group"
    },
    {
      "attribute_ID": "medicaid_billable",
      "fieldName": "medicaid_billable",
      "label": "Medicaid Billable",
      "type": "radio",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select Medicaid Billable",
      "isAvailable": "Y",
      "placeholder": "Medicaid Billable"
    },
    {
      "attribute_ID": "medicaid_billing_code",
      "fieldName": "medicaid_billing_code",
      "label": "Medicaid Billing Code",
      "type": "select",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select Medicaid Billing Code",
      "isAvailable": "Y",
      "placeholder": "Medicaid Billing Code"
    },
    {
      "attribute_ID": "max_billable_rate",
      "fieldName": "max_billable_rate",
      "label": "Max Billable Rate",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please enter Markup Percent",
      "isAvailable": "Y",
      "placeholder": "Max Billable Rate"
    },
    {
      "attribute_ID": "is_group_service",
      "fieldName": "is_group_service",
      "label": "Grouping Service",
      "type": "radio",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select Grouping Service",
      "isAvailable": "Y",
      "placeholder": "Grouping Service"
    },

    {
      "attribute_ID": "participant_lkp",
      "fieldName": "participant_lkp",
      "label": "Consumer",
      "type": "select",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select the Consumer",
      "isAvailable": "Y",
      "placeholder": "Consumer"
    },
    {
      "attribute_ID": "provider_lkp",
      "fieldName": "provider_lkp",
      "label": "Personal Assistant",
      "type": "select",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select the Personal Assistant",
      "isAvailable": "Y",
      "placeholder": "Personal Assistant"
    },
    {
      "attribute_ID": "rate",
      "fieldName": "rate",
      "label": "Rate",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please enter Rate",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "fromdate",
      "fieldName": "fromdate",
      "label": "Effective From Date",
      "type": "datepicker",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select from date",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "todate",
      "fieldName": "todate",
      "label": "Effective To Date",
      "type": "datepicker",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select to date",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "ratetype",
      "fieldName": "ratetype",
      "label": "Rate Type",
      "type": "select",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select Rate Type",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "minrate",
      "fieldName": "minrate",
      "label": "Minimum Rate",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please enter Minimum Rate",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "percent_billed",
      "fieldName": "percent_billed",
      "label": "Billed Percentage",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please enter Billed Percentage",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "defaultrate",
      "fieldName": "defaultrate",
      "label": "Default Rate",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please enter Default Rate",
      "isAvailable": "Y"
    }
  ],
  "validations": [
    {
      "whatToCheck": "atLeastXEntered",
      "clientOrServer": "C",
      "attributes": []
    },
    {
      "whatToCheck": "checkFieldValue",
      "clientOrServer": "C",
      "attributes": []
    },
    {
      "whatToCheck": "conditionalCheck",
      "clientOrServer": "C",
      "attributes": []
    },
    {
      "whatToCheck": "customValidator",
      "clientOrServer": "C",
      "attributes": []
    }
  ],
  "actions": [
    {
      "Name": "Previous",
      "Validate": null
    },
    {
      "Name": "Next",
      "Validate": [
        {
          "checkType": "atLeastXEntered",
          "validatorid": []
        },
        {
          "checkType": "checkFieldValue",
          "validatorid": []
        },
        {
          "checkType": "conditionalCheck",
          "validatorid": []
        },
        {
          "checkType": "customValidator",
          "validatorid": []
        }
      ]
    }
  ]
}
