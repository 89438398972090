{
    "documents": {
      "columns": [
        {
          "field": "type",
          "header": "Document Type",
          "jsonpath": "type",
          "isDisplayedAtHeader": "y",
          "isSearchable": "n"
        },
        {
          "field": "name",
          "header": "File Name",
          "jsonpath": "name",
          "isDisplayedAtHeader": "y",
          "isSearchable": "n"
        }

      ]
    }
  }