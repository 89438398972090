import { Component, Input, OnInit } from '@angular/core'; import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ListTableComponent } from '../../../shared/components/list-table/list-table.component';
import { NgZorroModule } from '../../../shared/modules/ng-zorro/ng-zorro.module';
import { AccordionComponent } from '../../../shared/components/accordion/accordion.component';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { AssociationDetailsComponent } from './association-details/association-details.component';
import { BaseComponent } from '../../../shared/utils/base.component';
import { PROVIDER_TYPES, RADIO_YES_NO_OPTIONS, STATIC_DATA, STATIC_JSON_DATA_PATH } from '../../../shared/constants/static-data.constants';
import { SectionsService } from '../../../services/sections.service';
import { ReferralDataService } from '../../../services/referral-data.service';
import { EnrollmentDataService } from '../../../services/enrollment-data.service';
import { LoggerService } from '../../../shared/services/logger.service';
import { ProviderAssociationsComponent } from './provider-associations/provider-associations.component';
import { PopupDialogComponent } from '../../../shared/components/popup-dialog/popup-dialog.component';
import { ModalComponent } from '../../../shared/components/modal/modal.component';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { performValidation } from '../../../shared/utils/validation.util';
import { TableRowActionsModel } from '../../../shared/interfaces/list.interface';
import { TableRowActions } from '../../../shared/interfaces/actions';
import { AuthorizeDirective } from '../../../shared/authorization/authorize.directive.';
import { AuthorizeFieldDirective } from '../../../shared/authorization/authorizeField.directive';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { LookupService } from '../../../services/lookup.service';
import { MEMBER_VIEW_PAGE_IDS, TABS } from '../../../shared/constants/page.constants';
import { LeadDetailsService } from '../../../services/lead-details.service';
import { Router } from '@angular/router';
import { AuthorizationUtility } from '../../../shared/authorization/auth.utility';

@Component({
  selector: 'ppl-associations',
  standalone: true,
  imports: [ListTableComponent, CommonModule, NgZorroModule, ReactiveFormsModule, AccordionComponent,
    ButtonComponent, AssociationDetailsComponent, ProviderAssociationsComponent,
    NzDatePickerModule, ModalComponent, NzAlertModule, PopupDialogComponent, AuthorizeDirective, AuthorizeFieldDirective,
  ],
  templateUrl: './associations.component.html',
  styleUrl: './associations.component.css'
})
export class AssociationsComponent extends BaseComponent implements OnInit {

  @Input() mode = 'read';
  radioOptions = RADIO_YES_NO_OPTIONS;
  newContactForm!: FormGroup;
  show = false;
  pageId = MEMBER_VIEW_PAGE_IDS[localStorage.getItem(STATIC_DATA.URL_FIRST_SEGMENT)!];
  tabId = TABS.Associations;

  associationDetailsData: any = {};
  prvdrAssociations: any = [];
  prvdrAssociationsStatus: any = [];

  cols: any[] = [];
  statusCols: any[] = [];
  selectedOption = '';

  actions: any = {
    "Add Status": false,
  }

  entry: string | null = '';
  showModal: any;
  modalBody = '';
  program: any;
  selectedAssociationId = '';
  enrollmentStatus!: FormGroup;
  statusEntries: any[] = [];
  disableAddStatus = false;
  disableStatus = false;
  statusReasons: any[] = [];
  showMoveToInPPLReviewButton = false;
  participantStatus = '';
  actionButtons: TableRowActionsModel = new TableRowActions();
  isParticipant = true;
  leadId: any;
  isAssociationsEditable: boolean = false;

  constructor(
    private sectionsService: SectionsService,
    private fb: FormBuilder,
    private referralDataService: ReferralDataService,
    private enrollmentDataService: EnrollmentDataService,
    private lookupService: LookupService,
    private logger: LoggerService,
    private leadDetailsService: LeadDetailsService,
    private router: Router,
    private authUtility: AuthorizationUtility
  ) {
    super();
  }

  activeKeys: string[] = ['1', '2', '3', '4', '5', '6', '7'];
  allPanelsExpanded = false;

  ngOnInit(): void {
    this.entry = localStorage.getItem('lead_entry');
    this.sectionsService.setFlow(this.entry)
    this.isParticipant = this.entry === 'participantEnrollment';
    this.isAssociationsEditable = this.authUtility.isSectionEditable(this.pageId, this.tabId, TABS.Associations);
    this.newContactForm = this.fb.group({
      newEmployer: ['No'],
      newAuthRep: ['No'],
      newVendor: ['No'],
      newProvider: ['No'],
      newContractor: ['No'],
      newPOA: ['No'],
      newLegalGuardian: ['No'],
    });
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    this.leadId = localStorage.getItem('selected_lead');
    const steps = this.sectionsService.getSectionData('NY');
    this.secData = steps[this.isParticipant ? 'associations' : 'enrollment_status'];
    this.createFormGroup();

    const controls = [
      'newEmployer',
      'newAuthRep',
      'newProvider',
      'newVendor',
      'newContractor',
      'newLegalGuardian',
      'newPOA',
    ];

    controls.forEach((control) => {
      this.newContactForm.get(control)?.valueChanges.subscribe((value) => {
        this.onRadioChange(control, value);
      });
    });

    this.getGridHeaders()
    this.enrollmentDataService.getParticipantAssocChanged().subscribe(() => {
      this.getAssociationDetails(this.program.program_code, this.leadId);
    })

    this.statusReasons = this.lookupService.getLookupValue(
      'enr_status_reason'
    );
  }

  getAssociationDetails(program_code: string, leadId: any, isWriteRequest = false) {
    this.enrollmentDataService.getAssociationsDetails(program_code, leadId, this.isParticipant, isWriteRequest).subscribe((response: any) => {
      console.log(response.responsedata);
      const data = response.responsedata;
      // participantAssociations
      if (this.isParticipant) {
        for (const key in PROVIDER_TYPES) {
          this.associationDetailsData[key] = data[key] || [];
        }
      } else {
        this.prvdrAssociations = data.participantAssociations;
        this.prvdrAssociations.forEach((association: any) => {
         
            association.actions = {
              showStatus: true
            }
            if(association.providerType === 'AR'){
              association.actions = {
                showStatus: true,
                manageConsumer: true
              }
            }
        });
        console.log('this.prvdrAssociations', this.prvdrAssociations);
      }

    });
  }

  getProviderAssoc(program_code: string, leadId: any) {
    this.enrollmentDataService.fetchProviderAssociations(program_code, leadId).subscribe((response: any) => {
      console.log(response);

    })
  }

  async getGridHeaders() {
    try {
      if (this.entry) {
        const tempCols = await this.sectionsService.getJsonConfigurationData(
          this.entry === 'participantEnrollment' ? STATIC_JSON_DATA_PATH.ASSOCIATIONS_DETAILS_HEADERS : STATIC_JSON_DATA_PATH.PROVIDER_ASSOCIATIONS_HEADERS, this.entry
        );
        console.log(tempCols.columns);
        this.cols = tempCols.columns;

        if (this.entry != 'participantEnrollment') {
          const statusCols = await this.sectionsService.getJsonConfigurationData(STATIC_JSON_DATA_PATH.PROVIDER_ASSOCIATIONS_STATUS_HEADERS, this.entry);
          this.statusCols = statusCols.enrollmentStatus.columns;
        }
      }
    } catch (e) {
      this.logger.debug('Communication logs - Error in getting column data', e);
    }

  }

  goToConsumer(consumerDetail: any) {
    const program = JSON.parse(
      localStorage.getItem(STATIC_DATA.SELECTED_PROGRAM)!
    );
    localStorage.setItem('lead_entry', 'participantEnrollment');
    this.sectionsService.setFlow('participantEnrollment');
    this.referralDataService.clearReferrralData();
    localStorage.setItem('selected_lead', consumerDetail.participantId);
      this.leadDetailsService
        .fetchLeadDetailsApi(
          'participantEnrollment',
          program.program_code,
          consumerDetail.participantId
        )
        .subscribe((data: any) => {
          this.referralDataService.setReferralDetails(data.responsedata);
          if(data?.responsedata?.infoData?.applicationStatus == "In Progress" || data?.responsedata?.infoData?.applicationStatus == "New Application"){
            setTimeout(() => {
              localStorage.setItem('participantId', data?.responsedata?.infoData.participantId );
              localStorage.setItem('participantPPLId', data.responsedata?.infoData.participantPPLId);
              localStorage.setItem('trackingNumber', data.responsedata?.infoData.trackingNumber);
              this.router.navigate(['/newReferral']);
            }, 200);
          } else {
            localStorage.setItem('limitedAccess', !consumerDetail.hasAccess as unknown as string);
            setTimeout(() => {
              this.router.navigate(['/home/participantEnrollmentList/details/']);
            }, 200);
          }
        });
       
  }


  action(event: any): void {
    this.logger.debug('Action', event);

    if (event.action == 'showStatus') {
      const data = event.data;
      this.modalBody = 'Enrollment Dates and Status - ' + data.participantFirstName + ' ' + data.participantLastName + ' (' + data.participantPplId + ")";
      this.showModal = true;
      this.selectedAssociationId = data.participantProviderAssociationId;
      this.getAssociationStatus();
      this.updateAssociationStatus(data.associationStatus);
    }
    if (event.action == 'manageConsumer') {
      const data = event.data;
      this.goToConsumer(data);
    }
    if (event && event.action === 'edit') {
      this.mode = 'edit';
      if (event?.data) {
        this.updateStatusEntries();
        const data = JSON.parse(JSON.stringify(event?.data));
        data.startDate = new Date(data.startDate);
        if (data.endDate) {
          data.endDate = new Date(data.endDate);
        }
        this.enrollmentStatus.patchValue(data);
      }
    }
  }

  getAssociationStatus() {
    this.enrollmentDataService.getAssociationStatus(this.program?.program_code, this.selectedAssociationId).subscribe((data: any) => {
      this.prvdrAssociationsStatus = data?.responsedata;
      this.prvdrAssociationsStatus.forEach((data: any) => {
        if (data.startDate && data.endDate) {
          if (new Date().getTime() < new Date(data.endDate).getTime()
            &&
            new Date().getTime() > new Date(data.startDate).getTime()) {
            data.actions = {
              edit: true
            }
          }
        }

        if (data.startDate) {
          if (new Date().getTime() < new Date(data.startDate).getTime()) {
            data.actions = {
              edit: true
            }
          }
        }
      });
    });
  }

  getProviderData(providerCode: string) {
    return this.associationDetailsData[providerCode] || [];
  }

  onRadioChange(controlName: string, value: string) {
    if (value === 'Yes') {
      this.referralDataService.setFlowForAssociation({ val: true, flow: controlName });
    }
  }

  add(control: string) {
    this.referralDataService.setFlowForAssociation({ val: true, flow: control });
  }

  hideAll() {
    this.activeKeys = [];
  }

  expandAll() {
    this.activeKeys = ['1', '2', '3', '4', '5', '6', '7'];
  }

  togglePanel(key: string): void {
    if (this.isPanelActive(key)) {
      this.activeKeys = this.activeKeys.filter((k) => k !== key);
    } else {
      this.activeKeys.push(key);
    }
  }

  isPanelActive(key: string): boolean {
    return this.activeKeys.includes(key);
  }

  handleCancel() {
    this.showModal = false;
    this.modalBody = '';
    this.selectedAssociationId = '';
    this.mode = 'read';
    this.showMoveToInPPLReviewButton = false;
    this.disableStatus = false;
  }

  save() {
    if (this.mode == 'add') {
      const data = { ...this.enrollmentStatus.value };

      data.startDate = this.dateFormatter.formatToString(data.startDate);

      if (data.endDate) {
        data.endDate = this.dateFormatter.formatToString(data.endDate);
      }

      try {
        this.validationErrors = performValidation(data, this.secData, 'Save');
      } catch (e) {
        this.logger.debug('Error in validations', e);
      }

      if (Object.keys(this.validationErrors).length === 0) {
        this.enrollmentDataService
          .createEnrollmentStatus(data, this.program?.program_code, this.selectedAssociationId, true)
          .subscribe(() => {
            this.logger.info('Status information saved successfully');
            this.cancelEdit();
            this.getAssociationStatus();
            this.disableStatus = false;
            this.getAssociationDetails(this.program.program_code, this.leadId, true);
            this.handleCancel();
          });
      }
    } else if (this.mode == 'edit') {
      this.updateStatus();
    }
  }

  updateStatus() {
    const formValues = this.enrollmentStatus.value;
    const data: any = {};
    data.associationId = formValues.associationId;
    data.statusId = formValues.statusId;
    data.status = formValues.status;
    data.reason = formValues.reason;
    data.comment = formValues.comment;
    data.startDate = this.dateFormatter.formatToString(formValues.startDate);
    if (formValues.endDate) {
      data.endDate = this.dateFormatter.formatToString(formValues.endDate);
    }
    this.enrollmentDataService
      .updateEnrollmentStatus(data, this.program?.program_code, formValues.associationId)
      .subscribe(() => {
        this.logger.info('Status information updated successfully');
        this.cancelEdit();
        this.getAssociationStatus();
        this.disableStatus = false;
        this.getAssociationDetails(this.program.program_code, this.leadId, false);
        this.handleCancel();
      });
  }

  moveToPPLReview(): void {
    this.statusEntries = [
      {
        status: "In PPL Review"
      }
    ];

    this.addStatus();
    this.enrollmentStatus.patchValue({
      startDate: new Date(),
      status: 'In PPL Review'
    });
    this.disableStatus = true;
  }


  disabledStartDate = (startValue: Date): boolean => {
    const endDate = this.enrollmentStatus.get('endDate')?.value;
    if (!startValue || !endDate) {
      return false;
    }
    return startValue.getTime() > endDate.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    const startDate = this.enrollmentStatus.get('startDate')?.value;
    if (!endValue || !startDate) {
      return false;
    }
    return endValue.getTime() <= startDate.getTime();
  };

  createFormGroup() {
    this.enrollmentStatus = this.fb.group({
      statusId: [],
      associationId: [],
      startDate: [],
      endDate: [],
      status: [],
      comment: [],
      reason: []
    });
  }

  addStatus() {
    this.mode = 'add';
    this.enrollmentStatus.reset();
    this.enrollmentStatus.controls['associationId'].setValue(this.selectedAssociationId);
    this.updateStatusEntries();
  }


  updateAssociationStatus(status: string) {
    this.participantStatus = status;
    console.log('Lead Status', status);
    this.showMoveToInPPLReviewButton = false;
    this.disableAddStatus = false;
    if (this.participantStatus === 'New Application' || this.participantStatus === 'In Progress' || this.participantStatus === 'Returned') {
      this.disableAddStatus = true;
    }

    if (this.participantStatus === 'Returned' || this.participantStatus === 'In Progress') {
      this.showMoveToInPPLReviewButton = true;
    }

    this.updateStatusEntries();
  }

  cancelEdit() {
    this.mode = 'read';
    this.validationErrors = {};
    this.enrollmentStatus.reset();
    this.disableStatus = false;
  }

  updateStatusEntries() {
    if (this.participantStatus === STATIC_DATA.IN_PPL_REVIEW || this.participantStatus == 'Paperwork Complete') {
      this.statusEntries = [
        {
          status: "Returned"
        },
        {
          status: "Paperwork Complete"
        },
        {
          status: "Association Active"
        }
      ];

      if (this.mode == 'edit') {
        this.statusEntries.push({
          status: 'In PPL Review'
        });
      }
    }
    else if (this.participantStatus === "Association Active" || this.participantStatus === 'Association Active with Gap') {
      this.statusEntries = [
        {
          status: "Association Active"
        },
        {
          status: "On Hold"
        },
        {
          status: "Disenrolled"
        },
        {
          status: "Blocked"
        }
      ];
    }
  }
}
