/* src/app/shared/menu-button/menu-button.component.css */
.dropdown-container {
  position: relative;
  display: inline-block;
}
.button-icon {
  margin-right: 0.5em;
}
.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  gap: 8px;
  padding: 4px;
  margin: 4px;
  font-family: Outfit;
  font-size: 1.143rem;
  text-align: left;
  list-style: none;
  background-color: rgba(255, 255, 255, 0.459);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}
.dropdown-item {
  padding: 8px 20px;
  clear: both;
  font-family: Outfit;
  font-weight: 400;
  color: #333;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
  cursor: pointer;
}
.dropdown-item:hover,
.dropdown-item:focus {
  transition: background-color 0.3s ease;
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
.fancy-button {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 2px solid #FFF;
  background:
    linear-gradient(
      99deg,
      #DDD -11.82%,
      #D6D6D6 60.87%,
      #F0F0F0 119.15%);
  box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.12);
  color: #3F3F3F;
  text-shadow: 0px 2px 4px #FFF;
  font-family: Outfit;
  font-size: 1.143rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.fancy-button:hover {
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 8px;
  border: 2px solid #11B8AE;
  background:
    linear-gradient(
      99deg,
      #DDD -11.82%,
      #F2F2F2 60.87%,
      #F0F0F0 119.15%);
  box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.50);
}
.fancy-button:active {
  border-radius: 8px;
  border: 1px solid #FFF;
  background: #11B8AE;
}
.fancy-button .button-icon {
  filter: invert(1);
}
/*# sourceMappingURL=menu-button.component-KFYCIVQC.css.map */
