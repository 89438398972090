export const environment = {
    production: false,
    isExternal: true,
    msalConfig: {
        auth: {
            clientId: '50a4023c-08dd-439d-aa98-9cc3f4275d6e',
            authority: ""
        },
    },
    apiConfig: {
        scopes: ['openid profile offline_access 50a4023c-08dd-439d-aa98-9cc3f4275d6e'],
        uri: 'https://pplfirstprod.onmicrosoft.com/50a4023c-08dd-439d-aa98-9cc3f4275d6e/app.user',
    },
    b2cPolicies: {
        names: {
            signUpSignIn: 'B2C_1_NG_Prd_Combined_SignIn',
        },
        authorities: {
            signUpSignIn: {
                authority:
                    'https://pplfirstprod.b2clogin.com/pplfirstprod.onmicrosoft.com/B2C_1_NG_Prd_Combined_SignIn',
            }
        },
        authorityDomain: 'pplfirstprod.b2clogin.com',
    },
    apiUrl: 'https://ngenapiext-prd.pplfirst.com/external',
    apimSubScriptionKey: '235e2f643ae249dda59641ccca346bf8',
    restrictedMenus: ["process_codes", "service_codes", "participant_provider_rates", "authorizations", "timesheets"]
};