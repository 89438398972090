<div class="alert-box" *ngIf="alert?.type">
    <div class="overlay"></div>
    <div class="content">
        <div class="header">
            <div style="display: flex; gap: 8px; align-items: center;">
                <div class="icon" style="color:green; font-size: 2.286rem;">
                    <div *ngIf="alert?.type === 'success'">
                        <i class="fa fa-check-circle"></i>
                    </div>
                    <div *ngIf="alert?.type === 'error'" style="color:red; font-size: 2.286rem;">
                        <i class="fa fa-times-circle"></i>
                    </div>
                </div>
                <div class="title">
                    {{ alert?.title }}
                </div>
            </div>
            <div class="action">
                <ppl-button 
                    label=""
                    ariaLabel="Close"
                    icon="fa fa-close" 
                    [customClass]="'rounded'" 
                    [iconPosition]="'left'"
                    (click)="closeAlert()">
                ></ppl-button>
            </div>
        </div>
        <div class="body">
            <div class="body-content">
                {{ alert?.message }}
            </div>
        </div>
    </div>