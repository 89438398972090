import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzInputModule } from 'ng-zorro-antd/input';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CommonModule, DatePipe } from '@angular/common';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import {
  RADIO_YES_NO_OPTIONS,
  STATIC_JSON_DATA_PATH,
} from '../../../../shared/constants/static-data.constants';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { SectionsService } from '../../../../services/sections.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { Router } from '@angular/router';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { AccordionComponent } from '../../../../shared/components/accordion/accordion.component';
import { AuthUtilizationComponent } from '../auth-utilization/auth-utilization.component';
import { AuthTimesheetsComponent } from '../auth-timesheets/auth-timesheets.component';
import { ListTableComponent } from '../../../../shared/components/list-table/list-table.component';
import { TableRowActionsModel } from '../../../../shared/interfaces/list.interface';
import { TableRowActions } from '../../../../shared/interfaces/actions';
import { GridAction } from '../../../../shared/components/list-table/grid-action.model';
import { LookupService } from '../../../../services/lookup.service';
import { ListService } from '../../../../shared/services/list.service';
import { AuthorizationsDetailsService } from '../../../../services/authorizations-service.service';
import { performValidation } from '../../../../shared/utils/validation.util';
import { LoggerService } from '../../../../shared/services/logger.service';
// import { performValidation } from '../../../shared/utils/validation.util';

@Component({
  selector: 'ppl-auth-forms',
  standalone: true,
  imports: [
    ButtonComponent,
    NzFormModule,
    ReactiveFormsModule,
    FormsModule,
    NzCardModule,
    NzInputModule,
    NzToolTipModule,
    CommonModule,
    NzDatePickerModule,
    NzToolTipModule,
    NzSelectModule,
    NzButtonModule,
    NzCollapseModule,
    ListTableComponent,
  ],
  providers: [ListService],
  templateUrl: './auth-forms.component.html',
  styleUrl: './auth-forms.component.css',
})
export class AuthFormsComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  authorizationsForm!: FormGroup;
  radioOptions = RADIO_YES_NO_OPTIONS;
  providerTypes: any;
  pageTitle = 'Add Authorization';
  mode = 'read';
  savedData: any = {};
  isNew = true;
  isConsumerSelected = false;
  showConsumersList = false;
  programName!: string;
  columnsToBeHidden: any;
  sharedDataKey: any;
  actionsToBeHidden: any;
  lazyLoad = true;
  cols: any[] = [];
  authCols: any[] = [];
  flow: string | null = '';
  selectedConsumer: any;
  selectedAuth: any;
  showSearchError = false;
  mcoEntries: any;
  showAddedAuths = false;
  modifiersList: any;
  serviceCodesList: any;
  diagnosisCodesList: any;
  authsInSession: any[] = [];

  actionButtons: TableRowActionsModel = new TableRowActions({ add: true });

  @Input() formState = 'edit';
  @Input() authData: any;
  @Output() authSubmitted = new EventEmitter<boolean>();
  @Output() authUpdate = new EventEmitter<boolean>();

  hiosList = [
    {
      lkpValueName: 'Hios 1',
      lkpValueCode: 'm1',
    },
    {
      lkpValueName: 'Hios 2',
      lkpValueCode: 'm1',
    },
  ];

  planIdList = [
    {
      lkpValueName: 'Plan 1',
      lkpValueCode: 'm1',
    },
    {
      lkpValueName: 'Plan 2',
      lkpValueCode: 'm1',
    },
  ];

  svcAdminList: any;

  unitTypeList: any;

  gridData = [
    {
      consumerFirstName: 'Nikhil',
      consumerLastName: 'Peter',
      medicaid: 'PRV-NY-10000722',
      pplId: 'PPL-NY-10000733',
    },
    {
      consumerFirstName: 'Venkat',
      consumerLastName: 'Paul',
      medicaid: 'PRV-NY-10000745',
      pplId: 'PPL-NY-10000793',
    },
  ];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private lookupService: LookupService,
    private sectionsService: SectionsService,
    private listService: ListService,
    private authorizationService: AuthorizationsDetailsService,
    private datePipe: DatePipe,
    private logger: LoggerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.authorizationsForm = this.fb.group({
      consumerFirstName: [''],
      consumerLastName: [''],
      medicaid: [''],
      consumerPPLId: [''],
      pplAuth: [''],
      clientAuth: [''],
      serviceCode: [null],
      diagnosisId: [null],
      hiosIdLkp: [null],
      planId: [null],
      serviceAdminLkp: [null],
      modifierCode1: [null],
      units: [''],
      unittypeLkp: [null],
      startDate: [''],
      endDate: [''],
      orgName: [null],
    });

    const program = JSON.parse(localStorage.getItem('selected_program')!);
    this.flow = localStorage.getItem('lead_submission_flow');
    this.selectedAuth = localStorage.getItem('selected_lead');
    this.programName = program.program_code;
    this.secData = this.sectionsService.getSectionData(
      program.program_code,
      'payroll'
    );
    this.getLookupValues();
    console.log('sectionData', this.secData);
    this.providerTypes = this.getLookup('ProviderType');
    this.svcAdminList = this.getLookup('Waiver');
    this.unitTypeList = this.getLookup('Utype');
    this.lookupService
      .getOrgData(program.program_code)
      .subscribe((data: any) => {
        console.log('data', data);
        if (data.status == 200) {
          this.mcoEntries = data.responsedata.filter(
            (item: any) => item.orgTypeLookup === 'MCO'
          );
          console.log('mcoEntries', this.mcoEntries);
        }
      });
    this.getGridHeaders();
    this.getAuthGridHeaders();
    this.authorizationsForm
      .get('svcAdministration')
      ?.valueChanges.subscribe((value) => {
        this.authorizationsForm.get('orgName')?.reset();
          this.authorizationsForm.get('hiosIdLkp')?.reset();
        this.authorizationsForm.get('planId')?.reset();
        if (value !== 'Health Plan') {
          this.authorizationsForm.get('orgName')?.disable();
          this.authorizationsForm.get('hiosIdLkp')?.disable();
          this.authorizationsForm.get('planId')?.disable();
        } else {
          this.authorizationsForm.get('orgName')?.enable();
          this.authorizationsForm.get('hiosIdLkp')?.enable();
          this.authorizationsForm.get('planId')?.enable();
        }
      });
    if (this.mode === 'edit') {
      this.disableFieldsForEdit();
      this.getSelectedAuthDetails();
    }
  }

  getLookupValues(): void {
    this.modifiersList =
      this.lookupService.getTasLookupValue('modifierDetailList');
    this.serviceCodesList = this.lookupService.getTasLookupValue(
      'servicecodeDetailList'
    );
    this.diagnosisCodesList = this.lookupService.getTasLookupValue(
      'diagnosisDetailList'
    );
    console.log('modifiers', this.modifiersList);
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes['formState'] && changes['formState'].currentValue) {
        this.mode = changes['formState'].currentValue;
      }
    } catch (e) {
      console.log('Error in getting section data and updating latest info', e);
    }
  }

  getSelectedAuthDetails() {
    // API call to fetch the details and patch form
    console.log('auth data', this.authData);
    const formValuesToPatch = {
      consumerFirstName: this.authData?.consumerFirstName,
      consumerLastName: this.authData?.consumerLastName,
      medicaid: this.authData?.medicaid,
      consumerPPLId: this.authData?.consumerPPLId,
      pplAuth: this.authData?.pplAuth,
      clientAuth: this.authData?.clientAuth,
      serviceCode: this.authData?.serviceCodeId,
      diagnosisId: this.authData?.diagnosisId,
      hiosIdLkp: this.authData?.hiosIdLkp,
      planId: this.authData?.planIdLkp,
      serviceAdminLkp: this.authData?.serviceAdminLkp,
      modifierCode1: this.authData?.modifier1,
      units: this.authData?.units,
      unittypeLkp: this.authData?.unittypeLkp,
      startDate: this.authData?.startDate,
      endDate: this.authData?.endDate,
      orgName: this.authData?.orgId,
    }

    this.authorizationsForm.patchValue(formValuesToPatch);
  }

  disableFieldsForEdit() {
    const fieldsToKeepEnabled = ['units', 'unittypeLkp', 'startDate', 'endDate'];
    const formControls = Object.keys(this.authorizationsForm.controls);

    formControls.forEach((control) => {
      if (!fieldsToKeepEnabled.includes(control)) {
        this.authorizationsForm.get(control)?.disable();
      }
    });
  }

  onConsumerSearch() {
    const formData = this.authorizationsForm.value;
    const from = '';
    console.log('formData', formData);
    this.showSearchError = !(
      formData.consumerFirstName.trim() !== '' ||
      formData.consumerLastName.trim() !== '' ||
      formData.medicaid.trim() !== '' ||
      formData.consumerPPLId.trim() !== ''
    );
    // this.showConsumersList = !this.showSearchError;
    if (!this.showSearchError) {
      const payload = this.buildRequestPayload();
      console.log();
      this.listService.getListData(payload, from).subscribe((data: any) => {
        console.log('search data', data);
        this.gridData = data.responsedata;
        this.showConsumersList = true;
      });
    }
  }

  buildRequestPayload(event?: any) {
    const formData = this.authorizationsForm.value;
    const payload: any = {
      pageName: 'consumer-search',
      programCode: this.programName,
      condition: 'and',
      recordsPerPage: 20,
      startingPageNumber: event ? (event?.startingPageNumber).toString() : '1',
      searchParam: [],
      orderByColumn: [],
      orderBy: [],
    };
    let searchParams = [];
    if (formData.consumerFirstName) {
      searchParams.push({
        jsonPath: 'consumerFirstName',
        value: formData.consumerFirstName,
        operator: 'equal',
      });
    }

    if (formData.consumerLastName) {
      searchParams.push({
        jsonPath: 'consumerLastName',
        value: formData.consumerLastName,
        operator: 'equal',
      });
    }

    if (formData.medicaid) {
      searchParams.push({
        jsonPath: 'medicaid',
        value: formData.medicaid,
        operator: 'equal',
      });
    }

    if (formData.consumerPPLId) {
      searchParams.push({
        jsonPath: 'consumerPPLId',
        value: formData.consumerPPLId,
        operator: 'equal',
      });
    }
    payload.searchParam = searchParams;
    return payload;
  }

  async getGridHeaders() {
    try {
      if (this.flow) {
        const tempCols = await this.sectionsService.getJsonConfigurationData(
          STATIC_JSON_DATA_PATH.AUTHORIZATIONS_CONSUMER_LIST_HEADERS,
          this.flow
        );
        console.log(tempCols.columns);
        this.cols = tempCols.columns;
      }
    } catch (e) {}
  }

  async getAuthGridHeaders() {
    try {
      if (this.flow) {
        const tempCols = await this.sectionsService.getJsonConfigurationData(
          STATIC_JSON_DATA_PATH.AUTHORIZATIONS_LIST_HEADERS,
          this.flow
        );
        console.log(tempCols.columns);
        this.authCols = tempCols.columns;
      }
    } catch (e) {}
  }

  onConsumerSelect() {}

  action(event: GridAction) {
    console.log('event');
    if (event && event.action === 'add') {
      console.log(event.data);
      this.selectedConsumer = event.data;
      this.isConsumerSelected = true;
      this.mode = 'add';
      this.showConsumersList = false;
    }
  }

  onCancelUser() {
    this.isConsumerSelected = false;
    this.selectedConsumer = {};
    this.authorizationsForm.reset();
    this.showAddedAuths = false;
    this.authsInSession = [];
  }

  onAdd() {
    console.log('Adding!!');
    this.saveAuthDetails('submit');
  }

  saveAuthDetails(submitMode: string) {
    const formData = this.authorizationsForm.value;
    const data = { ...this.authorizationsForm.value };

    data.startDate = this.dateFormatter.formatToString(data.startDate);

    if (data.endDate) {
      data.endDate = this.dateFormatter.formatToString(data.endDate);
    }
    try {
      this.validationErrors = performValidation(data, this.secData, 'Submit');
      console.log('validationErrors', this.validationErrors);
    } catch (e) {
      this.logger.debug('Error in validations', e);
    }
    if (Object.keys(this.validationErrors).length === 0) {
      const payload = {
        clientAuth: formData.clientAuth,
        pplAuth: formData.pplAuth,
        serviceCode: formData.serviceCode,
        modifier1: formData.modifierCode1,
        diagnosisId: formData.diagnosisId,
        units: formData.units,
        startDate: this.datePipe.transform(formData.startDate, 'yyyy-MM-dd'),
        endDate: this.datePipe.transform(formData.endDate, 'yyyy-MM-dd'),
        orgId: formData.orgName,
        participantId: this.selectedConsumer.participantId,
        billableRate: 0,
        hiosIdLkp: formData.hiosIdLkp,
        planIdLkp: formData.planId,
        serviceAdminLkp: formData.svcAdministration,
        unittypeLkp: formData.unitType,
      };
      console.log('payload for save', payload);
      this.authorizationService
        .saveAuthorization(this.programName, payload, true)
        .subscribe((data: any) => {
          console.log('saved auth', data);
          if (submitMode === 'submit') {
            this.authSubmitted.emit(true);
            this.showAddedAuths = false;
          } else {
            this.mode = 'add';
            this.authorizationsForm.reset();
            this.authsInSession = [...this.authsInSession, data.responsedata];
            console.log(this.authsInSession);
            this.showAddedAuths = true;
          }
        });
    }
  }

  onAddMoreAuths() {
    this.saveAuthDetails('Add more');
  }

  onCancelUpdate() {
    this.validationErrors = {};
    this.authorizationsForm.reset();
    this.authUpdate.emit(true);
  }

  onUpdate() {
    console.log('Updating!!');
    const formData = this.authorizationsForm.value;
    const payload = {
      authorizationId: this.selectedAuth,
      units: formData.units,
      dollars: formData.dollars,
      startDate: this.datePipe.transform(formData.startDate, 'yyyy-MM-dd'),
      endDate: this.datePipe.transform(formData.endDate, 'yyyy-MM-dd'),
    };
    this.authorizationService
      .updateAuthorizationDetails(this.programName, this.selectedAuth, payload)
      .subscribe((data: any) => {
        console.log('updated auth', data);
        this.onCancelUpdate();
      });
  }

  viewerValueChange(value: string) {
    const viewerValue = [value][0];
  }
}
