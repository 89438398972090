<div class="ppl-container-body">
  <div class="ppl-container">
    <div class="ppl-content-container" style="padding: 8px; max-height: calc(100vh - 140px); box-sizing: border-box;">
      <div class="line1">
        <div class="action">
          <ppl-button [label]="'Back'" icon="fa fa-chevron-left" (click)="goBack()"
            [customClass]="'primary-button semi-rounded'" [iconPosition]="'left'"></ppl-button>
        </div>
      </div>
      <div class="line2">
        {{pageTitle}}
      </div>
      <div style="width: 100%">
        <ppl-accordion [title]="'Basic Information'" [isOpen]="true">
          <div class="wiz-body">
            <div class="ppl-mt24">
              <div class="custom-row">
                @if((mode==="edit") && !this.isNew){
                <ppl-button label="Cancel" icon="fa fa-ban" [customClass]="'alt-button'" [iconPosition]="'left'"
                  (click)="onCancel()"></ppl-button>
                <ppl-button label="Save" icon="fa fa-floppy-o" [customClass]="'alt-button'" [iconPosition]="'left'"
                  (click)="onUpdate()"></ppl-button>
                }
                @if(mode==="read" && !this.isNew){
                <ppl-button label="Edit" icon="fa fa-pencil" [customClass]="'ghost-button'" [iconPosition]="'left'"
                  (click)="onEdit()"></ppl-button>
                }
              </div>
              @if(mode==="edit"){
              <form nz-form [formGroup]="participantProviderRates" class="ppl-p0 form-container">

                @if(isAttrAvailable('rate')){
                <nz-form-item nz-col class="ppl-form-item nz-padding-left">
                  <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="rate"
                    class="ppl-form-label">{{getAttributes('rate').label}}
                    @if(getAttributes('rate').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('rate')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      <input nz-input formControlName="rate" id="rate"
                        placeholder="{{getAttributes('rate').placeholder}}"
                        [ngClass]="{'error': validationErrors['rate'] }" />
                    </div>
                    @if (validationErrors['rate']) {
                    <span class="pl-1 error-message">{{validationErrors['rate']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }

                @if(isAttrAvailable('code')){
                <nz-form-item nz-col class="ppl-form-item nz-padding-left">
                  <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="code"
                    class="ppl-form-label">{{getAttributes('code').label}}
                    @if(getAttributes('code').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('code')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      <nz-select formControlName="serviceCodeDetailId" nzMode='default' class="ppl-select" id="code"
                        nzPlaceHolder="Select Service Code" nzShowSearch nzAllowClear
                        [ngClass]="{'error': validationErrors['code'] }" [nzDisabled]="">
                        @for(opt of serviceCodes; track $index){
                        <nz-option [nzLabel]="opt.code" [nzValue]="opt.serviceCodeDetailId"></nz-option>
                        }
                      </nz-select>
                    </div>
                    @if (validationErrors['code']) {
                    <span class="pl-1 error-message">{{validationErrors['code']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }

                @if(isAttrAvailable('modifierCode1')){
                <nz-form-item nz-col class="ppl-form-item nz-padding-left">
                  <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="modifierCode1"
                    class="ppl-form-label">{{getAttributes('modifierCode1').label}}
                    @if(getAttributes('modifierCode1').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('modifierCode1')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      <nz-select formControlName="modifierDetailId" nzMode='default' class="ppl-select"
                        id="modifierCode1" nzPlaceHolder="Select Modifier" nzShowSearch nzAllowClear
                        [ngClass]="{'error': validationErrors['modifierCode1'] }">
                        @for(opt of modifiersList; track $index){
                        <nz-option [nzLabel]="opt.code" [nzValue]="opt.modifierDetailId"></nz-option>
                        }
                      </nz-select>
                    </div>
                    @if (validationErrors['modifierCode1']) {
                    <span class="pl-1 error-message">{{validationErrors['modifierCode1']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }

                @if(isAttrAvailable('region')){
                <nz-form-item nz-col class="ppl-form-item">
                  <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="region"
                    class="ppl-form-label">{{getAttributes('region').label}}
                    @if(getAttributes('region').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('region')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      <nz-select (ngModelChange)="updateCountiesList($event)" formControlName="regionId"
                        nzMode='default' class="ppl-select" nzId="region" nzShowSearch nzAllowClear
                        [ngClass]="{'error': validationErrors['region'] }" [nzDisabled]="">
                        @for(opt of regions; track $index){
                        <nz-option [nzLabel]="opt.regionName" [nzValue]="opt.payRegionId"></nz-option>
                        }
                      </nz-select>
                    </div>
                    @if (validationErrors['region']) {
                    <span class="pl-1 error-message">{{validationErrors['region']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }

                @if(isAttrAvailable('county')){
                <nz-form-item nz-col class="ppl-form-item">
                  <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="county"
                    class="ppl-form-label">{{getAttributes('county').label}}
                    @if(getAttributes('county').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('county')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      <nz-select formControlName="countyId" nzMode='default' class="ppl-select" nzId="county"
                        nzShowSearch nzAllowClear [ngClass]="{'error': validationErrors['county'] }" [nzDisabled]="">
                        @for(opt of counties; track $index){
                        <nz-option [nzLabel]="opt.regionCountyName" [nzValue]="opt.payRegionCountyId"></nz-option>
                        }
                      </nz-select>
                    </div>
                    @if (validationErrors['county']) {
                    <span class="pl-1 error-message">{{validationErrors['county']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }

                @if(isAttrAvailable('fromdate')){
                <nz-form-item nz-col class="ppl-form-item nz-padding-left">
                  <nz-form-label [nzSm]="6" [nzXs]="24" zFor="fromdate"
                    class="ppl-form-label">{{getAttributes('fromdate').label}}
                    @if(getAttributes('fromdate').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('fromdate')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      <nz-date-picker formControlName="fromDate" id="fromdate" [nzFormat]="'MM/dd/yyyy'"
                        [nzPlaceHolder]="getAttributes('fromdate').placeholder" [nzDisabledDate]="disabledStartDate"
                        [ngClass]="{'error': validationErrors['dob'] }"></nz-date-picker>
                    </div>
                    @if (validationErrors['fromdate']) {
                    <span class="pl-1 error-message">{{validationErrors['fromdate']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }
                @if(isAttrAvailable('todate')){
                <nz-form-item nz-col class="ppl-form-item nz-padding-left">
                  <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="todate"
                    class="ppl-form-label">{{getAttributes('todate').label}}
                    @if(getAttributes('todate').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('todate')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      <nz-date-picker formControlName="toDate" id="todate" [nzFormat]="'MM/dd/yyyy'"
                        [nzPlaceHolder]="getAttributes('todate').placeholder" [nzDisabledDate]="disabledEndDate"
                        [ngClass]="{'error': validationErrors['dob'] }"></nz-date-picker>
                    </div>
                    @if (validationErrors['todate']) {
                    <span class="pl-1 error-message">{{validationErrors['todate']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }
              </form>

              @if(isNew){
              <div class="custom-row">
                <ppl-button [label]="'Cancel'" [icon]="'fa fa-close'" style="margin-right:4px; font-size:0.857rem;"
                  [customClass]="'ghost-button'" [iconPosition]="'right'" (click)="goBack()"></ppl-button>
                <ppl-button label="Submit" icon="fa fa-check-circle" [customClass]="'primary-button'"
                  [iconPosition]="'right'" (click)="onAdd()"></ppl-button>
              </div>
              }
              }
              @else if (mode==="read") {
              <div nz-row>
                @if(isAttrAvailable('code')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("code").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.serviceCode }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('region')){
                  <div nz-col nzSpan="12">
                    <div nz-row>
                      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("region").label }}
                      </div>
                      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.regionName }}</div>
                    </div>
                  </div>
                }
                @if(isAttrAvailable('county')){
                  <div nz-col nzSpan="12">
                    <div nz-row>
                      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("county").label }}
                      </div>
                      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.countyName }}</div>
                    </div>
                  </div>
                  }
                @if(isAttrAvailable('modifierCode1')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("modifierCode1").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.modifierCode }}</div>
                  </div>
                </div>
                }

                @if(isAttrAvailable('fromdate')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("fromdate").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.fromDate }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('todate')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("todate").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.toDate }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('rate')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("rate").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.rate }}</div>
                  </div>
                </div>
                }
              </div>
              }
            </div>
          </div>
        </ppl-accordion>
      </div>
    </div>
  </div>
</div>