@if (isAuthenticated) {
  <!-- Header -->
  <ppl-header></ppl-header> 

  <!-- <div class="ppl-container">
    
    <div class="containerHeight"> -->
      <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
      @if (!isIframe) {
        <main>
          <router-outlet></router-outlet>
        </main>
      }
    <!-- </div> -->
    <ppl-footer></ppl-footer>
    @if(showLoader === true) {
    <ppl-loader></ppl-loader>
    }

  <ppl-alertbox 
    [(isAlertVisible)]="alertDetails.isVisible" 
    [message]="alertDetails.message" 
    [title]="alertDetails.title"
    [success]="false"
    (onClose)="alertDetails.isVisible = false"></ppl-alertbox>

    <ppl-toaster></ppl-toaster>
  <!-- </div> -->
  <ppl-dialog></ppl-dialog>
}
