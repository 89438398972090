{
    "participant_info": {
        "sectionId": "participant_info",
        "header": "Personal Assistant Interest Information",
        
        "available_actions": null,
        "attributes": [
            {
                "attribute_ID": "fname",
                "fieldName": "fname",
                "label": "First Name",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter your first name",
                "isAvailable": "Y",
                "placeholder": "First Name"
            },
            {
                "attribute_ID": "mname",
                "fieldName": "mname",
                "label": "Middle Name",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter your middle name",
                "isAvailable": "Y",
                "placeholder": "Middle Name"
            },
            {
                "attribute_ID": "lname",
                "fieldName": "lname",
                "label": "Last Name",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter your last name",
                "isAvailable": "Y",
                "placeholder": "Last Name"
            },
            {
                "attribute_ID": "maiden_name",
                "fieldName": "maiden_name",
                "label": "Maiden or Previous Name",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter your maiden name",
                "isAvailable": "Y",
                "placeholder": "Maiden or Previous Name"
            },
            {
                "attribute_ID": "dob",
                "fieldName": "dob",
                "label": "Date of Birth",
                "type": "datepicker",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please select your date of birth",
                "isAvailable": "Y",
                "placeholder": "Select Date"
            },
            {
                "attribute_ID": "gender",
                "fieldName": "gender",
                "label": "Gender",
                "type": "radio",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select your gender",
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "state",
                "fieldName": "state",
                "label": "Residence State",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Residence State",
                "isAvailable": "Y",
                "placeholder": ""
            },
            {
                "attribute_ID": "isProvidingServices",
                "fieldName": "isProvidingServices",
                "label": "Are you providing CDPAP Services?",
                "type": "radio",
                "mandatory": "N",
                "validator": null,
                "tooltip": "",
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "SSN",
                "fieldName": "SSN",
                "label": "Social Security Number",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter your social security number",
                "isAvailable": "Y",
                "placeholder": "SSN"
            },
            {
                "attribute_ID": "CIN",
                "fieldName": "CIN",
                "label": "CIN",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter your MCI number ",
                "isAvailable": "N",
                "placeholder": "Medicaid Number"
            },
            {
                "attribute_ID": "mcoOrgName",
                "fieldName": "mcoOrgName",
                "label": "MCO",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select MCO",
                "isAvailable": "N",
                "placeholder": "MCO"
            },
            {
                "attribute_ID": "fiOrgName",
                "fieldName": "fiOrgName",
                "label": "FI",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select FI",
                "isAvailable": "N",
                "placeholder": "FI"
            },
            {
                "attribute_ID": "showOnDirectory",
                "fieldName": "showOnDirectory",
                "label": "Do you want to be listed in Personal Assistant Directory?",
                "type": "radio",
                "mandatory": "N",
                "validator": null,
                "tooltip": "",
                "isAvailable": "N"
            },
            {
                "attribute_ID": "servicesRendered",
                "fieldName": "servicesRendered",
                "label": "Services that you can render",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select the services",
                "isAvailable": "Y",
                "placeholder": "Services"
            }
        ],
        "validations": [
            {
                "whatToCheck": "atLeastXEntered",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": ["fname"],
                        "functionParams": {
                          "ErrMsg": "Please enter First Name."
                        }
                      },
                      {
                        "validatorid": 2,
                        "attribute_ID": ["lname"],
                        "functionParams": {
                          "ErrMsg": "Please enter Last Name"
                        }
                      },
                      {
                        "validatorid": 3,
                        "attribute_ID": ["dob"],
                        "functionParams": {
                          "ErrMsg": "Please select Date of Birth"
                        }
                      }
                ]
            },
            {
                "whatToCheck": "checkFieldValue",
                "clientOrServer": "C",
                "attributes": [ {
                    "validatorid": 1,
                    "attribute_ID": [
                        "fname"
                    ],
                    "functionParams": {
                        "isNullAllowed": "N",
                        "Format": "^[A-zÀ-ÿ\\s'\\-.]+$",
                        "ErrMsg": "Valid characters are A-z, periods (.), hyphens (-), spaces, and apostrophes (')"
                    }
                },
                {
                    "validatorid": 2,
                    "attribute_ID": [
                        "mname"
                    ],
                    "functionParams": {
                        "isNullAllowed": "N",
                        "Format": "^[A-zÀ-ÿ\\s'\\-.]+$",
                        "ErrMsg": "Valid characters are A-z, periods (.), hyphens (-), spaces, and apostrophes (')"
                    }
                },
                {
                    "validatorid": 3,
                    "attribute_ID": [
                        "lname"
                    ],
                    "functionParams": {
                        "isNullAllowed": "N",
                        "Format": "^[A-zÀ-ÿ\\s'\\-.]+$",
                        "ErrMsg": "Valid characters are A-z, periods (.), hyphens (-), spaces, and apostrophes (')"
                    }
                },
                {
                    "validatorid": 4,
                    "attribute_ID": [
                        "maiden_name"
                    ],
                    "functionParams": {
                        "isNullAllowed": "N",
                        "Format": "^[A-zÀ-ÿ\\s'\\-.]+$",
                        "ErrMsg": "Valid characters are A-z, periods (.), hyphens (-), spaces, and apostrophes (')"
                    }
                },
                {
                    "validatorid": 6,
                    "attribute_ID": [
                        "dob"
                    ],
                    "functionParams": {
                       "isNullAllowed": "N",
                        "CheckDate": true,
                        "ErrMsg": "Date of birth cannot be today or in the future."
                    }
                }]
            },
            {
                "whatToCheck": "conditionalCheck",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "functionParams": {
                          "basecondition": ["isProvidingServices"],
                          "baseconditionValue": ["No"],
                          "condtionalAttributes": ["SSN"],
                          "ErrMsg": "Please enter SSN"
                        }
                      },
                      {
                        "validatorid": 2,
                        "functionParams": {
                        "basecondition": ["isProvidingServices"],
                        "baseconditionValue": ["Yes"],
                        "condtionalAttributes": ["servicesRendered"],
                        "ErrMsg": "Please select services rendered"
                        }
                      }
                ]
            },
            {
                "whatToCheck": "customValidator",
                "clientOrServer": "C",
                "attributes": [
                 {
                     "validatorid": 1,
                     "attribute_ID": [
                         "SSN"
                     ],
                     "functionParams": {
                         "validatorFunction" : "validateSSN",
                         "ErrMsg": "Please Enter Valid SSN in the format xxx-xx-xxxx"
                     }
                 }
                ]
            }
        ],
        "actions": [
            {
                "Name": "Previous",
                "Validate": null
            },
            {
                "Name": "Next",
                "Validate": [
                    {
                        "checkType": "atLeastXEntered",
                        "validatorid": [
                            1, 2, 3
                        ]
                    },
                    {
                        "checkType": "checkFieldValue",
                        "validatorid": [
                            1, 2, 3, 4, 5, 6
                        ]
                    },
                    {
                        "checkType": "conditionalCheck",
                        "validatorid": [
                            1, 2
                        ]
                    },
                    {
                        "checkType": "customValidator",
                        "validatorid": [
                            1
                        ]
                    }
                ]
            }
        ]
    },
    "contact_details": {
        "sectionId": "contact_details",
        "header": "Contact Details",
        "text_1": "Please share your contact details",
        "text_2": null,
        "text_3": null,
        "available_actions": null,
        "attributes": [
            {
                "attribute_ID": "preferredMethod",
                "fieldName": "preferredMethod",
                "label": "What is the preferred contact method for PPL to contact you?",
                "type": "radio",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select the preferred contact method",
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "email",
                "fieldName": "email",
                "label": "Email Address",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter your email address",
                "isAvailable": "Y",
                "placeholder": "Email Address"
            },
            {
                "attribute_ID": "altPhone",
                "fieldName": "altPhone",
                "label": "Telephone Number",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter telephone or alternate phone number",
                "isAvailable": "Y",
                "placeholder": "10-digit Telephone Number"
            },
            {
                "attribute_ID": "mobile",
                "fieldName": "mobile",
                "label": "Mobile Number",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter your 10 digit mobile number",
                "isAvailable": "Y",
                "placeholder": "10-digit Mobile Number"
            },
            {
                "attribute_ID": "permanentAddress",
                "fieldName": "permanentAddress",
                "label": "Physical Address",
                "type": "form",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please select your Physical Address",
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "mailingAddress",
                "fieldName": "mailingAddress",
                "label": "Mailing Address",
                "type": "form",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter your Mailing Address",
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "street1",
                "fieldName": "street1",
                "label": "Address Type",
                "type": "text",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please specify the address type",
                "isAvailable": "Y"
              },
              {
                "attribute_ID": "city",
                "fieldName": "city",
                "label": "City",
                "type": "text",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter the city",
                "isAvailable": "Y"
              },
              {
                "attribute_ID": "countyOrRegion",
                "fieldName": "countyOrRegion",
                "label": "County",
                "type": "text",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please specify the county",
                "isAvailable": "Y"
              },
              {
                "attribute_ID": "zipCode",
                "fieldName": "zipCode",
                "label": "ZipCode",
                "type": "text",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter the Zip Code",
                "isAvailable": "Y"
              },
              {
                "attribute_ID": "state",
                "fieldName": "state",
                "label": "State",
                "type": "text",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter the state",
                "isAvailable": "Y"
              }
        ],
        "validations": [
            {
                "whatToCheck": "atLeastXEntered",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "email","mobile" 
                        ],
                        "functionParams": {
                            "minNeeded": 1,
                            "ErrMsg": "Please Enter email/mobile"
                        }
                    },
                    {
                        "validatorid": 3,
                        "attribute_ID": [
                            "city"
                        ],
                        "functionParams": {
                            "ErrMsg": "Please enter City"
                        }
                    },
                    {
                        "validatorid": 4,
                        "attribute_ID": [
                            "street1"
                        ],
                        "functionParams": {
                            "ErrMsg": "Please enter Address"
                        }
                    },
                    {
                        "validatorid": 6,
                        "attribute_ID": [
                            "zipCode"
                        ],
                        "functionParams": {
                            "ErrMsg": "Please enter Zip Code"                       
                        }
                    },
                    {
                        "validatorid": 7,
                        "attribute_ID": [
                            "state"
                        ],
                        "functionParams": {
                            "ErrMsg": "Please enter State"                       
                        }
                    }
                ]
            },
            {
                "whatToCheck": "checkFieldValue",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "email"
                        ],
                        "functionParams": {
                            "isNullAllowed": "N",
                            "Format": "^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,3}$",
                            "ErrMsg": "Please Enter valid email address"
                        }
                    },
                    {
                        "validatorid": 2,
                        "attribute_ID": [
                            "mobile"
                        ],
                        "functionParams": {
                           "isNullAllowed": "N",
                            "Format": "^\\d{10}$",
                            "ErrMsg": "Please enter mobile number in the format 9999999999"
                        }
                    },
                    {
                        "validatorid": 3,
                        "attribute_ID": [
                            "altPhone"
                        ],
                        "functionParams": {
                           "isNullAllowed": "N",
                            "Format": "^\\d{10}$",
                            "ErrMsg": "Please enter phone number in the format 9999999999"
                        }
                    },
                    {
                        "validatorid": 4,
                        "attribute_ID": [
                            "city"
                        ],
                        "functionParams": {
                           "isNullAllowed": "N",
                            "Format": "^[A-zÀ-ÿ\\s'\\-.]+$",
                            "ErrMsg": "Valid characters are A-z, periods (.), hyphens (-), spaces, and apostrophes (')"
                        }
                    },
                    {
                        "validatorid": 5,
                        "attribute_ID": [
                            "street1"
                        ],
                        "functionParams": {
                           "isNullAllowed": "N",
                            "Format": "^(?=.*[A-Za-zÀ-ÿ])[A-Za-zÀ-ÿ0-9.'\\-\\@\\s]*$",
                            "ErrMsg": "Please enter a valid address."
                        }
                    }
                ]
            },
            {
                "whatToCheck": "conditionalCheck",
                "clientOrServer": "C",
                "attributes": [
                  
                ]
            }
        ],
        "actions": [
            {
                "Name": "Previous",
                "Validate": null
            },
            {
                "Name": "Next",
                "Validate": [
                    {
                        "checkType": "atLeastXEntered",
                        "validatorid": [
                            1, 2, 3, 4, 5, 6, 7
                        ]
                    },
                    {
                        "checkType": "checkFieldValue",
                        "validatorid": [
                            1, 2, 3, 4, 5
                        ]
                    },
                    {
                        "checkType": "conditionalCheck",
                        "validatorid": [
                            1
                        ]
                    }
                ]
            }
        ]
    },
    "service_details": {
        "sectionId": "service_details",
        "header": "Consumer Details",
        "text_1": "Please share your service details",
        "text_2": null,
        "text_3": null,
        "available_actions": null,
        "attributes": [
            {
                "attribute_ID": "profLicences",
                "fieldName": "profLicences",
                "label": "Professional Licenses",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select your Professional Licenses",
                "isAvailable": "N",
                "placeholder": "Professional Licenses "
            },
            {
                "attribute_ID": "filesUploaded",
                "fieldName": "filesUploaded",
                "label": " ",
                "type": "file-upload",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please upload your supportive documents",
                "isAvailable": "N",
                "placeholder": ""
            },
            {
                "attribute_ID": "servicesRendered",
                "fieldName": "servicesRendered",
                "label": "Services that you can render",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select the services",
                "isAvailable": "N",
                "placeholder": "Services"
            },
            {
                "attribute_ID": "participantInfo",
                "fieldName": "participantInfo",
                "label": "Please provide Consumer(s) details",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "availabilityDays",
                "fieldName": "availabilityDays",
                "label": "Availability Day(s) of Week",
                "type": "checkbox",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select availability days",
                "isAvailable": "N",
                "placeholder": "Availability Day(s) of Week"
            },
            {
                "attribute_ID": "availabilityTiming",
                "fieldName": "availabilityTiming",
                "label": "Availability Timings",
                "type": "timepicker",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select availability timings",
                "isAvailable": "N",
                "placeholder": "Select Time"
            },
            {
                "attribute_ID": "isAvailableForEmergencies",
                "fieldName": "isAvailableForEmergencies",
                "label": "Available for Emergencies",
                "type": "radio",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Available for Emergencies",
                "isAvailable": "N"
            },
            {
                "attribute_ID": "dayEmergencyAvailablity",
                "fieldName": "dayEmergencyAvailablity",
                "label": "Day",
                "type": "timepicker",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Availability for Day Emergencies",
                "isAvailable": "N",
                "placeholder": "Select Time"
            },
            {
                "attribute_ID": "nightEmergencyAvailablity",
                "fieldName": "nightEmergencyAvailablity",
                "label": "Night",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Availability for Night Emergencies",
                "isAvailable": "N",
                "placeholder": "Select time"
            },
            {
                "attribute_ID": "distanceWillingToTravel",
                "fieldName": "distanceWillingToTravel",
                "label": "Distance willing to travel in miles",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter distance willing to travel in miles",
                "isAvailable": "N",
                "placeholder": "Distance willing to travel in miles"
            }
        ],
        "validations": [
            {
                "whatToCheck": "atLeastXEntered",
                "clientOrServer": "C",
                "attributes": [
                    
                ]
            },
            {
                "whatToCheck": "checkFieldValue",
                "clientOrServer": "C",
                "attributes": [{
                    "validatorid": 1,
                    "attribute_ID": [
                        "distanceWillingToTravel"
                    ],
                    "functionParams": {
                        "isNullAllowed": "N",
                        "Format": "^[0-9]+$",
                        "ErrMsg": "Please enter valid distance."
                    }
                }
            ]
            },
            {
                "whatToCheck": "conditionalCheck",
                "clientOrServer": "C",
                "attributes": []
            }
        ],
        "actions": [
            {
                "Name": "Previous",
                "Validate": null
            },
            {
                "Name": "Next",
                "Validate": [
                    {
                        "checkType": "atLeastXEntered",
                        "validatorid": [
                            1
                        ]
                    },
                    {
                        "checkType": "checkFieldValue",
                        "validatorid": [
                            1
                        ]
                    },
                    {
                        "checkType": "conditionalCheck",
                        "validatorid": [
                            1
                        ]
                    }
                ]
            }
        ]
    },
    "comm_preferences": {
        "sectionId": "comm_preferences",
        "header": "Communication Preferences",
        "text_1": "Please share your communication preferences",
        "text_2": null,
        "text_3": null,
        "available_actions": null,
        "attributes": [
            {
                "attribute_ID": "contactType",
                "fieldName": "contactType",
                "label": "Who is the primary contact?",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please select the primary contact",
                "isAvailable": "N"
            },
            {
                "attribute_ID": "spokenLanguages",
                "fieldName": "spokenLanguages",
                "label": "Languages Spoken",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select the Spoken Languages",
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "primaryLanguage",
                "fieldName": "primaryLanguage",
                "label": "Preferred Language",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select the Preferred Language",
                "isAvailable": "N"
            },
            {
                "attribute_ID": "formLanguage",
                "fieldName": "formLanguage",
                "label": "Language in which forms should be displayed",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select the Preferred Language for The Form",
                "isAvailable": "N"
            },
            {
                "attribute_ID": "contactFirstName",
                "fieldName": "contactFirstName",
                "label": "First Name",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter first name",
                "isAvailable": "N",
                "placeholder": "First Name"
            },
            {
                "attribute_ID": "contactLastName",
                "fieldName": "contactLastName",
                "label": "Last Name",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter last name",
                "isAvailable": "N",
                "placeholder": "Last Name"
            },
            {
                "attribute_ID": "contactEmail",
                "fieldName": "contactEmail",
                "label": "Email",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter email",
                "isAvailable": "N",
                "placeholder": "Email"
            },
            {
                "attribute_ID": "contactPhone",
                "fieldName": "contactPhone",
                "label": "Phone",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter phone number",
                "isAvailable": "N",
                "placeholder": "Phone"
            },
            {
                "attribute_ID": "daysToContact",
                "fieldName": "daysToContact",
                "label": "Best time to contact",
                "type": "check",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select best time to contact",
                "isAvailable": "N"
            },
            {
                "attribute_ID": "startContactTime",
                "fieldName": "startContactTime",
                "label": "",
                "type": "timepicker",
                "mandatory": "",
                "validator": null,
                "tooltip": "",
                "isAvailable": "",
                "placeholder": "Start time"
            },
            {
                "attribute_ID": "endContactTime",
                "fieldName": "endContactTime",
                "label": "",
                "type": "timepicker",
                "mandatory": "",
                "validator": null,
                "tooltip": "",
                "isAvailable": "",
                "placeholder": "End time"
            },
            {
                "attribute_ID": "language",
                "fieldName": "language",
                "label": "Primary Language",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select your primary language",
                "isAvailable": "N"
            },
            {
                "attribute_ID": "otherDetails",
                "fieldName": "otherDetails",
                "label": "Other Details ",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter other details",
                "isAvailable": "Y",
                "placeholder": "Other Details"
            },
            {
                "attribute_ID": "specialAccomdation",
                "fieldName": "specialAccomdation",
                "label": "Special Accommodations",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select special accomodations",
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "specialAccomdationOther",
                "fieldName": "specialAccomdationOther",
                "label": "Other Accommodations",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter any other special accomodations",
                "isAvailable": "Y"
            }
        ],
        "validations": [
            {
                "whatToCheck": "atLeastXEntered",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                          
                        ],
                        "functionParams": {
                            "ErrMsg": "This field is mandatory."
                        }
                    }
                ]
            },
            {
                "whatToCheck": "checkFieldValue",
                "clientOrServer": "C",
                "attributes": []
            },
            {
                "whatToCheck": "conditionalCheck",
                "clientOrServer": "C",
                "attributes": [ 
                  {
                    "validatorid": 1,
                    "functionParams": {
                      "basecondition": ["specialAccomdation"],
                      "baseconditionValue": ["OTH"],
                      "condtionalAttributes": ["specialAccomdationOther"],
                      "ErrMsg": "This field is mandatory."
                    }
                  }]
            }
        ],
        "actions": [
            {
                "Name": "Previous",
                "Validate": null
            },
            {
                "Name": "Next",
                "Validate": [
                    {
                        "checkType": "atLeastXEntered",
                        "validatorid": [
                          
                        ]
                    },
                    {
                        "checkType": "checkFieldValue",
                        "validatorid": [
                            1
                        ]
                    },
                    {
                        "checkType": "conditionalCheck",
                        "validatorid": [
                            1
                        ]
                    }
                ]
            }
        ]
    }
}