import { Injectable, signal, TemplateRef, WritableSignal } from '@angular/core';
import { v7 as uuid } from 'uuid';

export interface DialogData {
  id: string;
  content: TemplateRef<any>;
  config: DialogConfig;
  templateContext: {
    confirm: () => void;
    cancel: () => void;
  };
}

export interface DialogConfig {
  isVisible?: boolean;
  title?: string;
  message?: string;
  showClose?: boolean;
  onCancel?: () => void; // Callback for cancel action
  onConfirm?: () => void; // Callback for confirm action
  isOkText?: string;
  showButtons?: boolean;
  showOkButton?: boolean;
  showButtonIcons?: boolean;
  rightAlignedButtons?: boolean;
  size?: 'medium' | 'large' | 'x-large' | 'xx-large';
  position?: 'top' | 'center' | 'middle';
  styleClass?: string;
  hideSaveButton?: boolean;
}

const DEFAULT_CONFIG: DialogConfig = {
  isVisible: false,
  title: '',
  message: '',
  showClose: false,
  isOkText: 'OK',
  showButtons: true,
  showOkButton: false,
  showButtonIcons: false,
  rightAlignedButtons: false,
  size: 'medium',
  position: 'top',
  hideSaveButton: false,
};

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private _dialogStack: WritableSignal<DialogData[]> = signal([]);
  public get dialogStack() {
    return this._dialogStack;
  }

  public openDialog(
    content: DialogData['content'],
    customConfig: Partial<DialogConfig>,
    onConfirm: () => void,
    onCancel: () => void
  ): string {
    const dialogId = uuid();
    const config: DialogConfig = { ...DEFAULT_CONFIG, ...customConfig };
    const newDialog: DialogData = {
      id: dialogId,
      content,
      config,
      templateContext: {
        confirm: () => {
          this.closeDialog(dialogId);
          onConfirm();
        },
        cancel: () => {
          this.closeDialog(dialogId);
          onCancel();
        },
      },
    };
    const currentStack = this._dialogStack();
    this._dialogStack.set([...currentStack, newDialog]);
    return dialogId;
  }

  public closeDialog(dialogId: DialogData['id']): void {
    const currentStack = this._dialogStack();
    const updatedStack = currentStack.filter(
      (dialog) => dialog.id !== dialogId
    );
    this._dialogStack.set(updatedStack);
  }
}
