import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/utils/base.component';
import { SectionsService } from '../../../services/sections.service';
import { ENROLLMENT_TYPE_BACKEND_MAPPINGS, STATIC_DATA, PROVIDER_TYPES, RADIO_YES_NO_OPTIONS, STATIC_JSON_DATA_PATH } from '../../../shared/constants/static-data.constants';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { CommonModule } from '@angular/common';
import { PopupDialogComponent } from '../../../shared/components/popup-dialog/popup-dialog.component';
import { NewEnrollmentComponent } from '../../enrollment/new-enrollment/new-enrollment.component';
import { FlowDialogComponent } from '../../../shared/components/flow-dialog/flow-dialog.component';
import { ReferralDataService } from '../../../services/referral-data.service';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { ListTableComponent } from '../../../shared/components/list-table/list-table.component';
import { EnrollmentDataService } from '../../../services/enrollment-data.service';
import { DeleteAssociationModel, PrtcpntPrvdrAssociationModel } from '../../../shared/interfaces/participant-association.model';
import { EditMode } from '../../../interfaces/edit-mode.model';

@Component({
  selector: 'ppl-new-contact',
  standalone: true,
  imports: [
    NzCardModule,
    NzFormModule,
    ReactiveFormsModule,
    NzToolTipModule,
    NzRadioModule,
    CommonModule,
    PopupDialogComponent,
    NewEnrollmentComponent,
    FlowDialogComponent,
    ButtonComponent,
    NzCollapseModule,
    ListTableComponent
  ],
  templateUrl: './new-contact.component.html',
  styleUrl: './new-contact.component.css',
})
export class NewContactComponent extends BaseComponent implements OnInit {
  @Input() mode = 'edit';
  readonly editMode = EditMode;
  radioOptions = RADIO_YES_NO_OPTIONS;
  newContactForm!: FormGroup;
  show = false;
  associationDetailsData: any = {};
  entry: string | null = '';
  associations: any = [];
  flow: string|null = '';
  program: any;

  cols: any = [];
  participantCols: any[] = [];
  associationCols: any = [];
  participantAssociations: any[] = [];
  mandatoryAssociations: any[] = [];
  isStepMandatory = false;

actions: any = {
  "edit": false,
  "delete": true,
  "view": true,
  "download": false,
  "verify": false,
  "add": false
}

  constructor(
    private sectionsService: SectionsService,
    private fb: FormBuilder,
    private referralDataService: ReferralDataService,
    private enrollmentDataService: EnrollmentDataService
  ) {
    super();
  }

  activeKeys: string[] = [];
  allPanelsExpanded = false;

  ngOnInit(): void {
    this.newContactForm = this.fb.group({
      newEmployer: ['No'],
      newAuthRep: ['No'],
      newVendor: ['No'],
      newProvider: ['No'],
      newContractor: ['No'],
      newPOA: ['No'],
      newLegalGuardian: ['No'],
    });
    this.entry = localStorage.getItem('lead_entry');
    this.flow = localStorage.getItem('lead_submission_flow');
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    const steps = this.sectionsService.getSectionData(this.program.program_code);
    // const leadId = localStorage.getItem('selected_lead')!;
    const participantId = localStorage.getItem('participantId')!;
    this.secData = steps['associations'];
    this.mandatoryAssociations = this.secData.attributes.filter((data:any)=>{
      return data.mandatory === 'Y';
    });

    console.log("this.mandatoryAssociations--------------", this.mandatoryAssociations);
    if(this.sectionsService.getFlow() === 'providerEnrollment'){
      this.mode = 'read'
      // this.referralDataService.setReferralFormData(
      //   'isAssociationsFormDirty',true
      // );
      // this.referralDataService.setReferralFormData(
      //   'isAssociationsFormUpdated',true
      // );
    }

    const controls = [
      'newEmployer',
      'newAuthRep',
      'newProvider',
      'newVendor',
      'newContractor',
      'newLegalGuardian',
      'newPOA',
    ];

    controls.forEach((control) => {
      this.newContactForm.get(control)?.valueChanges.subscribe((value) => {
        this.onRadioChange(control, value);
      });
    });

    const data = this.sectionsService.getAllSteps(this.program.program_code);
    const associationStep = data.find((step: any) => step.step_id === STATIC_DATA.ASSOCIATIONS);
    this.isStepMandatory = associationStep ? associationStep.isMandatory === 'Y' : false;
    this.getGridHeaders();
    this.getFileCols();
    this.getAssocGridHeaders();
    this.enrollmentDataService.getParticipantAssocChanged().subscribe(() => {
      this.getAssociationDetails(this.program.program_code, participantId);

    })
    //this.getAssociationDetails(program.program_code, leadId);
    if (this.sectionsService.getFlow() === 'providerEnrollment') {
      const filledData = this.referralDataService.getReferralDetails(
        'participantAssociations'
      )!;
      if (filledData) {
        this.associations = filledData;
      }
    }

    this.referralDataService.nextClicked.subscribe((val) => {
      if(val === STATIC_DATA.ASSOCIATIONS){
        this.referralDataService.setStepValidation({step: STATIC_DATA.ASSOCIATIONS, val: this.checkFieldData()});
      }
    });
  }

  async getFileCols() {
    const tempCols = await this.sectionsService.getJsonConfigurationData(
      STATIC_JSON_DATA_PATH.PARTICIPANT_ASSOCIATIONS_HEADERS
    );
    this.associationCols = tempCols?.columns;
  }

  flattenData(data: Record<string, any[]>): any[] {
    return Object.values(data).flat();
}

  getAssociationDetails(program_code: string, participantId: any) {
    console.log('leadId', participantId);
    if(participantId) {
     const isParticipant = this.flow === 'participantEnrollment';
     this.enrollmentDataService.getAssociationsDetails(program_code, participantId, isParticipant, true).subscribe((response: any) => {
      console.log(response.responsedata);
      const data = response.responsedata;
      this.participantAssociations = this.flattenData(response.responsedata);

      for(const key in PROVIDER_TYPES) {
        this.associationDetailsData[key] = data[key] || [];
      }
    });
  }

  }

  action(event: any): void {
    if (event.action === 'delete') {
      const program = JSON.parse(localStorage.getItem('selected_program')!);
      const data : PrtcpntPrvdrAssociationModel = event.data;
      const payload : DeleteAssociationModel = {
        associationType : data.associationTypeCode,
        participantId : data.participantId,
        providerId : data.providerId,
        programCode : program.program_code
      }; 

      this.deleteAccociation(payload);
    } else if (event.action === 'view') {
      console.log('view event', event);
      this.referralDataService.setAssociationsEditable(true);
      const payload = {
        programCode: this.program.program_code,
        providerId: event.data.providerId,
        providerType: null,
      };
      this.enrollmentDataService
      .fetchParticipantAssociations(payload)
      .subscribe((response: any) => {
        console.log('Edit response', response);
        this.referralDataService.setAssociationDetails(response.responsedata);
        this.addAssociation(ENROLLMENT_TYPE_BACKEND_MAPPINGS[event.data.associationTypeCode], EditMode.Add)
        // const flattenedData = this.flattenResponse(response);
        // console.log(flattenedData.responsedata);
        // this.infoForm.patchValue(flattenedData.responsedata);
      });
    }
  }

  deleteAccociation(payload : DeleteAssociationModel){
    this.enrollmentDataService.deleteParticipantProviderAssociation(payload, true).subscribe((response: any) => {
      console.log('Edit response', response);
      this.enrollmentDataService.setParticipantAssocChanged(true);
    });
  }

  async getGridHeaders() {
    try {
      if (this.flow) {
        const tempCols = await this.sectionsService.getJsonConfigurationData(
          STATIC_JSON_DATA_PATH.ASSOCIATIONS_DETAILS_HEADERS, this.flow
        );
        this.cols = tempCols.columns;
      }
    } catch {
      // this.logger.debug('Communication logs - Error in getting column data', err);
    }

  }


  async getAssocGridHeaders() {
    try {
      if (this.flow) {
        const tempCols = await this.sectionsService.getJsonConfigurationData(
          STATIC_JSON_DATA_PATH.ALL_ASSOCIATIONS_DETAILS_HEADERS, this.flow
        );
        this.participantCols = tempCols.columns;
      }
    } catch {
      // this.logger.debug('Communication logs - Error in getting column data', err);
    }
  }

  getProviderData(providerCode: string) {
    return this.associationDetailsData[providerCode] || [];
  }

  checkFieldData(): boolean {
    return Object.values(this.associationDetailsData).some((association: any) => association.length > 0);
    //Do not delete the below commented code

    // if(this.mandatoryAssociations.length === 0 || this.flow === 'providerEnrollment'){
    //   return true;
    // }
    // return this.mandatoryAssociations.every(item => {
    //   const fieldName = item.fieldName.replace('new', '');
    //   const mappedFieldName = ENROLLMENT_TYPE_UI_TO_BACKEND_MAPPINGS[fieldName.toLowerCase()];
    //   return mappedFieldName && this.associationDetailsData[mappedFieldName] && this.associationDetailsData[mappedFieldName].length > 0;
    // });
  }

  onRadioChange(controlName: string, value: string) {
    if (value === 'Yes') {
      this.sectionsService.setEnrollmentType(controlName);
      //this.referralDataService.clearReferrralData();
      this.referralDataService.setFlowForAssociation({ val: true, flow: controlName });
    }
  }

  addAssociation(control: string, editMode?: EditMode){
    this.sectionsService.setEnrollmentType(control);
    if (editMode !== EditMode.Add) {
      this.referralDataService.clearAssociationsFormData();
    }
    //this.referralDataService.clearReferrralData();
    const flowParams: any = { val: true, flow: control }
    if (editMode) {
      flowParams.editMode = editMode;
      console.log("FLOW PARAMS", flowParams)
    }
    this.referralDataService.setFlowForAssociation(flowParams);
    // this.referralDataService.setReferralFormData(
    //   'isAssociationsFormDirty', true
    // );
    // this.referralDataService.setReferralFormData(
    //   'isAssociationsFormUpdated', true
    // );
  }

  // viewAssociation(control: string){
  //   this.enrollmentDataService
  //       .fetchParticipantAssociations(payload)
  //       .subscribe((response: any) => {
  //         console.log('Edit response', response);
  //         const flattenedData = this.flattenResponse(response);
  //         console.log(flattenedData.responsedata);
  //         this.infoForm.patchValue(flattenedData.responsedata);
  //       });
  // }

  hideAll() {
    this.activeKeys = [];
  }

  expandAll() {
    this.activeKeys = ['1', '2', '3', '4', '5', '6', '7'];
  }

  togglePanel(key: string): void {
    if (this.isPanelActive(key)) {
      this.activeKeys = this.activeKeys.filter((k) => k !== key);
    } else {
      this.activeKeys.push(key);
    }
  }

  isPanelActive(key: string): boolean {
    return this.activeKeys.includes(key);
  }
}
