{
     "participant_info": {
        "sectionId": "info",
        "header": "Employer Information",
        "available_actions": null,
        "attributes": [
            {
                "attribute_ID": "businessName",
                "fieldName": "businessName",
                "label": "Business Name",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter your business name",
                "isAvailable": "N",
                "placeholder": "Business Name"
            },
            {
                "attribute_ID": "fname",
                "fieldName": "fname",
                "label": "First Name",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter your first name",
                "isAvailable": "Y",
                "placeholder": "First Name"
            },
            {
                "attribute_ID": "mname",
                "fieldName": "mname",
                "label": "Middle Name",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter your middle name",
                "isAvailable": "N",
                "placeholder": "Middle Name"
            },
            {
                "attribute_ID": "lname",
                "fieldName": "lname",
                "label": "Last Name",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter your last name",
                "isAvailable": "Y",
                "placeholder": "Last Name"
            },
            {
                "attribute_ID": "maiden_name",
                "fieldName": "maiden_name",
                "label": "Maiden or Previous Name",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter your maiden name",
                "isAvailable": "N",
                "placeholder": "Maiden or Previous Name"
            },
            {
                "attribute_ID": "dob",
                "fieldName": "dob",
                "label": "Date of Birth",
                "type": "datepicker",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select your date of birth",
                "isAvailable": "Y",
                "placeholder": "Select Date"
            },
            {
                "attribute_ID": "gender",
                "fieldName": "gender",
                "label": "Gender",
                "type": "radio",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select your gender",
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "relationToParticipant",
                "fieldName": "relationToParticipant",
                "label": "Relationship to Consumer",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select your Relationship to Consumer",
                "isAvailable": "N"
            },
            {
                "attribute_ID": "EIN",
                "fieldName": "EIN",
                "label": "EIN",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter your EIN",
                "isAvailable": "Y",
                "placeholder": "EIN"
            },
            {
                "attribute_ID": "SSN",
                "fieldName": "SSN",
                "label": "Social Security Number",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter SSN",
                "isAvailable": "Y",
                "placeholder": "Social Security Number"
            },
            {
                "attribute_ID": "additionalDemographics",
                "fieldName": "additionalDemographics",
                "label": "Additional Demographics in case program requires fingerprinting :",
                "type": "section",
                "mandatory": "N",
                "validator": null,
                "tooltip": "",
                "isAvailable": "N",
                "placeholder": ""
            },
            {
                "attribute_ID": "race",
                "fieldName": "race",
                "label": "Race",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select your Race",
                "isAvailable": "N",
                "placeholder": "Select"
            },
            {
                "attribute_ID": "height",
                "fieldName": "height",
                "label": "Height",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter your height",
                "isAvailable": "N",
                "placeholder": "Height"
            },
            {
                "attribute_ID": "weight",
                "fieldName": "weight",
                "label": "Weight",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter your weight",
                "isAvailable": "N",
                "placeholder": "Weight"
            },
            {
                "attribute_ID": "eyeColor",
                "fieldName": "eyeColor",
                "label": "Eye Color",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select your eye color",
                "isAvailable": "N",
                "placeholder": "Select"
            },
            {
                "attribute_ID": "hairColor",
                "fieldName": "hairColor",
                "label": "Hair Color",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select your hair color",
                "isAvailable": "N",
                "placeholder": "Select"
            },
            {
                "attribute_ID": "birthPlace",
                "fieldName": "birthPlace",
                "label": "Place of Birth",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "",
                "isAvailable": "N",
                "placeholder": ""
            },
            {
                "attribute_ID": "NPI",
                "fieldName": "NPI",
                "label": "National Provider Number",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "National Provider Number",
                "isAvailable": "Y",
                "placeholder": "Please Enter 10 digit NPI"
            },
            {
                "attribute_ID": "addToED",
                "fieldName": "addToED",
                "label": "Would you like to be added to an Employee Directory for Self-Directed Consumers that may be looking for additional employee services?",
                "type": "radio",
                "mandatory": "N",
                "validator": null,
                "tooltip": "",
                "isAvailable": "N",
                "placeholder": ""
            }
        ],
        "validations": [
            {
                "whatToCheck": "atLeastXEntered",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "fname",
                            "lname",
                            "SSN"
                        ],
                        "functionParams": {
                            "ErrMsg": "This field is mandatory."
                        }
                    }
                ]
            },
            {
                "whatToCheck": "checkFieldValue",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "fname"
                        ],
                        "functionParams": {
                            "isNullAllowed": "N",
                            "Minlength": "4",
                            "ErrMsg": "Please Enter First Name min length of 4 characters"
                        }
                    },
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "lname"
                        ],
                        "functionParams": {
                            "isNullAllowed": "N",
                            "Maxlength": "4",
                            "ErrMsg": "Please Enter Last Name max length of 4 characters"
                        }
                    },
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "mname"
                        ],
                        "functionParams": {
                            "isNullAllowed": "N",
                            "Format": "[a-z]",
                            "ErrMsg": "Please Enter middle in lower case"
                        }
                    }
                ]
            },
            {
                "whatToCheck": "conditionalCheck",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "functionParams": {
                            "basecondition": [
                                "fname",
                                "lname"
                            ],
                            "condtionalAttributes": [
                                "maiden_name",
                                "dob"
                            ],
                            "ErrMsg": "Please Enter maiden name and date of birth"
                        }
                    }
                ]
            },
            {
                "whatToCheck": "customValidator",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "SSN"
                        ],
                        "functionParams": {
                            "validatorFunction" : "validateSSN",
                            "ErrMsg": "Please Enter Valid SSN in the format xxx-xx-xxxx"
                        }
                    }
                ]
            }
        ],
        "actions": [
            {
                "Name": "Previous",
                "Validate": null
            },
            {
                "Name": "Next",
                "Validate": [
                    {
                        "checkType": "atLeastXEntered",
                        "validatorid": [
                            1,
                            2,
                            3
                        ]
                    },
                    {
                        "checkType": "checkFieldValue",
                        "validatorid": [
                            1
                        ]
                    },
                    {
                        "checkType": "conditionalCheck",
                        "validatorid": [
                            1
                        ]
                    },
                    {
                        "checkType": "customValidator",
                        "validatorid": [
                            1
                        ]
                    }
                ]
            }
        ]
    },
    "contact_details": {
        "sectionId": "service_details",
        "header": "Service Details",
        "attributes": [
            {
                "attribute_ID": "email",
                "fieldName": "email",
                "label": "Email Address",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter your email address",
                "isAvailable": "Y",
                "placeholder": "Email Address"
            },
            {
                "attribute_ID": "altPhone",
                "fieldName": "altPhone",
                "label": "Telephone Number",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter telephone or alternate phone number",
                "isAvailable": "Y",
                "placeholder": "10-digit Telephone Number"
            },
            {
                "attribute_ID": "mobile",
                "fieldName": "mobile",
                "label": "Mobile Number",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter your Mobile number",
                "isAvailable": "Y",
                "placeholder": "10-digit Mobile Number"
            },
            {
                "attribute_ID": "permanentAddress",
                "fieldName": "permanentAddress",
                "label": "Address",
                "type": "form",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter your Address",
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "mailingAddress",
                "fieldName": "mailingAddress",
                "label": "Mailing Address",
                "type": "form",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter your Mailing Address",
                "isAvailable": "N"
            },
            {
                "attribute_ID": "yearsInState",
                "fieldName": "yearsInState",
                "label": "How many years have you resided in the State?",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "",
                "isAvailable": "N",
                "placeholder": ""
            }
        ],
        "validations": [
            {
                "whatToCheck": "atLeastXEntered",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "fname",
                            "lname",
                            "SSN"
                        ],
                        "functionParams": {
                            "ErrMsg": "This field is mandatory."
                        }
                    }
                ]
            },
            {
                "whatToCheck": "checkFieldValue",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "fname"
                        ],
                        "functionParams": {
                            "isNullAllowed": "N",
                            "Minlength": "4",
                            "ErrMsg": "Please Enter First Name min length of 4 characters"
                        }
                    },
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "lname"
                        ],
                        "functionParams": {
                            "isNullAllowed": "N",
                            "Maxlength": "4",
                            "ErrMsg": "Please Enter Last Name max length of 4 characters"
                        }
                    },
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "mname"
                        ],
                        "functionParams": {
                            "isNullAllowed": "N",
                            "Format": "[a-z]",
                            "ErrMsg": "Please Enter middle in lower case"
                        }
                    }
                ]
            },
            {
                "whatToCheck": "conditionalCheck",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "functionParams": {
                            "basecondition": [
                                "fname",
                                "lname"
                            ],
                            "condtionalAttributes": [
                                "maiden_name",
                                "dob"
                            ],
                            "ErrMsg": "Please Enter maiden name and date of birth"
                        }
                    }
                ]
            },
            {
                "whatToCheck": "customValidator",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "SSN"
                        ],
                        "functionParams": {
                            "validatorFunction" : "validateSSN",
							"ErrMsg": "Please Enter Valid SSN in the format xxx-xx-xxxx"
                        }
                    }
                ]
            }
        ],
        "actions": [
            {
                "Name": "Previous",
                "Validate": null
            },
            {
                "Name": "Next",
                "Validate": [
                    {
                        "checkType": "atLeastXEntered",
                        "validatorid": [
                            1,
                            2,
                            3
                        ]
                    },
                    {
                        "checkType": "checkFieldValue",
                        "validatorid": [
                            1
                        ]
                    },
                    {
                        "checkType": "conditionalCheck",
                        "validatorid": [
                            1
                        ]
                    },
                    {
                        "checkType": "customValidator",
                        "validatorid": [
                            1
                        ]
                    }
                ]
            }
        ]
    },
    "comm_preferences": {
        "sectionId": "comm_preferences",
        "header": "Communication Preferences",
        "attributes": [
            {
                "attribute_ID": "registerWith",
                "fieldName": "registerWith",
                "label": "How will the employer register and complete their enrollment with PPL? ",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select your primary language",
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "emergencyContact",
                "fieldName": "emergencyContact",
                "label": "Emergency Contact",
                "type": "section",
                "mandatory": "N",
                "validator": null,
                "tooltip": "",
                "isAvailable": "N",
                "placeholder": ""
            },
            {
                "attribute_ID": "nonProfitStatus",
                "fieldName": "nonProfitStatus",
                "label": "Non Profit Status",
                "type": "",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select the non profit Status",
                "isAvailable": "N",
                "placeholder": ""
            },
            {
                "attribute_ID": "taxExempt",
                "fieldName": "taxExempt",
                "label": "Tax Exempt",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "",
                "isAvailable": "N",
                "placeholder": "Select"
            },
            {
                "attribute_ID": "nprftDocumentationReceived",
                "fieldName": "nprftDocumentationReceived",
                "label": "Non-Profit Official Documentation Received",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "",
                "isAvailable": "N",
                "placeholder": "Select"
            }
        ],
        "validations": [
            {
                "whatToCheck": "atLeastXEntered",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "fname",
                            "lname",
                            "SSN"
                        ],
                        "functionParams": {
                            "ErrMsg": "This field is mandatory."
                        }
                    }
                ]
            },
            {
                "whatToCheck": "checkFieldValue",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "fname"
                        ],
                        "functionParams": {
                            "isNullAllowed": "N",
                            "Minlength": "4",
                            "ErrMsg": "Please Enter First Name min length of 4 characters"
                        }
                    },
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "lname"
                        ],
                        "functionParams": {
                            "isNullAllowed": "N",
                            "Maxlength": "4",
                            "ErrMsg": "Please Enter Last Name max length of 4 characters"
                        }
                    },
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "mname"
                        ],
                        "functionParams": {
                            "isNullAllowed": "N",
                            "Format": "[a-z]",
                            "ErrMsg": "Please Enter middle in lower case"
                        }
                    }
                ]
            },
            {
                "whatToCheck": "conditionalCheck",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "functionParams": {
                            "basecondition": [
                                "fname",
                                "lname"
                            ],
                            "condtionalAttributes": [
                                "maiden_name",
                                "dob"
                            ],
                            "ErrMsg": "Please Enter maiden name and date of birth"
                        }
                    }
                ]
            },
            {
                "whatToCheck": "customValidator",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "SSN"
                        ],
                        "functionParams": {
                            "validatorFunction" : "validateSSN",
                            "ErrMsg": "Please Enter Valid SSN in the format xxx-xx-xxxx"
                        }
                    }
                ]
            }
        ],
        "actions": [
            {
                "Name": "Previous",
                "Validate": null
            },
            {
                "Name": "Next",
                "Validate": [
                    {
                        "checkType": "atLeastXEntered",
                        "validatorid": [
                            1,
                            2,
                            3
                        ]
                    },
                    {
                        "checkType": "checkFieldValue",
                        "validatorid": [
                            1
                        ]
                    },
                    {
                        "checkType": "conditionalCheck",
                        "validatorid": [
                            1
                        ]
                    },
                    {
                        "checkType": "customValidator",
                        "validatorid": [
                            1
                        ]
                    }
                ]
            }
        ]
    },
    "service_details": {
        "sectionId": "service_details",
        "header": "Service Details",
        "text_1": "Please share your service details",
        "text_2": null,
        "text_3": null,
        "available_actions": null,
        "attributes": [
            {
                "attribute_ID": "profLicences",
                "fieldName": "profLicences",
                "label": "Professional Licenses",
                "type": "select",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please select your Professional Licenses",
                "isAvailable": "N",
                "placeholder": "Professional Licenses "
            },
            {
                "attribute_ID": "servicesRendered",
                "fieldName": "servicesRendered",
                "label": "Services that you can render",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select the services",
                "isAvailable": "N",
                "placeholder": "Services"
            }
        ],
        "validations": [],
        "actions": []
    }
}