@if(isPageRestrictionLoaded){
<div class="ppl-container-body">
  <div class="ppl-container">
    <div class="ppl-content-container" style="padding: 8px; max-height: calc(100vh - 140px); box-sizing: border-box;">
      <div class="line1">
        
          <div class="action" >
            @if(!isExternalEnvironment){
              <ppl-button [label]="backLabel" (click)="navigateToLeads()"
                icon="fa fa-chevron-left"
                [customClass]="'primary-button semi-rounded'" [iconPosition]="'left'"></ppl-button>
            }   
          </div>
       
        <div class="actGRP">
         <!-- <ppl-button label="Communication Logs" icon="fa fa-comments-o" [customClass]="'ghost-button'" [iconPosition]="'left'"
          (click)="communicationLogs()" *authorize="[pageId, tabId, 'communication_logs', 'button']"></ppl-button>  

          <ppl-button label="History" icon="fa fa-history" [customClass]="'ghost-button'" [iconPosition]="'left'"
          *authorize="[pageId, tabId, 'change_history', 'button']" (click)="getLeadHistory()"></ppl-button>  

          <ppl-button label="Comments" icon="fa fa-comment" [customClass]="'ghost-button'" [iconPosition]="'left'"
          (click)="comments()" *authorize="[pageId, tabId, 'comments', 'button']"></ppl-button>  

           <ppl-actions *authorize="[pageId, tabId, 'actions', 'button']" [listButton]="listButton" [selectedCheckBoxValue]="applicationData" [sourcePage]="entry"
            (leadUpdated)="getUpdatedLeadData($event)" [from]="'details'"></ppl-actions> -->
          
        </div>
      </div>
      <div class="line2">
        <img class="profile" src="../../assets/images/account_circle_24dp_E8EAED_FILL1_wght400_GRAD0_opsz24.svg" alt="User profile image"
          height="48px">
        Details for {{ filledInfo?.fname }}
      </div>

      <div style="width: 100%; box-sizing: border-box;">

        <!-- <ppl-tabbed-panel [horizontalMenuItemstoDisplay]="horizontalMenuItemstoDisplay">
    
  </ppl-tabbed-panel> -->



        <div class="ppl-tabs">
          <div class="ppl-nav ppl-nav-tabs">
            
            @for (menuItem of horizontalMenuItemstoDisplay; track $index) {
              <div class="ppl-nav-item">
                <a class="ppl-nav-link" [ngClass]="{'single-tab': horizontalMenuItemstoDisplay.length === 1}" [class.active]="menuItem.menu_id === selectedMenuItem"
                  (click)="onMenuSelection(menuItem)" href="javascript:void(0)">
                  {{ menuItem.menu_label }}
                </a>
              </div>
            }


          </div>

          <div class="tab-content">

            <router-outlet></router-outlet>

          </div>
        </div>

      </div>

        <ppl-side-drawer [width]="'1100px'" *authorize="[pageId, tabId, 'change_history', 'button']" [(isSideDrawerVisible)]="isCommunicationLogs"
        [title]="'Communication Logs'" (close)="isCommunicationLogs = false">
            <ppl-communication-logs [sourcePage]="entry" [leadId]="id" [program]="program.program_code" [isCommunicationLogs]="isCommunicationLogs" ></ppl-communication-logs>
        </ppl-side-drawer>


      <ppl-side-drawer *authorize="[pageId, tabId, 'change_history', 'button']" [(isSideDrawerVisible)]="isAuditHistory"
        [title]="'Audit History'" (close)="isAuditHistory = false">
        <ppl-timeline [data]="auditHistory"></ppl-timeline>
      </ppl-side-drawer>

      @if(filledInfo){

      <ppl-side-drawer [width]="'800px'" *authorize="[pageId, tabId, 'comments', 'button']" [(isSideDrawerVisible)]="enableComments"
        [title]="'Comments'" (close)="enableComments = false">
        <ppl-lead-comments [sourcePage]="entry" [sectionData]="steps" [leadId]="id" [program]="program.program_code"
          (leadUpdated)="getUpdatedLeadData($event)" [clearComments]="enableComments"></ppl-lead-comments>
      </ppl-side-drawer>
      
      }
      <ppl-notification-sms [show]="showSMSModal" [notificationType]="notificationType"
        (onHandleCancel)="onHandleCancel($event)" [selectedCheckBoxValue]="applicationData"
        [sourcePage]="'details'"></ppl-notification-sms>
    </div>
  </div>
</div>
}