import { Injectable } from '@angular/core';
import { LogLevel } from '../enums/log-level';

/**
 * Log output handler function.
 */
export type LogOutput = (source: string | undefined, level: LogLevel, ...objects: any[]) => void;

@Injectable(
  { providedIn: 'root' }
)
export class LoggerService {
  
  /**
   * Current logging level.
   * Set it to LogLevel.Off to disable logs completely.
   */
  static level = LogLevel.Debug;

  /* Additional log outputs.*/
  static outputs: LogOutput[] = [];

  /*Enables production mode.*/
  public enableProductionMode() {
    LoggerService.level = LogLevel.Error;
  }

  /* Logs messages or objects  with the debug level.*/
  public debug(source: string, ...objects: any[]) {
    this.log(source, console.log, LogLevel.Debug, objects);
  }

  /* Logs messages or objects  with the info level. */
  public info(source: string, ...objects: any[]) {
    this.log(source, console.info, LogLevel.Info, objects);
  }

  /* Logs messages or objects  with the warning level.*/
  public warn(source: string, ...objects: any[]) {
    this.log(source, console.warn, LogLevel.Warning, objects);
  }

  /* Logs messages or objects  with the error level. */
  public error(source: string, ...objects: any[]) {
    this.log(source, console.error, LogLevel.Error, objects);
  }

  /**
   * @function log
   * @param source
   * @param func
   * @param level
   * @param objects
   * @description log messages and events
   */
  private log(source: string, func: (...args: any[]) => void, level: LogLevel, objects: any[]) {
    const log = ['[' + source + ']'].concat(objects);
    if (level <= LoggerService.level) {
      func.apply(console, log);
      LoggerService.outputs.forEach((output) => output.apply(output, [source, level, objects]));
    }
  }
}
