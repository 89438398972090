<div class="alert-box" *ngIf="isToasterVisible">
    <div class="overlay"></div>
    <div class="content">
        <div class="header">
            <div style="display: flex; gap: 8px; align-items: center;">
                <div class="icon" style="color:green; font-size: 2.286rem;">
                    <div *ngIf="success">
                        <i  class="fa fa-check-circle"></i>
                    </div>
                    <div *ngIf="!success" style="color:red; font-size: 2.286rem;">
                        <i  class="fa fa-times-circle"></i>
                    </div>
                </div>
                <div class="title">
                    {{ title }}
                </div>                
            </div>
            <div class="action">
                <ppl-button
                label="" 
                icon="fa fa-close" 
                [customClass]="'rounded'" 
                [iconPosition]="'left'"
                (click)="closeAlert()">
                ></ppl-button>
            </div>                   
        </div>            
        <div class="body">
            <div class="body-content">
                {{ message }}
            </div>
        </div>
  </div>
  