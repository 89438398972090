import { Component, OnInit } from '@angular/core';
import { ProspectiveInfoComponent } from '../prospective-info/prospective-info.component';
import { AccordionComponent } from '../../../../shared/components/accordion/accordion.component';
import { AuthorizeDirective } from '../../../../shared/authorization/authorize.directive.';
import { STATIC_DATA, STATIC_JSON_DATA_PATH } from '../../../../shared/constants/static-data.constants';
import { SectionsService } from '../../../../services/sections.service';
import { LeadDetailsService } from '../../../../services/lead-details.service';
import { NotificationService } from '../../../../shared/services/notification.service';
import { MEMBER_VIEW_PAGE_IDS, PAGE_CONSTANTS, TABS } from '../../../../shared/constants/page.constants';
import { BaseComponent } from '../../../../shared/utils/base.component';

@Component({
  selector: 'ppl-prospective-participants',
  standalone: true,
  imports: [AccordionComponent,
    ProspectiveInfoComponent, AuthorizeDirective
  ],
  templateUrl: './prospective-participants.component.html',
  styleUrl: './prospective-participants.component.css'
})
export class ProspectiveParticipantsComponent  extends BaseComponent implements OnInit {

  filledInfo: any;
  steps: any;
  entry  = 'base';
  sectionDataPath: string = STATIC_JSON_DATA_PATH.LEAD_DETAILS_PROSPECTIVE_PARTICIPANTS_DATA;
  source!: string;
  pageId = '';
  tabId = TABS.ProspectiveInformation;
  currentRoute = '';
  addressInfo: any;
  program: any;
  selectedLead: any;

  constructor(private sectionsService: SectionsService, 
    private leadDetailsService: LeadDetailsService, 
    private notificationService: NotificationService) {
      super()
     }


    ngOnInit() {
      this.source = 'ppl-lead-prospective-participants';
      this.entry = localStorage.getItem("lead_entry")!;
      this.pageId = MEMBER_VIEW_PAGE_IDS[localStorage.getItem(STATIC_DATA.URL_FIRST_SEGMENT)!];
      this.program = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_PROGRAM)!);
      this.getSectionData();
      
      try {
        this.selectedLead = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!);
      } catch {
        this.notificationService.alert("error", "Error Fetching Data", "Error getting selected lead data");
      }
      //const selectedLead: any = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!);
      
      this.getLeadData();

    }
  
    async getSectionData(){
      try{
        if(this.entry){
        this.steps = await this.sectionsService.getJsonConfigurationData(this.sectionDataPath, this.entry);
        this.secData = this.steps[STATIC_DATA.PROSPECTIVE_PARTICIPANTS_DATA];
        this.log.debug(this.source, "Logger test successful", this.steps);
        }
      }
      catch(e){
        this.log.debug("Error fetching lead details data", e);
      }
    }
  
    updatedLeadData = (event: boolean) => {
  
      this.log.debug("Updated", event);
      if (event) {
        this.getLeadData();
      }
    };
  
  
    getLeadData(){
      console.log('i m called', this.entry, this.program.program_code, this.selectedLead)
      this.leadDetailsService.fetchLeadProspectiveParticipants(this.program.program_code, this.selectedLead, true).subscribe(
        (data:any)=>{
          this.filledInfo = data?.responsedata;
        }
      );
    }
  
    getLastModifiedDate(data : any[] ): string {
      if(data?.length){
        return data?.reduce((latest:any, current:any) => {
          const currentDate = new Date(current.modifiedDate);
          const latestDate = new Date(latest.modifiedDate);
          
          return currentDate > latestDate ? current : latest;
      }).modifiedDate;
      }else{
        return '';
      }
      
    }



}



