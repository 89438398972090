import { Component, inject, OnInit } from '@angular/core';
import { ListTableComponent } from '../../../shared/components/list-table/list-table.component';
import { PageConfig } from '../../../shared/authorization/auth.config';
import { AuthorizationUtility } from '../../../shared/authorization/auth.utility';
import { PAGE_CONSTANTS } from '../../../shared/constants/page.constants';
import { AuthorizationService } from '../../../shared/authorization/authorization.service';
import { ENROLLMENT_TYPE_BACKEND_MAPPINGS, STATIC_DATA } from '../../../shared/constants/static-data.constants';
import { FileUploadNotificationService } from '../../../services/file-upload-notification.service';
import { FileUploadNotificationData } from '../../../interfaces/file-upload-notification.interface';
import { Router } from '@angular/router';
import { MENU_BASE_URLS } from '../../../shared/constants/url.constants';
import { SectionsService } from '../../../services/sections.service';
import { ReferralDataService } from '../../../services/referral-data.service';

@Component({
  selector: 'ppl-file-upload-notification-list',
  standalone: true,
  imports: [ListTableComponent],
  templateUrl: './file-upload-notification-list.component.html',
  styleUrl: './file-upload-notification-list.component.css'
})
export class FileUploadNotificationListComponent implements OnInit {
  private authUtility = inject(AuthorizationUtility);
  private authService = inject(AuthorizationService);
  private fileUploadNotificationService = inject(FileUploadNotificationService);
  private sectionService = inject(SectionsService);
  private referralDataService = inject(ReferralDataService);
  private router = inject(Router)
  
  pageId: string = PAGE_CONSTANTS.FILE_TRIAGE_DATA_GRID_PAGE;
  sharedDataKey: string = STATIC_DATA.SHARED_FILE_TRAGE_DATA;
  lazyLoad = true;
  programName!: string;
  isPageRestrictionLoaded = false;
  columnsToBeHidden: any = [];
  actionsToBeHidden: any = [];

  ngOnInit(): void {
    const selectedProgram = JSON.parse(localStorage.getItem('selected_program')!);
    this.programName = selectedProgram.program_code;
    this.setPageAccess();
  }

  rowClicked(rowDetail: FileUploadNotificationData) {
    const { userType, userId } = rowDetail
    let enrollmentType = userType === 'provider' ? STATIC_DATA.PROVIDER_ENROLLMENT : STATIC_DATA.PARTICIPANT_ENROLLMENT;
    let enrollmentUrl = userType === 'provider' ? MENU_BASE_URLS.PROVIDER_ENROLLMENT : MENU_BASE_URLS.PARTICIPANT_ENROLLMENT;

    localStorage.setItem('selected_lead', userId.toString());
    localStorage.setItem('lead_entry', enrollmentType);
    localStorage.setItem(STATIC_DATA.URL_FIRST_SEGMENT, enrollmentUrl);
    this.sectionService.setFlow(enrollmentType);
    if (userType === 'provider') {
      this.sectionService.setEnrollmentType(JSON.stringify([ENROLLMENT_TYPE_BACKEND_MAPPINGS[rowDetail?.providerType || 'IR']]));
    }
    this.referralDataService.clearReferrralData();
    return this.router.navigate([`/home/${enrollmentUrl}/details`], { state: { navigateToTab: 'documents' } });
  }

  private setPageAccess() {
    const pageConfig = this.authUtility.getPageConfig(this.pageId);
    if(!pageConfig || !pageConfig.pageName) {
      this.authService.fetchPageAuthorization(this.programName, this.pageId, "LIST").subscribe(response => {
        this.setRestrictions(response);
      })
    } else {
      this.setRestrictions(pageConfig);
    }
  }

  private setRestrictions(pageConfig: PageConfig) {
    if (pageConfig) {
      this.columnsToBeHidden = this.authUtility.getHiddenColumns(pageConfig, this.pageId);
      this.actionsToBeHidden = this.authUtility.getHiddenActions(pageConfig, this.pageId);
      console.log('restricted columns', this.columnsToBeHidden);
      console.log('restricted actions', this.actionsToBeHidden);
    }
    this.isPageRestrictionLoaded = true;
  }
}
