/* src/app/components/leads/comm-preference/comm-preference.component.css */
nz-form-control {
  margin-top: 0.5em;
}
.time-picker {
  border: 1px solid lightgray;
  margin-bottom: 24px;
}
::ng-deep .ant-picker-input > input {
  border: none;
}
.ppl-radio-group {
  justify-content: start;
}
.ppl-form-label {
  margin-right: 8px;
}
.ant-form-item-label {
  margin-right: 8px;
}
::ng-deep nz-input-group > .ant-input {
  height: 30px;
}
.ant-radio-button-wrapper {
  margin: 0px;
  width: 100%;
}
.ant-input-affix-wrapper {
  margin-bottom: 0;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
}
.ant-checkbox + span {
  padding-right: 0 4px;
}
/*# sourceMappingURL=comm-preference.component-ZTOOUTLL.css.map */
