<div class="wiz-body">
  <div class="ppl-mt24">
    <div class="custom-row">
      @if(rateDetailsmode==="edit" && !this.isNew){
      <ppl-button label="Cancel" icon="fa fa-ban" [customClass]="'alt-button'" [iconPosition]="'left'"
        (click)="onCancel()"></ppl-button>
      <ppl-button label="Save" icon="fa fa-floppy-o" [customClass]="'alt-button'" [iconPosition]="'left'"
        (click)="onUpdate()"></ppl-button>
      }
      @if(rateDetailsmode==="new" && this.isNew){
      <ppl-button label="Cancel" icon="fa fa-ban" [customClass]="'alt-button'" [iconPosition]="'left'"
        (click)="onCancel()"></ppl-button>
      <ppl-button label="Save" icon="fa fa-pencil" [customClass]="'ghost-button'" [iconPosition]="'left'"
        (click)="onSave()"></ppl-button>
      }
    </div>
    @if(rateDetailsmode==="new" || rateDetailsmode==="edit"){
    <form nz-form nz-row [formGroup]="serviceCodeRateForm" class="ppl-p0">
      @if(isAttrAvailable('fromdate')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" zFor="fromdate"
            class="ppl-form-label">{{getAttributes('fromdate').label}}
            @if(getAttributes('fromdate').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('fromdate')?.tooltip || ''" nzTooltipPlacement="topRight">
              <nz-date-picker formControlName="effectiveFromDate" id="fromdate" [nzFormat]="'MM/dd/yyyy'"
                [nzPlaceHolder]="getAttributes('fromdate').placeholder" [nzDisabledDate]="disabledStartDate"
                [ngClass]="{'error': validationErrors['fromdate'] }"></nz-date-picker>
            </div>
            @if (validationErrors['fromdate']) {
            <span class="pl-1 error-message">{{validationErrors['fromdate']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }
      @if(isAttrAvailable('todate')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="todate" class="ppl-form-label">{{getAttributes('todate').label}}
            @if(getAttributes('todate').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('todate')?.tooltip || ''" nzTooltipPlacement="topRight">
              <nz-date-picker formControlName="effectiveEndDate" id="todate" [nzFormat]="'MM/dd/yyyy'"
                [nzPlaceHolder]="getAttributes('todate').placeholder" [nzDisabledDate]="disabledEndDate"
                [ngClass]="{'error': validationErrors['todate'] }"></nz-date-picker>
            </div>
            @if (validationErrors['todate']) {
            <span class="pl-1 error-message">{{validationErrors['todate']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }

      @if(isAttrAvailable('ratetype')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="ratetype"
            class="ppl-form-label">{{getAttributes('ratetype').label}}
            @if(getAttributes('ratetype').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('ratetype')?.tooltip || ''" nzTooltipPlacement="topRight">
              <nz-select formControlName="rateTypeLkp" nzMode='default' class="ppl-select" nzId="ratetype" nzShowSearch
                nzAllowClear [ngClass]="{'error': validationErrors['ratetype'] }" [nzDisabled]="">
                @for(opt of rateTypes; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
            </div>
            @if (validationErrors['ratetype']) {
            <span class="pl-1 error-message">{{validationErrors['ratetype']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }

      @if(isAttrAvailable('defaultrate')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="defaultrate"
            class="ppl-form-label">{{getAttributes('defaultrate').label}}
            @if(getAttributes('defaultrate').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('defaultrate')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <input nz-input formControlName="defaultRate" id="defaultrate"
                placeholder="{{getAttributes('defaultrate').placeholder}}"
                [ngClass]="{'error': validationErrors['defaultrate'] }" />
            </div>
            @if (validationErrors['defaultrate']) {
            <span class="pl-1 error-message">{{validationErrors['defaultrate']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }


      @if(isAttrAvailable('minrate')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="minrate"
            class="ppl-form-label">{{getAttributes('minrate').label}}
            @if(getAttributes('minrate').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('minrate')?.tooltip || ''" nzTooltipPlacement="topRight">
              <input nz-input formControlName="minRate" id="minrate"
                placeholder="{{getAttributes('minrate').placeholder}}"
                [ngClass]="{'error': validationErrors['minrate'] }" />
            </div>
            @if (validationErrors['minrate']) {
            <span class="pl-1 error-message">{{validationErrors['minrate']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }
      @if(isAttrAvailable('percent_billed')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="percent_billed"
            class="ppl-form-label">{{getAttributes('percent_billed').label}}
            @if(getAttributes('percent_billed').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('percent_billed')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <input nz-input formControlName="percentBilled" id="percent_billed"
                placeholder="{{getAttributes('percent_billed').placeholder}}"
                [ngClass]="{'error': validationErrors['percent_billed'] }" />
            </div>
            @if (validationErrors['percent_billed']) {
            <span class="pl-1 error-message">{{validationErrors['percent_billed']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }
    </form>
    }
    @if(rateDetailsmode==="read"){
    <div class="custom-row">
      <ppl-button label="Add New Rate" icon="fa fa-check-circle" [customClass]="'alt-button'" [iconPosition]="'right'"
        (click)="onAdd()"></ppl-button>
    </div>
    }

    <div class="table-style">
      <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true" [data]="gridData"
        [disableSelection]="true" [cols]="cols" (gridAction)="action($event)" [gridTitle]="false" [pagination]="false"
        [loadPage]="true" [actionButtons]="actionButtons">
      </ppl-list-table>
    </div>
  </div>
</div>