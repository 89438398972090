import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AddressComponent } from '../../../../shared/components/address/address.component';
import { SectionsService } from '../../../../services/sections.service';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { ListTableComponent } from '../../../../shared/components/list-table/list-table.component';
import { LeadDetailsService } from '../../../../services/lead-details.service';
import { LookupService } from '../../../../services/lookup.service';
import { performValidation } from '../../../../shared/utils/validation.util';
import {
  STATIC_DATA,
  STATIC_JSON_DATA_PATH,
} from '../../../../shared/constants/static-data.constants';
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { CommonModule } from '@angular/common';
import { LoggerService } from '../../../../shared/services/logger.service';
import {
  LeadDetailCommunicationModel,
  LeadDetailCommunicationUpdadeModel,
} from '../../../../interfaces/lead-detail.model';
import { TableRowActionsModel } from '../../../../shared/interfaces/list.interface';
import { TableRowActions } from '../../../../shared/interfaces/actions';
import { AuthorizeDirective } from '../../../../shared/authorization/authorize.directive.';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { NzAriaLabelDirective } from '../../../../shared/directives/nz-aria-label.directive';
import { AuthorizationUtility } from '../../../../shared/authorization/auth.utility';

@Component({
  selector: 'ppl-lead-detail-comm-methods',
  standalone: true,
  imports: [
    NgZorroModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AddressComponent,
    ListTableComponent,
    AuthorizeDirective,
    ButtonComponent,
    NzAriaLabelDirective
  ],
  templateUrl: './lead-detail-comm-methods.component.html',
  styleUrl: './lead-detail-comm-methods.component.css',
})
export class LeadDetailCommMethodsComponent
  extends BaseComponent
  implements OnInit, OnChanges {
  mode = 'read';
  filledInfo!: any;
  contactForm!: FormGroup;
  cols: any;
  gridData: any[] = [];
  communicationType: any;
  copyOfGridData: any = [];
  steps: any;
  entry = 'base';
  target!: LeadDetailCommunicationUpdadeModel;
  selectedLead = 0;
  actionButtons: TableRowActionsModel = new TableRowActions();
  approvedStatus: string = STATIC_DATA.APPROVED;
  leadStatus = '';
  isSectionEditable: boolean = false;

  @Input() pageId = '';
  @Input() tabId = '';
  @Input() programCode = '';
  @Input() set sectionData(data: string) {
    this.steps = data;
  }

  @Output() leadUpdated = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private sectionService: SectionsService,
    private leadDetailsService: LeadDetailsService,
    private lookupService: LookupService,
    private cdr: ChangeDetectorRef,
    private logger: LoggerService,
    private authUtility: AuthorizationUtility
  ) {
    super();
  }

  ngOnInit(): void {
    this.entry = localStorage.getItem('lead_entry')!;
    this.communicationType =
      this.lookupService.getLookupValue('Communication_Type');
    this.isSectionEditable = this.authUtility.isSectionEditable(this.pageId, this.tabId, 'CommunicationMethods');

    this.selectedLead = JSON.parse(
      localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!
    );
    this.leadDetailsService.getLeadStatus().subscribe((status: string) => {
      this.leadStatus = status;
      console.log('Lead Status', status);
      if (this.leadStatus === this.approvedStatus) {
        this.actionButtons.edit = false;
        this.actionButtons.delete = false;
      } else {
        this.actionButtons.edit = true;
        this.actionButtons.delete = true;
      }
    });
    this.createFormGroup();
    this.getFromData();
    this.leadDetailsService.getUpdatedLeadData().subscribe((data) => {
      this.gridData = data?.responsedata?.communicationData?.contactMethods;
      this.copyAndTransformGridData(this.gridData);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes['sectionData'] && changes['sectionData'].currentValue) {
        this.secData = this.steps['communication_data_contact_methods'];
      }
    } catch (e) {
      this.logger.debug(
        'Error in getting section data and updating latest info',
        e
      );
    }
  }

  createFormGroup() {
    this.contactForm = this.fb.group({
      contactMethod: [''],
      leadCommuniDetailsId: [''],
      id: [],
      value: [],
      preferred: [true],
    });
  }

  async getFromData() {
    //table column information
    try {
      if (this.entry) {
        const tempCols = await this.sectionService.getJsonConfigurationData(
          STATIC_JSON_DATA_PATH.LEAD_DETAILS_CONTACT_LIST_HEADERS,
          this.entry
        );

        this.cols = tempCols?.contactMethods?.columns;
      }
    } catch (e) {
      this.logger.debug('Error in getting table headers', e);
    }
  }

  cancelEdit() {
    this.mode = 'read';
    this.validationErrors = {};
    this.contactForm.reset();
    setTimeout(() => this.cdr.detectChanges(), 1000);
  }

  action(event: any) {
    console.log('acto', event);

    const selectedCommId = this.extractId(event?.data);

    const selectedData = this.gridData.find((row: any) => {

      if (
        [row.communiDetailsId, row.leadCommuniDetailsId, row.referralCommuniDetailsId, row.participantCommuniDetailsId].includes(selectedCommId)
      ) {
        return row;
      }
    });

    if (event && event.action === 'edit') {
      this.mode = 'edit';

      if (selectedData) {
        this.contactForm.patchValue(selectedData);

        if ([STATIC_DATA.REFERRAL, STATIC_DATA.PARTICIPANT_ENROLLMENT, STATIC_DATA.PROVIDER_ENROLLMENT].includes(this.entry)) {
          this.contactForm.patchValue({
            leadCommuniDetailsId: selectedCommId,
            id: this.selectedLead,
          });
        }
      }
    }

    if (event && event.action === 'delete') {
      if (selectedData) {
        this.delete(selectedData);
      }
    }
  }

  copyAndTransformGridData(gridData: any[]) {
    if (gridData) {
      const copyGridData = JSON.parse(JSON.stringify(gridData));

      this.copyOfGridData = copyGridData.map((item: any) => ({
        ...item,
        preferred: item.preferred ? 'Yes' : 'No',
        contactMethod:
          super.getLookupValue(this.communicationType, item.contactMethod) ||
          item.contactMethod,
        modifiedDate: super.formatDateString(item.modifiedDate),
        leadCommuniDetailsId:
          item.leadCommuniDetailsId || item.referralCommuniDetailsId || item.communiDetailsId,
      }));

      this.copyOfGridData = [...this.copyOfGridData];
      if (this.leadStatus !== this.approvedStatus) {
        this.actionButtons.edit = true;
        this.actionButtons.delete = true;
      } else {
        this.actionButtons.edit = false;
        this.actionButtons.delete = false;
      }
    } else {
      this.copyOfGridData = [];
      this.actionButtons.edit = false;
      this.actionButtons.delete = false;
    }
  }

  add() {
    this.mode = 'add';
    this.contactForm.reset();
    this.contactForm.controls['id'].setValue(this.selectedLead);
  }

  save() {
    const data = { ...this.contactForm.value };

    try {
      this.validationErrors = performValidation(data, this.secData, 'Save');
    } catch (e) {
      this.logger.debug('Error in validations', e);
    }

    if (Object.keys(this.validationErrors).length === 0) {
      this.target = this.preparePayload(data);
      this.leadDetailsService
        .createUpdateLeadCommunicationMethods(this.target, this.entry)
        .subscribe(
          {
            next: () => {
              this.logger.info('Contact method saved successfully');
              this.leadUpdated.emit(true);
              this.cancelEdit();
            },
            error: () => {
              this.leadUpdated.emit(true);
              this.cancelEdit();
            }
          }
        );
    }
  }

  delete(commMethod: any) {
    const id = this.extractId(commMethod)
    this.leadDetailsService
      .deleteLeadCommunicationMethod(id, this.entry, this.programCode)
      .subscribe(() => {
        this.logger.info('Contact method deleted successfully');
        this.leadUpdated.emit(true);
        /* this.getLatestLeadData(); */
      });
  }

  preparePayload(
    source: LeadDetailCommunicationModel,

  ): LeadDetailCommunicationUpdadeModel {
    const communiDetailsId: number | null = source.leadCommuniDetailsId ?? null;
    const existingContactMethod = this.gridData?.find(
      (row) => {
        if (
          communiDetailsId && [row.communiDetailsId, row.leadCommuniDetailsId, row.referralCommuniDetailsId, row.participantCommuniDetailsId].includes(communiDetailsId)
        ) {
          return row;
        }
      }
    );
    return {
      id: this.selectedLead,
      programCode: this.programCode,
      communiDetailsId,
      communicationMethod: {
        ...source,
        modifiedDate: existingContactMethod?.modifiedDate
      },
    };
  }


  extractId(commMethod: any) {
    let id = commMethod?.leadCommuniDetailsId;
    if (this.entry === STATIC_DATA.REFERRAL) {
      id = commMethod.referralCommuniDetailsId;
    }
    if (this.entry === STATIC_DATA.PARTICIPANT_ENROLLMENT) {
      id = commMethod.participantCommuniDetailsId
    }
    if (this.entry === STATIC_DATA.PROVIDER_ENROLLMENT) {
      id = commMethod.communiDetailsId
    }
    return id;
  }

}
