/* src/app/components/leads/enrollment/enrollment-comm-preferences/enrollment-comm-preferences.component.css */
.ppl-form-item {
  margin: 4px 8px !important;
}
.ppl-step-main {
  padding: 0px !important;
  border-radius: 0 !important;
  background-color: #eaeaea;
}
::ng-deep .ant-card-body {
  padding: 0 24px !important;
}
::ng-deep .ant-form-horizontal .ant-form-item-label {
  margin: 0px !important;
}
.ppl-form-control {
  margin-bottom: 4px !important;
}
/*# sourceMappingURL=enrollment-comm-preferences.component-AMNFUDWA.css.map */
