import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { BehaviorSubject, Observable, catchError, from, switchMap, tap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserDataResponse } from '../interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.apiUrl;
  userDetailsSubject$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private http: HttpClient, private authService: MsalService) {
  }

  getUsers(): Observable<UserDataResponse> {
     return this.http.post<UserDataResponse>(`${this.apiUrl}/api/v1/getAuthUserData`, {}).pipe(tap(response => {
      this.userDetailsSubject$.next(response); // Cache the response
    }),

     );
  };

  setToken(): void {
    this.authService.acquireTokenSilent({
      scopes: [...environment.apiConfig.scopes],
    }).subscribe({
      next: (response) => {
        console.log("token", response);
        localStorage.setItem("access_token", response.accessToken);
      },
      error: (error) => {
        console.error('Error acquiring token:', error);
        if (error instanceof InteractionRequiredAuthError) {
          this.authService.loginRedirect({
            scopes: [...environment.apiConfig.scopes]
          });
        }
      }
    });
  };

  refreshToken(): Observable<void> {
    return from(this.authService.acquireTokenSilent({
      scopes: [...environment.apiConfig.scopes]
    })).pipe(
      switchMap(response => {
        localStorage.setItem("access_token", response.accessToken);
        return new Observable<void>(observer => {
          observer.next();
          observer.complete();
        });
      }),
      catchError(error => {
        if (error instanceof InteractionRequiredAuthError) {
          this.authService.loginRedirect({
            scopes: [...environment.apiConfig.scopes]
          });
        }
        return throwError(error);
      })
    );
  }

  logout() {
    localStorage.clear();
    this.authService.logoutRedirect();
  }

  isTokenExpired(token: string | null): boolean {
    if (!token) return true; // No token, so consider expired
    const tokenParts = token.split('.');
    const tokenPayload = JSON.parse(atob(tokenParts[1]));
    const tokenExpiration = tokenPayload.exp;
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return currentTimestamp >= tokenExpiration;
  }

  


}