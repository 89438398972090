@if(mode==="read"){
<div class="ppl-mt8">
    <h2>Associations</h2>
</div>
@if(flow === 'participantEnrollment'){
    <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="false"
    [data]="participantAssociations" [disableSelection]="true" [cols]="participantCols" [gridTitle]="false"
    [pagination]="false" [loadPage]="true" [actionButtons]="null">
</ppl-list-table>
}
@if(flow === 'providerEnrollment'){
<ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="false" [data]="associations"
    [disableSelection]="true" [cols]="associationCols" [gridTitle]="false" [pagination]="false" [loadPage]="true"
    [actionButtons]="null"></ppl-list-table>
}
}
@else{
<div class="ppl-mt8">
    <h2>Add Associations</h2>
</div>
@if(isStepMandatory){
    <div class="ppl-form-item">
        <p class="info-box">
          <i class="fa fa-info-circle" aria-hidden="true"></i> <span>Please add at least one association</span>
        </p>
    </div>
}
<div class="ppl-mt16 btn-group" style="display: flex; gap: 8px;">
    <!-- <button nzType="primary" class="ppl-mr16 btn-hide" nz-button [nzSize]="'small'" (click)="expandAll()">Expand
      All</button>
    <button nzType="primary" nz-button class="btn-hide" [nzSize]="'small'" (click)="hideAll()">Hide All</button> -->
    <ppl-button label="Expand All" icon="fa fa-expand" [customClass]="'alt-button'" [iconPosition]="'right'"
        (click)="expandAll()"></ppl-button>
    <ppl-button label="Collapse All" icon="fa fa-compress" [customClass]="'alt-button'" [iconPosition]="'right'"
        (click)="hideAll()"></ppl-button>
</div>
<div>
    <form nz-form [formGroup]="newContactForm" class="ppl-p0">
        <nz-collapse [nzExpandIconPosition]="'end'">
            @if(isAttrAvailable('newEmployer')){
            <nz-collapse-panel [nzHeader]="'Employer Details'" [nzActive]="isPanelActive('1')"
                (nzClick)="togglePanel('1')">
                @if (getProviderData('EMP').length >0) {
                <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true"
                    [data]="getProviderData('EMP')" [disableSelection]="true" [cols]="cols" [gridTitle]="false"
                    [pagination]="false" [loadPage]="true" [actionButtons]="actions" (gridAction)="action($event)">
                </ppl-list-table>
                }
                @if(getProviderData('EMP').length < 1) { <div
                    style="display: flex; justify-content: flex-end; margin-top: 16px;">
                    <ppl-button label="Add Employer" icon="fa fa-plus" [customClass]="'ghost-button'"
                        [iconPosition]="'left'" (click)="addAssociation('employer')"></ppl-button>
</div>
}
</nz-collapse-panel>
}
@if(isAttrAvailable('newAuthRep')){
<nz-collapse-panel [nzHeader]="'Designated Representative Details'" [nzActive]="isPanelActive('2')"
    (nzClick)="togglePanel('2')">
    @if (getProviderData('AR').length >0) {

    <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true" [data]="getProviderData('AR')"
        [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false" [loadPage]="true"
        [actionButtons]="actions" (gridAction)="action($event)">
    </ppl-list-table>
    }
    <div style="display: flex; justify-content: flex-end; margin-top: 16px;">
        <ppl-button label="Add Designated Representative" icon="fa fa-plus" [customClass]="'ghost-button'"
            [iconPosition]="'left'" (click)="addAssociation('authRepresentative')"></ppl-button>
    </div>
</nz-collapse-panel>
}
@if(isAttrAvailable('newProvider')){
<nz-collapse-panel [nzHeader]="'Personal Assistant Details'" [nzActive]="isPanelActive('3')"
    (nzClick)="togglePanel('3')">
    @if (getProviderData('IR').length >0) {
    <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true" [data]="getProviderData('IR')"
        [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false" [loadPage]="true"
        [actionButtons]="actions" (gridAction)="action($event)">
    </ppl-list-table>
    }
    <div class="actions-container">
        <ppl-button label="Search" icon="fa fa-magnifying-glass" [customClass]="'ghost-button'" [iconPosition]="'left'"
        (click)="addAssociation('provider', editMode.Search)"></ppl-button>
        <ppl-button label="Add Personal Assistant" icon="fa fa-plus" [customClass]="'ghost-button'" [iconPosition]="'left'"
            (click)="addAssociation('provider', editMode.Edit)"></ppl-button>
    </div>
</nz-collapse-panel>
}
@if(isAttrAvailable('newVendor')){
<nz-collapse-panel [nzHeader]="'Vendor Details'" [nzActive]="isPanelActive('4')" (nzClick)="togglePanel('4')">
    @if (getProviderData('Vendor').length >0) {
    <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true"
        [data]="getProviderData('Vendor')" [disableSelection]="true" [cols]="cols" [gridTitle]="false"
        [pagination]="false" [loadPage]="true" [actionButtons]="actions" (gridAction)="action($event)">
    </ppl-list-table>
    }
    <div style="display: flex; justify-content: flex-end; margin-top: 16px;">
        <ppl-button label="Add Vendor" icon="fa fa-plus" [customClass]="'ghost-button'" [iconPosition]="'left'"
            (click)="addAssociation('vendor')"></ppl-button>
    </div>
</nz-collapse-panel>
}
@if(isAttrAvailable('newContractor')){
<nz-collapse-panel [nzHeader]="'Independent Contractor Details'" [nzActive]="isPanelActive('5')"
    (nzClick)="togglePanel('5')">
    @if (getProviderData('IC').length >0) {
    <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true" [data]="getProviderData('IC')"
        [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false" [loadPage]="true"
        [actionButtons]="actions" (gridAction)="action($event)">
    </ppl-list-table>
    }
    <div style="display: flex; justify-content: flex-end; margin-top: 16px;">
        <ppl-button label="Add Contractor" icon="fa fa-plus" [customClass]="'ghost-button'" [iconPosition]="'left'"
            (click)="addAssociation('contractor')"></ppl-button>
    </div>
</nz-collapse-panel>
}
@if(isAttrAvailable('newLegalGuardian')){
<nz-collapse-panel [nzHeader]="'Legal Guardian Details'" [nzActive]="isPanelActive('6')" (nzClick)="togglePanel('6')">
    @if (getProviderData('LG').length >0) {
    <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true" [data]="getProviderData('LG')"
        [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false" [loadPage]="true"
        [actionButtons]="actions" (gridAction)="action($event)">
    </ppl-list-table>
    }
    <div style="display: flex; justify-content: flex-end; margin-top: 16px;">
        <ppl-button label="Add Legal Guardian" icon="fa fa-plus" [customClass]="'ghost-button'" [iconPosition]="'left'"
            (click)="addAssociation('legalGuardian')"></ppl-button>
    </div>
</nz-collapse-panel>
}
@if(isAttrAvailable('newPOA')){
<nz-collapse-panel [nzHeader]="'POA Details'" [nzActive]="isPanelActive('7')" (nzClick)="togglePanel('7')">
    @if (getProviderData('POA').length >0) {
    <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true" [data]="getProviderData('POA')"
        [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false" [loadPage]="true"
        [actionButtons]="actions" (gridAction)="action($event)">
    </ppl-list-table>
    }
    <div style="display: flex; justify-content: flex-end; margin-top: 16px;">
        <ppl-button label="Add POA" icon="fa fa-plus" [customClass]="'ghost-button'" [iconPosition]="'left'"
            (click)="addAssociation('poa')"></ppl-button>
    </div>
</nz-collapse-panel>
}
</nz-collapse>
</form>
</div>
}