import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

type AlertType = 'success' | 'info' | 'warning' | 'error' | 'blank' | string;
type title = string;
type message = string;

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private alertSubject = new BehaviorSubject<{ type: AlertType; title: title; message: message} | null>(null);
  alert$ = this.alertSubject.asObservable();
  activeRequests = 0;
  loaderSubject$ = new Subject<boolean>();

  success(title: title, message: message) {
    this.alert('success', title, message);
  }

  error(title: title, message: message) {
    this.alert('error', title, message);
  }

  alert(type: AlertType, title: title, message: message) {
    this.alertSubject.next({type, title, message});
  }

  showLoader() {
    this.loaderSubject$.next(true);
  }

  hideLoader() {
    this.loaderSubject$.next(false);
  }

}
