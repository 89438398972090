import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { PagesAuthConfigResponse, UserDetails } from "../../interfaces/user.interface";
import { ProgramService } from "../../services/program.service";
import { UserService } from "../../services/user.service";
import { AuthorizationConfig } from "./auth.config";

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {
    private authConfig: AuthorizationConfig;

    public constructor(private userService: UserService,
        private programService: ProgramService) {
        const userDetails = this.userService.getUserDetails();
        this.authConfig = {
            restrictedRoutes: this.removeDuplicates(userDetails.restrictedRoutes),
            pageAuthorization: userDetails.pageAuthorization
        }
    }

    public getAuthorizationConfig() {
        return this.authConfig;
    }

    public fetchPageAuthorization(programCode: string, pageId: string, pageType: string): Observable<any> {
        return this.programService.fetchPageAuthorization(programCode, [pageId], pageType).pipe(
            map(response => {
                if (response) {
                    const pageConfig = this.loadPageConfiguration(pageId, response);
                    return pageConfig
                }

                return {};
            }));
    }

    private removeDuplicates(arr: string[]) {
        return arr.filter((item,
            index) => arr.indexOf(item) === index);
    }

    private loadPageConfiguration(pageId: string, response: PagesAuthConfigResponse) {
        const userDetails: UserDetails = JSON.parse(localStorage.getItem('user_details') || '{}');
        let pageConfig = {};
        response.responsedata.forEach(response => {
            if (!userDetails.pageAuthorization) {
                userDetails.pageAuthorization = {};
            }
            userDetails.pageAuthorization[response.pageName] = response;
            if (response.pageName.toLocaleLowerCase() == pageId.toLocaleLowerCase()) {
                pageConfig = response;
            }
        });

        localStorage.setItem('user_details', JSON.stringify(userDetails));
        return pageConfig
    }
}