<div class="ppl-mt8">
  <h2>Summary</h2>
  <p class="ppl-description">Please review the details on this summary page. If any updates are needed, return to the
    respective sections to make adjustments.
  </p>
</div>
<div class="ppl-mt16 btn-group" style="display: flex; gap: 8px;">
  <!-- <button nzType="primary" class="ppl-mr16 btn-hide" nz-button [nzSize]="'small'" (click)="expandAll()">Expand
    All</button>
  <button nzType="primary" nz-button class="btn-hide" [nzSize]="'small'" (click)="hideAll()">Hide All</button> -->
  <ppl-button label="Expand All" icon="fa fa-expand" [customClass]="'alt-button'" [iconPosition]="'right'"
    (click)="expandAll()"></ppl-button>
  <ppl-button label="Collapse All" icon="fa fa-compress" [customClass]="'alt-button'" [iconPosition]="'right'"
    (click)="hideAll()"></ppl-button>
</div>
<div>
  <!-- <div class="expand-collapse-container"> -->
  <div class="infoBox" style="margin-top: 0.8rem;">
    <i class="fa fa-info-circle" aria-hidden="true"></i> Your form will be processed by our team and we will contact you
    directly if we have any further questions.
  </div>
  <!-- </div> -->
  <nz-collapse [nzExpandIconPosition]="'end'">
    @if(getSteps('participant_info')?.isAvailable === "Y"){
    <nz-collapse-panel [nzHeader]="getSteps('participant_info')?.step_name" [nzActive]="isPanelActive('1')"
      (nzClick)="togglePanel('1')">
      <ppl-participant-info [mode]="'read'"></ppl-participant-info>
    </nz-collapse-panel>
    }
    @if(getSteps('service_details')?.isAvailable === "Y"){
    <nz-collapse-panel [nzHeader]="getSteps('service_details')?.step_name" [nzActive]="isPanelActive('2')"
      (nzClick)="togglePanel('2')">
      <ppl-service-details [mode]="'read'"></ppl-service-details>
    </nz-collapse-panel>
    }
    @if(getSteps('contact_details')?.isAvailable === "Y"){
    <nz-collapse-panel [nzHeader]="getSteps('contact_details')?.step_name" [nzActive]="isPanelActive('3')"
      (nzClick)="togglePanel('3')">
      <ppl-contact-details [mode]="'read'"></ppl-contact-details>
    </nz-collapse-panel>
    }
    @if(getSteps('comm_preferences')?.isAvailable === "Y"){
    <nz-collapse-panel [nzHeader]="getSteps('comm_preferences')?.step_name" [nzActive]="isPanelActive('4')"
      (nzClick)="togglePanel('4')">
      <ppl-comm-preference [mode]="'read'"></ppl-comm-preference>
    </nz-collapse-panel>
    }
    @if(getSteps('associations')?.isAvailable === "Y"){
    <nz-collapse-panel [nzHeader]="getSteps('associations')?.step_name" [nzActive]="isPanelActive('5')"
      (nzClick)="togglePanel('5')">
      <ppl-new-contact [mode]="'read'"></ppl-new-contact>
    </nz-collapse-panel>
    }
    @if(getSteps('signatures')?.isAvailable === "Y"){
    <nz-collapse-panel [nzHeader]="getSteps('signatures')?.step_name" [nzActive]="isPanelActive('6')"
      (nzClick)="togglePanel('6')">
      <ppl-upload-signatures [mode]="'read'"></ppl-upload-signatures>
    </nz-collapse-panel>
    }
    @if(getSteps('forms')?.isAvailable === "Y"){
    <nz-collapse-panel [nzHeader]="getSteps('forms')?.step_name" [nzActive]="isPanelActive('7')"
      (nzClick)="togglePanel('7')">
      <ppl-form-checklist  [mode]="'read'"></ppl-form-checklist>
    </nz-collapse-panel>
    }
    @if(getSteps('checklist')?.isAvailable === "Y"){
      <nz-collapse-panel [nzHeader]="getSteps('checklist')?.step_name" [nzActive]="isPanelActive('7')"
        (nzClick)="togglePanel('8')">
        <ppl-participant-checklist></ppl-participant-checklist>
      </nz-collapse-panel>
    }
    @if(getSteps('upload_docs')?.isAvailable === "Y"){
    <nz-collapse-panel [nzHeader]="getSteps('upload_docs')?.step_name" [nzActive]="isPanelActive('8')"
      (nzClick)="togglePanel('9')">
      <ppl-upload-docs [mode]="'read'"></ppl-upload-docs>
    </nz-collapse-panel>
    }
    @if(getSteps('payment_details')?.isAvailable === "Y"){
    <nz-collapse-panel [nzHeader]="getSteps('payment_details')?.step_name" [nzActive]="isPanelActive('9')"
      (nzClick)="togglePanel('10')">
      <ppl-payment-details [mode]="'read'"></ppl-payment-details>
    </nz-collapse-panel>
    }
  </nz-collapse>
</div>