/* src/app/shared/menu-drop-button/menu-drop-button.component.css */
.dropdown-container {
  position: relative;
  display: inline-block;
}
.button-icon {
  margin-right: 0.5em;
}
.dropdown-list {
  position: absolute;
  top: 38px;
  left: -446px;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  width: 560px;
  height: 50vh;
  gap: 8px;
  padding: 4px;
  margin: 4px;
  font-family: Outfit;
  font-size: 1.143rem;
  text-align: left;
  list-style: none;
  background-color: rgba(255 255 255 / 10%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}
.dropdown-list::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 520px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgb(244, 244, 244, 0.86);
}
.dropdown-item {
  margin: 12px;
  padding: 20px;
  display: flex;
  clear: both;
  flex-direction: row;
  font-family: Outfit;
  font-weight: 400;
  color: #333;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
  cursor: pointer;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: flex-start;
}
.dropdown-item:hover,
.dropdown-item:focus {
  transition: background-color 0.3s ease;
  color: #262626;
  text-decoration: none;
}
.menu-drop-button {
  display: flex;
  width: 120px;
  padding: 8px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;
  background: rgba(30 30 30 / 12%);
  border: 2px solid rgba(30, 30, 30, 0.44);
  font-family: Outfit;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  color: #F0F0F0;
}
.menu-drop-button:hover {
  cursor: pointer;
  transition: background-color 0.3s ease;
  background: #000000bd;
}
/*# sourceMappingURL=menu-drop-button.component-MAPOA6YQ.css.map */
