{
    "pre_referral_step1": {
        "sectionId": "pre_referral_step1",
        "header_1": "Consumer Interest",
        "text_1": "Please start by telling us some details",
        "available_actions":[{
            "button_name":"begin_referall_id",
            "isAvailable": "Y"
        }],
        "attributes": [
            {
                "attribute_ID": "state",
                "fieldName": "state",
                "label": "What is your state of residence?",
                "type": "dropdown",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please specify your state of residence",
                "isAvailable": "Y",
                "placeholder": "Select State"
            }
        ],
        "validations": [
            {
                "whatToCheck": "atLeastXEntered",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "state"
                        ],
                        "functionParams": {
                            "ErrMsg": "Please Select your State of Residence"
                        }
                    }
                ]
            }
        ],
        "actions": [
            {
                "Name": "Next",
                "Validate": [
                    {
                        "checkType": "atLeastXEntered",
                        "validatorid": [
                            1
                        ]
                    }
                ]
            }
        ]
    }
}