 
 <!--    <nz-card style="margin-left: 2%">  -->
<!--         <nz-collapse [nzExpandIconPosition]="'end'" >
            <nz-collapse-panel [nzHeader]="'Status Comments'" nzActive="panel.active"> -->
                <div class="custom-row">
                    <!-- eslint-disable-next-line @angular-eslint/template/elements-content -->
                    <button 
                        aria-label="Add comment"
                        class="remove-btn-defaults" 
                        nz-icon 
                        nzType="plus-circle" 
                        nzTheme="outline" 
                        (click)="enableComments()"
                    ></button>
                </div>

                @if(showCommentBox){
                    <form [formGroup]="commentsForm" class="ppl-p0">


                    <nz-row>
                        <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="Please enter comments"
                            nzTooltipPlacement="topRight">
                            <nz-textarea-count [nzMaxCharacterCount]="10000">
                            <textarea rows="4" 
                            nz-tooltip
                            nz-input
                            aria-label="Comments"
                            [maxlength]="10000"
                            nzTooltipTitle="{{ getAttributes('comments').tooltip }}"
                            nzTooltipPlacement="topRight"
                            formControlName="comments"
                            id="comments"
                            placeholder="{{ getAttributes('comments').placeholder }}"
                            [ngClass]="{'error': validationErrors['comments'] }"
                                  ></textarea>
                            </nz-textarea-count>
                    <span class="pl-1 error-message">{{validationErrors['comments']}}</span>
  

                
                </nz-form-control>
                    </nz-row>
                    <nz-row  [nzJustify]="'center'" style="margin-bottom: 1rem;">
                        <nz-col>
                            <!-- eslint-disable-next-line @angular-eslint/template/elements-content -->
                            <button
                                aria-label="Cancel comment"
                                class="remove-btn-defaults"
                                (click)="cancelComments()"
                                nz-icon
                                nzType="close-circle"
                                nzTheme="outline"
                            ></button>
                            <!-- eslint-disable-next-line @angular-eslint/template/elements-content -->
                            <button 
                                aria-label="Save comment"
                                class="remove-btn-defaults" 
                                nz-icon 
                                nzType="save" 
                                nzTheme="outline" 
                                (click)="saveComments()"
                            ></button>
                        </nz-col>
                    </nz-row>
                    </form>
                    
                    <nz-divider></nz-divider>
                }
                
                <!-- @if(comments.length > 0){
                @for (comment of comments; track $index) {
                    <div class="mb-3">
                        
                        <nz-row>
                            <nz-form-label class="ppl-form-label font-weight-600">1Comment:&nbsp;</nz-form-label><nz-form-label>{{comment.leadUserComment ? comment.leadUserComment : '-'}}</nz-form-label>
                        </nz-row>
                        <nz-row>
                            <nz-form-label class="ppl-form-label font-weight-600">2Comment by:&nbsp;</nz-form-label>
                            <nz-form-label>{{comment.modifiedByName}}</nz-form-label>
                        </nz-row>
                        <nz-row>
                            <nz-form-label class="ppl-form-label font-weight-600">Date:&nbsp;</nz-form-label>
                            <nz-form-label>{{comment.modifiedDate | date : 'medium'}}</nz-form-label>
                        </nz-row>
                        @if(!$last){
                            <div class="mt-3">
                                <nz-divider></nz-divider>
                            </div>
                        }
                        
                    </div>

                }
            } -->
            @if(comments.length > 0){
                <ppl-list-table
                [disableSort]="true"
                [disableSearch]="true"
                [data]="copyOfGridData"
                [disableSelection]="true"
                [cols]="cols" 
                [gridTitle]="false"
                [pagination]="false"
                [loadPage]="true"
                [tableWidth]="'30rem'"
              >
              </ppl-list-table>
              }@else {
                <nz-row>
                  <p>No Data found</p>
                </nz-row>
              }
                
<!--             </nz-collapse-panel>
        </nz-collapse> -->
        
            
   <!--  </nz-card> -->
