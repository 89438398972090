<ppl-accordion [title]="'Additional Attribute'" [isOpen]="true">
  @switch (mode) {
  @case ('read') {
  <div class="ant-row" style="justify-content: center;">
    <div class="ant-col-24">
      <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true" (gridAction)="action($event)"
        [data]="gridData" [disableSelection]="true" [actionButtons]="actionButtons" [cols]="cols" [gridTitle]="false"
        [pagination]="false"></ppl-list-table>
    </div>
  </div>
  <div class="ant-row" style="justify-content: center;">
    @if(isSectionEditable) {

    <div class="ant-col-6" style="padding-top: 4px;">
      <nz-select pplNzAriaLabel nzAriaLabel="Select Attribute" [nzMaxTagCount]="4" nzMode='default'
        id="selectedAttribute" [(ngModel)]="selectedAttributeType" required="true"
        nzPlaceHolder="Select attribute to be added" (ngModelChange)="validateSelectedAttribute($event)"
        style="width:100%; margin-bottom: 16px" nzShowArrow>
        @for(opt of options; track $index){
        <nz-option [nzLabel]="opt.attribute_text" [nzValue]="opt" [nzDisabled]="opt.disabled"></nz-option>
        }
      </nz-select>
      @if(displayTypeError) {
      <div class="pl-1 error-message">Attribute type is required</div>
      }
    </div>
    }

    @if(selectedAttributeType){
    <div class="ant-col-4" style="padding-top: 4px; padding-left: 10px;">
      @if(fieldType === 'dropdown'){
      <nz-select pplNzAriaLabel nzAriaLabel="Type" [nzMaxTagCount]="4" nzMode='default' id="selectedAttribute"
        [(ngModel)]="attributeValue" required="true" nzPlaceHolder="Select" style="width:100%; margin-bottom: 16px"
        nzShowArrow>
        @for(opt of lookupData[selectedAttribute]; track $index){
        <nz-option [nzLabel]="opt.value" [nzValue]="opt.value" [nzDisabled]="opt.disabled"></nz-option>
        }
      </nz-select>
      }
      @else if(dataType === 'text'){

      <input nz-tooltip nzTooltipPlacement="topRight" nz-input id="attributeValue" [(ngModel)]="attributeValue"
        (ngModelChange)="validateAttributeValue($event)" required="true" placeholder="Field Value" />

      @if(displayValueError) {
      <div class="pl-1 error-message">Attribute value is required</div>
      }
      }@else {
      <nz-date-picker [(ngModel)]="attributeValue" id="attributeValue" [nzFormat]="'MM/dd/yyyy'"></nz-date-picker>
      }
      @if(displayValueError) {
      <div class="pl-1 error-message">Attribute value is required</div>
      }
    </div>

    @if(displayDates) {
    <div class="ant-col-3" style="padding-top: 4px; padding-left: 10px;">
      <nz-date-picker [(ngModel)]="startDate" id="startDate" [nzFormat]="'MM/dd/yyyy'"></nz-date-picker>

      @if(displayStartDateError) {
      <div class="pl-1 error-message">Start date is required</div>
      }

    </div>
    <div class="ant-col-3" style="padding-top: 4px; padding-left: 10px;">
      <nz-date-picker [(ngModel)]="endDate" id="endDate" [nzFormat]="'MM/dd/yyyy'"></nz-date-picker>
      @if(displayEndDateError) {
      <div class="pl-1 error-message">End date is required</div>
      }
    </div>
    }
    }



    @if(isSectionEditable) {
    <div class="ant-col-2" style="padding-left: 20px;">
      <ppl-button label="Add" icon="fa fa-plus" [customClass]="'ghost-button'" [iconPosition]="'left'"
        (click)="addAttributes()"></ppl-button>
    </div>
    }
  </div>
  }

  @case('edit'){
  <div class="custom-row">

    <ppl-button label="Cancel" icon="fa fa-ban" [customClass]="'alt-button'" [iconPosition]="'left'"
      (click)="cancelEdit()"></ppl-button>

    <ppl-button label="Save" icon="fa fa-floppy-o" [customClass]="'alt-button'" [iconPosition]="'left'"
      (click)="save()"></ppl-button>
  </div>
  <div class="ant-row" style="justify-content: center;">
    <div class="ant-col-6" style="padding-top: 4px;">
      <input nz-tooltip nzTooltipPlacement="topRight" nz-input id="selectedAttribute"
        [(ngModel)]="selectedAttributeType" [disabled]="true" placeholder="Selected Attribute" />
    </div>
    <div class="ant-col-4" style="padding-top: 4px; padding-left: 10px;">
      @if(fieldType === 'dropdown'){
      <nz-select pplNzAriaLabel nzAriaLabel="Type" [nzMaxTagCount]="4" nzMode='default' id="selectedAttribute"
        [(ngModel)]="attributeValue" required="true" nzPlaceHolder="Select" style="width:100%; margin-bottom: 16px"
        nzShowArrow>
        @for(opt of lookupData[selectedAttribute]; track $index){
        <nz-option [nzLabel]="opt.value" [nzValue]="opt.value" [nzDisabled]="opt.disabled"></nz-option>
        }
      </nz-select>
      }
      @else if(dataType === 'text'){

      <input nz-tooltip nzTooltipPlacement="topRight" nz-input id="attributeValue" [(ngModel)]="attributeValue"
        (ngModelChange)="validateAttributeValue($event)" required="true" placeholder="Field Value" />

      @if(displayValueError) {
      <div class="pl-1 error-message">Attribute value is required</div>
      }
      }@else {
      <nz-date-picker [(ngModel)]="attributeValue" id="attributeValue" [nzFormat]="'MM/dd/yyyy'"></nz-date-picker>
      }
      @if(displayValueError) {
      <div class="pl-1 error-message">Attribute value is required</div>
      }
    </div>

    @if(displayDates) {
    <div class="ant-col-3" style="padding-top: 4px; padding-left: 10px;">
      <nz-date-picker [(ngModel)]="startDate" id="startDate" [nzFormat]="'MM/dd/yyyy'"></nz-date-picker>

      @if(displayStartDateError) {
      <div class="pl-1 error-message">Start date is required</div>
      }

    </div>
    <div class="ant-col-3" style="padding-top: 4px; padding-left: 10px;">
      <nz-date-picker [(ngModel)]="endDate" id="endDate" [nzFormat]="'MM/dd/yyyy'"></nz-date-picker>
      @if(displayEndDateError) {
      <div class="pl-1 error-message">End date is required</div>
      }
    </div>
    }
  </div>

  }
  }

</ppl-accordion>


<ppl-popup-dialog [(isVisible)]="showConfirmationDialog" [title]="'Delete Attribute'" [isOkText]="'Delete'"
  (cancel)="onClose()" [showButtons]="true" [position]="'top'" size="medium" (confirm)="handleConfirmDelete()">
  <div>
    <h3 class="ppl-gray-text mb-4">
      Are you sure you wish to delete?
    </h3>
    <p>
      This action cannot be undone. </p>
  </div>
</ppl-popup-dialog>