/* src/app/components/lead-details/associations/association-details-address/association-details-address.component.css */
::ng-deep .ant-card-body {
  padding: 0px !important;
}
::ng-deep .ant-form-horizontal .ant-form-item-label {
  margin: 0px !important;
}
.ppl-form-control {
  margin-bottom: 4px !important;
}
.ppl-form-item {
  margin: 4px 8px 4px 0px !important;
}
::ng-deep .ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0px;
}
/*# sourceMappingURL=association-details-address.component-PGVXNIJ4.css.map */
