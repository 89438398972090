
/**
 * 
 * @param template url template with placeholders in the form of {key}
 * @param params parameters to replace the placeholders in the template
 * @returns string formatted URL with replaced placeholders
 */


export function formatUrl(template: string, params: Record<string, string | number>, notificationService? : any): string {
    let url = template;
    for (const [key, value] of Object.entries(params)) {
      try{
        url = url.replace(`{${key}}`, encodeURIComponent(value.toString()));
      }catch(e : unknown){
        if(notificationService){
          console.log(e);
          notificationService.alert('error', 'Error', 'Error formatting URL with placeholder values. Please check your input data.');
        }
      }
      
    }
    return url;
  }