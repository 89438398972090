[
  {
    "menu_label": "Global Consumer Search",
    "menu_id": "global_consumer_search",
    "display": "y",
    "landing_component": "globalConsumerSearch"
  },
  {
    "menu_label": "Consumer Referral",
    "menu_id": "referral",
    "display": "y",
    "landing_component": "referralList"
  },
  {
    "menu_label": "Registration",
    "menu_id": "enrollment",
    "display": "y",
    "landing_component": "enrollmentList",
    "links": [
      {
          "sub_menu_label": "Consumer Registration",
          "sub_menu_id": "participant_enrollment",
          "landing_component": "participantEnrollmentList",
          "display": "y"
      },
      {
          "sub_menu_label": "Personal Assistant Registration",
          "sub_menu_id": "provider_enrollment",
          "landing_component":"providerEnrollmentList",
          "display": "y"
      }
  ]
  },
  {
    "menu_label": "Process Codes",
    "menu_id": "process_codes",
    "display": "y",
    "landing_component": "processCodeList"
  },
  {
    "menu_label": "Service Codes",
    "menu_id": "service_codes",
    "display": "y",
    "landing_component": "serviceCodeList"
  },
  {
    "menu_label": "Consumer - Personal Assistant Rates",
    "menu_id": "participant_provider_rates",
    "display": "y",
    "landing_component": "participantProviderRatesList"
  },
  {
    "menu_label": "Payable - Personal Assistant Rates",
    "menu_id": "payable_provider_rates",
    "display": "y",
    "landing_component": "payablePersonalAssistantRates"
  },
  {
    "menu_label": "Billable - Personal Assistant Rates",
    "menu_id": "billable_provider_rates",
    "display": "y",
    "landing_component": "billablePersonalAssistantRates"
  },
  {
    "menu_label": "Authorizations",
    "menu_id": "authorizations",
    "display": "y",
    "landing_component": "authorizations"
  },
  {
    "menu_label": "Timesheets",
    "menu_id": "timesheets",
    "display": "y"
  },
  {
    "menu_label": "File Triage",
    "menu_id": "fileTriage",
    "display": "y",
    "landing_component": "fileTriage"
  },
  {
    "menu_label": "File Upload Notification",
    "menu_id": "fileUploadNotification",
    "display": "y",
    "landing_component": "fileUploadNotification"
  }
]