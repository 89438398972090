import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { finalize } from 'rxjs';
import { NotificationService } from '../shared/services/notification.service';
import { EXCLUDE_LOADER_URLS } from '../shared/constants/url.constants';

export const loaderInterceptor: HttpInterceptorFn = (req, next) => {
  const loaderService = inject(NotificationService);


  // Load the url list to be excluded
  const excludeLoaderUrls = EXCLUDE_LOADER_URLS;

  // check if request URL should exclude loader
  const shouldExcludeLoader = excludeLoaderUrls.some(url => req.url.includes(url));
  
  if(!shouldExcludeLoader){
    loaderService.activeRequests++;
  }
  
  if (loaderService.activeRequests > 0) {
    loaderService.showLoader();
  }

  return next(req).pipe(
    finalize(() => {
      // decrement each request
      if (!shouldExcludeLoader && loaderService.activeRequests > 0) {
        loaderService.activeRequests--;
      }

      // stop when last request
      if (!shouldExcludeLoader && loaderService.activeRequests === 0) {
        loaderService.hideLoader();
      }
    })
  );
};
