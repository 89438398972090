<!-- <div class="main"> -->
<div class="ppl-container-body">

    <div class="ppl-container-head">
        @for(header of extractValues('header'); track $index){
            {{header}}
        }
        <div class="action">
            <ppl-button
            label="" 
            ariaLabel="Close"
            icon="fa fa-close" 
            [customClass]="'rounded'" 
            [iconPosition]="'left'"
            (handleClick)="returnClicked()">
            ></ppl-button>
        </div>       
    </div>
    <div class="ppl-container">
        <div class="ppl-content-container">
            <nz-card class="ppl-main-halfwidth logic-section">
                @for(text of extractValues('text'); track $index){
                    <p class="ppl-description">{{text}}</p>
                    }
                <form nz-form [formGroup]="logicForm" class="ppl-p0">
                    @if(isAttrAvailable('state')){                  
                        <nz-form-label [nzSm]="16" [nzXs]="24" nzFor="state"
                            class="ppl-form-label">{{getAttributes('state').label}}
                            @if(getAttributes('state').mandatory === 'Y'){
                            <span>*</span>
                            }</nz-form-label>
                        <nz-form-control class="ppl-form-control">
                            <nz-select pplNzAriaLabel nzAriaLabel="Select State" formControlName="state" [ngClass]="{'error': validationErrors['state']}"
                                id="state" class="ppl-w100" nzShowSearch nzAllowClear
                                nzPlaceHolder="{{getAttributes('state').placeholder}}">
                                @for (state of states; track $index) {
                                <nz-option [nzLabel]="state.lkpValueName" [nzValue]="state.lkpValueCode"></nz-option>
                                }
                            </nz-select>
                            @if (validationErrors['state']) {
                            <span class="pl-1 error-message">{{validationErrors['state']}}</span>
                            }
                        </nz-form-control>
                    
                    }
                </form>
                @if(isBtnAvailable('begin_referall_id')){
                <!-- <button nz-button nzType="primary" (click)="onBegin()" nzBlock class="ppl-mt36">{{buttonText}}</button> -->
                <!-- <div style="padding-top: 36px"> -->
                    <ppl-button 
                    [label]="buttonText" 
                    [customClass]="'primary-button small'" 
                    [iconPosition]="'left'"
                    (click)="onBegin()"
                    ></ppl-button>
                }
           
            </nz-card>
        </div>
    </div>
</div>