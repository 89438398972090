import { GlobalSearchComponent } from './components/global-search/global-search/global-search.component';
import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './components/core/home/home.component';
import { AuthorizationComponent } from './components/lead-details/authorization/authorization.component';
import { MemberViewComponent } from './components/lead-details/member-view/member-view.component';
import { LeadInformationComponent } from './components/lead-details/participant-information/lead-information/lead-information.component';
import { CommPreferenceComponent } from './components/leads/comm-preference/comm-preference.component';
import { ContactDetailsComponent } from './components/leads/contact-details/contact-details.component';
import { NewReferralComponent } from './components/leads/new-referral/new-referral.component';
import { ParticipantInformationComponent } from './components/leads/participant-information/participant-information.component';
import { ReferralLogicComponent } from './components/leads/referral-logic/referral-logic.component';
import { SummaryComponent } from './components/leads/summary/summary.component';
import { ParticipantLeadsListComponent } from './components/participant-leads-list/participant-leads-list.component';
import { ParticipantLeadsComponent } from './components/participant-leads/participant-leads.component';
import { ProviderLeadsListComponent } from './components/provider-leads-list/provider-leads-list.component';
import { MessageComponent } from './shared/components/message/message.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EnrollmentListComponent } from './components/enrollment/enrollment-list/enrollment-list.component';
import { EnrollmentListComponentOld } from './components/enrollment/enrollment-list_old/enrollment-list.component';
import { NewEnrollmentComponent } from './components/enrollment/new-enrollment/new-enrollment.component';
import { ParticipantEnrollmentListComponent } from './components/enrollment/participant-enrollment-list/participant-enrollment-list.component';
import { PaymentDetailsComponent } from './components/enrollment/payment-details/payment-details.component';
import { ProviderEnrollmentListComponent } from './components/enrollment/provider-enrollment-list/provider-enrollment-list.component';
import { AdditionalAttributesComponent } from './components/lead-details/additional-attributes/additional-attributes.component';
import { AssociationsComponent } from './components/lead-details/associations/associations.component';
import { ChecklistComponent } from './components/lead-details/check-list/ppl-checklist/checklist.component';
import { CommunicationDetailsComponent } from './components/lead-details/communication-details/communication-details/communication-details.component';
import { DocumentsComponent } from './components/lead-details/documents/documents/documents.component';
import { EmployerComponent } from './components/lead-details/employer/employer/employer.component';
import { LeadDetailSignatureComponent } from './components/lead-details/lead-detail-signature/lead-detail-signature.component';
import { ProspectiveParticipantsComponent } from './components/lead-details/prospective-participants/prospective-participants/prospective-participants.component';
import { LeadDetailSummaryComponent } from './components/lead-details/summary/lead-detail-summary/lead-detail-summary.component';
import { TimesheetsComponent } from './components/lead-details/timesheets/timesheets/timesheets.component';
import { ToDoListComponent } from './components/lead-details/to-do-list/to-do-list/to-do-list.component';
import { FormChecklistComponent } from './components/leads/form-checklist/form-checklist.component';
import { NewContactComponent } from './components/leads/new-contact/new-contact.component';
import { ServiceDetailsComponent } from './components/leads/service-details/service-details.component';
import { UploadDocsComponent } from './components/leads/upload-docs/upload-docs.component';
import { UploadSignaturesComponent } from './components/leads/upload-signatures/upload-signatures.component';
import { ReferralListComponent } from './components/referral/referral-list/referral-list.component';
import { ReferralsComponent } from './components/referral/referrals/referrals.component';
import { AuthGuard } from './shared/authorization/auth.gaurd';
import {
  clearListSharedDataGuard,
  clearParticipantLeadSharedDataGaurd,
} from './shared/can-deactivate-gaurd';
import { ProcessCodeListComponent } from './components/payroll/process-codes/process-code-list/process-code-list.component';
import { AddProcessCodeComponent } from './components/payroll/process-codes/add-process-code/add-process-code.component';
import { ServiceCodeListComponent } from './components/payroll/service-codes/service-code-list/service-code-list.component';
import { AddServiceCodeComponent } from './components/payroll/service-codes/add-service-code/add-service-code.component';
import { ParticipantProviderRatesListComponent } from './components/payroll/participant-provider-rates/participant-provider-rates-list/participant-provider-rates-list.component';
import { AddParticipantProviderRatesComponent } from './components/payroll/participant-provider-rates/add-participant-provider-rates/add-participant-provider-rates.component';
import { AuthorizationsListComponent } from './components/payroll/authorizations/authorizations-list/authorizations-list.component';
import { AddAuthorizationComponent } from './components/payroll/authorizations/add-authorization/add-authorization.component';
import { AuthorizationDetailsComponent } from './components/payroll/authorizations/authorization-details/authorization-details.component';
import { FileTriageListComponent } from './components/fileTriage/file-triage-list/file-triage-list.component';
import { AuthorizationMemberViewComponent } from './components/payroll/authorizations/authorization-member-view/authorization-member-view.component';
import { PayableProviderRatesListComponent } from './components/payroll/payable-provider-rates/payable-provider-rates-list/payable-provider-rates-list.component';
import { BillableProviderRatesListComponent } from './components/payroll/billable-provider-rates/billable-provider-rates-list/billable-provider-rates-list.component';
import { AddPayableProviderRatesComponent } from './components/payroll/payable-provider-rates/add-payable-provider-rates/add-payable-provider-rates.component';
import { AddBillableProviderRatesComponent } from './components/payroll/billable-provider-rates/add-billable-provider-rates/add-billable-provider-rates.component';
import { FileUploadNotificationListComponent } from './components/file-upload-notification/file-upload-notification-list/file-upload-notification-list.component';

export const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    children: [
      {
        path: 'participantLeadsList',
        component: ParticipantLeadsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ParticipantLeadsListComponent,
            pathMatch: 'full',
            canActivate: [AuthGuard],
            canDeactivate: [clearListSharedDataGuard],
          },
          {
            path: 'leadDetails',
            component: MemberViewComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'participantInformation',
                component: LeadInformationComponent,
                data: {
                  breadcrumb: 'ppl-authorization',
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'authorization',
                component: AuthorizationComponent,
                data: {
                  breadcrumb: 'Authorization',
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'employers',
                component: EmployerComponent,
                data: {
                  breadcrumb: 'Employers',
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'timesheets',
                component: TimesheetsComponent,
                data: {
                  breadcrumb: 'Timesheets',
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'documents',
                component: DocumentsComponent,
                data: {
                  breadcrumb: 'Documents',
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'communicationDetails',
                component: CommunicationDetailsComponent,
                data: {
                  breadcrumb: 'Communication Details',
                },
                canActivate: [AuthGuard],
              },
            ],
          },
        ],
        data: {
          breadcrumb: 'Participant Leads',
        },
        canDeactivate: [clearParticipantLeadSharedDataGaurd],
      },
      {
        path: 'providerLeadsList',
        component: ParticipantLeadsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ProviderLeadsListComponent,
            pathMatch: 'full',
            canDeactivate: [clearListSharedDataGuard],
            canActivate: [AuthGuard],
          },

          {
            path: 'leadDetails',
            component: MemberViewComponent,
            children: [
              {
                path: 'participantInformation',
                component: LeadInformationComponent,
                data: {
                  breadcrumb: 'Participant Information',
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'documents',
                component: DocumentsComponent,
                data: {
                  breadcrumb: 'Documents',
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'prospectiveParticipants',
                component: ProspectiveParticipantsComponent,
                data: {
                  breadcrumb: 'Prospective Participants',
                },
                canActivate: [AuthGuard],
              },
            ],
          },
        ],
        data: {
          breadcrumb: 'Provider Leads List',
        },
      },
      {
        path: 'participantEnrollmentList',
        component: ParticipantLeadsComponent,
        children: [
          {
            path: '',
            component: ParticipantEnrollmentListComponent,
            pathMatch: 'full',
            canDeactivate: [clearListSharedDataGuard],
            canActivate: [],
          },
          {
            path: 'details',
            component: MemberViewComponent,
            children: [
              {
                path: 'summary',
                component: LeadDetailSummaryComponent,
                data: {
                  breadcrumb: 'Summary',
                },
                canActivate: [],
              },
              {
                path: 'enrollmentInformation',
                component: LeadInformationComponent,
                data: {
                  breadcrumb: 'Basic Information',
                },
                canActivate: [],
              },
              {
                path: 'toDoList',
                component: ToDoListComponent,
                data: {
                  breadcrumb: 'To Do List',
                },
                canActivate: [],
              },
              {
                path: 'authorizations',
                component: AuthorizationComponent,
                data: {
                  breadcrumb: 'Authorizations',
                },
                canActivate: [],
              },

              {
                path: 'forms',
                component: FormChecklistComponent,
                data: {
                  breadcrumb: 'Forms',
                },
                canActivate: [],
              },
              {
                path: 'employers',
                component: EmployerComponent,
                data: {
                  breadcrumb: '',
                },
                canActivate: [],
              },
              {
                path: 'timesheets',
                component: TimesheetsComponent,
                data: {
                  breadcrumb: 'Timesheets',
                },
                canActivate: [],
              },
              {
                path: 'documents',
                component: DocumentsComponent,
                data: {
                  breadcrumb: 'Documents',
                },
                canActivate: [],
              },
              {
                path: 'checklist',
                component: ChecklistComponent,
                data: {
                  breadcrumb: 'Checklist',
                  isParticipant: true,
                },
                canActivate: [],
              },
              {
                path: 'associations',
                component: AssociationsComponent,
                data: {
                  breadcrumb: 'Associations',
                },
                canActivate: [],
              },
              {
                path: 'signature',
                component: LeadDetailSignatureComponent,
                data: {
                  breadcrumb: 'Signature',
                },
                canActivate: [],
              },
              {
                path: 'additionalAttributes',
                component: AdditionalAttributesComponent,
                data: {
                  breadcrumb: 'Additional Attributes',
                },
                canActivate: [],
              },
            ]
          }]
      },
      {
        path: 'globalConsumerSearch',
        component: ParticipantLeadsComponent,
        children: [
          {
            path: '',
            component: GlobalSearchComponent,
            pathMatch: 'full',
            canDeactivate: [],
            canActivate: [],
          },
          {
            path: 'details',
            component: MemberViewComponent,
            children: [
              {
                path: 'summary',
                component: LeadDetailSummaryComponent,
                data: {
                  breadcrumb: 'Authorization',
                },
                canActivate: [],
              },
              {
                path: 'enrollmentInformation',
                component: LeadInformationComponent,
                data: {
                  breadcrumb: 'ppl-authorization',
                },
                canActivate: [],
              },

              {
                path: 'participantInformation',
                component: LeadInformationComponent,
                data: {
                  breadcrumb: 'Participant Information',
                },
                canActivate: [],
              },
              {
                path: 'toDoList',
                component: ToDoListComponent,
                data: {
                  breadcrumb: 'To Do List',
                },
                canActivate: [],
              },
              {
                path: 'authorizations',
                component: AuthorizationComponent,
                data: {
                  breadcrumb: 'Authorizations',
                },
                canActivate: [],
              },

              {
                path: 'forms',
                component: FormChecklistComponent,
                data: {
                  breadcrumb: 'Forms',
                },
                canActivate: [],
              },
              {
                path: 'employers',
                component: EmployerComponent,
                data: {
                  breadcrumb: '',
                },
                canActivate: [],
              },
              {
                path: 'timesheets',
                component: TimesheetsComponent,
                data: {
                  breadcrumb: 'Timesheets',
                },
                canActivate: [],
              },
              {
                path: 'documents',
                component: DocumentsComponent,
                data: {
                  breadcrumb: 'Documents',
                },
                canActivate: [],
              },
              {
                path: 'checklist',
                component: ChecklistComponent,
                data: {
                  breadcrumb: 'Checklist',
                  isParticipant: true,
                },
                canActivate: [],
              },
              {
                path: 'associations',
                component: AssociationsComponent,
                data: {
                  breadcrumb: 'Associations',
                },
                canActivate: [],
              },
              {
                path: 'signature',
                component: LeadDetailSignatureComponent,
                data: {
                  breadcrumb: 'Signature',
                },
                canActivate: [],
              },
              {
                path: 'additionalAttributes',
                component: AdditionalAttributesComponent,
                data: {
                  breadcrumb: 'Additional Attributes',
                },
                canActivate: [],
              },
            ]
          }]
      },
      {
        path: 'providerEnrollmentList',
        component: ParticipantLeadsComponent,
        children: [
          {
            path: '',
            component: ProviderEnrollmentListComponent,
            pathMatch: 'full',
            canDeactivate: [clearListSharedDataGuard],
            canActivate: [],
          },
          {
            path: 'details',
            component: MemberViewComponent,
            children: [
              {
                path: 'summary',
                component: LeadDetailSummaryComponent,
                data: {
                  breadcrumb: 'Authorization',
                },
                canActivate: [],
              },
              {
                path: 'enrollmentInformation',
                component: LeadInformationComponent,
                data: {
                  breadcrumb: 'ppl-authorization',
                },
                canActivate: [],
              },
              {
                path: 'toDoList',
                component: ToDoListComponent,
                data: {
                  breadcrumb: 'To Do List',
                },
                canActivate: [],
              },
              {
                path: 'authorization',
                component: AuthorizationComponent,
                data: {
                  breadcrumb: 'Authorization',
                },
                canActivate: [],
              },

              {
                path: 'forms',
                component: FormChecklistComponent,
                data: {
                  breadcrumb: 'Forms',
                },
                canActivate: [],
              },
              {
                path: 'employers',
                component: EmployerComponent,
                data: {
                  breadcrumb: '',
                },
                canActivate: [],
              },
              {
                path: 'timesheets',
                component: TimesheetsComponent,
                data: {
                  breadcrumb: 'Timesheets',
                },
                canActivate: [],
              },
              {
                path: 'documents',
                component: DocumentsComponent,
                data: {
                  breadcrumb: 'Documents',
                },
                canActivate: [],
              },
              {
                path: 'checklist',
                component: ChecklistComponent,
                data: {
                  breadcrumb: 'Checklist',
                  isParticipant: false
                },
                canActivate: [],
              },
              {
                path: 'associations',
                component: AssociationsComponent,
                data: {
                  breadcrumb: 'Associations',
                },
                canActivate: [],
              },
              {
                path: 'signature',
                component: LeadDetailSignatureComponent,
                data: {
                  breadcrumb: 'Signature',
                },
                canActivate: [],
              },
              {
                path: 'additionalAttributes',
                component: AdditionalAttributesComponent,
                data: {
                  breadcrumb: 'Additional Attributes',
                },
                canActivate: [],
              },
            ]
          }]
      },
      {
        path: 'newEnrollment',
        component: NewReferralComponent,
        children: [
          {
            path: 'participant_info',
            component: ParticipantInformationComponent,
          },
          { path: 'contact_details', component: ContactDetailsComponent },
          { path: 'comm_preferences', component: CommPreferenceComponent },
          { path: 'service_details', component: ServiceDetailsComponent },
          { path: 'upload_docs', component: UploadDocsComponent },
          { path: 'signatures', component: UploadSignaturesComponent },
          { path: 'associations', component: NewContactComponent },
          { path: 'forms', component: FormChecklistComponent },
          { path: 'payment_details', component: PaymentDetailsComponent },
          { path: 'summary', component: SummaryComponent },
        ],
        canActivate: [AuthGuard],
      },
      { path: 'newEnrollmentForm/:flow', component: NewEnrollmentComponent },
      {
        path: 'referralList',
        component: ReferralsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ReferralListComponent,
            pathMatch: 'full',
            canActivate: [AuthGuard],
            canDeactivate: [clearListSharedDataGuard],
          },
          {
            path: 'referralDetails',
            component: MemberViewComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'participantInformation',
                component: LeadInformationComponent,
                data: {
                  breadcrumb: 'Participant Information',
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'authorization',
                component: AuthorizationComponent,
                data: {
                  breadcrumb: 'Authorization',
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'employers',
                component: EmployerComponent,
                data: {
                  breadcrumb: 'Employers',
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'timesheets',
                component: TimesheetsComponent,
                data: {
                  breadcrumb: 'Timesheets',
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'documents',
                component: DocumentsComponent,
                data: {
                  breadcrumb: 'Documents',
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'communicationDetails',
                component: CommunicationDetailsComponent,
                data: {
                  breadcrumb: 'Communication Details',
                },
                canActivate: [AuthGuard],
              },
            ],
          },
        ],
        data: {
          breadcrumb: 'Referrals',
        },
      },
      {
        path: 'enrollmentDetails',
        component: MemberViewComponent,
        children: [
          {
            path: 'summary',
            component: LeadDetailSummaryComponent,
            data: {
              breadcrumb: 'Authorization',
            },
            canActivate: [],
          },
          {
            path: 'enrollmentInformation',
            component: LeadInformationComponent,
            data: {
              breadcrumb: 'ppl-authorization',
            },
            canActivate: [],
          },
          {
            path: 'toDoList',
            component: ToDoListComponent,
            data: {
              breadcrumb: 'To Do List',
            },
            canActivate: [],
          },
          {
            path: 'authorization',
            component: AuthorizationComponent,
            data: {
              breadcrumb: 'Authorization',
            },
            canActivate: [],
          },


          {
            path: 'employers',
            component: EmployerComponent,
            data: {
              breadcrumb: '',
            },
            canActivate: [],
          },
          {
            path: 'timesheets',
            component: TimesheetsComponent,
            data: {
              breadcrumb: 'Timesheets',
            },
            canActivate: [],
          },
          {
            path: 'documents',
            component: DocumentsComponent,
            data: {
              breadcrumb: 'Documents',
            },
            canActivate: [],
          },
          {
            path: 'forms',
            component: FormChecklistComponent,
            data: {
              breadcrumb: 'Forms',
            },
            canActivate: [],
          },
          {
            path: 'checklist',
            component: ChecklistComponent,
            data: {
              breadcrumb: 'Checklist'
            },
            canActivate: [],
          },
          {
            path: 'associations',
            component: AssociationsComponent,
            data: {
              breadcrumb: 'Associations',
            },
            canActivate: [],
          },
          {
            path: 'signature',
            component: LeadDetailSignatureComponent,
            data: {
              breadcrumb: 'Signature',
            },
            canActivate: [],
          },
          {
            path: 'additionalAttributes',
            component: AdditionalAttributesComponent,
            data: {
              breadcrumb: 'Additional Attributes',
            },
            canActivate: [],
          },
        ],
      },
      { /* path: 'enrollmentList', component: EnrollmentsComponent, */
        path: 'enrollmentList',
        component: EnrollmentListComponentOld,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: EnrollmentListComponent,
            pathMatch: 'full',
            canActivate: [AuthGuard],
            canDeactivate: [clearListSharedDataGuard],
          },
          {
            path: 'referralDetails',
            component: MemberViewComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'participantInformation',
                component: LeadInformationComponent,
                data: {
                  breadcrumb: 'Participant Information',
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'authorization',
                component: AuthorizationComponent,
                data: {
                  breadcrumb: 'Authorization',
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'employers',
                component: EmployerComponent,
                data: {
                  breadcrumb: 'Employers',
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'timesheets',
                component: TimesheetsComponent,
                data: {
                  breadcrumb: 'Timesheets',
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'documents',
                component: DocumentsComponent,
                data: {
                  breadcrumb: 'Documents',
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'communicationDetails',
                component: CommunicationDetailsComponent,
                data: {
                  breadcrumb: 'Communication Details',
                },
                canActivate: [AuthGuard],
              },
            ],
          },
        ],
        data: {
          breadcrumb: 'Referrals',
        },
      },
      {
        path: 'processCode/:action',
        component: AddProcessCodeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'processCodeList',
        component: ProcessCodeListComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ProcessCodeListComponent,
            pathMatch: 'full',
            canActivate: [AuthGuard],
          },
        ]
      },
      {
        path: 'serviceCode/:action',
        component: AddServiceCodeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'serviceCodeList',
        component: ServiceCodeListComponent,
        canActivate: [AuthGuard]
      },
      /* {
        path: 'authorizations',
        component: AuthorizationsListComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Referrals',
        },
            children: [
              {
                path: 'participantInformation',
                component: LeadInformationComponent,
                data: {
                  breadcrumb: 'Participant Information',
                },
                canActivate: [AuthGuard],
              },
            ]
      }, */


      {
        path: 'authorizations',
        component: ParticipantLeadsComponent,
        children: [
          {
            path: '',
            component: AuthorizationsListComponent,
            pathMatch: 'full',
            canDeactivate: [],
            canActivate: [],
          },
          {
            path: 'details',
            component: AuthorizationMemberViewComponent,
            children: [
              {
                path: 'authorization',
                component: AuthorizationDetailsComponent,
                data: {
                  breadcrumb: 'Authorization',
                },
                canActivate: [],
              },

            ]
          }
        ]
        },
      {
        path: 'addAuthorizations',
        component: AddAuthorizationComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'addParticipantProviderRates/:action',
        component: AddParticipantProviderRatesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'participantProviderRatesList',
        component: ParticipantProviderRatesListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'addPayablePersonalAssistantRates/:action',
        component: AddPayableProviderRatesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'payablePersonalAssistantRates',
        component: PayableProviderRatesListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'addBillablePersonalAssistantRates/:action',
        component: AddBillableProviderRatesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'billablePersonalAssistantRates',
        component: BillableProviderRatesListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'fileTriage',
        component: FileTriageListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'fileUploadNotification',
        component: FileUploadNotificationListComponent,
        canActivate: [AuthGuard]
      },
    ],
    canActivate: [MsalGuard],
  },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'programs',
    component: DashboardComponent,
    canActivate: [MsalGuard],
    data: {
      breadcrumb: 'Provider Leads List',
    },
  },
  {
    path: 'referralLogic',
    component: ReferralLogicComponent,
    data: {
      breadcrumb: 'Provider Leads List',
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'confirmation',
    component: MessageComponent,
  },
  {
    path: 'newReferral',
    component: NewReferralComponent,
    children: [
      { path: '', component: ParticipantInformationComponent },
      { path: 'participant_info', component: ParticipantInformationComponent },
      { path: 'contact_details', component: ContactDetailsComponent },
      { path: 'comm_preferences', component: CommPreferenceComponent },
      { path: 'service_details', component: ServiceDetailsComponent },
      { path: 'upload_docs', component: UploadDocsComponent },
      { path: 'signatures', component: UploadSignaturesComponent },
      { path: 'associations', component: NewContactComponent },
      { path: 'forms', component: FormChecklistComponent, data: {isNewReferral: true} },
      { path: 'payment_details', component: PaymentDetailsComponent },
      { path: 'summary', component: SummaryComponent },
    ],
    canActivate: [AuthGuard],
  }
];
