@if(mode === 'edit' || mode === 'add' && secData){


<div class="custom-row">
  <ppl-button [label]="mode === 'add' ? 'Close' : 'Cancel'" [icon]="mode === 'add' ? 'fa fa-close' : 'fa fa-ban'"
    [customClass]="'alt-button'" [iconPosition]="'left'" (click)="cancelEdit()"></ppl-button>

  @if (mode === 'edit') {
  <ppl-button label="Save" icon="fa fa-floppy-o" [customClass]="'alt-button'" [iconPosition]="'left'"
    (click)="save()" [disabled]="isSaveDisabled"></ppl-button>
  }
</div>


<nz-form-control class="ppl-form-control">
  <div [formGroup]="prospectiveParticipant">

    <div nz-row class="justify-content-center align-items-center">
      @if (isAttrAvailable('programCode')) {
      <nz-form-item nz-col nzSpan="4" class="pr-2">
        <nz-form-label nzFor="programCode" [nzSm]="24" [nzXs]="24" class="ppl-form-label">{{
          getAttributes("programCode").label }}
          @if(getAttributes('programCode').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <nz-select formControlName="programCode" [ngClass]="{'error': validationErrors['programCode'] }"
            id="programCode" nzShowArrow>
            @for(program of programs; track $index){
            <nz-option [nzLabel]="program.program_name" [nzValue]="program.program_code"></nz-option>
            }
          </nz-select>
          @if (validationErrors['programCode']) {
          <span class="pl-1 error-message">{{validationErrors['programCode']}}</span>
          }
        </nz-form-control>
      </nz-form-item>
      }

      @if(isAttrAvailable('firstName')){
      <nz-form-item nz-col nzSpan="4" class="pr-2">
        <nz-form-label nzFor="firstName" [nzSm]="12" [nzXs]="24" class="ppl-form-label">{{
          getAttributes("firstName").label }}
          @if(getAttributes('firstName').mandatory === 'Y' && mode === 'edit'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <input nz-tooltip nzTooltipTitle="{{ getAttributes('firstName').tooltip }}" nzTooltipPlacement="topRight"
            nz-input formControlName="firstName" id="firstName" [authorizeField]="[pageId, tabId, 'firstName', 'Demographics']"
            placeholder="{{ getAttributes('firstName').placeholder }}"
            [ngClass]="{'error': validationErrors['firstName'] }" />
          @if (validationErrors['firstName']) {
          <span class="pl-1 error-message">{{validationErrors['firstName']}}</span>
          }
        </nz-form-control>

      </nz-form-item>
      }

      @if(isAttrAvailable('lastName')){
      <nz-form-item nz-col nzSpan="4" class="pr-2">
        <nz-form-label nzFor="lastName" [nzSm]="12" [nzXs]="24" class="ppl-form-label">{{
          getAttributes("lastName").label }}
          @if(getAttributes('lastName').mandatory === 'Y' && mode === 'edit'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <input nz-tooltip nzTooltipTitle="{{ getAttributes('lastName').tooltip }}" nzTooltipPlacement="topRight"
            nz-input formControlName="lastName" id="lastName" [authorizeField]="[pageId, tabId, 'lastName', 'Demographics']"
            placeholder="{{ getAttributes('lastName').placeholder }}"
            [ngClass]="{'error': validationErrors['lastName'] }" />
          @if (validationErrors['lastName']) {
          <span class="pl-1 error-message">{{validationErrors['lastName']}}</span>
          }
        </nz-form-control>

      </nz-form-item>
      }

      @if(isAttrAvailable('participantPPLId')){
      <nz-form-item nz-col nzSpan="4" class="pr-2">
        <nz-form-label nzFor="participantPPLId" [nzSm]="24" [nzXs]="24" class="ppl-form-label">{{
          getAttributes("participantPPLId").label }}
          @if(getAttributes('participantPPLId').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <input nz-tooltip nzTooltipTitle="{{ getAttributes('participantPPLId').tooltip }}" nzTooltipPlacement="topRight"
            nz-input formControlName="participantPPLId" id="participantPPLId"
            [authorizeField]="[pageId, tabId, 'participantPPLId', 'Demographics']"
            placeholder="{{ getAttributes('participantPPLId').placeholder }}"
            [ngClass]="{'error': validationErrors['participantPPLId'] }" />
          @if (validationErrors['participantPPLId']) {
          <span class="pl-1 error-message">{{validationErrors['participantPPLId']}}</span>
          }
        </nz-form-control>

      </nz-form-item>
      }

      <nz-form-item nz-col nzSpan="2">
        <ppl-button label="Search" [customClass]="'primary-button'" [icon]="'fa fa-magnifying-glass'" [iconPosition]="'left'" (click)="search()"></ppl-button>
      </nz-form-item>
    </div>
      <div nz-row>

      <!-- @if (isAttrAvailable('providerTypeLpk') && mode==='edit') {
      <nz-form-item nz-col nzSpan="4" class="pr-2">
        <nz-form-label nzFor="providerTypeLpk" [nzSm]="24" [nzXs]="24" class="ppl-form-label">{{
          getAttributes("providerTypeLpk").label }}
          @if(getAttributes('providerTypeLpk').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <nz-select formControlName="providerTypeLpk" [ngClass]="{'error': validationErrors['providerTypeLpk'] }"
            id="providerTypeLpk" nzShowArrow>
            <nz-option [nzLabel]="'Personal Assistant'" [nzValue]="'IR'"></nz-option>
          </nz-select>
          @if (validationErrors['providerTypeLpk']) {
          <span class="pl-1 error-message">{{validationErrors['providerTypeLpk']}}</span>
          }
        </nz-form-control>
      </nz-form-item>
      } -->
      <!-- @if(mode === 'add') { -->
      
      <!-- } -->
    </div>
    @if(showSearchError) {
    <p class="pl-1 error-message">Please enter atleast one search criteria.</p>
    }
    @if (showSearchResults) {

    <ppl-list-table 
      [disableSort]="true" 
      [disableSearch]="true" 
      [enableActions]="true" 
      [data]="searchResultsList"
      [disableSelection]="true" 
      [cols]="cols" 
      [gridTitle]="false" 
      [pagination]="true" 
      [loadPage]="false"
      [rowsToDisplay]="10"
      (gridAction)="action($event)" [actionButtons]="actionButtons" [isProvider]="true">
    </ppl-list-table>
    }
  </div>
</nz-form-control>
}@else if(mode === 'read'){
@if (leadStatus !== approvedStatus) {
<div class="custom-row">

  <ppl-button label="Add" icon="fa fa-plus" [customClass]="'ghost-button'" [iconPosition]="'left'"
    (click)="add()"></ppl-button>

  <!-- <span nz-icon nzType="plus-circle" nzTheme="outline" (click)="add()"
      *authorize="[pageId, tabId, 'Identifiers', 'section', 'edit']"></span> -->
</div>
}
@if(participantList && participantList.length > 0){
<ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true" [data]="participantList"
  [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false" [loadPage]="true"
  (gridAction)="action($event)" [actionButtons]="actionButtons" [isProvider]="true" [isVerifiedFlag]="true">
</ppl-list-table>

}@else {
<nz-row>
  <p>No Data found</p>
</nz-row>
}

}@else{
<nz-row>
  <p>No Data found</p>
</nz-row>
}

<ppl-popup-dialog [(isVisible)]="showDialog" [title]="'Add Personal Assistant Type'" [isOkText]="'Submit'" (confirm)="handleOk()"
  (cancel)="handleCancel()" [showButtons]="true" [showOkButton]="false" [size]="'medium'" [position]="'center'">

  <nz-select [(ngModel)]="selectedProviderType"
    id="providerTypeLpk" nzShowArrow class="my-2">
    <nz-option [nzLabel]="'Personal Assistant'" [nzValue]="'IR'"></nz-option>
  </nz-select>
  @if (selectProgramErr) {
    <span class="pl-1 error-message">Please select Personal Assistant</span>
  }

</ppl-popup-dialog>