import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location} from '@angular/common'


@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(private router: Router,
    private location: Location
  ) { }

  navigate(route : string) {
    this.router.navigate([route]);
  }

  navigateBack() {
    this.location.back();
  }
  
}
