/* src/app/components/leads/steps/steps.component.css */
::ng-deep .ant-steps-item-title {
  font-size: 0.929rem !important;
}
::ng-deep .ant-steps-icon {
  cursor: pointer;
}
::ng-deep .ant-steps-item-title {
  cursor: pointer;
}
.steps-footer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.steps-card {
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 10px;
  border: 2px solid #FFF;
  background: #FFF;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  height: calc(100vh - 244px);
  overflow-y: auto;
}
.steps-card::-webkit-scrollbar {
  width: 4px;
}
.main-steps {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  font-family: "outfit";
}
.steps-tips {
  color: #000;
  text-align: justify;
  font-family: "outfit";
  font-size: 1.143rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: var(--Information-20, #E5F2F3);
  padding: 8px;
  border-radius: 8px;
}
.fa {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  font-size: 1.429rem;
  color: #007C89;
}
/*# sourceMappingURL=steps.component-5HAVRXGY.css.map */
