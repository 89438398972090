import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { ButtonComponent } from "../button/button.component";

@Component({
  selector: 'ppl-notifications',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.css'
})
export class NotificationsComponent implements OnInit, OnChanges {
  isDropdownOpen = false;
  notifications = [];

  @Input() userType: string | null | undefined = '';
  @Input() program = '';


  constructor(private elementRef: ElementRef,
    private userService: UserService
  ) {}
    // Listens for clicks outside the dropdown
    @HostListener('document:click', ['$event.target'])
    onClickOutside(targetElement: string) {
      const clickedInside = this.elementRef.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.isDropdownOpen = false; // Close dropdown when clicked outside
      }
    }  
    
    ngOnInit(): void {
      this.getNotifications();
    }

    ngOnChanges(changes: SimpleChanges): void {
      try{
        if(changes['program'] && changes['program'].currentValue){
          console.log(changes['program'].currentValue);
          if(changes['program'].currentValue == 'Pre_Ref') {
            this.notifications = [];
          } else {
            this.getNotifications();
          }
        }
      }catch(e){
        console.log(e);
      }
    }

    getNotifications() {
      if(this.program !== 'Pre_Ref') {
        this.userService.fetchNotificationMessages(this.program, this.userType).subscribe((data) => {
          console.log('Notifications data', data.responsedata);
          this.notifications = data.responsedata;
        });
      }
    }

  
  // notifications = [
  //   { title: 'Lead participant #987654 got approved', time: '5 min ago' },
  //   { title: 'Your new lead created successfully!', time: 'Yesterday, 11:30 AM' },
  //   { title: 'New details added in the details', time: 'Sep 9, 2024, 10:45 AM' }
  // ];

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  viewNotification(notification: object): void {
    console.log('Viewing notification:', notification);
  }

  viewAllNotifications(): void {
    console.log('Viewing all notifications');
  }
}
