import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
 
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NzCollapseModule, 
    NzGridModule,
    NzButtonModule,
    NzInputModule,
    NzCardModule,
    NzFormModule,
    NzRadioModule,
    NzToolTipModule,
    NzIconModule,
    NzSelectModule,
    NzSwitchModule,
    NzDividerModule, 
    NzCheckboxModule,
    NzSpinModule,
    NzDrawerModule,
    NzTimelineModule,
    NzDropDownModule,
  ],
  exports: [
    NzCollapseModule, 
    NzGridModule,
    NzButtonModule,
    NzInputModule,
    NzCardModule,
    NzFormModule,
    NzRadioModule,
    NzToolTipModule,
    NzIconModule,
    NzSelectModule,
    NzSwitchModule,
    NzDividerModule,
    NzCheckboxModule,
    NzSpinModule,
    NzDrawerModule,
    NzTimelineModule,
    NzDropDownModule
  ]
})
export class NgZorroModule { }
