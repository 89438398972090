import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'ppl-google-translate',
  standalone: true,
  imports: [],
  templateUrl: './google-translate.component.html',
  styleUrl: './google-translate.component.css'
})
export class GoogleTranslateComponent implements AfterViewInit {

  ngAfterViewInit() {
    this.loadGoogleTraslateScript();
  }

  loadGoogleTraslateScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    document.body.appendChild(script);

    (window as any).googleTranslateElementInit = () => {
      new (window as any).google.translate.TranslateElement({pageLanguage: 'en', layout: (window as any).google.translate.TranslateElement.InlineLayout.VERTICAL},
        'google_translate_element'
      );
    };
  }

  autoTranslate(lang: string): void {
    const translateCode: HTMLSelectElement | null = document.querySelector('select.goog-te-combo');
    if (translateCode) {
      translateCode.value = lang;
      translateCode.dispatchEvent(new Event('change'));
    }
  }
}
