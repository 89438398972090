import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { ChecklistItem, DownloadFormPayload } from '../interfaces/checklist.model';
import { ERROR_MESSAGES } from '../shared/constants/error-message.constants';
import { ENDPOINTS } from '../shared/constants/url.constants';
import { GlobalErrorHandlerService } from '../shared/services/global-error-handler.service';
import { NotificationService } from '../shared/services/notification.service';
import { formatUrl } from '../shared/utils/format-url-util';
import { HttpRequestService } from '../shared/services/http-request.service';

@Injectable({
  providedIn: 'root'
})
export class CheckListService {
  private apiUrl = environment.apiUrl;
  constructor(private httpClient: HttpRequestService,
    private http: HttpClient,
    private errorHandler: GlobalErrorHandlerService,
    private notificationService: NotificationService,

  ) { }

  getChecklist(program_code: string, userId: string, isparticipant: boolean): Observable<any> {
    const url = formatUrl(
      isparticipant ? ENDPOINTS.GET_PARTICIPANT_CHECKLIST : ENDPOINTS.GET_PROVIDER_CHECKLIST,
      { program_code, userId },
      this.notificationService
    );
    return this.httpClient.get(url).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  getChecklistForParticipantAssociation(program_code: string, providerId: string, participantId: string, providerType: string): Observable<any> {
    const url = formatUrl(
      ENDPOINTS.GET_PARTICIPANT_ASSOCIATION_CHECKLIST,
      { program_code, providerId, participantId, providerType },
      this.notificationService
    );
    return this.httpClient.get(url).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  getPDFCheckList(program_code: string, userType: string, primaryLanguage: string, primaryId: string, providerId: string): Observable<any> {
    const payload = {
      "userType": `${userType}`,
      "primaryLanguage": `${primaryLanguage}`,
      "programCode": `${program_code}`,
      "participantId": `${primaryId}`,
      "providerId": `${providerId}`,
    };
    return this.httpClient.post(ENDPOINTS.GET_PDF_CHECKLIST, payload).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  getRefreshForm(payload: any): Observable<any> {
    return this.httpClient.post(ENDPOINTS.GET_REFRESH_FORMS, payload).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_REFRESH_FORMS
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  getFormData(program_code: string,
    form: ChecklistItem,
    source: string,
    participantId: string): Observable<any> {
    const userDetails: any = form.details.map(e => new Object({ userType: e.type, primaryId: e.pplId }));
    if (source == 'participant' && userDetails.filter((e: any) => e.userType == 'PTC').length == 0) {
      userDetails.push(new Object({ userType: 'PTC', primaryId: participantId }));
    }
    const payload = {
      formId: `${form.formMasterId}`,
      checkListItemId: `${form.checklistItemId}`,
      checkListItemDtlsId: `${form.checklistItemDetailId}`,
      source: `${source}`,
      programCode: `${program_code}`,
      languageCode: "ENG",
      prtcpntPrvdrIdAndTypes: userDetails
    };
    return this.httpClient.post(ENDPOINTS.GET_PDF_DATA, payload).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  saveChecklist(program_code: string, userId: string, isParticipant: boolean, payload: any): Observable<any> {
    const url = formatUrl(
      isParticipant ? ENDPOINTS.SAVE_PARTICIPANT_CHECKLIST : ENDPOINTS.SAVE_PROVIDER_CHECKLIST,
      { program_code, userId },
      this.notificationService
    );
    return this.httpClient.post(url, payload, true).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_SAVE_PART_PROVIDERS_FORMS_COMMENTS
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  saveChecklistForParticipantAssociation(program_code: string, userId: string, participantId: number, providerType: string, payload: any): Observable<any> {
    const url = formatUrl(ENDPOINTS.SAVE_PROVIDER_PARTICIPANT_ASSOCIATION_CHECKLIST,
      { program_code, userId, participantId, providerType },
      this.notificationService
    );
    return this.httpClient.post(url, payload, true).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_SAVE_PART_PROVIDERS_FORMS_COMMENTS
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  saveParticipantProviderForms(program_code: string,
    id: string,
    isParticipant: boolean,
    payload: any,): Observable<any> {
    let url = '';
    if (!isParticipant) {
      url = formatUrl(ENDPOINTS.SAVE_PROVIDER_FORMS,
        { program_code, id },
        this.notificationService
      );
    } else {
      url = formatUrl(ENDPOINTS.SAVE_PARTICIPANT_FORMS,
        { program_code, id },
        this.notificationService
      );
    }

    return this.httpClient.post(url, payload, true).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_SAVE_PART_PROVIDERS_FORMS_COMMENTS
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  saveParticipantAssocaitionForms(program_code: string,
    participantId: number,
    providerId: string,
    providerType: string, payload: any,): Observable<any> {
    const url = formatUrl(ENDPOINTS.SAVE_PARTICIPANT_ASSOCATION_FORMS,
      { program_code, providerId, participantId, providerType },
      this.notificationService
    );

    return this.httpClient.post(url, payload, true).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_SAVE_PART_PROVIDERS_FORMS_COMMENTS
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  downloadPDFForm(payload: DownloadFormPayload, fileName: string) {
    const headers = new HttpHeaders({
      'Accept': 'multipart/form-data'
    })
    this.http.post(ENDPOINTS.DOWNLOAD_PDF_FORM, payload, { headers: headers, responseType: 'blob', observe: 'response' })
      .subscribe((response: HttpResponse<Blob>) => {

        const contentDisposition = response.headers.get('Content-Disposition');
        if (response.body) {
          const blob = new Blob([response.body], { type: response.body.type });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      },
        error => {
          console.error('unable to open the file', error)
        })
  }

  getPDF(program_code: string, pdf_id: number, lang: string) {
    const url = formatUrl(ENDPOINTS.GET_PDF, { program_code, pdf_id, lang });
    const headers = new HttpHeaders({
      // 'Accept': 'multipart/form-data',
      'content-type': 'application/json'
    })
    return this.http.get(url, { headers: headers, responseType: 'blob', observe: 'response' });
    // this.httpClient.get(url, { headers: headers, responseType: 'blob', observe: 'response' }).subscribe((response: HttpResponse<Blob>) => {

    //   const contentDisposition = response.headers.get('Content-Disposition');
    //   const fileName = 'test.pdf';
    //   if(response.body) {
    //     const blob = new Blob([response.body], {type: response.body.type});
    //     const url = window.URL.createObjectURL(blob);
    //   }
    //   },
    //     error => {
    //       console.error('unable to open the file', error)
    //     })
  }
}
