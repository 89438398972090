<div class="ppl-mt20 p-4">
    <form nz-form nz-row [formGroup]="timesheetForm" class="ppl-p0">
        <nz-form-item nz-col nzSpan="24" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="consumer" class="ppl-form-label">
                    Consumer
                    <span>*</span>
                </nz-form-label>
                <nz-form-control class="ppl-form-control">
                    <nz-select formControlName="consumer" class="ppl-select" id="consumer"
                        nzPlaceHolder="Select Consumer" (ngModelChange)="viewerValueChange($event)" nzShowSearch
                        nzAllowClear>
                        @for(opt of consumers; track $index){
                        <nz-option [nzLabel]="opt.name" [nzValue]="opt.id"></nz-option>
                        }
                    </nz-select>
                </nz-form-control>
            </nz-row>
        </nz-form-item>

        <nz-form-item nz-col nzSpan="24" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="8" [nzXs]="24" class="ppl-form-label">
                    Date Range
                    <span>*</span>
                </nz-form-label>
                <nz-form-control class="ppl-form-control">
                    <nz-range-picker nzFormat="yyyy-MM-dd" ngModel (nzOnCalendarChange)="onCalendarChange($event)"
                        (nzOnOk)="onOk($event)"></nz-range-picker>
                </nz-form-control>
            </nz-row>
        </nz-form-item>
    </form>


    @if(timesheet.length > 0){
    <p-table #timesheetTable [value]="timesheet" [columns]="cols" [scrollable]="true"  [rows]="5">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="text-wrap: nowrap; background-color:#a8e4e14d;width: 15%;text-align: center;">
                    Date
                </th>
                <th style="text-wrap: nowrap; background-color:#a8e4e14d;width: 15%;text-align: center;">
                    Service
                </th>
                <th style="text-wrap: nowrap; background-color:#a8e4e14d;width: 45%;text-align: center;">
                    Hrs
                </th>
                <th style="text-wrap: nowrap; background-color:#a8e4e14d;width: 25%;text-align: center;">
                    Actions
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                @if(timesheet.length){
                <td class="table-data" style="text-align: center;">
                    <ng-container>
                        {{ rowData['date'] }}
                    </ng-container>
                </td>
                <td class="table-data" style="text-align: center;">
                    <ng-container>
                        <nz-select class="ppl-select" id="service" [(ngModel)]="rowData['service']"
                            nzPlaceHolder="Select a common serice" nzShowSearch nzAllowClear>
                            @for(opt of services; track $index){
                            <nz-option [nzLabel]="opt.name" [nzValue]="opt.id"></nz-option>
                            }
                        </nz-select>
                    </ng-container>
                </td>
                <td class="table-data" style="text-align: center;">
                    <ng-container>
                        <div class="time-row">
                            <span>Time In: </span>
                            <nz-time-picker [(ngModel)]="rowData['from']" nzUse12Hours
                                nzFormat="h:mm a" class="mr-2"></nz-time-picker>

                            <span class="ml-4">Time Out: </span>

                            <nz-time-picker [(ngModel)]="rowData['to']" nzUse12Hours nzFormat="h:mm a"></nz-time-picker>
                        </div>
                    </ng-container>
                </td>
                <td class="table-data" style="text-align: center;">
                    <ng-container>
                        <div class="custom-width">
                            <ppl-button label="Add More Hours" icon="fa fa-plus" [iconPosition]="'left'"></ppl-button>
                        </div>

                        <div class="custom-width">
                            <ppl-button label="Copy" icon="fa fa-plus" [iconPosition]="'left'"></ppl-button>
                        </div>

                        <div class="custom-width">
                            <ppl-button label="Paste" icon="fa fa-plus" [iconPosition]="'left'"></ppl-button>
                        </div>
                    </ng-container>
                </td>
                }
            </tr>
        </ng-template>
    </p-table>
    }
</div>