<ppl-accordion [title]="'To Do List'" [isOpen]="true">
    @if(checkListData && checkListData.length > 0) {
    <ul class="todo-list">
        @for(checkList of checkListData; track $index) {
        <li class="todo-item">
            <span class="todo-item-title">Checklist <strong>{{checkList.name}}</strong> for <strong>{{checkList.for}}</strong></span>
            <span class="todo-status">Pending</span>
        </li>
        }

        @for(checkList of prvdrAssociations; track $index) {
            <li class="todo-item">
                <span class="todo-item-title">Checklist for {{checkList.providerTypeLabel}} {{checkList.participantFirstName}} {{checkList.participantLastName}} <strong>({{checkList.participantPplId}})</strong></span>
                <span class="todo-status">Pending</span>
            </li>
            }
    </ul>
    }
    @else {
        <nz-alert class="mb-2" nzType="info" nzMessage="No pending items for you." nzShowIcon></nz-alert>
    }
</ppl-accordion>