@if(mode==="edit" || mode==="add"){

<div class="custom-row">
  <ppl-button 
  label="Cancel" 
  icon="fa fa-ban" 
  [customClass]="'alt-button'" 
  [iconPosition]="'left'"
  (click)="cancelEdit()"
  ></ppl-button>    

  <ppl-button 
  label="Save" 
  icon="fa fa-floppy-o" 
  [customClass]="'alt-button'" 
  [iconPosition]="'left'"
  (click)="save()"
  ></ppl-button>

  <!-- <span (click)="cancelEdit()" nz-icon nzType="close-circle" nzTheme="outline"></span>
  <span nz-icon nzType="check-circle" nzTheme="outline" (click)="save()"></span> -->
</div>

<div class="ppl-mt20">

  <form nz-form nz-row [formGroup]="identifierForm" class="ppl-p0">

    @if(isAttrAvailable('type')){
    <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
      <nz-row>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="type">{{ getAttributes("type").label }}
          @if(getAttributes('type').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control" nzLabelAlign="left">
          <nz-select formControlName="type" [nzDisabled]="mode === 'edit'" nzTooltipPlacement="topRight" [(ngModel)]="selectedIdentifierValue"
            class="ppl-select" nzId="type" nzShowSearch nzAllowClear [ngClass]="{'error': validationErrors['type'] }">
            @for(opt of identifiersList; track $index){
            <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"
              [nzDisabled]="createdIdentifiers.includes(opt.lkpValueCode)"></nz-option>
            }
          </nz-select>

          @if (validationErrors['type']) {
          <span class="pl-1 error-message">{{validationErrors['type']}}</span>
          }
        </nz-form-control>
      </nz-row>
    </nz-form-item>}

    @if(isAttrAvailable('value')){
    <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="value">{{getAttributes('value').label}}
          @if(getAttributes('value').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control pl-2">
          @if(selectedIdentifierValue  === 'SSN'){
            <nz-input-group [nzSuffix]="suffixTemplate" style="height: 32px; padding: 0px 11px;"
                [ngClass]="{'error': validationErrors['value'] }">
                <input nz-input nz-tooltip nzTooltipTitle="{{getAttributes('value').tooltip}}" nzTooltipPlacement="topRight" [type]="ssnVisible ? 'text' : 'password'" formControlName="value"
                  nzId="value" placeholder="{{getAttributes('value').placeholder}}" (input)="formatSSN($event)" />
              </nz-input-group>
              <ng-template #suffixTemplate>
                <!-- We use eslint-disable here because the button's content is supplied by the nz-icon directive -->
                <!-- eslint-disable-next-line @angular-eslint/template/elements-content -->
                <button 
                  [attr.aria-label]="ssnVisible ? 'Hide SSN' : 'Show SSN'"
                  class="ssn-visible" 
                  nz-icon 
                  [nzType]="ssnVisible ? 'eye' : 'eye-invisible'" 
                  (click)="ssnVisible = !ssnVisible"
                ></button>
              </ng-template>
          }@else{
            <input nz-input nz-tooltip [nzTooltipTitle]="getTooltipTitle()" nzTooltipPlacement="topRight"
            formControlName="value" id="value" placeholder="{{getAttributes('value').placeholder}}"
            [ngClass]="{'error': validationErrors['value'] }" />
          }
          @if (validationErrors['value']) {
          <span class="pl-1 error-message">{{validationErrors['value']}}</span>
          }
          @if (ssnError) {
            <span class="pl-1 error-message">Please enter a valid SSN</span>
          }
        </nz-form-control>
      </nz-row>
    </nz-form-item>
    }

    @if(isAttrAvailable('preferred')){
    <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="preferred"
          class="ppl-form-label">{{getAttributes('preferred').label}}</nz-form-label>
        <nz-form-control class="ppl-form-control">
          <nz-switch nzId="preferred" formControlName="preferred" pplNzAriaLabel nzAriaLabel="preferred"></nz-switch>
        </nz-form-control>
      </nz-row>
    </nz-form-item>
    }


  </form>

</div>
}
@else if(mode === 'read'){
  @if(leadStatus !== approvedStatus){
    <div class="custom-row">
      <ppl-button 
      label="Add" 
      icon="fa fa-plus" 
      [customClass]="'ghost-button'" 
      [iconPosition]="'left'"
      (click)="add()"
      *authorize="[pageId, tabId, 'Identifiers', 'section', 'edit']"
      ></ppl-button> 

      <!-- <span nz-icon nzType="plus-circle" nzTheme="outline" (click)="add()"
        *authorize="[pageId, tabId, 'Identifiers', 'section', 'edit']"></span> -->
    </div>
  }

@if(copyOfGridData.length > 0){
<ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="isSectionEditable" [data]="copyOfGridData"
  [disableSelection]="true" [cols]="cols" (gridAction)="action($event)" [gridTitle]="false" [pagination]="false"
  [loadPage]="true" [actionButtons]="actionButtons">
</ppl-list-table>
}@else {
<nz-row>
  <p>No Data found</p>
</nz-row>
}

}@else{
<nz-row>
  <p>No Data found</p>
</nz-row>
}