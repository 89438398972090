/* src/app/shared/components/list-table/list-table.component.css */
:host ::ng-deep .p-icon-field-left .p-input-icon:first-of-type {
  left: 48.75rem;
  color: #71717a;
  top: 9px;
}
.actGRP {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.ico {
  filter: invert(0.75);
}
.ico:hover {
  cursor: pointer;
}
img {
  filter: invert(0.75);
}
.table-header-action-container {
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.header-text {
  font-weight: 600;
  font-size: 1.071rem;
}
.condition-dropdown-container {
  display: flex;
  padding: 12px;
  padding-bottom: 17px;
  margin-bottom: 12px;
}
.condition-dropdown-container label {
  position: relative;
  top: 3px;
  margin-right: 10px;
  font-family: outfit !important;
  font-size: 1rem;
}
.sortBy {
  padding: 12px;
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: 48% 50%;
  grid-gap: 12px;
}
.global-search {
  border: 1px solid #cdc7c7;
  padding-left: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  width: 100%;
}
::ng-deep .p-overlaypanel-content {
  margin-top: 6px;
}
::ng-deep .p-dropdown {
  width: 100%;
  font-size: 1rem;
  font-family: outfit;
}
::ng-deep .p-dropdown-item {
  font-size: small;
  font-family: outfit;
}
::ng-deep .p-dropdown-label {
  padding: 3px 0px 6px 9px;
  border-right: 1px solid #cdcdd0;
  border-radius: 0px;
  font-size: small;
}
.search-input {
  border: 1px solid #cdc7c7;
  padding-left: 16px;
  border-radius: 8px;
  font-size: 1rem;
  margin-right: 4px;
}
.filter-btn {
  padding: 3px 8px 3px 8px !important;
}
.filter-panel {
  width: 100%;
}
.filter-text {
  font-weight: 400 !important;
  font-size: 0.929rem !important;
  margin-right: 4px;
}
.filter-manger {
  padding: 8px;
  display: grid;
  grid-template-columns: 31% 21% 31% auto auto;
  gap: 10px;
  margin-bottom: 12px;
}
.filter-action-btn {
  display: flex;
  justify-content: end;
  margin-top: 18px;
}
.filter-error {
  display: flex;
  justify-content: center;
  margin-top: 18px;
  font-size: 1rem;
}
.filter-action-btn button {
  padding-left: 46px;
  border: 1px solid #cdcdd0;
  border-radius: 5px;
  padding-right: 45px;
  cursor: pointer;
  background-color: white;
}
.primary-bgclr {
  background-color: #3b82f6 !important;
  color: white;
}
.success-btn-color {
  background-color: rgb(81, 189, 81) !important;
  color: white;
  border: none;
  font-weight: 500;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.success-btn-color:hover {
  opacity: 0.8;
}
.danger-btn-color:hover {
  opacity: 0.8;
}
.hammer-btn {
  cursor: pointer;
  background-color: #e2e2e2 !important;
  padding: 6px 8px 8px 8px;
  border-radius: 4px;
  position: relative;
  top: -7px;
  right: -9px;
  margin-right: 8px;
}
.drop-btn {
  cursor: pointer;
  padding: 7px;
  border-radius: 4px;
  margin-left: 0;
  border: 1px solid #cdc7c7;
  font-size: 1rem;
}
.global-search::placeholder {
  color: black;
}
.form-group label {
  display: block;
  margin-bottom: 6px;
}
.form-group textarea {
  width: 100%;
  box-sizing: border-box;
}
::ng-deep .ant-modal-header,
::ng-deep .ant-modal-footer {
  background-color: #cff1f04d;
}
.delete-btn {
  cursor: pointer;
  background-color: #e2e2e2 !important;
  color: black !important;
  padding: 4px;
  border: none !important;
  outline: none !important;
}
.delete-btn:focus {
  box-shadow: none !important;
}
.danger-btn-color {
  background-color: #f06060 !important;
  color: white;
  border: none;
  font-weight: 500;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.secondary-btn-color {
  background-color: #ffffff !important;
  color: rgb(0, 0, 0);
  border: 1px solid #e2e2e2;
  font-weight: 500;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.primary-btn-color {
  background: #1890ff !important;
  color: white;
  border: none;
  font-weight: 500;
  padding: 3px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}
.primary-btn-color_1 {
  background: #1890ff !important;
  color: white;
  border: none;
  font-weight: 500;
  padding: 3px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}
.disabled-btn {
  cursor: not-allowed !important;
  color: rgb(165, 157, 157) !important;
  background: white !important;
}
.row-buttons {
  display: flex;
  align-items: center;
  font-size: small;
  position: absolute;
  right: 0;
}
.row-buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row-buttons-container h4 {
  margin: 0;
}
.filter-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.row-btns {
  padding: 0 0 0 10px;
}
::ng-deep .p-button-icon-only {
  background: #1890ff;
  color: white;
  padding: 4px;
}
:host ::ng-deep .p-paginator {
  padding-top: 25px !important;
  padding-right: 0px !important;
  justify-content: end;
}
.list-table-component td {
  font-size: 0.929rem;
}
td {
  font-size: 0.929rem;
  padding: 8px 3px;
}
.prospective {
  padding: 0.75rem 1.5rem;
}
th {
  color: #000000d9 !important;
  font-size: 0.929rem;
  font-weight: 600;
  padding-bottom: 0px;
}
:host ::ng-deep p-inputicon {
  padding-top: 10px !important;
}
:host ::ng-deep .p-checkbox .p-highlight {
  background: #1890ff !important;
  border: 0px;
  border-radius: 0px;
}
:host ::ng-deep .p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #fafafa !important;
  color: #000000d9 !important;
  font-weight: 100;
}
.filter-item {
  display: flex;
  justify-content: space-between;
}
.filter-item div {
  display: flex;
}
.filter-item {
  border-right: 1px solid rgba(0, 0, 0, .06);
}
.last-th {
  border: none;
}
::ng-deep .ant-modal-title {
  font-weight: 600;
}
.check-box {
  padding-left: 24px !important;
}
::ng-deep .p-button {
  background: #1677ff !important;
  color: white !important;
  font-size: 0.929rem !important;
  font-family: outfit !important;
}
::ng-deep .p-datatable-header {
  background-color: white !important;
  padding: 0 0 5px 0;
  border: none;
}
.csv-btn {
  padding: 5px 8px;
}
:host ::ng-deep .p-overlaypanel:after,
.p-overlaypanel:before {
  border: none !important;
}
.custom-search-btn {
  cursor: pointer;
  padding: 6px 8px 8px 8px;
  border-radius: 4px;
  position: relative;
  top: -7px;
  right: -9px;
  margin-right: 8px;
}
.action-item > * {
  margin-right: 0.75rem;
  padding-left: 20px;
}
.action-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  box-sizing: border-box;
}
.table-data-first-column {
  cursor: pointer;
  color: blue;
}
.table-data-first-column:hover {
  color: hotpink;
}
.table-data-first-column:active {
  color: blue;
}
.line-item-table {
  width: 100% !important;
}
/*# sourceMappingURL=list-table.component-TR3NAB6M.css.map */
