<ppl-accordion [title]="'Timesheet Summary'" [isOpen]="true">
    <div class="custom-row">
        <div class="mr-2">
            <ppl-button label="Add Timeentries" icon="fa fa-plus" [customClass]="'ghost-button'" [iconPosition]="'left'"
                (click)="addTimeentries()" *authorize="[pageId, tabId, 'AddTimeenties', 'section', 'edit']"></ppl-button>
        </div>

        <div class="mr-2">
            <ppl-button label="Adjust" icon="fa fa-adjust" [customClass]="'ghost-button'" [iconPosition]="'left'"
                 *authorize="[pageId, tabId, 'AddTimeenties', 'section', 'edit']"></ppl-button>
        </div>

        <div class="mr-2">
            <ppl-button label="Void" icon="fa fa-ban" [customClass]="'ghost-button'" [iconPosition]="'left'"
                 *authorize="[pageId, tabId, 'AddTimeenties', 'section', 'edit']"></ppl-button>
        </div>
    </div>

    @if(timesheets.length > 0){
    <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="false" [data]="timesheets" (rowDetailEvent)="rowClicked($event)"
        [disableSelection]="false" [cols]="cols" (gridAction)="action($event)" [gridTitle]="false" [pagination]="false" [showLineItem]="true" [lineItemCols]="line_item_cols"
        [loadPage]="true" [actionButtons]="actionButtons">
    </ppl-list-table>
    }@else {
    <nz-row>
        <p>No Data found</p>
    </nz-row>
    }
</ppl-accordion>


<div class="filter-panel">
    <ng-template #timesheetDialog let-cancel="cancel">
       <ppl-timesheets-add-entries></ppl-timesheets-add-entries>
    </ng-template>
</div>