import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ParticipantInformationComponent } from '../../participant-information/participant-information.component';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { UploadComponent } from '../../../../shared/components/upload/upload.component';

@Component({
  selector: 'ppl-enrollment-info',
  standalone: true,
  imports: [
    NzFormModule,
    FormsModule,
    ReactiveFormsModule,
    NzGridModule,
    NzCardModule,
    NzRadioModule,
    NzDatePickerModule,
    CommonModule,
    NzToolTipModule,
    NzIconModule,
    NzInputModule,
    NzSelectModule,
    UploadComponent,
    NzDividerModule,
  ],
  templateUrl: './enrollment-info.component.html',
  styleUrl: './enrollment-info.component.css',
})
export class EnrollmentInfoComponent
  extends ParticipantInformationComponent
  implements OnChanges
{
  @Input() updatedFormValue: FormGroup['value'] | null;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['updatedFormValue'] &&
      changes['updatedFormValue'].currentValue &&
      this.updatedFormValue
    ) {
      this.infoForm.markAsDirty();
      this.infoForm.patchValue(this.updatedFormValue);
    }
  }
}
