import { FormGroup } from "@angular/forms";
import { StepInfo } from "../../interfaces/referralSections.interface";
import { DetailsHelperService } from "./details-helper.service";
import { DateTimeFormatterService } from "./date-time-formatter.service";
import { inject } from "@angular/core";
import { LoggerService } from "../services/logger.service";
import { DAYS_OF_WEEK } from "../constants/static-data.constants";
import { FormFieldAttributes } from "../../interfaces/form-types.interface";

export class BaseComponent {
  secData: any;
  data: StepInfo[] = [];
  helperService: DetailsHelperService;
  validationErrors: any = {};
  daysOfWeek: string[] = DAYS_OF_WEEK;
  dateFormatter: DateTimeFormatterService;
  log: any;

  constructor() {
    this.helperService = new DetailsHelperService();
    this.dateFormatter = inject(DateTimeFormatterService);
    this.log = inject(LoggerService);
  }

  isBtnAvailable(btn: string): any {
    return this.helperService.checkIfButtonAvaibale(this.secData, btn);
  }

  isAttrAvailable(id: string): any {
    //console.log(this.secData);
     //console.log(id);
    return this.helperService.checkIfAttributeAvaibale(this.secData, id);
  }

  getAttributes(id: string): any {
    return this.helperService.getAttributes(this.secData, id);
  }

  getFormFieldAttributes(id: string): FormFieldAttributes {
    return this.helperService.getAttributes(this.secData, id);
  }

  getSteps(id: string): any {
    return this.helperService.getSteps(this.data, id);
  }

  extractValues(key: string): any {
    return this.helperService.extractValues(this.secData, key);
  }

  getLookupValue(code: any, check: any) {
    try {
      return this.helperService.getLookupValue(code, check);
    } catch (e) {
      console.log(e);
      return code;
    }
  }

  getLookup(key: string) {
    return this.helperService.getLookup(key);
  }

  hasAnyValueInForm(form: FormGroup): boolean {
    return Object.values(form.controls).some((control) => {
      const value = control?.value;
      // If value is null or undefined, return false
      if (value === null || value === undefined) {
        return false;
      }
     
      // Check if the value is a non-empty string
      if (typeof value === 'string') {
        return value.trim() !== '';
      }
      if (typeof value === 'number') {
        return value > 0;
      }

      // Check if the value is an array
      if (Array.isArray(value)) {
        return value.some((item) => {
          if (typeof item === 'string') {
            return item.trim() !== '' && item.trim().length > 0;
          }
          if (typeof item === 'boolean') {
            return item === true;
          }

          if(typeof item === 'object') {
            return Object.entries(item as object).some((itm: any)=>{
              return Object.values(itm[1]).some((itmVal: any) => {
                  return typeof itmVal ==='string' && itmVal.trim()!== '' && itmVal.trim().length > 0;
              });
            });
          }
          return false;
        });
      }

      if (!Array.isArray(value) && typeof value === 'object') {
        return Object.values(value).some((fieldValue) => {
          if (typeof fieldValue === 'string') {
            return fieldValue.trim() !== '';
          }
          if (typeof fieldValue === 'boolean') {
            return fieldValue === true;
          }
          if (fieldValue && typeof fieldValue === 'number') {
            return fieldValue > 0;
          }
          return false;
        });
      }
  
      // For other types, return false
      return false;
    });
  }
  
  

  getDaysSelected(daysOftheWeek: boolean[]): string[] {
    try {
      return daysOftheWeek
        .map((checked: boolean, i: number) =>
          checked ? this.daysOfWeek[i] : null
        )
        .filter((day: any) => day !== null) as string[];
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  getDaysOftheWeek(daysOftheWeek: string[]): boolean[] {
    try {
      return this.daysOfWeek.map((day) => daysOftheWeek.includes(day));
    } catch (e:any) {
        this.log.info('Base Component : getDaysOftheWeek', e.message);
      return [];
    }
  }

  transformDateToValidPipeFormat(date: string): string {
    const [datePart, timePart] = date.split(' ');
    const [month, day, year] = datePart.split('/');
    const [hours, minutes, seconds, milliseconds] = timePart.split(':');

    return new Date(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day),
      parseInt(hours),
      parseInt(minutes),
      parseInt(seconds),
      parseInt(milliseconds)
    ).toISOString()
  }

  formatDateString(date: string): string {
    try {
      return this.dateFormatter.formatDateTime(date);
    } catch (e:any) {
        this.log.info('Base Component : formatDateString', e.message);
      return '';
    }
  }

  formatDateStringNoMs(date: string): string {
    try {
      return this.dateFormatter.formatDateTimeNoMs(this.transformDateToValidPipeFormat(date));
    } catch (e:any) {
        this.log.info('Base Component : formatDateString', e.message);
        return '';
      }
  }

  getTimeFromDate(date: Date): string {
    try {
      return this.dateFormatter.getTimeFromDate(date);
    } catch (e:any) {
        this.log.info('Base Component : getTimeFromDate', e.message);
      return '';
    }
  }

  parsePreferredDaysAndTime(preferredDaysAndTime: string): any {
    try {
      return this.dateFormatter.parsePreferredDaysAndTime(preferredDaysAndTime);
    } catch (e: any) {
      this.log.info('Base Component : parsePreferredDaysAndTime', e.message);
      return null;
    }
  }

  getDateTimeFromTime(time: string) {

    try {
        return this.dateFormatter.getDateTimeFromTime(time);
      } catch (e: any) {
        this.log.info('Base Component : getDateTimeFromTime', e.message);
        return null;
      }
    
  }

  getStartAndEndTimes(startAndEndTime : string) :  any{
    //input format "06:30 AM - 06:30 PM"
    try {
      return this.dateFormatter.getStartAndEndTimes(startAndEndTime);
    } catch (e: any) {
      this.log.info('Base Component : parsePreferredDaysAndTime', e.message);
      return null;
    }
  }
}