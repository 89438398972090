import { Component, Input, OnInit } from '@angular/core';
import { UploadComponent } from "../../../shared/components/upload/upload.component";
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { BaseComponent } from '../../../shared/utils/base.component';
import { SectionsService } from '../../../services/sections.service';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ReferralDataService } from '../../../services/referral-data.service';
import { NzFormModule } from 'ng-zorro-antd/form';
import { ListTableComponent } from '../../../shared/components/list-table/list-table.component';
import { STATIC_DATA, STATIC_JSON_DATA_PATH } from '../../../shared/constants/static-data.constants';
import { CommonModule } from '@angular/common';
import { LeadDetailsService } from '../../../services/lead-details.service';
import { FileUploadComponent } from '../../lead-details/documents/file-upload/file-upload/file-upload.component';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'ppl-upload-docs',
  standalone: true,
  imports: [
    UploadComponent,
    NzCardModule,
    NzGridModule,
    NzFormModule,
    ReactiveFormsModule,
    ListTableComponent, CommonModule,
    FileUploadComponent
  ],
  templateUrl: './upload-docs.component.html',
  styleUrl: './upload-docs.component.css',
})
export class UploadDocsComponent extends BaseComponent implements OnInit {
  @Input() mode = 'edit';
  licences: any[] = [];
  uploadDocsForm!: FormGroup;
  fileList: any = [];
  formHasBeenFilled: any;
  filledInfo: any = {};
  cols: any[]=[];
  flow: any;
  leadId: any;
  steps: any;
  showFileList = false;

  constructor(
    private sectionsService: SectionsService,
    private referralDataService: ReferralDataService,
    private fb: FormBuilder,
    private leadDetailsService: LeadDetailsService,
    private sharedService: SharedService
  ) {
    super();
  }

  ngOnInit(): void {

    this.getFileCols();

    this.uploadDocsForm = this.fb.group({
      filesUploaded: [[]],
    });
    const program = JSON.parse(localStorage.getItem('selected_program')!);
    this.steps = this.sectionsService.getSectionData(program.program_code);
    this.secData = this.steps['upload_docs'];
    this.licences = this.getLookup('License_Type');
    this.flow = this.sectionsService.getFlow();


    this.uploadDocsForm.valueChanges.subscribe(() => {
      this.fileList = this.uploadDocsForm.value.filesUploaded;
      const hasValue = this.hasAnyValueInForm(this.uploadDocsForm);
      this.formHasBeenFilled = hasValue;
      this.referralDataService.setReferralFormData(
        'isUploadFormUpdated',
        this.formHasBeenFilled
      );

      this.referralDataService.setReferralFormData(
        'filesUploaded',
        this.uploadDocsForm.value
      );
      this.referralDataService.setReferralFormData(
        'isUploadFormDirty',
        this.uploadDocsForm.dirty
      );
      if (this.fileList.length > 0) {
        this.referralDataService.setReferralFormData('isUploadFormDirty', true);
      }
    });

    if (
      this.sectionsService.getFlow() == 'referral' &&
      localStorage.getItem('lead_entry') == 'referral' &&
      !this.referralDataService.getReferralFormData().filesUploaded
    ) {
      const filledData =
        this.referralDataService.getReferralDetails('filesUploaded')!;
      if (filledData) {
        this.uploadDocsForm.patchValue(filledData);
      }
    }

    if (this.referralDataService.getReferralFormData().filesUploaded) {
      this.filledInfo =
        this.referralDataService.getReferralFormData().filesUploaded;
       this.uploadDocsForm.patchValue(this.filledInfo);
    }

    this.referralDataService.nextClicked.subscribe(() => {
      if (this.fileList.length > 0) {
        this.referralDataService.setError([]);
      }
    });
    if(this.flow === STATIC_DATA.REFERRAL){
      this.showFileList = true;
    }
    this.leadId = '';
    if(this.flow === STATIC_DATA.PARTICIPANT_ENROLLMENT){
      this.leadId = localStorage.getItem('participantId');
      this.getDocumentsUploaded();
    }
    if(this.flow === STATIC_DATA.PROVIDER_ENROLLMENT){
      this.leadId = localStorage.getItem('providerId')
      this.getDocumentsUploaded();
    }
    if(this.flow === STATIC_DATA.REFERRAL){
      this.leadId = localStorage.getItem('referralId')
    }
  }

  filesUpdated() {
    console.log('Files List update');
      this.getDocumentsUploaded();
  }

  filesUploaded(files: any) {
    this.uploadDocsForm.patchValue({
      filesUploaded: files,
    });
    if(this.flow === STATIC_DATA.PARTICIPANT_ENROLLMENT || this.flow === STATIC_DATA.PROVIDER_ENROLLMENT){
      this.getDocumentsUploaded();
    }
  }

  async getFileCols() {
    const tempCols = await this.sectionsService.getJsonConfigurationData(
      STATIC_JSON_DATA_PATH.FILE_UPLOAD_HEADERS
    );
    this.cols =  tempCols?.documents?.columns;
  }

  hasUploadedFile(): boolean {
    return (
      this.filledInfo['filesUploaded'] &&
      this.filledInfo['filesUploaded'].length > 0
    );
  }

  getDocumentsUploaded() {
    const program = JSON.parse(localStorage.getItem('selected_program')!);
    const flow = this.sectionsService.getFlow()!;
    this.sharedService.getUploadedFileDetails(program.program_code, this.leadId, flow).subscribe((data) => {
      this.filledInfo= data?.responsedata;
    })
  }
}
