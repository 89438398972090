/* src/app/components/leads/service-details/service-details.component.css */
.nz-form .ant-form-item {
  display: flex;
  align-items: center;
}
.nz-form .ant-form-item-control {
  flex: 1;
}
.time-picker {
  border: 1px solid lightgray;
  margin-bottom: 24px;
}
::ng-deep .ant-picker-input > input {
  border: none;
}
::ng-deep .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: 0;
}
.grid {
  display: grid;
  grid-template-columns: 30% 30% 30% auto;
  gap: 4px;
  margin-bottom: 4px;
}
::ng-deep .ant-checkbox + span {
  padding-right: 10px;
  padding-left: 4px;
}
.ant-radio-button-wrapper {
  margin: 0px;
  width: 100%;
}
/*# sourceMappingURL=service-details.component-THGY2I5W.css.map */
