import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {

  private header: HttpHeaders = new HttpHeaders({ responseType: 'JSON' });



  constructor(private _http: HttpClient) { }



  /**
   * Gets http request service
   * @param url 
   * @param [header] 
   * @returns get 
   */
  public get<T = object>(url: string, isWriteRequest?: boolean, header?: object): Observable<T> {
    const options = {
      headers: header === undefined ? this.header : new HttpHeaders(header as Record<string, string | string[]>),
    };

    if (isWriteRequest) {
      options.headers = options.headers.append('rfrw', 'true');
    }

    return this._http.get<T>(url, options);
  }

  /**
   * Posts http request service
   * @param [url] 
   * @param [body] 
   * @param [header] 
   * @returns post 
   */
  public post<T = object>(url: string, body: object, isWriteRequest?: boolean, header?: object): Observable<T> {
    const options = {
      headers: header === undefined ? this.header : new HttpHeaders(header as Record<string, string | string[]>),
    };

    if (isWriteRequest) {
      options.headers = options.headers.append('rfrw', 'true');
    }

    return this._http.post<T>(url, body, options);
  }

  /**
   * Puts http request service
   * @param [url] 
   * @param [body] 
   * @param [header] 
   * @returns put 
   */
  public put<T = object>(url: string, body?: object, header?: object, isWriteRequest = true): Observable<T> {
    const options = {
      headers: header === undefined ? this.header : new HttpHeaders(header as Record<string, string | string[]>),
    };

    if (isWriteRequest) {
      options.headers = options.headers.append('rfrw', 'true');
    }

    return this._http.put<T>(url, body, options);
  }

  /**
   * Deletes http request service
   * @param [url] 
   * @param [header] 
   * @param [body] 
   * @returns delete 
   */
  public delete<T = object>(url: string, header?: object, body?: object, isWriteRequest = true): Observable<T> {
    const options = {
      headers: header === undefined ? this.header : new HttpHeaders(header as Record<string, string | string[]>),
      body: body,
    };

    if (isWriteRequest) {
      options.headers = options.headers.append('rfrw', 'true');
    }

    return this._http.request<T>('delete', url, options);
  }

  /**
  * Performs an HTTP PATCH request.
  * @param [url] The URL for the PATCH request.
  * @param [body] The body of the request.
  * @param [header] Optional headers to be sent with the request.
  * @returns Observable<Object> Observable containing the response from the server.
  */
  public patch<T = object>(url: string, body: object, header?: object, isWriteRequest = true): Observable<T> {
    const options = {
      headers: header === undefined ? this.header : new HttpHeaders(header as Record<string, string | string[]>),
    };

    if (isWriteRequest) {
      options.headers = options.headers.append('rfrw', 'true');
    }

    return this._http.patch<T>(url, body, options);
  }


}
