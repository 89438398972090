@if(entry === 'provider' && filedInfo?.providerDocumentDetails.length > 0){
<ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="!readonly" [data]="fileListInfo"
  [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false" [loadPage]="true"
  (gridAction)="action($event)" [actionButtons]="actionButtons">
</ppl-list-table>

}
@else if (entry === 'referral' && filedInfo?.referralDocumentDetails.length > 0) {
<ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="!readonly" [data]="fileListInfo"
  [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false" [loadPage]="true"
  (gridAction)="action($event)" [actionButtons]="actionButtons">
</ppl-list-table>
}
@else if (entry === 'participantEnrollment' && filedInfo?.participantEnrlDocumentDetails?.length > 0) {
<ppl-list-table [disableSort]="true" [disableSearch]="true" [tableWidth]="tableWidth" [enableActions]="!!showButtons && !readonly"
  [data]="fileListInfo" [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false"
  [loadPage]="true" (gridAction)="action($event)" [actionButtons]="actionButtons">
</ppl-list-table>
}
@else if (entry === 'providerEnrollment' && filedInfo?.providerEnrlDocumentDetails?.length > 0) {
<ppl-list-table [disableSort]="true" [disableSearch]="true" [tableWidth]="tableWidth" [enableActions]="!!showButtons && !readonly"
  [data]="fileListInfo" [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false"
  [loadPage]="true" (gridAction)="action($event)" [actionButtons]="actionButtons">
</ppl-list-table>
}
@else if (summary){
<nz-row>
  <p>No Files Uploaded</p>
</nz-row>
}
@if (leadStatus !== approvedStatus && showUpload) {
<div class="ppl-mt20">
  <!-- <div nz-row *ngFor="let file of filedInfo.providerDocumentDetails">
    <div nz-col nzSpan="6" class="ppl-read-label">{{file.fileName}} </div>
    <a (click)="fecthcFile()">
      <button nz-button nzType="primary" nzSize="small">
        view
      </button>
    </a>

    <span nz-icon nzType="delete" (click)="deleteFile()" nzTheme="outline"></span>
  </div> -->
  @if(!readonly){
  <nz-form-item class="ppl-form-item pt-2">
    <nz-form-control class="ppl-form-control" nzSpan="8">
      <p style="font-size:0.929rem;">Please upload supporting documents</p>
      <ppl-upload (filesUploaded)="filesUploaded($event)" [fileList]="fileList" [flow]="entry" [leadId]="leadId"
        [docTypeList]="licences" [showFileList]="false"></ppl-upload>
    </nz-form-control>
  </nz-form-item>
  }
</div>
}