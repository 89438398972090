import { NgZorroModule } from './../../../../shared/modules/ng-zorro/ng-zorro.module';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ListTableComponent } from "../../../../shared/components/list-table/list-table.component";
import { BaseComponent } from '../../../../shared/utils/base.component';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LookupService } from '../../../../services/lookup.service';
import { LeadDetailsService } from '../../../../services/lead-details.service';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { performValidation } from '../../../../shared/utils/validation.util';
import { LoggerService } from '../../../../shared/services/logger.service';
import { RADIO_YES_NO_OPTIONS, STATIC_DATA } from '../../../../shared/constants/static-data.constants';
import { AuthorizeDirective } from '../../../../shared/authorization/authorize.directive.';
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import { NotificationService } from '../../../../shared/services/notification.service';
import { ERROR_MESSAGES } from '../../../../shared/constants/error-message.constants';

@Component({
  selector: 'ppl-lead-detail-communication-info',
  standalone: true,
  imports: [NgZorroModule, CommonModule, ListTableComponent, FormsModule, ReactiveFormsModule, NzTimePickerModule, AuthorizeDirective, ButtonComponent],
  templateUrl: './lead-detail-communication-info.component.html',
  styleUrl: './lead-detail-communication-info.component.css'
})
export class LeadDetailCommunicationInfoComponent extends BaseComponent implements OnInit, OnChanges {

  preferncesForm!: FormGroup;
  mode = "read";
  filledInfo!: any;
  contactType: any[] = [];
  languages: any[] = [];
  accommodations: any[] = [];
  steps: any;
  parsedBestTimeToContact: any;
  spokenLanguages: any;
  entry!: string;
  selectedLead: any;
  approvedStatus: string = STATIC_DATA.APPROVED;
  leadStatus = '';
  formLanguages = [
    {
        "lkpValueCode": "AR",
        "lkpValueName": "Arabic"
    },
    {
        "lkpValueCode": "BN",
        "lkpValueName": "Bangla"
    },
    {
        "lkpValueCode": "CMN",
        "lkpValueName": "Mandarin"
    },
    {
        "lkpValueCode": "EN",
        "lkpValueName": "English"
    },
    {
        "lkpValueCode": "FR",
        "lkpValueName": "French"
    },
    {
        "lkpValueCode": "HT",
        "lkpValueName": "Haitian Creole"
    },
    {
        "lkpValueCode": "IT",
        "lkpValueName": "Italian"
    },
    {
        "lkpValueCode": "JI",
        "lkpValueName": "Yiddish"
    },
    {
        "lkpValueCode": "KO",
        "lkpValueName": "Korean"
    },
    {
        "lkpValueCode": "PL",
        "lkpValueName": "Polish"
    },
    {
        "lkpValueCode": "RU",
        "lkpValueName": "Russian"
    },
    {
        "lkpValueCode": "SP",
        "lkpValueName": "Spanish"
    },
    {
        "lkpValueCode": "UR",
        "lkpValueName": "Urdu"
    },
    {
        "lkpValueCode": "YUE",
        "lkpValueName": "Cantonese"
    }
]

  participantRegisterMode: any;

  @Input() set sectionData(data: string) {
    /* console.log(data); */
    this.steps = data
  }

  @Output() leadUpdated = new EventEmitter<boolean>();
  @Input() pageId = '';
  @Input() tabId = '';
  @Input() programCode  = '';
  @Input() selectedLeadId!: number; 
  radioOptions = RADIO_YES_NO_OPTIONS;

  constructor(private fb: FormBuilder, 
    private logger : LoggerService,
    private lookupService: LookupService, private leadDetailsService: LeadDetailsService, private notificationService : NotificationService) {
    super()
  }


  ngOnInit(): void {
    
    this.participantRegisterMode = this.getLookup('Participant_Register_Mode');
    this.selectedLead = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!);
    this.entry = localStorage.getItem("lead_entry")!;
    this.leadDetailsService.getLeadStatus().subscribe((status: string) => {
      this.leadStatus = status;
      console.log('Lead Status', status);
    });
    this.getLookupData()
    this.createFormGroup();
    if (this.mode == 'read') {

      this.leadDetailsService.getUpdatedLeadData().subscribe(
        (data)=> {

          if(this.entry == STATIC_DATA.PROVIDER_ENROLLMENT){
            this.filledInfo = data?.responsedata?.communicationPreferences;
          }else{
            this.filledInfo = data?.responsedata?.contactDetailsData;
          }
          

            if(this.filledInfo && this.filledInfo.bestTimeToContact){
             this.parsedBestTimeToContact =  super.parsePreferredDaysAndTime(this.filledInfo.bestTimeToContact);
             /* this.logger.debug("this.parsedBestTimeToContact =====>", this.parsedBestTimeToContact ) */
            }
            
            if(this.filledInfo && this.filledInfo.spokenLanguages){
              this.spokenLanguages = this.filledInfo.spokenLanguages.map((lang :any) => {return lang.language})
              /* this.logger.debug("this.spokenLanguages =====>", this.spokenLanguages ) */
            }
          
        }
      )
      //this.filledInfo = this.staticData;
    }

  }


  ngOnChanges(changes: SimpleChanges){
    try{
      if(changes['sectionData'] && changes['sectionData'].currentValue){
        this.secData = this.steps['contact_detail_comm_preferences'];
      }
    }catch(e){
      console.log('Error in getting section data and updating latest info', e);
    }
  }


createFormGroup() : void  {
  
  const formElements : any = {
    leadContactDetailsId : new FormControl(),
    leadId : new FormControl(),
    spokenLanguages : new FormControl(''),
    primaryLanguage : new FormControl(''),
    formLanguage : new FormControl(''),
    otherDetails: new FormControl(''),
    specialAccomdation: new FormControl(''),
    specialAccomdationOther: new FormControl(''),
    
  }


  if(this.entry == STATIC_DATA.PARTICIPANT || this.entry == STATIC_DATA.REFERRAL || this.entry == STATIC_DATA.PARTICIPANT_ENROLLMENT){
    formElements.daysToContact = this.fb.array(
      this.daysOfWeek.map(() => this.fb.control(false))
    );
    formElements.bestTimeToContact = new FormControl();
    formElements.bestTimeToContactForSave = new FormControl('');
    formElements.startContactTime = new FormControl(null);
    formElements.endContactTime = new FormControl(null);
    formElements.contactType = new FormControl('');
    formElements.contactFirstName = new FormControl('');
    formElements.contactLastName = new FormControl('');
    formElements.contactEmail = new FormControl('');
    formElements.contactPhone = new FormControl('');
    formElements.registerPref = new FormControl('');
  }

  if(this.entry == STATIC_DATA.PROVIDER || this.entry == STATIC_DATA.PROVIDER_ENROLLMENT){
    formElements.availabilityDays= this.fb.array(
      this.daysOfWeek.map(() => this.fb.control(false))
    );
    formElements.availabilityTimingStart = new FormControl(null);
    formElements.availabilityTimingEnd = new FormControl(null);
    formElements.distanceWillingToTravel = new FormControl(null);
    formElements.dayEmergencyAvailablityStart = new FormControl(null);
    formElements.dayEmergencyAvailablityEnd = new FormControl(null);
    formElements.nightEmergencyAvailablityStart = new FormControl(null);
    formElements.nightEmergencyAvailablityEnd = new FormControl(null);
    formElements.isAvailableForEmergencies = new FormControl();
    formElements.registerPref = new FormControl('');
  }

  if(this.entry === STATIC_DATA.PROVIDER_ENROLLMENT){
    formElements.emergencyContactFirstName = new FormControl(null);
    formElements.emergencyContactLastName = new FormControl(null);
    formElements.emergencyContactPhone = new FormControl(null);
  }
  

  if(this.entry === STATIC_DATA.REFERRAL){
    formElements.registerPref = new FormControl('');
  }

  this.preferncesForm = new FormGroup(formElements);
}
  getLookupData(){

    this.languages = this.lookupService.getLookupValue("Language");
    this.contactType = this.lookupService.getLookupValue("Contact_Type");
    this.accommodations = this.lookupService.getLookupValue("Special_Accomdation");

  }

  getValue(code: string, valueOf: string) {
    let check;
    if (valueOf == "lang") {
      check = this.languages;
    }
    else if (valueOf == "contact") {
      check = this.contactType
    }
    else {
      check = this.accommodations
    }
    return this.lookupService.getLookupName(check, code);
  }

  editInfo() {
    this.mode = 'edit';
    this.preferncesForm.patchValue(this.filledInfo);

    if(this.entry == STATIC_DATA.PROVIDER || this.entry == STATIC_DATA.PROVIDER_ENROLLMENT){
      let emergencyAvailabilityDay = null;
      let emergencyAvailabilityNight = null;
      /* if(!this.filledInfo?.emergencyAvailabilityDay.includes('NaN')){ */
        emergencyAvailabilityDay = super.getStartAndEndTimes(this.filledInfo?.emergencyAvailabilityDay);  
      /* } */
      
      /* if(!this.filledInfo?.emergencyAvailabilityNight.includes('NaN')){ */
        emergencyAvailabilityNight = super.getStartAndEndTimes(this.filledInfo?.emergencyAvailabilityNight)
      /* } */
       
      this.preferncesForm.patchValue({spokenLanguages : this.spokenLanguages, 
          isAvailableForEmergencies : this.filledInfo?.isAvailableForEmergencies === true ? 'Yes' : 'No',
          availabilityTimingStart: super.getDateTimeFromTime(this.parsedBestTimeToContact?.startTime),
          availabilityTimingEnd: super.getDateTimeFromTime(this.parsedBestTimeToContact?.endTime),
          availabilityDays : super.getDaysOftheWeek(this.parsedBestTimeToContact?.days),
          
          dayEmergencyAvailablityStart : emergencyAvailabilityDay ? emergencyAvailabilityDay?.startTime : null,
          dayEmergencyAvailablityEnd : emergencyAvailabilityDay ? emergencyAvailabilityDay?.endTime : null,
          nightEmergencyAvailablityStart : emergencyAvailabilityNight ? emergencyAvailabilityNight?.startTime : null,
          nightEmergencyAvailablityEnd : emergencyAvailabilityNight ? emergencyAvailabilityNight?.endTime : null,
          leadContactDetailsId : this.getContactDetailsId(this.filledInfo)
        });
    }

    if(this.entry == STATIC_DATA.PARTICIPANT || this.entry == STATIC_DATA.REFERRAL || this.entry == STATIC_DATA.PARTICIPANT_ENROLLMENT){
      this.preferncesForm.patchValue({spokenLanguages : this.spokenLanguages, 
        startContactTime: super.getDateTimeFromTime(this.parsedBestTimeToContact?.startTime),
        endContactTime: super.getDateTimeFromTime(this.parsedBestTimeToContact?.endTime),
        daysToContact : super.getDaysOftheWeek(this.parsedBestTimeToContact?.days),
        leadContactDetailsId : this.getContactDetailsId(this.filledInfo)
      });
    }
  }

  getContactDetailsId(source : any){
    if(this.entry === STATIC_DATA.PARTICIPANT || this.entry === STATIC_DATA.PROVIDER){
      return source?.leadContactDetailsId
    }else if(this.entry === STATIC_DATA.REFERRAL){
      return source?.referralContactDetailsId;
    }else if(this.entry === STATIC_DATA.PARTICIPANT_ENROLLMENT){
      return source?.participantContactDetailsId
    }else if(this.entry === STATIC_DATA.PROVIDER_ENROLLMENT){
      return source?.contactDetailsId
    }else{
      return 0
    }
    
  }

  cancelEdit() {
    this.mode = 'read';
    this.preferncesForm.reset();
  }

  save(){
    let payload : any = {};
    const formData = this.preferncesForm.value;
    

    if(this.entry === STATIC_DATA.PROVIDER || this.entry === STATIC_DATA.PROVIDER_ENROLLMENT){
      const selectedDays = super.getDaysSelected(formData.availabilityDays);
      formData.availabilityDays = selectedDays;
      /* formData.isAvailableForEmergencies = formData.isAvailableForEmergencies === true ? 'Yes' : 'No'; */
      formData.availabilityTimingStart = super.getTimeFromDate(formData.availabilityTimingStart);
      formData.availabilityTimingEnd = super.getTimeFromDate(formData.availabilityTimingEnd);
      formData.dayEmergencyAvailablityStart = super.getTimeFromDate(formData.dayEmergencyAvailablityStart);
      formData.dayEmergencyAvailablityEnd = super.getTimeFromDate(formData.dayEmergencyAvailablityEnd);
      formData.nightEmergencyAvailablityStart = super.getTimeFromDate(formData.nightEmergencyAvailablityStart);
      formData.nightEmergencyAvailablityEnd = super.getTimeFromDate(formData.nightEmergencyAvailablityEnd);
      
    }else{
      const selectedDays = super.getDaysSelected(formData.daysToContact);;
      formData.daysToContact = selectedDays;
      formData.startContactTime = super.getTimeFromDate(formData.startContactTime);
      formData.endContactTime = super.getTimeFromDate(formData.endContactTime);
    }

    try{
      this.validationErrors = performValidation(formData, this.secData, 'Save');
    } catch {
      console.log("Error in validations");
    }

    const formDataNull = this.isFormNotEmpty(formData);
    if(!formDataNull){
      this.notificationService.error(
        'Error',
        ERROR_MESSAGES.NO_UPDATE_LEAD_COMM_INFO
      );
      return
    }

    if(Object.keys(this.validationErrors).length === 0){
      
      payload = this.preparePayload(formData);   
      //payload.participantContactDetails.spokenLanguages =  payload.participantContactDetails.spokenLanguages.map((lang:string) => ({ language: lang }));

      /* payload.leadId = formData.leadId;
      payload.leadContactDetailsId = formData.leadContactDetailsId; */

      this.leadDetailsService.updateLeadCommunicationInfo(payload, this.entry).subscribe(
        {
          next: () => {
            this.logger.info('Communication information saved successfully');
            this.leadUpdated.emit(true);
            this.cancelEdit();
          },
          error: () => {
            this.leadUpdated.emit(true);
            this.cancelEdit();
          }
        }
      )
    }
    
  }

  excludeDataCheck = ['bestTimeToContact', 'isAvailableForEmergencies', 'availabilityDays']

  isFormNotEmpty(formValue: any): boolean {
    const hasNonEmptyFields = Object.keys(formValue).some(key => {
      if(!this.excludeDataCheck.includes(key)){
        if (Array.isArray(formValue[key])) {
          // Check if any item in the array is non-empty
          return formValue[key].some((item: any) =>{
            if(typeof item === 'boolean' && item){
              return item
            }
  
            return typeof item ==='string' && item && item.trim().length > 0
          } 
        )}
        // Check other fields
        if(typeof formValue[key] != 'boolean' && typeof formValue[key] !== 'number'){
          console.log("key", key)
          return formValue[key] && formValue[key].trim() !== '';
        }
      }
    
    });
    return hasNonEmptyFields;
  }

  getLanguages():string{

    const languages:string[] = []
      this.spokenLanguages?.forEach((el: string) => {
          languages.push(super.getLookupValue(this.languages, el))
      });
    return languages.join(', ');
  }


  preparePayload(source: any) {
  
    let payload: any = {};

    source.isAvailableForEmergencies = source.isAvailableForEmergencies == 'Yes' ? true : false;
    
    const spokenLanguages: any[]= source?.spokenLanguages?.map((lang:string) => ({ language: lang }));
    
    if (this.entry === STATIC_DATA.PARTICIPANT) {
      payload.participantContactDetails = { ...source };
      payload.contactDetailsId = source.leadContactDetailsId;
      payload.participantContactDetails.spokenLanguages = spokenLanguages;
    } else if (this.entry === STATIC_DATA.PROVIDER) {
      payload.providerCommunicationInfo = { ...source };
      payload.providerCommunicationInfo.spokenLanguages = spokenLanguages;
    } else if (this.entry === STATIC_DATA.REFERRAL) {
      payload.referralParticipantContactDetails = { ...source };
      payload.referralParticipantContactDetails.spokenLanguages =
        spokenLanguages;
    }else if (this.entry === STATIC_DATA.PARTICIPANT_ENROLLMENT){
      payload.participantEnrlContactDetails = { ...source };
      payload.participantEnrlContactDetails.modifiedDate = this.filledInfo?.modifiedDate;
      payload.participantContactDetailsId = source.leadContactDetailsId;
      payload.participantEnrlContactDetails.spokenLanguages =
        spokenLanguages;
    }else{
      payload.providerEnrlContactDetails = { ...source };
      payload.providerEnrlContactDetails.modifiedDate = this.filledInfo?.modifiedDate;
      payload.contactDetailsId = source.leadContactDetailsId;
      payload.providerEnrlContactDetails.spokenLanguages =
        spokenLanguages;
    }

    payload = {...payload, 
                id: this.selectedLead, 
                programCode : this.programCode, 
                contactDetailsId : source.leadContactDetailsId
              };

    return payload;

  }

  disabledNightHours = (): number[] => {
    return this.dateFormatter.disabledEmergencyHours.night;
  };

  disabledDayHours = (): number[] => {
    return this.dateFormatter.disabledEmergencyHours.day;
  };
}
