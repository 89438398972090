import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Observable } from 'rxjs';

export interface AlertDetails {
  type: string,
  title: string;
  message: string;
  isVisible: boolean
}

@Injectable({
  providedIn: 'root'
})
export class ErrorAlertService {

  private alertDetails$ = new BehaviorSubject<AlertDetails>({type: 'error', title: '', message: '', isVisible: false});

  constructor(private modalService: NzModalService) {}

  setAlertDetails(alertDetails: AlertDetails): void {
    this.alertDetails$.next(alertDetails)
  }

  getAlertDetails(): Observable<AlertDetails> {
    return this.alertDetails$.asObservable();
  }

  openModal(type: string, title: string, message: string ): void {
    const alertDetails = {
      type, title, message, isVisible: true
    }
    this.setAlertDetails(alertDetails);
  }
}
