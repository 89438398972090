import { Component, OnInit } from '@angular/core';
import { LeadSignatureComponent } from "../../../shared/components/signature/signature.component";
import { AccordionComponent } from "../../../shared/components/accordion/accordion.component";
import { AuthorizeDirective } from '../../../shared/authorization/authorize.directive.';
import { NgZorroModule } from '../../../shared/modules/ng-zorro/ng-zorro.module';
import { ButtonComponent } from "../../../shared/components/button/button.component";
import { LeadDetailsService } from '../../../services/lead-details.service';
import { ListTableComponent } from '../../../shared/components/list-table/list-table.component';
import { BaseComponent } from '../../../shared/utils/base.component';
import { LoggerService } from '../../../shared/services/logger.service';
import { MEMBER_VIEW_PAGE_IDS, PAGE_CONSTANTS, TABS } from '../../../shared/constants/page.constants';
import { STATIC_DATA } from '../../../shared/constants/static-data.constants';
import { NotificationService } from '../../../shared/services/notification.service';
import { CommonModule } from '@angular/common';
import { AuthorizationUtility } from '../../../shared/authorization/auth.utility';

@Component({
  selector: 'ppl-lead-detail-signature',
  standalone: true,
  imports: [LeadSignatureComponent, AccordionComponent, ListTableComponent, AuthorizeDirective, NgZorroModule, ButtonComponent, CommonModule],
  templateUrl: './lead-detail-signature.component.html',
  styleUrl: './lead-detail-signature.component.css'
})


export class LeadDetailSignatureComponent extends BaseComponent implements OnInit {


  pageId = MEMBER_VIEW_PAGE_IDS[localStorage.getItem(STATIC_DATA.URL_FIRST_SEGMENT)!];
  tabId = TABS.Signature;

  updateSignature = false;
  signatureData: any;
  mode = "read";
  copyOfGridData: any = [];

  entry = 'base';
  selectedLead: any;
  program: any;
  steps: any;
  entrys: any;
  imageUrl: any;
  isLoading = false;
  isEditable = true;

  constructor(private leadDetailsService: LeadDetailsService, private logger: LoggerService, private notificationService: NotificationService,
    private authUtility: AuthorizationUtility
  ) {
    super();
  }

  ngOnInit() {
    this.isEditable = this.authUtility.isSectionEditable(this.pageId, this.tabId, "Signature");

    //this.entrys = localStorage.getItem('lead_entry');
    this.entry = ((localStorage.getItem("lead_entry")!) === 'participantEnrollment') ? 'participant' : 'provider';
    // this.pageId = this.entry == 'participant' ? PAGE_CONSTANTS.PARTICIPANT_INFORMATION : PAGE_CONSTANTS.PROVIDER_INFORMATION;
    this.program = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_PROGRAM)!);
    try {
      this.selectedLead = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!);
    } catch {
      this.notificationService.alert("error", "Error Fetching Data", "Error getting selected lead data");
    }
    this.getSignatureData(false);
  }

  getSignatureData(readReplica: boolean) {
    this.isLoading = true;
    this.leadDetailsService.fetchLeadSignatureDetailsApi(this.program.program_code, this.selectedLead, this.entry, readReplica).subscribe({
      next: (data) => {
        this.isLoading = false;
        if (data?.responsedata && data.responsedata.length > 0) {
          const activeSign = data?.responsedata.find((data: any) => data.isActive);
          this.updateSignature = true;
          this.imageUrl = activeSign?.signatureImageBase64 ? `data:image/png;base64,${activeSign?.signatureImageBase64}` : undefined;
        }
      }, error: () => {
        this.isLoading = false;
      }
    });
  }


  add(): void {
    this.mode = 'add';
  }

  fieldsChanged(event: any) {
    const program = JSON.parse(localStorage.getItem('selected_program')!);
    if (event.isEntered) {
      const base64Data = event.dataURL.split(',')[1];
      const payload = {
        value: event.name,
        signatureImage: base64Data,
        id: this.selectedLead,
        type: ((localStorage.getItem("lead_entry")!) === 'participantEnrollment') ? 'participant' : 'provider',
        programCode: program.program_code,
      }
      console.log(payload);
      this.leadDetailsService.saveSignature(payload, true).subscribe(() => {
        this.updateSignature = true;
        this.getSignatureData(true);
      })
    }
    else {
      this.updateSignature = true;
      this.getSignatureData(false);
    }
  }
}
