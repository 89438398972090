import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@Component({
  selector: 'ppl-modal',
  templateUrl: './modal.component.html',
  imports: [
    NzModalModule,
    OverlayPanelModule,
    ButtonModule,
    NzIconModule,
    NzSelectModule,
    NzInputModule,
    FormsModule,
    NzDropDownModule,
  ],
  styleUrls: ['./modal.component.css'],
  standalone: true,
})
export class ModalComponent {
  isVisible = false;
  @Input() title = '';
  @Input() isOkText = 'OK';
  @Input() cancelText = 'Cancel';
  @Input() showModal = false;
  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  handleOk(): void {
    this.confirm.emit();
  }

  handleCancel(): void {
    this.cancel.emit();
  }
}
