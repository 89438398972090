import { Component } from '@angular/core';

@Component({
  selector: 'ppl-employer',
  standalone: true,
  imports: [],
  templateUrl: './employer.component.html',
  styleUrl: './employer.component.css'
})
export class EmployerComponent {

}
