import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DAYS_OF_WEEK } from '../shared/constants/static-data.constants';
import { ENDPOINTS } from '../shared/constants/url.constants';
import { formatUrl } from '../shared/utils/format-url-util';
import { HttpRequestService } from '../shared/services/http-request.service';

@Injectable({
  providedIn: 'root',
})
export class ReferralDataService {
  referralFormData: any = {};
  associationFormData: any = {};
  nextClicked = new Subject();
  associationSaveClicked = new Subject();
  flowForAssociation = new Subject();
  validationError = new Subject();
  stepValidation = new Subject();
  referralDetails: any;
  associationDetails: any;
  isChanged = false;
  associationFormReadonly = false;

  constructor(private http: HttpRequestService) { }

  setReferralFormData(form: string, data: any) {
    this.referralFormData[form] = data;
  }

  setAssociationFormData(form: string, data: any) {
    this.associationFormData[form] = data;
  }

  clearAssociationsFormData(){
    this.associationFormData = {}
    this.associationSaveClicked = new Subject();
    this.associationDetails = {};
    this.associationFormReadonly = false;
  }

  setChanged(val: boolean) {
    this.isChanged = val;
  }

  getChanged() {
    return this.isChanged;
  }

  clearReferrralData() {
    this.referralFormData = {};
    this.referralDetails = {};
    this.nextClicked = new Subject();
    this.associationSaveClicked = new Subject();
    this.validationError = new Subject();
    this.isChanged = false;
    localStorage.removeItem('trackingNumber');
    localStorage.removeItem('providerPPLId');
    localStorage.removeItem('providerId');
    localStorage.removeItem('participantPPLId');
    localStorage.removeItem('participantId');
  }

  getReferralFormData() {
    return this.referralFormData;
  }

  getAssociationFormData() {
    return this.associationFormData;
  }

  saveLead(flow: any, data: any, isWriteRequest = false): Observable<any> {
    let url;
    if (flow == "referral") {
      url = ENDPOINTS.SAVE_REFERRAL
    }
    else if (flow == "participantEnrollment") {
      url = ENDPOINTS.ENROLLMENT.SAVE_PARTICIPANT_ENROLLMENT
    }
    else if (flow == "providerEnrollment") {
      url = ENDPOINTS.ENROLLMENT.SAVE_PROVIDER_ENROLLMENT
    }
    else {
      flow = (flow == 'participants') ? 'Participant' : 'Provider';
      url = formatUrl(ENDPOINTS.SAVE_LEAD, { flow });
    }
    return this.http.post<any>(url, data, isWriteRequest);
  }

  setClick(val: any) {
    this.nextClicked.next(val);
  }

  setAssociationFormClick(val: any) {
    this.associationSaveClicked.next(val);
  }

  setError(val: any) {
    this.validationError.next(val);
  }

  setStepValidation(val: any) {
    this.stepValidation.next(val);
  }

  setFlowForAssociation(val: any) {
    this.flowForAssociation.next(val);
  }

  getFlowForAssociation() {
    return this.flowForAssociation
  }

  convertToTimeObject(timeString: string): any {
    if (!timeString) {
      return null;
    }
    const [time, period] = timeString.split(' ');
    if (!time || !period) {
      return null;
    }
    let [hours, minutes] = time.split(':').map(Number);
    if (isNaN(hours) || isNaN(minutes)) {
      return null;
    }
    if (period === 'PM' && hours < 12) hours += 12;
    if (period === 'AM' && hours === 12) hours = 0;

    const resultDate = new Date();
    resultDate.setHours(hours, minutes, 0, 0);
    return resultDate;
  }

  setReferralDetails(details: any) {
    this.referralDetails = details;
  }

  getProviderReferralDetails(form: string, modifiedForm: string): any {
    if (this.referralDetails && form === 'infoData' && this.referralDetails[modifiedForm]) {
      const identifiers = this.referralDetails[modifiedForm]?.identifiers || [];
      const ssn =
        identifiers.find((item: any) => item.type === 'SSN')?.value || '';
      const cin =
        identifiers.find((item: any) => item.type === 'CIN')?.value || '';
      const ein =
        identifiers.find((item: any) => item.type === 'EIN')?.value || '';
      const NPI =
        identifiers.find((item: any) => item.type === 'NPI')?.value || '';
      const isProvidingServices = this.referralDetails[modifiedForm]?.isProvidingServices ? "Yes" : "No";
      const showOnDirectory = this.referralDetails[modifiedForm]?.isShowOnDirectory ? "Yes" : "No";
      return {
        ...this.referralDetails[modifiedForm],
        SSN: ssn,
        CIN: cin,
        EIN: ein,
        NPI: NPI,
        isProvidingServices,
        showOnDirectory
      };
    }

    if (this.referralDetails && form === 'serviceDetailsData' && this.referralDetails[modifiedForm]) {
      const profLicences: string[] =
        this.referralDetails[modifiedForm]?.profLicences?.map(
          (entry: any) => entry.licenseType
        ) || [];
      const servicesRendered: string[] =
        this.referralDetails[modifiedForm]?.servicesRendered?.map(
          (entry: any) => entry.serviceType
        ) || [];
      return {
        ...this.referralDetails[form],
        profLicences,
        servicesRendered
      };
    }

    if (
      this.referralDetails &&
      form === 'communicationData' && this.referralDetails[modifiedForm]
    ) {
      const communicationPreferences = this.referralDetails[modifiedForm];
      console.log("here in service======comm", communicationPreferences);
      const languages: string[] =
      communicationPreferences?.spokenLanguages?.map(
          (entry: any) => entry.language
        ) || [];
      const dates = DAYS_OF_WEEK;
      const daysOfContact = dates.map((day: any) => communicationPreferences.availabilityDays?.includes(day));
      const timeRangeAndDays = communicationPreferences.bestTimeToContact.replace('Preferred Time: ', '');
      const [timeRange, daysPart] = timeRangeAndDays.split('[');
      const [startTime, endTime] = timeRange.split(' - ');
      return {
        ...communicationPreferences,
        spokenLanguages: languages,
        availabilityDays: daysOfContact,
        isAvailableForEmergencies: communicationPreferences.isAvailableForEmergencies ? "Yes": "No",
        availabilityTimingStart: this.convertToTimeObject(startTime),
        availabilityTimingEnd: this.convertToTimeObject(endTime),
      };
    }

    if (this.referralDetails && form === 'contactDetailsData' && this.referralDetails[modifiedForm]) {
      const communicationData = this.referralDetails[modifiedForm] || {};
      const contactMethods = communicationData.contactMethods || [];
      const preferredMethod =
        contactMethods.find((item: any) => item.preferred)?.contactMethod || '';
      const contactValues = contactMethods.reduce((acc: any, item: any) => {
        acc[item.contactMethod] = item.value;
        return acc;
      }, {});

      return {
        ...communicationData,
        altPhone: contactValues.altPhone || '',
        mobile: contactValues.mobile || '',
        email: contactValues.email || '',
        preferredMethod: preferredMethod,
        residentGt18: this.referralDetails['basicInfo']?.residentGt18,
      };
    }

    if (this.referralDetails && form === 'filesUploaded') {
      return {
        ...this.referralDetails[form],
      };
    }

    if (this.referralDetails && form === 'participantAssociations') {
      return this.referralDetails[form];
    }
    return {};
  }

  getReferralDetails(form: string, flow?: string): any {
    if (flow && flow == 'providerEnrollment') {
      let modifiedForm = form;
      if (form == 'infoData') {
        modifiedForm = 'basicInfo';
      } else if (form == 'serviceDetailsData') {
        modifiedForm = 'serviceDetails';
      } else if (form == 'communicationData') {
        modifiedForm = 'communicationPreferences';
      } else if (form == 'contactDetailsData') {
        modifiedForm = 'contactDetails';
      }
      return this.getProviderReferralDetails(form, modifiedForm);
    }

    if (this.referralDetails && form === 'infoData') {
      const identifiers = this.referralDetails[form]?.identifiers || [];
      const ssn =
        identifiers.find((item: any) => item.type === 'SSN')?.value || '';
      const cin =
        identifiers.find((item: any) => item.type === 'CIN')?.value || '';
      const ein =
        identifiers.find((item: any) => item.type === 'EIN')?.value || '';
      const NPI =
        identifiers.find((item: any) => item.type === 'NPI')?.value || '';
      const isProvidingServices = this.referralDetails[form]?.isProvidingServices ? "Yes" : "No";
      const showOnDirectory = this.referralDetails[form]?.isShowOnDirectory ? "Yes" : "No";
      return {
        ...this.referralDetails[form],
        SSN: ssn,
        CIN: cin,
        EIN: ein,
        NPI: NPI,
        isProvidingServices,
        showOnDirectory
      };
    }

    if (this.referralDetails && form === 'serviceDetailsData') {
      const profLicences: string[] =
        this.referralDetails['serviceDetails']?.profLicences?.map(
          (entry: any) => entry.licenseType
        ) || [];
      const servicesRendered: string[] =
        this.referralDetails['serviceDetails']?.servicesRendered?.map(
          (entry: any) => entry.serviceType
        ) || [];
      return {
        ...this.referralDetails[form],
        profLicences,
        servicesRendered
      };
    }

    if (
      this.referralDetails &&
      form === 'communicationData' &&
      this.referralDetails['contactDetailsData']
    ) {
      console.log("here in service======comm", this.referralDetails['contactDetailsData']);
      const languages: string[] =
        this.referralDetails['contactDetailsData']?.spokenLanguages?.map(
          (entry: any) => entry.language
        ) || [];
      const timeRangeAndDays = this.referralDetails[
        'contactDetailsData'
      ].bestTimeToContact.replace('Preferred Time: ', '');
      const [timeRange, daysPart] = timeRangeAndDays.split('[');
      const [startTime, endTime] = timeRange.split(' - ');
      const days = daysPart?.replace(']', '').trim();
      const daysArray = days?.split(',').map((day: any) => day.trim());
      const dates = DAYS_OF_WEEK;
      const daysOfContact = dates.map((day: any) => daysArray?.includes(day));

       
      return {
        ...this.referralDetails['contactDetailsData'],
        spokenLanguages: languages,
        startContactTime: this.convertToTimeObject(startTime),
        endContactTime: this.convertToTimeObject(endTime),
        daysToContact: daysOfContact,
      };
    }

    if (this.referralDetails && form === 'contactDetailsData') {
      const communicationData = this.referralDetails['communicationData'] || {};
      const contactMethods = communicationData.contactMethods || [];
      const preferredMethod =
        contactMethods.find((item: any) => item.preferred)?.contactMethod || '';
      const contactValues = contactMethods.reduce((acc: any, item: any) => {
        acc[item.contactMethod] = item.value;
        return acc;
      }, {});

      return {
        ...communicationData,
        altPhone: contactValues.altPhone || '',
        mobile: contactValues.mobile || '',
        email: contactValues.email || '',
        preferredMethod: preferredMethod,
        residentGt18: this.referralDetails['infoData']?.residentGt18,
      };
    }

    if (this.referralDetails && form === 'filesUploaded') {
      return {
        ...this.referralDetails[form],
      };
    }

    if (this.referralDetails && form === 'participantAssociations') {
      return this.referralDetails[form];
    }
    return {};
  }

  setAssociationDetails(details: any) {
    this.associationDetails = details;
  }
  setAssociationsEditable(editable: boolean) {
    this.associationFormReadonly = editable;
  }

  getAssociationsEditable() {
    console.log('this.associationFormReadonly', this.associationFormReadonly);
    return this.associationFormReadonly;
  }

  getAssociationDetails(form: string, flow?: string): any {
    let modifiedForm = form;
    if (form == 'infoData') {
      modifiedForm = 'basicInfo';
    } else if (form == 'serviceDetailsData') {
      modifiedForm = 'serviceDetails';
    } else if (form == 'communicationData') {
      modifiedForm = 'communicationPreferences';
    } else if (form == 'contactDetailsData') {
      modifiedForm = 'contactDetails';
    }
    if (this.associationDetails && form === 'infoData' && this.associationDetails[modifiedForm]) {
      const identifiers = this.associationDetails[modifiedForm]?.identifiers || [];
      const ssn =
        identifiers.find((item: any) => item.type === 'SSN')?.value || '';
      const ein =
        identifiers.find((item: any) => item.type === 'EIN')?.value || '';
      const NPI =
        identifiers.find((item: any) => item.type === 'NPI')?.value || '';
      return {
        ...this.associationDetails[modifiedForm],
        SSN: ssn,
        EIN: ein,
        NPI: NPI
      };
    }

    if (this.associationDetails && form === 'serviceDetailsData' && this.associationDetails[modifiedForm]) {
      const profLicences: string[] =
        this.associationDetails[modifiedForm]?.profLicences?.map(
          (entry: any) => entry.licenseType
        ) || [];
      const servicesRendered: string[] =
        this.associationDetails[modifiedForm]?.servicesRendered?.map(
          (entry: any) => entry.serviceType
        ) || [];
      return {
        ...this.associationDetails[form],
        profLicences,
        servicesRendered
      };
    }

    if (
      this.associationDetails &&
      form === 'communicationData' && this.associationDetails[modifiedForm]
    ) {
      const communicationPreferences = this.associationDetails[modifiedForm];
      console.log("here in service======comm", communicationPreferences);
  
      return {
        ...communicationPreferences,
        isAvailableForEmergencies: communicationPreferences.isAvailableForEmergencies ? "Yes": "No"
      };
    }

    if (this.associationDetails && form === 'contactDetailsData' && this.associationDetails[modifiedForm]) {
      const communicationData = this.associationDetails[modifiedForm] || {};
      const contactMethods = communicationData.contactMethods || [];
      const contactValues = contactMethods.reduce((acc: any, item: any) => {
        acc[item.contactMethod] = item.value;
        return acc;
      }, {});

      return {
        ...communicationData,
        altPhone: contactValues.altPhone || '',
        mobile: contactValues.mobile || '',
        email: contactValues.email || '',
      };
    }
    return {};
  }

  checkDups(flow: string, data: any) {
    const url = formatUrl(ENDPOINTS.CHECK_DUPS_DATA, { flow });
    return this.http.post<any>(url, data);
  }

  saveInfoData(data: any, isParticipant: boolean, isWriteRequest = false) {
    const url = isParticipant ? ENDPOINTS.ENROLLMENT.SAVE_PARTICIPANT_ENROLLMENT_INFO : ENDPOINTS.ENROLLMENT.SAVE_PROVIDER_ENROLLMENT_INFO;
    return this.http.post<any>(url, data, isWriteRequest);
  }

  saveCommPref(data: any, isParticipant: boolean, isWriteRequest = false) {
    const url = isParticipant ? ENDPOINTS.ENROLLMENT.SAVE_PARTICIPANT_ENROLLMENT_COMM_PREF : ENDPOINTS.ENROLLMENT.SAVE_PROVIDER_ENROLLMENT_COMM_PREF;
    return this.http.post<any>(url, data, isWriteRequest);
  }

  saveServiceDetails(data: any, isWriteRequest = false) {
    const url = ENDPOINTS.ENROLLMENT.SAVE_PROVIDER_ENROLLMENT_SERVICE_DETAILS;
    return this.http.post<any>(url, data, isWriteRequest);
  }

  saveContactDetails(data: any, isParticipant: boolean, isWriteRequest = false) {
    const url = isParticipant ? ENDPOINTS.ENROLLMENT.SAVE_PARTICIPANT_ENROLLMENT_CONTACT_DETAILS : ENDPOINTS.ENROLLMENT.SAVE_PROVIDER_ENROLLMENT_CONTACT_DETAILS;
    return this.http.post<any>(url, data, isWriteRequest);
  }

  getSignature(program_code: string, type: string, id: any, isWriteRequest = false) {
    const url = formatUrl(ENDPOINTS.GET_SIGNATURE, { program_code, type, id });
    return this.http.get(url, isWriteRequest);
  }

  saveSignature(data: any, isWriteRequest = false): Observable<any> {
    return this.http.post<any>(ENDPOINTS.SAVE_SIGNATURE, data, isWriteRequest);
  }

}
