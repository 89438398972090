import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { SectionsService } from '../../../services/sections.service';
import { ButtonComponent } from "../../../shared/components/button/button.component";
import { BaseComponent } from '../../../shared/utils/base.component';
import { PaymentDetailsComponent } from "../../enrollment/payment-details/payment-details.component";
import { CommPreferenceComponent } from '../comm-preference/comm-preference.component';
import { ContactDetailsComponent } from '../contact-details/contact-details.component';
import { FormChecklistComponent } from "../form-checklist/form-checklist.component";
import { NewContactComponent } from '../new-contact/new-contact.component';
import { ChecklistParticipantComponent } from "../participant-checklist/participant-checklist.component";
import { ParticipantInformationComponent } from '../participant-information/participant-information.component';
import { ServiceDetailsComponent } from '../service-details/service-details.component';
import { UploadDocsComponent } from "../upload-docs/upload-docs.component";
import { UploadSignaturesComponent } from "../upload-signatures/upload-signatures.component";

@Component({
  selector: 'ppl-summary',
  standalone: true,
  imports: [ContactDetailsComponent,
    ParticipantInformationComponent,
    NzCollapseModule,
    CommPreferenceComponent,
    ServiceDetailsComponent,
    CommonModule,
    NzButtonModule, UploadDocsComponent, NewContactComponent, PaymentDetailsComponent, ButtonComponent, FormChecklistComponent, UploadSignaturesComponent, ChecklistParticipantComponent],
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.css'
})
export class SummaryComponent extends BaseComponent implements OnInit {

  constructor(private sectionsService: SectionsService) {
    super()
  }

  ngOnInit(): void {
    const program = JSON.parse(localStorage.getItem("selected_program")!);
    // this.data = this.sectionsService.getAllSteps(program.program_code);
    const flow = this.sectionsService.getFlow();
    if(flow === 'providerEnrollment') {
      this.data = this.sectionsService.getProviderSteps(program.program_code);
    } else {
      this.data = this.sectionsService.getAllSteps(program.program_code);
    }
  }

  activeKeys: string[] = [];
  allPanelsExpanded = false;

  hideAll() {
    this.activeKeys = [];
  }

  expandAll() {
    this.activeKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  }

  togglePanel(key: string): void {
    if (this.isPanelActive(key)) {
      this.activeKeys = this.activeKeys.filter(k => k !== key);
    } else {
      this.activeKeys.push(key);
    }
  }

  isPanelActive(key: string): boolean {
    return this.activeKeys.includes(key);
  }

}
