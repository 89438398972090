import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationUtility } from './auth.utility';

@Directive({
  standalone: true,
  selector: '[authorizeFieldView]',
})
export class AuthorizeFieldViewDirective {
  private hasView = false;
  private id!: string;
  private pageId!: string;
  private tabId!: string;
  private section!: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authUtility: AuthorizationUtility
  ) {
  }

  @Input() set authorizeFieldView(config: string[]) {
    this.pageId = config[0];
    this.tabId = config[1];
    this.id = config[2];
    this.section = config[3];
    this.handleView();
  }

  private handleView() {
    if (!this.id || !this.pageId || this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }

    const hasAccess = this.authUtility.isFieldReadable(this.pageId, this.tabId, this.section, this.id);

    if (hasAccess && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }
}