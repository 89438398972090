/* src/app/components/core/layout/footer/footer.component.css */
.copyright {
  color: #163235;
  text-align: right;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0.07px;
}
.footer {
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  display: flex;
  padding: 12px 32px;
  justify-content: flex-end;
  align-items: center;
  background: #FFF;
  text-align: center;
  box-shadow: 4px 0px 54px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
}
/*# sourceMappingURL=footer.component-LEDY6XWX.css.map */
