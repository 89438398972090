@if(mode==="edit"){
<div class="ppl-mt8">
    @for(header of extractValues('header'); track $index){
    <h2>{{header}}</h2>
    }
    @for(text of extractValues('text'); track $index){
    <p class="ppl-description">{{text}}</p>
    }
</div>
<div class="ppl-mt24">
    <nz-card nz-col [nzSpan]="20" class="ppl-p24 ppl-step-main" [nzBordered]="false" >
      <form nz-form [formGroup]="uploadDocsForm" class="ppl-p0">
        <!-- @if(isAttrAvailable('filesUploaded')){ -->
        <nz-form-item class="ppl-form-item pt-2">
          <nz-form-control class="ppl-form-control" >
            <p style="font-size:0.929rem;">Please upload supporting documents</p>
            <ppl-upload [docTypeList] = "licences" (filesUploaded)="filesUploaded($event)" [leadId]="leadId"
            [fileList]="fileList" [showFileList]="showFileList" [flow]="flow"></ppl-upload>
          </nz-form-control>
        </nz-form-item>
        <!-- } -->
      </form>
      @if(flow === 'participantEnrollment' || flow === 'providerEnrollment') {
      <ppl-file-upload [filedInfo]="filledInfo" [showUpload]="false" [tableWidth]="'40rem'" [sectionData]="steps" [entry]="flow" (filesUpdated)="filesUpdated()"></ppl-file-upload>
      }
      <!-- @if(hasUploadedFile() && cols.length > 0){
      <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="false" [data]="filledInfo['filesUploaded']"
      [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false" [loadPage]="true"> -->
      <!-- <ppl-file-upload [filedInfo]="filledInfo" [sectionData]="steps" [entry]="flow" (filesUpdated)="filesUpdated()"></ppl-file-upload> -->
  <!-- </ppl-list-table> -->
<!-- } -->
    </nz-card>
  </div>
}
@else if(mode==="read"){
  <div nz-row>
    <div nz-col nzSpan="6" class="ppl-read-label">Files Uploaded:</div>
    <div nz-col nzSpan="18">
      @if(flow === 'participantEnrollment' || flow === 'providerEnrollment') {
        <ppl-file-upload [filedInfo]="filledInfo" [tableWidth]="'40rem'" [summary]="true" [showButtons]="false" [showUpload]="false" [sectionData]="steps" [entry]="flow" (filesUpdated)="filesUpdated()"></ppl-file-upload>
      }
      @else {
        @if(hasUploadedFile() && cols.length > 0){
          <!-- {{filledInfo['filesUploaded'] | json}} -->
          <ppl-list-table [disableSort]="true" [tableWidth]="'40rem'" [disableSearch]="true" [enableActions]="false" [data]="filledInfo['filesUploaded']"
            [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false" [loadPage]="true">
        </ppl-list-table>
          <!-- @for(file of filledInfo['filesUploaded']; track $index){
            <div><strong>{{ file.type }}</strong></div>
            <div>{{ file.name }}</div>
          } -->
         }
      }
    </div>
  </div>
}