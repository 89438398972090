/* src/app/components/lead-details/authorization/authorization.component.css */
.paystub-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.paystub-item {
  background-color: #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
  border: 1px solid #ddd;
}
.paystub-item:hover {
  background-color: #c8e1e0;
}
.paystub-date {
  color: #01796F;
  font-weight: bold;
  font-size: 1.286rem;
}
.paystub-amount {
  color: #333;
  font-size: 1.143rem;
}
.paystub-status {
  font-size: 1rem;
  color: #666;
}
.download-btn {
  background-color: #01796F;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.download-btn:hover {
  background-color: #01635d;
}
/*# sourceMappingURL=authorization.component-4I34KGXW.css.map */
