@if(mode==="edit"){
@if(source !== 'enrollment'){
<div class="ppl-mt8">
  @for(header of extractValues('header'); track $index){
  <h2>{{header}}</h2>
  }
  @for(text of extractValues('text'); track $index){
  <p class="ppl-description">{{text}}</p>
  }
</div>
}
<div [ngClass]="{'ppl-mt24': source !== 'enrollment'}">
  <nz-card nz-col class="ppl-step-main" [nzBordered]="false">
    <form nz-form [formGroup]="infoForm" class="ppl-p0">
      <nz-row>
        @if(isAttrAvailable('businessName')){
        <div nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" nzFor="businessName"
              class="ppl-form-label">{{getAttributes('businessName').label}}
              @if(getAttributes('businessName').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-form-control [nzSpan]="24" class="ppl-form-control">
              <div nz-tooltip [nzTooltipTitle]="getAttributes('businessName')?.tooltip || ''"
                nzTooltipPlacement="topRight">
                <input nz-input formControlName="businessName" id="businessName"
                  placeholder="{{getAttributes('businessName').placeholder}}"
                  [ngClass]="{'error': validationErrors['businessName'] }" [readonly]="formReadonly" />
              </div>
              @if (validationErrors['businessName']) {
              <span class="pl-1 error-message">{{validationErrors['businessName']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </div>
        }

        @if(isAttrAvailable('fname')){
        <nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" nzFor="fname" class="ppl-form-label">{{getAttributes('fname').label}}
              @if(getAttributes('fname').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-form-control [nzSpan]="24" class="ppl-form-control">
              <div nz-tooltip [nzTooltipTitle]="getAttributes('fname')?.tooltip || ''" nzTooltipPlacement="topRight">
                <input nz-input formControlName="fname" id="fname" placeholder="{{getAttributes('fname').placeholder}}"
                  [ngClass]="{'error': validationErrors['fname'] }" [readonly]="formReadonly" />
              </div>
              @if (validationErrors['fname']) {
              <span class="pl-1 error-message">{{validationErrors['fname']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        }

        @if(isAttrAvailable('mname')){
        <nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" nzFor="mname" class="ppl-form-label">{{getAttributes('mname').label}}
              @if(getAttributes('mname').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-form-control [nzSpan]="24" class="ppl-form-control">
              <div nz-tooltip [nzTooltipTitle]="getAttributes('mname')?.tooltip || ''" nzTooltipPlacement="topRight">
                <input nz-input formControlName="mname" id="mname" placeholder="{{getAttributes('mname').placeholder}}"
                  [ngClass]="{'error': validationErrors['mname'] }" [readonly]="formReadonly" />
              </div>
              @if (validationErrors['mname']) {
              <span class="pl-1 error-message">{{validationErrors['mname']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        }

        @if(isAttrAvailable('lname')){
        <nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" nzFor="lname" class="ppl-form-label">{{getAttributes('lname').label}}
              @if(getAttributes('lname').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-form-control [nzSpan]="24" class="ppl-form-control">
              <div nz-tooltip [nzTooltipTitle]="getAttributes('lname')?.tooltip || ''" nzTooltipPlacement="topRight">
                <input nz-input formControlName="lname" id="lname" placeholder="{{getAttributes('lname').placeholder}}"
                  [ngClass]="{'error': validationErrors['lname'] }" [readonly]="formReadonly" />
              </div>
              @if (validationErrors['lname']) {
              <span class="pl-1 error-message">{{validationErrors['lname']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        }

        @if(isAttrAvailable('maiden_name')){
        <div nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" nzFor="maiden_name"
              class="ppl-form-label">{{getAttributes('maiden_name').label}}
              @if(getAttributes('maiden_name').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-form-control [nzSpan]="24" class="ppl-form-control">
              <div nz-tooltip [nzTooltipTitle]="getAttributes('maiden_name')?.tooltip || ''"
                nzTooltipPlacement="topRight">
                <input nz-input formControlName="maiden_name" id="maiden_name"
                  placeholder="{{getAttributes('maiden_name').placeholder}}"
                  [ngClass]="{'error': validationErrors['maiden_name'] }" [readonly]="formReadonly" />
              </div>
              @if (validationErrors['maiden_name']) {
              <span class="pl-1 error-message">{{validationErrors['maiden_name']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </div>
        }
      </nz-row>
      <nz-row>
        @if(isAttrAvailable('dob')){
        <div nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" nzFor="dob" class="ppl-form-label">{{getAttributes('dob').label}}
              @if(getAttributes('dob').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control [nzSpan]="24" class="ppl-form-control">
              <div nz-tooltip [nzTooltipTitle]="getAttributes('dob')?.tooltip || ''" nzTooltipPlacement="topRight">
                <nz-date-picker formControlName="dob" id="dob" [nzFormat]="'MM/dd/yyyy'"
                  [nzPlaceHolder]="getAttributes('dob').placeholder" [nzDisabledDate]="disabledDate"
                  [ngClass]="{'error': validationErrors['dob'] }" [nzDisabled]="formReadonly" ></nz-date-picker>
              </div>
              @if (validationErrors['dob']) {
              <span class="pl-1 error-message">{{validationErrors['dob']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </div>
        }
        @if(isAttrAvailable('gender')){
        <div nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" nzFor="gender" class="ppl-form-label">{{getAttributes('gender').label}}
              @if(getAttributes('gender').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control [nzSpan]="24" class="ppl-form-control">
              <div nz-tooltip [nzTooltipTitle]="getAttributes('gender')?.tooltip || ''" nzTooltipPlacement="topRight">
                <!-- <nz-radio-group formControlName="gender" class="ppl-radio-group" id="gender"
                      [ngClass]="{'error': validationErrors['gender'] }">
                      @for(opt of gender; track $index){
                      <label nz-radio-button [nzValue]="opt.lkpValueCode">{{opt.lkpValueName}}</label>
                      }
                    </nz-radio-group> -->
                <nz-select formControlName="gender" class="ppl-select" nzId="gender" nzShowSearch nzAllowClear
                  [ngClass]="{'error': validationErrors['gender'] }" [nzDisabled]="formReadonly">
                  @for(opt of gender; track $index){
                  <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                  }
                </nz-select>
              </div>
              @if (validationErrors['gender']) {
              <span class="pl-1 error-message">{{validationErrors['gender']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </div>
        }
        @if(isAttrAvailable('isProvidingServices')){
        <div nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" nzFor="gender"
              class="ppl-form-label">{{getAttributes('isProvidingServices').label}}
              @if(getAttributes('isProvidingServices').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control [nzSpan]="24" class="ppl-form-control">
              <div nz-tooltip [nzTooltipTitle]="getAttributes('isProvidingServices')?.tooltip || ''"
                nzTooltipPlacement="topRight">
                <nz-radio-group formControlName="isProvidingServices" class="ppl-radio-group" id="isProvidingServices"
                  [ngClass]="{'error': validationErrors['isProvidingServices'] }">
                  @for(opt of radioOptions; track $index){
                  <label nz-radio-button [nzValue]="opt.value">{{opt.label}}</label>
                  }
                </nz-radio-group>
              </div>
              @if (validationErrors['isProvidingServices']) {
              <span class="pl-1 error-message">{{validationErrors['isProvidingServices']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </div>
        }
        @if(isAttrAvailable('SSN')){
        <div nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" nzFor="SSN" class="ppl-form-label">{{getAttributes('SSN').label}}
              @if(getAttributes('SSN').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control [nzSpan]="24" class="ppl-form-control">
              <div nz-tooltip [nzTooltipTitle]="getAttributes('SSN')?.tooltip || ''" nzTooltipPlacement="topRight">
                <nz-input-group [nzSuffix]="suffixTemplate" [ngClass]="{'error': validationErrors['SSN'] }">
                  <input nz-input [type]="ssnVisible ? 'text' : 'password'" formControlName="SSN" id="SSN" (input)="formatSSN($event)"
                    placeholder="{{getAttributes('SSN').placeholder}}" [readonly]="formReadonly" />
                </nz-input-group>
              </div>
              <ng-template #suffixTemplate>
                <span nz-icon [nzType]="ssnVisible ? 'eye' : 'eye-invisible'" (click)="ssnVisible = !ssnVisible"></span>
              </ng-template>
              @if (validationErrors['SSN']) {
              <span class="pl-1 error-message">{{validationErrors['SSN']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </div>
        }
        @if(ssnWith9 && flow==="employer"){
        <div nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" class="ppl-form-label">
            </nz-form-label>
            <nz-form-control [nzSpan]="24" class="ppl-form-control">
              <p style="font-size:0.929rem;">Please upload ITIN documents</p>
              <ppl-upload></ppl-upload>
            </nz-form-control>
          </nz-form-item>
        </div>
        }

        @if(isAttrAvailable('CIN')){
        <div nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" nzFor="CIN" class="ppl-form-label">{{getAttributes('CIN').label}}
              @if(getAttributes('CIN').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control [nzSpan]="24" class="ppl-form-control">
              <div nz-tooltip [nzTooltipTitle]="getAttributes('CIN')?.tooltip || ''" nzTooltipPlacement="topRight">
                <input nz-input id="CIN" formControlName="CIN"
                  placeholder="{{getAttributes('CIN').placeholder}}"
                  [ngClass]="{'error': validationErrors['CIN'] }" [readonly]="formReadonly" />
              </div>
              @if (validationErrors['CIN']) {
              <span class="pl-1 error-message">{{validationErrors['CIN']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </div>
        }
      </nz-row>
      <nz-row>
        @if(isAttrAvailable('EIN')){
        <div nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" nzFor="EIN" class="ppl-form-label">{{getAttributes('EIN').label}}
              @if(getAttributes('EIN').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-form-control [nzSpan]="24" class="ppl-form-control">
              <div nz-tooltip [nzTooltipTitle]="getAttributes('EIN')?.tooltip || ''" nzTooltipPlacement="topRight">
                <input nz-input formControlName="EIN" id="EIN" placeholder="{{getAttributes('EIN').placeholder}}"
                  [ngClass]="{'error': validationErrors['EIN'] }" [readonly]="formReadonly" />
              </div>
              @if (validationErrors['EIN']) {
              <span class="pl-1 error-message">{{validationErrors['EIN']}}</span>
              }

            </nz-form-control>
          </nz-form-item>

        </div>
        <div nz-col [nzSm]="18" [nzXs]="24" class="notes">
          <strong>Notes:</strong>
          <p>
            1. If EIN is lost/misplaced, then obtain your existing EIN using "<a>How to ask the IRS
              for a
              147c letter</a>".
          </p>
          <p>
            2. If EIN doesn't exist, PPL will obtain an EIN on your behalf after completion and
            submission
            of registration paperwork.
          </p>
        </div>
        }
        @if(isAttrAvailable('waiver')){
        <div nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" nzFor="waiver" class="ppl-form-label">{{getAttributes('waiver').label}}
              @if(getAttributes('waiver').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control [nzSpan]="24" class="ppl-form-control" nz-tooltip
              nzTooltipTitle="{{getAttributes('waiver').tooltip}}" nzTooltipPlacement="topRight">
              <nz-select formControlName="waiver" class="ppl-select" nzId="waiver" nzPlaceHolder="Select Service Administration"
                [ngClass]="{'error': validationErrors['waiver'] }" nzShowSearch nzAllowClear [nzDisabled]="formReadonly">
                @for(opt of waiver; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
              @if (validationErrors['waiver']) {
              <span class="pl-1 error-message">{{validationErrors['waiver']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </div>
        }
        @if(isAttrAvailable('mcoOrgName')){
        <div nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" nzFor="mcoOrgName" class="ppl-form-label">{{getAttributes('mcoOrgName').label}}
              @if(getAttributes('mcoOrgName').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control [nzSpan]="24" class="ppl-form-control" nz-tooltip
              nzTooltipTitle="{{getAttributes('mcoOrgName').tooltip}}" nzTooltipPlacement="topRight">
              <nz-select formControlName="mcoOrgName" class="ppl-select" nzId="mcoOrgName" nzPlaceHolder="Select MCO"
                [ngClass]="{'error': validationErrors['mcoOrgName'] }" nzShowSearch nzAllowClear [nzDisabled]="formReadonly">
                @for(opt of mcoEntries; track $index){
                <nz-option [nzLabel]="opt.orgName" [nzValue]="opt.orgName"></nz-option>
                }
              </nz-select>
              @if (validationErrors['mcoOrgName']) {
              <span class="pl-1 error-message">{{validationErrors['mcoOrgName']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </div>
        }
        @if(isAttrAvailable('fiOrgName')){
        <div nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" nzFor="fiOrgName" class="ppl-form-label">{{getAttributes('fiOrgName').label}}
              @if(getAttributes('fiOrgName').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control [nzSpan]="24" class="ppl-form-control" nz-tooltip
              nzTooltipTitle="{{getAttributes('fiOrgName').tooltip}}" nzTooltipPlacement="topRight">
              <nz-select formControlName="fiOrgName" class="ppl-select" nzId="fiOrgName" nzPlaceHolder="Select FI"
                [ngClass]="{'error': validationErrors['fiOrgName'] }" nzShowSearch nzAllowClear [nzDisabled]="formReadonly">
                @for(opt of fiEntries; track $index){
                <nz-option [nzLabel]="opt.orgName" [nzValue]="opt.orgName"></nz-option>
                }
              </nz-select>
              @if (validationErrors['fiOrgName']) {
              <span class="pl-1 error-message">{{validationErrors['fiOrgName']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </div>
        }
        @if(isAttrAvailable('relationToParticipant')){
        <div nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" nzFor="relationToParticipant"
              class="ppl-form-label">{{getAttributes('relationToParticipant').label}}
              @if(getAttributes('relationToParticipant').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control [nzSpan]="24" class="ppl-form-control" nz-tooltip
              nzTooltipTitle="{{getAttributes('relationToParticipant').tooltip}}" nzTooltipPlacement="topRight">
              <nz-select formControlName="relationToParticipant" class="ppl-select" nzId="relationToParticipant"
                nzPlaceHolder="Select relation to Consumer"
                [ngClass]="{'error': validationErrors['relationToParticipant'] }" nzShowSearch nzAllowClear [nzDisabled]="formReadonly">
                @for(relation of relationshipOptions; track $index){
                  <nz-option [nzLabel]="relation.lkpValueName" [nzValue]="relation.lkpValueCode"></nz-option>
                  }
              </nz-select>
              @if (validationErrors['relationToParticipant']) {
              <span class="pl-1 error-message">{{validationErrors['relationToParticipant']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </div>
        }
        @if(isAttrAvailable('showOnDirectory')){
        <div nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" nzFor="showOnDirectory"
              class="ppl-form-label">{{getAttributes('showOnDirectory').label}}
              @if(getAttributes('showOnDirectory').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-form-control [nzSpan]="24" class="ppl-form-control">
              <div nz-tooltip [nzTooltipTitle]="getAttributes('showOnDirectory')?.tooltip || ''"
                nzTooltipPlacement="topRight">
                <nz-radio-group [ngClass]="{'error': validationErrors['showOnDirectory'] }"
                  formControlName="showOnDirectory" class="ppl-radio-group" id="showOnDirectory">
                  @for(opt of radioOptions; track $index){
                  <label nz-radio-button [nzValue]="opt.value">{{opt.label}}</label>
                  }
                </nz-radio-group>
              </div>
              @if (validationErrors['showOnDirectory']) {
              <span class="pl-1 error-message">{{validationErrors['showOnDirectory']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </div>
        }
        @if(isAttrAvailable('NPI')){
        <div nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" nzFor="NPI" class="ppl-form-label">{{getAttributes('NPI').label}}
              @if(getAttributes('NPI').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control [nzSpan]="24" class="ppl-form-control">
              <div nz-tooltip [nzTooltipTitle]="getAttributes('NPI')?.tooltip || ''" nzTooltipPlacement="topRight">
                <input nz-input formControlName="NPI" id="NPI" placeholder="{{getAttributes('NPI').placeholder}}"
                  [ngClass]="{'error': validationErrors['NPI'] }" [readonly]="formReadonly" />
              </div>
              @if (validationErrors['NPI']) {
              <span class="pl-1 error-message">{{validationErrors['NPI']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </div>
        }
        @if(isAttrAvailable('additionalDemographics')){
        <div nz-col [nzSm]="24" [nzXs]="24">
          <!-- <nz-divider class="divider"></nz-divider> -->
          <nz-form-label [nzSpan]="24" nzFor="additionalDemographics" class="ppl-form-label"
            style="margin:8px 0 24px 0;">{{getAttributes('additionalDemographics').label}}
            @if(getAttributes('additionalDemographics').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-row>
            @if(isAttrAvailable('race')){
            <div nz-col [nzSm]="6" [nzXs]="24">
              <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
                <nz-form-label [nzSpan]="24" nzFor="race" class="ppl-form-label">{{getAttributes('race').label}}
                  @if(getAttributes('race').mandatory === 'Y'){
                  <span>*</span>
                  }
                </nz-form-label>
                <nz-form-control [nzSpan]="24" class="ppl-form-control" nz-tooltip
                  nzTooltipTitle="{{getAttributes('race').tooltip}}" nzTooltipPlacement="topRight">
                  <nz-select formControlName="race" class="ppl-select" nzId="race" nzShowArrow
                    [ngClass]="{'error': validationErrors['race'] }" [nzDisabled]="formReadonly">
                    @for(race of raceOptions; track $index){
                      <nz-option [nzLabel]="race.lkpValueName" [nzValue]="race.lkpValueCode"></nz-option>
                      }
                  </nz-select>
                  @if (validationErrors['race']) {
                  <span class="pl-1 error-message">{{validationErrors['race']}}</span>
                  }
                </nz-form-control>
              </nz-form-item>
            </div>
            }
            @if(isAttrAvailable('height')){
            <div nz-col [nzSm]="6" [nzXs]="24">
              <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
                <nz-form-label [nzSpan]="24" nzFor="height" class="ppl-form-label">{{getAttributes('height').label}}
                  @if(getAttributes('height').mandatory === 'Y'){
                  <span>*</span>
                  }
                </nz-form-label>
                <nz-form-control [nzSpan]="24" class="ppl-form-control">
                  <div nz-tooltip [nzTooltipTitle]="getAttributes('height')?.tooltip || ''"
                    nzTooltipPlacement="topRight" class="height-input-container ppl-form-control">
                    <nz-row>
                      <nz-col [nzSm]="12" [nzXs]="24">
                        <input nz-input formControlName="heightFeet" id="heightFeet" placeholder="Feet"
                          [ngClass]="{'error': validationErrors['heightFeet']}" [readonly]="formReadonly" />
                        <!-- <span>Feet</span> -->
                      </nz-col>
                      <nz-col [nzSm]="12" [nzXs]="24">
                        <input nz-input formControlName="heightInches" id="heightInches" placeholder="Inches"
                          [ngClass]="{'error': validationErrors['heightInches']}" />
                        <!-- <span>Inches</span> -->
                      </nz-col>
                    </nz-row>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
            }
            @if(isAttrAvailable('weight')){
            <div nz-col [nzSm]="6" [nzXs]="24">
              <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
                <nz-form-label [nzSpan]="24" nzFor="weight" class="ppl-form-label">
                  {{getAttributes('weight').label}}
                  @if(getAttributes('weight').mandatory === 'Y'){
                  <span>*</span>
                  }
                </nz-form-label>
                <nz-form-control [nzSpan]="24" class="ppl-form-control">
                  <div nz-tooltip [nzTooltipTitle]="getAttributes('weight')?.tooltip || ''"
                    nzTooltipPlacement="topRight" class="weight-input-container ppl-form-control">
                    <input nz-input formControlName="weight" id="weight"
                      placeholder="{{getAttributes('weight').placeholder}}"
                      [ngClass]="{'error': validationErrors['weight']}" [readonly]="formReadonly" />
                    <!-- <span class="unit-label">Pounds</span> -->
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
            }
            @if(isAttrAvailable('eyeColor')){
            <div nz-col [nzSm]="6" [nzXs]="24">
              <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
                <nz-form-label [nzSpan]="24" nzFor="eyeColor" class="ppl-form-label">{{getAttributes('eyeColor').label}}
                  @if(getAttributes('eyeColor').mandatory === 'Y'){
                  <span>*</span>
                  }
                </nz-form-label>
                <nz-form-control [nzSpan]="24" class="ppl-form-control" nz-tooltip
                  nzTooltipTitle="{{getAttributes('eyeColor').tooltip}}" nzTooltipPlacement="topRight">
                  <nz-select formControlName="eyeColor" class="ppl-select" nzId="eyeColor" nzShowArrow
                    [ngClass]="{'error': validationErrors['eyeColor'] }" [nzDisabled]="formReadonly">
                    @for(color of eyeColors; track $index){
                      <nz-option [nzLabel]="color.lkpValueName" [nzValue]="color.lkpValueCode"></nz-option>
                      }
                  </nz-select>
                  @if (validationErrors['eyeColor']) {
                  <span class="pl-1 error-message">{{validationErrors['eyeColor']}}</span>
                  }
                </nz-form-control>
              </nz-form-item>
            </div>
            }
            @if(isAttrAvailable('hairColor')){
            <div nz-col [nzSm]="6" [nzXs]="24">
              <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
                <nz-form-label [nzSpan]="24" nzFor="hairColor"
                  class="ppl-form-label">{{getAttributes('hairColor').label}}
                  @if(getAttributes('hairColor').mandatory === 'Y'){
                  <span>*</span>
                  }
                </nz-form-label>
                <nz-form-control [nzSpan]="24" class="ppl-form-control" nz-tooltip
                  nzTooltipTitle="{{getAttributes('hairColor').tooltip}}" nzTooltipPlacement="topRight">
                  <nz-select formControlName="hairColor" class="ppl-select" nzId="hairColor" nzShowArrow
                    [ngClass]="{'error': validationErrors['hairColor'] }" [nzDisabled]="formReadonly">
                    @for(color of hairColors; track $index){
                      <nz-option [nzLabel]="color.lkpValueName" [nzValue]="color.lkpValueCode"></nz-option>
                      }
                  </nz-select>
                  @if (validationErrors['hairColor']) {
                  <span class="pl-1 error-message">{{validationErrors['hairColor']}}</span>
                  }
                </nz-form-control>
              </nz-form-item>
            </div>
            }
            @if(isAttrAvailable('birthPlace')){
            <div nz-col [nzSm]="6" [nzXs]="24">
              <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
                <nz-form-label [nzSpan]="24" nzFor="birthPlace"
                  class="ppl-form-label">{{getAttributes('birthPlace').label}}
                  @if(getAttributes('birthPlace').mandatory === 'Y'){
                  <span>*</span>
                  }
                </nz-form-label>
                <nz-form-control [nzSpan]="24" class="ppl-form-control">
                  <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
                    <input nz-input formControlName="birthCity" id="birthCity" placeholder="City" [readonly]="formReadonly" />
                  </div>
                </nz-form-control>
                <nz-form-control [nzSpan]="24" class="ppl-form-control" style="margin: 0px 4px;">
                  <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
                    <input nz-input formControlName="birthState" id="birthState" placeholder="State" [readonly]="formReadonly" />
                  </div>
                </nz-form-control>
                <nz-form-control [nzSpan]="24" class="ppl-form-control">
                  <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
                    <input nz-input formControlName="birthCountry" id="birthCountry" placeholder="Country" [readonly]="formReadonly" />
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
            }
          </nz-row>
          <!-- <nz-divider class="divider"></nz-divider> -->
        </div>
        }
      </nz-row>
    </form>
  </nz-card>
</div>}
@else if(mode==="read"){
<div nz-row>
  @if(isAttrAvailable('businessName')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{ getAttributes('businessName').label }} :</div>
    <div nz-col nzSpan="12">{{ filledInfo['businessName']}}</div>
  </div>
  }
  @if(isAttrAvailable('fname')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{ getAttributes('fname').label }} :</div>
    <div nz-col nzSpan="12">{{ filledInfo['fname']}}</div>
  </div>
  }
  @if(isAttrAvailable('mname')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('mname').label}} :</div>
    <div nz-col nzSpan="12">{{ filledInfo['mname']}}</div>
  </div>
  }
  @if(isAttrAvailable('lname')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('lname').label}} :</div>
    <div nz-col nzSpan="12">{{ filledInfo['lname']}}</div>
  </div>
  }
  @if(isAttrAvailable('maiden_name')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('maiden_name').label}} :</div>
    <div nz-col nzSpan="12">{{ filledInfo['maiden_name']}}</div>
  </div>
  }
  @if(isAttrAvailable('dob')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('dob').label}} :</div>
    <div nz-col nzSpan="12">{{ filledInfo['dob'] }}</div>
  </div>
  }
  @if(isAttrAvailable('gender')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('gender').label}} :</div>
    <div nz-col nzSpan="12">{{(getLookupValue(gender, filledInfo['gender']))}}</div>
  </div>
  }
  @if(isAttrAvailable('state')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{ getAttributes('state').label }} :</div>
    <div nz-col nzSpan="12">{{ (getLookupValue(states, this.state))}}</div>
  </div>
  }
  @if(isAttrAvailable('isProvidingServices')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('isProvidingServices').label}} :</div>
    <div nz-col nzSpan="12">{{ filledInfo['isProvidingServices'] }}</div>
  </div>
  }
  @if(isAttrAvailable('SSN')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('SSN').label}} :</div>
    <div nz-col nzSpan="12">{{ filledInfo['SSN']}}</div>
  </div>
  }
  @if(isAttrAvailable('CIN')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('CIN').label}} :</div>
    <div nz-col nzSpan="12">{{ filledInfo['CIN']}}</div>
  </div>
  }
  @if(isAttrAvailable('EIN')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('EIN').label}} :</div>
    <div nz-col nzSpan="12">{{ filledInfo['EIN']}}</div>
  </div>
  }
  @if(isAttrAvailable('waiver')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('waiver').label}} :</div>
    <div nz-col nzSpan="12">{{ getLookupValue(waiver, filledInfo['waiver']) }}</div>
  </div>
  }
  @if(isAttrAvailable('mcoOrgName')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('mcoOrgName').label}} :</div>
    <div nz-col nzSpan="12">{{ filledInfo['mcoOrgName'] }}</div>
  </div>
  }
  @if(isAttrAvailable('fiOrgName')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('fiOrgName').label}} :</div>
    <div nz-col nzSpan="12">{{ filledInfo['fiOrgName'] }}</div>
  </div>
  }
  @if(isAttrAvailable('relationToParticipant')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('relationToParticipant').label}} :</div>    
    <div nz-col nzSpan="12">{{ filledInfo.relationToParticipant ? getLookupValue(relationshipOptions, filledInfo['relationToParticipant']) : filledInfo.relationToParticipant}}</div>
  </div>
  }
  @if(isAttrAvailable('showOnDirectory')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('showOnDirectory').label}} :</div>
    <div nz-col nzSpan="12">{{ filledInfo['showOnDirectory']}}</div>
  </div>
  }
  @if(isAttrAvailable('NPI')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('NPI').label}} :</div>
    <div nz-col nzSpan="12">{{ filledInfo['NPI']}}</div>
  </div>
  }
  @if(isAttrAvailable('additionalDemographics')){
  <div nz-col nzSpan="12">
    <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('additionalDemographics').label}} :</div>
    @if(isAttrAvailable('race')){
    <div nz-col nzSpan="12">
      <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('race').label}} :</div>      
      <div nz-col nzSpan="12">{{ filledInfo.race ? getLookupValue(raceOptions, filledInfo['race']) : filledInfo.race}}</div>
    </div>
    }
    @if(isAttrAvailable('height')){
    <div nz-col nzSpan="12">
      <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('height').label}} :</div>
      <div nz-col nzSpan="12">{{ filledInfo['heightFeet']}} Feet {{ filledInfo['heightInches'] || 0}} Inches</div>
    </div>
    }
    @if(isAttrAvailable('weight')){
    <div nz-col nzSpan="12">
      <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('weight').label}} :</div>
      <div nz-col nzSpan="12">{{ filledInfo['weight'] }}</div>
    </div>
    }
    @if(isAttrAvailable('eyeColor')){
    <div nz-col nzSpan="12">
      <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('eyeColor').label}} :</div>      
      <div nz-col nzSpan="12">{{ filledInfo.eyeColor ? getLookupValue(eyeColors, filledInfo['eyeColor']) : filledInfo.eyeColor}}</div>
    </div>
    }
    @if(isAttrAvailable('hairColor')){
    <div nz-col nzSpan="12">
      <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('hairColor').label}} :</div>      
      <div nz-col nzSpan="12">{{ filledInfo.hairColor ? getLookupValue(hairColors, filledInfo['hairColor']) : filledInfo.hairColor}}</div>
    </div>
    }
    @if(isAttrAvailable('birthPlace')){
    <div nz-col nzSpan="12">
      <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('birthPlace').label}} :</div>
      <div nz-col nzSpan="12">{{ filledInfo['birthCity']}} {{ filledInfo['birthState']}} {{ filledInfo['birthCountry']}}
      </div>
    </div>
    }
    </div>
  }
  </div>
  }