<div class="custom-row">
  @if(mode==="edit"){
    <ppl-button 
    label="Cancel" 
    icon="fa fa-ban" 
    [customClass]="'alt-button'" 
    [iconPosition]="'left'"
    (click)="cancelEdit()"
    ></ppl-button>    

    <ppl-button 
    label="Save" 
    icon="fa fa-floppy-o" 
    [customClass]="'alt-button'" 
    [iconPosition]="'left'"
    (click)="save()"
    ></ppl-button>

  <!-- <span
    (click)="cancelEdit()"
    nz-icon
    nzType="close-circle"
    nzTheme="outline"
  ></span>
  <span nz-icon nzType="check-circle" nzTheme="outline" (click)="save()"></span> -->
  }
  @if(mode==="read" && leadStatus !== approvedStatus){
    <ppl-button 
    label="Edit" 
    icon="fa fa-pencil" 
    [customClass]="'ghost-button'" 
    [iconPosition]="'left'"
    (click)="editInfo()"
    *authorize="[pageId, tabId, 'CommunicationInformation', 'section', 'edit']"
    ></ppl-button>  

  <!-- <span
    (click)="editInfo()"
    nz-icon
    nzType="edit"
    nzTheme="outline"
    *authorize="[pageId, tabId, 'CommunicationInformation', 'section', 'edit']"
  ></span> -->
  }
</div>
@if(mode==="edit"){
  <div class="ppl-mt20">
      <form nz-form  nz-row  [formGroup]="preferncesForm" class="ppl-p0">
        @if(isAttrAvailable('contactType')){
        <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label  [nzSm]="8"
          [nzXs]="24" nzFor="contactType"
            class="ppl-form-label pl-5">{{getAttributes('contactType').label}}
            @if(getAttributes('contactType').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control class="ppl-form-control">
            <nz-select formControlName="contactType" class="ppl-select" nzId="contactType" nzShowSearch nzAllowClear  [ngClass]="{'error': validationErrors['contactType'] }">
              @for(opt of contactType; track $index){
              <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
              }
            </nz-select>
            @if (validationErrors['contactType']) {
              <span class="pl-1 error-message">{{validationErrors['contactType']}}</span>
            }
          </nz-form-control>
        </nz-row>
          
        </nz-form-item>
        }
        @if(preferncesForm.get('contactType')?.value === 'OTH'){
        @if(isAttrAvailable('contactFirstName')){
        <nz-form-item  nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
          <nz-row>
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="contactFirstName" class="ppl-form-label pl-5">
              {{getAttributes('contactFirstName').label}}
              @if(getAttributes('contactFirstName').mandatory === 'Y'){
                <span>*</span>
                }</nz-form-label>
            <nz-form-control class="ppl-form-control">
              <input nz-tooltip nzTooltipTitle="{{getAttributes('contactFirstName').tooltip}}" nzTooltipPlacement="topRight"
                nz-input formControlName="contactFirstName" id="contactFirstName"
                placeholder="{{getAttributes('contactFirstName').placeholder}}" [ngClass]="{'error': validationErrors['contactFirstName'] }"/>
                @if (validationErrors['contactFirstName']) {
                  <span class="pl-1 error-message">{{validationErrors['contactFirstName']}}</span>
                }
            </nz-form-control>
          </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('contactLastName')){
        <nz-form-item  nz-col nzSpan="12" nzPaddingLeft="20"  class="ppl-form-item">
          <nz-row>
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="contactLastName" class="ppl-form-label pl-5">
              {{getAttributes('contactLastName').label}}
              @if(getAttributes('contactLastName').mandatory === 'Y'){
                <span>*</span>
                }</nz-form-label>
          <nz-form-control class="ppl-form-control">
            <input nz-tooltip nzTooltipTitle="{{getAttributes('contactLastName').tooltip}}" nzTooltipPlacement="topRight"
              nz-input formControlName="contactLastName" id="contactLastName"
              placeholder="{{getAttributes('contactLastName').placeholder}}" [ngClass]="{'error': validationErrors['contactLastName'] }" />
              @if (validationErrors['contactLastName']) {
                <span class="pl-1 error-message">{{validationErrors['contactLastName']}}</span>
              }
          </nz-form-control>
          </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('contactEmail')){
        <nz-form-item  nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item">
          <nz-row>
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="contactEmail" class="ppl-form-label pl-5">
              {{getAttributes('contactEmail').label}}
              @if(getAttributes('contactEmail').mandatory === 'Y'){
                <span>*</span>
                }</nz-form-label>
          <nz-form-control class="ppl-form-control">
            <input nz-tooltip nzTooltipTitle="{{getAttributes('contactEmail').tooltip}}" nzTooltipPlacement="topRight"
              nz-input formControlName="contactEmail" id="contactEmail"
              placeholder="{{getAttributes('contactEmail').placeholder}}" [ngClass]="{'error': validationErrors['contactEmail'] }" />
              @if (validationErrors['contactEmail']) {
                <span class="pl-1 error-message">{{validationErrors['contactEmail']}}</span>
              }
          </nz-form-control>
          </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('contactPhone')){
        <nz-form-item  nz-col nzSpan="12" nzPaddingLeft="20"  class="ppl-form-item">
          <nz-row>
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="contactPhone" class="ppl-form-label pl-5">
              {{getAttributes('contactPhone').label}}
              @if(getAttributes('contactPhone').mandatory === 'Y'){
                <span>*</span>
                }</nz-form-label>
          <nz-form-control class="ppl-form-control">
            <input nz-tooltip nzTooltipTitle="{{getAttributes('contactPhone').tooltip}}" nzTooltipPlacement="topRight"
              nz-input formControlName="contactPhone" id="contactPhone"
              placeholder="{{getAttributes('contactPhone').placeholder}}" [ngClass]="{'error': validationErrors['contactPhone'] }" />
              @if (validationErrors['contactPhone']) {
                <span class="pl-1 error-message">{{validationErrors['contactPhone']}}</span>
              }
          </nz-form-control>
          </nz-row>
        </nz-form-item>
        }
  
        }

        @if(isAttrAvailable('availabilityDays')){
          <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item">
            <nz-row>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="availabilityDays" class="ppl-form-label pl-5">{{
                getAttributes('availabilityDays').label }}
                @if(getAttributes('availabilityDays').mandatory === 'Y'){
                <span>*</span>
                }
              </nz-form-label>
              <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('availabilityDays').tooltip}}"
                nzTooltipPlacement="topRight">
                <div [formGroup]="preferncesForm">
                  <div formArrayName="availabilityDays">
                    <ng-container>
                      @for(day of daysOfWeek; track $index){
                      <label nz-checkbox [formControlName]="$index">
                        {{ day }}
                      </label>
                      }
                    </ng-container>
                  </div>
                  <span class="error-message">
                    {{ validationErrors['availabilityDays'] }}
                  </span>
                </div>
              </nz-form-control>
            </nz-row>
          </nz-form-item>
          <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item">
            <nz-row>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="availabilityTimingStart" class="ppl-form-label pl-5">{{
                getAttributes('availabilityTimingStart').label }}
                @if(getAttributes('availabilityTimingStart').mandatory === 'Y'){
                <span>*</span>
                }
              </nz-form-label>
              <nz-col>
                <div class="time-picker" [ngClass]="{'error': validationErrors['availabilityTimingStart'] }" [ngClass]="{'error': validationErrors['availabilityTimingEnd'] }">
                  <nz-time-picker [nzSize]="'small'" [nzUse12Hours]="true" [nzFormat]="'h:mm a'" [nzMinuteStep]="15" formControlName="availabilityTimingStart"
                    id="availabilityTimingStart" [nzPlaceHolder]="getAttributes('availabilityTimingStart').placeholder"
                    nzBorderless></nz-time-picker>
                  <nz-time-picker [nzSize]="'small'" [nzUse12Hours]="true" [nzFormat]="'h:mm a'" [nzMinuteStep]="15" formControlName="availabilityTimingEnd" id="availabilityTimingEnd"
                    [nzPlaceHolder]="getAttributes('availabilityTimingEnd').placeholder" nzBorderless></nz-time-picker>
                </div>
                @if (validationErrors['availabilityTimingStart'] || validationErrors['availabilityTimingEnd']) {
                  <span class="pl-1 error-message">{{validationErrors['availabilityTimingStart'] ? validationErrors['availabilityTimingStart'] : validationErrors['availabilityTimingEnd']}}</span>
                }
              </nz-col>
            
            
            </nz-row>
          </nz-form-item>
          }

          @if(isAttrAvailable('distanceWillingToTravel')){
            <nz-form-item  nz-col nzSpan="12" nzPaddingLeft="20"  class="ppl-form-item">
              <nz-row>
                <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="distanceWillingToTravel" class="ppl-form-label pl-5">{{getAttributes('distanceWillingToTravel').label}}</nz-form-label>
              <nz-form-control class="ppl-form-control">
                <input nz-tooltip nzTooltipTitle="{{getAttributes('distanceWillingToTravel').tooltip}}" nzTooltipPlacement="topRight"
                  nz-input formControlName="distanceWillingToTravel" id="distanceWillingToTravel"
                  placeholder="{{getAttributes('distanceWillingToTravel').placeholder}}" [ngClass]="{'error': validationErrors['distanceWillingToTravel'] }" />
                  <!-- @if (validationErrors['distanceWillingToTravel']) { -->
                    <span class="pl-1 error-message">{{validationErrors['distanceWillingToTravel']}}</span>
                  <!-- } -->
              </nz-form-control>
              </nz-row>
            </nz-form-item>
            }
            
            @if(isAttrAvailable('emergencyContactFirstName')){
              <nz-form-item  nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
                <nz-row>
                  <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="emergencyContactFirstName" class="ppl-form-label pl-5">
                    {{getAttributes('emergencyContactFirstName').label}}
                    @if(getAttributes('emergencyContactFirstName').mandatory === 'Y'){
                      <span>*</span>
                      }</nz-form-label>
                  <nz-form-control class="ppl-form-control">
                    <input nz-tooltip nzTooltipTitle="{{getAttributes('emergencyContactFirstName').tooltip}}" nzTooltipPlacement="topRight"
                      nz-input formControlName="emergencyContactFirstName" id="emergencyContactFirstName"
                      placeholder="{{getAttributes('emergencyContactFirstName').placeholder}}" [ngClass]="{'error': validationErrors['emergencyContactFirstName'] }"/>
                      @if (validationErrors['emergencyContactFirstName']) {
                        <span class="pl-1 error-message">{{validationErrors['emergencyContactFirstName']}}</span>
                      }
                  </nz-form-control>
                </nz-row>
              </nz-form-item>
              }
              @if(isAttrAvailable('emergencyContactLastName')){
              <nz-form-item  nz-col nzSpan="12" nzPaddingLeft="20"  class="ppl-form-item">
                <nz-row>
                  <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="emergencyContactLastName" class="ppl-form-label pl-5">
                    {{getAttributes('emergencyContactLastName').label}}
                    @if(getAttributes('emergencyContactLastName').mandatory === 'Y'){
                      <span>*</span>
                      }</nz-form-label>
                <nz-form-control class="ppl-form-control">
                  <input nz-tooltip nzTooltipTitle="{{getAttributes('emergencyContactLastName').tooltip}}" nzTooltipPlacement="topRight"
                    nz-input formControlName="emergencyContactLastName" id="emergencyContactLastName"
                    placeholder="{{getAttributes('emergencyContactLastName').placeholder}}" [ngClass]="{'error': validationErrors['emergencyContactLastName'] }" />
                    @if (validationErrors['emergencyContactLastName']) {
                      <span class="pl-1 error-message">{{validationErrors['emergencyContactLastName']}}</span>
                    }
                </nz-form-control>
                </nz-row>
              </nz-form-item>
              }
              <!-- @if(isAttrAvailable('contactEmail')){
              <nz-form-item  nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item">
                <nz-row>
                  <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="contactEmail" class="ppl-form-label pl-5">
                    {{getAttributes('contactEmail').label}}
                    @if(getAttributes('contactEmail').mandatory === 'Y'){
                      <span>*</span>
                      }</nz-form-label>
                <nz-form-control class="ppl-form-control">
                  <input nz-tooltip nzTooltipTitle="{{getAttributes('contactEmail').tooltip}}" nzTooltipPlacement="topRight"
                    nz-input formControlName="contactEmail" id="contactEmail"
                    placeholder="{{getAttributes('contactEmail').placeholder}}" [ngClass]="{'error': validationErrors['contactEmail'] }" />
                    @if (validationErrors['contactEmail']) {
                      <span class="pl-1 error-message">{{validationErrors['contactEmail']}}</span>
                    }
                </nz-form-control>
                </nz-row>
              </nz-form-item>
              } -->
              @if(isAttrAvailable('emergencyContactPhone')){
              <nz-form-item  nz-col nzSpan="12" nzPaddingLeft="20"  class="ppl-form-item">
                <nz-row>
                  <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="emergencyContactPhone" class="ppl-form-label pl-5">
                    {{getAttributes('emergencyContactPhone').label}}
                    @if(getAttributes('emergencyContactPhone').mandatory === 'Y'){
                      <span>*</span>
                      }</nz-form-label>
                <nz-form-control class="ppl-form-control">
                  <input nz-tooltip nzTooltipTitle="{{getAttributes('emergencyContactPhone').tooltip}}" nzTooltipPlacement="topRight"
                    nz-input formControlName="emergencyContactPhone" id="emergencyContactPhone"
                    placeholder="{{getAttributes('emergencyContactPhone').placeholder}}" [ngClass]="{'error': validationErrors['emergencyContactPhone'] }" />
                    @if (validationErrors['emergencyContactPhone']) {
                      <span class="pl-1 error-message">{{validationErrors['emergencyContactPhone']}}</span>
                    }
                </nz-form-control>
                </nz-row>
              </nz-form-item>
            }

            @if(isAttrAvailable('isAvailableForEmergencies')){
              <nz-form-item  nz-col nzSpan="12" nzPaddingLeft="20"  class="ppl-form-item">
                <nz-row>
                  <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="isAvailableForEmergencies"
                  class="ppl-form-label pl-5">{{getAttributes('isAvailableForEmergencies').label}}
                  @if(getAttributes('isAvailableForEmergencies').mandatory === 'Y'){
                  <span>*</span>
                  }</nz-form-label>
                <nz-form-control class="ppl-form-control">
                  <div nz-tooltip [nzTooltipTitle]="getAttributes('isAvailableForEmergencies')?.tooltip || ''"
                    nzTooltipPlacement="topRight">
                    <nz-radio-group [ngClass]="{'error': validationErrors['isAvailableForEmergencies'] }"
                      formControlName="isAvailableForEmergencies" class="ppl-radio-group" nzId="isAvailableForEmergencies">
                      @for(opt of radioOptions; track $index){
                        <label nz-radio-button [nzValue]="opt.value">{{opt.label}}</label>
                      }
                    </nz-radio-group>
                  </div>
                  @if (validationErrors['isAvailableForEmergencies']) {
                  <span class="pl-1 error-message">{{validationErrors['isAvailableForEmergencies']}}</span>
                  }
                </nz-form-control>
                </nz-row>
              </nz-form-item>
              }
                
                
              @if(preferncesForm.get('isAvailableForEmergencies')?.value === 'Yes'){
                @if(isAttrAvailable('dayEmergencyAvailablity')){
                <nz-form-item  nz-col nzSpan="12" nzPaddingLeft="20"  class="ppl-form-item">
                  <nz-row>
                    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="dayEmergencyAvailablity"
                    class="ppl-form-label pl-5">{{getAttributes('dayEmergencyAvailablity').label}}
                    @if(getAttributes('dayEmergencyAvailablity').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-col class="ppl-form-control">
                    <div class="time-picker" [ngClass]="{'error': validationErrors['dayEmergencyAvailablity'] }">
                      <nz-time-picker [nzSize]="'small'" [nzFormat]="'h:mm a'" [nzMinuteStep]="15"
                        formControlName="dayEmergencyAvailablityStart" id="dayEmergencyAvailablityStart"
                        [nzPlaceHolder]="getAttributes('dayEmergencyAvailablityStart').placeholder" [nzDisabledHours]="disabledNightHours" nzBorderless></nz-time-picker>
                      <nz-time-picker [nzSize]="'small'" [nzFormat]="'h:mm a'"
                        [nzMinuteStep]="15" formControlName="dayEmergencyAvailablityEnd" id="dayEmergencyAvailablityEnd"
                        [nzPlaceHolder]="getAttributes('dayEmergencyAvailablityEnd').placeholder" [nzDisabledHours]="disabledNightHours" nzBorderless></nz-time-picker>
                    </div>
                    <span class="pl-1 error-message">{{validationErrors['dayEmergencyAvailablityStart'] ? validationErrors['dayEmergencyAvailablityStart'] : validationErrors['dayEmergencyAvailablityEnd']}}</span>
                  </nz-col>
                  
                    
                  </nz-row>
                  
                </nz-form-item>
                }
                @if(isAttrAvailable('nightEmergencyAvailablity')){
                  <nz-form-item  nz-col nzSpan="12" nzPaddingLeft="20"  class="ppl-form-item">
                    <nz-row>
                  <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="nightEmergencyAvailablity"
                    class="ppl-form-label pl-5 ">{{getAttributes('nightEmergencyAvailablity').label}}
                    @if(getAttributes('nightEmergencyAvailablity').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-col class="ppl-form-control">
                    <div class="time-picker ppl-form-control" [ngClass]="{'error': validationErrors['nightEmergencyAvailablityStart'] }">
                      <nz-time-picker [nzSize]="'small'" [nzFormat]="'h:mm a'" [nzMinuteStep]="15"
                        formControlName="nightEmergencyAvailablityStart" id="nightEmergencyAvailablityStart"
                        [nzPlaceHolder]="getAttributes('nightEmergencyAvailablity').placeholder" [nzDisabledHours]="disabledDayHours" nzBorderless></nz-time-picker>
                      <nz-time-picker [nzSize]="'small'" [nzFormat]="'h:mm a'"
                        [nzMinuteStep]="15" formControlName="nightEmergencyAvailablityEnd" id="nightEmergencyAvailablityEnd"
                        [nzPlaceHolder]="getAttributes('nightEmergencyAvailablityEnd').placeholder" [nzDisabledHours]="disabledDayHours" nzBorderless></nz-time-picker>
                        
                    </div>
                    
                    <span class="pl-1 error-message">{{validationErrors['nightEmergencyAvailablityStart'] || validationErrors['nightEmergencyAvailablityEnd']}}</span>
                  </nz-col>
                  
                
                  </nz-row>
                </nz-form-item>
                }
                }


        @if(isAttrAvailable('spokenLanguages')){
        <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item">
          <nz-row>
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="spokenLanguages"
            class="ppl-form-label pl-5">{{getAttributes('spokenLanguages').label}}
            @if(getAttributes('spokenLanguages').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('spokenLanguages').tooltip}}"
            nzTooltipPlacement="topRight">
            <nz-select formControlName="spokenLanguages"  nzMode="multiple" class="ppl-select" nzId="spokenLanguages" nzShowSearch nzAllowClear
              nzPlaceHolder="{{getAttributes('spokenLanguages').label}}" [ngClass]="{'error': validationErrors['spokenLanguages'] }">
              @for(opt of languages; track $index){
              <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
              }
            </nz-select>
            @if (validationErrors['spokenLanguages']) {
              <span class="pl-1 error-message">{{validationErrors['spokenLanguages']}}</span>
            }
          </nz-form-control>
          </nz-row>
          
        </nz-form-item>
        }

        @if(isAttrAvailable('primaryLanguage')){
          <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item">
            <nz-row>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="primaryLanguage"
              class="ppl-form-label pl-5">{{getAttributes('primaryLanguage').label}}
              @if(getAttributes('primaryLanguage').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('primaryLanguage').tooltip}}"
              nzTooltipPlacement="topRight">
              <nz-select formControlName="primaryLanguage"  nzMode="default" class="ppl-select" nzId="primaryLanguage" nzShowSearch nzAllowClear
                nzPlaceHolder="{{getAttributes('primaryLanguage').label}}" [ngClass]="{'error': validationErrors['primaryLanguage'] }">
                @for(opt of languages; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
              @if (validationErrors['primaryLanguage']) {
                <span class="pl-1 error-message">{{validationErrors['primaryLanguage']}}</span>
              }
            </nz-form-control>
            </nz-row>
            
          </nz-form-item>
          }

          @if(isAttrAvailable('formLanguage')){
            <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item">
              <nz-row>
                <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="formLanguage"
                class="ppl-form-label pl-5">{{getAttributes('formLanguage').label}}
                @if(getAttributes('formLanguage').mandatory === 'Y'){
                <span>*</span>
                }
              </nz-form-label>
              <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('formLanguage').tooltip}}"
                nzTooltipPlacement="topRight">
                <nz-select formControlName="formLanguage"  nzMode="default" class="ppl-select" nzId="formLanguage" nzShowArrow
                  nzPlaceHolder="{{getAttributes('formLanguage').label}}" [ngClass]="{'error': validationErrors['formLanguage'] }">
                  @for(opt of formLanguages; track $index){
                    <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                  }
                </nz-select>
                @if (validationErrors['formLanguage']) {
                  <span class="pl-1 error-message">{{validationErrors['formLanguage']}}</span>
                }
              </nz-form-control>
              </nz-row>
              
            </nz-form-item>
            }
        


        @if(isAttrAvailable('daysToContact')){
        <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item">
          <nz-row>
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="daysToContact" class="ppl-form-label pl-5">{{
              getAttributes('daysToContact').label }}
              @if(getAttributes('daysToContact').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('daysToContact').tooltip}}"
              nzTooltipPlacement="topRight">
              <div [formGroup]="preferncesForm">
                <div formArrayName="daysToContact">
                  <ng-container>
                    @for(day of daysOfWeek; track $index){
                    <label nz-checkbox [formControlName]="$index">
                      {{ day }}
                    </label>
                    }
                  </ng-container>
                </div>
                <span class="error-message">
                  {{ validationErrors['daysToContact'] }}
                </span>
              </div>
            </nz-form-control>
          </nz-row>
        </nz-form-item>
        <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item">
          <nz-row>
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="startContactTime" class="ppl-form-label pl-5">{{
              getAttributes('startContactTime').label }}
              @if(getAttributes('startContactTime').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-col>
              <div class="time-picker" [ngClass]="{'error': validationErrors['startContactTime'] }" [ngClass]="{'error': validationErrors['endContactTime'] }">
                <nz-time-picker [nzSize]="'small'" [nzUse12Hours]="true" [nzFormat]="'h:mm a'" [nzMinuteStep]="15" formControlName="startContactTime"
                  id="startContactTime" [nzPlaceHolder]="getAttributes('startContactTime').placeholder"
                  nzBorderless></nz-time-picker>
                <nz-time-picker [nzSize]="'small'" [nzUse12Hours]="true" [nzFormat]="'h:mm a'" [nzMinuteStep]="15" formControlName="endContactTime" id="endContactTime"
                  [nzPlaceHolder]="getAttributes('endContactTime').placeholder" nzBorderless></nz-time-picker>
              </div>
              @if (validationErrors['startContactTime'] || validationErrors['endContactTime']) {
                <span class="pl-1 error-message">{{validationErrors['startContactTime'] ? validationErrors['startContactTime'] : validationErrors['endContactTime']}}</span>
              }
            </nz-col>
          
          
          </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('otherDetails')){
        <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20"  class="ppl-form-item">
          <nz-row>
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="otherDetails"
            class="ppl-form-label pl-5">{{getAttributes('otherDetails').label}}
            @if(getAttributes('otherDetails').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('otherDetails').tooltip}}"
            nzTooltipPlacement="topRight">
            <textarea rows="4" cols="40" nz-input formControlName="otherDetails" id="otherDetails" class="ppl-form-control"
              placeholder="{{getAttributes('otherDetails').placeholder}}"  [ngClass]="{'error': validationErrors['otherDetails'] }"></textarea>
              @if (validationErrors['otherDetails']) {
                <span class="pl-1 error-message">{{validationErrors['otherDetails']}}</span>
              }
          </nz-form-control>
          
          </nz-row>
          
        </nz-form-item>
        }
        @if(isAttrAvailable('specialAccomdation')){
        <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item">
          <nz-row>
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="specialAccomdation"
            class="ppl-form-label pl-5">{{getAttributes('specialAccomdation').label}}
            @if(getAttributes('specialAccomdation').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control class="ppl-form-control" nz-tooltip
            nzTooltipTitle="{{getAttributes('specialAccomdation').tooltip}}" nzTooltipPlacement="topRight">
            <nz-select formControlName="specialAccomdation" class="ppl-select" nzId="specialAccomdation" nzShowSearch nzAllowClear  [ngClass]="{'error': validationErrors['specialAccomdation'] }">
              @for(opt of accommodations; track $index){
              <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
              }
            </nz-select>
            @if (validationErrors['specialAccomdation']) {
              <span class="pl-1 error-message">{{validationErrors['specialAccomdation']}}</span>
            }
          </nz-form-control>
          </nz-row>
        </nz-form-item>
        }
        @if(preferncesForm.get('specialAccomdation')?.value === 'OTH'){
        @if(isAttrAvailable('specialAccomdationOther')){
        <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item">
          <nz-row>
            <nz-form-label [nzSm]="8" [nzXs]="24" zFor="specialAccomdationOther" class="ppl-form-label pl-5"></nz-form-label>
          <nz-form-control class="ppl-form-control" nz-tooltip
            nzTooltipTitle="{{getAttributes('specialAccomdationOther').tooltip}}" nzTooltipPlacement="topRight">
            <textarea nz-tooltip rows="4" nz-input formControlName="specialAccomdationOther" id="specialAccomdationOther"
              placeholder="{{getAttributes('specialAccomdationOther').placeholder}}"  [ngClass]="{'error': validationErrors['specialAccomdationOther'] }"></textarea>
              @if (validationErrors['specialAccomdationOther']) {
                <span class="pl-1 error-message">{{validationErrors['specialAccomdationOther']}}</span>
              }
          </nz-form-control>
          </nz-row>
        </nz-form-item>
        }
        }
        @if(isAttrAvailable('registerPref')){
          <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item">
            <nz-row>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="registerPref"
              class="ppl-form-label pl-5">{{getAttributes('registerPref').label}}
              @if(getAttributes('registerPref').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control class="ppl-form-control" nz-tooltip
              nzTooltipTitle="{{getAttributes('registerPref').tooltip}}" nzTooltipPlacement="topRight">
              <nz-select formControlName="registerPref" class="ppl-select" nzId="registerPref" nzShowSearch nzAllowClear  [ngClass]="{'error': validationErrors['registerPref'] }">
                @for (opt of participantRegisterMode; track $index) {
                  <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
              @if (validationErrors['registerPref']) {
                <span class="pl-1 error-message">{{validationErrors['registerPref']}}</span>
              }
            </nz-form-control>
            </nz-row>
          </nz-form-item>
          }

          
      </form>
  </div>
  }
  @else if(mode==="read" && filledInfo && secData){
    <div nz-row>

    
  @if(isAttrAvailable('contactType')){

    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('contactType').label}}</div>
    <div nz-col nzSpan="6">  {{ filledInfo?.contactType ? getValue(filledInfo?.contactType, 'contact') : filledInfo?.contactType}}</div>
  
  }
  @if(filledInfo.contactType === 'OTH'){
  @if(isAttrAvailable('contactFirstName')){
  
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('contactFirstName').label}}</div>
    <div nz-col nzSpan="6">{{ filledInfo?.contactFirstName}}</div>
  
  }
  @if(isAttrAvailable('contactLastName')){
 
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('contactLastName').label}}</div>
    <div nz-col nzSpan="6">{{ filledInfo?.contactLastName}}</div>
  
  }
  @if(isAttrAvailable('contactEmail')){
  
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('contactEmail').label}}</div>
    <div nz-col nzSpan="6">{{ filledInfo?.contactEmail}}</div>
  
  }
  @if(isAttrAvailable('contactPhone')){
  
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('contactPhone').label}}</div>
    <div nz-col nzSpan="6">{{ filledInfo?.contactPhone}}</div>
  
  }
  }

  @if(isAttrAvailable('availabilityDays')){
  
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('bestTimeToContact').label}}</div>
    <div nz-col nzSpan="6">{{ filledInfo?.bestTimeToContact }}</div>
  
  }

  @if(isAttrAvailable('distanceWillingToTravel')){
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('distanceWillingToTravel').label}}</div>
    <div nz-col nzSpan="6">{{ filledInfo?.distanceWillingToTravel }}</div>
  }

  @if(isAttrAvailable('emergencyContactFirstName')){
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('emergencyContactFirstName').label}}</div>
    <div nz-col nzSpan="6">{{ filledInfo?.emergencyContactFirstName}}</div>
  }

  @if(isAttrAvailable('emergencyContactLastName')){
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('emergencyContactLastName').label}}</div>
    <div nz-col nzSpan="6">{{ filledInfo?.emergencyContactLastName}}</div>
  }

  @if(isAttrAvailable('emergencyContactPhone')){
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('emergencyContactPhone').label}}</div>
    <div nz-col nzSpan="6">{{ filledInfo?.emergencyContactPhone}}</div>
  }

  @if(isAttrAvailable('isAvailableForEmergencies')){
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('isAvailableForEmergencies').label}}</div>
    <div nz-col nzSpan="6">{{ filledInfo?.isAvailableForEmergencies === true ? 'Yes' : 'No' }}</div>
  }

    @if(filledInfo?.isAvailableForEmergencies){

      @if(isAttrAvailable('dayEmergencyAvailablity')){
        <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('dayEmergencyAvailablity').label}}</div>
        <div nz-col nzSpan="6">{{ filledInfo?.emergencyAvailabilityDay}}</div>
      }

      @if(isAttrAvailable('nightEmergencyAvailablity')){
        <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('nightEmergencyAvailablity').label}}</div>
        <div nz-col nzSpan="6">{{ filledInfo?.emergencyAvailabilityNight}}</div>
      }
    
    }


  

  @if(isAttrAvailable('spokenLanguages')){
  
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('spokenLanguages').label}}</div>
    <div nz-col nzSpan="6">{{ filledInfo?.spokenLanguages ? getLanguages() : ''}}</div>
  
  }
  @if(isAttrAvailable('primaryLanguage')){
  
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('primaryLanguage').label}}</div>
    <div nz-col nzSpan="6">{{ filledInfo?.primaryLanguage ? getValue(filledInfo?.primaryLanguage, 'lang') : ''}}</div>
  
  }
  @if(isAttrAvailable('formLanguage')){
  
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('formLanguage').label}}</div>
    <div nz-col nzSpan="6">{{ filledInfo?.formLanguage ? getValue(filledInfo?.formLanguage, 'lang') : ''}}</div>
  
  }
  @if(isAttrAvailable('daysToContact')){
 
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('bestTimeToContact').label}}</div>
    <div nz-col nzSpan="6">{{filledInfo?.bestTimeToContact}}<!-- {{ filledInfo.daysToContact }} {{ (filledInfo.startContactTime)}} {{
      (filledInfo.endContactTime) }} --></div>
  
  }
  @if(isAttrAvailable('otherDetails')){
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('otherDetails').label}}</div>
    <div nz-col nzSpan="6">{{ filledInfo?.otherDetails }}</div>
  }
  @if(isAttrAvailable('specialAccomdation')){
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('specialAccomdation').label}}</div>
    <div nz-col nzSpan="6"> {{filledInfo?.specialAccomdation ? getLookupValue(accommodations, filledInfo?.specialAccomdation) : ''}}</div>
  }
  @if(filledInfo.specialAccomdation === 'OTH'){
  @if(isAttrAvailable('specialAccomdationOther')){
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('specialAccomdationOther').label}}</div>
    <div nz-col nzSpan="6">{{ filledInfo?.specialAccomdationOther }}</div>
  }
  }
  @if(isAttrAvailable('registerPref')){
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('registerPref').label}}</div>
    <div nz-col nzSpan="6"> {{filledInfo?.registerPref ? getLookupValue(participantRegisterMode, filledInfo?.registerPref) : ''}}</div>
  }

  @if(isAttrAvailable('showOnDirectory')){
    <div nz-col nzSpan="6" class="ppl-read-label">{{getAttributes('showOnDirectory').label}}</div>
    <div nz-col nzSpan="6"> {{filledInfo?.showOnDirectory ? filledInfo?.showOnDirectory : '-'}}</div>
  }

  
</div>
  }@else if(mode==="read" && !filledInfo ){
    <nz-row>
        <p>No Data found</p>
      </nz-row>
}


