<div class="ppl-container-body">
  <div class="ppl-container">
    <div class="ppl-content-container" style="padding: 8px; max-height: calc(100vh - 140px); box-sizing: border-box;">
      <div class="line1">
        <div class="action">
          <ppl-button [label]="'Back'" icon="fa fa-chevron-left" (click)="goBack()"
            [customClass]="'primary-button semi-rounded'" [iconPosition]="'left'"></ppl-button>
        </div>
      </div>
      <div class="line2">
        {{pageTitle}}
      </div>
      <div style="width: 100%">
        <ppl-accordion [title]="'Basic Information'" [isOpen]="true">
          <div class="wiz-body">
            <div class="ppl-mt24">
              <div class="custom-row">
                @if((mode==="edit") && !this.isNew){
                <ppl-button label="Cancel" icon="fa fa-ban" [customClass]="'alt-button'" [iconPosition]="'left'"
                  (click)="onCancel()"></ppl-button>
                <ppl-button label="Save" icon="fa fa-floppy-o" [customClass]="'alt-button'" [iconPosition]="'left'"
                  (click)="onUpdate()"></ppl-button>
                }
                @if(mode==="read" && !this.isNew){
                <ppl-button label="Edit" icon="fa fa-pencil" [customClass]="'ghost-button'" [iconPosition]="'left'"
                  (click)="onEdit()"></ppl-button>
                }
              </div>
              @if(mode==="edit"){
              <form nz-form [formGroup]="participantProviderRates" class="ppl-p0 form-container">
                @if(isAttrAvailable('code')){
                <nz-form-item nz-col class="ppl-form-item nz-padding-left">
                  <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="code"
                    class="ppl-form-label">{{getAttributes('code').label}}
                    @if(getAttributes('code').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('code')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      <nz-select formControlName="servicecodeDetailId" nzMode='default' class="ppl-select" id="code" nzShowSearch
                        nzAllowClear [ngClass]="{'error': validationErrors['code'] }" [nzDisabled]="">
                        @for(opt of serviceCodes; track $index){
                          <nz-option [nzLabel]="opt.code" [nzValue]="opt.serviceCodeDetailId"></nz-option>
                        }
                      </nz-select>
                    </div>
                    @if (validationErrors['code']) {
                    <span class="pl-1 error-message">{{validationErrors['code']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }
                @if(isAttrAvailable('participant_lkp')){
                <nz-form-item nz-col class="ppl-form-item nz-padding-left">
                  <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="participant_lkp"
                    class="ppl-form-label">{{getAttributes('participant_lkp').label}}
                    @if(getAttributes('participant_lkp').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <!-- <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                      <div nz-tooltip [nzTooltipTitle]="getAttributes('participant_lkp')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-tooltip nzTooltipTitle="'First Name'" nzTooltipPlacement="topRight"
                            nz-input formControlName="participant_lkp" id="participant_lkp"
                            placeholder="First Name" />
                      </div>
                      @if (validationErrors['participant_lkp']) {
                      <span class="pl-1 error-message">{{validationErrors['participant_lkp']}}</span>
                      }
                    </nz-form-control> -->
                  @if(isProviderDisabled){
                  <div [formGroup]="prospectiveParticipant">
                    <div nz-row>
                      <nz-form-item nz-col nzSpan="5" class="ppl-form-item pr-2 nz-padding-left">
                        <!-- <nz-form-label nzFor="firstName" [nzSm]="12" [nzXs]="24" class="ppl-form-label">First Name
                            </nz-form-label> -->
                        <nz-form-control class="ppl-form-control pright-8">
                          <input nz-tooltip nzTooltipTitle="Please enter First Name" nzTooltipPlacement="topRight"
                            nz-input formControlName="firstName" id="firstName" placeholder="First Name" />
                        </nz-form-control>
                      </nz-form-item>
                      <nz-form-item nz-col nzSpan="5" class="ppl-form-item pr-2 nz-padding-left">
                        <!-- <nz-form-label nzFor="lastName" [nzSm]="12" [nzXs]="24" class="ppl-form-label">lastName
                            </nz-form-label> -->
                        <nz-form-control class="ppl-form-control pright-8">
                          <input nz-tooltip nzTooltipTitle="Please enter Last Name" nzTooltipPlacement="topRight"
                            nz-input formControlName="lastName" id="lastName" placeholder="Last Name" />
                        </nz-form-control>
                      </nz-form-item>
                      <nz-form-item nz-col nzSpan="5" class="ppl-form-item pr-2 nz-padding-left">
                        <!-- <nz-form-label nzFor="participantPPLId" [nzSm]="12" [nzXs]="24" class="ppl-form-label">participantPPLId
                            </nz-form-label> -->
                        <nz-form-control class="ppl-form-control pright-8">
                          <input nz-tooltip nzTooltipTitle="Please enter Customer PPL Id" nzTooltipPlacement="topRight"
                            nz-input formControlName="participantPPLId" id="participantPPLId"
                            placeholder="Customer PPL Id" />
                        </nz-form-control>
                      </nz-form-item>
                      <nz-form-item nz-col nzSpan="3" class="ppl-form-item nz-padding-left">
                        <ppl-button label="Search" [customClass]="'primary-button form-button'" [iconPosition]="'left'"
                          (click)="search()" style="margin-top: 24px; height: 32px"></ppl-button>
                      </nz-form-item>
                    </div>
                  </div>
                  <div nz-row>
                    <div nz-col nzOffset="6">
                      @if (showSearchResults) {
                      <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true"
                        [data]="searchResultsList" [disableSelection]="true" [cols]="cols" [gridTitle]="false"
                        [pagination]="true" [loadPage]="false" (gridAction)="action($event)"
                        [rowsToDisplay]="10" [actionButtons]="actionButtons" [isProvider]="true">
                      </ppl-list-table>
                      }
                    </div>
                  </div>
                  }
                  @else {
                  <div nz-row style="display:flex; align-items: baseline">
                    <div class="ppl-form-item pr-2 nz-padding-left">
                      <!-- <p>{{selectedParticipantDetails}}</p> -->
                      <nz-form-control [nzSm]="24" [nzXs]="24" class="ppl-form-control">
                        <div nz-tooltip [nzTooltipTitle]="getAttributes('participant_lkp')?.tooltip || ''"
                          nzTooltipPlacement="topRight">
                          <input nz-tooltip nzTooltipTitle="'First Name'" nzTooltipPlacement="topRight" nz-input
                            formControlName="participantId" id="participant_lkp" placeholder="First Name" />
                        </div>
                        @if (validationErrors['participant_lkp']) {
                        <span class="pl-1 error-message">{{validationErrors['participant_lkp']}}</span>
                        }
                      </nz-form-control>
                    </div>
                    @if(reselectAllowed){
                      <div class="ppl-form-item pr-2 nz-padding-left">
                        <ppl-button label="Reselect" [customClass]="'primary-button form-button'" [iconPosition]="'left'"
                          (click)="reselect()" style="margin-top: 24px; height:32px;"></ppl-button>
                      </div>
                    }
                  </div>
                  }
                </nz-form-item>
                }

                <!-- <button (click)="add()">Add</button> -->

                @if(isAttrAvailable('provider_lkp')){
                <nz-form-item nz-col class="ppl-form-item nz-padding-left">
                  <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="provider_lkp"
                    class="ppl-form-label">{{getAttributes('provider_lkp').label}}
                    @if(getAttributes('provider_lkp').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('provider_lkp')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      @if(reselectAllowed){
                        <nz-select formControlName="providerId" nzMode='default' class="ppl-select" id="provider_lkp"
                        nzPlaceHolder="Select Personal Assistant" [nzDisabled]="!isParticipantSelected" nzShowSearch nzAllowClear
                        [ngClass]="{'error': validationErrors['provider_lkp'] }" [nzDisabled]="isProviderDisabled">
                        @for(opt of providers; track $index){
                        <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                        }
                      </nz-select>
                      }
                      @else {
                        <input nz-tooltip nzTooltipTitle="Personal Assistant" nzTooltipPlacement="topRight" nz-input
                        formControlName="providerId" id="provider" placeholder="Personal Assistant" />
                      }
                    </div>
                    @if (validationErrors['provider_lkp']) {
                    <span class="pl-1 error-message">{{validationErrors['provider_lkp']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }

                @if(isAttrAvailable('fromdate')){
                <nz-form-item nz-col class="ppl-form-item nz-padding-left">
                  <nz-form-label [nzSm]="6" [nzXs]="24" zFor="fromdate"
                    class="ppl-form-label">{{getAttributes('fromdate').label}}
                    @if(getAttributes('fromdate').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('fromdate')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      <nz-date-picker formControlName="fromDate" id="fromdate" [nzFormat]="'MM/dd/yyyy'"
                        [nzPlaceHolder]="getAttributes('fromdate').placeholder" [nzDisabledDate]="disabledStartDate"
                        [ngClass]="{'error': validationErrors['dob'] }"></nz-date-picker>
                    </div>
                    @if (validationErrors['fromdate']) {
                    <span class="pl-1 error-message">{{validationErrors['fromdate']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }
                @if(isAttrAvailable('todate')){
                <nz-form-item nz-col class="ppl-form-item nz-padding-left">
                  <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="todate"
                    class="ppl-form-label">{{getAttributes('todate').label}}
                    @if(getAttributes('todate').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('todate')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      <nz-date-picker formControlName="toDate" id="todate" [nzFormat]="'MM/dd/yyyy'"
                        [nzPlaceHolder]="getAttributes('todate').placeholder" [nzDisabledDate]="disabledEndDate"
                        [ngClass]="{'error': validationErrors['dob'] }"></nz-date-picker>
                    </div>
                    @if (validationErrors['todate']) {
                    <span class="pl-1 error-message">{{validationErrors['todate']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }

                @if(isAttrAvailable('rate')){
                <nz-form-item nz-col class="ppl-form-item nz-padding-left">
                  <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="rate"
                    class="ppl-form-label">{{getAttributes('rate').label}}
                    @if(getAttributes('rate').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('rate')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      <input nz-input formControlName="rate" id="rate"
                        placeholder="{{getAttributes('rate').placeholder}}"
                        [ngClass]="{'error': validationErrors['rate'] }" />
                    </div>
                    @if (validationErrors['rate']) {
                    <span class="pl-1 error-message">{{validationErrors['rate']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }



              </form>


              @if(isNew){
              <div class="custom-row">
                <ppl-button [label]="'Cancel'" [icon]="'fa fa-close'" style="margin-right:4px; font-size:0.857rem;"
                [customClass]="'ghost-button'" [iconPosition]="'right'" (click)="goBack()"></ppl-button>
                <ppl-button label="Submit" icon="fa fa-check-circle" [customClass]="'primary-button'"
                  [iconPosition]="'right'" (click)="onAdd()"></ppl-button>
              </div>
              }
              }
              @else if (mode==="read") {
                <div nz-row> 
                  @if(isAttrAvailable('code')){
                  <div nz-col nzSpan="12">
                    <div nz-row>
                      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("code").label }}
                      </div>
                      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.code }}</div>
                    </div>
                  </div>
                }
                @if(isAttrAvailable('participant_lkp')){
                  <div nz-col nzSpan="12">
                    <div nz-row>
                      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("participant_lkp").label }}
                      </div>
                      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.consumerFirstName }} {{savedData?.consumerLastName}}</div>
                    </div>
                  </div>
                }
                @if(isAttrAvailable('provider_lkp')){
                  <div nz-col nzSpan="12">
                    <div nz-row>
                      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("provider_lkp").label }}
                      </div>
                      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.providerFirstName }} {{savedData?.providerLastName}}</div>
                    </div>
                  </div>
                }
                @if(isAttrAvailable('fromdate')){
                  <div nz-col nzSpan="12">
                    <div nz-row>
                      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("fromdate").label }}
                      </div>
                      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.fromDate }}</div>
                    </div>
                  </div>
                }
                @if(isAttrAvailable('todate')){
                  <div nz-col nzSpan="12">
                    <div nz-row>
                      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("todate").label }}
                      </div>
                      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.toDate }}</div>
                    </div>
                  </div>
                }
                @if(isAttrAvailable('rate')){
                  <div nz-col nzSpan="12">
                    <div nz-row>
                      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("rate").label }}
                      </div>
                      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.rate }}</div>
                    </div>
                  </div>
                }
                </div>
              }
            </div>
          </div>
        </ppl-accordion>
      </div>
    </div>
  </div>
</div>