/* src/app/components/leads/enrollment/enrollment-info/enrollment-info.component.css */
.notes {
  font-size: 0.857rem;
  margin-top: 9px;
}
.notes p {
  margin: 0;
}
.ppl-form-item {
  margin: 4px 8px !important;
}
.ppl-step-main {
  padding: 0px !important;
  border-radius: 0 !important;
  background-color: #eaeaea;
}
::ng-deep .ant-card-body {
  padding: 0px !important;
}
::ng-deep .ant-col-24.ant-form-item-label {
  padding: 0px !important;
}
::ng-deep .ant-form-horizontal .ant-form-item-label {
  margin: 0px !important;
}
.ppl-form-control {
  margin: 0 0 4px 0 !important;
}
::ng-deep .ant-picker-input > input {
  border: none;
}
::ng-deep .ant-picker {
  padding: 0 11px;
  width: 100%;
}
::ng-deep .ant-input-affix-wrapper {
  margin-bottom: 0px !important;
  height: 34px !important;
  padding: 0px 11px !important;
}
/*# sourceMappingURL=enrollment-info.component-LHN2NFXD.css.map */
