/* src/app/shared/components/dialog/dialog.component.css */
.popup-dialog {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.01);
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
}
.position-dialog.center {
  top: -150px;
  left: -14px;
}
.position-dialog.top {
  top: 0;
  left: 0;
}
.position-dialog.middle {
  top: -83px;
  left: -14px;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.content {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1051;
}
.content.medium {
  width: 600px;
  max-width: 700px;
}
.content.large {
  width: 70%;
  max-width: 75%;
}
.fit-body {
  padding: 0 12px 0 12px !important;
}
.content.x-large {
  width: 85%;
  max-width: 90%;
  height: 96%;
}
.content.xx-large {
  width: calc(100% - 3rem);
  height: calc(100% - 3rem);
}
.content.xx-large .body {
  height: inherit;
  padding: 0;
  overflow: hidden;
}
.content.xx-large .body .body-content {
  gap: 0;
}
.content.x-large .fit-body,
.content.xx-large .fit-body {
  overflow-y: scroll !important;
  overflow-x: hidden;
  padding-right: 0px !important;
  height: 90%;
}
:is(.content.x-large .fit-body, .content.xx-large .fit-body)::-webkit-scrollbar {
  width: 4px;
}
:is(.content.x-large .fit-body, .content.xx-large .fit-body)::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  margin: 2px 4px;
}
:is(.content.x-large .fit-body, .content.xx-large .fit-body)::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
:is(.content.x-large .fit-body, .content.xx-large .fit-body)::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
  margin: 2px 4px;
}
.content.x-large .fit-body .body-content {
  height: 100%;
}
.header {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 3px solid #fff;
  background:
    linear-gradient(
      343deg,
      #e6e6e6 -18.21%,
      #f3f3f3 44.63%,
      #eaeaea 104.38%);
  box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
}
.title {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  color: #163235;
  font-family: Outfit;
  font-size: 1.714rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.body {
  display: flex;
  height: auto;
  padding: 12px 12px 32px 12px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 3px solid #fff;
  background:
    linear-gradient(
      343deg,
      #e6e6e6 -18.21%,
      #f3f3f3 44.63%,
      #eaeaea 104.38%);
  box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.12);
}
.body-content {
  align-self: stretch;
  color: var(--Color-9, #333);
  font-family: Outfit;
  font-size: 1.429rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.action-footer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
  width: 100%;
}
.action-footer.right-aligned-buttons {
  display: flex;
  justify-content: right;
}
.action-footer-button {
  display: grid;
  place-items: center;
  width: 50%;
}
.ppl-button {
  width: 100%;
}
.container {
  overflow: hidden;
}
.pdf-viewer .body {
  padding: 0;
}
.pdf-viewer .body .body-content {
  gap: 0;
}
.popup-dialog:nth-child(n) {
  z-index: calc(1000 + n * 10);
}
.popup-dialog:nth-child(n) .content {
  z-index: calc(1010 + n * 10);
}
/*# sourceMappingURL=dialog.component-U3UBJOWT.css.map */
