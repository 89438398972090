import { Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AccordionComponent } from "../../../../shared/components/accordion/accordion.component";
import { ListTableComponent } from "../../../../shared/components/list-table/list-table.component";
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { TableRowActionsModel } from '../../../../shared/interfaces/list.interface';
import { TableRowActions } from '../../../../shared/interfaces/actions';
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import { DialogConfig, DialogService } from '../../../../shared/services/dialog.service';
import { AuthorizeDirective } from '../../../../shared/authorization/authorize.directive.';
import { TimesheetNewEntriesComponent } from '../timesheets-add-entries/timesheets-add-entries.component';
import { PAGE_CONSTANTS, TABS } from '../../../../shared/constants/page.constants';

@Component({
  selector: 'ppl-timesheets',
  standalone: true,
  imports: [AccordionComponent, ListTableComponent, NgZorroModule, ButtonComponent, AuthorizeDirective, TimesheetNewEntriesComponent],
  templateUrl: './timesheets.component.html',
  styleUrl: './timesheets.component.css'
})
export class TimesheetsComponent implements OnInit {
  rowClicked($event: any) {
    throw new Error('Method not implemented.');
  }
  actionButtons: TableRowActionsModel = new TableRowActions();
  @ViewChild('timesheetDialog') timesheetDialog!: TemplateRef<any>;
  private dialogSvc = inject(DialogService);
  pageId: string = PAGE_CONSTANTS.TIMESHEET;
  tabId: string = TABS.TIMESHEET;

  cols = [
    {
      field: 'timesheet_no',
      header: 'Timesheet No',
      jsonpath: 'timesheet_no',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'timesheet_from_date',
      header: 'Timesheet From Date',
      jsonpath: 'timesheet_from_date',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'timesheet_to_date',
      header: 'Timesheet To Date',
      jsonpath: 'timesheet_to_date',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'consumer_name',
      header: 'Consumer Name',
      jsonpath: 'consumer_name',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'consumer_pplID',
      header: 'Consumer PPL ID',
      jsonpath: 'consumer_pplID',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'billed_hr',
      header: 'Billed Hrs',
      jsonpath: 'billed_hr',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'total_hrs',
      header: 'Total Hrs',
      jsonpath: 'total_hrs',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'total_paid_amount',
      header: 'Total Paid Amount',
      jsonpath: 'total_paid_amount',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'status',
      header: 'Status',
      jsonpath: 'status',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
  ];

  line_item_cols = [
    {
      field: 'timesheet_no',
      header: 'Timesheet No',
      jsonpath: 'timesheet_no',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'timesheet_from_date',
      header: 'Timesheet From Date',
      jsonpath: 'timesheet_from_date',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'service_code',
      header: 'Service Code',
      jsonpath: 'service_code',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'mod1',
      header: 'Mod 1',
      jsonpath: 'mod1',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'mod2',
      header: 'Mod 2',
      jsonpath: 'mod2',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'from',
      header: 'Time In',
      jsonpath: 'from',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'to',
      header: 'Time out',
      jsonpath: 'to',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'billed_hrs',
      header: 'Billed Hrs',
      jsonpath: 'billed_hrs',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'paid_amount',
      header: 'Paid Amount',
      jsonpath: 'paid_amount',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'authorizationNumber',
      header: 'Authorization No',
      jsonpath: 'authorizationNumber',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'status',
      header: 'Status',
      jsonpath: 'status',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
  ];

  timesheets = [
    {
      timesheet_no: 'T-112334',
      timesheet_from_date: '2024-09-29',
      timesheet_to_date: '2024-10-05',
      consumer_name: 'Nikhil Malhotra',
      consumer_pplID: 'PRC-NY-10000802',
      billed_hr: '18 hrs',
      total_hrs: '18 hrs',
      total_paid_amount: '2500',
      status: 'in Process',
      line_item: [
        {
          timesheet_no: 'T-112334',
          timesheet_from_date: '2024-09-29',
          service_code: 'Service 1',
          mod1: 'Venkataramana Neelapala',
          mod2: 'Sumit Murgai',
          from: '12:00 AM',
          to: '04:30 AM',
          billed_hr: '4.30 hrs',
          paid_amount: '1200',
          authorizationNumber: 'PVC-132334',
          status: 'approved'
        },
        {
          timesheet_no: 'T-112334',
          timesheet_from_date: '2024-09-29',
          service_code: 'Service 1',
          mod1: 'Venkataramana Neelapala',
          mod2: 'Sumit Murgai',
          from: '12:00 AM',
          to: '04:30 AM',
          billed_hr: '4.30 hrs',
          paid_amount: '1200',
          authorizationNumber: 'PVC-132334',
          status: 'approved'
        }
      ]
    },
    {
      timesheet_no: 'T-212356',
      timesheet_from_date: '2024-10-06',
      timesheet_to_date: '2024-10-12',
      consumer_name: 'Henderson Branch',
      consumer_pplID: 'PRC-NY-10000801',
      billed_hr: '12 hrs',
      total_hrs: '12 hrs',
      total_paid_amount: '700',
      status: 'in Process',
      line_item: [
        {
          timesheet_no: 'T-112334',
          timesheet_from_date: '2024-09-29',
          service_code: 'Service 1',
          mod1: 'Venkataramana Neelapala',
          mod2: 'Sumit Murgai',
          from: '12:00 AM',
          to: '04:30 AM',
          billed_hr: '4.30 hrs',
          paid_amount: '1200',
          authorizationNumber: 'PVC-132334',
          status: 'approved'
        },
        {
          timesheet_no: 'T-112334',
          timesheet_from_date: '2024-09-29',
          service_code: 'Service 1',
          mod1: 'Venkataramana Neelapala',
          mod2: 'Sumit Murgai',
          from: '12:00 AM',
          to: '04:30 AM',
          billed_hr: '4.30 hrs',
          paid_amount: '1200',
          authorizationNumber: 'PVC-132334',
          status: 'approved'
        }
      ]
    },
    {
      timesheet_no: 'T-312334',
      timesheet_from_date: '2024-10-13',
      timesheet_to_date: '2024-10-19',
      consumer_name: 'Renata Laugherane',
      consumer_pplID: 'PRC-NY-10000797',
      billed_hr: '8 hrs',
      total_hrs: '8 hrs',
      total_paid_amount: '600',
      status: 'in Process',
      line_item: [
        {
          timesheet_no: 'T-112334',
          timesheet_from_date: '2024-09-29',
          service_code: 'Service 1',
          mod1: 'Venkataramana Neelapala',
          mod2: 'Sumit Murgai',
          from: '12:00 AM',
          to: '04:30 AM',
          billed_hr: '4.30 hrs',
          paid_amount: '1200',
          authorizationNumber: 'PVC-132334',
          status: 'approved'
        },
        {
          timesheet_no: 'T-112334',
          timesheet_from_date: '2024-09-29',
          service_code: 'Service 1',
          mod1: 'Venkataramana Neelapala',
          mod2: 'Sumit Murgai',
          from: '12:00 AM',
          to: '04:30 AM',
          billed_hr: '4.30 hrs',
          paid_amount: '1200',
          authorizationNumber: 'PVC-132334',
          status: 'approved'
        }
      ]
    },
    {
      timesheet_no: 'T-112366',
      timesheet_from_date: '2024-10-20',
      timesheet_to_date: '2024-10-27',
      consumer_name: 'Torry	Hunton',
      consumer_pplID: 'PRC-NY-10000796',
      billed_hr: '4 hrs',
      total_hrs: '4 hrs',
      total_paid_amount: '400',
      status: 'in Process',
      line_item: [
        {
          timesheet_no: 'T-112334',
          timesheet_from_date: '2024-09-29',
          service_code: 'Service 1',
          mod1: 'Venkataramana Neelapala',
          mod2: 'Sumit Murgai',
          from: '12:00 AM',
          to: '04:30 AM',
          billed_hr: '4.30 hrs',
          paid_amount: '1200',
          authorizationNumber: 'PVC-132334',
          status: 'approved'
        },
        {
          timesheet_no: 'T-112334',
          timesheet_from_date: '2024-09-29',
          service_code: 'Service 1',
          mod1: 'Venkataramana Neelapala',
          mod2: 'Sumit Murgai',
          from: '12:00 AM',
          to: '04:30 AM',
          billed_hr: '4.30 hrs',
          paid_amount: '1200',
          authorizationNumber: 'PVC-132334',
          status: 'approved'
        }
      ]
    },
    {
      timesheet_no: 'T-112312',
      timesheet_from_date: '2024-10-27',
      timesheet_to_date: '2024-11-02',
      consumer_name: 'Gwenni Malhotra',
      consumer_pplID: 'PRC-NY-10000804',
      billed_hr: '12 hrs',
      total_hrs: '12 hrs',
      total_paid_amount: '1200',
      status: 'in Process',
      line_item: [
        {
          timesheet_no: 'T-112334',
          timesheet_from_date: '2024-09-29',
          service_code: 'Service 1',
          mod1: 'Venkataramana Neelapala',
          mod2: 'Sumit Murgai',
          from: '12:00 AM',
          to: '04:30 AM',
          billed_hr: '4.30 hrs',
          paid_amount: '1200',
          authorizationNumber: 'PVC-132334',
          status: 'approved'
        },
        {
          timesheet_no: 'T-112334',
          timesheet_from_date: '2024-09-29',
          service_code: 'Service 1',
          mod1: 'Venkataramana Neelapala',
          mod2: 'Sumit Murgai',
          from: '12:00 AM',
          to: '04:30 AM',
          billed_hr: '4.30 hrs',
          paid_amount: '1200',
          authorizationNumber: 'PVC-132334',
          status: 'approved'
        }
      ]
    },
    {
      timesheet_no: 'T-112323',
      timesheet_from_date: '2024-11-02',
      timesheet_to_date: '2024-11-09',
      consumer_name: 'Jefferey Malhotra',
      consumer_pplID: 'PRC-NY-10000804',
      billed_hr: '19 hrs',
      total_hrs: '19 hrs',
      total_paid_amount: '1900',
      status: 'in Process',
      line_item: [
        {
          timesheet_no: 'T-112334',
          timesheet_from_date: '2024-09-29',
          service_code: 'Service 1',
          mod1: 'Venkataramana Neelapala',
          mod2: 'Sumit Murgai',
          from: '12:00 AM',
          to: '04:30 AM',
          billed_hr: '4.30 hrs',
          paid_amount: '1200',
          authorizationNumber: 'PVC-132334',
          status: 'approved'
        },
        {
          timesheet_no: 'T-112334',
          timesheet_from_date: '2024-09-29',
          service_code: 'Service 1',
          mod1: 'Venkataramana Neelapala',
          mod2: 'Sumit Murgai',
          from: '12:00 AM',
          to: '04:30 AM',
          billed_hr: '4.30 hrs',
          paid_amount: '1200',
          authorizationNumber: 'PVC-132334',
          status: 'approved'
        }
      ]
    },
    {
      timesheet_no: 'T-112333',
      timesheet_from_date: '2024-11-10',
      timesheet_to_date: '2024-11-16',
      consumer_name: 'Kendrick Malhotra',
      consumer_pplID: 'PRC-NY-10000804',
      billed_hr: '12 hrs',
      total_hrs: '12 hrs',
      total_paid_amount: '1400',
      status: 'in Process',
      line_item: [
        {
          timesheet_no: 'T-112334',
          timesheet_from_date: '2024-09-29',
          service_code: 'Service 1',
          mod1: 'Venkataramana Neelapala',
          mod2: 'Sumit Murgai',
          from: '12:00 AM',
          to: '04:30 AM',
          billed_hr: '4.30 hrs',
          paid_amount: '1200',
          authorizationNumber: 'PVC-132334',
          status: 'approved'
        },
        {
          timesheet_no: 'T-112334',
          timesheet_from_date: '2024-09-29',
          service_code: 'Service 1',
          mod1: 'Venkataramana Neelapala',
          mod2: 'Sumit Murgai',
          from: '12:00 AM',
          to: '04:30 AM',
          billed_hr: '4.30 hrs',
          paid_amount: '1200',
          authorizationNumber: 'PVC-132334',
          status: 'approved'
        }
      ]
    },
    {
      timesheet_no: 'T-112338',
      timesheet_from_date: '2024-11-17',
      timesheet_to_date: '2024-11-23',
      consumer_name: 'Olly Agostini',
      consumer_pplID: 'PRC-NY-10000804',
      billed_hr: '12 hrs',
      total_hrs: '12 hrs',
      total_paid_amount: '1400',
      status: 'in Process',
      line_item: [
        {
          timesheet_no: 'T-112334',
          timesheet_from_date: '2024-09-29',
          service_code: 'Service 1',
          mod1: 'Venkataramana Neelapala',
          mod2: 'Sumit Murgai',
          from: '12:00 AM',
          to: '04:30 AM',
          billed_hr: '4.30 hrs',
          paid_amount: '1200',
          authorizationNumber: 'PVC-132334',
          status: 'approved'
        },
        {
          timesheet_no: 'T-112334',
          timesheet_from_date: '2024-09-29',
          service_code: 'Service 1',
          mod1: 'Venkataramana Neelapala',
          mod2: 'Sumit Murgai',
          from: '12:00 AM',
          to: '04:30 AM',
          billed_hr: '4.30 hrs',
          paid_amount: '1200',
          authorizationNumber: 'PVC-132334',
          status: 'approved'
        }
      ]
    },
  ]

  ngOnInit(): void {
  }

  action(event: any) {
    console.log('acto', event);
    if (event && event.action === 'edit') {
    }
  }

  addTimeentries() {
    this.openPopup();
  }

  openPopup() {
    const dialogConfig: DialogConfig = {
      title: 'Add New Time Entries',
      isOkText: 'Save',
      size: 'xx-large',
      position: 'top',
      showClose: true,
      showButtons: false,
    }

    this.dialogSvc.openDialog(this.timesheetDialog, dialogConfig, () => this.handleCancel(), () => this.handleCancel());
  }

  handleCancel() {

  }
}
