<div class="dropdown-container">
    <button [type]="type" [disabled]="disabled" (click)="toggleDropdown()" [ngClass]="customClass">
      <ng-container *ngIf="icon && iconPosition === 'left'">
        <i class="fa fa-chevron-down"></i>
      </ng-container>
      {{ label }}
      <ng-container *ngIf="icon && iconPosition === 'right'">
        <i class="fa fa-chevron-down"></i>
      </ng-container>
    </button>
    <ul class="dropdown-list" *ngIf="showDropdown">
      @for (subMenuItem of item?.links; track $index){
        <li class="dropdown-item" (click)="selectItem(subMenuItem)">{{subMenuItem.sub_menu_label}}</li>
      }
    </ul>
  </div>
  