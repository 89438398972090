/* src/app/components/enrollment/new-enrollment/new-enrollment.component.css */
.button-container {
  width: 50%;
  display: flex;
  gap: 12px;
  margin-left: auto;
}
.main-container {
  margin: auto;
  width: 65%;
  overflow-y: auto;
  height: 100%;
  margin-top: 100px !important;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}
.action-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
}
.hide-form {
  display: none;
}
/*# sourceMappingURL=new-enrollment.component-HQWIZR43.css.map */
