@if(mode==="edit"){
@if(source !== 'enrollment'){
<div class="ppl-mt8">
  @for(header of extractValues('header'); track $index){
  <h2>{{header}}</h2>
  }
  @for(text of extractValues('text'); track $index){
  <p class="ppl-description">{{text}}</p>
  }
</div>
}
<div [ngClass]="{'ppl-mt24': source !== 'enrollment'}">
  <nz-card nz-col [nzSpan]="source !== 'enrollment' ? 20 : 24" class="ppl-p24 ppl-step-main" [nzBordered]="false">
    <form nz-form [formGroup]="contactForm" class="ppl-p0">
      <nz-row [nzGutter]="16" class="ppl-new-form-control">
        @if(isAttrAvailable('preferredMethod')){
        <nz-col [nzSpan]="12">
          <nz-form-label [nzSpan]="24" nzFor="preferredMethod"
            [ngStyle]="{ 'margin-bottom':'0' }">{{getAttributes('preferredMethod').label}}
            @if(getAttributes('preferredMethod').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <nz-radio-group [ngClass]="{'error': validationErrors['preferredMethod'] }" nz-tooltip
              nzTooltipTitle="{{getAttributes('preferredMethod').tooltip}}" nzTooltipPlacement="topRight"
              id="preferredMethod" formControlName="preferredMethod" class="ppl-radio-group">
              <label nz-radio-button [nzValue]="getNzValue('Email')">Email</label>
              <label nz-radio-button [nzValue]="getNzValue('Mobile')">Mobile</label>
            </nz-radio-group>
            @if (validationErrors['preferredMethod']) {
            <span class="pl-1 error-message">{{validationErrors['preferredMethod']}}</span>
            }
          </nz-form-control>
        </nz-col>
        }
      </nz-row>
      <nz-row class="ppl-new-form-control" [nzGutter]="16">
        @if(isAttrAvailable('email') && isAttrAvailable('mobile')){
          <nz-col [nzSpan]="8">
            <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
              <nz-form-label [nzSpan]="24" nzFor="email" [ngStyle]="{ 'margin-bottom':'0' }"
              class="ppl-form-label">
                Please enter Email Address or Mobile Number
                @if(getAttributes('email').mandatory === 'Y' && getAttributes('mobile').mandatory === 'Y'){
                <span>*</span>
                }
              </nz-form-label>
              <nz-form-control [nzSpan]="24" class="ppl-mr36">
                <input nz-input nz-tooltip nzTooltipTitle="{{getAttributes('email').tooltip}}"
                  nzTooltipPlacement="topRight" formControlName="email" id="email"
                  [ngClass]="{'error': validationErrors['email'] }"
                  placeholder="{{getAttributes('email').placeholder}}" [readonly]="formReadonly" />
                @if (validationErrors['email']) {
                <span class="pl-1 error-message">{{validationErrors['email']}}</span>
                }
              </nz-form-control>
              <nz-form-control [nzSpan]="24">
                <input nz-input nz-tooltip nzTooltipTitle="{{getAttributes('mobile').tooltip}}"
                  nzTooltipPlacement="topRight" formControlName="mobile" id="mobile"
                  [ngClass]="{'error': validationErrors['mobile'] }"
                  placeholder="{{getAttributes('mobile').placeholder}}" [readonly]="formReadonly" />
                @if (validationErrors['mobile']) {
                <span class="pl-1 error-message">{{validationErrors['mobile']}}</span>
                }
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          }
        @if(isAttrAvailable('email') && !isAttrAvailable('mobile')){
        <nz-col [nzSpan]="8">
          <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
            <nz-form-label [nzSpan]="24" nzFor="email" [ngStyle]="{ 'margin-bottom':'0' }"
              class="ppl-form-label">{{getAttributes('email').label}}
              @if(getAttributes('email').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input nz-tooltip nzTooltipTitle="{{getAttributes('email').tooltip}}"
                nzTooltipPlacement="topRight" formControlName="email" id="email"
                [ngClass]="{'error': validationErrors['email'] }"
                placeholder="{{getAttributes('email').placeholder}}" [readonly]="formReadonly" />
              @if (validationErrors['email']) {
              <span class="pl-1 error-message">{{validationErrors['email']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        }
        @if(!isAttrAvailable('email') && isAttrAvailable('mobile')){

          <nz-col [nzSpan]="8">
            <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
              <nz-form-label [nzSpan]="24" nzFor="mobile" class="ppl-form-label"
                [ngStyle]="{ 'margin-bottom':'0' }">{{getAttributes('mobile').label}}
                @if(getAttributes('mobile').mandatory === 'Y'){
                <span>*</span>
                }
              </nz-form-label>
              <nz-form-control [nzSpan]="24">
                <input nz-tooltip nzTooltipTitle="{{getAttributes('mobile').tooltip}}" nzTooltipPlacement="topRight"
                  nz-input id="mobile" formControlName="mobile"
                  [ngClass]="{'error': validationErrors['mobile'] }"
                  placeholder="{{getAttributes('mobile').placeholder}}" [readonly]="formReadonly" />
                @if (validationErrors['mobile']) {
                <span class="pl-1 error-message">{{validationErrors['mobile']}}</span>
                }
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          }
        @if(isAttrAvailable('altPhone')){
        <nz-col [nzSpan]="8">
          <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
            <nz-form-label [nzSpan]="24" nzFor="altPhone" [ngStyle]="{ 'margin-bottom':'0' }"
              class="ppl-form-label">{{getAttributes('altPhone').label}}
              @if(getAttributes('altPhone').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-tooltip nzTooltipTitle="{{getAttributes('altPhone').tooltip}}" nzTooltipPlacement="topRight"
                nz-input formControlName="altPhone" [ngClass]="{'error': validationErrors['altPhone'] }" id="altPhone"
                placeholder="{{getAttributes('altPhone').placeholder}}" [readonly]="formReadonly" />
              @if (validationErrors['altPhone']) {
              <span class="pl-1 error-message">{{validationErrors['altPhone']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        }
       
      </nz-row>
      @if(isAttrAvailable('permanentAddress')){
      <!-- <nz-divider class="divider" style="margin-top: 16px;"></nz-divider> -->
      <nz-form-item class="ppl-form-item">
        <!-- <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="permanentAddress"
              class="ppl-form-label">{{getAttributes('permanentAddress').label}}
              @if(getAttributes('permanentAddress').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label> -->
        <div class="address-section">
          <nz-form-control class="ppl-form-control address-control">
            <ppl-enrollment-address [addressForm]="getFormGroup('permanentAddress')"
              [permanentValidationErrors]="permanentValidationErrors" [is_permanent]="isMailing"
              [showPSD]="showPSD" [formReadonly]="formReadonly"></ppl-enrollment-address>
            <!-- <ppl-address [addressForm]="getFormGroup('permanentAddress')" [permanentValidationErrors]="permanentValidationErrors" [is_permanent]="isMailing" [showPSD]="showPSD"></ppl-address> -->
          </nz-form-control>
        </div>
      </nz-form-item>
      <!-- <nz-divider class="divider"></nz-divider> -->
      }
      @if(!contactForm.get('permanentAddress')?.value.mailing){
      @if(isAttrAvailable('mailingAddress')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="mailingAddress"
          class="ppl-form-label">{{getAttributes('mailingAddress').label}}
          @if(getAttributes('mailingAddress').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <div class="address-section">
          <nz-form-control class="ppl-form-control address-control">
            <ppl-enrollment-address [addressForm]="getFormGroup('mailingAddress')"
              [mailingValidationErrors]="mailingValidationErrors" [is_permanent]="false"
              [showPSD]="showPSD"></ppl-enrollment-address>
            <!-- <ppl-address [addressForm]="getFormGroup('mailingAddress')" [mailingValidationErrors]="mailingValidationErrors" [is_permanent]="false" [showPSD]="showPSD"></ppl-address> -->
          </nz-form-control>
        </div>
        <!-- <nz-divider class="divider"></nz-divider> -->
      </nz-form-item>
      }
      }
      @if(isAttrAvailable('residentGt18')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="residentGt18"
          class="ppl-form-label">{{getAttributes('residentGt18').label}}
          @if(getAttributes('residentGt18').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control [nzSm]="16" class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('residentGt18')?.tooltip || ''" nzTooltipPlacement="topRight">
            <nz-radio-group [ngClass]="{'error': validationErrors['residentGt18'] }" formControlName="residentGt18"
              class="ppl-radio-group" id="residentGt18">
              @for(opt of radioOptions; track $index){
              <label nz-radio-button [nzValue]="opt.value">{{opt.label}}</label>
              }
              <label nz-radio-button [nzValue]="'unknown'">Unknown</label>
            </nz-radio-group>
          </div>
          @if (validationErrors['residentGt18']) {
          <span class="pl-1 error-message">{{validationErrors['residentGt18']}}</span>
          }

        </nz-form-control>
      </nz-form-item>
      }
      @if(contactForm.get('residentGt18')?.value === 'Yes'){
      <div nzRow>
        <div nzCol nzOffset="8" nzSpan="16">
          <p>This response may require additional background checks for an employee during the time of enrollment.</p>
        </div>
      </div>
      }
      @if(isAttrAvailable('yearsInState')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="yearsInState"
          class="ppl-form-label">{{getAttributes('yearsInState').label}}
          @if(getAttributes('yearsInState').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control [nzSm]="16" class="ppl-form-control">
          <input nz-input nz-tooltip nzTooltipTitle="{{getAttributes('yearsInState').tooltip}}"
            nzTooltipPlacement="topRight" formControlName="yearsInState" id="yearsInState"
            [ngClass]="{'error': validationErrors['yearsInState'] }"
            placeholder="{{getAttributes('yearsInState').placeholder}}" [readonly]="formReadonly" />
          @if (validationErrors['yearsInState']) {
          <span class="pl-1 error-message">{{validationErrors['yearsInState']}}</span>
          }
          <div class="notes">
            <p>
              This response may require additional background checks during enrolment.
            </p>
          </div>
        </nz-form-control>
      </nz-form-item>
      }
    </form>
  </nz-card>
</div>
}
@else if(mode==="read"){
@if(isAttrAvailable('preferredMethod')){
<div nz-row>
  <div nz-col nzSpan="11" class="ppl-read-label" style="margin-right:8px">{{ getAttributes('preferredMethod').label }}
  </div>
  <div nz-col nzSpan="12">{{ getLookupValue(communicationType, filledInfo['preferredMethod'])}}</div>
</div>
}
@if(isAttrAvailable('email')){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('email').label}} :</div>
  <div nz-col nzSpan="16">{{filledInfo['email']}}</div>
</div>
}
@if(isAttrAvailable('altPhone')){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('altPhone').label}} :</div>
  <div nz-col nzSpan="16">{{ filledInfo['altPhone']}}</div>
</div>
}
@if(isAttrAvailable('mobile')){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('mobile').label}} :</div>
  <div nz-col nzSpan="16">{{ filledInfo['mobile']}}</div>
</div>
}
@if(isAttrAvailable('permanentAddress')){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('permanentAddress').label}} :</div>
</div>
<div>
  <ppl-address [mode]="'read'" [showPSD]="showPSD" [data]="filledInfo['permanentAddress']"></ppl-address>
</div>
}

@if(isAttrAvailable('mailingAddress')){
@if(!contactForm.get('permanentAddress')?.value.mailing){
<div nz-row>
  <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('mailingAddress').label}} :</div>
</div>
<div>
  <ppl-address [mode]="'read'" [is_permanent]="false" [showPSD]="showPSD" [data]="filledInfo['mailingAddress']"></ppl-address>
</div>
}
}
@if(isAttrAvailable('residentGt18')){
<div nz-row>
  <div nz-col nzSpan="11" class="ppl-read-label" style="margin-right:8px">{{getAttributes('residentGt18').label}} :
  </div>
  <div nz-col nzSpan="12">{{ filledInfo['residentGt18'] }}</div>
</div>
}
}