import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from "../button/button.component";

@Component({
  selector: 'ppl-toaster-backup',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './toaster-backup.component.html',
  styleUrl: './toaster-backup.component.css'
})
export class ToasterBackupComponent {
  @Input() isToasterVisible = false;
  @Output() isToasterVisibleChange = new EventEmitter<boolean>();
  @Input() title ='';
  @Input() message = '';
  @Input() success = false;
  @Output() onClose = new EventEmitter<void>();

  closeAlert(): void {
    this.isToasterVisible = false;
    this.isToasterVisibleChange.emit(this.isToasterVisible); // Emit changes when closing the alert
    this.onClose.emit();
  }
}
