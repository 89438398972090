/* src/app/components/lead-details/participant-information/lead-detail-comm-methods/lead-detail-comm-methods.component.css */
.nz-padding-left {
  padding-left: 1rem;
}
.custom-row {
  display: flex;
  justify-content: flex-end;
}
[nz-icon] {
  margin-right: 6px;
  font-size: 1.429rem;
  color: #3c86f5;
}
.ant-radio-group {
  display: block;
  align-items: left !important;
}
.ant-radio-button-wrapper {
  margin: 0px;
  width: 33%;
}
/*# sourceMappingURL=lead-detail-comm-methods.component-IZT6LYIO.css.map */
