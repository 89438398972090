/* src/app/shared/components/button/button.component.css */
.alt-button {
  width: 100%;
  height: 2.571rem;
  display: flex;
  padding: 0.571rem 0.714rem;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  font-size: 0.857rem;
  font-style: normal;
  font-weight: 700;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
}
.alt-button:hover {
  cursor: pointer;
  background-color: #004C4A;
}
.alt-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.primary-button {
  width: 100%;
  height: 2.571rem;
  display: flex;
  padding: 0.571rem 0.714rem;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  font-size: 0.857rem;
  font-style: normal;
  font-weight: 700;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
}
.form-button {
  height: 32px !important;
}
.primary-button .button-icon {
  filter: invert(1);
}
.primary-button:hover {
  transition: background-color 0.3s ease;
  cursor: pointer;
  background-color: #303030;
  color: #ffffff;
  filter: invert(0);
}
.primary-button:hover > .button-icon {
  transition: background-color 0.4s ease;
  cursor: pointer;
  color: #ffffff;
  filter: invert(0);
}
.primary-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.rounded {
  border-radius: 100px;
  border: 2px solid #FFF;
  padding: 12px;
  height: 2.571rem;
  width: 36px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  content: none;
  justify-content: center;
}
.rounded:hover {
  transition: background-color 0.4s ease;
  cursor: pointer;
  background-color: #303030;
  color: #FFF;
}
.semi-rounded {
  border-radius: 100px;
  padding: 4px 12px 4px 8px;
}
.back-button {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #FFF;
  border: none;
  flex-direction: column;
  content: none;
}
.back-button:hover {
  transition: background-color 0.4s ease;
  cursor: pointer;
  background-color: #303030;
  color: #FFF;
}
.ghost-button {
  display: flex;
  height: 2.571rem;
  align-items: center;
  padding: 0.571rem 0.714rem;
  gap: 8px;
  border-radius: 8px;
  font-style: normal;
  font-weight: 700;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
}
.ghost-button:hover {
  transition: background-color 0.4s ease;
  cursor: pointer;
  background-color: #303030;
  color: #FFF;
}
.ghost-button:disabled {
  cursor: default;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.45);
}
.large {
  font-size: 1.143rem;
  padding: 24px;
  justify-content: flex-start;
}
.primary-button.small {
  justify-content: center !important;
}
/*# sourceMappingURL=button.component-KEVBGPKA.css.map */
