import { environment } from '../../../environments/environment';

export const API_BASE_URL = environment.apiUrl;

export const ENDPOINTS = {
  GET_PROGRAMS: `${API_BASE_URL}/api/v1/getProgramList`,
  GET_NOTIFICATIONS: `${API_BASE_URL}/api/v1/getNotificationMessage/{program_code}/{userType}`,
  GET_PROGRAM_TYPES: `${API_BASE_URL}/api/enrollment/provider/v1/getProviderLookUpTypes/{program_code}`,

  GET_LEAD_DATA: `${API_BASE_URL}/api/enrollment/v1/get{flow}LeadData/{program_code}/{participant_lead_id}`,
  UPDATE_LEAD_DEMOGRAPHICS: `${API_BASE_URL}/api/update/v1/updateDemographics/{flow}`,
  UPDATE_LEAD_PROF_LICENSES: `${API_BASE_URL}/api/enrollment/v1/updateProfLicenses/NGDEV/78`,
  UPDATE_LEAD_ADDRESS: `${API_BASE_URL}/api/update/v1/updateAddress/{flow}`,
  CREATE_UPDATE_LEAD_COMMUNICATION_METHODS: `${API_BASE_URL}/api/update/v1/updateCommunicationMethods/{flow}`,
  CREATE_UPDATE_LEAD_IDENTIFIERS: `${API_BASE_URL}/api/update/v1/updateIdentifiers/{flow}`,
  UPDATE_LEAD_COMMUNICATION_INFO: `${API_BASE_URL}/api/update/v1/updateCommuniInfo/{flow}`,
  CREATE_LEAD_COMMENTS: `${API_BASE_URL}/api/update/v1/updateComments/{flow}`,
  DELETE_LEAD_COMMUNICATION_METHODS: `${API_BASE_URL}/api/update/v1/deleteComniMethod/{flow}/{program_code}/{id}`,
  DELETE_LEAD_IDENTIFIER: `${API_BASE_URL}/api/update/v1/deleteIdentifier/{flow}/{program_code}/{id}`,

  UPDATE_PARTICIPANT_LEAD_INFO_DATA: `${API_BASE_URL}/api/enrollment/v1/updateLeadDemographics/participant`,
  UPDATE_PROVIDER_LEAD_INFO: `${API_BASE_URL}/api/enrollment/v1/updateLeadDemographics/provider`,
  UPDATE_PROVIDER_LEAD_ADDRESS: `${API_BASE_URL}/api/enrollment/v1/updateLeadDemographics/provider`,
  SEARCH_PARTICIPANT_LEAD_DATA: `${API_BASE_URL}/api/referral/v1/searchProspectiveParticipants`,
  VERIFY_PARTICIPANT: `${API_BASE_URL}/api/referral/v1/verifyProspectiveParticipant`,
  ADD_PARTICIPANT_PROVIDER_ASSOCIATION: `${API_BASE_URL}/api/enrollment/provider/v1/addParticipantProviderAssoc`,
  GET_USERS: `${API_BASE_URL}/api/v1/getUserList`,
  GET_ORG_DATA: `${API_BASE_URL}/api/v1/getOrgListForProgram/{program_code}`,
  ASSIGN_LEAD: `${API_BASE_URL}/api/common/v1/assignUser`,
  UPDATE_LEAD_STATUS: `${API_BASE_URL}/api/enrollment/v1/update{flow}LeadStatus`,
  // UPDATE_PROVIDER_LEAD_STATUS: `${API_BASE_URL}/api/enrollment/v1/updateProviderLeadStatus`,
  UPDATE_REFERRAL_STATUS: `${API_BASE_URL}/api/referral/v1/updateReferralStatus`,
  
  UPDATE_PARTICIPANT_STATUS: `${API_BASE_URL}/api/enrollment/participant/v1/updateStatus`,
  SAVE_LEAD: `${API_BASE_URL}/api/enrollment/v1/save{flow}Lead`,
  SAVE_REFERRAL: `${API_BASE_URL}/api/referral/v1/saveReferralParticipant`,

  GET_AUDIT_COMMENTS: `${API_BASE_URL}/api/v1/getAuditComments/{program_code}/{flow}/{id}/ALL_TABLES`,

  SEND_NOTIFICATION: `${API_BASE_URL}/api/v1/cmnctn/sendCommunication`,
  SEND_NOTIFICATION_WITH_IDS: `${API_BASE_URL}/api/enrollment/v1/sendCommunication`,

  GET_REFERRAL_DATA: `${API_BASE_URL}/api/referral/v1/getReferralParticipantData/{program_code}/{participant_lead_id}`,

  GET_PROSPECTIVE_PARTICIPANTS : `${API_BASE_URL}/api/enrollment/v1/getPrvdrPrtcpntDtls/{program_code}/{id}`,
  GET_VIEWER_DATA : `${API_BASE_URL}/api/{program_code}/v1/viewer/participant/{id}`,
  GET_VIEWER_USERS: `${API_BASE_URL}/api/{program_code}/v1/viewer/users?orgName={orgName}&orgType={orgType}`,
  SAVE_PARTICIPANT_VIEWER: `${API_BASE_URL}/api/{program_code}/v1/viewer/participant/{lead_id}`,
  UPDATE_PARTICIPANT_VIEWER: `${API_BASE_URL}/api/{program_code}/v1/viewer/{record_id}/changeDate`,
  INACTIVE_PARTICIPANT_VIEWER: `${API_BASE_URL}/api/{program_code}/v1/viewer/{record_id}/inactive`,

  GET_ENROLLMENT_STATUS_DATA : `${API_BASE_URL}/api/{program_code}/v1/status/participant/{id}`,
  SAVE_ENROLLMENT_STATUS: `${API_BASE_URL}/api/{program_code}/v1/status/participant/{lead_id}`,
  UPDATE_ENROLLMENT_DATA: `${API_BASE_URL}/api/{program_code}/v1/status/participant/{participantId}/change-date`,

  GET_SIGNATURE_DATA : `${API_BASE_URL}/api/enrollment/v1/getSignature/{program_code}/{flow}/{id}`,
  CHECK_DUPS_DATA: `${API_BASE_URL}/api/common/v1/dupsCheck/{flow}`,

  UPDATE_PROVIDER_SERVICES_RENDERED : `${API_BASE_URL}/api/update/v1/updateProviderServices/{flow}`,
  UPDATE_PROVIDER_PROF_LICENCES : `${API_BASE_URL}/api/update/v1/updateProfLicenses/{flow}`,
  GET_COMMUNICATION_LOGS : `${API_BASE_URL}/api/enrollment/v1/getCmnctnLogs/{program_code}/{flow}/{id}`,
  DELETE_PROSPECTIVE_PARTICIPANT : `${API_BASE_URL}/api/update/v1/deleteProspectiveParticipants/{id}`,
  ADD_UPDATE_PROSPECTIVE_PARTICIPANT : `${API_BASE_URL}/api/update/v1/updateProspectiveParticipants`,
  GET_PDF_CHECKLIST : `${API_BASE_URL}/api/v1/getPDFChecklist`,
  GET_PDF: `${API_BASE_URL}/api/v1/getPDF/{program_code}/{pdf_id}/{lang}`,
  GET_PDF_DATA : `${API_BASE_URL}/api/v1/getPDFFormData`,

  GET_MAIL_FORMS: `${API_BASE_URL}/api/v1/mailForms/{program_code}/{entityType}/{id}`,
  SEND_MAIL_FORMS: `${API_BASE_URL}/api/v1/mailForms/{program_code}/{entityType}/{id}`,

  SAVE_PARTICIPANT_FORMS : `${API_BASE_URL}/api/v1/forms/{program_code}/participant/{id}`,
  SAVE_PROVIDER_FORMS : `${API_BASE_URL}/api/v1/forms/{program_code}/provider/{id}`,
  SAVE_PARTICIPANT_ASSOCATION_FORMS : `${API_BASE_URL}/api/v1/forms/{program_code}/provider/{providerId}/participant/{participantId}?providerType={providerType}`,

  SAVE_PARTICIPANT_CHECKLIST : `${API_BASE_URL}/api/{program_code}/v1/checklist/participant/{userId}`,
  SAVE_PROVIDER_CHECKLIST : `${API_BASE_URL}/api/{program_code}/v1/checklist/provider/{userId}`,
  SAVE_PROVIDER_PARTICIPANT_ASSOCIATION_CHECKLIST : `${API_BASE_URL}/api/{program_code}/v1/checklist/provider/{userId}/participant/{participantId}?providerType={providerType}`,
  DOWNLOAD_PDF_FORM : `${API_BASE_URL}/api/v1/downloadForms`,
  GET_PARTICIPANT_CHECKLIST : `${API_BASE_URL}/api/{program_code}/v1/checklist/participant/{userId}`,
  GET_PROVIDER_CHECKLIST : `${API_BASE_URL}/api/{program_code}/v1/checklist/provider/{userId}`,
  GET_PARTICIPANT_ASSOCIATION_CHECKLIST : `${API_BASE_URL}/api/{program_code}/v1/checklist/provider/{providerId}/participant/{participantId}?providerType={providerType}`,
  UPDATE_AUTHORIZATION_DETAILS: `${API_BASE_URL}/api/{program_code}/v1/consumer-auth/{authorization_id}`,
  SAVE_AUTHORIZATIONS: `${API_BASE_URL}/api/{program_code}/v1/consumer-auth/`,
  ENROLLMENT : {
    SAVE_PARTICIPANT_ENROLLMENT_INFO: `${API_BASE_URL}/api/enrollment/participant/v1/saveParticipantEnrollmentInfo`,
    SAVE_PARTICIPANT_ENROLLMENT_COMM_PREF: `${API_BASE_URL}/api/enrollment/participant/v1/saveParticipantEnrlCommuniPrefs`,
    SAVE_PARTICIPANT_ENROLLMENT_CONTACT_DETAILS: `${API_BASE_URL}/api/enrollment/participant/v1/saveParticipantEnrlCommuniInfo`,
    GET_ENROLLMENT_DATA: `${API_BASE_URL}/api/enrollment/participant/v1/getParticipantEnrollmentData/{program_code}/{participant_lead_id}`,
    GET_ENROLLMENT_ASSOCIATIONS_DETAILS:`${API_BASE_URL}/api/enrollment/v1/getAssociationsByPrtcpnt/{program_code}/{participant_lead_id}`,
    GET_PARTICIPANT_ASSOCIATION: `${API_BASE_URL}/api/enrollment/participant/v1/getParticipantAssociation`,
    SAVE_PARTICIPANT_ENROLLMENT: `${API_BASE_URL}/api/enrollment/participant/v1/saveParticipantEnrollment`,
    SAVE_PARTICIPANT_ASSOCIATIONS: `${API_BASE_URL}/api/enrollment/provider/v1/saveParticipantProviderAssoc`,
    UPDATE_PARTICIPANT_ASSOCIATIONS_STATUS: `${API_BASE_URL}/api/enrollment/participant/v1/updateParticipantEnrlStatus`,
    SEND_PARTICIPANT_REGISTRATION:  `${API_BASE_URL}/api/enrollment/participant/v1/resendRegistrationLink`,
    SEND_PROVIDER_REGISTRATION:  `${API_BASE_URL}/api/enrollment/provider/v1/resendRegistrationLink`,
    DELETE_PARTICIPANT_PROVIDER_ASSOCIATION: `${API_BASE_URL}/api/enrollment/provider/v1/deletePrtcpntPrvdrAssctn`,

    // provider enrollment
    SAVE_PROVIDER_ENROLLMENT_INFO: `${API_BASE_URL}/api/enrollment/provider/v1/saveProviderEnrollmentInfo`,
    SAVE_PROVIDER_ENROLLMENT_COMM_PREF: `${API_BASE_URL}/api/enrollment/provider/v1/saveProviderEnrlCommuniPrefs`,
    SAVE_PROVIDER_ENROLLMENT_CONTACT_DETAILS: `${API_BASE_URL}/api/enrollment/provider/v1/saveProviderEnrlCommuniInfo`,
    SAVE_PROVIDER_ENROLLMENT_SERVICE_DETAILS: `${API_BASE_URL}/api/enrollment/provider/v1/saveProviderEnrlServiceDtls`,
    SAVE_PROVIDER_ENROLLMENT: `${API_BASE_URL}/api/enrollment/provider/v1/saveProviderEnrollment`,
    GET_PROVIDER_ASSOCIATIONS_DATA: `${API_BASE_URL}/api/enrollment/provider/v1/getProviderEnrollmentData/{program_code}/{participant_lead_id}`,

    GET_PROVIDER_ENROLLMENT_ASSOCIATIONS_DETAILS:`${API_BASE_URL}/api/enrollment/provider/v1/getProviderPrtcpntAssctn/{program_code}/{lead_id}`,
    GET_PROVIDER_ASSOCIATIONS: `${API_BASE_URL}/api/enrollment/v1/getAssociationsByProvider/{program_code}/{lead_id}`,
    UPDATE_PROVIDER_ASSOCIATIONS_STATUS: `${API_BASE_URL}/api/enrollment/provider/v1/updateProviderEnrlStatus`,

    GET_PROVIDER_PARTICIAPNT_ASSOCIATIONS_STATUS: `${API_BASE_URL}/api/{program_code}/v1/status/association/{associationId}`,
    CREATE_PROVIDER_PARTICIAPNT_ASSOCIATIONS_STATUS: `${API_BASE_URL}/api/{program_code}/v1/status/association/{associationId}`,
    UPDATE_PROVIDER_PARTICIAPNT_ASSOCIATIONS_STATUS: `${API_BASE_URL}/api/{program_code}/v1/status/association/{associationId}/change-date`,

    GET_FILE_TRIAGE: `${API_BASE_URL}/api/enrollment/v1/getFileTriage/{program_code}`,
    GET_FILE_TRIAGE_PDF: `${API_BASE_URL}/api/common/v1/downloadByPath`,
    GET_FILE_UPLOAD_NOTIFICATION: `${API_BASE_URL}/api/enrollment/v1/getUnacknowledgedUploads/{program_code}`,
    ACKNOWLEDGE_FILE_UPLOAD_NOTIFICATION: `${API_BASE_URL}/api/enrollment/v1/acknowledgeUpload`,
    GET_PROVIDERS_BY_SEARCH: `${API_BASE_URL}/api/enrollment/provider/v1/searchProviders`,
    GET_PARTICIPANTS_BY_SEARCH: `${API_BASE_URL}/api/enrollment/participant/v1/searchParticipants`,
  },
  COMMON:{
    UPLOAD_FILE: `${API_BASE_URL}/api/upload/v1/fileUpload`,
    GET_FILE_DETAILS: `${API_BASE_URL}/api/common/v1/getDocumentDetails/{program_code}/{flow}/{id}`,
    DELETE_FILE: `${API_BASE_URL}/api/upload/v1/deleteFileUpload/{program}/{type}/{id}/{createType}`,
    VIEW_FILE: `${API_BASE_URL}/api/common/v1/download/{program_code}/{flow}/{id}`
  },
  GET_REFRESH_FORMS : `${API_BASE_URL}/api/enrollment/v1/refreshForm`,
  GET_PARTICIPANT_ENROLLMENT_INFO : `${API_BASE_URL}/api/enrollment/participant/v1/getParticipantEnrollmentData/{program_code}/{participant_lead_id}`,
  GET_PROVIDER_ENROLLMENT_INFO : `${API_BASE_URL}/api/enrollment/provider/v1/getProviderEnrollmentData/{program_code}/{provider_lead_id}`,
  GET_SIGNATURE: `${API_BASE_URL}/api/enrollment/v1/getSignature/{program_code}/{type}/{id}`,
  SAVE_SIGNATURE: `${API_BASE_URL}/api/enrollment/v1/saveSignature`,
  GET_ENROLLMENT_SUMMARY : `${API_BASE_URL}/api/enrollment/{user_type}/v1/summaries/{program_code}/{participant_lead_id}`,

  GET_ADDITIONAL_ATTRIBUTES : `${API_BASE_URL}/api/enrollment/v1/getAdditionalAttributes/{programCode}/{enrollmentType}/{id}`,
  SAVE_ADDITIONAL_ATTRIBUTES : `${API_BASE_URL}/api/enrollment/v1/saveAdditionalAttributes`,
  DELETE_ADDITIONAL_ATTRIBUTES : `${API_BASE_URL}/api/enrollment/v1/additionalAttributes/{programCode}/{enrollmentType}/{id}`,
  CLAIMS: {
    GET_PROCESS_CODES : `${API_BASE_URL}/clmapi/claims/v1/processCodeDetails/{program_code}/{id}`,
    ADD_PROCESS_CODE: `${API_BASE_URL}/clmapi/claims/v1/processCodeDetails`,
    UPDATE_PROCESS_CODE: `${API_BASE_URL}/clmapi/claims/v1/processCodeDetails/{id}`,
    GET_SERVICE_CODES: `${API_BASE_URL}/clmapi/claims/servicecode/v1/serviceCodeDetails/{program_code}/{id}`,
    ADD_SERVICE_CODE: `${API_BASE_URL}/clmapi/claims/servicecode/v1/serviceCodeDetails`,
    UPDATE_SERVICE_CODE: `${API_BASE_URL}/clmapi/claims/servicecode/v1/serviceCodeDetails/{id}`,
    GET_SERVICE_CODE_RATES: `${API_BASE_URL}/clmapi/claims/servicecode/v1/serviceCodeDetailsRates/{program_code}/{id}`,
    ADD_SERVICE_CODE_RATE: `${API_BASE_URL}/clmapi/claims/servicecode/v1/serviceCodeRateDetails`,
    UPDATE_SERVICE_CODE_RATES: `${API_BASE_URL}/clmapi/claims/servicecode/v1/serviceCodeRateDetails/{id}`,
    GET_PARTICIPANT_PROVIDER_RATES: `${API_BASE_URL}/clmapi/claims/servicecode/v1/consumerAssistantRate/{program_code}/{id}`,
    ADD_PARTICIPANT_PROVIDER_RATES: `${API_BASE_URL}/clmapi/claims/servicecode/v1/ConsumerAssistant`,
    ADD_PAYABLE_PA_RATES: `${API_BASE_URL}/clmapi/payrate/v1/paPayRateDetails`,
    GET_PAYABLE_PROVIDER_RATES: `${API_BASE_URL}/clmapi/payrate/v1/paPayRateDetails/{program_code}/{id}`,
    UPDATE_PAYABLE_PA_RATES: `${API_BASE_URL}/clmapi/payrate/v1/paPayRateDetails/{id}`,
    UPDATE_PARTICIPANT_PROVIDER_RATES: `${API_BASE_URL}/clmapi/claims/servicecode/v1/ConsumerAssistant/{id}`,
    GET_BILLABLE_PROVIDER_RATES: `${API_BASE_URL}/clmapi/payrate/v1/paBillRateDetails/{program_code}/{id}`,
    ADD_BILLABLE_PROVIDER_RATES: `${API_BASE_URL}/clmapi/payrate/v1/paBillRateDetails`,
    UPDATE_BILLABLE_PROVIDE_RATES: `${API_BASE_URL}/clmapi/payrate/v1/paBillRateDetails/{id}`
  },

  GET_LOOKUP_VALUES: `${API_BASE_URL}/api/v1/getLookupValues`,
  ASSIGN_FILE_TRIAGE: `${API_BASE_URL}/api/enrollment/v1/assignFileTriage`,
  GET_TAS_LOOKUP_VALUES: `${API_BASE_URL}/clmapi/claims/servicecode/v1/getTasLookupValues`,
  GET_CONSUMER_AUTHORIZATIONS : `${API_BASE_URL}/api/{programCode}/v1/consumer-auth/participant/{id}`,
  GET_LIST_PAGE_DATA: `${API_BASE_URL}/api/v1/getListPageData`,
};

export const EXCLUDE_LOADER_URLS = ['https://api.addressy.com/'];


export const MENU_BASE_URLS = {
  PARTICIPANT_LEADS : 'participantLeadsList',
  PROVIDER_LEADS : 'providerLeadsList',
  GLOBAL_PARTICIPANT_SEARCH : 'globalConsumerSearch',
  REFERRAL : 'referralList',
  PARTICIPANT_ENROLLMENT : 'participantEnrollmentList',
  PROVIDER_ENROLLMENT : 'providerEnrollmentList',
  PROCESS_CODES : 'processCodeList',
  EXTERNAL_MEMBERS : 'enrollmentDetails',
  AUTHORIZATIONS : 'authorizations',
}
