{
    "participant_info": {
        "sectionId": "participant_info",
        "header": "Provider Lead Information",
        
        "available_actions": null,
        "attributes": [
            {
                "attribute_ID": "fname",
                "fieldName": "fname",
                "label": "First Name",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter your first name",
                "isAvailable": "Y",
                "placeholder": "First Name"
            },
            {
                "attribute_ID": "mname",
                "fieldName": "mname",
                "label": "Middle Name",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter your middle name",
                "isAvailable": "Y",
                "placeholder": "Middle Name"
            },
            {
                "attribute_ID": "lname",
                "fieldName": "lname",
                "label": "Last Name",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter your last name",
                "isAvailable": "Y",
                "placeholder": "Last Name"
            },
            {
                "attribute_ID": "maiden_name",
                "fieldName": "maiden_name",
                "label": "Maiden or Previous Name",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter your maiden name",
                "isAvailable": "Y",
                "placeholder": "Maiden or Previous Name"
            },
            {
                "attribute_ID": "dob",
                "fieldName": "dob",
                "label": "Date of Birth",
                "type": "datepicker",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please select your date of birth",
                "isAvailable": "Y",
                "placeholder": "Select Date"
            },
            {
                "attribute_ID": "gender",
                "fieldName": "gender",
                "label": "Gender",
                "type": "radio",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select your gender",
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "isProvidingServices",
                "fieldName": "isProvidingServices",
                "label": "Are you providing contracted services?",
                "type": "radio",
                "mandatory": "N",
                "validator": null,
                "tooltip": "",
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "SSN",
                "fieldName": "SSN",
                "label": "Social Security Number",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter your social security number",
                "isAvailable": "Y",
                "placeholder": "SSN"
            },
            {
                "attribute_ID": "MCD_ID",
                "fieldName": "MCD_ID",
                "label": "MCI number",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter your MCI number ",
                "isAvailable": "N",
                "placeholder": "Medicaid Number"
            }
        ],
        "validations": [
            {
                "whatToCheck": "atLeastXEntered",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "fname",
                            "lname",
                            "dob",
                            "SSN"
                        ],
                        "functionParams": {
                            "ErrMsg": "This field is mandatory."
                        }
                    }
                ]
            },
            {
                "whatToCheck": "checkFieldValue",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "fname"
                        ],
                        "functionParams": {
                            "isNullAllowed": "N",
                            "Minlength": "4",
                            "ErrMsg": "Please Enter First Name min length of 4 characters"
                        }
                    }
                ]
            },
            {
                "whatToCheck": "conditionalCheck",
                "clientOrServer": "C",
                "attributes": []
            },
            {
                "whatToCheck": "customValidator",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "SSN"
                        ],
                       "functionParams": {
							"validatorFunction" : "validateSSN",
							"ErrMsg": "SSN Cannot start with a 9"
                        }
                    }
                ]
            }
        ],
        "actions": [
            {
                "Name": "Previous",
                "Validate": null
            },
            {
                "Name": "Next",
                "Validate": [
                    {
                        "checkType": "atLeastXEntered",
                        "validatorid": [
                            1,
                            2,
                            3,
                            4
                        ]
                    },
                    {
                        "checkType": "checkFieldValue",
                        "validatorid": [
                            1
                        ]
                    },
                    {
                        "checkType": "conditionalCheck",
                        "validatorid": [
                            1
                        ]
                    },
                    {
                        "checkType": "customValidator",
                        "validatorid": [
                            1
                        ]
                    }
                ]
            }
        ]
    },
    "contact_details": {
        "sectionId": "contact_details",
        "header": "Contact Details",
        "text_1": "Please share your contact details",
        "text_2": null,
        "text_3": null,
        "available_actions": null,
        "attributes": [
            {
                "attribute_ID": "preferredMethod",
                "fieldName": "preferredMethod",
                "label": "What is the preferred contact method for PPL to contact you?",
                "type": "radio",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please select the preferred contact method",
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "email",
                "fieldName": "email",
                "label": "Email Address",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter your email address",
                "isAvailable": "Y",
                "placeholder": "Email Address"
            },
            {
                "attribute_ID": "altPhone",
                "fieldName": "altPhone",
                "label": "Telephone Number",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please enter telephone or alternate phone number",
                "isAvailable": "Y",
                "placeholder": "10-digit Telephone Number"
            },
            {
                "attribute_ID": "mobile",
                "fieldName": "mobile",
                "label": "Mobile Number",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter your 10 digit mobile number",
                "isAvailable": "Y",
                "placeholder": "10-digit Mobile Number"
            },
            {
                "attribute_ID": "permanentAddress",
                "fieldName": "permanentAddress",
                "label": "Physical Address",
                "type": "form",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please select your Physical Address",
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "mailingAddress",
                "fieldName": "mailingAddress",
                "label": "Mailing Address",
                "type": "form",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter your Mailing Address",
                "isAvailable": "Y"
            }
        ],
        "validations": [
            {
                "whatToCheck": "atLeastXEntered",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "mobile",
                            "email"
                        ],
                        "functionParams": {
                            "ErrMsg": "This field is mandatory."
                        }
                    }
                ]
            },
            {
                "whatToCheck": "checkFieldValue",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "email"
                        ],
                        "functionParams": {
                            "isNullAllowed": "N",
                            "Minlength": "4",
                            "ErrMsg": "Please enter valid email address"
                        }
                    }
                ]
            },
            {
                "whatToCheck": "conditionalCheck",
                "clientOrServer": "C",
                "attributes": []
            }
        ],
        "actions": [
            {
                "Name": "Previous",
                "Validate": null
            },
            {
                "Name": "Next",
                "Validate": [
                    {
                        "checkType": "atLeastXEntered",
                        "validatorid": [
                            1,
                            2
                        ]
                    },
                    {
                        "checkType": "checkFieldValue",
                        "validatorid": [
                            1
                        ]
                    },
                    {
                        "checkType": "conditionalCheck",
                        "validatorid": [
                            1
                        ]
                    }
                ]
            }
        ]
    },
    "service_details": {
        "sectionId": "service_details",
        "header": "Service Details",
        "text_1": "Please share your service details",
        "text_2": null,
        "text_3": null,
        "available_actions": null,
        "attributes": [
            {
                "attribute_ID": "profLicences",
                "fieldName": "profLicences",
                "label": "Professional Licenses",
                "type": "select",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please select your Professional Licenses",
                "isAvailable": "Y",
                "placeholder": "Professional Licenses "
            },
            {
                "attribute_ID": "filesUploaded",
                "fieldName": "filesUploaded",
                "label": " ",
                "type": "file-upload",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please upload your supportive documents",
                "isAvailable": "Y",
                "placeholder": ""
            },
            {
                "attribute_ID": "servicesRendered",
                "fieldName": "servicesRendered",
                "label": "Services that you can render",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select the services",
                "isAvailable": "Y",
                "placeholder": "Services"
            },
            {
                "attribute_ID": "participantInfo",
                "fieldName": "participantInfo",
                "label": "Give us information on prospective consumer",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "availabilityDays",
                "fieldName": "availabilityDays",
                "label": "Availability Day(s) of Week",
                "type": "checkbox",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please select availability days",
                "isAvailable": "Y",
                "placeholder": "Availability Day(s) of Week"
            },
            {
                "attribute_ID": "availabilityTiming",
                "fieldName": "availabilityTiming",
                "label": "Availability Timings",
                "type": "timepicker",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please select availability timings",
                "isAvailable": "Y",
                "placeholder": "Select Time"
            },
            {
                "attribute_ID": "isAvailableForEmergencies",
                "fieldName": "isAvailableForEmergencies",
                "label": "Available for Emergencies",
                "type": "radio",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Available for Emergencies",
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "showOnDirectory",
                "fieldName": "showOnDirectory",
                "label": "Do you want to be listed in Provider Directory?",
                "type": "radio",
                "mandatory": "N",
                "validator": null,
                "tooltip": "",
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "dayEmergencyAvailablity",
                "fieldName": "dayEmergencyAvailablity",
                "label": "Day",
                "type": "timepicker",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Availability for Day Emergencies",
                "isAvailable": "Y",
                "placeholder": "Select Time"
            },
            {
                "attribute_ID": "nightEmergencyAvailablity",
                "fieldName": "nightEmergencyAvailablity",
                "label": "Night",
                "type": "select",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Availability for Night Emergencies",
                "isAvailable": "Y",
                "placeholder": "Select time"
            },
            {
                "attribute_ID": "distanceWillingToTravel",
                "fieldName": "distanceWillingToTravel",
                "label": "Distance willing to travel in miles",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter distance willing to travel in miles",
                "isAvailable": "Y",
                "placeholder": "Distance willing to travel in miles"
            }
        ],
        "validations": [
            {
                "whatToCheck": "atLeastXEntered",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "profLicences" 
                        ],
                        "functionParams": {
                            "ErrMsg": "This field is mandatory."
                        }
                    },
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "distanceWillingToTravel"
                           
                        ],
                        "functionParams": {
                            "ErrMsg": "This field is mandatory."
                        }
                    }
                ]
            },
            {
                "whatToCheck": "checkFieldValue",
                "clientOrServer": "C",
                "attributes": []
            },
            {
                "whatToCheck": "conditionalCheck",
                "clientOrServer": "C",
                "attributes": []
            }
        ],
        "actions": [
            {
                "Name": "Previous",
                "Validate": null
            },
            {
                "Name": "Next",
                "Validate": [
                    {
                        "checkType": "atLeastXEntered",
                        "validatorid": [
                            1,
                            2
                        ]
                    },
                    {
                        "checkType": "checkFieldValue",
                        "validatorid": [
                            1
                        ]
                    },
                    {
                        "checkType": "conditionalCheck",
                        "validatorid": [
                            1
                        ]
                    }
                ]
            }
        ]
    },
    "payment_details": {
        "sectionId": "payment_details",
        "header": "Payment Details",
        "text_1": "Please tell us how you want to receive your payments.  You can receive a direct deposit to your bank account or a direct deposit to your debit card.  It takes one to two pay periods for direct deposit to become active.  In the interim, you will be paid by paper check.",
        "text_2": null,
        "text_3": null,
        "available_actions": null,
        "attributes": [
            {
                "attribute_ID": "paymentMethod",
                "fieldName": "paymentMethod",
                "label": "Payment Method",
                "type": "radio",
                "mandatory": "N",
                "validator": null,
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "bankName",
                "fieldName": "bankName",
                "label": "Bank Name",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "isAvailable": "Y",
                "placeholder": "Bank Name"
            },
            {
                "attribute_ID": "accountType",
                "fieldName": "accountType",
                "label": "Account Type",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "isAvailable": "Y",
                "placeholder": "Account Type"
            },
            {
                "attribute_ID": "routingNumber",
                "fieldName": "routingNumber",
                "label": "Routing Number",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "isAvailable": "Y",
                "placeholder": "Routing Number"
            },
            {
                "attribute_ID": "accountNumber",
                "fieldName": "accountNumber",
                "label": "Account Number",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "isAvailable": "Y",
                "placeholder": "Account Number"
            },
            {
                "attribute_ID": "payStub",
                "fieldName": "payStub",
                "label": "Would you like to receive your pay stub in the mail?",
                "type": "radio",
                "mandatory": "N",
                "validator": null,
                "isAvailable": "Y"
            }
        ],
        "validations": [
            {
                "whatToCheck": "atLeastXEntered",
                "clientOrServer": "C",
                "attributes": []
            },
            {
                "whatToCheck": "checkFieldValue",
                "clientOrServer": "C",
                "attributes": []
            },
            {
                "whatToCheck": "conditionalCheck",
                "clientOrServer": "C",
                "attributes": []
            }
        ],
        "actions": [
            {
                "Name": "Previous",
                "Validate": null
            },
            {
                "Name": "Next",
                "Validate": []
            }
        ]
    },
    "comm_preferences": {
        "sectionId": "comm_preferences",
        "header": "Communication Preferences",
        "text_1": "Please share your communication preferences",
        "text_2": null,
        "text_3": null,
        "available_actions": null,
        "attributes": [
            {
                "attribute_ID": "contactType",
                "fieldName": "contactType",
                "label": "Who is the primary contact?",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select the primary contact",
                "isAvailable": "N"
            },
            {
                "attribute_ID": "spokenLanguages",
                "fieldName": "spokenLanguages",
                "label": "Languages Spoken",
                "type": "select",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select the Spoken Languages",
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "contactFirstName",
                "fieldName": "contactFirstName",
                "label": "First Name",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter first name",
                "isAvailable": "N",
                "placeholder": "First Name"
            },
            {
                "attribute_ID": "contactLastName",
                "fieldName": "contactLastName",
                "label": "Last Name",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter last name",
                "isAvailable": "N",
                "placeholder": "Last Name"
            },
            {
                "attribute_ID": "contactEmail",
                "fieldName": "contactEmail",
                "label": "Email",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter email",
                "isAvailable": "N",
                "placeholder": "Email"
            },
            {
                "attribute_ID": "contactPhone",
                "fieldName": "contactPhone",
                "label": "Phone",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter phone number",
                "isAvailable": "N",
                "placeholder": "Phone"
            },
            {
                "attribute_ID": "daysToContact",
                "fieldName": "daysToContact",
                "label": "Best time to contact",
                "type": "check",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please select best time to contact",
                "isAvailable": "N"
            },
            {
                "attribute_ID": "startContactTime",
                "fieldName": "startContactTime",
                "label": "",
                "type": "timepicker",
                "mandatory": "",
                "validator": null,
                "tooltip": "",
                "isAvailable": "",
                "placeholder": "Start time"
            },
            {
                "attribute_ID": "endContactTime",
                "fieldName": "endContactTime",
                "label": "",
                "type": "timepicker",
                "mandatory": "",
                "validator": null,
                "tooltip": "",
                "isAvailable": "",
                "placeholder": "End time"
            },
            {
                "attribute_ID": "language",
                "fieldName": "language",
                "label": "Primary Language",
                "type": "select",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please select your primary language",
                "isAvailable": "N"
            },
            {
                "attribute_ID": "otherDetails",
                "fieldName": "otherDetails",
                "label": "Other Details ",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter other details",
                "isAvailable": "Y",
                "placeholder": "Other Details"
            },
            {
                "attribute_ID": "specialAccomdation",
                "fieldName": "specialAccomdation",
                "label": "Special Accommodations",
                "type": "select",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please select special accomodations",
                "isAvailable": "Y"
            },
            {
                "attribute_ID": "specialAccomdationOther",
                "fieldName": "specialAccomdationOther",
                "label": "Special Accommodations",
                "type": "input",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please enter any other special accomodations",
                "isAvailable": "Y"
            }
        ],
        "validations": [
            {
                "whatToCheck": "atLeastXEntered",
                "clientOrServer": "C",
                "attributes": [
                    {
                        "validatorid": 1,
                        "attribute_ID": [
                            "otherDetails"
                           
                        ],
                        "functionParams": {
                            "ErrMsg": "This field is mandatory."
                        }
                    }
                ]
            },
            {
                "whatToCheck": "checkFieldValue",
                "clientOrServer": "C",
                "attributes": []
            },
            {
                "whatToCheck": "conditionalCheck",
                "clientOrServer": "C",
                "attributes": []
            }
        ],
        "actions": [
            {
                "Name": "Previous",
                "Validate": null
            },
            {
                "Name": "Next",
                "Validate": [
                    {
                        "checkType": "atLeastXEntered",
                        "validatorid": [
                            1
                        ]
                    },
                    {
                        "checkType": "checkFieldValue",
                        "validatorid": [
                            1
                        ]
                    },
                    {
                        "checkType": "conditionalCheck",
                        "validatorid": [
                            1
                        ]
                    }
                ]
            }
        ]
    }
}