import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadServiceService {
  
    download(blob: string, fileName: string, fileInNewTab: boolean) {
      console.log('I am here');

      const contentType = 'application/pdf'
      const byteCharacters = blob;
      const byteArrays = [];
  
      const sliceSize = 512;
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
  
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
  
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
  
      const blobData = new Blob(byteArrays, { type: contentType });
  
      
        const link = document.createElement('a');
        const url = URL.createObjectURL(blobData);
  
        link.setAttribute('href', url);
        link.setAttribute('target', fileInNewTab ? '_blank' : '_self');
        link.setAttribute('Content-Disposition', 'inline');
  
        if (fileName) {
          link.setAttribute('download', fileName);
        }
  
     
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
       
        URL.revokeObjectURL(url);
    }
}
