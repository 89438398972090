<div class="ppl-main-fullwidth mb-5">
  <div class="card">
    <p-table #dt2 
      [value]="filteredData" 
      [(selection)]="selectedCheckBoxValue"
      [paginator]="isPaginationVisible()"
      [rows]="pageSize"
      [columns]="cols" 
      [exportHeader]="'customExportHeader'"
      [tableStyle]="{ 'min-width': tableWidth ? tableWidth:'50rem'}" 
      [scrollable]="tableScrollable"
      [scrollHeight]="tableScrollHeight"
      [globalFilterFields]="globalFilterField" 
      [totalRecords]="totalRecords || filteredData.length" 
      (onLazyLoad)="loadRecords($event)"
      (onSort)="sortByColumn($event)" 
      (onFilter)="onColumnFilter($event)" 
      (onHeaderCheckboxToggle)="selectAll($event)"
      [lazy]="loadPage" 
      styleClass="p-datatable-striped"
    >
      @if(!disableSearch){
      <ng-template pTemplate="caption">
        <div style="display: flex; justify-content: space-between;">
          <div class="table-header-action-container">
            <div style="position: relative; width: 35% !important">
              <p-iconField iconPosition="right" class="ml-auto">
                <input #inputRef display="menu" pInputText type="text" class="global-search" title="Filter results"
                  (input)="onSearch($any($event.target).value)" [(ngModel)]="gridTextSearchFilter"
                  placeholder="Filter" />
              </p-iconField>
              <!-- <div class="filter-panel">
                
                <div></div>
              </div> -->
            </div>
            <ppl-button ariaLabel="Custom search" label="Custom Search" icon="fa fa-filter" [customClass]="'primary-button'"
              [iconPosition]="'left'" (click)="show = true"></ppl-button>
            <ppl-button 
              label="" 
              ariaLabel="Download"
              icon="fa fa-download" 
              [customClass]="'primary-button'" 
              [iconPosition]="'left'"
              (click)="dt2.exportCSV({selectionOnly: true})" 
            ></ppl-button>
            <ppl-button 
              label="" 
              ariaLabel="Refresh"
              icon="fa fa-refresh" 
              [customClass]="'primary-button'" 
              [iconPosition]="'left'"
              (click)="onCancel()" 
            ></ppl-button>
            <!-- <p-inputIcon (click)="filterpanel.toggle($event)">
              <div class="filter-btn custom-search-btn" [ngClass]="{'primary-bgclr': isFilterApplied, 'hammer-btn': !isFilterApplied}">
                <i class="pi pi-filter"></i>&nbsp;
                <span class="filter-text"><b>Custom Search</b></span>
              </div>
            </p-inputIcon> -->
            <!-- <p-inputIcon (click)="dt2.exportCSV()">
              <i class="pi pi-file-excel hammer-btn"></i>
            </p-inputIcon> -->
            <!-- <p-inputIcon (click)="onCancel()">
              <i class="pi pi-refresh hammer-btn"></i>
            </p-inputIcon> -->
          </div>
          <div class="row-buttons-container">
            <!-- <div class="custom-row m-0">
              <span nz-icon nzType="message" nzTheme="outline" class="mr-2" (click)="sendSMS()"></span>
              <span nz-icon nzType="mail" nzTheme="outline" class="mr-2"></span>
            </div> -->
            <div class="actGRP">
              <!-- <img src="../../assets/images/sms_24dp_E8EAED_FILL1_wght400_GRAD0_opsz24.svg" height="32px" (click)="sendNotification('sms')">
          <img class="ico" src="../../assets/images/mail_24dp_E8EAED_FILL1_wght400_GRAD0_opsz24.svg" (click)="sendNotification('email')" height="32px"> -->
              <ppl-actions [listButton]="listButton" [selectedCheckBoxValue]="selectedCheckBoxValue"
                [sourcePage]="jsonList" (leadUpdated)="leadUpdated($event)" [from]="'list'"></ppl-actions>
              <div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      }
      <ng-template pTemplate="header" let-columns>
        <tr>
          @if(!disableSelection){
          <th style="width: 4rem; padding-top: 1px; background-color: #a8e4e14d;">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          } @for ( col of columns ; track col; let idx = $index ) {

          @if(disableSort){
          <th style="text-wrap: nowrap; background-color:#a8e4e14d;">
            {{ col.header }}
          </th>
          }

          @if(!disableSort){
          <th [pSortableColumn]="col.field" style="background-color:#a8e4e14d;">


            <div class="filter-item" [class.last-th]="idx === columns?.length - 1">
              {{ col.header }}
              <div>
                <p-sortIcon [field]="col.field"></p-sortIcon>
                <!-- <p-columnFilter type="text" [field]="col.field" display="menu" /> -->
              </div>
            </div>
          </th>
          }
          } @if(enableActions){
          <th style="background-color:#a8e4e14d;">
            <div>Actions</div>
          </th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">

          @if(!disableSelection){
          <td class="check-box">
            <p-tableCheckbox [value]="rowData" [ariaLabel]="rowData[rowDataAccessibilityKey]"></p-tableCheckbox>
          </td>
          }
          @if(!data.length){
          @for (record of filterHeaders; track $index) {
          @if($first){
          <td (click)="rowClicked(rowData)" class="table-data-first-column">
            {{ rowData[record.jsonpath] || '-' }}
          </td>
          }@else {
          <td (dblclick)="rowClicked(rowData)" class="table-data">
            {{ rowData[record.jsonpath] || '-' }}
          </td>
          }
          }
          }

          @if(data.length){
          @for ( col of columns ; track col ) {
            @if(col.header === 'Message'){
              <td [innerHTML]="rowData[col.field]" class="table-data" (click)="rowClicked(rowData, col)" [ngClass]="{'prospective': disableSort}">             </td>
            }

            @else {
              <td class="table-data" (click)="rowClicked(rowData, col)" [ngClass]="{'prospective': disableSort}">
                <ng-container *ngIf="col.field === 'isVerified'">
                  {{ rowData.isVerified ? 'Yes' : 'No' }}
                </ng-container>
                <ng-container *ngIf="col.field !== 'isVerified'">
                  {{ rowData[col.field] }}
                </ng-container>
              </td>
            }
          }
          }

          @if(enableActions){
          <td>
            <div class="action-item">
              <div class="action-container">
                @if(actionButtons?.edit && (!isVerifiedFlag || (isVerifiedFlag && !rowData.isVerified)) ||
                rowData.actions?.edit){
                <ppl-button ariaLabel="Edit" label="Edit" icon="fa fa-pencil" [customClass]="'ghost-button'" [iconPosition]="'left'"
                  (click)="gridActionClicked('edit', rowData)"></ppl-button>
                }
                @if(actionButtons?.verify && (!isVerifiedFlag || (isVerifiedFlag && !rowData.isVerified)) ||
                rowData.actions?.verify){
                <ppl-button ariaLabel="Edit and verify" label="Edit & Verify" icon="fa fa-pencil" [customClass]="'ghost-button'" [iconPosition]="'left'"
                  (click)="gridActionClicked('verify', rowData)"></ppl-button>
                }

                @if(actionButtons?.view){
                <ppl-button ariaLabel="View" label="View" icon="fa fa-eye" [customClass]="'ghost-button'" [iconPosition]="'left'"
                  (click)="gridActionClicked('view', rowData)"></ppl-button>
                }

                @if(actionButtons?.download){
                <ppl-button ariaLabel="Download" label="Download" icon="fa fa-download" [customClass]="'ghost-button'"
                  [iconPosition]="'left'" (click)="gridActionClicked('download', rowData)"></ppl-button>
                }

                @if(actionButtons?.delete){
                <ppl-button ariaLabel="Delete" label="Delete" icon="fa fa-trash" [customClass]="'ghost-button'" [iconPosition]="'left'"
                  (click)="gridActionClicked('delete', rowData)"></ppl-button>
                }

                @if(actionButtons?.add){
                <ppl-button ariaLabel="Add" label="Add" icon="fa fa-plus" [customClass]="'ghost-button'" [iconPosition]="'left'"
                  (click)="gridActionClicked('add', rowData)"></ppl-button>
                }

                @if(actionButtons?.refresh){
                <ppl-button ariaLabel= "Refresh" label="Refresh" icon="fa fa-refresh" [customClass]="'ghost-button'" [iconPosition]="'left'"
                  (click)="gridActionClicked('refresh', rowData)"></ppl-button>
                }

                @if(rowData.actions?.showStatus){
                <ppl-button ariaLabel="Manage Status" label="Manage Status" icon="fa fa-briefcase" [customClass]="'ghost-button'"
                  [iconPosition]="'left'" (click)="gridActionClicked('showStatus', rowData)"></ppl-button>
                }

                @if(rowData.actions?.manageConsumer){
                  <ppl-button ariaLabel="Manage Consumer" label="Manage Consumer" icon="fa fa-briefcase" [customClass]="'ghost-button'"
                    [iconPosition]="'left'" (click)="gridActionClicked('manageConsumer', rowData)"></ppl-button>
                  }

                @if(rowData.actions?.moveToPPLReview){
                <ppl-button ariaLabel="Move to PPL review" label="Move To PPL Review" icon="fa fa-arrow-circle-right" [customClass]="'ghost-button'"
                  [iconPosition]="'left'" (click)="gridActionClicked('moveToPPLReview', rowData)"></ppl-button>
                }

                @if(actionButtons?.mail){
                <ppl-button ariaLabel="Mail" label="Mail" icon="fa fa-envelope" [customClass]="'ghost-button'"
                  [iconPosition]="'left'" (click)="gridActionClicked('requestMail', rowData)"></ppl-button>
                }
              </div>
            </div>
          </td>
          }
        </tr>
        @if(showLineItem && rowData.showLineItem) {
        <tr style="background: #fff !important; border-bottom: 1px solid green;">
          <td></td>
          <td [attr.colspan]="columns.length">
            <p-table #dtLineItem [value]="rowData.line_item" [columns]="lineItemCols" styleClass="line-item-table">
              <ng-template pTemplate="header" let-ncolumns>
                @for ( ncol of ncolumns ; track ncol; ) {
          <th style="text-wrap: nowrap; padding-left: 20px !important;">
            {{ ncol.header }}
          </th>
          }
      </ng-template>
      <ng-template pTemplate="body" let-nrowData let-ncolumns="columns">
        <tr [pSelectableRow]="rowData" style="background: #fff !important; ">
          @if(rowData.line_item.length){
          @for ( ncol of ncolumns ; track ncol ) {
          <td class="table-data" [ngClass]="{'prospective': disableSort}">
            <ng-container>
              {{ nrowData[ncol.field] }}
            </ng-container>
          </td>
          }
          }
        </tr>
      </ng-template>
    </p-table>
    </td>
    </tr>
    }


    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns?.length + 1" style="text-align: center;font-weight: 700;">
          No Record Found
        </td>
      </tr>

    </ng-template>

    </p-table>

  </div>
</div>



<ppl-popup-dialog [(isVisible)]="show" [title]="'Custom Search'" [showButtons]="true" [showOkButton]="false"
  [showButtonIcons]="false" [position]="'center'" [size]="'large'" [isOkText]="'Apply'" [rightAlignedButtons]="true"
  (confirm)="applyFilter()" (cancel)="clearNotApplicableFilterRules(); filterError='' ; show = false">

  <div class="overlay-content">
    <div style="display: flex; justify-content: space-between;">
      <h3 class="header-text">Condition</h3>
      <!-- <i class="pi pi-times close-icon ppl-pointer" (click)="clearNotApplicableFilterRules();filterError='' ; show = false"></i> -->
    </div>
    <div class="condition-dropdown-container">
      <div *ngFor="let category of conditionType">
        <p-radioButton [inputId]="category.label" name="category" [value]="category.value" [(ngModel)]="condition" [ariaLabel]="category.label"/>
        <label [for]="category.value" class="ml-2">
          {{ category.label }}
        </label>
      </div>
    </div>
    <div style="display:flex; justify-content: space-between;">
      <h3 class="header-text">Enter filter parameter</h3>
      <button type="button" pButton (click)="clearAllFilters()" aria-label="Clear all filters" icon="pi pi-filter-slash" class="delete-btn"
        style="margin-bottom: 4px;">
        &nbsp;&nbsp; Clear all filters</button>
    </div>
    <div>
      @for (rule of filterRules; track rule; let idx = $index ) {
      <div class="filter-manger ant-form" [class.test]="idx === 0">
        <!-- <p-dropdown [optionLabel]="'header_label'" [optionValue]="'jsonpath'" [ngClass]="rule.column === '' && filterError ? 'error-input' : 'valid'"
              [options]="getAvailableColumn()" [optionDisabled]="'disabled'" #dropdownValue
              [(ngModel)]="rule.column" placeholder="Select Column">
            </p-dropdown> -->

        <nz-select pplNzAriaLabel nzAriaLabel="Select Column" [(ngModel)]="rule.column" class="ppl-select" nzPlaceHolder="Select Column"
          [nzStatus]="getErrorStatus(rule.column === '' && filterError)" [nzOptionOverflowSize]="4" nzAllowClear>
          @for(opt of getAvailableColumn(); track $index){
          <nz-option [nzLabel]="opt.header_label" [nzValue]="opt.jsonpath"></nz-option>
          }
        </nz-select>

        <nz-select pplNzAriaLabel nzAriaLabel="Select Filter Type" [(ngModel)]="rule.filtertype" class="ppl-select" nzPlaceHolder="Select Filter Type"
          [nzStatus]="getErrorStatus(rule.filtertype === '' && filterError)" [nzOptionOverflowSize]="4" nzAllowClear>
          @for (opt of filterTypes; track $index) {
          <nz-option [nzLabel]="opt.label" [nzValue]="opt.value"></nz-option>
          }
        </nz-select>

        <!-- <p-dropdown [options]="filterTypes" [(ngModel)]="rule.filtertype" [ngClass]="rule.filtertype === '' && filterError ? 'error-input' : 'valid'"
              placeholder="Select Filter Type">
            </p-dropdown> -->

        <!-- <input display="menu" pInputText type="text" class="search-input" [(ngModel)]="rule.value"
              placeholder="Enter Value" [ngClass]="rule.value === '' && filterError ? 'error-input' : 'valid'"/> -->

        <input nz-input [(ngModel)]="rule.value" placeholder="Enter Value"
          [nzStatus]="getErrorStatus(rule.value === '' && filterError)" />

        <!-- <p-button (onClick)="removeRule(idx, $any(dropdownValue.value))" icon="pi pi-trash" class="hammer-btn"></p-button>
            <p-button (onClick)="addRule()" icon="pi pi-plus"></p-button> -->
        <button aria-label="Remove rule" type="button" pButton (click)="removeRule(idx)" icon="pi pi-trash" class="delete-btn"></button>
        <button aria-label="Add rule" type="button" pButton (click)="addRule()" icon="pi pi-plus" class="delete-btn"></button>
      </div>
      }
    </div>

    <div>
      <h3 class="header-text">Sort By</h3>
      <div class="sortBy ant-form">

        <nz-select 
          pplNzAriaLabel 
          nzAriaLabel="Column Name" 
          [(ngModel)]="columnName" 
          class="ppl-select" 
          nzPlaceHolder="Column Name" 
          [nzOptionOverflowSize]="4"
          nzAllowClear>
          @for (opt of getAvailableColumn(); track $index) {
          <nz-option [nzLabel]="opt.header_label" [nzValue]="opt.jsonpath"></nz-option>
          }
        </nz-select>

        <nz-select pplNzAriaLabel nzAriaLabel="Sort by Ascending or Descending" [(ngModel)]="sortBy" class="ppl-select" nzPlaceHolder="Select Sort By" [nzOptionOverflowSize]="4"
          nzAllowClear>
          @for (opt of sortByOptions; track $index) {
          <nz-option [nzLabel]="opt.label" [nzValue]="opt.value"></nz-option>
          }
        </nz-select>

        <!-- <p-dropdown [optionLabel]="'header_label'" [optionValue]="'jsonpath'" [(ngModel)]="columnName"
              [options]="getAvailableColumn()" placeholder="Column Name">
            </p-dropdown> -->
        <!-- <p-dropdown [options]="sortByOptions" [(ngModel)]="sortBy" placeholder="Select Sort By">
            </p-dropdown> -->
      </div>
    </div>

    <div class="filter-error">
      <span class="text-danger" *ngIf="filterError">{{filterError}}</span>
    </div>

  </div>

</ppl-popup-dialog>
<!-- <p-overlayPanel class="overlay" #filterpanel [dismissable]="false">
  
</p-overlayPanel> -->
<!-- <ppl-notification-sms [show]="showSMSModal" (onHandleCancel)="onHandleCancel($event)" [notificationType]="notificationType"
[sourcePage]="jsonList" [selectedCheckBoxValue]="selectedCheckBoxValue"></ppl-notification-sms> -->