import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { DeleteAssociationModel, ParticipantAssociationData } from '../shared/interfaces/participant-association.model';
import { ERROR_MESSAGES } from '../shared/constants/error-message.constants';
import { ENDPOINTS } from '../shared/constants/url.constants';
import { GlobalErrorHandlerService } from '../shared/services/global-error-handler.service';
import { HttpRequestService } from '../shared/services/http-request.service';
import { NotificationService } from '../shared/services/notification.service';
import { formatUrl } from '../shared/utils/format-url-util';
import { FileTriageData } from '../interfaces/fileTriage.interface';
import { AssignFileTriagePayload } from '../components/fileTriage/file-triage-list/file-triage-list.component';
import { ApiResponse } from '../shared/interfaces/response.interface';
import { ProviderEnrollmentDataResponse } from '../shared/interfaces/provider.model';

@Injectable({
  providedIn: 'root',
})
export class EnrollmentDataService {

  private participantEnrlAssoc$ = new BehaviorSubject<any>(false);
  
  constructor(
    private http: HttpRequestService,
    private errorHandler: GlobalErrorHandlerService,
    private notificationService : NotificationService
  ) {}

  setParticipantAssocChanged(newStatus: boolean): void {
    this.participantEnrlAssoc$.next(newStatus)
  }

  getParticipantAssocChanged(): Observable<any> {
    return this.participantEnrlAssoc$.asObservable();
  }

  getAssociationsDetails(program_code: string, participant_lead_id: string | number, isParticipant: boolean, isWriteRequest = false): Observable<ProviderEnrollmentDataResponse | object> {
    console.log('lead_id', participant_lead_id);
    const url = formatUrl(
      isParticipant 
      ? ENDPOINTS.ENROLLMENT.GET_ENROLLMENT_ASSOCIATIONS_DETAILS 
      : ENDPOINTS.ENROLLMENT.GET_PROVIDER_ASSOCIATIONS_DATA,
      { program_code, participant_lead_id }
    );
    console.log(url);
    return this.http.get(url, isWriteRequest).pipe(
          catchError((err) => {
            return throwError(() => this.errorHandler.handleError(err));
          })
        );
    // if(isParticipant) {
    //   return this.http.get(url).pipe(
    //     catchError((err) => {
    //       return throwError(() => this.errorHandler.handleError(err));
    //     })
    //   );
    // } else {
    //   let payload = {
    //     programCode: program_code, providerId: lead_id
    //   }
    //   return this.http.post(url, payload).pipe(
    //     catchError((err) => {
    //       return throwError(() => this.errorHandler.handleError(err));
    //     })
    //   );
    // }

  }

  fetchParticipantAssociations(data: any) {
    const url = formatUrl(ENDPOINTS.ENROLLMENT.GET_PARTICIPANT_ASSOCIATION, {});
    return this.http.post<ApiResponse<ParticipantAssociationData>>(url, data).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  saveParticipantAssociations(data: any, isWriteRequest = false) {
    const url = formatUrl(ENDPOINTS.ENROLLMENT.SAVE_PARTICIPANT_ASSOCIATIONS, {});
    return this.http.post(url, data, isWriteRequest).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  fetchProviderAssociations(program_code: string, lead_id: any) {
    const url = formatUrl(ENDPOINTS.ENROLLMENT.GET_PROVIDER_ASSOCIATIONS, {program_code, lead_id});
    return this.http.get(url).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  getAssociationStatus(program_code: string, associationId: string) {
    const url = formatUrl(ENDPOINTS.ENROLLMENT.GET_PROVIDER_PARTICIAPNT_ASSOCIATIONS_STATUS, {program_code, associationId});
    return this.http.get(url).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  createEnrollmentStatus(data: any, program_code: string, associationId: string, rfrwRequest = false) {
    const url = formatUrl(ENDPOINTS.ENROLLMENT.CREATE_PROVIDER_PARTICIAPNT_ASSOCIATIONS_STATUS, {program_code, associationId});
    return this.http.post(url, data, rfrwRequest).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_PARTICIPANT_ENROLLMENT_STATUS_SAVE
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  updateEnrollmentStatus(data: any, program_code: string, associationId: string): Observable<any> {
    const url = formatUrl(ENDPOINTS.ENROLLMENT.UPDATE_PROVIDER_PARTICIAPNT_ASSOCIATIONS_STATUS, { program_code, associationId });

    return this.http.patch(url, data).pipe(
      tap(() => { 
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_PARTICIPANT_ENROLLMENT_STATUS_UPDATE
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  deleteParticipantProviderAssociation(association : DeleteAssociationModel,  rfrwRequest = false){
    const url = formatUrl( ENDPOINTS.ENROLLMENT.DELETE_PARTICIPANT_PROVIDER_ASSOCIATION, {})
    return this.http.post(url, association, rfrwRequest).pipe(
      tap(() => {
        this.notificationService.success("Success", ERROR_MESSAGES.SUCCESS_PARTICIPANT_PROVIDER_ASSOC);
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  getFileTriageList(program_code: string) {
    const url = formatUrl(ENDPOINTS.ENROLLMENT.GET_FILE_TRIAGE, {program_code});
    return this.http.get<FileTriageData[]>(url).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  assignFileTriage(fileTriageAssignments: AssignFileTriagePayload) {
    const url = formatUrl(ENDPOINTS.ASSIGN_FILE_TRIAGE, {});
    return this.http.post(url, fileTriageAssignments, true).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }
}
