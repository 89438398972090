{
	"columns":[
		{
			"header_id": "referralTrackingNumber",
			"header_label": "Tracking Number",
			"jsonpath":  "referralTrackingNumber" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "participant_ppl_id",
			"header_label": "PPL ID",
			"jsonpath":  "participant_ppl_id" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "type",
			"header_label": "Type",
			"jsonpath":  "type" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "n"
		},
		{
			"header_id": "firstName",
			"header_label": "First Name",
			"jsonpath":  "firstName" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "middleName",
			"header_label": "Middle Name",
			"jsonpath":  "middleName" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "n"
		},
		{
			"header_id": "lastName",
			"header_label": "Last Name",
			"jsonpath":  "lastName" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "ssn",
			"header_label": "SSN",
			"jsonpath":  "ssn" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "cin",
			"header_label": "CIN",
			"jsonpath":  "cin" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "dateOfBirth",
			"header_label": "Date of Birth",
			"jsonpath":  "dateOfBirth" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "gender",
			"header_label": "Gender",
			"jsonpath":  "gender" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "n"
		},
		{
			"header_id": "maiden_previous",
			"header_label": "Maiden previous",
			"jsonpath":  "maiden_previous" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "applicationStatus",
			"header_label": "Application Status",
			"jsonpath":  "applicationStatus" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "applicationSubmissionDate",
			"header_label": "Submission Date",
			"jsonpath":  "applicationSubmissionDate" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		
		{
			"header_id": "id",
			"header_label": "id",
			"jsonpath":  "id" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "mcoOrgName",
			"header_label": "MCO",
			"jsonpath":  "mcoOrgName" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		},
		{
			"header_id": "fiOrgName",
			"header_label": "CDPAP Facilitator",
			"jsonpath":  "fiOrgName" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		},
		{
			"header_id": "currentFI",
			"header_label": "Current FI",
			"jsonpath":  "currentFI" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		},
		{
			"header_id": "county",
			"header_label": "County",
			"jsonpath":  "county" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		}
    ],
	"buttons": [
		
    ],
    "apiDetails": "globalSearch.json",
	"pageName": "globalconsumerlist",
	"orderByColumn": ["referralTrackingNumber"],
	"orderBy": ["desc"]
}