import { TableRowActionsModel } from "./list.interface";

const noActions: TableRowActionsModel = {
    edit: false,
    delete: false,
    view: false,
    download: false,
    verify: false,
    add: false,
    refresh: false,
    mail: false,
}

export class TableRowActions {
    edit  = false;
    delete  = false;
    view  = false;
    download  = false;
    verify = false;
    add = false;
    refresh = false;
    mail = false;
    constructor(actions?: Partial<TableRowActionsModel>) {
        let newActions = { ...noActions }
        if (actions) newActions = { ...newActions, ...actions };

        this.edit = newActions.edit;
        this.delete = newActions.delete;
        this.view = newActions.view;
        this.download = newActions.download;
        this.verify = newActions.verify;
        this.add = newActions.add;
        this.refresh = newActions.refresh;
        this.mail = newActions.mail;
    }
}