<ppl-accordion
  [title]="'Signature'"
  [isOpen]="true"
  *authorize="[pageId, tabId, 'Signature', 'section']"
>
  <section class="signature--container">
    @if (!isLoading) {

    <ppl-signature
      [showButtons]="true"
      (fieldsChanged)="fieldsChanged($event)"
      [base64Image]="imageUrl"
      [flow] = "entry"
      [isEditable]="isEditable"
    >
    </ppl-signature>

    }
  </section>
</ppl-accordion>
