/* src/app/components/core/layout/header/header.component.css */
.header-container {
  top: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  background: #004C4A;
  padding: 16px;
  box-sizing: border-box;
}
.cpyname {
  color: #FFF;
  font-size: 1.429rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.city {
  display: flex;
  align-items: center;
  gap: 8px;
}
.cityName {
  color: var(--material-theme-sys-light-on-primary, #FFF);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.profileClick {
  position: relative;
  display: flex;
  padding: 4px 4px;
  align-items: center;
  gap: 0px;
  border-radius: 44px;
  border: 2px solid rgba(255, 255, 255, 0.40);
  background: rgba(255, 255, 255, 0.20);
}
.profileClick:hover {
  cursor: pointer;
}
.header-right {
  display: flex;
  align-items: center;
  gap: 12px;
}
.header-left {
  display: flex;
  align-items: center;
  cursor: pointer !important;
  border: inherit;
  background: inherit;
  padding: 0;
}
.logo {
  height: 32px;
  margin-right: 20px;
}
.dropdown-menu {
  position: absolute;
  left: -189px;
  top: 42px;
  display: flex;
  width: 250px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 12px;
  border: 2px solid #ECECEC;
  background: rgba(234, 234, 234, 0.46);
  box-shadow: 0px 0px 104px 0px rgba(0, 0, 0, 0.10) inset, -4px 4px 24px 0px rgba(0, 0, 0, 0.45);
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  z-index: 1000;
}
.dropdown-menu::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 208px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgb(244, 244, 244, 0.86);
}
.dropdown-menu button {
  display: block;
  width: 100%;
  padding: 10px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.143rem;
}
.dropdown-menu button:hover {
  background-color: #f1f1f1;
}
.profilelogo {
  width: 32px;
  height: 32px;
  padding: 12px;
  background-color: #10B8AD;
  border-radius: 108px;
  border: 1px solid #018f85;
}
.profileNameDes {
  display: flex;
  align-items: stretch;
  align-self: stretch;
  gap: 8px;
  flex-direction: column;
}
.profileName {
  color: #2E2E2E;
  font-size: 1.429rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.profileDes {
  color: #444;
  font-family: Outfit;
  font-size: 1.143rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.profile {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.line {
  width: 100%;
  height: 3px;
  background: #11B8AE;
}
.action {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
/*# sourceMappingURL=header.component-PE4DVZ4E.css.map */
