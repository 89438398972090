import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { NgZorroModule } from '../../modules/ng-zorro/ng-zorro.module';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import { NotificationService } from '../../services/notification.service';

enum EditMode {
  Edit = 'edit',
  Read = 'read'
}
@Component({
  selector: 'ppl-signature',
  standalone: true,
  imports: [CommonModule,
    NgZorroModule, FormsModule, ButtonComponent],
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css'],
})
export class LeadSignatureComponent implements AfterViewInit, OnInit{
  @ViewChild('canvas', { static: false }) canvasRef!: ElementRef<HTMLCanvasElement>;
  @ViewChild('signatureContainer') signatureContainerRef!: ElementRef<HTMLDivElement>;
  private signaturePad!: SignaturePad;
  isSignatureButtonDisabled = false;
  @Input() name = '';
  @Input() isSignatureGenerated = false;
  showSignaturePad = false;
  @Input() showInputFields = false;
  @Input() showButtons = true;
  @Input() base64Image = '';
  @Input() flow = '';
  @Input() isEditable = true;
  displayNote = false;

  @Output() signatureSaved = new EventEmitter<string>();
  @Output() signatureCleared = new EventEmitter<void>();
  @Output() errorThrown = new EventEmitter<string>();
  @Output() fieldsChanged = new EventEmitter<any>();

  signaturePadDimensions = {
    width: 70,
    height: 20,
  }

  public EDIT_MODE = EditMode;
  editMode = EditMode.Read;

  constructor(private notificationService: NotificationService){

  }

  ngOnInit(): void {
    this.displayNote = this.flow.includes('participant');
  }

  ngAfterViewInit(): void {
    this.initSignaturePad();
  }

  initSignaturePad() {
    setTimeout(() => {
      if (!this.signatureContainerRef) return;
      const containerWidth = this.signatureContainerRef.nativeElement.offsetWidth;
      this.updateSignaturePadDimensionsByAspectRatio(containerWidth);
      const canvas = this.canvasRef.nativeElement;
      this.signaturePad = new SignaturePad(canvas);
      if (this.base64Image) {
        const { width, height } = this.signaturePadDimensions;
        this.signaturePad.fromDataURL(this.base64Image, { width, height });
      }
    }, 0);
  }

  displayInputFields() {
    this.name = '';
    this.clearSignature();
    this.showInputFields = true;
  }

  updateSignaturePadDimensionsByAspectRatio(widthOfContainer: number) {
    const aspectRatio = 2 / 7;
    this.signaturePadDimensions.height = widthOfContainer * aspectRatio;
    this.signaturePadDimensions.width = widthOfContainer;
  }

  generateSignature() {
    this.clearSignature(true);
    this.isSignatureButtonDisabled =  true;
    this.showSignaturePad = true;
    if (!this.name || this.name.trim() === '') {
      this.notificationService.alert("error", "First Name","Please enter a name first.");
      return;
    }
    setTimeout(() => {
      this.fitNameToCanvas();
      this.isSignatureGenerated = true;
    }, 0);
  }

  fitNameToCanvas() {
    const canvas = this.canvasRef.nativeElement;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    let fontSize = 120;
    ctx.font = `italic ${fontSize}px "Brush Script MT", cursive`;
    let textWidth = ctx.measureText(this.name).width;

    while (textWidth > canvas.width - 80) { // 80 = 40px padding on each side
      fontSize--;
      ctx.font = `italic ${fontSize}px "Brush Script MT", cursive`;
      textWidth = ctx.measureText(this.name).width;
    }
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Center text vertically + horizontally
    const textX = (canvas.width - textWidth) / 2;
    const textMetrics = ctx.measureText(this.name);
    const ascent = textMetrics.actualBoundingBoxAscent || fontSize * 0.8;
    const descent = textMetrics.actualBoundingBoxDescent || fontSize * 0.2;
    const textY = (canvas.height + ascent - descent) / 2;
    ctx.fillText(this.name, textX, textY);
  }

  clearSignature(isRegenerateSignature?: boolean) {
    if (this.signaturePad) {
      this.signaturePad.clear();
    } else if (this.canvasRef) {
      const canvas = this.canvasRef.nativeElement;
      const ctx = canvas.getContext('2d');
      ctx?.clearRect(0, 0, canvas.width, canvas.height);
    }
    if(this.isSignatureGenerated && !isRegenerateSignature){
      this.name = '';
    }
    this.isSignatureGenerated = false;
    this.signatureCleared.emit(); 
    this.isSignatureButtonDisabled = false;
    this.resetSignaturePad();
  }

  resetSignaturePad() {
    setTimeout(() => {
      const canvas = this.canvasRef.nativeElement;
      this.signaturePad = new SignaturePad(canvas);
    }, 0);
  }
  
  saveSignature(): void {
    if (this.showSignaturePad) {
      if (this.signaturePad?.isEmpty()) {
        alert('Please provide a signature first.');
        return;
      }
    } else if (!this.isSignatureGenerated) {
      alert('Please generate a signature first.');
      return;
    }
    if (!this.name || this.name.trim() === '') {
      alert("Please enter a name first.");
      return;
    }
  
    const canvas = this.canvasRef.nativeElement;
    const dataURL = canvas.toDataURL('image/png');
    this.downloadImage(dataURL, 'signature.png');
    this.signatureSaved.emit(dataURL);
  }

  emitSignature(){
    if(!this.isSignatureGenerated) {
      if (!this.signaturePad || this.signaturePad?.isEmpty()) {
        this.notificationService.alert("error", "No Signature","Please provide a signature first.");
        return;
      }
    }
    const canvas = this.canvasRef.nativeElement;
    const dataURL = canvas.toDataURL('image/png');
    this.fieldsChanged.emit({ isEntered: true, name: this.name, dataURL });
  }

  cancel() {
    this.fieldsChanged.emit({ isEntered: false})
  }
  
  drawSignature() {
    this.isSignatureButtonDisabled = false;
    this.name = '';
    this.clearSignature();
    this.showInputFields = false;
    this.showSignaturePad = true;

    setTimeout(() => {
      const canvas = this.canvasRef.nativeElement;
      this.signaturePad = new SignaturePad(canvas);
    }, 0);
  }

  downloadImage(data: string, filename: string) {
    const link = document.createElement('a');
    link.href = data;
    link.download = filename;
    link.click();
  }

  setEditMode(mode: EditMode) {
    if (mode === EditMode.Edit) {
      this.initSignaturePad();
    }
    this.editMode = mode;
  }
}
