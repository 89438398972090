import { Component, OnInit } from '@angular/core';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzInputModule } from 'ng-zorro-antd/input';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CommonModule } from '@angular/common';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import {
  RADIO_YES_NO_OPTIONS,
  STATIC_JSON_DATA_PATH,
} from '../../../../shared/constants/static-data.constants';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { SectionsService } from '../../../../services/sections.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { Router } from '@angular/router';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { AccordionComponent } from '../../../../shared/components/accordion/accordion.component';
import { ListTableComponent } from '../../../../shared/components/list-table/list-table.component';
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { LookupValueItem } from '../../../../interfaces/lookup.interface';
import { TableRowActionsModel } from '../../../../shared/interfaces/list.interface';
import { TableRowActions } from '../../../../shared/interfaces/actions';
import { ClaimsService } from '../../../../services/claims.service';
import { DateTimeFormatterService } from '../../../../shared/utils/date-time-formatter.service';

@Component({
  selector: 'ppl-add-service-code-rate-details',
  standalone: true,
  imports: [
    ButtonComponent,
    NzFormModule,
    ReactiveFormsModule,
    FormsModule,
    NzCardModule,
    NzInputModule,
    NzToolTipModule,
    CommonModule,
    NzDatePickerModule,
    NzToolTipModule,
    NzSelectModule,
    NzButtonModule,
    NzCollapseModule,
    AccordionComponent,
    ListTableComponent,
    NgZorroModule,
  ],
  templateUrl: './add-service-code-rate-details.component.html',
  styleUrl: './add-service-code-rate-details.component.css',
})
export class AddServiceCodeRateDetailsComponent
  extends BaseComponent
  implements OnInit
{
  serviceCodeRateForm!: FormGroup;
  radioOptions = RADIO_YES_NO_OPTIONS;
  pageTitle = 'Add New Service Code';
  mode = 'edit';
  rateDetailsmode = 'read';
  savedData: any = {};
  isNew = true;
  programName!: string;
  columnsToBeHidden: any;
  sharedDataKey: any;
  actionsToBeHidden: any;
  lazyLoad = true;
  rateTypes: LookupValueItem[] = [];
  program: any;
  cols: any;
  rateId: any;
  actionButtons: TableRowActionsModel = new TableRowActions();
  gridData: any = [];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private sectionsService: SectionsService,
    private claimsService: ClaimsService,
    private dateTimeFormatterService: DateTimeFormatterService
  ) {
    super();
  }

  async ngOnInit() {
    this.serviceCodeRateForm = this.fb.group({
      effectiveFromDate: [null],
      effectiveEndDate: [null],
      rateTypeLkp: [null],
      minRate: [{ value: null, disabled: true }],
      defaultRate: [{ value: null, disabled: true }],
      percentBilled: [{ value: null, disabled: true }],
    });

    this.sectionsService.setFlow('serviceCode');
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    this.rateTypes = this.getLookup('Rate_Type');
    this.actionButtons.edit = true;
    this.secData = this.sectionsService.getSectionData(
      this.program.program_code,
      'payroll'
    );
    await this.getFromData();

    this.claimsService
      .getServiceCodeRates(
        this.program.program_code,
        localStorage.getItem('selected_id')
      )
      .subscribe((data: any) => {
        this.gridData = data.responsedata;
      });

    this.programName = this.program.program_code;
    this.serviceCodeRateForm
      .get('rateTypeLkp')
      ?.valueChanges.subscribe((value) => {
        this.resetFields();
        if (value === 'NGTB') {
          this.serviceCodeRateForm.get('minRate')?.enable();
          this.serviceCodeRateForm.get('defaultRate')?.disable();
          this.serviceCodeRateForm.get('percentBilled')?.disable();
        } else if (value === 'SURT') {
          this.serviceCodeRateForm.get('defaultRate')?.disable();
          this.serviceCodeRateForm.get('minRate')?.disable();
          this.serviceCodeRateForm.get('percentBilled')?.enable();
        } else if (value === 'DFLT') {
          this.serviceCodeRateForm.get('defaultRate')?.enable();
          this.serviceCodeRateForm.get('minRate')?.disable();
          this.serviceCodeRateForm.get('percentBilled')?.disable();
        } else {
          this.serviceCodeRateForm.get('percentBilled')?.disable();
          this.serviceCodeRateForm.get('minRate')?.disable();
          this.serviceCodeRateForm.get('defaultRate')?.disable();
        }
      });
  }

  resetFields(): void {
    this.serviceCodeRateForm.patchValue({
      minRate: null,
      percentBilled: null,
      defaultRate: null
    });
  }

  returnClicked() {
    this.router.navigate(['/home/serviceCodeList']);
  }

  disabledDates = (startValue: Date): boolean => {
    const serviceCodes = this.claimsService.getFormData('service_code');
    const endDate = new Date(serviceCodes.effectiveEndDate);
    const startDate = new Date(serviceCodes.effectiveFromDate);
    if (!startValue || !endDate || !(endDate instanceof Date) || !startDate || !(startDate instanceof Date)) {
      return false;
    }
    return !(startValue.getTime() > startDate.getTime() && startValue.getTime() < endDate.getTime());
  };

  disabledStartDate = (startValue: Date): boolean => {
    const effectiveEndDate = this.serviceCodeRateForm.get('effectiveEndDate')?.value;
    if (!startValue || !effectiveEndDate) {
        return false;
    }
    const endDate = new Date(effectiveEndDate);
    const isDisabledBasedOnFormEndDate = startValue.getTime() > endDate.getTime();
    return isDisabledBasedOnFormEndDate || this.disabledDates(startValue);
  };

  disabledEndDate = (startValue: Date): boolean => {
    const effectiveFromDate = this.serviceCodeRateForm.get('effectiveFromDate')?.value;
    if (!startValue || !effectiveFromDate) {
        return false;
    }
    const endDate = new Date(effectiveFromDate);
    const isDisabledBasedOnFormEndDate = startValue.getTime() <= endDate.getTime();
    return isDisabledBasedOnFormEndDate || this.disabledDates(startValue);
  };

  goBack() {
    this.router.navigate(['/home/serviceCodeList']);
  }

  async getFromData() {
    const entry = localStorage.getItem('lead_entry')!;
    try {
      if (entry) {
        const tempCols = await this.sectionsService.getJsonConfigurationData(
          STATIC_JSON_DATA_PATH.SERVICE_CODE_RATES_LIST_HEADERS,
          entry
        );
        this.cols = tempCols.columns;
      }
    } catch (e) {
      console.error('Error in getting column data', e);
    }
  }

  onEdit() {
    this.mode = 'edit';
  }

  onAdd() {
    this.serviceCodeRateForm.reset();
    this.rateDetailsmode = 'new';
    this.isNew = true;
  }

  onCancel() {
    this.rateDetailsmode = 'read';
    this.validationErrors = {};
    this.serviceCodeRateForm.reset();
  }

  onSave() {
    const payload = {
      ...this.serviceCodeRateForm.value,
      programCode: this.program.program_code,
      serviceCodeDetailId: localStorage.getItem('selected_id'),
      effectiveFromDate: this.dateTimeFormatterService.formatToString(
        new Date(this.serviceCodeRateForm.value.effectiveFromDate)
      ),
      effectiveEndDate: this.dateTimeFormatterService.formatToString(
        new Date(this.serviceCodeRateForm.value.effectiveEndDate)
      ),
    };
    this.claimsService.addServiceCodeRates(payload).subscribe((data: any) => {
      if (data.status == 200) {
        this.claimsService
          .getServiceCodeRates(
            this.program.program_code,
            localStorage.getItem('selected_id'),
            true
          )
          .subscribe((data: any) => {
            this.gridData = data.responsedata;
            this.rateDetailsmode = 'read';
            this.isNew = false;
          });
      }
    });
  }

  onUpdate() {
    const payload = {
      ...this.serviceCodeRateForm.value,
      programCode: this.program.program_code,
      serviceCodeDetailId: localStorage.getItem('selected_id'),
      effectiveFromDate: this.dateTimeFormatterService.formatToString(
        new Date(this.serviceCodeRateForm.value.effectiveFromDate)
      ),
      effectiveEndDate: this.dateTimeFormatterService.formatToString(
        new Date(this.serviceCodeRateForm.value.effectiveEndDate)
      ),
    };
    this.claimsService
      .updateServiceCodeRates(payload, this.rateId)
      .subscribe((data) => {
        if (data.status === 200) {
          this.claimsService
            .getServiceCodeRates(
              this.program.program_code,
              localStorage.getItem('selected_id')
            )
            .subscribe((data) => {
              this.gridData = data.responsedata;
              this.rateDetailsmode = 'read';
              this.isNew = false;
            });
        }
      });
  }

  action(event: any) {
    if (event && event.action === 'edit') {
      this.rateDetailsmode = 'edit';
      this.isNew = false;
      const data = {
        ...event.data,
        effectiveFromDate: this.formatDateString(event.data.effectiveFromDate),
        effectiveEndDate: this.formatDateString(event.data.effectiveEndDate)
      }
      this.serviceCodeRateForm.patchValue(data);
      this.rateId = event.data.serviceCodeXRatesId;
    }
  }
}
