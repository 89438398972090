<div class="custom-row">
  @if(mode==="edit"){
      <ppl-button 
      label="Cancel" 
      icon="fa fa-ban" 
      [customClass]="'alt-button'" 
      [iconPosition]="'left'"
      (click)="cancelEdit()"
      ></ppl-button>    

      <ppl-button 
      label="Save" 
      icon="fa fa-floppy-o" 
      [customClass]="'alt-button'" 
      [iconPosition]="'left'"
      (click)="save()"
      ></ppl-button>
    <!-- <span (click)="cancelEdit()" nz-icon nzType="close-circle" nzTheme="outline"></span>
    <span (click)="save()" nz-icon nzType="check-circle" nzTheme="outline"></span> -->
    }
  @if(mode==="read" && leadStatus !== approvedStatus){
    <!-- <span (click)="editInfo()" nz-icon nzType="edit" nzTheme="outline"></span> -->
    <ppl-button 
    label="Edit" 
    icon="fa fa-pencil" 
    [customClass]="'ghost-button'" 
    [iconPosition]="'left'"
    (click)="editInfo()"
    ></ppl-button> 
  }
  
</div>

<!-- Edit Mode -->
@if(mode==="edit"){ 
  <form nz-form [formGroup]="servicesForm" class="ppl-p0">
    <nz-form-item class="ppl-form-item">
      <nz-form-label nzSpan="4" nzFor="profLicences" class="ppl-form-label">
        {{getAttributes('profLicences').label}}
        @if(getAttributes('profLicences').mandatory === 'Y'){
          <span>*</span>
        }
      </nz-form-label>
      <nz-form-control nzSpan="8">
        <div nz-tooltip [nzTooltipTitle]="getAttributes('profLicences')?.tooltip || ''" nzTooltipPlacement="topRight">
          <nz-select [nzMaxTagCount]="4" nzMode='multiple' [ngClass]="{'error': validationErrors['profLicences'] }"
            nzPlaceHolder="{{getAttributes('profLicences').placeholder}}" id="profLicences"
            formControlName="profLicences" style="width:100%; margin-bottom: 16px" nzShowArrow>
            @for(opt of licences; track $index){
              <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
            }
          </nz-select>
        </div>
        @if (validationErrors['profLicences']) {
          <span class="pl-1 error-message">{{validationErrors['profLicences']}}</span>
        }
      </nz-form-control>
    </nz-form-item>
  </form>
}

<!-- Read Mode -->
@else if(mode==="read" && secData){
  @if(isAttrAvailable('profLicences') && filledInfo){
    <div nz-row>
      <div nz-col nzSpan="4" class="ppl-read-label">{{getAttributes('profLicences').label}}</div>
      <div nz-col>
        @for (file of filledInfo; track $index) {
          {{getLookupValue(licences, file.licenseType)}}@if(!$last){, }       
        }
      </div>
    </div>
  }@else {
    <nz-row>
      <p>No Data found</p>
    </nz-row>
  }
}