<div [ngClass]="{'ppl-mt24': source !== 'enrollment'}">
  <nz-card nz-col [nzSpan]="source !== 'enrollment' ? 20 : 24" class="ppl-p24 ppl-step-main" [nzBordered]="false">
    <form nz-form [formGroup]="servicesForm" class="ppl-p0">
      <nz-row>
        <div nz-col nzSpan="12" nz-flex>
          @if(isAttrAvailable('profLicences')){
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="profLicences"
              class="ppl-form-label">{{getAttributes('profLicences').label}}
              @if(getAttributes('profLicences').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control class="">
              <div nz-tooltip [nzTooltipTitle]="getAttributes('profLicences')?.tooltip || ''"
                nzTooltipPlacement="topRight">
                <nz-select [nzMaxTagCount]="4" nzMode='multiple'
                  [ngClass]="{'error': validationErrors['profLicences'] }"
                  nzPlaceHolder="{{getAttributes('profLicences').placeholder}}" id="profLicences"
                  formControlName="profLicences" style="width:100%; margin-bottom: 16px" nzShowArrow [nzDisabled]="formReadonly">
                  @for(opt of licences; track $index){
                  <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                  }
                </nz-select>
              </div>
              @if (validationErrors['profLicences']) {
              <span class="pl-1 error-message">{{validationErrors['profLicences']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
          }
        </div>
        <div nz-col nzSpan="12" nz-flex>
          @if(isAttrAvailable('servicesRendered')){
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="servicesRendered"
              class="ppl-form-label">{{getAttributes('servicesRendered').label}}
              @if(getAttributes('servicesRendered').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control class="ppl-form-control">
              <nz-select [nzMaxTagCount]="4" nzMode='multiple'
                [ngClass]="{'error': validationErrors['servicesRendered'] }"
                nzPlaceHolder="{{getAttributes('servicesRendered').placeholder}}" id="servicesRendered"
                formControlName="servicesRendered" style="width:100%; margin-bottom: 16px" nzShowArrow [nzDisabled]="formReadonly">
                @for(opt of services; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
              @if (validationErrors['servicesRendered']) {
              <span class="pl-1 error-message">{{validationErrors['servicesRendered']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
          }
        </div>
      </nz-row>

      <!-- @if(isAttrAvailable('filesUploaded')){
        <nz-form-item class="ppl-form-item pt-2">
          <nz-form-label [nzSm]="9">
          </nz-form-label>
          <nz-form-control class="ppl-form-control">
            <p style="font-size:0.929rem;">Please upload supporting documents</p>
            <ppl-upload (filesUploaded)="filesUploaded($event)" [fileList]="fileList"
            [docTypeList] = "licences" [flow]="'provider'" [leadId]="''" [showFileList]="true"></ppl-upload>
          </nz-form-control>
        </nz-form-item>
        } -->

    </form>
  </nz-card>
</div>