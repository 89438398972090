/* src/app/components/lead-details/member-view/member-view.component.css */
[nz-icon] {
  margin-right: 6px;
  font-size: 1.429rem;
  color: #3c86f5;
}
.drop-btn {
  cursor: pointer;
  padding: 7px;
  border-radius: 4px;
  margin-left: 0;
  border: 1px solid #cdc7c7;
  font-size: 1rem;
}
.action {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.actGRP {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.line1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.ico {
  filter: invert(0.75);
}
.ico:hover {
  cursor: pointer;
}
img {
  filter: invert(0.75);
}
.profile {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #000000;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
}
.line2 {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  color: var(--black-700-black, #121212);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Outfit;
  font-size: 2.286rem;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 24px;
}
/*# sourceMappingURL=member-view.component-CUIU3HAG.css.map */
