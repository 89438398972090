import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { SectionsService } from '../../../services/sections.service';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ListService } from '../../services/list.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'ppl-message',
  standalone: true,
  imports: [NzCardModule,
    NzButtonModule,
    NzIconModule
  ],
  providers: [ListService],
  templateUrl: './message.component.html',
  styleUrl: './message.component.css'
})
export class MessageComponent implements OnInit {
  trackingNumber = "";
  id?: string = "";
  flow: string | null = null;

  constructor(private router: Router, private sectionsService: SectionsService, private listService: ListService,
    private notificationService : NotificationService
  ) {
  }

  onClose() {
    let path;
    if (!this.flow) return;
    if (this.flow.includes('participant')) {
      path = '/home/participantLeadsList';
    } else if (this.flow.includes('provider')) {
      path = '/home/providerLeadsList';
    } else {
      path = '/home/referralList';
    }
    this.router.navigateByUrl(path);
  }

  ngOnInit(): void {
    this.trackingNumber = history.state.num;
    this.id = history.state.id;
    this.flow = this.sectionsService.getFlow();
  }

  sendComm(type: string) {
    const program = JSON.parse(localStorage.getItem('selected_program')!);
    const message = `Thank you for your submission! Your tracking number is ${this.trackingNumber}`;
    const payload = {
      "communicationType": type.toUpperCase(),
      "program": program.program_code,
      "leadIds": [
         this.id?.toString()
      ],
      "type":"referral",
      "message": message
  }
    this.listService.sendNotification('confirmation', payload).subscribe((data) => {
      if( data.status == 200 ) {
        this.notificationService.alert("success", "Success", `${type.toUpperCase()} sent successfully.`);
      }
    });
  }

}
