import { Component } from '@angular/core';
import { STATIC_DATA } from '../../../shared/constants/static-data.constants';
import { ParticipantLeadsListComponent } from '../../participant-leads-list/participant-leads-list.component';
import { PAGE_CONSTANTS } from '../../../shared/constants/page.constants';
import { Router } from '@angular/router';
import { SectionsService } from '../../../services/sections.service';
import { ReferralDataService } from '../../../services/referral-data.service';
import { SharedDataService } from '../../../shared/services/shared-data-service';
import { AuthorizationUtility } from '../../../shared/authorization/auth.utility';
import { AuthorizationService } from '../../../shared/authorization/authorization.service';
import { LeadDetailsService } from '../../../services/lead-details.service';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { ListTableComponent } from '../../../shared/components/list-table/list-table.component';
import { AuthorizeButtonDirective } from '../../../shared/authorization/authorizeButton.directive';

@Component({
  selector: 'ppl-global-search',
  standalone: true,
  imports: [ButtonComponent, ListTableComponent, AuthorizeButtonDirective],
  templateUrl: './global-search.component.html',
  styleUrl: './global-search.component.css',
})
export class GlobalSearchComponent extends ParticipantLeadsListComponent {
  override sharedDataKey: string =
    STATIC_DATA.SHARED_GLOBAL_CONSUMER_SEARCH_DATA;
  override pageId: string = PAGE_CONSTANTS.GLOBAL_PARTICIPANT_DATA_GRID_PAGE;

  constructor(
    router: Router,
    sectionService: SectionsService,
    referralDataService: ReferralDataService,
    sharedDataService: SharedDataService,
    authUtility: AuthorizationUtility,
    authService: AuthorizationService,
    private leadDetailsService: LeadDetailsService
  ) {
    super(
      router,
      sectionService,
      referralDataService,
      sharedDataService,
      authUtility,
      authService
    );
  }

  /* override onNewLead(): void {
    this.router.navigate(['/referralLogic']);
    this.sectionService.setFlow('providers');
    this.referralDataService.clearReferrralData();
  }

  onNewReferral() {
    this.router.navigate(['/newReferral']);
    this.sectionService.setFlow('providerEnrollment');
    localStorage.setItem('lead_entry', '');
    this.referralDataService.clearReferrralData();
  }

  onNewEnrollment() {
    this.router.navigate(['/newReferral']);
    localStorage.setItem('participantId', "");
    this.sectionService.setFlow('participantEnrollment');
    localStorage.setItem('lead_entry', '');
    this.referralDataService.clearReferrralData();
  } */

  override rowClicked(rowDetail: any) {
    const program = JSON.parse(
      localStorage.getItem(STATIC_DATA.SELECTED_PROGRAM)!
    );
    const limitedAccess = !rowDetail.hasAccess as unknown as string;

    localStorage.setItem('selected_lead', rowDetail.id);

    if (rowDetail.type.toLocaleLowerCase() === 'participant') {
      localStorage.setItem(STATIC_DATA.URL_FIRST_SEGMENT, 'participantEnrollmentList');
      localStorage.setItem('lead_entry', 'participantEnrollment');
      this.referralDataService.clearReferrralData();

      if (
        rowDetail.applicationStatus.toLocaleLowerCase() == 'new application' ||
        rowDetail.applicationStatus.toLocaleLowerCase() == 'in progress'
      ) {
        this.leadDetailsService
          .fetchLeadDetailsApi(
            'participantEnrollment',
            program.program_code,
            rowDetail.id
          )
          .subscribe((data: any) => {
            this.referralDataService.setReferralDetails(data.responsedata);

            this.sectionService.setFlow('participantEnrollment');
            localStorage.setItem(
              'participantId',
              data?.responsedata?.infoData.participantId
            );
            localStorage.setItem(
              'participantPPLId',
              data.responsedata?.infoData.participantPPLId
            );
            localStorage.setItem(
              'trackingNumber',
              data.responsedata?.infoData.trackingNumber
            );

            setTimeout(() => {
              this.router.navigate(['/newReferral']);
            }, 200);
          });
      } else {
        this.referralDataService.clearReferrralData();
        this.redirectToDetailPage(limitedAccess);
      }
    } else {
      localStorage.setItem(STATIC_DATA.URL_FIRST_SEGMENT, 'globalConsumerSearch');

      this.referralDataService.clearReferrralData();

      localStorage.setItem('lead_entry', 'referral');
      this.sectionService.setFlow('referral');

      if (
        rowDetail.applicationStatus == '' ||
        rowDetail.applicationStatus == null
      ) {
        this.leadDetailsService
          .fetchLeadDetailsApi('referral', program.program_code, rowDetail.id)
          .subscribe((data: any) => {
            this.referralDataService.setReferralDetails(data.responsedata);
            setTimeout(() => {
              localStorage.setItem(
                'referralId',
                data.responsedata?.infoData?.referralId
              );
              this.router.navigate(['/newReferral']);
            }, 200);
          });
      } else {
        setTimeout(() => {
          this.redirectToDetailPage(limitedAccess);
        }, 200);
      }
    }
  }

  redirectToDetailPage(limitedAccess: string): void {
    localStorage.setItem('limitedAccess', limitedAccess);
    setTimeout(() => {
      this.router.navigate(['/home/globalConsumerSearch/details/']);
    }, 200);
  }
}
