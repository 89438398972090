

export function performValidation(pageData: any, sectionData: any, actionName : string, pageName? : string, additionalData?: any ): any {
    

    console.log('performValidation form data =====>', pageData);
    console.log('performValidation section data ====>', sectionData);
    console.log('performValidation pageName ====>', pageName);
    console.log('performValidation additionalData ====>', additionalData);

    const customFunctionList = {
        'providerLeadValidateSSN' : (data : any) => providerLeadValidateSSN(data),
        'validateSSN': (data : any) => validateSSN(data)
    };
 
    let validationList : any[] = [];
    let actions: any[] = [];

    validationList = getValidationList(sectionData);
    actions = getActions(sectionData);

    const actionValidationList = getActionValidationList(actionName, actions);

    let validationErrorMessages : any = {}

    actionValidationList.forEach((validationActionGroup: any) => {

        switch(validationActionGroup.checkType) {
            case 'atLeastXEntered': {
                
                try{

                    const {attributes} = getAttributesForCheckType(validationActionGroup.checkType, validationList);
                
                    attributes?.forEach((attribute: any)=> {
                        const {attribute_ID, functionParams } = attribute;
                                                
                        // TODO: Perform validation based on functionParams and attribute_ID
                        let fieldData  = 0;
                        const fieldErrorMessage : any = {};
                        attribute_ID.forEach((attrId : string) => {
                                if(fieldHasData(pageData[attrId])){
                                    fieldData++;
                                    
                                }else{
                                    fieldErrorMessage[attrId]= functionParams.ErrMsg;
                                }
                        });

                        if(Object.prototype.hasOwnProperty.call(functionParams, 'minNeeded') && fieldData < functionParams.minNeeded) {
                            validationErrorMessages = {...validationErrorMessages,...fieldErrorMessage};
                        }
                        else if(!Object.prototype.hasOwnProperty.call(functionParams, 'minNeeded')){
                            validationErrorMessages = {...validationErrorMessages,...fieldErrorMessage};
                        }

                        
                    });

                }catch(e){
                    console.log('Error in performing atLeastXEntered validation', e);
                }
                
                break;
            }

            case 'checkFieldValue': { // case 'checkFieldValue'

                try{

                    const {attributes} = getAttributesForCheckType(validationActionGroup.checkType, validationList);

                    attributes?.forEach((attribute: any)=> {
                        const {attribute_ID, functionParams } = attribute;

                        // TODO: Perform validation based on functionParams and attribute_ID
                        const fieldErrorMessage : any = {};
                        attribute_ID.forEach((attrId : string) => {

                        
                            const fieldData = pageData[attrId];

                            if(Object.prototype.hasOwnProperty.call(functionParams, 'isNullAllowed') && functionParams.isNullAllowed.toLowerCase() === 'n' && fieldHasData(fieldData)){

                                if(Object.prototype.hasOwnProperty.call(functionParams, 'Maxlength') && functionParams.Maxlength > 0){
                                    if(fieldData.length > functionParams.Maxlength){
                                        fieldErrorMessage[attrId] = functionParams.ErrMsg;
                                    }
                                }
                                if(Object.prototype.hasOwnProperty.call(functionParams, 'Minlength') && functionParams.Minlength > 0){
                                    if(fieldData.length < functionParams.Minlength){
                                        fieldErrorMessage[attrId] = functionParams.ErrMsg;
                                    }
                                }

                                if(Object.prototype.hasOwnProperty.call(functionParams, 'Format') && functionParams.Format.length > 0){
                                    if(!testRegularExpression(fieldData, functionParams.Format)){
                                        fieldErrorMessage[attrId] = functionParams.ErrMsg;
                                    }
                                }

                                if (Object.prototype.hasOwnProperty.call(functionParams, 'CheckDate') && functionParams.CheckDate) {
                                    const dob = new Date(fieldData);
                                    const today = new Date();
                                    today.setHours(0, 0, 0, 0);
                        
                                    if (dob >= today) {
                                        fieldErrorMessage[attrId] = functionParams.ErrMsg;
                                    }
                                }
                            }
                        });

                        validationErrorMessages = {...validationErrorMessages,...fieldErrorMessage};
        
                    });

                }catch(e){
                    console.error('Error in performValidation validation', e);
                }

                break;
            }

            case 'conditionalCheck': { // case 'conditionalCheck'
                
                try{
                    const {attributes} = getAttributesForCheckType(validationActionGroup.checkType, validationList);

                attributes?.forEach((attribute: any)=> {
                    const { functionParams } = attribute;

                    const {basecondition, baseconditionValue, condtionalAttributes, Format, ErrMsg} = functionParams;
                    
                    // TODO: Perform validation based on functionParams and attribute_ID
                    const fieldErrorMessage : any = {};
                    let baseConditionFieldHasData  = 0;
                    
                    basecondition.forEach((attrId : string, idx : number) => {

                        // TODO: Perform data check if the baseconditionValue doesn't exists
                        if(!baseconditionValue){
                            if(fieldHasData(pageData[attrId])){
                                baseConditionFieldHasData++;
                            }
                        }

                        // TODO: Perform data check if the baseconditionValue exists
                        if(baseconditionValue){
                            if(fieldHasData(pageData[attrId]) && pageData[attrId] === baseconditionValue[idx]){
                                baseConditionFieldHasData++;
                            }
                        }

                    });

                    // if the base condition is met, validate the condtional attributes
                    if(baseConditionFieldHasData === basecondition.length ){
                        if(Format && Format.length > 0) {
                            condtionalAttributes.forEach((attrId : string) => {
                                if(fieldHasData(pageData[attrId]) && !testRegularExpression(pageData[attrId], Format)){
                                    fieldErrorMessage[attrId] = ErrMsg;
                                }
                            });
                        } else {
                            condtionalAttributes.forEach((attrId : string) => {
                                if(!fieldHasData(pageData[attrId])){
                                    fieldErrorMessage[attrId] = ErrMsg;
                                }
                            });
                        }

                    }
                    

                    // merger the validation error messages
                    validationErrorMessages = {...validationErrorMessages,...fieldErrorMessage};
    
                 });
                }catch(error){
                    console.error('Error in performing conditionalCheck validation', error);
                    
                }
                


                break;
            }

            case 'customValidator': { // case 'customFunction'

                try{
                    const {attributes} = getAttributesForCheckType(validationActionGroup.checkType, validationList);
                    
                    attributes?.forEach((attribute: any)=> {
                        const { functionParams, attribute_ID } = attribute;

                        const { validatorFunction, ErrMsg} = functionParams;
                        const fieldErrorMessage : any = {};

                        // fetch the function based on the configuration and perform validation
                        const validatorResp  = (customFunctionList as any)[validatorFunction](pageData)

                        if(validatorResp && attribute_ID.length > 0){
                            attribute_ID.forEach((ele : string) => {
                                fieldErrorMessage[ele] = ErrMsg;
                            });
                        
                        }

                        validationErrorMessages = mergeValidationErrors(validationErrorMessages, fieldErrorMessage);
                    });
                }catch(error){
                    console.error('Error in performing customValidator validation', error);
                }
                

                break;
            }

            
        }

        
    });

        console.log('validationErrorMessages ====>', validationErrorMessages);
        return validationErrorMessages;
   
}

// get actiion list for a specific action name
function getActionValidationList(actionName: string, actionList: any[]): any {
    const validations = actionList.find((action: any) => action.Name === actionName)
   if(validations){
    return validations.Validate;
   } else{
    return [];
   }
    
}


// get actions from section data
function getActions(sectionData: any): any {
    return sectionData.actions;
}


// get validations list from section data
function getValidationList(sectionData: any): any {
    return sectionData.validations;
}

// get attributes for a specific check type (atLeastXEntered) from validations list
function getAttributesForCheckType(validationType: string, validationList: any[]): any {
    
    const validations = validationList.filter((validation: any) => validation.whatToCheck === validationType)
    const attributes = {attributes : []};
    if(validations.length> 0){
        return validations[0];
    }
    return attributes

}


//check if field has the data 
function fieldHasData<T>(field: T): boolean {
    if(Array.isArray(field)) {  
        return field.length > 0;
    
    }else if(typeof field === 'string'){
        const value = field ? field?.trim() : field;
        return (value && (value !== '' || value !== null || value !== undefined) ) ? true : false;
    
    }else if(typeof field === 'number'){
        return field > 0;
    
    }else if(typeof field === 'object' && !Array.isArray(field) && field !== null && field !== undefined){

        if (field instanceof Date) {
          return true;
        } else if (field instanceof Object) {
          return Object.entries(field as object).length > 0;
        } else {
          return false;
        }
        
    }

    return false
    
}

// test regular expression function
function testRegularExpression(value: string, regexString: string): boolean {
    const regex = new RegExp(regexString)
    return regex.test(value);
}

// custom validator function for validationg ssn starting with 9
function providerLeadValidateSSN(formData: any) {
       /*  let formData  = JSON.parse(formFieldData); */
       console.log('ssn validate', formData);
    if(formData?.SSN && formData?.isProvidingServices && formData?.isProvidingServices?.toLowerCase() == 'yes'){  // Male SSN format is 999-99-9999
        const ssn = formData.SSN.replace(/-/g, '');
        const regex = new RegExp(/^9\d*/)

        if(regex.test(ssn)){
            return true ;
        }

    }
    return false;
}

function validateSSN(formData: any) {
    /*  let formData  = JSON.parse(formFieldData); */
    console.log('ssn validate', formData);
    if(formData?.SSN) {
        const ssn = formData.SSN.replace(/-/g, '');
        const regex = /^\d{9}$/;
        
        const ssnRegex = /^(?!666|000|9\d{2})(?!.*(\d)(?:-?\1){8})(?!123456789)\d{3}-(?!00)\d{2}-(?!0000)\d{4}$|^(?!.*(000|666|9\d{2})\b)(?!.*\b00\b)(?!.*0000)(?!.*(\d)(?:-?\1){8})(?!123456789)\d{3}-\d{2}-?\d{4}$/;

        // Check if ssn has exactly 9 digits first
        if (!regex.test(ssn)) {
            return true;
        }

        // Validate the ssn against the main regex
        if (!ssnRegex.test(formData.SSN)) {
            return true;
        }

    }
    return false;
}
function mergeValidationErrors(validationErrorMsgs: any, currentErrorMsgs: any): any {
    const validationErrorMessages = {...validationErrorMsgs,...currentErrorMsgs};
    return validationErrorMessages;
}
