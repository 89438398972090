import { Injectable } from '@angular/core';

export enum ThemeType {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  currentTheme = ThemeType.small; // Default to small

  constructor() {}

  private removeUnusedThemes(currentTheme: ThemeType): void {
    Object.values(ThemeType).forEach((theme) => {
      if (theme !== currentTheme) {
        document.documentElement.classList.remove(theme);
        const removedThemeStyle = document.getElementById(theme);
        if (removedThemeStyle) {
          document.head.removeChild(removedThemeStyle);
        }
      }
    });
  }

  private loadCss(href: string, id: string): Promise<Event> {
    return new Promise((resolve, reject) => {
      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.href = href;
      style.id = id;
      style.onload = resolve;
      style.onerror = reject;
  
      const head = document.head;
      const firstLinkElement = head.querySelector('link');
      if (firstLinkElement) {
        head.insertBefore(style, firstLinkElement);
      } else {
        head.appendChild(style);
      }
    });
  }

  public loadTheme(theme: ThemeType, firstLoad = true): Promise<Event> {
    this.currentTheme = theme;
    if (firstLoad) {
      document.documentElement.classList.add(theme);
    }
    return new Promise<Event>((resolve, reject) => {
      this.loadCss(`${theme}.css`, theme).then(
        (e) => {
          if (!firstLoad) {
            document.documentElement.classList.add(theme);
          }
          this.removeUnusedThemes(theme);
          resolve(e);
        },
        (e) => reject(e)
      );
    });
  }

  public setTheme(theme: ThemeType): Promise<Event> {
    return this.loadTheme(theme, false);
  }
}