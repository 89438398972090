@if(flow === 'participants' || flow === 'providers' ){
    <div class="ppl-p48 ant-menu-horizontal">
    <nz-card class="ppl-main-halfwidth" nz-col nzSpan="10">
        <div>
            <h1>Confirmation</h1>
        </div>
        <p class="ppl-gray-text ppl-mt36">
            Thank you for your submission!<br>
            Your tracking number is <b>{{trackingNumber}}</b>.<br>
            One of our team members will be in touch with you within 24 to 48 hours.
        </p>
        <button nz-button nzType="primary" class="ppl-mt36" (click)="onClose()">Close</button>
    </nz-card>
</div>
}
@else {
    <div class="ppl-p48 main-container" style="background-color: #cff1f04d">
        <div class="ppl-main-hlfwidth">
            <div>
                <h1>Confirmation</h1>
            </div>
            <p class="ppl-gray-text ppl-mt20">
                Thank you for your submission!<br>
                Your tracking number is <b>{{trackingNumber}}</b>.<br>
                How would you like the referral Consumer to receive their confirmation?
            </p>
        <nz-card class="ppl-mt8" nz-col nzSpan="10">
            <div class="option-container">
                <p tabindex="0" class="options" role="button" (click)="sendComm('email')" (keypress.enter)="sendComm('email')"><span class="btn-color" nz-icon nzType="laptop" nzTheme="outline"></span><strong>Email</strong></p>
                <p tabindex="0" class="options" role="button" (click)="sendComm('sms')" (keypress.enter)="sendComm('sms')"><span class="btn-color" nz-icon nzType="shake" nzTheme="outline" ></span><strong>SMS</strong></p>
            </div>
            <div class="button-container">
                <button nz-button nzType="primary" (click)="onClose()">Close</button>
            </div>
        </nz-card>
        </div>
    </div>
}
