import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, tap, throwError } from 'rxjs';
import { ENDPOINTS } from '../shared/constants/url.constants';
import { GlobalErrorHandlerService } from '../shared/services/global-error-handler.service';
import { NotificationService } from '../shared/services/notification.service';
import { formatUrl } from '../shared/utils/format-url-util';
import { HttpRequestService } from '../shared/services/http-request.service';

@Injectable({
  providedIn: 'root',
})
export class ClaimsService {
  formData: any = {};
  private selectedLeadId: number | null = null;
  private previousData$ = new BehaviorSubject<any>(null);

  constructor(
    private httpClient: HttpRequestService,
    private errorHandler: GlobalErrorHandlerService,
    private notificationService: NotificationService
  ) { }

  addProcessCode(payload: any): Observable<any> {
    return this.httpClient
      .post(ENDPOINTS.CLAIMS.ADD_PROCESS_CODE, payload, true)
      .pipe(
        tap(() => {
          this.notificationService.success(
            'Success',
            'Process Code Added Successfully.'
          );
        }),
        catchError((err) => {
          return throwError(() => this.errorHandler.handleError(err));
        })
      )
  }

  updateProcessCode(payload: any, id: any): Observable<any> {
    const url = formatUrl(ENDPOINTS.CLAIMS.UPDATE_PROCESS_CODE, { id });
    return this.httpClient.patch(url, payload).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          'Process Code Updated Successfully.'
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    )
  }

  addServiceCode(payload: any): Observable<any> {
    return this.httpClient
      .post(ENDPOINTS.CLAIMS.ADD_SERVICE_CODE, payload, true)
      .pipe(
        tap(() => {
          this.notificationService.success(
            'Success',
            'Service Code Added Successfully.'
          );
        }),
        catchError((err) => {
          return throwError(() => this.errorHandler.handleError(err));
        })
      );
  }

  updateServiceCode(payload: any, id: any): Observable<any> {
    const url = formatUrl(ENDPOINTS.CLAIMS.UPDATE_SERVICE_CODE, { id });
    return this.httpClient
      .patch(url, payload)
      .pipe(
        tap(() => {
          this.notificationService.success(
            'Success',
            'Service Code Updated Successfully.'
          );
        }),
        catchError((err) => {
          return throwError(() => this.errorHandler.handleError(err));
        })
      );
  }

  getServiceCodeRates(program_code: any, id: any, isAfterUpdate = false): Observable<any> {
    const url = formatUrl(ENDPOINTS.CLAIMS.GET_SERVICE_CODE_RATES, { program_code, id });
    return this.httpClient
      .get(url, isAfterUpdate)
      .pipe(
        catchError((err) => {
          return throwError(() => this.errorHandler.handleError(err));
        })
      );
  }

  addServiceCodeRates(payload: any): Observable<any> {
    return this.httpClient
      .post(ENDPOINTS.CLAIMS.ADD_SERVICE_CODE_RATE, payload, true)
      .pipe(
        tap(() => {
          this.notificationService.success(
            'Success',
            'Service Code Rate Added Successfully.'
          );
        }),
        catchError((err) => {
          return throwError(() => this.errorHandler.handleError(err));
        })
      );
  }

  updateServiceCodeRates(payload: any, id: any): Observable<any> {
    const url = formatUrl(ENDPOINTS.CLAIMS.UPDATE_SERVICE_CODE_RATES, { id });
    return this.httpClient
      .patch(url, payload)
      .pipe(
        tap(() => {
          this.notificationService.success(
            'Success',
            'Service Code Rate Updated Successfully.'
          );
        }),
        catchError((err) => {
          return throwError(() => this.errorHandler.handleError(err));
        })
      );
  }

  updateParticipantProviderRates(payload: any, id: any): Observable<any> {
    const url = formatUrl(ENDPOINTS.CLAIMS.UPDATE_PARTICIPANT_PROVIDER_RATES, { id });
    return this.httpClient
      .patch(url, payload)
      .pipe(
        tap(() => {
          this.notificationService.success(
            'Success',
            'Rate Updated Successfully.'
          );
        }),
        catchError((err) => {
          return throwError(() => this.errorHandler.handleError(err));
        })
      );
  }

  getParticipantProviderRates(program_code: any, id: any, isWriteRequest = false) {
    const url = formatUrl(ENDPOINTS.CLAIMS.GET_PARTICIPANT_PROVIDER_RATES, { program_code, id });
    return this.httpClient
      .get(url, isWriteRequest)
      .pipe(
        catchError((err) => {
          return throwError(() => this.errorHandler.handleError(err));
        })
      );
  }

  addPartcicipantProviderRates(payload: any, isWriteRequest = false): Observable<any> {
    return this.httpClient
      .post(ENDPOINTS.CLAIMS.ADD_PARTICIPANT_PROVIDER_RATES, payload, isWriteRequest)
      .pipe(
        tap(() => {
          this.notificationService.success(
            'Success',
            'Rate Added Successfully.'
          );
        }),
        catchError((err) => {
          return throwError(() => this.errorHandler.handleError(err));
        })
      );
  }

  getBillableProviderRates(program_code: any, id: any, isWriteRequest = false) {
    const url = formatUrl(ENDPOINTS.CLAIMS.GET_BILLABLE_PROVIDER_RATES, { program_code, id });
    return this.httpClient
      .get(url, isWriteRequest)
      .pipe(
        catchError((err) => {
          return throwError(() => this.errorHandler.handleError(err));
        })
      );
  }

  getPayableProviderRates(program_code: any, id: any, isWriteRequest = false) {
    const url = formatUrl(ENDPOINTS.CLAIMS.GET_PAYABLE_PROVIDER_RATES, { program_code, id });
    return this.httpClient
      .get(url, isWriteRequest)
      .pipe(
        catchError((err) => {
          return throwError(() => this.errorHandler.handleError(err));
        })
      );
  }


  addBillableProviderRates(payload: any, isWriteRequest = false): Observable<any> {
    return this.httpClient
      .post(ENDPOINTS.CLAIMS.ADD_BILLABLE_PROVIDER_RATES, payload, isWriteRequest)
      .pipe(
        tap(() => {
          this.notificationService.success(
            'Success',
            'Rate Added Successfully.'
          );
        }),
        catchError((err) => {
          return throwError(() => this.errorHandler.handleError(err));
        })
      );
  }

  updateBillableProviderRates(payload: any, isWriteRequest = false): Observable<any> {
    return this.httpClient
      .post(ENDPOINTS.CLAIMS.ADD_BILLABLE_PROVIDER_RATES, payload, isWriteRequest)
      .pipe(
        tap(() => {
          this.notificationService.success(
            'Success',
            'Rate Updated Successfully.'
          );
        }),
        catchError((err) => {
          return throwError(() => this.errorHandler.handleError(err));
        })
      );
  }

  addPayablePARates(payload: any, isWriteRequest = false): Observable<any> {
    return this.httpClient
      .post(ENDPOINTS.CLAIMS.ADD_PAYABLE_PA_RATES, payload, isWriteRequest)
      .pipe(
        tap(() => {
          this.notificationService.success(
            'Success',
            'Rate Added Successfully.'
          );
        }),
        catchError((err) => {
          return throwError(() => this.errorHandler.handleError(err));
        })
      );
  }

  updatePayablePARates(payload: any, id: any): Observable<any> {
    const url = formatUrl(ENDPOINTS.CLAIMS.UPDATE_PAYABLE_PA_RATES, { id });
    return this.httpClient
      .patch(url, payload)
      .pipe(
        tap(() => {
          this.notificationService.success(
            'Success',
            'Rate Added Successfully.'
          );
        }),
        catchError((err) => {
          return throwError(() => this.errorHandler.handleError(err));
        })
      );
  }

  setFormData(form: string, data: any) {
    this.formData[form] = data;
  }

  getFormData(form: string) {
    return this.formData[form];
  }

  clearFormData() {
    this.formData = {};
  }

  fetchClaimsData(program_code: string, id: any, entry: string, isWriteRequest = false): Observable<any> {
    let url;
    if (entry === 'serviceCode') {
      url = formatUrl(
        ENDPOINTS.CLAIMS.GET_SERVICE_CODES,
        { program_code, id },
        this.notificationService
      );
    } else {
      url = formatUrl(
        ENDPOINTS.CLAIMS.GET_PROCESS_CODES,
        { program_code, id },
        this.notificationService
      );
    }

    return this.httpClient.get(url, isWriteRequest).pipe(
      tap((response) => {
        this.previousData$.next(response); // Cache the response
        this.selectedLeadId = id; // Update the previous ID
      }),
      catchError((err) => {
        this.previousData$.next(null as any); // Clear the cache
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }
}
