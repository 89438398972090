<ng-container>
    @if(isPageRestrictionLoaded){
    <div class="ppl-container-body">
        <div class="ppl-container-head">
            Billable - Personal Assistant Rates
            <div class="action">
                <ppl-button label="Add Billable - Personal Assistant Rates" icon="fa fa-plus" [customClass]="'primary-button'"
                    [iconPosition]="'left'" (click)="onAddParticipantProviderRates()"></ppl-button>
            </div>
        </div>
        <div class="ppl-container">
            <div class="ppl-content-container">
                <div class="table-style">
                    <ppl-list-table [pageConfig]="programName" [sharedDataKey]="sharedDataKey"
                        (rowDetailEvent)="rowClicked($event)" [loadPage]="lazyLoad"
                        [columnsToBeHidden]="columnsToBeHidden" [actionsToBeHidden]="actionsToBeHidden"
                        [jsonList]="'list_billable_personal_assistant_rates'" [from]="'claims'">
                    </ppl-list-table>
                </div>
            </div>
        </div>
    </div>
    }

</ng-container>