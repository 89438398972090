import { Injectable } from '@angular/core';
import { Program, UserDetails } from '../interfaces/user.interface';
import { catchError, Observable, Subject, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { formatUrl } from '../shared/utils/format-url-util';
import { ENDPOINTS } from '../shared/constants/url.constants';
import { HttpRequestService } from '../shared/services/http-request.service';
import { GlobalErrorHandlerService } from '../shared/services/global-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl = environment.apiUrl;

  programSubject = new Subject<Program[]>();
  selectedProgramSubject = new Subject<Program>();
  selectedMenuList= new Subject<Program>();
  constructor(    private http: HttpRequestService,
    private errorHandler: GlobalErrorHandlerService,

  ) { }

  getUserDetails = (): UserDetails => {
    return JSON.parse(localStorage.getItem('user_details') || '{}');
  }

  userPrograms = (programs: Program[]) => {
    this.programSubject.next(programs);
  }

  getSelectedProgram= (): Program => {
    return JSON.parse(localStorage.getItem('selected_program') || '{}');
  }

  setSelectedProgram =(program: Program) =>{
    this.selectedProgramSubject.next(program);
  }
  
  setSelectedMenu = (val:Program)=>{
    this.selectedMenuList.next(val);
  }

  getSelectedMenu =():Program =>{
    return JSON.parse(localStorage.getItem('selected_menu') || '{}');
  }

  fetchNotificationMessages(
    program_code: string,
    userType: any
  ): Observable<any> {
    const url = formatUrl(ENDPOINTS.GET_NOTIFICATIONS, {
      program_code,
      userType
    });
    return this.http.get(url).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }
}
