import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ModalComponent } from '../../components/modal/modal.component';
import { ListService } from '../../services/list.service';

@Component({
  selector: 'ppl-notification-sms',
  standalone: true,
  imports: [ModalComponent, NzInputModule, NzFormModule, ReactiveFormsModule],
  templateUrl: './notification-sms.component.html',
  styleUrl: './notification-sms.component.css',
})
export class NotificationSMSComponent implements OnChanges, OnInit {
  @Input() show: any;
  @Input() selectedCheckBoxValue: any[] = [];
  @Input() notificationType = '';
  @Input() sourcePage = '';
  isVisible = false;
  title = '';
  okText = 'Send';
  showModal = false;
  @Output() onHandleCancel = new EventEmitter<string>();
  notificationForm!: FormGroup;
  numbers: string[] = [];
  email: string[] = [];
  type = '';
  program: any;
  leadIds: string[] = [];

  constructor(
    private listService: ListService,
    private formBuilder: FormBuilder
  ) {}

  getType() {
    switch (this.sourcePage) {
      case 'list_participant_leads':
        return 'participant';
      case 'list_referrals':
        return 'referral';
      case 'list_providers_leads':
        return 'provider';
      default:
        return 'unknown';
    }
  }

  ngOnInit(): void {
    const program = JSON.parse(localStorage.getItem("selected_program")!);
    if(program){
      this.program = program.program_code;
    }
    this.notificationForm = this.formBuilder.group({
      comment: ['', [Validators.maxLength(100)]],
    });
    this.listService.applicationDataSub.subscribe((data: any) => {
      if (this.sourcePage.toLowerCase().includes('list')) {
        this.leadIds = data.map(
          (item: any) => item.lead_participant_id || item.lead_id
        );
      } else {
        const mobileNumbers = data[0].mobile_number;
        this.email = data[0].email_id;
        this.numbers = mobileNumbers.map((number: string) => {
          if (!number.startsWith('+1')) {
            return '+1' + number;
          }
          return number;
        });
      }
    });
  }

  handleOk() {
    const recepientValues =
      this.notificationType === 'mobile number' ? this.numbers : this.email;
    let payload: any = {
      communicationType: this.type.toUpperCase(),
      message: this.notificationForm.value.comment,
      program: this.program,
      leadIds: this.leadIds
    };
    if (this.sourcePage.toLocaleLowerCase().includes('list')) {
      payload = {
        ...payload,
        type: this.getType(),
      };
    } else {
      payload = {
        ...payload,
        recipients: recepientValues
      }
    }

    console.log("payload====>",payload);
    this.listService.sendNotification('',payload).subscribe(() => {
      this.handleCancel();
    });
  }

  handleCancel() {
    this.showModal = false;
    this.onHandleCancel.emit('');
    this.notificationForm.patchValue({ comment: '' });
    this.selectedCheckBoxValue = [];
  }

  ngOnChanges(change: SimpleChanges): void {
    try {
      if (change['show'] && change['show'].currentValue) {
        this.showModal = change['show'].currentValue == 'sms' ? true : false;
      }
      if (
        change['notificationType'] &&
        change['notificationType'].currentValue
      ) {
        this.title =
          change['notificationType'].currentValue === 'mobile number'
            ? ' Send SMS'
            : 'Send Email';
        this.type =
          change['notificationType'].currentValue === 'mobile number'
            ? 'sms'
            : 'email';
      }
    } catch (err) {
      console.log(err)
    }
  }
}