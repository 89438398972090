<div id="signature" class="ppl-mt20">
  <form nz-form nz-col [nzXs]="24" [nzMd]="14" [nzLg]="13" class="ppl-p0 flex flex-col items-center ma-auto">
    @if (editMode === EDIT_MODE.Edit || !base64Image) {
    <div class="edit-cta">
      <ppl-button label="Cancel" icon="fa fa-ban" [customClass]="'ghost-button'" [iconPosition]="'left'"
        style="display: block; width: fit-content" (click)="setEditMode(EDIT_MODE.Read)"
        [disabled]="!base64Image"></ppl-button>
    </div>
    <nz-form-item class="w-full mb-2">
      <nz-form-label [nzSm]="4" [nzXs]="24" nzRequired nzFor="name">Name</nz-form-label>
      <nz-form-control [nzSm]="20" [nzXs]="24">
        <input id="name" nz-tooltip nzTooltipTitle="Enter name to generate signature" nzTooltipPlacement="topRight"
          nzSize="large" nz-input placeholder="Enter Name" [(ngModel)]="name" name="nameInput" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="w-full mb-0 justify-center">
      <div #signatureContainer class="signature-container">
        <canvas #canvas class="canvas" [height]="signaturePadDimensions.height"
          [width]="signaturePadDimensions.width"></canvas>
      </div>
      @if(showButtons){
      <div class="action-buttons--container w-full flex justify-between mt-4">
        @if(isEditable) {
        <ppl-button label="Generate By Name" icon="fa fa-cogs" iconPosition="left"
          [customClass]="'primary-button justify-center'" (click)="generateSignature()"
          style="display: inline-block"></ppl-button>
        <ppl-button label="Draw Your Signature" icon="fa fa-pencil" iconPosition="left"
          [customClass]="'primary-button justify-center'" (click)="drawSignature()"
          style="white-space: nowrap; display: inline-block"></ppl-button>
        <ppl-button label="Clear" [customClass]="'ghost-button justify-center'" icon="fa fa-times-circle-o"
          iconPosition="left" (click)="clearSignature()" style="white-space: nowrap; font-size: 0.857rem"></ppl-button>
        <ppl-button label="Save" [customClass]="'primary-button justify-center'" icon="fa fa-floppy-o"
          iconPosition="left" (click)="emitSignature()" style="white-space: nowrap"></ppl-button>
        }
      </div>
      }
    </nz-form-item>
    } @else if (editMode === EDIT_MODE.Read) {
    <div class="edit-cta">
      @if(isEditable) {
      <ppl-button label="Update" icon="fa fa-pencil" [customClass]="'ghost-button'" [iconPosition]="'left'"
        (click)="setEditMode(EDIT_MODE.Edit)"></ppl-button>
      }
    </div>
    <section>
      <div>Current Signature:</div>
      @if (base64Image) {
      <img [src]="base64Image" alt="" />
      } @else {
      <p>No Signatures Added</p>
      }
    </section>
    }
    @if(displayNote){
    <div class="info-box">
      <p style="margin-top: 16px;">If the consumer is a minor or has a disability and cannot provide an e-signature,
        please type into the Name box: "X witnessed by" then your name, relationship to the consumer, and social
        security number.</p>
      <p style="margin-bottom: 0;"><strong>For Example:</strong> X witnessed by John Doe (Parent) (999-99-9999)</p>
    </div>
    }
  </form>
</div>