import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LeadDetailsService } from '../../../services/lead-details.service';
import { ReferralDataService } from '../../../services/referral-data.service';
import { SectionsService } from '../../../services/sections.service';
import { AuthorizationUtility } from '../../../shared/authorization/auth.utility';
import { AuthorizationService } from '../../../shared/authorization/authorization.service';
import { PAGE_CONSTANTS } from '../../../shared/constants/page.constants';
import { STATIC_DATA } from '../../../shared/constants/static-data.constants';
import { SharedDataService } from '../../../shared/services/shared-data-service';
import { ParticipantLeadsListComponent } from '../../participant-leads-list/participant-leads-list.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { AuthorizeButtonDirective } from '../../../shared/authorization/authorizeButton.directive';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { ListTableComponent } from '../../../shared/components/list-table/list-table.component';
import { EnrollmentListComponentOld } from '../enrollment-list_old/enrollment-list.component';

@Component({
  selector: 'ppl-enrollment-list',
  standalone: true,
  imports: [
    ListTableComponent,
    NzIconModule,
    NzButtonModule,
    RouterModule,
    AuthorizeButtonDirective,
    ButtonComponent,
    EnrollmentListComponentOld
  ],
  templateUrl: './enrollment-list.component.html',
  styleUrl: './enrollment-list.component.css',
})
export class EnrollmentListComponent extends ParticipantLeadsListComponent {
  override sharedDataKey: string = STATIC_DATA.SHARED_ENROLLMENT_DATA;
  override pageId: string = PAGE_CONSTANTS.ENROLLMENT_DATA_GRID_PAGE;

  constructor(
    router: Router,
    sectionService: SectionsService,
    referralDataService: ReferralDataService,
    sharedDataService: SharedDataService,
    authUtility: AuthorizationUtility,
    authService: AuthorizationService,
    private leadDetailsService: LeadDetailsService
  ) {
    super(
      router,
      sectionService,
      referralDataService,
      sharedDataService,
      authUtility,
      authService
    );
  }

  override onNewLead(): void {
    this.router.navigate(['/referralLogic']);
    this.sectionService.setFlow('providers');
    this.referralDataService.clearReferrralData();
  }

  onNewReferral() {
    this.router.navigate(['/newReferral']);
    this.sectionService.setFlow('providerEnrollment');
    localStorage.setItem('lead_entry', '');
    this.referralDataService.clearReferrralData();
  }

  override rowClicked(rowDetail: any) {
    const program = JSON.parse(
      localStorage.getItem(STATIC_DATA.SELECTED_PROGRAM)!
    );
    localStorage.setItem('lead_entry', 'referral');
    this.sectionService.setFlow('referral');
    localStorage.setItem('selected_lead', rowDetail.referral_participant_id);
    if (rowDetail.application_stage_lkp == 'Copied from Lead') {
      this.leadDetailsService
        .fetchLeadDetailsApi(
          'referral',
          program.program_code,
          rowDetail.referral_participant_id
        )
        .subscribe((data: any) => {
          this.referralDataService.setReferralDetails(data.responsedata);
          setTimeout(() => {
            this.router.navigate(['/newReferral']);
          }, 200);
        });
    } else {
      setTimeout(() => {
        this.router.navigate(['/home/referralList/referralDetails/']);
      }, 200);
    }
  }
}
