<div class="side-drawer" *ngIf="isSideDrawerVisible" [style.width]="width">
    <div class="overlay"></div>
    <div class="content">
        <div class="header">
            <div style="display: flex; gap: 8px; align-items: center;">
                <div class="icon">
                    <i class="fa fa-bolt"></i>
                </div>
                <div class="title">
                    {{ title }}
                </div>                
            </div>
            <div class="action">
                <ppl-button
                label=""
                ariaLabel="close"
                icon="fa fa-close" 
                [customClass]="'rounded'" 
                [iconPosition]="'left'"
                (click)="closeAlert()">
                ></ppl-button>
            </div>                   
        </div>            
        <div class="body">
            <div class="body-content">
                {{ message }}
                <ng-content></ng-content>
            </div>
        </div>
  </div>
  