/* src/app/shared/components/modal/modal.component.css */
.drop-btn {
  cursor: pointer;
  padding: 7px;
  border-radius: 4px;
  margin-left: 0;
  border: 1px solid #cdc7c7;
  font-size: 1rem;
}
/*# sourceMappingURL=modal.component-33GQJDTY.css.map */
