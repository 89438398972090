{
  "columns": [
    {
      "header_id": "participantPplId",
      "header_label": "Consumer PPL ID",
      "jsonpath": "participantPplId",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "consumerFirstName",
      "header_label": "Consumer First Name",
      "jsonpath": "consumerFirstName",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "consumerLastName",
      "header_label": "Consumer Last Name",
      "jsonpath": "consumerLastName",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "pplProviderId",
      "header_label": "Personal Assistant PPL ID",
      "jsonpath": "pplProviderId",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "providerFirstName",
      "header_label": "Personal Assistant First Name",
      "jsonpath": "providerFirstName",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "providerLastName",
      "header_label": "Personal Assistant Last Name",
      "jsonpath": "providerLastName",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "code",
      "header_label": "Service Code",
      "jsonpath": "code",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "rate",
      "header_label": "Rate",
      "jsonpath": "rate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "fromDate",
      "header_label": "From Date",
      "jsonpath": "fromDate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "toDate",
      "header_label": "To Date",
      "jsonpath": "toDate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "createdby",
      "header_label": "Created By",
      "jsonpath": "createdby",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "createddate",
      "header_label": "Created Date",
      "jsonpath": "createddate",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "modifiedby",
      "header_label": "Modified By",
      "jsonpath": "modifiedby",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "modifieddate",
      "header_label": "Modified Date",
      "jsonpath": "modifieddate",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "ptcPrvdrXRatesId",
      "header_label": "Id",
      "jsonpath": "ptcPrvdrXRatesId",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    }
  ],
  "buttons": [],
  "apiDetails": "consumerProviderList.json",
  "pageName": "consumer-provider-list",
  "orderByColumn": ["ptcPrvdrXRatesId"],
  "orderBy": ["desc"]
}
