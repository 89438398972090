import { Injectable } from '@angular/core';
import { Observable, catchError, throwError, tap } from 'rxjs';
import { ERROR_MESSAGES } from '../constants/error-message.constants';
import { ENDPOINTS } from '../constants/url.constants';
import { formatUrl } from '../utils/format-url-util';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { SectionsService } from '../../services/sections.service';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { HttpRequestService } from './http-request.service';
import { NotificationService } from './notification.service';
import { SharedDataService } from './shared-data-service';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(
    private http: HttpRequestService,
    private sharedService: SharedDataService,
    private sectionService: SectionsService,
    private errorHandler: GlobalErrorHandlerService,
    private notificationService: NotificationService,
    private httpClient: HttpClient,
  ) {}

  getUploadedFileDetails(
    program_code: string,
    id: any,
    type: string,
    hasUpdatedEntry?: boolean
  ): Observable<any> {
    const flow = this.sharedService.getType(type);
    const url = formatUrl(ENDPOINTS.COMMON.GET_FILE_DETAILS, { program_code, id, flow });
    return this.http.get(url, hasUpdatedEntry).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  uploadFile(requestPayload: any): Observable<any> {
    return this.http.post(ENDPOINTS.COMMON.UPLOAD_FILE, requestPayload, true).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_FILE_UPLOAD
        );
      }),
      catchError((err) => {
        console.log(err);
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  deleteFile(program: string, id: any, createType: string, entry: string): Observable<any> {
    const type = entry;
    const url = formatUrl(ENDPOINTS.COMMON.DELETE_FILE, {program, type, id, createType,});
    return this.http
      .delete(url)
      .pipe(
        tap(() => {
          this.notificationService.success(
            'Success',
            ERROR_MESSAGES.SUCCESS_FILE_DELETE
          );
        }),
        catchError((err) => {
          console.log(err);
          return throwError(() => this.errorHandler.handleError(err));
        })
      );
  }

  downloadFile(program_code: string, flow: string, id: string, selectedFileName: string) {
    // let flow = entry === STATIC_DATA.PARTICIPANT_ENROLLMENT ? 'participantenrlmnt' : entry;
    const url = formatUrl(ENDPOINTS.COMMON.VIEW_FILE, { program_code, flow, id });
    const headers = new HttpHeaders({
      'Accept': 'multipart/form-data'
    })
    //let url = (ENDPOINTS.VIEW_FILE);
    this.httpClient.get(url, { headers: headers, responseType: 'blob', observe: 'response' }).subscribe((response: HttpResponse<Blob>) => {

      //const contentDisposition = response.headers.get('Content-Disposition');
      const fileName = selectedFileName;
      if (response.body) {
        const blob = new Blob([response.body], { type: response.body.type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    },
      error => {
        console.error('unable to open the file', error)
      })
  }

}
