/* src/app/components/lead-details/associations/association-details/association-details.component.css */
.ant-picker {
  padding: 0 8px;
  width: 100%;
  border-radius: 8px;
}
:host ::ng-deep .ant-select-selector {
  border-radius: 8px !important;
}
.ppl-radio-group {
  justify-content: start;
}
.nz-form .ant-form-item {
  display: flex;
  align-items: center;
}
.nz-form .ant-form-item-control {
  flex: 1;
}
::ng-deep .ant-picker-input > input {
  border: none;
}
::ng-deep nz-input-group > .ant-input {
  height: 30px;
}
.ant-radio-button-wrapper {
  margin: 0px;
  width: 100%;
}
.ant-input-affix-wrapper {
  margin-bottom: 0;
}
::ng-deep .ant-tooltip-inner {
  border-radius: 8px;
}
.notes {
  font-size: 0.929rem;
  margin-top: 4px;
}
.notes p {
  margin: 0;
}
.divider {
  border-top: 2px solid #cff1f04d;
}
.height-input-container {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 8px;
}
.height-input-container input {
  flex: 1;
  min-width: 80px;
  box-sizing: border-box;
}
.height-input-container span {
  margin-left: 4px;
}
.weight-input-container {
  display: flex;
  align-items: center;
}
.weight-input-container input {
  width: 64%;
  margin-right: 8px;
}
.unit-label {
  margin-left: 4px;
}
.ppl-form-item {
  margin: 4px 10px 10px !important;
}
.ppl-form-control {
  margin: 0 4px 4px 0 !important;
}
.ant-form-horizontal .ant-form-item-label {
  margin-bottom: 0 !important;
}
.address-section {
  width: 100%;
}
.ant-divider-horizontal {
  margin-bottom: 16px;
  margin-top: 0;
}
.address-control {
  margin-bottom: 0px;
}
::ng-deep .ant-form-horizontal .ant-form-item-label {
  margin: 0px !important;
}
::ng-deep .ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0px;
}
.ssn-visible {
  border: inherit;
  background: inherit;
  padding: 0;
}
.actions-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 1rem;
}
/*# sourceMappingURL=association-details.component-GFX4JT7H.css.map */
