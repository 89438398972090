import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ppl-flow-dialog',
  standalone: true,
  imports: [],
  templateUrl: './flow-dialog.component.html',
  styleUrl: './flow-dialog.component.css'
})
export class FlowDialogComponent {
  @Input() isVisible = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();

}
