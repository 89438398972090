<div class="custom-row">
    @if(mode==="edit"){

      <ppl-button 
      label="Cancel" 
      icon="fa fa-ban" 
      [customClass]="'alt-button'" 
      [iconPosition]="'left'"
      (click)="cancelEdit()"
      ></ppl-button>    
  
      <ppl-button 
      label="Save" 
      icon="fa fa-floppy-o" 
      [customClass]="'alt-button'" 
      [iconPosition]="'left'"
      (click)="save()"
      ></ppl-button>

    <!-- <span
      (click)="cancelEdit()"
      nz-icon
      nzType="close-circle"
      nzTheme="outline"
    ></span>
    <span nz-icon nzType="check-circle" (click)="save()" nzTheme="outline"></span> -->
    }
    @if(mode==="read" && leadStatus !== approvedStatus){
      <ppl-button 
      label="Edit" 
      icon="fa fa-pencil" 
      [customClass]="'ghost-button'" 
      [iconPosition]="'left'"
      (click)="editInfo()"
      *authorize="[pageId, tabId, 'AddressInformation', 'section', 'edit']"
      ></ppl-button>      
    <!-- <span
      (click)="editInfo()"
      nz-icon
      nzType="edit"
      nzTheme="outline"
      *authorize="[pageId, tabId, 'AddressInformation', 'section', 'edit']"
    ></span> -->
    }
  </div>

@if(mode==="edit"){

    <div class="ppl-mt24">
        <form nz-form [formGroup]="contactForm" class="ppl-p0">
          @if(isAttrAvailable('permanentAddress')){
          <nz-form-item nzSpan="24" nz-col class="ppl-form-item">
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="permanentAddress"
              class="ppl-form-label">{{getAttributes('permanentAddress').label}}
              @if(getAttributes('permanentAddress').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <div class="address-section">
              <nz-form-control class="ppl-form-control address-control">
                <ppl-lead-details-address [addressForm]="getFormGroup('permanentAddress')" [is_permanent]="true" [statesList]="statesList" [validationErrors]="validationErrorsPermanentAdd"></ppl-lead-details-address>
              </nz-form-control>
            </div>
          </nz-form-item>
          <nz-divider class="divider"></nz-divider>
          }
          @if(!contactForm.get('permanentAddress')?.value.mailing){
            @if(isAttrAvailable('mailingAddress')){
              <nz-form-item nzSpan="24" nz-col class="ppl-form-item">
                <div class="address-section">
                  <nz-form-control class="ppl-form-control address-control">
                    <ppl-lead-details-address [addressForm]="getFormGroup('mailingAddress')" [is_permanent]="false" [statesList]="statesList" [validationErrors]="validationErrorsMailingAdd"></ppl-lead-details-address>
                  </nz-form-control>
                </div>
              </nz-form-item>
              
            }
          }
          @if(isAttrAvailable('residentGt18')){
            <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
              <nz-row>
                <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="residentGt18" class="ppl-form-label" >{{ getAttributes("residentGt18").label }}
                @if(getAttributes('residentGt18').mandatory === 'Y'){
                <span>*</span>
                }</nz-form-label>
              <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('residentGt18').tooltip}}"
                nzTooltipPlacement="topRight">
                <nz-select formControlName="residentGt18" class="ppl-select" nzId="residentGt18" nzPlaceHolder="Select"
                  [ngClass]="{'error': validationErrors['residentGt18'] }" nzShowSearch nzAllowClear>
                  @for(opt of radioOptions; track $index){
                    <nz-option [nzLabel]="opt.label" [nzValue]="opt.value"></nz-option>
                  }
                  <nz-option [nzLabel]="'Unknown'" [nzValue]="'unknown'"></nz-option>
                </nz-select>
                @if (validationErrors['residentGt18']) {
                <span class="pl-1 error-message">{{validationErrors['residentGt18']}}</span>
                }
              </nz-form-control>
            </nz-row>
            </nz-form-item>
            }
        </form>
    </div>
    }
    @else if(mode==="read" && filledInfo?.permanentAddress && secData){
    
    @if(isAttrAvailable('permanentAddress')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('permanentAddress').label}} :</div>
    </div>
    
    <ppl-lead-details-address [mode]="'read'" [data]="filledInfo.permanentAddress" [statesList]="statesList"></ppl-lead-details-address>
   
    }@else {
      <nz-row>
        <p>No Data found</p>
      </nz-row>
    }
    
    @if(isAttrAvailable('mailingAddress')){
      @if(!filledInfo.permanentAddress?.mailing){
        <div nz-row class="pb-2 pt-2">
          <div nz-col nzSpan="12" class="ppl-read-label">{{getAttributes('mailingAddress').label}} :</div>
        </div>

        <ppl-lead-details-address [mode]="'read'" [is_permanent]="false" [data]="filledInfo.mailingAddress" [statesList]="statesList"></ppl-lead-details-address>
      
        }
    }
    @if(isAttrAvailable('residentGt18')){
      <div nz-row>
        <div nz-col [nzXs]="24" [nzSm]="16" [nzMd]="16" [nzLg]="10" [nzXXl]="4" class="ppl-read-label">{{getAttributes('residentGt18').label}}</div>
        <div nz-col >{{ filledInfo['residentGt18'] }}</div>
      </div>
      }
    }@else if(mode==="read" && !filledInfo?.permanentAddress ){
        <nz-row>
            <p>No Data found</p>
          </nz-row>
    }
