import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ChecklistGroup, ChecklistItem, ChecklistResponse } from '../../../interfaces/checklist.model';
import { CommonModule } from '@angular/common';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { ButtonModule } from 'primeng/button';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { ModalComponent } from '../../../shared/components/modal/modal.component';
import { EditpdfComponent } from '../../editpdf/editpdf.component';
import { CommPreferenceComponent } from '../comm-preference/comm-preference.component';
import { RADIO_YES_NO_OPTIONS } from '../../../shared/constants/static-data.constants';
import { PDFFormConfig } from '../../../interfaces/config';
import { CheckListService } from '../../../services/check-list.service';
import { HttpResponse } from '@angular/common/http';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormChecklistComponent } from '../form-checklist/form-checklist.component';
import { ChecklistViewComponent } from "../checklist-view/checklist-view.component";
import { DateTimeFormatterService } from '../../../shared/utils/date-time-formatter.service';
import { ChecklistHelperService } from '../../../services/checklist.helper.service';
import { PopupDialogComponent } from '../../../shared/components/popup-dialog/popup-dialog.component';
import { DialogConfig, DialogService } from '../../../shared/services/dialog.service';
import { ReferralDataService } from '../../../services/referral-data.service';

@Component({
  selector: 'ppl-participant-checklist',
  standalone: true,
  imports: [NzCollapseModule,
    CommPreferenceComponent,
    CommonModule,
    ButtonModule,
    ButtonComponent,
    ModalComponent,
    EditpdfComponent,
    FormChecklistComponent,
    NzSelectModule,
    ChecklistViewComponent,
    PopupDialogComponent
  ],
  templateUrl: './participant-checklist.component.html',
  styleUrl: './participant-checklist.component.css'
})
export class ChecklistParticipantComponent implements OnInit {
  @ViewChild('dialogContent') dialogContent!: TemplateRef<any>;

  date = '';
  userId?: string | null;
  checklistData: ChecklistGroup[] = [];
  radioOptions = RADIO_YES_NO_OPTIONS;
  showModal = false;
  modalBody = '';
  selectedForm: any;
  program: any;
  pdfFile?: string;
  pdfBlob?: Blob;
  pdfConfig?: PDFFormConfig;
  pplId = '';
  signature: any;
  signatureId: any;
  isSignAdded = false;
  isParticipantFlow = false;
  @Input() isEditable = true;
  source = '';

  constructor(
    private checklistHelperService: ChecklistHelperService,
    private checkListService: CheckListService,
    private referralService: ReferralDataService,
    private dateTimeFormatterService: DateTimeFormatterService,
    private dialogSvc: DialogService
  ) { }

  ngOnInit(): void {
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    this.userId = localStorage.getItem('selected_lead');
    this.isParticipantFlow = localStorage.getItem('lead_entry') == 'participantEnrollment' || localStorage.getItem('lead_submission_flow') == 'participantEnrollment';
    this.source = this.isParticipantFlow ? 'participant' : 'provider';
    this.getSignature(this.program.program_code, this.source, this.userId);
    this.getChecklist();
  }

  getChecklist() {
    this.checkListService.getChecklist(this.program?.program_code, this.userId || '', true).subscribe((data: any) => {
      if (data.responsedata) {
        const checklist: ChecklistResponse = data.responsedata;
        this.pplId = checklist.pplId;
        this.checklistData = this.checklistHelperService.buildChecklistDataForParticipant(checklist.checkList, false);
      }
    });
  }

  saveChecklist() {
    const checklistSaveResponse = this.generateSaveChecklistResponses();
    const payload = {
      type: "participant",
      checklistItems: checklistSaveResponse.filter(e => e != null)
    };
    this.checkListService.saveChecklist(this.program?.program_code,
      this.userId || '',
      true, payload).subscribe(response => {
        console.log(response, 'response')
        this.getChecklist();
      });
  }

  generateSaveChecklistResponses() {
    const checklist: any[] = [];
    this.checklistData.forEach(e => {
      if (e.children && e.children.length > 0) {
        e.children.forEach(firstChild => {
          this.generateEachChecklistItemResponse(firstChild, checklist);

          firstChild.children.forEach(secondChild => {
            this.generateEachChecklistItemResponse(secondChild, checklist);
          })
        })
      }
    });

    return checklist;
  }

  generateEachChecklistItemResponse(e: ChecklistGroup, checklist: any[]) {
    if (e.items && e.items.length > 0) {
      e.items.forEach(i => {
        checklist.push(new Object({
          checklistId: i.checklistItemId,
          checklistDetailId: i.checklistItemDetailId,
          value: i.value,
          fromDate: i.fromDate,
          toDate: i.toDate,
          participantId: this.userId,
          associationId: i.details.filter(e => e.associationId != -1).map(d => d.associationId),
        }));
      });
    }
  }

  handleCheckListClick(form: any) {
    this.modalBody = form.formDesc;
    const dialogConfig: DialogConfig = {
      title: this.modalBody,
      isOkText: 'Save',
      size: 'xx-large',
      position: 'top',
      showClose: true,
      showButtons: false,
    }
    this.dialogSvc.openDialog(this.dialogContent, dialogConfig, () => this.handleCancel(), () => this.handleCancel());
    this.showModal = true;
    this.loadPDF(form);
  }

  fieldsUpdated(event: any) { }

  handleCancel(): void {
    this.showModal = false;
    this.pdfConfig = undefined;
    this.pdfFile = undefined;
  }

  loadPDF(form: ChecklistItem) {
    this.checkListService.getPDF(this.program?.program_code, form.formMasterId, 'eng').subscribe((response: HttpResponse<Blob>) => {
      console.log('pdf response' + response);
      if (response.body) {
        const blob = new Blob([response.body], { type: response.body.type });
        this.pdfBlob = blob;
        this.pdfFile = window.URL.createObjectURL(blob);
        console.log('blob file' + this.pdfFile);
      }
    });

    this.loadPDFData(form);
  }

  getSignature(program_code: string, type: string, id: string | null) {
    this.referralService.getSignature(program_code, type, id).subscribe((data: any) => {
      if (data?.responsedata && data?.responsedata.length) {
        const activeSign = data?.responsedata.find((data: any) => data.isActive);
        this.isSignAdded = true;
        this.signature = `data:image/png;base64,${activeSign?.signatureImageBase64}`;
        this.signatureId = activeSign.participantSignatureId;
      }
    })
  }

  loadPDFData(form: ChecklistItem) {
    this.checkListService.getFormData(this.program?.program_code,
      form,
      "participant", this.pplId).subscribe(response => {
        console.log(response, 'response')
        if (response?.responsedata) {
          this.pdfConfig = {
            fields: response?.responsedata.Form_Fields,
            form_description: form.formDesc,
            form_id: form.formMasterId + '',
            form_name: form.form_name,
            is_mandatory: false
          };
        }
      });
  }

  hideAll() {
    this.checklistData.forEach(group => {
      group.isActive = false;

      this.toggleCollapsePanel(group, false);
    });
  }

  expandAll() {
    this.checklistData.forEach(group => {
      group.isActive = true;

      this.toggleCollapsePanel(group, true);
    });
  }

  toggleCollapsePanel(group: ChecklistGroup, activate: boolean) {
    group.isActive = activate;
    if (group.children && group.children.length > 0) {
      group.children.forEach(c => {
        this.toggleCollapsePanel(c, activate);
      })
    }
  }

  isPanelActive(group: ChecklistGroup): boolean {
    return group.isActive;
  }
}
