{
    "columns": [
      {
        "header_id": "fileName",
        "header_label": "File Name",
        "jsonpath": "fileName",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "createdDate",
        "header_label": "Received Date",
        "jsonpath": "createdDate",
        "isDisplayedAtHeader": "y",
        "isSearchable": "n"
      },
      {
        "header_id": "source",
        "header_label": "Source",
        "jsonpath": "source",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "createdBy",
        "header_label": "Last Modified By",
        "jsonpath": "createdBy",
        "isDisplayedAtHeader": "n",
        "isSearchable": "n"
      },
      {
        "header_id": "daysInQueue",
        "header_label": "Days in Queue",
        "jsonpath": "daysInQueue",
        "isDisplayedAtHeader": "y",
        "isSearchable": "n"
      },
      {
        "header_id": "filePath",
        "header_label": "File Path",
        "jsonpath": "filePath",
        "isDisplayedAtHeader": "n",
        "isSearchable": "n"
      }
    ],
    "buttons": [
      {
        "button_id":"file_triage_assign",
        "button_label":"Assign",
        "buttonAction" : "fileTriageAssign",
        "className": "primary-btn-color"
      }
    ],
    "apiDetails": "fileTriageList.json",
    "pageName": "fileTriageList",
    "orderByColumn": ["fileName"],
    "orderBy": ["desc"]
  }
  