/* src/app/shared/components/loader/loader.component.css */
.ppl-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #11b8ad3a;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.ant-spin {
  color: aquamarine !important;
}
.ppl-spinner .ant-spin-dot-item {
  background-color: #11B8AE !important;
}
/*# sourceMappingURL=loader.component-OO7IUSYL.css.map */
