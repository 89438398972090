import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AccordionComponent } from "../../../shared/components/accordion/accordion.component";
import { AuthorizeDirective } from '../../../shared/authorization/authorize.directive.';
import { MEMBER_VIEW_PAGE_IDS, PAGE_CONSTANTS, TABS } from '../../../shared/constants/page.constants';
import { SectionsService } from '../../../services/sections.service';
import { Router } from '@angular/router';
import { STATIC_DATA, STATIC_JSON_DATA_PATH } from '../../../shared/constants/static-data.constants';
import { ListTableComponent } from "../../../shared/components/list-table/list-table.component";
import { TableRowActionsModel } from '../../../shared/interfaces/list.interface';
import { TableRowActions } from '../../../shared/interfaces/actions';
import { NgZorroModule } from '../../../shared/modules/ng-zorro/ng-zorro.module';
import { BaseComponent } from '../../../shared/utils/base.component';
import { PopupDialogComponent } from "../../../shared/components/popup-dialog/popup-dialog.component";
import { DialogConfig, DialogService } from '../../../shared/services/dialog.service';
import { noop } from 'rxjs';
import { LeadDetailsService } from '../../../services/lead-details.service';

@Component({
  selector: 'ppl-authorization',
  standalone: true,
  imports: [AccordionComponent, AuthorizeDirective, ListTableComponent, NgZorroModule, PopupDialogComponent],
  templateUrl: './authorization.component.html',
  styleUrl: './authorization.component.css'
})
export class AuthorizationComponent extends BaseComponent implements OnInit {

  @ViewChild('dialogContent') dialogContent!: TemplateRef<any>;
  entry: string | null =  '';
  title = '';
  show = false;


  pageId: string = '';
  tabId = TABS.Authorization;
  programName!: string;
  columnsToBeHidden: any;
  sharedDataKey: any;
  actionsToBeHidden: any;
  lazyLoad = true;
  isPageRestrictionLoaded = false;
  actionButtonsApproved: TableRowActionsModel = new TableRowActions({view: true});
  actionButtonsErrored: TableRowActionsModel = new TableRowActions();

  dataData = [];

  authData = {}
  selectedLead = 0;
  approvedCols: any;
  erroredCols: any;

  constructor(
    private router: Router,
    private sectionsService: SectionsService,
    private dialogSvc: DialogService,
    private leadDetailsService : LeadDetailsService
  ) {
    super()
  }

  ngOnInit(): void {
    this.pageId = MEMBER_VIEW_PAGE_IDS[localStorage.getItem(STATIC_DATA.URL_FIRST_SEGMENT)!];

    if(this.entry === 'participant'){
      this.title = 'Authorization Summary'
    }else{
      this.title = 'Paystub history'
    }

    this.entry = localStorage.getItem('lead_entry');
    const selectedProgram = JSON.parse(localStorage.getItem('selected_program')!);
    console.log('.......   ', selectedProgram);
    this.programName = selectedProgram.program_code;
  
    const program = JSON.parse(localStorage.getItem('selected_program')!);
    this.programName = program.program_code;
    this.getSectionData();
    this.getGridHeaders();
    this.selectedLead = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!);
    this.getParticipantAuthorizations(this.programName, this.selectedLead )
  }


  async getSectionData() {
    try {
      if (this.entry) {
        this.secData = await this.sectionsService.getJsonConfigurationData(
          STATIC_JSON_DATA_PATH.AUTHORIZATIONS_SECTION_DATA,
          'authorizations'
        );
      }
    } catch (e) {
      console.log('error fetching section data: ', e);
    }
  }

  async getGridHeaders() {
    try {
        if(this.entry) {
        const tempCols = await this.sectionsService.getJsonConfigurationData(STATIC_JSON_DATA_PATH.AUTHORIZATIONS_PARTICIPANT_DETAILS_LIST_HEADERS, this.entry);
        this.approvedCols = tempCols.approvedAuthorizations.columns;
        this.erroredCols = tempCols.erroredAuthorizations.columns;
        }
    } catch (e) {
      console.log("header fetch error: ", e);
    }

  }

  
  onAddAuthorizations() {
    console.log('process code add button clicked');
    this.router.navigate(['/home/addAuthorizations']);
  }

  action(event: any) {
    console.log('acto', event);
    if (event && event.action === 'view') {

      this.authData = event.data;

      const dialogConfig: DialogConfig = {
        title: 'Auth Details',
        isOkText: 'Save',
        size: 'large',
        position: 'top',
        showClose: true,
        showButtons: false,
      }
      this.dialogSvc.openDialog(this.dialogContent, dialogConfig, () => noop, () => noop);
    }


  }


  getParticipantAuthorizations(programCode: string, id: number) {

    const payload = {
      "pageName": "consumer-auth",
      "programCode":programCode,
      "condition" : "and",
      "recordsPerPage": 20,
      "startingPageNumber" : "1",
      "orderByColumn":["participantId"],
      "orderBy":["desc"],
      "searchParam" :[{
              "jsonPath":"participantId",
              "value" : id,
              "operator" : "equal"
         }]
  }


    this.leadDetailsService.getParticipantAuthorizations(payload).subscribe((data: any)=>{
      this.dataData = data.responsedata
    })
  }
}
