import { Component,OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ReferralDataService } from '../../services/referral-data.service';
import { SectionsService } from '../../services/sections.service';
import { AuthorizationUtility } from '../../shared/authorization/auth.utility';
import { AuthorizationService } from '../../shared/authorization/authorization.service';
import { AuthorizeButtonDirective } from '../../shared/authorization/authorizeButton.directive';
import { ButtonComponent } from "../../shared/components/button/button.component";
import { ListTableComponent } from '../../shared/components/list-table/list-table.component';
import { PAGE_CONSTANTS } from '../../shared/constants/page.constants';
import { STATIC_DATA } from '../../shared/constants/static-data.constants';
import { SharedDataService } from '../../shared/services/shared-data-service';
import { BaseComponent } from '../../shared/utils/base.component';
import { PageConfig } from '../../shared/authorization/auth.config';

@Component({
  selector: 'ppl-participant-leads-list',
  standalone: true,
  imports: [ListTableComponent, NzIconModule, NzButtonModule, RouterModule, AuthorizeButtonDirective, ButtonComponent],
  templateUrl: './participant-leads-list.component.html',
  styleUrl: './participant-leads-list.component.css'
})
export class ParticipantLeadsListComponent extends BaseComponent implements OnInit{
  title = 'List of all Consumer Interest';
  programName!: string;
  lazyLoad = true;
  sharedDataKey: string = STATIC_DATA.SHARED_PARTICIPANT_LEADS_DATA;
  pageId: string = PAGE_CONSTANTS.PARTICIPANT_LEADS_DATA_GRID_PAGE;
  columnsToBeHidden: string[] = [];
  actionsToBeHidden: string[] = [];
  isPageRestrictionLoaded = false;

  constructor(protected router: Router, 
    protected sectionService: SectionsService, 
    protected referralDataService: ReferralDataService, 
    protected sharedDataService: SharedDataService,
    private authUtility: AuthorizationUtility,
  private authService: AuthorizationService) {
    super();
   }

  ngOnInit() {
    const selectedProgram = JSON.parse(localStorage.getItem('selected_program')!);
    console.log('.......   ', selectedProgram);
    this.programName = selectedProgram.program_code;
    this.setPageAccess();
  }

  private setPageAccess() {
    const pageConfig = this.authUtility.getPageConfig(this.pageId);
    if(!pageConfig || !pageConfig.pageName) {
      this.authService.fetchPageAuthorization(this.programName, this.pageId, "LIST").subscribe(response => {
        this.setRestrictions(response);
      })
    } else {
      this.setRestrictions(pageConfig);
    }
  }

  private setRestrictions(pageConfig: PageConfig) {
    if (pageConfig) {
      this.columnsToBeHidden = this.authUtility.getHiddenColumns(pageConfig, this.pageId);
      this.actionsToBeHidden = this.authUtility.getHiddenActions(pageConfig, this.pageId);
      console.log('restricted columns', this.columnsToBeHidden);
      console.log('restricted actions', this.actionsToBeHidden);
    
    }
    this.isPageRestrictionLoaded = true;
  }

  onNewLead() {
    this.router.navigate(['/referralLogic']);
    this.sectionService.setFlow('participants');
    this.referralDataService.clearReferrralData();
  }

  rowClicked(rowDetail: { lead_participant_id: string; }) {
    console.log(rowDetail);

    localStorage.setItem('lead_entry','participant');
    localStorage.setItem('selected_lead', rowDetail.lead_participant_id);
    setTimeout(() => {
      this.router.navigate(['/home/participantLeadsList/leadDetails/']);
    }, 200)    
  }

  returnClicked(): void {
    this.router.navigate(['/home/participantLeadsList']);
  }

  clearData() {
    this.sharedDataService.clearData(this.sharedDataKey);
  }

}
