@if (infoForm) {
  <form nz-form [formGroup]="infoForm" class="ppl-p0">
    <nz-row>
      @if(isAttrAvailable('businessName')){
      <nz-col [nzSm]="6" [nzXs]="24">
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSpan]="24" nzFor="businessName"
            class="ppl-form-label">{{getAttributes('businessName').label}}
            @if(getAttributes('businessName').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSpan]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('businessName')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <input nz-input formControlName="businessName" [id]="'businessName' + sectionName"
                placeholder="{{getAttributes('businessName').placeholder}}"
                [ngClass]="{'error': validationErrors['businessName'] }" [readonly]="formReadonly" />
            </div>
            @if (validationErrors['businessName']) {
            <span class="pl-1 error-message">{{validationErrors['businessName']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      }
      @if(isAttrAvailable('fname')){
      <nz-col [nzSm]="6" [nzXs]="24">
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSpan]="24" nzFor="firstName" class="ppl-form-label">{{getAttributes('fname').label}}
            @if(getAttributes('fname').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSpan]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('fname')?.tooltip || ''" nzTooltipPlacement="topRight">
              <input nz-input formControlName="fname" [id]="'fname' + sectionName"
                placeholder="{{getAttributes('fname').placeholder}}"
                [ngClass]="{'error': validationErrors['fname'] }" [readonly]="formReadonly || (isReadonly() && getFormControlValue('fname'))" />
            </div>
            @if (validationErrors['fname']) {
            <span class="pl-1 error-message">{{validationErrors['fname']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      }

      @if(isAttrAvailable('mname')){
      <nz-col [nzSm]="6" [nzXs]="24">
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSpan]="24" nzFor="mname" class="ppl-form-label">{{getAttributes('mname').label}}
            @if(getAttributes('mname').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSpan]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('mname')?.tooltip || ''" nzTooltipPlacement="topRight">
              <input nz-input formControlName="mname" [id]="'mname' + sectionName"
                placeholder="{{getAttributes('mname').placeholder}}"
                [ngClass]="{'error': validationErrors['mname'] }" [readonly]="formReadonly || isReadonly()" />
            </div>
            @if (validationErrors['mname']) {
            <span class="pl-1 error-message">{{validationErrors['mname']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      }

      @if(isAttrAvailable('lname')){
      <nz-col [nzSm]="6" [nzXs]="24">
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSpan]="24" nzFor="lastName" class="ppl-form-label">{{getAttributes('lname').label}}
            @if(getAttributes('lname').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSpan]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('lname')?.tooltip || ''" nzTooltipPlacement="topRight">
              <input nz-input formControlName="lname" [id]="'lname' + sectionName"
                placeholder="{{getAttributes('lname').placeholder}}"
                [ngClass]="{'error': validationErrors['lname'] }" [readonly]="formReadonly || (isReadonly() && getFormControlValue('lname'))" />
            </div>
            @if (validationErrors['lname']) {
            <span class="pl-1 error-message">{{validationErrors['lname']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      }

      @if(isAttrAvailable('maiden_name')){
      <div nz-col [nzSm]="6" [nzXs]="24">
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSpan]="24" nzFor="maiden_name" class="ppl-form-label">{{getAttributes('maiden_name').label}}
            @if(getAttributes('maiden_name').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSpan]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('maiden_name')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <input nz-input formControlName="maiden_name" [id]="'maiden_name' + sectionName"
                placeholder="{{getAttributes('maiden_name').placeholder}}"
                [ngClass]="{'error': validationErrors['maiden_name'] }" [readonly]="formReadonly" />
            </div>
            @if (validationErrors['maiden_name']) {
            <span class="pl-1 error-message">{{validationErrors['maiden_name']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
      </div>
      }

    </nz-row>
    <nz-row>
      @if(isAttrAvailable('dob')){
      <div nz-col [nzSm]="6" [nzXs]="24">
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSpan]="24" nzFor="dob" class="ppl-form-label">{{getAttributes('dob').label}}
            @if(getAttributes('dob').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control [nzSpan]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('dob')?.tooltip || ''" nzTooltipPlacement="topRight">
              <nz-date-picker formControlName="dob" [id]="'dob' + sectionName" style="height: 32px;"
                [nzFormat]="'MM/dd/yyyy'" [nzPlaceHolder]="getAttributes('dob').placeholder"
                [nzDisabledDate]="disabledDate" [ngClass]="{'error': validationErrors['dob'] }" [nzDisabled]="formReadonly || (isReadonly() && getFormControlValue('dob'))"></nz-date-picker>
            </div>
            @if (validationErrors['dob']) {
            <span class="pl-1 error-message">{{validationErrors['dob']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
      </div>
      }
      @if(isAttrAvailable('gender')){
      <div nz-col [nzSm]="6" [nzXs]="24">
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSpan]="24" [nzFor]="getAccessibleInputId('gender')" class="ppl-form-label">{{getAttributes('gender').label}}
            @if(getAttributes('gender').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control [nzSpan]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('gender')?.tooltip || ''" nzTooltipPlacement="topRight">
              <nz-select formControlName="gender" class="ppl-select" [nzId]="getAccessibleInputId('gender')" nzShowSearch
                nzAllowClear [ngClass]="{'error': validationErrors['gender'] }" [nzDisabled]="formReadonly">
                @for(opt of gender; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
            </div>
            @if (validationErrors['gender']) {
            <span class="pl-1 error-message">{{validationErrors['gender']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
      </div>
      }

      @if(isAttrAvailable('isProvidingServices')){
      <div nz-col [nzSm]="6" [nzXs]="24">
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSpan]="24" nzFor="gender"
            class="ppl-form-label">{{getAttributes('isProvidingServices').label}}
            @if(getAttributes('isProvidingServices').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control [nzSpan]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('isProvidingServices')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <nz-radio-group formControlName="isProvidingServices" class="ppl-radio-group"
                [id]="'isProvidingServices' + sectionName"
                [ngClass]="{'error': validationErrors['isProvidingServices'] }">
                @for(opt of radioOptions; track $index){
                <label nz-radio-button [nzValue]="opt.value">{{opt.label}}</label>
                }
              </nz-radio-group>
            </div>
            @if (validationErrors['isProvidingServices']) {
            <span class="pl-1 error-message">{{validationErrors['isProvidingServices']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
      </div>
      }

      @if(isAttrAvailable('SSN')){
      <div nz-col [nzSm]="6" [nzXs]="24">
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSpan]="24" nzFor="SSN" class="ppl-form-label">{{getAttributes('SSN').label}}12
            @if(getAttributes('SSN').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control [nzSpan]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('SSN')?.tooltip || ''" nzTooltipPlacement="topRight">
              <nz-input-group [nzSuffix]="suffixTemplate" style="height: 32px; padding: 0px 11px;"
                [ngClass]="{'error': validationErrors['SSN'], 'input-group--readonly': (isReadonly() && getFormControlValue('SSN')) }">
                <input nz-input [type]="ssnVisible ? 'text' : 'password'" formControlName="SSN" (input)="formatSSN($event)"
                  [id]="'SSN' + sectionName" placeholder="{{getAttributes('SSN').placeholder}}" [readonly]="formReadonly || (isReadonly() && getFormControlValue('SSN'))" />
              </nz-input-group>
            </div>
            <ng-template #suffixTemplate>
              <!-- eslint-disable-next-line @angular-eslint/template/elements-content -->
              <button 
                [attr.aria-label]="ssnVisible ? 'Hide SSN' : 'Show SSN'"
                class="ssn-visible" 
                nz-icon 
                [nzType]="ssnVisible ? 'eye' : 'eye-invisible'" 
                (click)="ssnVisible = !ssnVisible"
              ></button>
            </ng-template>
            @if (validationErrors['SSN']) {
            <span class="pl-1 error-message">{{validationErrors['SSN']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
      </div>
      }
    </nz-row>

    <nz-row>
      @if(isAttrAvailable('EIN')){
      <div nz-col [nzSm]="6" [nzXs]="24">
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSpan]="24" nzFor="EIN" class="ppl-form-label">{{getAttributes('EIN').label}}
            @if(getAttributes('EIN').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSpan]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('EIN')?.tooltip || ''" nzTooltipPlacement="topRight">
              <input nz-input formControlName="EIN" id="EIN" placeholder="{{getAttributes('EIN').placeholder}}"
                [ngClass]="{'error': validationErrors['EIN'] }" [readonly]="formReadonly" />
            </div>
            @if (validationErrors['EIN']) {
            <span class="pl-1 error-message">{{validationErrors['EIN']}}</span>
            }

          </nz-form-control>
        </nz-form-item>

      </div>
      <div nz-col [nzSm]="18" [nzXs]="24" class="notes">
        <strong>Notes:</strong>
        <p>
          1. If EIN is lost/misplaced, then obtain your existing EIN using "<a>How to ask the IRS
            for a
            147c letter</a>".
        </p>
        <p>
          2. If EIN doesn't exist, PPL will obtain an EIN on your behalf after completion and
          submission
          of registration paperwork.
        </p>
      </div>
      }
    </nz-row>

    <nz-row>
      @if(isAttrAvailable('relationToParticipant')){
      <div nz-col [nzSm]="6" [nzXs]="24">
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSpan]="24" [nzFor]="getAccessibleInputId('relationToParticipant')"
            class="ppl-form-label">{{getAttributes('relationToParticipant').label}}
            @if(getAttributes('relationToParticipant').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control [nzSpan]="24" class="ppl-form-control" nz-tooltip
            nzTooltipTitle="{{getAttributes('relationToParticipant').tooltip}}" nzTooltipPlacement="topRight">
            <nz-select formControlName="relationToParticipant" class="ppl-select"
              [nzId]="getAccessibleInputId('relationToParticipant')" nzPlaceHolder="Select relation to consumer"
              [ngClass]="{'error': validationErrors['relationToParticipant'] }" nzAllowClear [nzDisabled]="formReadonly">
              @for(relation of relationshipOptions; track $index){
              <nz-option [nzLabel]="relation.lkpValueName" [nzValue]="relation.lkpValueCode"></nz-option>
              }
            </nz-select>
            @if (validationErrors['relationToParticipant']) {
            <span class="pl-1 error-message">{{validationErrors['relationToParticipant']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
      </div>
      }

      @if(isAttrAvailable('NPI')){
      <div nz-col [nzSm]="6" [nzXs]="24">
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSpan]="24" nzFor="NPI" class="ppl-form-label">{{getAttributes('NPI').label}}
            @if(getAttributes('NPI').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control [nzSpan]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('NPI')?.tooltip || ''" nzTooltipPlacement="topRight">
              <input nz-input formControlName="NPI" [id]="'NPI'  + sectionName" placeholder="{{getAttributes('NPI').placeholder}}"
                [ngClass]="{'error': validationErrors['NPI'] }" [readonly]="formReadonly || (isReadonly() && getFormControlValue('NPI'))" />
            </div>
            @if (validationErrors['NPI']) {
            <span class="pl-1 error-message">{{validationErrors['NPI']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
      </div>
      }

      @if(isAttrAvailable('additionalDemographics')){
      <div nz-col [nzSm]="24" [nzXs]="24">
        <nz-form-label [nzSpan]="24" nzFor="additionalDemographics" class="ppl-form-label"
          style="margin:8px 0 24px 0;">{{getAttributes('additionalDemographics').label}}
          @if(getAttributes('additionalDemographics').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-row>
          @if(isAttrAvailable('race')){
          <div nz-col nzFlex="1 1 auto" [nzXs]="24">
            <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
              <nz-form-label [nzSpan]="24" nzFor="race" class="ppl-form-label">{{getAttributes('race').label}}
                @if(getAttributes('race').mandatory === 'Y'){
                <span>*</span>
                }
              </nz-form-label>
              <nz-form-control [nzSpan]="24" class="ppl-form-control" nz-tooltip
                nzTooltipTitle="{{getAttributes('race').tooltip}}" nzTooltipPlacement="topRight">

                <nz-select formControlName="race" class="ppl-select"
                [nzId]="'race' + sectionName"
                [ngClass]="{'error': validationErrors['race'] }" nzAllowClear [nzDisabled]="formReadonly">
                @for(race of raceOptions; track $index){
                <nz-option [nzLabel]="race.lkpValueName" [nzValue]="race.lkpValueCode"></nz-option>
                }
              </nz-select>
                @if (validationErrors['race']) {
                <span class="pl-1 error-message">{{validationErrors['race']}}</span>
                }
              </nz-form-control>
            </nz-form-item>
          </div>
          }
          @if(isAttrAvailable('height')){
          <div nz-col nzFlex="1 1 auto" [nzXs]="24">
            <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
              <nz-form-label [nzSpan]="24" nzFor="height" class="ppl-form-label">{{getAttributes('height').label}}
                @if(getAttributes('height').mandatory === 'Y'){
                <span>*</span>
                }
              </nz-form-label>
              <nz-form-control [nzSpan]="24" class="ppl-form-control">
                <div nz-tooltip [nzTooltipTitle]="getAttributes('height')?.tooltip || ''" nzTooltipPlacement="topRight"
                  class="height-input-container ppl-form-control">
                  <nz-row [nzGutter]="16">
                    <nz-col [nzSm]="12" [nzXs]="24">
                      <input nz-input formControlName="heightFeet" [id]="'heightFeet'  + sectionName" placeholder="Feet"
                        [ngClass]="{'error': validationErrors['heightFeet']}" [readonly]="formReadonly" />
                    </nz-col>
                    <nz-col [nzSm]="12" [nzXs]="24">
                      <input nz-input formControlName="heightInches" [id]="'heightInches'  + sectionName"
                        placeholder="Inches" [ngClass]="{'error': validationErrors['heightInches']}" [readonly]="formReadonly" />
                    </nz-col>
                  </nz-row>
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>
          }
          @if(isAttrAvailable('weight')){
          <div nz-col nzFlex="1 1 auto" [nzXs]="24">
            <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
              <nz-form-label [nzSpan]="24" nzFor="weight" class="ppl-form-label">
                {{getAttributes('weight').label}}
                @if(getAttributes('weight').mandatory === 'Y'){
                <span>*</span>
                }
              </nz-form-label>
              <nz-form-control [nzSpan]="24" class="ppl-form-control">
                <div nz-tooltip [nzTooltipTitle]="getAttributes('weight')?.tooltip || ''" nzTooltipPlacement="topRight"
                  class="weight-input-container ppl-form-control">
                  <input nz-input formControlName="weight" [id]="'weight'  + sectionName"
                    placeholder="{{getAttributes('weight').placeholder}}"
                    [ngClass]="{'error': validationErrors['weight']}" [readonly]="formReadonly" />
                  <!-- <span class="unit-label">Pounds</span> -->
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>
          }
          @if(isAttrAvailable('eyeColor')){
          <div nz-col nzFlex="1 1 auto" [nzXs]="24">
            <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
              <nz-form-label [nzSpan]="24" nzFor="eyeColor" class="ppl-form-label">{{getAttributes('eyeColor').label}}
                @if(getAttributes('eyeColor').mandatory === 'Y'){
                <span>*</span>
                }
              </nz-form-label>
              <nz-form-control [nzSpan]="24" class="ppl-form-control" nz-tooltip
                nzTooltipTitle="{{getAttributes('eyeColor').tooltip}}" nzTooltipPlacement="topRight">
                <nz-select formControlName="eyeColor" class="ppl-select" [nzId]="'eyeColor'  + sectionName" nzShowArrow
                  [ngClass]="{'error': validationErrors['eyeColor'] }" [nzDisabled]="formReadonly">
                  @for(color of eyeColors; track $index){
                  <nz-option [nzLabel]="color.lkpValueName" [nzValue]="color.lkpValueCode"></nz-option>
                  }
                </nz-select>
                @if (validationErrors['eyeColor']) {
                <span class="pl-1 error-message">{{validationErrors['eyeColor']}}</span>
                }
              </nz-form-control>
            </nz-form-item>
          </div>
          }
        </nz-row>
        <nz-row>
          @if(isAttrAvailable('hairColor')){
          <div nz-col nzFlex="1 1 auto" [nzXs]="24">
            <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
              <nz-form-label [nzSpan]="24" nzFor="hairColor" class="ppl-form-label">{{getAttributes('hairColor').label}}
                @if(getAttributes('hairColor').mandatory === 'Y'){
                <span>*</span>
                }
              </nz-form-label>
              <nz-form-control [nzSpan]="24" class="ppl-form-control" nz-tooltip
                nzTooltipTitle="{{getAttributes('hairColor').tooltip}}" nzTooltipPlacement="topRight">
                <nz-select formControlName="hairColor" class="ppl-select" [nzId]="'hairColor'  + sectionName" nzShowArrow
                  [ngClass]="{'error': validationErrors['hairColor'] }" [nzDisabled]="formReadonly">
                  @for(color of hairColors; track $index){
                  <nz-option [nzLabel]="color.lkpValueName" [nzValue]="color.lkpValueCode"></nz-option>
                  }
                </nz-select>
                @if (validationErrors['hairColor']) {
                <span class="pl-1 error-message">{{validationErrors['hairColor']}}</span>
                }
              </nz-form-control>
            </nz-form-item>
          </div>
          }
          @if(isAttrAvailable('birthPlace')){
          <div nz-col nzFlex="1 1 auto" [nzXs]="24">
            <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
              <nz-form-label [nzSpan]="24" nzFor="birthPlace"
                class="ppl-form-label">{{getAttributes('birthPlace').label}}
                @if(getAttributes('birthPlace').mandatory === 'Y'){
                <span>*</span>
                }
              </nz-form-label>
              <nz-form-control [nzSpan]="24" class="ppl-form-control">
                <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
                  <input nz-input formControlName="birthCity" [id]="'birthCity'  + sectionName" placeholder="City" [readonly]="formReadonly" />
                </div>
              </nz-form-control>
              <nz-form-control [nzSpan]="24" class="ppl-form-control" style="margin: 0px 4px;">
                <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
                  <input nz-input formControlName="birthState" [id]="'state'  + sectionName" placeholder="State" [readonly]="formReadonly" />
                </div>
              </nz-form-control>
              <nz-form-control [nzSpan]="24" class="ppl-form-control">
                <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
                  <input nz-input formControlName="birthCountry" [id]="'country'  + sectionName"
                    placeholder="Country" [readonly]="formReadonly" />
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>
          }
        </nz-row>
      </div>
      }
    </nz-row>
    <nz-row>
      @if(isAttrAvailable('email') && isAttrAvailable('mobile')){
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSpan]="24" nzFor="email" [ngStyle]="{ 'margin-bottom':'0' }"
          class="ppl-form-label">Please enter Email Address or Mobile Number
          @if(getAttributes('email').mandatory === 'Y' && getAttributes('mobile').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
          <nz-form-control class="ppl-form-control">
            <input nz-input nz-tooltip nzTooltipTitle="{{getAttributes('email').tooltip}}" nzTooltipPlacement="topRight"
              formControlName="email" [id]="'email'  + sectionName" [ngClass]="{'error': validationErrors['email'] }"
              placeholder="{{getAttributes('email').placeholder}}" [readonly]="formReadonly" />
            @if (validationErrors['email']) {
            <span class="pl-1 error-message">{{validationErrors['email']}}</span>
            }
          </nz-form-control>
          <nz-form-control [nzSpan]="24">
            <input nz-tooltip nzTooltipTitle="{{getAttributes('mobile').tooltip}}" nzTooltipPlacement="topRight"
              nz-input [id]="'mobile'  + sectionName" formControlName="mobile"
              [ngClass]="{'error': validationErrors['mobile'] }"
              placeholder="{{getAttributes('mobile').placeholder}}" [readonly]="formReadonly" />
            @if (validationErrors['mobile']) {
            <span class="pl-1 error-message">{{validationErrors['mobile']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
      }
      @if(isAttrAvailable('email') && !isAttrAvailable('mobile')){
      <nz-col [nzSm]="6" [nzXs]="24">
        <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
          <nz-form-label [nzSpan]="24" nzFor="email" [ngStyle]="{ 'margin-bottom':'0' }"
            class="ppl-form-label">{{getAttributes('email').label}}
            @if(getAttributes('email').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSpan]="24">
            <input nz-input nz-tooltip nzTooltipTitle="{{getAttributes('email').tooltip}}" nzTooltipPlacement="topRight"
              formControlName="email" [id]="'email'  + sectionName" [ngClass]="{'error': validationErrors['email'] }"
              placeholder="{{getAttributes('email').placeholder}}" [readonly]="formReadonly" />
            @if (validationErrors['email']) {
            <span class="pl-1 error-message">{{validationErrors['email']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      }
      @if(isAttrAvailable('mobile') && !isAttrAvailable('email')){

        <nz-col [nzSm]="6" [nzXs]="24">
          <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
            <nz-form-label [nzSpan]="24" nzFor="mobile" class="ppl-form-label"
              [ngStyle]="{ 'margin-bottom':'0' }">{{getAttributes('mobile').label}}
              @if(getAttributes('mobile').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-tooltip nzTooltipTitle="{{getAttributes('mobile').tooltip}}" nzTooltipPlacement="topRight"
                nz-input [id]="'mobile'  + sectionName" formControlName="mobile"
                [ngClass]="{'error': validationErrors['mobile'] }"
                placeholder="{{getAttributes('mobile').placeholder}}" [readonly]="formReadonly" />
              @if (validationErrors['mobile']) {
              <span class="pl-1 error-message">{{validationErrors['mobile']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        }
      @if(isAttrAvailable('altPhone')){
      <nz-col [nzSm]="6" [nzXs]="24">
        <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
          <nz-form-label [nzSpan]="24" nzFor="altPhone" [ngStyle]="{ 'margin-bottom':'0' }"
            class="ppl-form-label">{{getAttributes('altPhone').label}}
            @if(getAttributes('altPhone').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSpan]="24">
            <input nz-tooltip nzTooltipTitle="{{getAttributes('altPhone').tooltip}}" nzTooltipPlacement="topRight"
              nz-input formControlName="altPhone" [ngClass]="{'error': validationErrors['altPhone'] }"
              [id]="'altPhone'  + sectionName" placeholder="{{getAttributes('altPhone').placeholder}}" [readonly]="formReadonly" />
            @if (validationErrors['altPhone']) {
            <span class="pl-1 error-message">{{validationErrors['altPhone']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      }
     
    </nz-row>

    @if(isAttrAvailable('permanentAddress')){
    <nz-form-item class="ppl-form-item">
      <div class="address-section">
        <nz-form-control class="ppl-form-control address-control">
          <ppl-association-details-address [addressForm]="getFormGroup('permanentAddress')" [is_permanent]="true"
            [statesList]="statesList" [validationErrors]="permanentAddressErrors" [formReadonly]="formReadonly || isReadonly()" ></ppl-association-details-address>
        </nz-form-control>
      </div>
    </nz-form-item>
    }

    <nz-row>
      @if(isAttrAvailable('yearsInState')){
      <nz-col [nzSm]="12" [nzXs]="24">
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="yearsInState"
            class="ppl-form-label">{{getAttributes('yearsInState').label}}
            @if(getAttributes('yearsInState').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control [nzSm]="12" class="ppl-form-control">
            <input nz-input nz-tooltip nzTooltipTitle="{{getAttributes('yearsInState').tooltip}}"
              nzTooltipPlacement="topRight" formControlName="yearsInState" [id]="'yearsInState'  + sectionName"
              [ngClass]="{'error': validationErrors['yearsInState'] }"
              placeholder="{{getAttributes('yearsInState').placeholder}}" [readonly]="formReadonly" />
            @if (validationErrors['yearsInState']) {
            <span class="pl-1 error-message">{{validationErrors['yearsInState']}}</span>
            }
            <div class="notes">
              <p>
                This response may require additional background checks during enrolment.
              </p>

            </div>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      }
    </nz-row>
    <nz-row [nzGutter]="16">
      @if(isAttrAvailable('emergencyContact')){
      <nz-col [nzSm]="24" [nzXs]="24">
          <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="emergencyContact"
            class="ppl-form-label pl-3">{{getAttributes('emergencyContact').label}}
            @if(getAttributes('emergencyContact').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
      </nz-col>
      <nz-col [nzSpan]="8">
        <nz-form-item>
          <nz-form-control class="ppl-form-control pl-3">
            <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
              <input nz-input formControlName="emergencyContactFirstName" [id]="'emergencyContactFirstName'  + sectionName"
                placeholder="First Name" [readonly]="formReadonly" />
            </div>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col [nzSpan]="8">
        <nz-form-item>
          <nz-form-control class="ppl-form-control" style="margin: 0 8px;">
            <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
              <input nz-input formControlName="emergencyContactLastName" [id]="'emergencyContactLastName'  + sectionName"
                placeholder="Last Name" class="reduced-width-inputs" [readonly]="formReadonly" />
            </div>
          </nz-form-control>
        </nz-form-item>
      </nz-col>

      <nz-col [nzSpan]="8">
        <nz-form-item>
          <nz-form-control class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
              <input nz-input formControlName="emergencyContactPhone" [id]="'emergencyContactPhone'  + sectionName"
                placeholder="Contact Number" class="reduced-width-inputs" [readonly]="formReadonly" />
            </div>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      }
    </nz-row>
    <nz-row class="ppl-new-form-control" [nzGutter]="16">
      @if(isAttrAvailable('registerPref')){
      <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
        <nz-form-label [nzSm]="24" [nzXs]="24" [nzFor]="getAccessibleInputId('registerPref')"
          class="ppl-form-label">{{getAttributes('registerPref').label}}
          @if(getAttributes('registerPref').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-col [nzSm]="8" [nzXs]="24">
          <nz-form-control class="ppl-form-control" nz-tooltip
            nzTooltipTitle="{{getAttributes('registerPref').tooltip}}" nzTooltipPlacement="topRight">
            <nz-select formControlName="registerPref" class="ppl-select" [nzId]="getAccessibleInputId('registerPref')"
              nzShowArrow [ngClass]="{'error': validationErrors['registerPref'] }" [nzDisabled]="formReadonly">
              @for (opt of participantRegisterMode; track $index) {
              <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
              }
            </nz-select>
            @if (validationErrors['registerPref']) {
            <span class="pl-1 error-message">{{validationErrors['registerPref']}}</span>
            }
          </nz-form-control>
        </nz-col>
      </nz-form-item>
      }
    </nz-row>

    @if(isAttrAvailable('nonProfitStatus')){
    <nz-form-item class="ppl-form-item">
      <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="nonProfitStatus"
        class="ppl-form-label">{{getAttributes('nonProfitStatus').label}}
        @if(getAttributes('nonProfitStatus').mandatory === 'Y'){
        <span>*</span>
        }</nz-form-label>
    </nz-form-item>
    <nz-row>
      <nz-col [nzSm]="6" [nzXs]="24">
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="taxExempt"
            class="ppl-form-label">{{getAttributes('taxExempt').label}}
            @if(getAttributes('taxExempt').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control class="ppl-form-control">

            <nz-select [nzMaxTagCount]="4" nzMode='default' formControlName="taxExempt" class="ppl-select"
            nzPlaceHolder="{{getAttributes('taxExempt').placeholder}}"
                [nzId]="'taxExempt' + sectionName"
                [ngClass]="{'error': validationErrors['taxExempt'] }" nzShowArrow [nzDisabled]="formReadonly">
                @for(opt of taxExemptOptions; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>

            @if (validationErrors['taxExempt']) {
            <span class="pl-1 error-message">{{validationErrors['taxExempt']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col [nzSm]="6" [nzXs]="24">
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="nprftDocumentationReceived"
            class="ppl-form-label">{{getAttributes('nprftDocumentationReceived').label}}
            @if(getAttributes('nprftDocumentationReceived').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control class="ppl-form-control">
            <nz-select [nzMaxTagCount]="4" nzMode='default' [ngClass]="{'error': validationErrors['nprftDocumentationReceived'] }"
              nzPlaceHolder="{{getAttributes('nprftDocumentationReceived').placeholder}}" nzId="nprftDocumentationReceived"
              formControlName="nprftDocumentationReceived" style="width:100%; margin-bottom: 16px" nzShowArrow [nzDisabled]="formReadonly">
              <nz-option [nzValue]="'yes'" [nzLabel]="'Yes'"></nz-option>
              <nz-option [nzValue]="'no'" [nzLabel]="'No'"></nz-option>
            </nz-select>
            @if (validationErrors['nprftDocumentationReceived']) {
            <span class="pl-1 error-message">{{validationErrors['nprftDocumentationReceived']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </nz-row>
    }

    <nz-row>
      @if(isAttrAvailable('profLicences')){
      <nz-col [nzSm]="6" [nzXs]="24">
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="profLicences"
            class="ppl-form-label">{{getAttributes('profLicences').label}}
            @if(getAttributes('profLicences').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('profLicences')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <nz-select [nzMaxTagCount]="4" nzMode='multiple' [ngClass]="{'error': validationErrors['profLicences'] }"
                nzPlaceHolder="{{getAttributes('profLicences').placeholder}}" [nzId]="'profLicences'  + sectionName"
                formControlName="profLicences" style="width:100%; margin-bottom: 16px" nzShowArrow [nzDisabled]="formReadonly">
                @for(opt of licences; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
            </div>
            @if (validationErrors['profLicences']) {
            <span class="pl-1 error-message">{{validationErrors['profLicences']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      }

      @if(isAttrAvailable('servicesRendered')){
      <nz-col [nzSm]="6" [nzXs]="24">
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="servicesRendered"
            class="ppl-form-label">{{getAttributes('servicesRendered').label}}
            @if(getAttributes('servicesRendered').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control class="ppl-form-control">
            <nz-select [nzMaxTagCount]="4" nzMode='multiple'
              [ngClass]="{'error': validationErrors['servicesRendered'] }"
              nzPlaceHolder="{{getAttributes('servicesRendered').placeholder}}" [nzId]="'servicesRendered'  + sectionName"
              formControlName="servicesRendered" style="width:100%; margin-bottom: 16px" nzShowArrow [nzDisabled]="formReadonly">
              @for(opt of services; track $index){
              <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
              }
            </nz-select>
            @if (validationErrors['servicesRendered']) {
            <span class="pl-1 error-message">{{validationErrors['servicesRendered']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      }
    </nz-row>
  </form>
}
