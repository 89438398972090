import { Component, OnInit } from '@angular/core';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzInputModule } from 'ng-zorro-antd/input';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CommonModule } from '@angular/common';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { SectionsService } from '../../../../services/sections.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ActivatedRoute, Router } from '@angular/router';
import { AccordionComponent } from '../../../../shared/components/accordion/accordion.component';
import { ClaimsService } from '../../../../services/claims.service';
import { performValidation } from '../../../../shared/utils/validation.util';
import { DateTimeFormatterService } from '../../../../shared/utils/date-time-formatter.service';
import { LookupValueItem } from '../../../../interfaces/lookup.interface';
import { Program } from '../../../../interfaces/user.interface';
import { PopupDialogComponent } from '../../../../shared/components/popup-dialog/popup-dialog.component';
import { LookupService } from '../../../../services/lookup.service';

@Component({
  selector: 'ppl-add-process-code',
  standalone: true,
  imports: [
    ButtonComponent,
    NzFormModule,
    ReactiveFormsModule,
    FormsModule,
    NzCardModule,
    NzInputModule,
    NzToolTipModule,
    CommonModule,
    NzDatePickerModule,
    NzToolTipModule,
    NzSelectModule,
    NzButtonModule,
    AccordionComponent,
    PopupDialogComponent,
  ],
  templateUrl: './add-process-code.component.html',
  styleUrl: './add-process-code.component.css',
})
export class AddProcessCodeComponent extends BaseComponent implements OnInit {
  processCodeForm!: FormGroup;
  radioOptions: LookupValueItem[] = [];
  providerTypes: any[] = [];
  codeCategories: LookupValueItem[] = [];
  pageTitle = 'Add New Process Code';
  mode = 'edit';
  savedData: any = {};
  isNew = true;
  program!: Program;
  show = false;
  confirmationMessage = '';
  serviceCodes: any;
  dispositions: LookupValueItem[] = [];

  constructor(
    private fb: FormBuilder,
    private sectionsService: SectionsService,
    private router: Router,
    private route: ActivatedRoute,
    private claimsService: ClaimsService,
    private dateTimeFormatterService: DateTimeFormatterService,
    private lookupService: LookupService
  ) {
    super();
  }

  ngOnInit(): void {
    this.processCodeForm = this.fb.group({
      code: [null],
      subcategoryLkp: [null],
      description: [''],
      internalMessage: [''],
      externalMessage: [''],
      allowOverride: [null],
      effectiveFromDate: [null],
      effectiveEndDate: [null],
      providerTypes: [[]],
      serviceCodes: [[]],
      applicableForAuth: [null],
      applicableForTs: [null],
      resolutionText: [''],
      dispositionLkp:['']
    });

    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    this.secData = this.sectionsService.getSectionData(
      this.program.program_code,
      'payroll'
    );

    this.providerTypes = this.lookupService.getProviderLookupValue();
    this.codeCategories = this.getLookup('process_code_cat');
    this.dispositions = this.getLookup('TS_DIS');
    this.radioOptions = this.getLookup('Yes_No');
    this.serviceCodes = this.lookupService.getTasLookupValue(
      'servicecodeDetailList'
    );
    const processCodes =
      this.claimsService.getFormData('process_code').processCodes[0];
    this.savedData = this.formatProcessCodeData(processCodes);

    this.route.paramMap.subscribe((params) => {
      const action = params.get('action')!;
      this.pageTitle =
        action == 'update'
          ? `Details for Process Code ${this.savedData.code}`
          : this.pageTitle;
      this.isNew = action === 'add';
      this.mode = action == 'update' ? 'read' : 'edit';
    });

  }

  getTasLookup(lookupArr: any, valueCheck: string | string[]) {
    if (Array.isArray(valueCheck)) {
      return valueCheck.map((c) => this.getTasLookupName(lookupArr, c));
    } else {
      return this.getTasLookupName(lookupArr, valueCheck);
    }
  }

  getTasLookupName(lookupArr: any, valueCode: string): string | undefined {
    const lookup = lookupArr.find((gen: any) => {
      return gen.serviceCodeDetailId === valueCode;
    });
    return lookup ? lookup.code : undefined;
  }

  getProvLookupValue(lookupArr: any[], valueCheck: string[]): string[] {
    return valueCheck.map((valueCode) => {
      const lookup = lookupArr.find((gen: any) => gen.code === valueCode);
      return lookup ? lookup.name : undefined;
    });
  }

  formatProcessCodeData(processCodes: any) {
    return {
      ...processCodes,
      allowOverride: processCodes.allowOverride ? 'Yes' : 'No',
      applicableForAuth: processCodes.applicableForAuth ? 'Yes' : 'No',
      applicableForTs: processCodes.applicableForTs ? 'Yes' : 'No',
      providerTypes: this.getProviderTypes(processCodes.providerTypes),
    };
  }

  getProviderTypes = (providers: any) => {
    return providers.map((providerType: any) => {
      const provider = this.providerTypes.find(
        (p) => p.code === providerType.providerTypeLkp
      );
      return provider ? provider.code : undefined;
    });
  };

  disabledStartDate = (startValue: Date): boolean => {
    const endDate = this.processCodeForm.get('effectiveEndDate')?.value;
    if (!startValue || !endDate || !(endDate instanceof Date)) {
      return false;
    }
    return startValue.getTime() > endDate.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    const startDate = this.processCodeForm.get('effectiveFromDate')?.value;
    if (!endValue || !startDate || !(startDate instanceof Date)) {
      return false;
    }
    return endValue.getTime() <= startDate.getTime();
  };

  goBack() {
    this.router.navigate(['/home/processCodeList']);
  }

  onEdit() {
    this.mode = 'edit';
    const data = {
      ...this.savedData,
      effectiveFromDate: this.formatDateString(this.savedData.effectiveFromDate),
      effectiveEndDate: this.formatDateString(this.savedData.effectiveEndDate)
    }
    this.processCodeForm.patchValue(data);
  }

  onClose() {
    this.show = false;
    this.claimsService.clearFormData();
    this.router.navigate(['/home/processCodeList']);
  }

  onAdd() {
    this.validationErrors = performValidation(
      this.processCodeForm.value,
      this.secData,
      'Next'
    );
    const payload = this.createPayload();
    if (Object.keys(this.validationErrors).length === 0) {
      this.claimsService.addProcessCode(payload).subscribe(
        (data) => {
          if (data.status == 200) {
            // this.confirmationMessage = `Process code ${this.processCodeForm.value.code} added successfully.`;
            // this.show = true;
            this.onClose();
          }
        },
        (error) => {
          console.error('Error while adding a process code: ', error);
        }
      );
    }
    else {
      return;
    }
  }

  createPayload() {
    return {
      ...this.processCodeForm.value,
      programCode: this.program.program_code,
      allowOverride: this.getBoolean(this.processCodeForm.value.allowOverride),
      applicableForAuth: this.getBoolean(
        this.processCodeForm.value.applicableForAuth
      ),
      applicableForTs: this.getBoolean(
        this.processCodeForm.value.applicableForTs
      ),
      effectiveFromDate: this.dateTimeFormatterService.formatToString(
        new Date(this.processCodeForm.value.effectiveFromDate)
      ),
      effectiveEndDate: this.dateTimeFormatterService.formatToString(
        new Date(this.processCodeForm.value.effectiveEndDate)
      ),
      providerTypes: this.convertProviderTypes(
        this.processCodeForm.value.providerTypes
      ),
    };
  }

  convertProviderTypes(providerType: string[]): { providerTypeLkp: string }[] {
    return providerType.map((type) => ({
      providerTypeLkp: type,
    }));
  }

  convertServiceCodes(serviceCode: string[]): {
    code: string;
    description: string;
    effectiveFromDate: string;
    effectiveEndDate: string;
  }[] {
    const defaultDescription = 'Default service description';
    const effectiveFromDate = '2024-10-01';
    const effectiveEndDate = '2024-12-01';

    return serviceCode.map((code) => ({
      code,
      description: defaultDescription, // Apply default description for all codes
      effectiveFromDate,
      effectiveEndDate,
    }));
  }

  getBoolean(val: string) {
    return val === 'Yes';
  }

  onCancel() {
    this.mode = 'read';
    this.validationErrors = {};
    this.processCodeForm.reset();
  }

  onUpdate() {
    this.validationErrors = performValidation(
      this.processCodeForm.value,
      this.secData,
      'Next'
    );
    const payload = this.createPayload();
    const id =
      this.claimsService.getFormData('process_code').processCodes[0]
        .processCodeDetailId;
    if (Object.keys(this.validationErrors).length === 0) {
      this.claimsService.updateProcessCode(payload, id).subscribe(
        (data) => {
          if (data.status == 200) {
            this.claimsService
              .fetchClaimsData(this.program.program_code, id, 'processCode')
              .subscribe((data: any) => {
                this.savedData = this.formatProcessCodeData(
                  data.responsedata.processCodes[0]
                );
              });
            this.mode = 'read';
          }
        },
        (error) => {
          console.error('Error while updating process code: ', error);
        }
      );
    } else {
      return;
    }
  }
}
