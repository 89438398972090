<ng-container>
    @if(isPageRestrictionLoaded){
    <div class="ppl-container-body">
        <div class="ppl-container-head">
            Personal Assistant Registration
            <div class="action">
                <button nz-button nz-dropdown [nzDropdownMenu]="menu4" class="fancy-button" class="primary-button">
                    <span style="font-weight:600">Add Consumer Associate<i class="fa fa-chevron-right" aria-hidden="true" style="margin-left: 4px"></i></span>
                </button>
                <nz-dropdown-menu #menu4="nzDropdownMenu">
                    <ul nz-menu>
                        @for (provider of providerTypes; track $index) {
                            <li nz-menu-item (click)="onNewEnrollment(provider.code)">
                                New {{provider.name}}
                            </li>
                            }
                        <!-- <li nz-menu-item (click)="onNewEnrollment('employer')">New Employer</li> -->
                        <!-- <li nz-menu-item (click)="onNewEnrollment('authRepresentative')">New Authorized Representative</li>
                        <li nz-menu-item (click)="onNewEnrollment('provider')">New Individual Provider</li>
                        <li nz-menu-item (click)="onNewEnrollment('vendor')">New Vendor</li>
                        <li nz-menu-item (click)="onNewEnrollment('contractor')">New Independent Contractor</li>
                        <li nz-menu-item (click)="onNewEnrollment('legalGuardian')">New Legal Guardian</li>
                        <li nz-menu-item (click)="onNewEnrollment('poa')">New POA</li> -->
                    </ul>
                </nz-dropdown-menu>

                <!-- <ppl-button label="Create New Individual Provider" icon="fa fa-plus" [customClass]="'primary-button'"
                    [iconPosition]="'left'" [authorizeButton]="[pageId, 'newParticipantEnrollment']"
                    (click)="onNewEnrollment('provider')"></ppl-button>
                <ppl-button label="Create New Vendor" icon="fa fa-plus" [customClass]="'primary-button'"
                    [iconPosition]="'left'" [authorizeButton]="[pageId, 'newProviderReferral']"
                    (click)="onNewEnrollment('vendor')" style="margin: 0 8px"></ppl-button>
                <ppl-button label="Create New Independent Contractor" icon="fa fa-plus" [customClass]="'primary-button'"
                    [iconPosition]="'left'" [authorizeButton]="[pageId, 'newProviderReferral']"
                    (click)="onNewEnrollment('contractor')"></ppl-button>
                    <ppl-button label="Create New Employer" icon="fa fa-plus" [customClass]="'primary-button'"
                    [iconPosition]="'left'" [authorizeButton]="[pageId, 'newParticipantEnrollment']"
                    (click)="onNewEnrollment('employer')"></ppl-button>
                    <ppl-button label="Create New Legal Guardian Provider" icon="fa fa-plus" [customClass]="'primary-button'"
                    [iconPosition]="'left'" [authorizeButton]="[pageId, 'newParticipantEnrollment']"
                    (click)="onNewEnrollment('legalGuardian')"></ppl-button>
                    <ppl-button label="Create New Authorized Representative" icon="fa fa-plus" [customClass]="'primary-button'"
                    [iconPosition]="'left'" [authorizeButton]="[pageId, 'newParticipantEnrollment']"
                    (click)="onNewEnrollment('authRepresentative')"></ppl-button>
                    <ppl-button label="Create New POA" icon="fa fa-plus" [customClass]="'primary-button'"
                    [iconPosition]="'left'" [authorizeButton]="[pageId, 'newParticipantEnrollment']"
                    (click)="onNewEnrollment('poa')"></ppl-button> -->
            </div>
        </div>
        <div class="ppl-container">
            <div class="ppl-content-container">
                <div class="table-style">
                    <ppl-list-table
                        [pageConfig]="programName"
                        [sharedDataKey]="sharedDataKey"
                        (rowDetailEvent)="rowClicked($event)"
                        [loadPage]="lazyLoad"
                        [columnsToBeHidden]="columnsToBeHidden"
                        [actionsToBeHidden]="actionsToBeHidden"
                        [jsonList]="'list_provider_enrollments'"
                        rowDataAccessibilityKey="ppl_provider_id"
                    >
                    </ppl-list-table>
                </div>
            </div>
        </div>
    </div>
    }

</ng-container>