import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { ERROR_MESSAGES } from '../constants/error-message.constants';
import { LoggerService } from './logger.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private logger : LoggerService, private notificationService : NotificationService) { }

  handleError(error: any, optionsMessage?: string) {

    // Handle different error types
    if (error instanceof HttpErrorResponse) {
      
      // Handle HTTP errors
      const additionalInfo = optionsMessage ? optionsMessage : 'HTTP error:'
      this.logger.debug(additionalInfo, error);
      
      // Handle HTTP errors with 401
      if(error?.status == 401){
        this.notificationService.alert('error', "Error:401", ERROR_MESSAGES.ERROR_401);
      }else if(error?.status == 504){
        this.notificationService.alert("error", "Error:504", ERROR_MESSAGES.ERROR_504);
      }else if(error?.status == 500){
        this.notificationService.alert("error", "Error:500", ERROR_MESSAGES.ERROR_500);
      }else if(error?.status == 502){
        this.notificationService.alert("error", "Error:502", ERROR_MESSAGES.ERROR_502);
      }else if(error?.status == 0){
        this.notificationService.alert("error", "Error:0", ERROR_MESSAGES.ERROR_0);
      }else if(error?.status == 406){
        const err: any = error as any;
        const message = err.error.responsemsg.map((err: any) => err.desc);
        this.notificationService.alert("error", "Error:406", message);
      }else{
        this.notificationService.alert("error", "Error", error.error.message);
      }

    } else if (error instanceof Error) {
      
      // Handle other errors
      const additionalInfo = optionsMessage ? optionsMessage : 'Application error:'
      this.logger.debug(additionalInfo, error);
    
    } else {
    
      // Handle unknown error types
      const additionalInfo = optionsMessage ? optionsMessage : 'Unknown error:'
      this.logger.debug(additionalInfo, error);
    
    }
  }
}
