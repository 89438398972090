import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from "../button/button.component";

@Component({
  selector: 'ppl-alertbox',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './alertbox.component.html',
  styleUrl: './alertbox.component.css'
})
export class AlertboxComponent {
  @Input() isAlertVisible = false;
  @Output() isAlertVisibleChange = new EventEmitter<boolean>();
  @Input() title ='';
  @Input() message = '';
  @Input() success = false;
  @Output() onClose = new EventEmitter<void>();

  closeAlert(): void {
    this.isAlertVisible = false;
    this.isAlertVisibleChange.emit(this.isAlertVisible); // Emit changes when closing the alert
    this.onClose.emit();
  }
}