<form nz-form nz-row [formGroup]="authorizationsForm" class="ppl-p0">
    @if (!isConsumerSelected || mode==='edit'){
    @if(mode==='add') {
    <div class="row pb-3 mt-0" style="width: 100%">
        <h4 class="step-header">Step 1: Search Consumer</h4>
    </div>

    }
    <div class="row pb-3 mt-0 ml-0" style="width: 100%">
        @if(isAttrAvailable('consumerFirstName')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="consumerFirstName"
                    class="ppl-form-label">{{getAttributes('consumerFirstName').label}}
                    @if(getAttributes('consumerFirstName').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('consumerFirstName')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="consumerFirstName" id="consumerFirstName"
                            placeholder="{{getAttributes('consumerFirstName').placeholder}}"
                            [ngClass]="{'error': validationErrors['consumerFirstName'] }" />
                    </div>
                    @if (validationErrors['consumerFirstName']) {
                    <span class="pl-1 error-message">{{validationErrors['consumerFirstName']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }

        @if(isAttrAvailable('consumerLastName')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="consumerLastName"
                    class="ppl-form-label">{{getAttributes('consumerLastName').label}}
                    @if(getAttributes('consumerLastName').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('consumerLastName')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="consumerLastName" id="consumerLastName"
                            placeholder="{{getAttributes('consumerLastName').placeholder}}"
                            [ngClass]="{'error': validationErrors['consumerLastName'] }" />
                    </div>
                    @if (validationErrors['consumerLastName']) {
                    <span class="pl-1 error-message">{{validationErrors['consumerLastName']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }

        @if(isAttrAvailable('medicaid')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="medicaid"
                    class="ppl-form-label">{{getAttributes('medicaid').label}}
                    @if(getAttributes('medicaid').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('medicaid')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="medicaid" id="medicaid"
                            placeholder="{{getAttributes('medicaid').placeholder}}"
                            [ngClass]="{'error': validationErrors['medicaid'] }" />
                    </div>
                    @if (validationErrors['medicaid']) {
                    <span class="pl-1 error-message">{{validationErrors['medicaid']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }

        @if(isAttrAvailable('consumerPPLId')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="consumerPPLId"
                    class="ppl-form-label">{{getAttributes('consumerPPLId').label}}
                    @if(getAttributes('consumerPPLId').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('consumerPPLId')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="consumerPPLId" id="consumerPPLId"
                            placeholder="{{getAttributes('consumerPPLId').placeholder}}"
                            [ngClass]="{'error': validationErrors['consumerPPLId'] }" />
                    </div>
                    @if (validationErrors['consumerPPLId']) {
                    <span class="pl-1 error-message">{{validationErrors['consumerPPLId']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
    </div>
    }
    @if(isConsumerSelected) {
    <div class="row pb-3 mt-0" style="width: 100%">
        <h4 class="step-header">Step 3: Authorization Details</h4>
    </div>
    <div class="row pb-3 mt-0 mb-1" style="width: 100%">
        <h5>User Info:</h5>
    </div>
    <div nz-row style="width: 100%" class="ml-3">
        <div nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="consumerFirstName" class="ppl-form-label">
                    {{getAttributes('consumerFirstName').label}}
                </nz-form-label>
                <nz-form-label [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    {{selectedConsumer.consumerFirstName}}
                </nz-form-label>
            </nz-row>

        </div>
        <div nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="consumerLastName" class="ppl-form-label">
                    {{getAttributes('consumerLastName').label}}
                </nz-form-label>
                <nz-form-label [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    {{selectedConsumer.consumerLastName}}
                </nz-form-label>
            </nz-row>

        </div>
        <div nz-col nzSpan="12" class="ppl-form-item nz-padding-left">

            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="medicaid" class="ppl-form-label">
                    {{getAttributes('medicaid').label}}
                </nz-form-label>
                <nz-form-label [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    {{selectedConsumer.medicaid}}
                </nz-form-label>
            </nz-row>


        </div>
        <div nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="consumerPPLId" class="ppl-form-label">
                    {{getAttributes('consumerPPLId').label}}
                </nz-form-label>
                <nz-form-label [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    {{selectedConsumer.consumerPPLId}}
                </nz-form-label>
            </nz-row>
        </div>
    </div>
    }

    @if(isConsumerSelected || mode==='edit') {
    @if(mode==='add') {
    <div class="row pb-3 mt-5 mb-3" style="width: 100%; margin-top: 5px">
        <h5>Auth Details:</h5>
    </div>
    }
    <div class="row" style="width: 100%; margin-left: 0">
        @if (mode==='edit') {
        @if(isAttrAvailable('pplAuth')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="pplAuth"
                    class="ppl-form-label">{{getAttributes('pplAuth').label}}
                    @if(getAttributes('pplAuth').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('pplAuth')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="pplAuth" id="pplAuth"
                            placeholder="{{getAttributes('pplAuth').placeholder}}"
                            [ngClass]="{'error': validationErrors['pplAuth'] }" />
                    </div>
                    @if (validationErrors['pplAuth']) {
                    <span class="pl-1 error-message">{{validationErrors['pplAuth']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
        }
        @if(isAttrAvailable('clientAuth')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="clientAuth"
                    class="ppl-form-label">{{getAttributes('clientAuth').label}}
                    @if(getAttributes('clientAuth').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('clientAuth')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="clientAuth" id="clientAuth"
                            placeholder="{{getAttributes('clientAuth').tooltip}}"
                            [ngClass]="{'error': validationErrors['clientAuth'] }" />
                    </div>
                    @if (validationErrors['clientAuth']) {
                    <span class="pl-1 error-message">{{validationErrors['clientAuth']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }

        @if(isAttrAvailable('serviceCode')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="serviceCode"
                    class="ppl-form-label">{{getAttributes('serviceCode').label}}
                    @if(getAttributes('serviceCode').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('serviceCode')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="serviceCode" nzMode='default' class="ppl-select" id="serviceCode"
                            nzPlaceHolder="Select Service code" nzShowSearch nzAllowClear
                            [ngClass]="{'error': validationErrors['serviceCode'] }">
                            @for(opt of serviceCodesList; track $index){
                            <nz-option [nzLabel]="opt.code" [nzValue]="opt.serviceCodeDetailId"></nz-option>
                            }
                        </nz-select>
                    </div>
                    @if (validationErrors['serviceCode']) {
                    <span class="pl-1 error-message">{{validationErrors['serviceCode']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }

        @if(isAttrAvailable('diagnosisId')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="diagnosisId"
                    class="ppl-form-label">{{getAttributes('diagnosisId').label}}
                    @if(getAttributes('diagnosisId').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('diagnosisId')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="diagnosisId" nzMode='default' class="ppl-select" id="diagnosisId"
                            nzPlaceHolder="Select Primary Diagnosis code" nzShowSearch nzAllowClear
                            [ngClass]="{'error': validationErrors['diagnosisId'] }">
                            @for(opt of diagnosisCodesList; track $index){
                            <nz-option [nzLabel]="opt.description" [nzValue]="opt.diagnosisDetailId"></nz-option>
                            }
                        </nz-select>
                    </div>
                    @if (validationErrors['diagnosisId']) {
                    <span class="pl-1 error-message">{{validationErrors['diagnosisId']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('svcAdministration')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="svcAdministration"
                    class="ppl-form-label">{{getAttributes('svcAdministration').label}}
                    @if(getAttributes('svcAdministration').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>

                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('svcAdministration')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="serviceAdminLkp" nzMode='default' class="ppl-select"
                            id="svcAdministration" nzPlaceHolder="Select Service Administration" nzShowSearch
                            nzAllowClear [ngClass]="{'error': validationErrors['svcAdministration'] }">
                            @for(opt of svcAdminList; track $index){
                            <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                            }
                        </nz-select>
                    </div>
                    @if (validationErrors['svcAdministration']) {
                    <span class="pl-1 error-message">{{validationErrors['svcAdministration']}}</span>
                    }
                </nz-form-control>

            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('orgName')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="orgName"
                    class="ppl-form-label">{{getAttributes('orgName').label}}
                    @if(getAttributes('orgName').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>

                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('orgName')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="orgName" nzMode='default' class="ppl-select" id="orgName"
                            nzPlaceHolder="Select MCO ID" nzShowSearch nzAllowClear
                            [ngClass]="{'error': validationErrors['orgName'] }">
                            @for(opt of mcoEntries; track $index){
                            <nz-option [nzLabel]="opt.orgName" [nzValue]="opt.orgId"></nz-option>
                            }
                        </nz-select>
                    </div>
                    @if (validationErrors['orgName']) {
                    <span class="pl-1 error-message">{{validationErrors['orgName']}}</span>
                    }
                </nz-form-control>

            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('hiosIdLkp')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="hiosIdLkp"
                    class="ppl-form-label">{{getAttributes('hiosIdLkp').label}}
                    @if(getAttributes('hiosIdLkp').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>

                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('hiosIdLkp')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="hiosIdLkp" nzMode='default' class="ppl-select" id="hiosIdLkp"
                            nzPlaceHolder="Select HIOS ID" nzShowSearch nzAllowClear
                            [ngClass]="{'error': validationErrors['hiosIdLkp'] }">
                            @for(opt of hiosList; track $index){
                            <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                            }
                        </nz-select>
                    </div>
                    @if (validationErrors['hiosIdLkp']) {
                    <span class="pl-1 error-message">{{validationErrors['hiosIdLkp']}}</span>
                    }
                </nz-form-control>

            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('planId')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="planId"
                    class="ppl-form-label">{{getAttributes('planId').label}}
                    @if(getAttributes('planId').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>

                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('planId')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="planId" nzMode='default' class="ppl-select" id="planId"
                            nzPlaceHolder="Select Plan ID" nzShowSearch nzAllowClear
                            [ngClass]="{'error': validationErrors['planId'] }">
                            @for(opt of planIdList; track $index){
                            <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                            }
                        </nz-select>
                    </div>
                    @if (validationErrors['planId']) {
                    <span class="pl-1 error-message">{{validationErrors['planId']}}</span>
                    }
                </nz-form-control>

            </nz-row>
        </nz-form-item>
        }

        @if(isAttrAvailable('modifierCode1')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="modifierCode1"
                    class="ppl-form-label">{{getAttributes('modifierCode1').label}}
                    @if(getAttributes('modifierCode1').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('modifierCode1')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="modifierCode1" nzMode='default' class="ppl-select"
                            id="modifierCode1" nzPlaceHolder="Select Modifier" nzShowSearch nzAllowClear
                            [ngClass]="{'error': validationErrors['modifierCode1'] }">
                            @for(opt of modifiersList; track $index){
                            <nz-option [nzLabel]="opt.code" [nzValue]="opt.modifierDetailId"></nz-option>
                            }
                        </nz-select>
                    </div>
                    @if (validationErrors['modifierCode1']) {
                    <span class="pl-1 error-message">{{validationErrors['modifierCode1']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
    </div>
    <!-- <div class="row" style="width: 100%; margin-left: 0">
    </div> -->
    <div class="row" style="width: 100%; margin-left: 0">

        @if(isAttrAvailable('units')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="units"
                    class="ppl-form-label">{{getAttributes('units').label}}
                    @if(getAttributes('units').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('units')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="units" id="units"
                            placeholder="{{getAttributes('units').tooltip}}"
                            [ngClass]="{'error': validationErrors['units'] }" />
                    </div>
                    @if (validationErrors['units']) {
                    <span class="pl-1 error-message">{{validationErrors['units']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('unitType')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="unitType"
                    class="ppl-form-label">{{getAttributes('unitType').label}}
                    @if(getAttributes('unitType').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>

                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('unitType')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="unittypeLkp" nzMode='default' class="ppl-select" id="unitType"
                            nzPlaceHolder="Select Unit Type" nzShowSearch nzAllowClear
                            [ngClass]="{'error': validationErrors['unitType'] }">
                            @for(opt of unitTypeList; track $index){
                            <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                            }
                        </nz-select>
                    </div>
                    @if (validationErrors['unitType']) {
                    <span class="pl-1 error-message">{{validationErrors['unitType']}}</span>
                    }
                </nz-form-control>

            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('dollars')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="dollars"
                    class="ppl-form-label">{{getAttributes('dollars').label}}
                    @if(getAttributes('dollars').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('dollars')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="dollars" id="dollars"
                            placeholder="{{getAttributes('dollars').tooltip}}"
                            [ngClass]="{'error': validationErrors['dollars'] }" />
                    </div>
                    @if (validationErrors['dollars']) {
                    <span class="pl-1 error-message">{{validationErrors['dollars']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('startDate')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="startDate"
                    class="ppl-form-label">{{getAttributes('startDate').label}}
                    @if(getAttributes('startDate').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('startDate')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-date-picker formControlName="startDate" id="startDate" [nzFormat]="'MM/dd/yyyy'"
                            [nzPlaceHolder]="getAttributes('startDate').placeholder" [nzDisabledDate]=""
                            [ngClass]="{'error': validationErrors['startDate'] }"></nz-date-picker>
                    </div>
                    @if (validationErrors['startDate']) {
                    <span class="pl-1 error-message">{{validationErrors['startDate']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('endDate')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="endDate"
                    class="ppl-form-label">{{getAttributes('endDate').label}}
                    @if(getAttributes('endDate').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('endDate')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-date-picker formControlName="endDate" id="endDate" [nzFormat]="'MM/dd/yyyy'"
                            [nzPlaceHolder]="getAttributes('endDate').placeholder" [nzDisabledDate]=""
                            [ngClass]="{'error': validationErrors['endDate'] }"></nz-date-picker>
                    </div>
                    @if (validationErrors['endDate']) {
                    <span class="pl-1 error-message">{{validationErrors['endDate']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
    </div>
    }


</form>

@if(isNew){
@if (!isConsumerSelected && mode==='add') {
<div class="custom-row">
    <ppl-button label="Search" icon="fa fa-check-circle" [customClass]="'primary-button'" [iconPosition]="'right'"
        (click)="onConsumerSearch()"></ppl-button>
</div>
<!-- <div class="row">
    <p>* Please search and select a consumer to add an authorization.</p>
</div> -->
@if(showSearchError) {
<p class="pl-1 error-message">Please enter atleast one search criteria.</p>
}
}
}

@if (showConsumersList) {
<div class="row" style="width: 100%">
    <h4 class="step-header">Step 2: Select Consumer</h4>
</div>
<div class="table-style">
    <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true" [data]="gridData"
        [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="true" [rowsToDisplay]="10"
        [loadPage]="true" (gridAction)="action($event)" [actionButtons]="actionButtons">
    </ppl-list-table>
</div>
}

@if(isNew){
@if(isConsumerSelected) {
<div class="custom-row">
    <ppl-button label="Submit" icon="fa fa-check-circle" [customClass]="'alt-button'" [iconPosition]="'right'"
        (click)="onAdd()"></ppl-button>
    <ppl-button label="Submit & Add more" icon="fa fa-check-circle" [customClass]="'alt-button'"
        [iconPosition]="'right'" (click)="onAddMoreAuths()"></ppl-button>
    <ppl-button label="Cancel and select User" icon="fa fa-ban" [customClass]="'alt-button'" [iconPosition]="'right'"
        (click)="onCancelUser()"></ppl-button>
</div>
}
@if (mode==='edit') {
<div class="custom-row">
    @if(mode==="edit" ){
    <ppl-button label="Cancel" icon="fa fa-ban" [customClass]="'alt-button'" [iconPosition]="'left'"
        (click)="onCancelUpdate()"></ppl-button>
    <ppl-button label="Save" icon="fa fa-floppy-o" [customClass]="'alt-button'" [iconPosition]="'left'"
        (click)="onUpdate()"></ppl-button>
    }
</div>
}

@if (showAddedAuths) {
<div class="row" style="width: 100%">
    <h5>Authorizations Added</h5>
</div>
<div class="table-style">
    <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="false" [data]="authsInSession"
        [disableSelection]="true" [cols]="authCols" [gridTitle]="false" [pagination]="false" [loadPage]="true"
        (gridAction)="action($event)" [actionsToBeHidden]="actionsToBeHidden">
    </ppl-list-table>
</div>
}

}