import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { ListTableComponent } from '../../../../shared/components/list-table/list-table.component';
import { STATIC_DATA } from '../../../../shared/constants/static-data.constants';
import { PAGE_CONSTANTS } from '../../../../shared/constants/page.constants';
import { PageConfig } from '../../../../shared/authorization/auth.config';
import { AuthorizationUtility } from '../../../../shared/authorization/auth.utility';
import { SharedDataService } from '../../../../shared/services/shared-data-service';
import { AuthorizationService } from '../../../../shared/authorization/authorization.service';
import { SectionsService } from '../../../../services/sections.service';
import { ClaimsService } from '../../../../services/claims.service';

@Component({
  selector: 'ppl-participant-provvider-rates',
  standalone: true,
  imports: [ButtonComponent, ListTableComponent],
  templateUrl: './participant-provider-rates-list.component.html',
  styleUrl: './participant-provider-rates-list.component.css',
})
export class ParticipantProviderRatesListComponent {
  sharedDataKey: string = STATIC_DATA.SHARED_PARTICIPANT_PROVIDER_RATES_DATA;
  pageId: string = PAGE_CONSTANTS.PARTICIPANT_PROVIDER_RATES_GRID_PAGE;
  columnsToBeHidden: string[] = [];
  actionsToBeHidden: string[] = [];
  isPageRestrictionLoaded = false;
  programName!: string;
  lazyLoad = true;

  constructor(
    private router: Router,private authService: AuthorizationService,
    private sharedDataService: SharedDataService, private authUtility: AuthorizationUtility,
    private sectionService: SectionsService, private claimsService: ClaimsService
  ) {
  }

  ngOnInit() {
    const selectedProgram = JSON.parse(localStorage.getItem('selected_program')!);
    this.programName = selectedProgram.program_code;
    this.setPageAccess();
  }
  onAddParticipantProviderRates() {
    this.router.navigate(['/home/addParticipantProviderRates','add']);
  }

  rowClicked(rowDetail: any) {
    const program = JSON.parse(
      localStorage.getItem(STATIC_DATA.SELECTED_PROGRAM)!
    );
    localStorage.setItem('lead_entry', 'associationRates');
    this.sectionService.setFlow('associationRates');
    localStorage.setItem('selected_id', rowDetail.ptcPrvdrXRatesId);
      this.claimsService.getParticipantProviderRates(
          program.program_code,
          rowDetail.ptcPrvdrXRatesId,
          false
        )
        .subscribe((data: any) => {
          this.claimsService.setFormData('association_rates', data.responsedata);
          setTimeout(() => {
            this.router.navigate(['/home/addParticipantProviderRates', 'update']);
          }, 200);
        });
  }

  private setPageAccess() {
    const pageConfig = this.authUtility.getPageConfig(this.pageId);
    if (!pageConfig || !pageConfig.pageName) {
      this.authService.fetchPageAuthorization(this.programName, this.pageId, "LIST").subscribe(response => {
        this.setRestrictions(response);
      })
    } else {
      this.setRestrictions(pageConfig);
    }
  }

  private setRestrictions(pageConfig: PageConfig) {
    if (pageConfig) {
      this.columnsToBeHidden = this.authUtility.getHiddenColumns(pageConfig, this.pageId);
      this.actionsToBeHidden = this.authUtility.getHiddenActions(pageConfig, this.pageId);
    }
    this.isPageRestrictionLoaded = true;
  }

  clearData() {
    this.sharedDataService.clearData(this.sharedDataKey);
  }
}
