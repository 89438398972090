[
  {
    "menu_label": "Referral",
    "menu_id": "referral",
    "display": "y",
    "landing_component": "participantLeadsList"
  },
  {
    "menu_label": "Enrollment",
    "menu_id": "enrollment",
    "display": "y",
    "landing_component": "enrollmentList"
  },
  {
    "menu_label": "Authorizations",
    "menu_id": "authorizations",
    "display": "y"
  },
  {
    "menu_label": "Vendor Pre-Registration",
    "menu_id": "vendorPreRegistration",
    "display": "y"
  },
  {
    "menu_label": "Dashboard",
    "menu_id": "dashboard",
    "display": "y"
  },
  {
    "menu_label": "Reporting",
    "menu_id": "reporting",
    "display": "y"
  },
  {
    "menu_label": "Documents",
    "menu_id": "documents",
    "display": "y"
  },
  {
    "menu_label": "Admin Tools",
    "menu_id": "adminTools",
    "display": "y"
  }
]