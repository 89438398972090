import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { routes } from './app.routes';
import { BrowserModule } from '@angular/platform-browser';
import {
  provideHttpClient,
  withInterceptorsFromDi,
  HTTP_INTERCEPTORS,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { provideNoopAnimations } from '@angular/platform-browser/animations';

import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import {
  MsalInterceptor,
  MSAL_INSTANCE,
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalService,
  MsalGuard,
  MsalBroadcastService,
} from '@azure/msal-angular';
import { environment } from '../environments/environment';
/* import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list'; */
import { authInterceptor } from './interceptors/auth.interceptor';
import { DatePipe } from '@angular/common';
import { errorInterceptor } from './interceptors/error.interceptor';
import { SharedDataService } from './shared/services/shared-data-service';
import { loaderInterceptor } from './interceptors/loader.interceptor';
import { GlobalErrorHandlerService } from './shared/services/global-error-handler.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthorizationUtility } from './shared/authorization/auth.utility';
import { AuthorizationService } from './shared/authorization/authorization.service';

interface Config {
  clientId: string;
  authority: string;
  redirectUri: string;
  postLogoutRedirectUri: string;
  knownAuthorities?: string[];
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  const config: Config = {
    clientId: environment.msalConfig.auth.clientId,
    authority: environment.b2cPolicies.authorities.signUpSignIn.authority ? environment.b2cPolicies.authorities.signUpSignIn.authority : environment.msalConfig.auth.authority,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  };

  if (environment.b2cPolicies.authorities.signUpSignIn.authority) {
    config['knownAuthorities'] = [environment.b2cPolicies.authorityDomain]
  }

  return new PublicClientApplication({
    auth: config,
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, string[]>();
  protectedResourceMap.set(
    environment.apiConfig.uri,
    environment.apiConfig.scopes
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes],
    },
    loginFailedRoute: '/login-failed',
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withInMemoryScrolling({scrollPositionRestoration: 'top'})),
    importProvidersFrom(
      BrowserModule,
      /* MatButtonModule,
      MatToolbarModule,
      MatListModule,
      MatMenuModule */
    ),
    provideNoopAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withFetch(), withInterceptors([authInterceptor, errorInterceptor, loaderInterceptor])),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    DatePipe,
    SharedDataService,
    NzModalService,
    AuthorizationUtility,
    AuthorizationService
  ],
};
