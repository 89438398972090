/* src/app/components/leads/referral-logic/referral-logic.component.css */
.ant-form-item {
  flex-direction: column;
}
.main {
  width: 42%;
  margin: auto;
}
::ng-deep .ant-form-item-label > label {
  padding-top: 0px;
}
h2 {
  padding-top: 10px;
}
.ppl-description {
  padding-top: 6px !important;
}
.ppl-form-item {
  padding: 0 8px;
}
.logic-section {
  width: 34%;
}
.action {
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  color: gray;
}
.referlogic-ppl-container-body {
  display: flex;
  height: 854px;
  padding: 48px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 24px;
  background: rgba(254, 255, 255, 0.51);
}
.referral-container {
  display: flex;
  padding: 8px;
  margin: 12px;
  align-items: start;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  border: 2px solid #FFF;
  background:
    linear-gradient(
      82deg,
      rgba(248, 248, 248, 0.35) -14.04%,
      rgba(240, 240, 240, 0.49) 45.53%,
      rgba(255, 253, 253, 0.79) 110.07%);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}
/*# sourceMappingURL=referral-logic.component-UHEHKVRB.css.map */
