/* src/app/components/leads/new-referral/new-referral.component.css */
.steps {
  position: sticky;
  top: 0;
}
.steps-section {
  padding: 8px 16px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #f0f0f0;
}
.action {
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  color: gray;
}
.btn-color {
  padding: 4px;
  border-radius: 4px;
  margin-right: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #303030;
  background: #c7bcff;
  border-radius: 8px;
  border: 2px solid var(--Backgrounds-Primary, #3d0072);
}
.options {
  cursor: pointer;
  width: fit-content;
}
.Sidebar {
  height: calc(100vh - 210px);
  display: flex;
  width: 390px;
  padding: 0.8rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 2px solid #FFF;
  background: #FFF;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  box-sizing: border-box;
  overflow-y: auto;
}
.Sidebar::-webkit-scrollbar {
  width: 4px;
}
.Sidebar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  margin: 2px 4px;
}
.Sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.Sidebar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
  margin: 2px 4px;
}
.wiz-body {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
  padding: 12px;
}
.wiz-container {
  height: calc(100vh - 210px);
  width: 100%;
  display: flex;
  padding: 24px 28px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  border: 2px solid #FFF;
  background:
    linear-gradient(
      82deg,
      rgba(248, 248, 248, 0.35) -14.04%,
      rgba(240, 240, 240, 0.49) 45.53%,
      rgba(255, 253, 253, 0.79) 110.07%);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  box-sizing: border-box;
  overflow-y: scroll;
}
.wiz-container::-webkit-scrollbar {
  width: 4px;
}
.wiz-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  margin: 2px 4px;
}
.wiz-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.wiz-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
  margin: 2px 4px;
}
.tracking-info {
  margin-right: 10px;
  font-size: 1rem;
  font-style: normal !important;
  color: rgba(0, 0, 0, 0.85);
  padding: 8px;
}
.tracking-number,
.tracking-ppl-id {
  display: inline-block;
  margin-right: 30px;
}
.primary-color-text {
  color: #004C4A !important;
}
/*# sourceMappingURL=new-referral.component-Y6ZXR2HT.css.map */
