<nz-timeline nzMode="left">

    @for (audit of auditData; track audit; let idx = $index ) {
        <nz-timeline-item nzLabel="{{audit.modifiedFirstName}} {{audit.modifiedLastName}} - {{audit.modifiedDate}}"></nz-timeline-item>
        @for (data of audit.modifiedData; track data; let idx = $index ) {
            <nz-timeline-item class="list-padding"><p style="margin: 0;">{{data.columnName}}</p> 
                <p style="margin: 0;">Old Value : <span style="color: rgba(2, 75, 233, 0.692);">{{data.previousValue}}</span></p> 
                <p style="margin: 0;">New Value: <span style="color: green;">{{data.currentValue}}</span></p>
            </nz-timeline-item>        
        }
    }@empty {
        <p>No data changes found</p>
    }
</nz-timeline>
