<div class="accordion-item">
    <div class="accordion-header">
      <!-- Left Elements -->
      {{accordion.title}}

      <!-- Right Elements -->
      <div style="display: flex; flex-direction: row; align-items: center; gap: 8px;">
        @if(accordion.additionalText){
          <ng-container>{{accordion.additionalText}}</ng-container> 
        }
          <button 
            class="toggle-button"
            type="button"
            (click)="toggleAccordion(accordion, $event)"
            [attr.aria-label]="'toggle ' + accordion.title + ' content'"
            [attr.aria-expanded]="accordion.isOpen"
            aria-controls="accordion-content"
          > 
            <i [class]="accordion.isOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>
          </button>
      </div>
    </div>
    <div 
      [class]="'accordion-collapse collapse show'"
      [ngClass]="{'show': accordion.isOpen}"
      id="accordion-content"
    >
      <div class="accordion-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>