import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommPreferenceComponent } from '../../comm-preference/comm-preference.component';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'ppl-enrollment-comm-preferences',
  standalone: true,
  imports: [
    NzFormModule,
    FormsModule,
    ReactiveFormsModule,
    NzGridModule,
    NzCardModule,
    NzRadioModule,
    NzSelectModule,
    NzCheckboxModule,
    NzInputModule,
    NzTimePickerModule,
    NzToolTipModule,
    CommonModule,
  ],
  templateUrl: './enrollment-comm-preferences.component.html',
  styleUrl: './enrollment-comm-preferences.component.css',
})
export class EnrollmentCommPreferencesComponent extends CommPreferenceComponent implements OnChanges {
  @Input() updatedFormValue: FormGroup['value'] | null;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['updatedFormValue'] &&
      changes['updatedFormValue'].currentValue &&
      this.updatedFormValue
    ) {
      this.preferncesForm.markAsDirty();
      this.preferncesForm.patchValue(this.updatedFormValue);
    }
  }
}
