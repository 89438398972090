<div class="footer">
    <div class="copyright">
        Copyright © 2024 PPL CareConnect All rights reserved.
    </div>
    <div class="footer-links ml-2">
        <a href="https://www.pplfirst.com/privacy-policy/" target="_blank">Privacy Policy</a>
        <a class="ml-2" href="https://www.pplfirst.com/terms-of-use/" target="_blank">Terms of Use</a>
    </div>
    <div class="ml-4">
        <ppl-google-translate></ppl-google-translate>
    </div>
    <div class="ml-4">
        <app-font-selector></app-font-selector>
    </div>
</div>
