import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { UploadComponent } from '../../../../shared/components/upload/upload.component';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { STATIC_DATA } from '../../../../shared/constants/static-data.constants';
import { performValidation } from '../../../../shared/utils/validation.util';
import { LeadDetailsService } from '../../../../services/lead-details.service';
import { LoggerService } from '../../../../shared/services/logger.service';
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';

@Component({
  selector: 'ppl-lead-detail-professional-licenses',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    UploadComponent,
    CommonModule,
    ButtonComponent,
    NgZorroModule
],
  templateUrl: './lead-detail-professional-licenses.component.html',
  styleUrl: './lead-detail-professional-licenses.component.css'
})

export class LeadDetailProfessionalLicensesComponent extends BaseComponent implements OnInit, OnChanges {
  steps: any;
  @Input()professionalLicense: any;

  @Input() set sectionData(data: any) {
    this.steps = data;
  }
  
  @Output() leadUpdated = new EventEmitter<boolean>();
  @Input() pageId = '';
  @Input() source?: string = '';
  @Input() selectedLeadId  = 0;
  @Input() programCode  = '';
  @Input() entry = '';

  servicesForm!: FormGroup;
  mode = "read";
  
  filledInfo!: any;
  licences: any[] = [];
  approvedStatus: string = STATIC_DATA.APPROVED;
  leadStatus = '';
 

  constructor(
    private fb: FormBuilder,
    private leadDetailsService: LeadDetailsService,
    private logger : LoggerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.servicesForm = this.fb.group({
      profLicences: [[]],
      leadId : []
    });
    this.leadDetailsService.getLeadStatus().subscribe((status: string) => {
      this.leadStatus = status;
      console.log('Lead Status', status);
    });
    if (this.mode == "read") {
      this.leadDetailsService.getUpdatedLeadData().subscribe(
        (data)=> {
          if(this.entry === STATIC_DATA.PROVIDER_ENROLLMENT){
            this.filledInfo = data?.responsedata?.serviceDetails?.profLicences;
          }else{
            this.filledInfo = data?.responsedata?.profLicenses;
          }
          
                /*  console.log(this.filledInfo); */
        }
      )
    }

    this.licences = this.getLookup('License_Type');   
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes['sectionData'] && changes['sectionData'].currentValue) {
        this.secData = this.steps['prof_licences'];
      }
    } catch (e) {
      this.logger.debug('Error in getting section data and updating latest info', e);
    }
  }

  cancelEdit(){
    this.mode = 'read';
    //need to rest the form
    this.servicesForm.reset();
  }

  save(){
    //add save logic
    const formData = this.servicesForm.value;
    try {
      this.validationErrors = performValidation(formData, this.secData, 'Save');
    } catch (e) {
      this.logger.debug("Error in validations", e);
    }

    if (Object.keys(this.validationErrors).length === 0) {
      if(Array.isArray(formData.profLicences) && formData.profLicences.length > 0){
        const payload = this.preparePayload(formData);

        this.leadDetailsService.updateLeadProfessionalLicences(payload, this.entry).subscribe(
          { 
            next: () => {
                this.logger.info('Lead Professional licences saved successfully');
                this.leadUpdated.emit(true);
                this.cancelEdit();
              },
            error: () => {
              this.leadUpdated.emit(true);
              this.cancelEdit();
            }
          }
        ) 
      }
    }
  }

  preparePayload(source: any){
    

    const payload : any = {};
    const transformSelection = this.getPayloadTransformation(source.profLicences);
  if(this.entry === STATIC_DATA.PARTICIPANT_ENROLLMENT || this.entry === STATIC_DATA.PROVIDER){
    payload.profLicenses = transformSelection;
  }else{
    payload.prvdrEnrlprofLicenses = transformSelection;
  }

    return {
      id: this.selectedLeadId,
      programCode : this.programCode,
      ...payload
    };
  
  }
  
  getPayloadTransformation(selectedValues : string[]): any {
   const selectedData =  selectedValues?.map((service : any) => {
    const row = this.filledInfo?.find((data : any) => data.licenseType === service);
    return {
      licenseType : service,
      modifiedDate: row?.modifiedDate
    }
    });
  
    return selectedData
  }
 
  editInfo() {
    this.mode = 'edit';
    const profLicenseData =  this.filledInfo?.map((license:any) => license.licenseType);
    const leadId = this.selectedLeadId;

    this.servicesForm.patchValue({
      profLicences: profLicenseData || [],
      leadId : leadId
    })
    /* console.log(this.professionalLicense.profLicences)
    console.log(profLicenseData);
    console.log(this.professionalLicense)
    console.log(this.filledInfo) */
   // this.patchLicenseData();
   // this.contactForm.patchValue(this.filledInfo);
   // this.contactForm.controls['leadId'].setValue(this.filledInfo?.permanentAddress?.leadId || this.selectedLeadId);
  }

}
