import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AuthorizationUtility } from './auth.utility';

@Directive({
  standalone: true,
  selector: '[authorizeButton]',
})
export class AuthorizeButtonDirective implements OnInit{

  private pageId!: string;
  private buttonId!: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private authUtility: AuthorizationUtility,
  ) {
  }

  ngOnInit(): void {
    this.updateViewState();
  }

  @Input() set authorizeButton(config: string[]) {
    this.pageId = config[0].trim();
    this.buttonId = config[1].trim();
  }

  private updateViewState() {
    const element = this.el.nativeElement;

    const buttonConfig = this.authUtility.isButtonVisible(`${this.pageId}`, this.buttonId);

    if(!buttonConfig) {
      this.renderer.removeChild(element.parentNode, element)
    }
  }
}