import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { MainMenuItem, SubMenuLinkItem } from '../../interfaces/menu.interface';
import { RoutingService } from '../services/routing.service';

@Component({
  selector: 'ppl-menu-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './menu-button.component.html',
  styleUrl: './menu-button.component.css'
})
export class MenuButtonComponent {
  @Input() label = 'Dropdown';
  @Input() icon?: string;
  @Input() iconPosition: 'left' | 'right' = 'right';
  @Input() type = 'button';
  @Input() disabled = false;
  @Input() customClass = '';
  @Input() item!: MainMenuItem;

  showDropdown = false;

  constructor(private eRef: ElementRef, private routerService : RoutingService) {}

  toggleDropdown(): void {
    if (!this.disabled) {
      this.showDropdown = !this.showDropdown;
    }
  }

  selectItem(item: SubMenuLinkItem): void {
    console.log('Selected item:', item);
    this.showDropdown = false;
    const route = `/home/${item.landing_component}`
    this.routerService.navigate(route)
  }

  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showDropdown = false;
    }
  }  
}