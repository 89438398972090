/* src/app/components/lead-details/communication-logs/communication-logs.component.css */
.ant-form-item-label-left {
  display: inline-block;
  min-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-align: left !important;
  vertical-align: middle;
}
/*# sourceMappingURL=communication-logs.component-F5D4D6B5.css.map */
