export const listablecongfig = {
    pageSize: 20,
    oprators: [
        { label: 'Contains', value: 'contains' },
        { label: 'Starts with', value: 'startswith' },
        { label: 'Ends with', value: 'endswith' },
        { label: 'Greaterthan', value: 'greaterthan' },
        { label: 'Greaterthan Or Equal', value: 'greaterthanOrEqual' },
        { label: 'Equal', value: 'equal' },
        { label: 'Lessthan', value: 'lessthan' },
        { label: 'Lessthan Or Equal', value: 'lessthanOrEqual' },
        { label: 'Not Equal', value: 'notEqual' },
        { label: 'Like', value: 'like' },
    ],
    conditonType: [
        { label: 'And', value: 'and' },
        { label: 'OR', value: 'or' },
    ],
    sortByOptions: [
        { label: 'Ascending', value: 'asc' },
        { label: 'Descending', value: 'desc' },
    ]
}