import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common'; // Import CommonModule

@Component({
  selector: 'ppl-button',
  standalone: true,
  imports: [CommonModule],  // Add CommonModule to imports
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent {
  @Input() label = 'Button';
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() disabled = false;
  @Input() icon?: string;
  @Input() customClass = 'alt-button'; // Default class
  @Input() iconPosition: 'left' | 'right' = 'right'; // Default position
  @Input() ariaLabel = ''

  @Output() handleClick = new EventEmitter<any>()



  handleClickEvent(event : Event) {
    console.log(`${this.label} button clicked`);
    this.handleClick.emit(event);
  }
}
