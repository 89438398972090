import { CommonModule, DatePipe } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  Output,
  EventEmitter, OnChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCardModule } from 'ng-zorro-antd/card';
//import { UploadComponent } from '../../../shared/components/upload/upload.component';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { UploadComponent } from '../../../../../shared/components/upload/upload.component';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { ReferralDataService } from '../../../../../services/referral-data.service';
import { SectionsService } from '../../../../../services/sections.service';
import { LeadDetailsService } from '../../../../../services/lead-details.service';
import { ListTableComponent } from '../../../../../shared/components/list-table/list-table.component';
import {
  STATIC_JSON_DATA_PATH,
  STATIC_DATA,
} from '../../../../../shared/constants/static-data.constants';
import { BaseComponent } from '../../../../../shared/utils/base.component';
import { LoggerService } from '../../../../../shared/services/logger.service';
import { TableRowActionsModel } from '../../../../../shared/interfaces/list.interface';
import { TableRowActions } from '../../../../../shared/interfaces/actions';
import { ListService } from '../../../../../shared/services/list.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SharedDataService } from '../../../../../shared/services/shared-data-service';
import { SharedService } from '../../../../../shared/services/shared.service';

@Component({
  selector: 'ppl-file-upload',
  standalone: true,
  imports: [
    NzToolTipModule,
    NzSelectModule,
    NzFormModule,
    NzInputModule,
    NzTimePickerModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzCardModule,
    UploadComponent,
    NzRadioModule,
    NzIconModule,
    NzButtonModule,
    NzToolTipModule,
    ListTableComponent,
    CommonModule,
  ],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.css',
})
export class FileUploadComponent extends BaseComponent implements OnInit, OnChanges {
  servicesForm!: FormGroup;
  fileList: any;
  @Input() filedInfo: any;
  @Input() tableWidth?: any;
  @Input() summary?: any;
  mode = 'read';
  steps: any = {};
  cols: any;
  fileListInfo: any[] = [];
  actionButtons: TableRowActionsModel = new TableRowActions();
  program: any;
  //entry: any;
  filledInfo: any;
  approvedStatus: string = STATIC_DATA.APPROVED;
  leadStatus = '';
  leadId: string | null = '';
  licences: any[] = [];
  fileId = '';
  customType = '';

  @Input() set sectionData(data: any) {
    this.steps = data;
  }

  @Input() entry = 'base';
  @Input() readonly = false;
  @Input() showButtons? = true;
  @Input() showUpload? = true;
  @Input() selectedLead = 0;
  @Output() filesUpdated = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private i18n: NzI18nService,
    private datePipe: DatePipe,
    private referralDataService: ReferralDataService,
    private sectionsService: SectionsService,
    private leadDetailsService: LeadDetailsService,
    private logger: LoggerService,
    private listService: ListService,
    private msg: NzMessageService,
    private sharedDataService: SharedDataService,
    private sharedService: SharedService
  ) {
    super();
  }

  ngOnInit(): void {
    // console.log("filedinfo in file com, this.fil", this.filedInfo)
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    // this.entry =
    //   localStorage.getItem('lead_entry')!;
    // this.sectionsService.setFlow(this.entry);
    // this.entry = this.sectionsService.getFlow()!;
    this.leadId = localStorage.getItem('selected_lead');
    this.licences = this.getLookup('License_Type');
    this.getFromData();
    this.leadDetailsService.getLeadStatus().subscribe((status: string) => {
      this.leadStatus = status;
      console.log('Lead Status', status);
      if (this.leadStatus === this.approvedStatus) {
        this.actionButtons.download = false;
        this.actionButtons.delete = false;
      } else {
        this.actionButtons.download = true;
        this.actionButtons.delete = true;
      }
    });
    this.leadDetailsService.getUpdatedLeadData().subscribe((data) => {
      this.filledInfo = data?.responsedata?.infoData;
    });
  }

  filesUploaded(files: any) {
    console.log('Files List', files);
    this.fileList = files;
    this.filesUpdated.emit();
  }

  cancelEdit() {
    this.mode = 'read';
    //this.leadUpdated.emit(true);
    //need to rest the form
    //this.contactForm.reset();
  }

  async getFromData() {
    try {
      if (this.entry) {
        const tempCols = await this.sectionsService.getJsonConfigurationData(
          STATIC_JSON_DATA_PATH.LEAD_DETAILS_DOCUMENT_LIST_HEADERS,
          this.entry
        );
        this.cols = tempCols.documents.columns;
        console.log(this.cols);
      }
    } catch (e) {
      this.log.debug('Error in getting column data', e);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    try {
      if (changes['filedInfo'] && changes['filedInfo'].currentValue) {
        this.fileListInfo =
          this.entry === STATIC_DATA.PROVIDER
            ? changes['filedInfo'].currentValue.providerDocumentDetails
            : this.entry === STATIC_DATA.REFERRAL
            ? changes['filedInfo'].currentValue.referralDocumentDetails
            : this.entry === 'providerEnrollment'
            ? changes['filedInfo'].currentValue.providerEnrlDocumentDetails
            : changes['filedInfo'].currentValue.participantEnrlDocumentDetails;
        this.logger.info(
          'fileListInfo',
          changes['filedInfo'].currentValue.providerDocumentDetails
        );
        if (!this.fileListInfo) {
          this.fileListInfo = [];
        }
        console.log('File List', this.fileListInfo);
      }
    } catch (e) {
      this.logger.info(
        'Error in getting section data and updating latest info',
        e
      );
    }
  }

  action(event: any): void {
    this.logger.debug('Action', event);
    const selectedFileName = event.data.fileName;

    switch(this.entry) {
    case STATIC_DATA.REFERRAL:
      this.fileId = event.data.referralFileId;
      break;
    case STATIC_DATA.PARTICIPANT_ENROLLMENT:
      this.fileId = event.data.leadFileId;
      break;
    case STATIC_DATA.PROVIDER_ENROLLMENT:
        this.fileId = event.data.fileId;
        break;
    default:
      this.fileId = event.data.leadFileId;
}
        // : event.data.referralFileId;
    // let flow = entry.charAt(0).toUpperCase() + entry.slice(1).toLowerCase();
    if (event.action === 'download') {
      this.mode = 'edit';
      this.fecthcFile(selectedFileName, this.fileId);
      // this.servicesForm.patchValue(event.data);
    }
    if (event.action === 'delete') {
      this.mode = 'edit';
      this.deleteFile();
      // this.servicesForm.patchValue(event.data);
    }
  }

  add(): void {
    this.mode = 'add';
  }

  deleteFile() {
    const createType = this.leadId === '' ? 'new': 'detail';
    this.sharedService.deleteFile(this.program.program_code, this.fileId, createType, this.sharedDataService.getType(this.entry)).subscribe((data: any) => {
      console.log(data);
      // this.msg.success(`File deleted successfully`);
       this.filesUpdated.emit();
    });    
  }

  save() {
    //added update functionality for licenses
    // let payLoad:any={};
    // let servicesRendered: any=[];
    // this.servicesForm.controls['profLicences'].value.map((x: any)=>servicesRendered.push({serviceType:x}))
    // console.log(this.filledInfo.contactDetailsData.leadId)
    // payLoad={
    //   'leadId': this.filledInfo.contactDetailsData.leadId,
    //   'servicesRendered':servicesRendered
    // }
    // this.leadDetailsService.updateProfessionalLicense(payLoad, this.entry).subscribe(() => {
    //   this.logger.info('Lead Provider Service is sucessfully updated!');
    //   //this.servicesForm.reset();
    //   this.leadUpdated.emit(true);
    //   this.mode = 'read'
    // })
  }

  editInfo() {
    this.mode = 'edit';
    // this.patchLicenseData();
    // this.contactForm.patchValue(this.filledInfo);
    // this.contactForm.controls['leadId'].setValue(this.filledInfo?.permanentAddress?.leadId || this.selectedLeadId);
  }

  // downloadFileFromServer(fileUrl: string) {
  //   this.http.get(fileUrl, {responseType: 'blob'}).subscribe((blob) => {
  //   })
  // }

  fecthcFile(selectedFileName: string, fileId: string) {
    this.sharedService.downloadFile(
      this.program.program_code,
      this.sharedDataService.getType(this.entry),
      fileId,
      selectedFileName
    );
  }
}
