<ppl-accordion [title]="'Approved Authorizations'" [isOpen]="true" *authorize="[pageId, tabId, 'Authorization', 'section']">
    @if(this.entry === 'participantEnrollment'){
    <div class="table-style">
        <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true" [data]="dataData"
            [disableSelection]="true" [cols]="approvedCols" (gridAction)="action($event)" [gridTitle]="false"
            [pagination]="false" [loadPage]="true" [actionButtons]="actionButtonsApproved">
        </ppl-list-table>
    </div>
    <ng-template #dialogContent let-confirm="confirm" let-cancel="cancel">
        <form nz-form nz-row class="ppl-p0">
            @if(isAttrAvailable('pplAuth')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="pplAuth"
                        class="ppl-form-label">{{getAttributes('pplAuth').label}}
                        @if(getAttributes('pplAuth').mandatory === 'Y'){
                        <span>*</span>
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.pplAuth}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('clientAuth')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="clientAuth"
                        class="ppl-form-label">{{getAttributes('clientAuth').label}}
                        @if(getAttributes('clientAuth').mandatory === 'Y'){
                        <!-- <span>*</span> -->
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.clientAuth}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('serviceCodeDesc')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="serviceCodeDesc"
                        class="ppl-form-label">{{getAttributes('serviceCodeDesc').label}}
                        @if(getAttributes('serviceCodeDesc').mandatory === 'Y'){
                        <!--  <span>*</span> -->
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.serviceCodeDesc}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('orgName')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="orgName"
                        class="ppl-form-label">{{getAttributes('orgName').label}}
                        @if(getAttributes('orgName').mandatory === 'Y'){
                        <!-- <span>*</span> -->
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.orgName}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('modifierCode1')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="modifierCode1"
                        class="ppl-form-label">{{getAttributes('modifierCode1').label}}
                        @if(getAttributes('modifierCode1').mandatory === 'Y'){
                        <span>*</span>
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.modifierCode1}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('modifierCode2')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="modifierCode2"
                        class="ppl-form-label">{{getAttributes('modifierCode2').label}}
                        @if(getAttributes('modifierCode2').mandatory === 'Y'){
                        <span>*</span>
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.modifierCode2}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('modifierCode3')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="modifierCode3"
                        class="ppl-form-label">{{getAttributes('modifierCode3').label}}
                        @if(getAttributes('modifierCode3').mandatory === 'Y'){
                        <span>*</span>
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.modifierCode3}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('modifierCode4')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="modifierCode4"
                        class="ppl-form-label">{{getAttributes('modifierCode4').label}}
                        @if(getAttributes('modifierCode4').mandatory === 'Y'){
                        <span>*</span>
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.modifierCode4}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('units')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="units"
                        class="ppl-form-label">{{getAttributes('units').label}}
                        @if(getAttributes('units').mandatory === 'Y'){
                        <!-- <span>*</span> -->
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.units}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('dollars')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="dollars"
                        class="ppl-form-label">{{getAttributes('dollars').label}}
                        @if(getAttributes('dollars').mandatory === 'Y'){
                        <span>*</span>
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.dollars}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('startDate')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="startDate"
                        class="ppl-form-label">{{getAttributes('startDate').label}}
                        @if(getAttributes('startDate').mandatory === 'Y'){
                        <!-- <span>*</span> -->
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.startDate}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('endDate')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="endDate"
                        class="ppl-form-label">{{getAttributes('endDate').label}}
                        @if(getAttributes('endDate').mandatory === 'Y'){
                        <!-- <span>*</span> -->
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.endDate}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
        </form>
    </ng-template>
    <ppl-popup-dialog [(isVisible)]="show" [title]="'Auth Details'" (cancel)="show = false" [showButtons]="true"
        [position]="'center'" size="x-large" [showOkButton]="false">
    </ppl-popup-dialog>
    }@else {
    <ul class="paystub-list">
        <li class="paystub-item">
            <div>
                <div class="paystub-date">September 20, 2024</div>
                <div class="paystub-amount">$1,200.00</div>
            </div>
            <div>
                <button class="download-btn">Download PDF</button>
            </div>
        </li>
        <li class="paystub-item">
            <div>
                <div class="paystub-date">August 20, 2024</div>
                <div class="paystub-amount">$1,150.00</div>
            </div>
            <div>
                <button class="download-btn">Download PDF</button>
            </div>
        </li>
        <li class="paystub-item">
            <div>
                <div class="paystub-date">July 20, 2024</div>
                <div class="paystub-amount">$1,175.00</div>
            </div>
            <div>
                <button class="download-btn">Download PDF</button>
            </div>
        </li>
        <li class="paystub-item">
            <div>
                <div class="paystub-date">June 20, 2024</div>
                <div class="paystub-amount">$1,125.00</div>
            </div>
            <div>
                <button class="download-btn">Download PDF</button>
            </div>
        </li>
    </ul>
    }
</ppl-accordion>
<ppl-accordion [title]="'Errored Out Authorizations'" [isOpen]="true" *authorize="[pageId, tabId, 'Authorization', 'section']">

    @if(this.entry === 'participantEnrollment'){

    <div class="table-style">
        <!-- <ppl-list-table [pageConfig]="programName" 
                [loadPage]="lazyLoad" [data]="dataData" [cols]="cols" [disableSort]="true"
                [columnsToBeHidden]="columnsToBeHidden" [actionsToBeHidden]="actionsToBeHidden" [disableSearch]="true" [pagination]="true" [disableSelection]="true" [rowsToDisplay]="10"
                [enableActions]="true" [actionButtons]="actions" (gridAction)="action($event)">
            </ppl-list-table> -->

        <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true" [data]="dataData"
            [disableSelection]="true" [cols]="erroredCols" (gridAction)="action($event)" [gridTitle]="false"
            [pagination]="false" [loadPage]="true" [actionButtons]="actionButtonsErrored">
        </ppl-list-table>
    </div>

    <ng-template #dialogContent let-confirm="confirm" let-cancel="cancel">
        <form nz-form nz-row class="ppl-p0">
            <!-- @if(isAttrAvailable('consumerFirstName')){
                    <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                        <nz-row>
                            <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="consumerFirstName"
                                class="ppl-form-label">{{getAttributes('consumerFirstName').label}}
                                @if(getAttributes('consumerFirstName').mandatory === 'Y'){
                                <span>*</span>
                                }
                            </nz-form-label>
                            <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                                {{authData?.consumerFirstName}}
                            </nz-form-control>
                        </nz-row>
                    </nz-form-item>
                    } -->

            <!-- @if(isAttrAvailable('consumerLastName')){
                    <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                        <nz-row>
                            <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="consumerLastName"
                                class="ppl-form-label">{{getAttributes('consumerLastName').label}}
                                @if(getAttributes('consumerLastName').mandatory === 'Y'){
                                <span>*</span>
                                }
                            </nz-form-label>
                            <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                                {{authData?.consumerLastName}}
                            </nz-form-control>
                        </nz-row>
                    </nz-form-item>
                    } -->

            <!-- @if(isAttrAvailable('medicaid')){
                    <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                        <nz-row>
                            <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="medicaid"
                                class="ppl-form-label">{{getAttributes('medicaid').label}}
                                @if(getAttributes('medicaid').mandatory === 'Y'){
                                <span>*</span>
                                }
                            </nz-form-label>
                            <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                                {{authData?.medicaid}}
                            </nz-form-control>
                        </nz-row>
                    </nz-form-item>
                    } -->

            <!-- @if(isAttrAvailable('consumerPPLId')){
                    <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                        <nz-row>
                            <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="consumerPPLId"
                                class="ppl-form-label">{{getAttributes('consumerPPLId').label}}
                                @if(getAttributes('consumerPPLId').mandatory === 'Y'){
                                <span>*</span>
                                }
                            </nz-form-label>
                            <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                                {{authData?.consumerPPLId}}
                            </nz-form-control>
                        </nz-row>
                    </nz-form-item>
                    } -->

            @if(isAttrAvailable('pplAuth')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="pplAuth"
                        class="ppl-form-label">{{getAttributes('pplAuth').label}}
                        @if(getAttributes('pplAuth').mandatory === 'Y'){
                        <span>*</span>
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.pplAuth}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }

            @if(isAttrAvailable('clientAuth')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="clientAuth"
                        class="ppl-form-label">{{getAttributes('clientAuth').label}}
                        @if(getAttributes('clientAuth').mandatory === 'Y'){
                        <!-- <span>*</span> -->
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.clientAuth}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }

            @if(isAttrAvailable('serviceCodeDesc')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="serviceCodeDesc"
                        class="ppl-form-label">{{getAttributes('serviceCodeDesc').label}}
                        @if(getAttributes('serviceCodeDesc').mandatory === 'Y'){
                        <!--  <span>*</span> -->
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.serviceCodeDesc}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('orgName')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="orgName"
                        class="ppl-form-label">{{getAttributes('orgName').label}}
                        @if(getAttributes('orgName').mandatory === 'Y'){
                        <!-- <span>*</span> -->
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.orgName}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('modifierCode1')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="modifierCode1"
                        class="ppl-form-label">{{getAttributes('modifierCode1').label}}
                        @if(getAttributes('modifierCode1').mandatory === 'Y'){
                        <span>*</span>
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.modifierCode1}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('modifierCode2')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="modifierCode2"
                        class="ppl-form-label">{{getAttributes('modifierCode2').label}}
                        @if(getAttributes('modifierCode2').mandatory === 'Y'){
                        <span>*</span>
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.modifierCode2}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('modifierCode3')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="modifierCode3"
                        class="ppl-form-label">{{getAttributes('modifierCode3').label}}
                        @if(getAttributes('modifierCode3').mandatory === 'Y'){
                        <span>*</span>
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.modifierCode3}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('modifierCode4')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="modifierCode4"
                        class="ppl-form-label">{{getAttributes('modifierCode4').label}}
                        @if(getAttributes('modifierCode4').mandatory === 'Y'){
                        <span>*</span>
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.modifierCode4}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('units')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="units"
                        class="ppl-form-label">{{getAttributes('units').label}}
                        @if(getAttributes('units').mandatory === 'Y'){
                        <!-- <span>*</span> -->
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.units}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('dollars')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="dollars"
                        class="ppl-form-label">{{getAttributes('dollars').label}}
                        @if(getAttributes('dollars').mandatory === 'Y'){
                        <span>*</span>
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.dollars}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('startDate')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="startDate"
                        class="ppl-form-label">{{getAttributes('startDate').label}}
                        @if(getAttributes('startDate').mandatory === 'Y'){
                        <!-- <span>*</span> -->
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.startDate}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('endDate')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="endDate"
                        class="ppl-form-label">{{getAttributes('endDate').label}}
                        @if(getAttributes('endDate').mandatory === 'Y'){
                        <!-- <span>*</span> -->
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        {{authData?.endDate}}
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }


        </form>
    </ng-template>

    <ppl-popup-dialog [(isVisible)]="show" [title]="'Auth Details'" (cancel)="show = false" [showButtons]="true"
        [position]="'center'" size="x-large" [showOkButton]="false">

    </ppl-popup-dialog>




    }@else {
    <ul class="paystub-list">
        <li class="paystub-item">
            <div>
                <div class="paystub-date">September 20, 2024</div>
                <div class="paystub-amount">$1,200.00</div>
            </div>
            <div>
                <button class="download-btn">Download PDF</button>
            </div>
        </li>
        <li class="paystub-item">
            <div>
                <div class="paystub-date">August 20, 2024</div>
                <div class="paystub-amount">$1,150.00</div>
            </div>
            <div>
                <button class="download-btn">Download PDF</button>
            </div>
        </li>
        <li class="paystub-item">
            <div>
                <div class="paystub-date">July 20, 2024</div>
                <div class="paystub-amount">$1,175.00</div>
            </div>
            <div>
                <button class="download-btn">Download PDF</button>
            </div>
        </li>
        <li class="paystub-item">
            <div>
                <div class="paystub-date">June 20, 2024</div>
                <div class="paystub-amount">$1,125.00</div>
            </div>
            <div>
                <button class="download-btn">Download PDF</button>
            </div>
        </li>
    </ul>
    }
</ppl-accordion>