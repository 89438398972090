import { Component, OnInit, Signal, signal, TemplateRef, ViewChild, WritableSignal } from '@angular/core';
import { ListTableComponent } from '../../../shared/components/list-table/list-table.component';
import { ENROLLMENT_TYPE_BACKEND_MAPPINGS, STATIC_DATA } from '../../../shared/constants/static-data.constants';
import { PAGE_CONSTANTS } from '../../../shared/constants/page.constants';
import { FileTriageData } from '../../../interfaces/fileTriage.interface';
import { FileTriageService } from '../../../services/fileTriage.service';
import { EnrollmentDataService } from '../../../services/enrollment-data.service';
import { DialogConfig, DialogService } from '../../../shared/services/dialog.service';
import { EditpdfComponent } from '../../editpdf/editpdf.component';
import { ColumnType } from '../../../interfaces/list.interface';
import { SearchProvidersDetailsItem } from '../../../shared/interfaces/provider.model';
import { NotificationService } from '../../../shared/services/notification.service';
import { SearchAndSelectProviderComponent } from "../search-and-select-provider/search-and-select-provider.component";
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { Router } from '@angular/router';
import { SectionsService } from '../../../services/sections.service';
import { ReferralDataService } from '../../../services/referral-data.service';
import { MENU_BASE_URLS } from '../../../shared/constants/url.constants';
import { SearchParticipantsDetailsItem } from '../../../shared/interfaces/participant.model';
import { AuthorizationUtility } from '../../../shared/authorization/auth.utility';
import { AuthorizationService } from '../../../shared/authorization/authorization.service';
import { PageConfig } from '../../../shared/authorization/auth.config';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { CommonModule } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { ListService } from '../../../shared/services/list.service';

export interface FileTriageAssignmentInput {
  sourceFilePath: string;
  entityId: string;
  entityType: string;
  programCode: string;
  docType: string;
}

export type AssignFileTriagePayload = FileTriageAssignmentInput[];

export interface FileTriageAssignmentConfirmation {
  file_location: string;
  file_upload_tracking_number: number;
  modified_file_name: string;
  actual_file_name: string;
}

const fileTriageTableColumns: ColumnType[] = [
  {
    header: 'First Name',
    field: 'firstName',
  },
  {
    header: 'Last Name',
    field: 'lastName',
  },
  {
    header: 'PPL ID',
    field: 'providerPPLId',
  },
  {
    header: 'Date of Birth',
    field: 'dob',
  },
];


@Component({
  selector: 'ppl-file-triage-list',
  standalone: true,
  imports: [
    ListTableComponent,
    EditpdfComponent,
    SearchAndSelectProviderComponent,
    ButtonComponent,
    CommonModule,
],
  templateUrl: './file-triage-list.component.html',
  styleUrl: './file-triage-list.component.css',
})
export class FileTriageListComponent implements OnInit {
  @ViewChild('dialogContent') dialogContent!: TemplateRef<any>;
  @ViewChild('dialogContent2') dialogContent2!: TemplateRef<any>;
  @ViewChild('dialogHeader') dialogHeader!: TemplateRef<any>;

  pageId: string = PAGE_CONSTANTS.FILE_TRIAGE_DATA_GRID_PAGE;
  programName!: string;
  columnsToBeHidden: any = [];
  sharedDataKey: string = STATIC_DATA.SHARED_FILE_TRAGE_DATA;
  actionsToBeHidden: any = [];
  lazyLoad = true;
  isPageRestrictionLoaded = false;
  pdfFile?: any;
  pdfConfig: any;
  checkedRows: FileTriageData[] = [];
  dialogId: any;

  cols: ColumnType[] = [...fileTriageTableColumns];

  drawerRef: WritableSignal<NzDrawerRef | null> = signal(null);
  selectedRow: Signal<FileTriageData | null | undefined> 
    = toSignal(this.fileTriageService.getSelectedRow());

  constructor(
    private fileTriageService: FileTriageService,
    private enrollmentService: EnrollmentDataService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private drawerService: NzDrawerService,
    private router: Router,
    private sectionService: SectionsService,
    private referralDataService: ReferralDataService,
    private authUtility: AuthorizationUtility,
    private authService: AuthorizationService,
    public listService: ListService
  ) {}

  ngOnInit() {
    const selectedProgram = JSON.parse(localStorage.getItem('selected_program')!);
    this.programName = selectedProgram.program_code;
    this.setPageAccess();
    
    this.fileTriageService.setOpenDialogFunction((checkedRows: FileTriageData[]) => { 
      console.log(checkedRows);
      this.checkedRows = checkedRows;
      const dialogConfig: DialogConfig = {
        title: `${checkedRows.length} file(s) selected`,
        isOkText: 'Save',
        size: 'large',
        position: 'top',
        showClose: true,
        showButtons: false,
      }
      this.dialogId = this.dialogService.openDialog(this.dialogContent2, dialogConfig, () => {}, () => {});
    })
  }

  ngOnDestory() {
    this.fileTriageService.setOpenDialogFunction(null);
  }

  rowClicked(rowDetail: FileTriageData) {
    this.fileTriageService.setSelectedRow(rowDetail);
    this.fileTriageService.getFileTriagePDF(rowDetail.filePath).subscribe((pdf: any) => {
      this.pdfFile = pdf;
      if (!this.drawerRef()) {
        const drawerRef = this.drawerService.create({
          nzTitle: this.dialogHeader,
          nzContent: this.dialogContent,
          nzMask: false,
          nzWidth: '50vw'
        });
        this.drawerRef.set(drawerRef);
        drawerRef.afterClose.subscribe(() => {
          this.pdfFile = null;
          this.drawerRef.set(null);
        });
      } 
    })
  }

  handleCancel(){
    this.pdfFile = null
  }

  handleAddProvider(provider: SearchProvidersDetailsItem & Record<string, any>) {
    const fileTriageAssignments: FileTriageAssignmentInput[] = this.checkedRows.map(row => {
      return {
        sourceFilePath: row.filePath,
        entityId: provider.providerId.toString(),
        entityType: 'providerenrlmnt',
        programCode: this.programName,
        docType: provider?.['docType'] || '',
      }
    })

    this.enrollmentService.assignFileTriage(fileTriageAssignments).subscribe((res: any) => {
      if (res.status === 200) {

        const assignmentResponses = res.responsedata as FileTriageAssignmentConfirmation[];

        const confirmedAssignments = assignmentResponses.filter((assignment) => !!assignment.file_upload_tracking_number);

        if (confirmedAssignments.length) {
          this.dialogService.closeDialog(this.dialogId);
          this.notificationService.alert(
            'success',
            'Success',
            `Successfully assigned ${confirmedAssignments.length} file(s) to ${provider.firstName} ${provider.lastName}.`
          );
          
          // navigate to checklist
          localStorage.setItem('lead_entry', STATIC_DATA.PROVIDER_ENROLLMENT);
          localStorage.setItem('selected_lead', provider.providerId.toString());
          localStorage.setItem(STATIC_DATA.URL_FIRST_SEGMENT, MENU_BASE_URLS.PROVIDER_ENROLLMENT);
          this.sectionService.setFlow(STATIC_DATA.PROVIDER_ENROLLMENT);
          this.sectionService.setEnrollmentType(JSON.stringify([ENROLLMENT_TYPE_BACKEND_MAPPINGS[provider?.providerType || 'IR']]));
          this.referralDataService.clearReferrralData();
          this.router.navigate(['/home/providerEnrollmentList/details'], { state: { navigateToTab: 'checklist' } });

          // should refresh here if no navigation?
        }
      }
    })
  }
  
  handleAddParticipant(participant: SearchParticipantsDetailsItem & Record<string, any>) {
    const fileTriageAssignments: FileTriageAssignmentInput[] = this.checkedRows.map(row => {
      return {
        sourceFilePath: row.filePath,
        entityId: participant.participantId.toString(),
        entityType: 'participantenrlmnt',
        programCode: this.programName,
        docType: participant?.['docType'] || '',
      }
    })

    this.enrollmentService.assignFileTriage(fileTriageAssignments).subscribe((res: any) => {
      if (res.status === 200) {

        const assignmentResponses = res.responsedata as FileTriageAssignmentConfirmation[];

        const confirmedAssignments = assignmentResponses.filter((assignment) => !!assignment.file_upload_tracking_number);

        if (confirmedAssignments.length) {
          this.dialogService.closeDialog(this.dialogId);
          this.notificationService.alert(
            'success',
            'Success',
            `Successfully assigned ${confirmedAssignments.length} file(s) to ${participant.firstName} ${participant.lastName}.`
          );
          
          // navigate to checklist
          localStorage.setItem('lead_entry', STATIC_DATA.PARTICIPANT_ENROLLMENT);
          localStorage.setItem('selected_lead', participant.participantId.toString());
          localStorage.setItem(STATIC_DATA.URL_FIRST_SEGMENT, MENU_BASE_URLS.PARTICIPANT_ENROLLMENT);
          this.sectionService.setFlow(STATIC_DATA.PARTICIPANT_ENROLLMENT);
          this.referralDataService.clearReferrralData();
          this.router.navigate(['/home/participantEnrollmentList/details'], { state: { navigateToTab: 'checklist' } });

          // should refresh here if no navigation?
        }
      }
    })
  }

  onCancelAssignment() {
    this.dialogService.closeDialog(this.dialogId);
  }

  assignPreviewFile() {
    this.fileTriageService.callOpenDialogFunction([this.selectedRow()]);
    const drawerRef = this.drawerRef();
    drawerRef?.close();
  }

  private setPageAccess() {
    const pageConfig = this.authUtility.getPageConfig(this.pageId);
    if(!pageConfig || !pageConfig.pageName) {
      this.authService.fetchPageAuthorization(this.programName, this.pageId, "LIST").subscribe(response => {
        this.setRestrictions(response);
      })
    } else {
      this.setRestrictions(pageConfig);
    }
  }

  private setRestrictions(pageConfig: PageConfig) {
    if (pageConfig) {
      this.columnsToBeHidden = this.authUtility.getHiddenColumns(pageConfig, this.pageId);
      this.actionsToBeHidden = this.authUtility.getHiddenActions(pageConfig, this.pageId);
      console.log('restricted columns', this.columnsToBeHidden);
      console.log('restricted actions', this.actionsToBeHidden);
    
    }
    this.isPageRestrictionLoaded = true;
  }
}