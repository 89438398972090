import {
  Component,
  inject,
  TemplateRef,
} from '@angular/core';
import {
  DialogService,
} from '../../services/dialog.service';
import { ButtonComponent } from '../button/button.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ppl-dialog',
  standalone: true,
  imports: [ButtonComponent, CommonModule],
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.css',
})
export class DialogComponent {
  public dialogSvc = inject(DialogService);

  isTemplateRef(content: TemplateRef<any>) {
    return content instanceof TemplateRef;
  }

}
