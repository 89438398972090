import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AddressComponent } from '../../../../shared/components/address/address.component';
import { ListTableComponent } from '../../../../shared/components/list-table/list-table.component';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { SectionsService } from '../../../../services/sections.service';
import { LeadDetailsService } from '../../../../services/lead-details.service';
import { LookupService } from '../../../../services/lookup.service';
import {
  STATIC_DATA,
  STATIC_JSON_DATA_PATH,
} from '../../../../shared/constants/static-data.constants';
import { performValidation } from '../../../../shared/utils/validation.util';
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { CommonModule } from '@angular/common';
import { LoggerService } from '../../../../shared/services/logger.service';
import {
  LeadDetailIdentifiers,
  LeadDetailIdentifiersAddUpdateModel,
  LeadDetailIdentifiersModel,
} from '../../../../interfaces/lead-detail.model';
import { TableRowActionsModel } from '../../../../shared/interfaces/list.interface';
import { TableRowActions } from '../../../../shared/interfaces/actions';
import { AuthorizeDirective } from '../../../../shared/authorization/authorize.directive.';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { LookupValueItem } from '../../../../interfaces/lookup.interface';
import { NzAriaLabelDirective } from '../../../../shared/directives/nz-aria-label.directive';
import { AuthorizationUtility } from '../../../../shared/authorization/auth.utility';

@Component({
  selector: 'ppl-lead-detail-identifiers',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgZorroModule,
    ReactiveFormsModule,
    AddressComponent,
    ListTableComponent,
    AuthorizeDirective,
    ButtonComponent,
    NzAriaLabelDirective
  ],
  templateUrl: './lead-detail-identifiers.component.html',
  styleUrl: './lead-detail-identifiers.component.css',
})
export class LeadDetailIdentifiersComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  mode = 'read';
  filledInfo!: any;
  identifierForm!: FormGroup;
  cols: any;
  gridData: any[] = [];
  identifiersList: LookupValueItem[] = [];
  copyOfGridData: any[] = [];
  steps!: any;
  entry = 'base';
  selectedLead: any;
  actionButtons: TableRowActionsModel = new TableRowActions();
  createdIdentifiers: string[] = [];
  approvedStatus: string = STATIC_DATA.APPROVED;
  leadStatus = '';
  selectedIdentifierValue: string | undefined | null = null;
  ssnVisible = false;
  ssnError = false;
  isSectionEditable: boolean = false;
  
  @Input() pageId = '';
  @Input() tabId = '';
  @Input() programCode = '';

  @Input() set sectionData(data: string) {
    this.steps = data;
    /* console.log(this.steps); */
  }

  @Output() leadUpdated = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private sectionService: SectionsService,
    private leadDetailsService: LeadDetailsService,
    private lookupService: LookupService,
    private logger: LoggerService,
    private authUtility: AuthorizationUtility
  ) {
    super();
  }

  async ngOnInit() {
    this.isSectionEditable = this.authUtility.isSectionEditable(this.pageId, this.tabId, 'Viewer');
    this.selectedLead = JSON.parse(
      localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!
    );
    console.log(this.selectedLead);

    this.entry = localStorage.getItem('lead_entry')!;
    /* console.log(this.entry); */
    this.createFormGroup();
    this.identifiersList = this.lookupService.getLookupValue(
      'Identifier_type'
    );

    await this.getFromData();

    this.gridData = [];
    this.leadDetailsService.getLeadStatus().subscribe((status: string) => {
      this.leadStatus = status;
      console.log('Lead Status', status);
      if (this.leadStatus === this.approvedStatus) {
        this.actionButtons.edit = false;
        this.actionButtons.delete = false;
      } else {
        this.actionButtons.edit = true;
        this.actionButtons.delete = true;
      }
    });

    this.leadDetailsService.getUpdatedLeadData().subscribe((data) => {
      this.gridData = data?.responsedata?.infoData?.identifiers;

      this.copyAndTransformGridData(this.gridData);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes['sectionData'] && changes['sectionData'].currentValue) {
        this.secData = this.steps['identifiers'];
      }
    } catch (e) {
      this.logger.debug(
        'Error in getting section data and updating latest info',
        e
      );
    }
  }

  createFormGroup() {
    this.identifierForm = this.fb.group({
      leadIdentifierId: [],
      leadId: [],
      type: [],
      value: [],
      /* addlVal1: [],
      addlVal2: [] */
    });
  }

  async getFromData() {
    try {
      if (this.entry) {
        const tempCols = await this.sectionService.getJsonConfigurationData(
          STATIC_JSON_DATA_PATH.LEAD_DETAILS_CONTACT_LIST_HEADERS,
          this.entry
        );
        this.cols = tempCols.identifiers.columns;
      }
    } catch (e) {
      this.logger.debug('Error in getting column data', e);
    }
  }

  copyAndTransformGridData(gridData: any[]) {
    this.createdIdentifiers = [];
    if (gridData) {
      const copyGridData = gridData.slice();

      this.copyOfGridData = copyGridData.map((item) => ({
        ...item,
        type: super.getLookupValue(this.identifiersList, item.type),
        modifiedDate: super.formatDateString(item.modifiedDate),
      }));

      copyGridData.forEach((item) => {
        this.createdIdentifiers.push(item.type);
      });
      if (this.leadStatus !== this.approvedStatus) {
        this.actionButtons.edit = true;
        this.actionButtons.delete = true;
      } else {
        this.actionButtons.edit = false;
        this.actionButtons.delete = false;
      }
    } else {
      this.copyOfGridData = [];
      this.actionButtons.delete = false;
      this.actionButtons.edit = false;
    }
  }

  cancelEdit() {
    this.mode = 'read';
    this.validationErrors = {};
    this.identifierForm.reset();
  }

  action(event: any) {
    console.log('acto', event);

    const selectedId = this.extractId(event.data); 
    this.selectedIdentifierValue = this.identifiersList.find(item => item.lkpValueName === event.data.type)?.lkpValueCode;

    //(this.entry === 'referral')? event.data.referralIdentifierId : event.data.leadIdentifierId;
    const selectedData = this.gridData.find(
      (row: any) => {
        if (
          row.leadIdentifierId === selectedId ||
          row.referralIdentifierId === selectedId || 
          row.enrollmentIdentifierId === selectedId ||
          row.providerIdentifierId === selectedId
        ) {
          return row;
        }
      }
    );

    if (event && event.action === 'edit') {
      this.mode = 'edit';

      if (selectedData) {
        console.log("selectedData",selectedData);
        this.identifierForm.patchValue(selectedData);
        if (this.entry === STATIC_DATA.REFERRAL || this.entry === STATIC_DATA.PARTICIPANT_ENROLLMENT || this.entry === STATIC_DATA.PROVIDER_ENROLLMENT) {
          this.identifierForm.patchValue({
            leadIdentifierId: selectedId,
            leadId: this.selectedLead,
          });
        }
      }
    }

    if (event && event.action === 'delete') {
      if (selectedData) {
        this.delete(selectedId);
      }
    }
  }

  formatSSN(event: any): void {
    let value = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
    if (value.length > 9) {
      value = value.slice(0, 9); // Limit the value to 9 digits
    }
    if (value.length > 5) {
      value = value.slice(0, 5) + '-' + value.slice(5); // Add a dash after the first 5 digits
    }
    if (value.length > 3) {
      value = value.slice(0, 3) + '-' + value.slice(3); // Add a dash after the first 3 digits
    }
    event.target.value = value; // Update the input field with the formatted value
  }
  
  add() {
    this.mode = 'add';
    this.identifierForm.reset();
    this.identifierForm.controls['leadId'].setValue(this.selectedLead);
  }

  save() {
    const pageData = this.identifierForm.value;
    this.ssnError = false;
    if(pageData.type === 'SSN') {
      const ssn = pageData.value.replace(/-/g, '');
        const regex = /^\d{9}$/;        
        const ssnRegex = /^(?!666|000|9\d{2})(?!.*(\d)(?:-?\1){8})(?!123456789)\d{3}-(?!00)\d{2}-(?!0000)\d{4}$|^(?!.*(000|666|9\d{2})\b)(?!.*\b00\b)(?!.*0000)(?!.*(\d)(?:-?\1){8})(?!123456789)\d{3}-\d{2}-?\d{4}$/;
        // Check if ssn has exactly 9 digits first
        if (!regex.test(ssn)) {
            this.ssnError = true;
        }
        // Validate the ssn against the main regex
        if (!ssnRegex.test(pageData.value)) {
          this.ssnError = true;
        }
    }
    try {
      this.validationErrors = performValidation(
        this.identifierForm.value,
        this.secData,
        'Save'
      );
      this.logger.debug('Error in validations', this.validationErrors);
    } catch {
      this.logger.debug('Error in validations');
    }
    if (Object.keys(this.validationErrors).length === 0 && !this.ssnError) {
      const payload = this.preparePayload(pageData);
      this.leadDetailsService
        .createUpdateLeadIdentifier(payload, this.entry)
        .subscribe({
          next: () => {
            this.logger.info('Lead Identifier saved successfully');
            this.leadUpdated.emit(true);
            this.cancelEdit();
          },
          error: () => {
            this.leadUpdated.emit(true);
            this.cancelEdit();
          }
        });
    }
  }

  extractId(identifier: any){
    let id = identifier.leadIdentifierId;
    if(this.entry === STATIC_DATA.REFERRAL){
      id = identifier.referralIdentifierId;
    }
    if(this.entry === STATIC_DATA.PARTICIPANT_ENROLLMENT){
      id = identifier.enrollmentIdentifierId
    }
    if(this.entry === STATIC_DATA.PROVIDER_ENROLLMENT){
      id = identifier.providerIdentifierId
    }
    return id;
  }

  delete(identifierId: number) {
    /* let id = identifier?.leadIdentifierId;
    if (this.entry === STATIC_DATA.REFERRAL) {
      id = identifier.referralIdentifierId;
    }
    if(this.entry === STATIC_DATA.PARTICIPANT_ENROLLMENT){
      id = identifier.enrollmentIdentifierId
    } */

    //let id = this.extractId(identifier);

    this.leadDetailsService
      .deleteLeadIdentifier(identifierId, this.entry, this.programCode)
      .subscribe(() => {
        this.logger.info('Identified deleted successfully');
        this.leadUpdated.emit(true);
      });
  }

  preparePayload(
    source: LeadDetailIdentifiersModel
  ): LeadDetailIdentifiersAddUpdateModel {
    const { leadIdentifierId, ...identifierData } = source;
    const leadDetailIdentifierId = this.mode === 'edit' ? leadIdentifierId : null;
    const existingIdentifier = this.gridData ? this.gridData.find(
      (row) => {
        if (
          row.leadIdentifierId === leadDetailIdentifierId ||
          row.referralIdentifierId === leadDetailIdentifierId || 
          row.enrollmentIdentifierId === leadDetailIdentifierId ||
          row.providerIdentifierId === leadDetailIdentifierId
        ) {
          return row;
        }
      }
    ) : null;
    
    return {
      id: this.selectedLead,
      identifierId: leadDetailIdentifierId,
      programCode: this.programCode,
      identifier: {...identifierData as LeadDetailIdentifiers, modifiedDate: existingIdentifier?.modifiedDate},
    };
  }

  getAddedIdentifier(identifier: string): boolean {
    const created = this.createdIdentifiers.find(
      (identifier) => identifier.toLowerCase() === identifier.toLowerCase()
    );
    this.logger.info('Added identifier', this.createdIdentifiers, identifier);
    if (created) {
      return true;
    } else {
      return false;
    }
  }

  getTooltipTitle(){
    const selectedLkpValueName = this.identifiersList.find(identifer => this.selectedIdentifierValue === identifer.lkpValueCode)?.lkpValueName
    
    return selectedLkpValueName ? `Please Enter ${selectedLkpValueName}`: ''
  }
}
