/* src/app/components/lead-details/lead-comments/lead-comments.component.css */
[nz-icon] {
  margin-right: 6px;
  font-size: 1.429rem;
  color: #3c86f5;
  cursor: pointer;
}
nz-form-control {
  margin-top: 0.5em;
}
::ng-deep .ant-picker-input > input {
  border: none;
}
.ppl-form-label {
  margin-right: 8px;
}
.ant-form-item-label {
  margin-right: 8px;
}
::ng-deep nz-input-group > .ant-input {
  height: 30px;
}
.ant-input-affix-wrapper {
  margin-bottom: 0;
}
nz-form-control {
  width: 100%;
}
textarea {
  width: 100%;
  box-sizing: border-box;
}
.ant-divider.ant-divider-horizontal {
  margin-top: 0%;
  margin-bottom: 0%;
}
.ant-form-item-label > label::after {
  content: "" !important;
}
.remove-btn-defaults {
  background: inherit;
  border: inherit;
  padding: 0;
}
/*# sourceMappingURL=lead-comments.component-Z5STHSNC.css.map */
