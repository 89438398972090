import { Component, EventEmitter, Input, Output, SimpleChanges, OnInit, OnChanges  } from '@angular/core';
import { LeadDetailsService } from '../../../services/lead-details.service';
import { CommonModule, } from '@angular/common';
import { BaseComponent } from '../../../shared/utils/base.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoggerService } from '../../../shared/services/logger.service';
import { performValidation } from '../../../shared/utils/validation.util';
import { NgZorroModule } from '../../../shared/modules/ng-zorro/ng-zorro.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ListTableComponent } from '../../../shared/components/list-table/list-table.component';
import { SectionsService } from '../../../services/sections.service';
import { STATIC_JSON_DATA_PATH } from '../../../shared/constants/static-data.constants';

@Component({
  selector: 'ppl-lead-comments',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, NgZorroModule, NzIconModule, ListTableComponent],
  templateUrl: './lead-comments.component.html',
  styleUrl: './lead-comments.component.css'
})
export class LeadCommentsComponent extends BaseComponent implements OnInit, OnChanges{
  comments: any[] =[] ;
  showCommentBox = false;
  entry: any;
  commentsForm!: FormGroup;
  steps : any;
  _clearComments = false;
  cols: any;
  copyOfGridData: any = [];

  @Input() set sourcePage(data: any) {
    this.entry = data;
  }

  @Input() set sectionData(data: string) {
    this.steps = data
  }

  @Input() leadId  = 0;
  @Input() program = '';

  @Input() 
  set clearComments(value: boolean) {
    this._clearComments = value;
    if(value){
      this.cancelComments();
    }
  }

  get clearComments() {
    return this._clearComments;
  }
  
  @Output() leadUpdated = new EventEmitter<boolean>();

  constructor(private leadDetailsService : LeadDetailsService,  private fb: FormBuilder, private logger : LoggerService, private sectionService : SectionsService){
    super();
  }

  ngOnInit(){
    this.createFormGroup();
    this.getFromData();
    this.leadDetailsService.getUpdatedLeadData().subscribe(
      (data)=> {
        this.comments = data?.responsedata?.comments ? data?.responsedata?.comments : [];
        this.copyAndTransformGridData(this.comments);
      }
    )
    
    
  }

  async getFromData() {
    try {
      if (this.entry) {
        const tempCols = await this.sectionService.getJsonConfigurationData(
          STATIC_JSON_DATA_PATH.LEAD_DETAILS_CONTACT_LIST_HEADERS, this.entry
        );
        this.cols = tempCols.comments.columns;
      }
    } catch (e) {
      this.logger.debug('Comments- Error in getting column data', e);
    }

  }

  ngOnChanges(changes: SimpleChanges){
    try{
      if(changes['sectionData'] && changes['sectionData'].currentValue){
        this.secData = this.steps['lead_detail_comments'];
        this.logger.debug('Error in getting section data and updating latest info', this.secData);
      }
    }catch(e){
      this.logger.debug('Error in getting section data and updating latest info', e);
    }
  }


  createFormGroup(){
    this.commentsForm  = this.fb.group({
      comments: [''],
      leadCommentsId: [''],
      leadId: [],
    });
  }

  copyAndTransformGridData(gridData: any[]) {
    if (gridData && Array.isArray(gridData)) {
      const copyGridData = JSON.parse(JSON.stringify(gridData));

      this.copyOfGridData = copyGridData.map((item: any) => ({
        ...item,
        leadUserComment: item.leadUserComment ? item.leadUserComment : '-',
        modifiedDate: super.formatDateStringNoMs(item.modifiedDate),
        modifiedByName:item.modifiedByName,
      }));

      this.copyOfGridData = [...this.copyOfGridData];
    }

  }


  enableComments(){
    this.showCommentBox = true;
    this.commentsForm.patchValue({ leadId: this.leadId});
  }

  cancelComments(){
    this.showCommentBox = false;
    this.commentsForm.reset()
  }

  saveComments(){
    /* this.showCommentBox = false; */

    const data = {...this.commentsForm.value };

    try{
      this.validationErrors = performValidation(data, this.secData, 'Save');
    }catch(e){
      this.logger.debug("Error in validations", e);
    }

    if (Object.keys(this.validationErrors).length === 0) {
      const payload = this.preparePayload(data)
      this.leadDetailsService.addLeadComments(payload, this.entry).subscribe((data)=>{
        this.logger.info('Comment saved successfully');
        /* this.getLatestLeadData() */
        if(data.status == 200){
          this.leadUpdated.emit(true);
        }
        this.cancelComments();
      })
  
    }

  }


  preparePayload(source: any) : any {
    return {
      id: source.leadId,
      commentsId : source.leadCommentsId,
      leadUserComment: source.comments,
      programCode: this.program
    };

  }
}
