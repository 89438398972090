import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { PDFFormComponent } from "../../../leads/pdf-form/pdf-form.component";
import { AccordionComponent } from "../../../../shared/components/accordion/accordion.component";
import { AuthorizeDirective } from '../../../../shared/authorization/authorize.directive.';
import { ChecklistParticipantComponent } from '../../../leads/participant-checklist/participant-checklist.component';
import { ActivatedRoute } from '@angular/router';
import { ProviderChecklistComponent } from "../../../leads/provider-checklist/provider-checklist.component";
import { BaseComponent } from '../../../../shared/utils/base.component';
import { AuthorizationUtility } from '../../../../shared/authorization/auth.utility';
import { MEMBER_VIEW_PAGE_IDS, TABS } from '../../../../shared/constants/page.constants';
import { STATIC_DATA } from '../../../../shared/constants/static-data.constants';

@Component({
  selector: 'ppl-checklist',
  standalone: true,
  imports: [CommonModule, PDFFormComponent, AccordionComponent, AuthorizeDirective, ChecklistParticipantComponent, ProviderChecklistComponent],
  templateUrl: './checklist.component.html',
  styleUrl: './checklist.component.css'
})
export class ChecklistComponent extends BaseComponent {
  pageId = MEMBER_VIEW_PAGE_IDS[localStorage.getItem(STATIC_DATA.URL_FIRST_SEGMENT)!];
  tabId = TABS.Checklist;
  readonly = false;
  isParticipant = false;
  isEditable = false;

  constructor(private route: ActivatedRoute, private authUtility: AuthorizationUtility) {
    super();
    this.isEditable = this.authUtility.isSectionEditable(this.pageId, this.tabId, TABS.Checklist);
    if (Object.prototype.hasOwnProperty.call(route.snapshot.data, 'isParticipant')){
      this.isParticipant = route.snapshot.data['isParticipant'];
    } else {
      if(localStorage.getItem('lead_entry') == 'participantEnrollment') {
        this.isParticipant = true;
      } else {
        if(localStorage.getItem('lead_entry') == 'participantEnrollment') {
          this.isParticipant = false;
        }
      }
    }
  }
}
