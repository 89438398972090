@if(secData){
    <div nzSpan="10" class="mb-2">
      <nz-card class="pt-0 pb-0">
          <div nz-row>
              @if(isAttrAvailable('participant_id')){
                  <div nz-col nzSpan="4" ngClass="font-weight-600 pt-2 pb-2">
                    {{ getAttributes("participant_id").label }}
                  </div>
                  <div nz-col nzSpan="4"  ngClass="pt-2 pb-2">{{ filledInfo?.participant_id ? filledInfo?.participant_id : '-' }}</div>
                
                  } @if(isAttrAvailable('referral_id')){
                
                  <div nz-col nzSpan="4" ngClass="font-weight-600 pt-2 pb-2">
                    {{ getAttributes("referral_id").label }}
                  </div>
                  <div nz-col nzSpan="4"  ngClass="pt-2 pb-2">{{ filledInfo?.referral_id ? filledInfo?.referral_id : '-' }}</div>
                
                  } @if(isAttrAvailable('program_id')){
                
                  <div nz-col nzSpan="4" ngClass="font-weight-600 pt-2 pb-2">
                    {{ getAttributes("program_id").label }}
                  </div>
                  <div nz-col nzSpan="4"  ngClass="pt-2 pb-2">{{ filledInfo?.program_id ? filledInfo?.program_id : '-' }}</div>
                
                  }  
                  
                   @if(isAttrAvailable('finalApplicantDisposition')){
              
                <div nz-col nzSpan="4" ngClass="font-weight-600 pt-2 pb-2">
                  {{ getAttributes("finalApplicantDisposition").label }}
                </div>
                <div nz-col nzSpan="4"  ngClass="pt-2 pb-2">{{ filledInfo?.finalApplicantDisposition }}</div>
              
                } @if(isAttrAvailable('applicationStatusLkp')){
              
                <div nz-col nzSpan="4" ngClass="font-weight-600 pt-2 pb-2">
                  {{ getAttributes("applicationStatusLkp").label }}
                </div>
                <div nz-col nzSpan="4"  ngClass="pt-2 pb-2">{{ filledInfo?.applicationStatus ? getLookupValue( applicationStatusList, filledInfo?.applicationStatus) :  '' }}</div>
              
                }
                 @if(isAttrAvailable('applicationStageLkp')){
              
                <div nz-col nzSpan="4" ngClass="font-weight-600 pt-2 pb-2">
                  {{ getAttributes("applicationStageLkp").label }}
                </div>
                <div nz-col nzSpan="4"  ngClass="pt-2 pb-2">{{ filledInfo?.applicationStage ? getLookupValue( applicationStageList, filledInfo?.applicationStage) :  '' }}</div>
              
                } 
                @if(isAttrAvailable('trackingNumber')){
              
                  <div nz-col nzSpan="4" ngClass="font-weight-600 pt-2 pb-2">
                    {{ getAttributes("trackingNumber").label }}
                  </div>
                  <div nz-col nzSpan="4"  ngClass="pt-2 pb-2">{{ filledInfo?.trackingNumber }}</div>
                
                  } 
          </div>
      </nz-card>
    </div>
  }