@if(listButton.length>0){
<button nz-button nz-dropdown [nzDropdownMenu]="menu4" class="fancy-button">
    Select an Action <i class="fa fa-chevron-right" aria-hidden="true"></i>
</button>
<nz-dropdown-menu #menu4="nzDropdownMenu">
    <ul nz-menu>
        @for(btns of listButton; track $index){
        <li nz-menu-item (click)="actionClicked(btns)">{{btns.button_label}}</li>
        }
    </ul>
</nz-dropdown-menu>
}

    <!-- <ppl-alertbox 
    [(isAlertVisible)]="alertDetails.isVisible" 
    [message]="alertDetails?.message" 
    [title]="alertDetails?.title"
    [success]="false"
    (onClose)="show = false"></ppl-alertbox> -->

    <!-- type: 'error', title: '', message: '', isVisible: false -->
<!-- <ppl-modal [showModal]="show" [title]="title" [isOkText]="okText" (onOk)="handleOk()" (onCancel)="handleCancel()">
        @if(title!='Assign to me'){
        <nz-select [nzMaxTagCount]="3"
            [nzMode]="(title=='Assign to User' && selectedCheckBoxValue.length>1) ? 'multiple' : 'default'"
            nzPlaceHolder={{placeholder}} [(ngModel)]="listOfSelectedValue" style="width:100%; margin-bottom: 16px"
            nzShowArrow>
            @for(item of listOfOption; track $index ){
            <nz-option [nzLabel]="item" [nzValue]="item"></nz-option>
            }
        </nz-select>
        }
        @if(title=='Approve'){
        <nz-select [nzMaxTagCount]="3" nzPlaceHolder="Please select program" [(ngModel)]="selectedProgram"
            style="width:100%; margin-bottom: 16px" nzShowArrow>
            @for(program of programs; track $index){
            <nz-option [nzLabel]="program.program_name" [nzValue]="program.program_code"></nz-option>
            }
        </nz-select>
        }
        <div class="form-group">
            <label for="comments">Comments: </label>
            <textarea rows="3" nz-input id="comments" [(ngModel)]="comments"
                placeholder="Please enter comments here"></textarea>
        </div>
</ppl-modal> -->


<ppl-popup-dialog 
      [(isVisible)]="show" 
      [title]="title" 
      [isOkText]="okText"
      (confirm)="handleOk()"
      (cancel)="handleCancel()"
      [showButtons] = "true"
      [showOkButton]="false"
      [size]="'medium'"
      [position]="'center'">
      @if(showApproved){
        <div class="ppl-p24">
            <p class="ppl-gray-text ppl-mt20">Your Application is approved.</p>
            <p>An email/SMS has been sent to you with a registration link to proceed.</p>
        </div>
      }
      @else {
        @if(title==="Update Status"){
            <nz-select [nzMaxTagCount]="3"
                [nzMode]="'default'"
                nzPlaceHolder={{placeholderStatus}} [(ngModel)]="listOfSelectedValueStatus" style="width:100%; margin-bottom: 16px" nzShowArrow>
                @for(item of listOfOtherOptions; track $index ){
                <nz-option [nzLabel]="item" [nzValue]="item"></nz-option>
                }
            </nz-select>
        }

      @if(!['Assign to me', 'Send SMS', 'Send Email'].includes(title)){
            <nz-select [nzMaxTagCount]="3"
                [nzMode]="(title==='Assign to User' && selectedCheckBoxValue.length>1) ? 'multiple' : 'default'"
                nzPlaceHolder={{placeholder}} [(ngModel)]="listOfSelectedValue" style="width:100%; margin-bottom: 16px"
                nzShowArrow>
                @for(item of listOfOption; track $index ){
                <nz-option [nzLabel]="item" [nzValue]="item"></nz-option>
                }
            </nz-select>

        }
        
        @if(['Send SMS', 'Send Email'].includes(title)){
            <p>Note that the {{type}} will be sent to the {{notificationType}} specified in Contact Details under the profile.</p>
        }
        @if(title==='Approve' && (flow === 'provider' || flow === 'participant')){
        <nz-select [nzMaxTagCount]="3" nzPlaceHolder="Please select Program" [(ngModel)]="selectedProgram"
            style="width:100%; margin-bottom: 16px" nzShowArrow>
            @for(program of programs; track $index){
            <nz-option [nzLabel]="program.program_name" [nzValue]="program.program_code"></nz-option>
            }
        </nz-select>
        }
        @if (type === 'Email') {
            <div class="form-group">
                <label for="subject">Subject</label>
                <nz-textarea-count [nzMaxCharacterCount]="500">
                <textarea rows="3" nz-input id="subject" [(ngModel)]="subject"
                    placeholder="Start typing..." maxlength="500"></textarea>
                </nz-textarea-count>
                @if(noMessage){
                    <p class="error-message">Please enter a Subject.</p>
                }
            </div>
        }

        <div class="form-group">
            <label for="comments">{{labelForText}}</label>
            <nz-textarea-count [nzMaxCharacterCount]="title === 'Send SMS' ? 160 : 10000">
            <textarea rows="3" nz-input id="comments" [(ngModel)]="comments"
                placeholder="Start typing..." [maxlength]="title === 'Send SMS' ? 160 : 10000"></textarea>
            </nz-textarea-count>
            @if(noMessage){
                <p class="error-message">Please enter a message.</p>
            }
        </div>
    }
    </ppl-popup-dialog>
