/* src/app/components/lead-details/prospective-participants/prospective-info/prospective-info.component.css */
[nz-icon] {
  margin-right: 6px;
  font-size: 1.429rem;
  color: #3c86f5;
}
.ant-form-item {
  margin-bottom: 0.5em;
}
/*# sourceMappingURL=prospective-info.component-4VBSYWI2.css.map */
