import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ContactDetailsComponent } from '../../contact-details/contact-details.component';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { AddressComponent } from '../../../../shared/components/address/address.component';
import { EnrollmentAddressComponent } from '../enrollment-address/enrollment-address.component';

@Component({
  selector: 'ppl-enrollment-contact-details',
  standalone: true,
  imports: [
    NzFormModule,
    FormsModule,
    ReactiveFormsModule,
    NzGridModule,
    NzCardModule,
    NzSwitchModule,
    NzToolTipModule,
    AddressComponent,
    NzDividerModule,
    NzRadioModule,
    CommonModule,
    EnrollmentAddressComponent,
  ],
  templateUrl: './enrollment-contact-details.component.html',
  styleUrl: './enrollment-contact-details.component.css',
})
export class EnrollmentContactDetailsComponent
  extends ContactDetailsComponent
  implements OnChanges
{
  @Input() updatedFormValue: FormGroup['value'] | null;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['updatedFormValue'] &&
      changes['updatedFormValue'].currentValue &&
      this.updatedFormValue
    ) {
      this.contactForm.markAsDirty();
      this.contactForm.patchValue(this.updatedFormValue);
    }
  }
}
