/* src/app/components/dashboard/dashboard.component.css */
:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --background-color: #ecf0f1;
  --text-color: #2c3e50;
  --border-color: #bdc3c7;
  --font-size-small: 0.875rem;
  --font-size-medium: 1rem;
  --font-size-large: 1.25rem;
  --spacing-small: 0.5rem;
  --spacing-medium: 1rem;
  --spacing-large: 2rem;
  --border-radius-small: 0.25rem;
  --border-radius-medium: 0.5rem;
  --border-radius-large: 1rem;
}
body {
  font-family: Arial, sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
}
button {
  background-color: var(--primary-color);
  color: #fff;
  padding: var(--spacing-medium) var(--spacing-large);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-medium);
  font-size: var(--font-size-medium);
}
button:hover {
  background-color: var(--secondary-color);
}
/*# sourceMappingURL=dashboard.component-S3YINN2W.css.map */
