/* src/app/components/editpdf/editpdf.component.css */
.left-panel {
  width: 20%;
  float: left;
}
.right-panel {
  width: 80%;
  display: inline-block;
}
/*# sourceMappingURL=editpdf.component-RVANVSQG.css.map */
