import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { ERROR_MESSAGES } from '../shared/constants/error-message.constants';
import { ENDPOINTS } from '../shared/constants/url.constants';
import { GlobalErrorHandlerService } from '../shared/services/global-error-handler.service';
import { HttpRequestService } from '../shared/services/http-request.service';
import { NotificationService } from '../shared/services/notification.service';
import { formatUrl } from '../shared/utils/format-url-util';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationsDetailsService {

  
  constructor(
    private http: HttpRequestService,
    private errorHandler: GlobalErrorHandlerService,
    private notificationService : NotificationService
  ) {}


  updateAuthorizationDetails(program_code: string, authorization_id: string, data: any): any {
    console.log('lead_id', authorization_id);
    const url = formatUrl(
       ENDPOINTS.UPDATE_AUTHORIZATION_DETAILS,
      { program_code, authorization_id }
    );
    console.log(url);
    return this.http.patch(url, data).pipe(
        tap(() => { 
          this.notificationService.success(
            'Success',
            'Successfully updated Authorization'
          );
        }),
        catchError((err) => {
          return throwError(() => this.errorHandler.handleError(err));
        })
      );

  }

  saveAuthorization(program_code: string,data: any, rfrwRequest = false) {
    const url = formatUrl(ENDPOINTS.SAVE_AUTHORIZATIONS, {program_code});
    return this.http.post(url, data, rfrwRequest).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

}
