{
  "columns": [
    {
      "header_id": "code",
      "header_label": "Code",
      "jsonpath": "code",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "subcategoryLkp",
      "header_label": "Sub Category",
      "jsonpath": "subcategoryLkp",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "description",
      "header_label": "Description",
      "jsonpath": "description",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "allowOverride",
      "header_label": "Override Allowed",
      "jsonpath": "allowOverride",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "effectiveFromDate",
      "header_label": "Effective Start Date",
      "jsonpath": "effectiveFromDate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "effectiveEndDate",
      "header_label": "Effective End Date",
      "jsonpath": "effectiveEndDate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "internalMessage",
      "header_label": "Internal Message",
      "jsonpath": "internalMessage",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "externalMessage",
      "header_label": "External Message",
      "jsonpath": "externalMessage",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "providerTypes",
      "header_label": "Personal Assistant Type",
      "jsonpath": "providerTypes",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "serviceCodes",
      "header_label": "Service Code",
      "jsonpath": "serviceCodes",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "dispositionLkp",
      "header_label": "Disposition",
      "jsonpath": "dispositionLkp",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "createdby",
      "header_label": "Created By",
      "jsonpath": "createdby",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "createddate",
      "header_label": "Created Date",
      "jsonpath": "createddate",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "modifiedby",
      "header_label": "Modified By",
      "jsonpath": "modifiedby",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "modifieddate",
      "header_label": "Modified Date",
      "jsonpath": "modifieddate",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    }
  ],
  "buttons": [],
  "apiDetails": "processCode.json",
  "pageName": "process-codes-list",
  "orderByColumn": ["processCodeDetailId"],
  "orderBy": ["desc"]
}
