@if(mode==="read"){
    <div class="table-style">
      <ppl-list-table [pageConfig]="programName" [sharedDataKey]="sharedDataKey" (rowDetailEvent)="rowClicked($event)"
        [loadPage]="true" [data]="tableData" [columnsToBeHidden]="columnsToBeHidden" [disableSort]="true" [disableSearch]="true" [disableSelection]="true" 
        [actionsToBeHidden]="actionsToBeHidden" jsonList="list_auth_timesheets">
      </ppl-list-table>
    </div>


    <!-- [disableSort]="true" [disableSearch]="true" [enableActions]="true" [data]="copyOfGridData"
  [disableSelection]="true" [cols]="cols" (gridAction)="action($event)" [gridTitle]="false" [pagination]="false"
  [loadPage]="true" [actionButtons]="actionButtons" -->
}