<ppl-inner-page-layout>
    <div header>
        New Registration
    </div>
    <div body>
        <nz-card class="ppl-main-halfwidth" style="width:50%; margin:auto">
            <button nz-button nzType="primary" (click)="newEnrollment()" nzBlock class="ppl-mt16">Add new
                Registration</button>
            <button nz-button nzType="default" (click)="goTo('add_employer')" nzBlock class="ppl-mt16">Add new
                Employer</button>
            <button nz-button nzType="default" (click)="goTo('add_authRepresentative')" nzBlock class="ppl-mt16">Add new
                Designated Representative</button>
            <button nz-button nzType="default" (click)="goTo('add_individualProvider')" nzBlock class="ppl-mt16">Add new
                Personal Assistant</button>
            <button nz-button nzType="default" (click)="goTo('add_vendor')" nzBlock class="ppl-mt16">Add
                new Vendor</button>
            <button nz-button nzType="default" (click)="goTo('add_independentContractor')" nzBlock class="ppl-mt16">Add
                new
                Independent Contractor</button>
            <button nz-button nzType="default" (click)="goTo('add_legalGuardian')" nzBlock class="ppl-mt16">Add new
                Legal Guardian</button>
            <button nz-button nzType="default" (click)="goTo('add_POA')" nzBlock class="ppl-mt16">Add new
                POA</button>
        </nz-card>
    </div>
</ppl-inner-page-layout>