/* src/app/shared/components/actions/actions.component.css */
.drop-btn {
  cursor: pointer;
  padding: 7px;
  border-radius: 4px;
  margin-left: 0;
  border: 1px solid #cdc7c7;
  font-size: 1rem;
}
.fancy-button {
  display: flex;
  padding: 8px 12px;
  height: 2.571rem;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 2px solid #bbbbbb;
  background:
    linear-gradient(
      99deg,
      #DDD -11.82%,
      #D6D6D6 60.87%,
      #F0F0F0 119.15%);
  color: #3F3F3F;
  text-shadow: 0px 2px 4px #FFF;
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.fancy-button:hover {
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 8px;
  border: 2px solid #11B8AE;
  background:
    linear-gradient(
      99deg,
      #DDD -11.82%,
      #F2F2F2 60.87%,
      #F0F0F0 119.15%);
}
.error-message {
  font-size: 1.143rem;
}
/*# sourceMappingURL=actions.component-CHQD7D3M.css.map */
