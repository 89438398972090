import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ServiceDetailsComponent } from '../../service-details/service-details.component';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CommonModule } from '@angular/common';
import { NzGridModule } from 'ng-zorro-antd/grid';

@Component({
  selector: 'ppl-enrollment-service-details',
  standalone: true,
  imports: [
    NzCardModule,
    NzFormModule,
    ReactiveFormsModule,
    NzSelectModule,
    NzToolTipModule,
    CommonModule,
    NzGridModule,
  ],
  templateUrl: './enrollment-service-details.component.html',
  styleUrl: './enrollment-service-details.component.css',
})
export class EnrollmentServiceDetailsComponent extends ServiceDetailsComponent implements OnChanges{
  @Input() updatedFormValue: FormGroup['value'] | null;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['updatedFormValue'] &&
      changes['updatedFormValue'].currentValue &&
      this.updatedFormValue
    ) {
      this.servicesForm.markAsDirty();
      this.servicesForm.patchValue(this.updatedFormValue);
    }
  }
}
