import { Component, computed, EventEmitter, inject, Input, OnInit, Output, Signal, signal, WritableSignal } from '@angular/core';
import { ParticipantInformationComponent } from '../../leads/participant-information/participant-information.component';
import { ServiceDetailsComponent } from '../../leads/service-details/service-details.component';
import { ContactDetailsComponent } from '../../leads/contact-details/contact-details.component';
import { CommPreferenceComponent } from '../../leads/comm-preference/comm-preference.component';
import { ReferralDataService } from '../../../services/referral-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { BaseComponent } from '../../../shared/utils/base.component';
import { SectionsService } from '../../../services/sections.service';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { EnrollmentServiceDetailsComponent } from "../../leads/enrollment/enrollment-service-details/enrollment-service-details.component";
import { EnrollmentCommPreferencesComponent } from '../../leads/enrollment/enrollment-comm-preferences/enrollment-comm-preferences.component';
import { EnrollmentContactDetailsComponent } from "../../leads/enrollment/enrollment-contact-details/enrollment-contact-details.component";
import { EnrollmentInfoComponent } from "../../leads/enrollment/enrollment-info/enrollment-info.component";
import { ProviderSearchAndSelectComponent } from '../../lead-details/provider-search-and-select/provider-search-and-select.component';
import { SearchProvidersDetailsItem } from '../../../shared/interfaces/provider.model';
import { AddAssociationFormComponent } from '../../lead-details/add-association-form/add-association-form.component';
import { STATIC_DATA } from '../../../shared/constants/static-data.constants';
import { Form, FormGroup } from '@angular/forms';
import { JsonPipe } from '@angular/common';
import { EditMode } from '../../../interfaces/edit-mode.model';
import { EnrollmentDataService } from '../../../services/enrollment-data.service';

@Component({
  selector: 'ppl-new-enrollment',
  standalone: true,
  imports: [
    ParticipantInformationComponent,
    ServiceDetailsComponent,
    ContactDetailsComponent,
    CommPreferenceComponent,
    NzButtonModule,
    ButtonComponent,
    EnrollmentServiceDetailsComponent,
    EnrollmentCommPreferencesComponent,
    EnrollmentContactDetailsComponent,
    EnrollmentInfoComponent,
    ProviderSearchAndSelectComponent,
    AddAssociationFormComponent,
],
  templateUrl: './new-enrollment.component.html',
  styleUrl: './new-enrollment.component.css',
})
export class NewEnrollmentComponent extends BaseComponent implements OnInit {
  private referralDataSvc = inject(ReferralDataService);

  @Input() flow:any;
  @Input({required: true}) mode = EditMode.Edit;
  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  sectionNameFormType = STATIC_DATA.ASSOCIATION_INDEPENDENT_PROVIDER;
  selectedProviderToAdd: WritableSignal<SearchProvidersDetailsItem | null> = signal(null);
  providerId: WritableSignal<number | null> = signal(null);

  associationForm: WritableSignal<FormGroup | null> = signal(null);
  associationFormValue: FormGroup['value'] | null = null;
  constructor(
    private referralService: ReferralDataService,
    private router: Router,
    private route: ActivatedRoute,
    private sectionService: SectionsService,
    private enrollmentDataService: EnrollmentDataService
  ) {
    super();
    this.referralDataSvc.validationError.subscribe({next: (validationError) => {
      this.collectValidationErrors(validationError);
    }});
  }


  ngOnInit(): void {
    if (this.referralDataSvc.associationDetails && !this.selectedProviderToAdd()) {
      const associationDetails = this.referralDataSvc.associationDetails;
      this.providerId.set(associationDetails?.basicInfo?.providerId || null);
    }
    // this.route.paramMap.subscribe((params) => {
    //   const flow = params.get('flow');
     this.sectionService.setFlow(this.flow);
    // });
    
  }

  collectValidationErrors(validationError: object | any) {
    const keysToCheck = ['email', 'mobile'];
    if(typeof validationError === 'object' && this.hasKeys(validationError, keysToCheck)) {
      this.validationErrors = {
        ...this.validationErrors,
        ...validationError
      }
    }
  }

  private hasKeys(obj: object, keys: string[]) {
    return keys.some(key => key in obj);
  }

  onSubmit() {
    this.sectionService.setFlow('participantEnrollment');
  }

  onCancel() {
    this.referralService.setFlowForAssociation(false);
    this.sectionService.setFlow('participantEnrollment');
    this.enrollmentDataService.setParticipantAssocChanged(true);
   // this.router.navigateByUrl('/home/participantEnrollmentList');
  }

  handleAddNewProvider() {
    this.mode = EditMode.Edit;

  }

  handleProviderSelected(provider: SearchProvidersDetailsItem) {
    this.selectedProviderToAdd.set(provider);
    this.mode = EditMode.Edit;
  }

  handleFormUpdate(formGroup: FormGroup) {
    this.associationForm.set(formGroup);
    this.associationFormValue = formGroup.value;
  }


}
