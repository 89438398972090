import { AfterViewInit, Directive, ElementRef, inject, Input } from '@angular/core';

enum NzElement {
  Select = 'NZ-SELECT'
}

@Directive({
  selector: '[pplNzAriaLabel]',
  standalone: true
})
export class NzAriaLabelDirective implements AfterViewInit {
  private el = inject(ElementRef);

  @Input({required: true}) nzAriaLabel: string = '';

  constructor() { }

  ngAfterViewInit(): void {
    this.applyAriaLabel();
  }

  applyAriaLabel() {
    if (!this.el) {
      return
    }
    const el = this.el.nativeElement as HTMLElement;
    const targetEl = this.findInputOrButton(el);

    if (!targetEl) {
      return
    }
    targetEl.ariaLabel = this.nzAriaLabel;
  }

  findInputOrButton(element: HTMLElement): HTMLElement | null {
    const queue: HTMLElement[] = [element];
  
    while (queue.length > 0) {
      const current = queue.shift()!;
      if (current.tagName === 'INPUT' || current.tagName === 'BUTTON') {
        return current;
      }
      for (let i = 0; i < current.children.length; i++) {
        queue.push(current.children[i] as HTMLElement);
      }
    }
  
    return null;
  }
}
