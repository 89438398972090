import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LookupService } from '../../../services/lookup.service';
import { ListService } from '../../services/list.service';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonConfig } from '../../interfaces/list.interface';
import { FormsModule } from '@angular/forms';
import { ErrorAlertService } from '../../services/error-alert.service';
import { ModalComponent } from '../modal/modal.component';
import { HttpRequestService } from '../../services/http-request.service';
import { PopupDialogComponent } from '../popup-dialog/popup-dialog.component';
import { SharedDataService } from '../../services/shared-data-service';
import { STATIC_DATA } from '../../constants/static-data.constants';
import { NotificationService } from '../../services/notification.service';
import { AlertboxComponent } from '../alertbox/alertbox.component';
import { NgZorroModule } from '../../modules/ng-zorro/ng-zorro.module';
import { BaseComponent } from '../../utils/base.component';
import { FileTriageService } from '../../../services/fileTriage.service';
import { FileUploadNotificationService } from '../../../services/file-upload-notification.service';
import { FileUploadNotificationData } from '../../../interfaces/file-upload-notification.interface';

@Component({
  selector: 'ppl-actions',
  standalone: true,
  imports: [
    OverlayPanelModule,
    ButtonModule,
    FormsModule,
    ModalComponent,
    PopupDialogComponent,
    AlertboxComponent,
    NgZorroModule,
  ],
  templateUrl: './actions.component.html',
  styleUrl: './actions.component.css',
})
export class ActionsComponent extends BaseComponent implements OnInit {
  @Output() leadUpdated = new EventEmitter<boolean>();
  @Input() listButton: ButtonConfig[] = [];
  @Input() selectedCheckBoxValue: any[] = [];
  @Input() sourcePage = '';
  @Input() show: any;
  @Input() from = '';
  comments = '';
  subject = '';
  approvalReasons: any[] = [];
  updateReasons: any[] = [];
  denialReasons: any[] = [];
  listOfSelectedValue: string[] = [];
  listOfSelectedValueStatus = '';
  selectedProgram = '';
  userList: any;
  programs: any[] = [];
  listOfOption: string[] = [];
  listOfOtherOptions: string[] = [];
  leadStatus: string[] = [];
  title = '';
  okText = '';
  isAssignToUser = false;
  placeholder = '';
  program: any;
  email: string[] = [];
  numbers: string[] = [];
  type = '';
  notificationType = '';
  labelForText = '';
  flow: any;
  showApproved = false;
  showButtons = true;
  noMessage = false;
  placeholderStatus = '';
  // showOkButton: boolean = false;
  /* alertDetails: any = {type: 'error', title: '', message: '', isVisible: false}; */

  constructor(
    private listService: ListService,
    private http: HttpRequestService,
    private sharedDataService: SharedDataService,
    private lookupService: LookupService,
    private errorAlertService: ErrorAlertService,
    private notificationService: NotificationService,
    private fileTriageService: FileTriageService,
    private sharedService: SharedDataService,
    private fileUploadNotificationService: FileUploadNotificationService
  ) {
    super();
  }

  ngOnInit(): void {
    const program = JSON.parse(localStorage.getItem('selected_program')!);
    if (program) {
      this.program = program.program_code;
    }
    this.listService.getPrograms().subscribe((data: any) => {
      if (data.responsedata) {
        this.programs = data.responsedata.filter(
          (program: any) => program?.program_code !== STATIC_DATA.LEAD_PROGRAM
        );
      }
    });
    this.listService.applicationDataSub.subscribe((data: any) => {
      if (this.from === 'details') {
        const mobileNumbers = data[0].mobile_number;
        this.email = data[0].email_id;
        this.numbers = mobileNumbers.map((number: string) => {
          if (!number.startsWith('+1')) {
            return '+1' + number;
          }
          return number;
        });
        this.numbers = mobileNumbers.map((number: string) => {
          const phoneNumber = number.replace(/-/g, '');
          if (!phoneNumber.startsWith('+1')) {
            return '+1' + phoneNumber;
          }
          return phoneNumber;
        });
      }
    });
    this.flow = this.sharedDataService.getType(this.sourcePage);
    /* this.errorAlertService.getAlertDetails().subscribe((details: any) => {
      this.alertDetails = details;
      console.log('Alert Details', details);

    }); */
  }

  handleOk(): void {
    const leadIds = this.selectedCheckBoxValue.map((item) => {
      if (this.flow === 'participant') {
        return item.lead_participant_id || item.leadId;
      } else if (this.flow === 'provider') {
        return item.lead_id || item.leadId;
      } else if (this.flow === 'referral') {
        return item.referral_participant_id || item.leadId;
      } else if (this.flow === 'participantenrlmnt') {
        return item.participant_id || item.leadId;
      } else if(this.flow === 'filetriage'){
          return item.filePath;
      } else {
        return item.provider_id || item.leadId;
      }
    });
    const selectedValues = Array.isArray(this.listOfSelectedValue)
      ? this.listOfSelectedValue
      : [this.listOfSelectedValue];
    const program = JSON.parse(localStorage.getItem('selected_program')!);
    const programCode = program.program_code;
    const commPayload: any = {
      message: this.comments,
      program: programCode,
      leadIds: leadIds.map((id) =>
        typeof id === 'number' ? id.toString() : id
      ),
      type: this.sharedDataService.getType(this.sourcePage),
    };
    switch (this.title) {
      case 'Assign to User': {
        const userMap = new Map<string, number>(
          this.userList.responsedata.map((user: any) => [
            `${user.first_name} ${user.last_name}`,
            user.userid,
          ])
        );
        const userIds = selectedValues
          .map((name: string) => userMap.get(name))
          .filter((id) => id !== undefined);
        const updatedPayload = {
          ids: leadIds,
          comments: this.comments,
          selectedUsers: userIds,
          type: this.sharedService.getType(this.sourcePage),
          programCode: programCode,
        };
        this.listService.assignUser(updatedPayload).subscribe((data: any) => {
          if (data.status == 200) {
            this.leadUpdated.emit(true);
            this.notificationService.alert(
              'success',
              'Success',
              'Assigned to user successfully!'
            );
            this.handleCancel();
          }
        });
        break;
      }

      case 'Assign to me': {
        const user = JSON.parse(localStorage.getItem('user_details')!);
        const userId = user.userid;
        const updatedPayload = {
          ids: leadIds,
          comments: this.comments,
          selectedUsers: [userId],
          type: this.sharedService.getType(this.sourcePage),
          programCode: programCode,
        };
        this.listService.assignUser(updatedPayload).subscribe((data: any) => {
          if (data.status == 200) {
            this.leadUpdated.emit(true);
            this.notificationService.alert(
              'success',
              'Success',
              'Assigned to you successfully!'
            );
            this.handleCancel();
          }
        });
        break;
      }

      case 'Approve': {
        const program = (this.program = JSON.parse(
          localStorage.getItem('selected_program')!
        ));
        const type = this.sharedDataService.getType(this.sourcePage);
        const flowTypes = ['referral', 'participantenrlmnt', 'providerenrlmnt'];
        const selectedProgram = flowTypes.includes(this.flow)
          ? program.program_code
          : this.selectedProgram;
        const reason = this.getLookupName(
          this.approvalReasons,
          selectedValues[0]
        );
        if (this.flow === 'participant' || this.flow === 'provider') {
          let errorMessage = '';
          if (!selectedProgram && !reason) {
            errorMessage =
              'Please select both Program and Reason to complete this action.';
          } else if (!selectedProgram && reason) {
            errorMessage = 'Please select a Program to complete this action.';
          } else if (selectedProgram && !reason) {
            errorMessage = 'Please select a Reason to complete this action.';
          }
          if (errorMessage) {
            this.errorAlertService.openModal('error', 'Error', errorMessage);
            break;
          }
        }

        const updatedPayload = {
          ...(type === 'referral'
            ? { referralIds: leadIds }
            : type === 'participantenrlmnt'
            ? { participantIds: leadIds }
            : type === 'providerenrlmnt'
            ? { providerIds: leadIds }
            : { leadIds }),
          comments: this.comments,
          programCode: selectedProgram,
          status: 'APPROVE',
          reasonCode: reason,
        };
        this.listService
        .updateUserStatus(updatedPayload, this.sharedDataService.getType(this.sourcePage))
        .subscribe((data) => {
          if (data.status == 200) {
            this.leadUpdated.emit(true);
            this.notificationService.alert(
              'success',
              'Success',
              'Approved successfully!'
            );
            this.handleCancel();
          }
        });
        break;
      }

      case 'Deny': {
        const type = this.sharedDataService.getType(this.sourcePage);
        const updatedPayload = {
          ...(type === 'referral'
            ? { referralIds: leadIds }
            : type === 'participantenrlmnt'
            ? { participantIds: leadIds }
            : type === 'providerenrlmnt'
            ? { providerIds: leadIds }
            : { leadIds }),
          comments: this.comments,
          programCode: programCode,
          status: 'REJECT',
          reasonCode: this.getLookupName(this.denialReasons, selectedValues[0]),
        };
        this.listService
          .updateUserStatus(
            updatedPayload,
            this.sharedDataService.getType(this.sourcePage)
          )
          .subscribe((data) => {
            if (data.status == 200) {
              this.leadUpdated.emit(true);
              this.notificationService.alert(
                'success',
                'Success',
                'Denied successfully!'
              );
              this.handleCancel();
            }
          });
        break;
      }
      case 'Resurrect': {
        const type = this.sharedDataService.getType(this.sourcePage);
        const updatedPayload = {
          ...(type === 'referral'
            ? { referralIds: leadIds }
            : type === 'participantenrlmnt'
            ? { participantIds: leadIds }
            : type === 'providerenrlmnt'
            ? { providerIds: leadIds }
            : { leadIds }),
          comments: this.comments,
          programCode: programCode,
          status: 'RESURRECT',
          reasonCode: this.getLookupName(
            this.approvalReasons,
            selectedValues[0]
          ),
        };
        this.listService
          .updateUserStatus(
            updatedPayload,
            this.sharedDataService.getType(this.sourcePage)
          )
          .subscribe((data) => {
            if (data.status == 200) {
              this.leadUpdated.emit(true);
              this.notificationService.alert(
                'success',
                'Success',
                'Resurrected successfully!'
              );
              this.handleCancel();
            }
          });
        break;
      }
      case 'Send SMS': {
        const payload: any = {
          ...commPayload,
          communicationType: 'SMS',
          ...(this.from === 'details' ? { recipients: this.numbers } : {}),
        };
        if (this.comments.trim() == '') {
          this.noMessage = true;
        } else {
          this.noMessage = false;
          this.listService
            .sendNotification(this.from, payload)
            .subscribe((data) => {
              if (data.status == 200) {
                this.notificationService.alert(
                  'success',
                  'Success',
                  'SMS sent successfully!'
                );
              }
              this.handleCancel();
            });
        }
        break;
      }
      case 'Send Email': {
        const payload: any = {
          ...commPayload,
          communicationType: 'EMAIL',
          subject: this.subject,
          ...(this.from === 'details' ? { recipients: this.email } : {}),
        };
        if (this.comments.trim() == '') {
          this.noMessage = true;
        } else {
          this.noMessage = false;
          this.listService
            .sendNotification(this.from, payload)
            .subscribe((data) => {
              if (data.status == 200) {
                this.notificationService.alert(
                  'success',
                  'Success',
                  'Email sent successfully!'
                );
              }
              this.handleCancel();
            });
        }
        break;
      }
      case 'Update Status': {
        const program = (this.program = JSON.parse(
          localStorage.getItem('selected_program')!
        ));
        const type = this.sharedDataService.getType(this.sourcePage);
        const flowTypes = ['referral', 'participantenrlmnt', 'providerenrlmnt'];
        const updatedPayload = {
          participantIds: leadIds,
          comments: this.comments,
          programCode: flowTypes.includes(this.flow)
            ? program.program_code
            : this.selectedProgram,
          status: this.listOfSelectedValueStatus
            .toUpperCase()
            .replace(/\s/g, '_'),
          reasonCode: this.getLookupName(
            this.approvalReasons,
            selectedValues[0]
          ),
        };
        // if(this.okText == 'Ok'){
        //   this.handleCancel();
        // }
        // else{
        this.listService
          .updateParticipantStatus(updatedPayload, true)
          .subscribe((data) => {
            if (data.status == 200) {
              this.listOfSelectedValueStatus = '';
              this.notificationService.alert(
                'success',
                'Success',
                'Status Updated successfully!'
              );
              this.leadUpdated.emit(true);
              // this.listOfSelectedValue = [];
              // this.comments = '';
              // this.selectedProgram = '';
              this.handleCancel();
            }
          });
        // }
        break;
      }
    }
  }

  getLookupName(lookupArr: any[], valueName: string): string | undefined {
    const lookup = lookupArr.find((gen) => {
      return gen.lkpValueName === valueName;
    });
    return lookup ? lookup.lkpValueCode : undefined;
  }

  handleCancel(): void {
    this.listOfSelectedValue = [];
    this.show = false;
    this.showApproved = false;
    this.showButtons = true;
    this.comments = '';
    this.selectedProgram = '';
    this.noMessage = false;
    this.listOfSelectedValueStatus = '';
    this.type = '';
    this.title = '';
    // this.showOkButton = false;
  }
  actionClicked(btn: any) {
    this.leadStatus = this.selectedCheckBoxValue.map(
      (item) => item.application_status_lkp
    );
    if (!this.selectedCheckBoxValue?.length) {
      const errorMessage = 'Please select records for completing this action.';
      this.errorAlertService.openModal('error', 'Error', errorMessage);
      return;
    }

    const setupModal = (
      title: string,
      okText: string,
      placeholder: string,
      isAssignToUser: boolean
    ) => {
      this.show = true;
      this.title = title;
      this.okText = okText;
      this.isAssignToUser = isAssignToUser;
      this.placeholder = placeholder;
    };

    this.labelForText = 'Comments: ';
    switch (btn.buttonAction) {
      case 'assign_to_user': {
        if (
          this.leadStatus.some(
            (status: string) =>
              status == 'Approved' ||
              status == 'Denied' ||
              status == 'Disenrolled'
          )
        ) {
          const errorMessage =
            'Please deselect the Approved and Denied applications to continue with this action.';
          this.errorAlertService.openModal('error', 'Error', errorMessage);
          break;
        }
        const payload = {
          lookupValue: [{ lkpValueCode: 'FMS', lkpValueName: 't' }],
        };
        this.listService.getUsers(payload).subscribe((data: any) => {
          this.userList = data;
          this.listOfOption = data.responsedata.map(
            (user: any) => `${user.first_name} ${user.last_name}`
          );
        });
        setupModal('Assign to User', 'Assign', 'Please select User', true);
        break;
      }

      case 'assign_to_me':
        if (
          this.leadStatus.some(
            (status: string) =>
              status == 'Approved' ||
              status == 'Denied' ||
              status == 'Disenrolled'
          )
        ) {
          const errorMessage =
            'Please deselect the Approved and Denied applications to continue with this action.';
          this.errorAlertService.openModal('error', 'Error', errorMessage);
          break;
        }
        setupModal('Assign to me', 'Assign', 'Please select Reason', false);
        break;

      case 'approve':
        if (
          this.leadStatus.some(
            (status: string) =>
              status == 'Approved' ||
              status == 'Denied' ||
              status == 'Disenrolled'
          )
        ) {
          const errorMessage =
            'Please deselect the Approved and Denied applications to continue with this action.';
          this.errorAlertService.openModal('error', 'Error', errorMessage);
          break;
        }
        this.approvalReasons = this.lookupService.getLookupValue(
          'Lead_Reason_for_Approval'
        );
        this.listOfOption = this.approvalReasons.map(
          (item) => item.lkpValueName
        );
        setupModal('Approve', 'Approve', 'Please select Reason', false);
        break;

      case 'Deny':
        if (
          this.leadStatus.some(
            (status: string) =>
              status == 'Approved' ||
              status == 'Denied' ||
              status == 'Disenrolled'
          )
        ) {
          const errorMessage =
            'Please deselect the Approved and Denied applications to continue with this action.';
          this.errorAlertService.openModal('error', 'Error', errorMessage);
          break;
        }
        this.denialReasons = this.lookupService.getLookupValue(
          'Lead_Reason_for_Denial'
        );
        this.listOfOption = this.denialReasons.map((item) => item.lkpValueName);
        setupModal('Deny', 'Deny', 'Please select Reason', false);
        break;

      case 'Resurrect':
        if (
          this.leadStatus.some(
            (status: string) => status !== 'Denied' && status !== 'Disenrolled'
          )
        ) {
          const errorMessage = 'Only denied applications can be resurrected.';
          this.errorAlertService.openModal('error', 'Error', errorMessage);
          break;
        }
        this.approvalReasons = this.lookupService.getLookupValue(
          'Lead_Reason_for_Approval'
        );
        this.listOfOption = this.approvalReasons.map(
          (item) => item.lkpValueName
        );
        setupModal('Resurrect', 'Resurrect', 'Please select Reason', false);
        break;
      case 'sendSMS':
        this.labelForText = 'Message: ';
        this.type = 'SMS';
        this.notificationType = 'mobile number';
        setupModal('Send SMS', 'Send', 'Please type here...', false);
        break;
      case 'sendEmail':
        this.labelForText = 'Message: ';
        this.type = 'Email';
        this.notificationType = 'email address';
        setupModal('Send Email', 'Send', 'Please type here...', false);
        break;
      case 'fileTriageAssign':
        this.fileTriageService.callOpenDialogFunction(this.selectedCheckBoxValue);
        break;
      case 'fileUploadNotificationAcknowledge':
        this.fileUploadNotificationService.acknowledgeFiles(
          this.selectedCheckBoxValue as FileUploadNotificationData[],
          this.program
        ).subscribe((res) => {
          if(res.status === 200) {
            this.notificationService.alert(
              'success',
              'Success',
              `Successfully acknowledged ${res?.responsedata?.acknowledgments?.length} file(s).`
            );
          } else {
            this.notificationService.alert(
              'error',
              'Error',
              'Error acknowledging file(s).'
            );
          }
          this.leadUpdated.emit(true);
        });
        break;
      case 'update_status':
        if (
          this.leadStatus.some(
            (status: string) =>
              status == 'Approved' ||
              status == 'Denied' ||
              status == 'Disenrolled'
          )
        ) {
          const errorMessage =
            'Please deselect the Approved and Denied applications to continue with this action.';
          this.errorAlertService.openModal('error', 'Error', errorMessage);
          break;
        }
        this.approvalReasons = this.lookupService.getLookupValue(
          'Lead_Reason_for_Approval'
        );
        this.updateReasons = this.lookupService.getLookupValue(
          'Participant_Enrollment_status'
        );
        this.listOfOption = this.approvalReasons.map(
          (item) => item.lkpValueName
        );
        this.listOfOtherOptions = this.updateReasons.map(
          (item) => item.lkpValueName
        );
        this.placeholderStatus = 'Please select status';
        setupModal('Update Status', 'Update', 'Please select Reason', false);
        break;
      case 'send_registration':
        if (
          this.flow === 'participantenrlmnt' &&
          this.leadStatus.some(
            (status: string) =>
              status == 'In Progress' || status == 'New Application'
          )
        ) {
          const errorMessage =
            'Please deselect New and In Progress applications to continue with this action.';
          this.errorAlertService.openModal('error', 'Error', errorMessage);
          break;
        }
        {
          const leadIds = this.selectedCheckBoxValue.map((item) => {
            if (this.flow === 'participant') {
              return item.lead_participant_id || item.leadId;
            } else if (this.flow === 'provider') {
              return item.lead_id || item.leadId;
            } else if (this.flow === 'referral') {
              return item.referral_participant_id || item.leadId;
            } else if (this.flow === 'participantenrlmnt') {
              return item.participant_id || item.leadId;
            } else {
              return item.provider_id || item.leadId;
            }
          });
          let pageType = '';
          if (this.from === 'details' && this.flow === 'participantenrlmnt') {
            pageType = 'participantDetail';
          } else if (
            this.from !== 'details' &&
            this.flow === 'participantenrlmnt'
          ) {
            pageType = 'participantList';
          } else if (
            this.from === 'details' &&
            this.flow === 'providerenrlmnt'
          ) {
            pageType = 'providerDetail';
          } else if (
            this.from !== 'details' &&
            this.flow === 'providerenrlmnt'
          ) {
            pageType = 'providerList';
          }
          const program = (this.program = JSON.parse(
            localStorage.getItem('selected_program')!
          ));
          const flowTypes = [
            'referral',
            'participantenrlmnt',
            'providerenrlmnt',
          ];
          let updatedPayload: any;
          if (this.flow === 'participantenrlmnt') {
            updatedPayload = {
              participantIds: leadIds,
              comments: this.comments,
              pageType: pageType,
              commType: 'Registration',
              programCode: flowTypes.includes(this.flow)
                ? program.program_code
                : this.selectedProgram,
            };
          } else if (this.flow === 'providerenrlmnt') {
            updatedPayload = {
              providerIds: leadIds,
              programCode: flowTypes.includes(this.flow)
                ? program.program_code
                : this.selectedProgram,
            };
          }
          this.listService
            .sendRegistrationLink(updatedPayload, this.flow)
            .subscribe((data: any) => {
              if (data.status == 200) {
                this.notificationService.alert(
                  'success',
                  'Success',
                  'Registration Link resent successfully!'
                );
                this.leadUpdated.emit(true);
                this.handleCancel();
              }
            });
          break;
        }
    }
  }
}
