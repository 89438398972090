@if(mode==="edit"){
<div class="ppl-mt8">
    @for(header of extractValues('header'); track $index){
    <h2>{{header}}</h2>
    }
    @for(text of extractValues('text'); track $index){
    <p class="ppl-description">{{text}}</p>
    }
</div>

<div class="ppl-mt24">
    <nz-card nz-col [nzSpan]="20" class="ppl-step-main" [nzBordered]="false">
        <form nz-form [formGroup]="paymentDetailsForm" class="ppl-p0">
            @if(isAttrAvailable('paymentMethod')){
            <nz-form-item class="ppl-form-item">
                <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="paymentMethod"
                    class="ppl-form-label">{{getAttributes('paymentMethod').label}}
                    @if(getAttributes('paymentMethod').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('paymentMethod')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="paymentMethod" class="ppl-select" id="paymentMethod" nzShowArrow
                            nzPlaceHolder="{{getAttributes('paymentMethod').label}}"
                            [ngClass]="{'error': validationErrors['paymentMethod'] }">
                            <nz-option [nzLabel]="'Direct Deposit to Bank Account'" [nzValue]="'bankAccnt'"></nz-option>
                            <nz-option [nzLabel]="'Deposit to new Debit Card'" [nzValue]="'debitCard'"></nz-option>
                        </nz-select>
                    </div>
                    @if (validationErrors['paymentMethod']) {
                    <span class="pl-1 error-message">{{validationErrors['paymentMethod']}}</span>
                    }
                </nz-form-control>
            </nz-form-item>
            }
            @if(paymentDetailsForm.get('paymentMethod')?.value === 'bankAccnt'){
            @if(isAttrAvailable('bankName')){
            <nz-form-item class="ppl-form-item">
                <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="bankName"
                    class="ppl-form-label">{{getAttributes('bankName').label}}
                    @if(getAttributes('bankName').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('bankName')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="bankName" id="bankName"
                            placeholder="{{getAttributes('bankName').placeholder}}"
                            [ngClass]="{'error': validationErrors['bankName'] }" />
                    </div>
                    @if (validationErrors['bankName']) {
                    <span class="pl-1 error-message">{{validationErrors['bankName']}}</span>
                    }
                </nz-form-control>
            </nz-form-item>
            }
            @if(isAttrAvailable('accountType')){
            <nz-form-item class="ppl-form-item">
                <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="accountType"
                    class="ppl-form-label">{{getAttributes('accountType').label}}
                    @if(getAttributes('accountType').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('accountType')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-radio-group formControlName="accountType" class="ppl-radio-group" id="accountType"
                            [ngClass]="{'error': validationErrors['accountType'] }">
                            <label nz-radio-button [nzValue]="'checking'">Checking account</label>
                            <label nz-radio-button [nzValue]="'savings'">Savings account</label>
                        </nz-radio-group>
                    </div>
                    @if (validationErrors['accountType']) {
                    <span class="pl-1 error-message">{{validationErrors['accountType']}}</span>
                    }
                </nz-form-control>
            </nz-form-item>
            }
            @if(isAttrAvailable('routingNumber')){
            <nz-form-item class="ppl-form-item">
                <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="routingNumber"
                    class="ppl-form-label">{{getAttributes('routingNumber').label}}
                    @if(getAttributes('routingNumber').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('routingNumber')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="routingNumber" id="routingNumber"
                            placeholder="{{getAttributes('routingNumber').placeholder}}"
                            [ngClass]="{'error': validationErrors['routingNumber'] }" />
                    </div>
                    @if (validationErrors['routingNumber']) {
                    <span class="pl-1 error-message">{{validationErrors['routingNumber']}}</span>
                    }
                </nz-form-control>
            </nz-form-item>
            }
            @if(isAttrAvailable('accountNumber')){
            <nz-form-item class="ppl-form-item">
                <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="accountNumber"
                    class="ppl-form-label">{{getAttributes('accountNumber').label}}
                    @if(getAttributes('accountNumber').mandatory === 'Y'){
                    <span>*</span>
                    }</nz-form-label>
                <nz-form-control class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('accountNumber')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input id="accountNumber" formControlName="accountNumber"
                            placeholder="{{getAttributes('accountNumber').placeholder}}"
                            [ngClass]="{'error': validationErrors['accountNumber'] }" />
                    </div>
                    @if (validationErrors['accountNumber']) {
                    <span class="pl-1 error-message">{{validationErrors['accountNumber']}}</span>
                    }
                </nz-form-control>
            </nz-form-item>}
            }
            @if(isAttrAvailable('payStub')){
            <nz-form-item class="ppl-form-item">
                <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="gender"
                    class="ppl-form-label">{{getAttributes('payStub').label}}
                    @if(getAttributes('payStub').mandatory === 'Y'){
                    <span>*</span>
                    }</nz-form-label>
                <nz-form-control [nzSm]="16" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('payStub')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-radio-group formControlName="payStub" class="ppl-radio-group" id="payStub"
                            [ngClass]="{'error': validationErrors['payStub'] }">
                            @for(opt of radioOptions; track $index){
                            <label nz-radio-button [nzValue]="opt.value">{{opt.label}}</label>
                            }
                        </nz-radio-group>
                    </div>
                    @if (validationErrors['payStub']) {
                    <span class="pl-1 error-message">{{validationErrors['payStub']}}</span>
                    }
                </nz-form-control>
            </nz-form-item>}
        </form>
    </nz-card>
</div>}
@else if(mode==="read"){
@if(isAttrAvailable('paymentMethod')){
<div nz-row>
    <div nz-col nzSpan="8" class="ppl-read-label">{{ getAttributes('paymentMethod').label }} :</div>
    <!-- <div nz-col nzSpan="16">{{ filledInfo['paymentMethod']}}</div> -->
</div>
}
@if(paymentDetailsForm.get('paymentMethod')?.value === 'bankAccnt'){
@if(isAttrAvailable('bankName')){
<div nz-row>
    <div nz-col nzSpan="8" class="ppl-read-label">{{ getAttributes('bankName').label }} :</div>
    <!-- <div nz-col nzSpan="16">{{ filledInfo['bankName']}}</div> -->
</div>
}
@if(isAttrAvailable('accountType')){
<div nz-row>
    <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('accountType').label}} :</div>
    <!-- <div nz-col nzSpan="16">{{ filledInfo['accountType']}}</div> -->
</div>
}
@if(isAttrAvailable('routingNumber')){
<div nz-row>
    <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('routingNumber').label}} :</div>
    <!-- <div nz-col nzSpan="16">{{ filledInfo['routingNumber']}}</div> -->
</div>
}
@if(isAttrAvailable('accountNumber')){
<div nz-row>
    <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('accountNumber').label}} :</div>
    <!-- <div nz-col nzSpan="16">{{ filledInfo['accountNumber']}}</div> -->
</div>
}
}
@if(isAttrAvailable('payStub')){
<div nz-row>
    <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('payStub').label}} :</div>
    <!-- <div nz-col nzSpan="16">{{ filledInfo['payStub'] }}</div> -->
</div>
}
}