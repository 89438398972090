@if(mode==="edit" || mode==="add"){
<div class="custom-row">

  <ppl-button 
  label="Cancel" 
  icon="fa fa-ban" 
  [customClass]="'alt-button'" 
  [iconPosition]="'left'"
  (click)="cancelEdit()"
  ></ppl-button>    

  <ppl-button 
  label="Save" 
  icon="fa fa-floppy-o" 
  [customClass]="'alt-button'" 
  [iconPosition]="'left'"
  (click)="save()"
  ></ppl-button>

  <!-- <span
    (click)="cancelEdit()"
    nz-icon
    nzType="close-circle"
    nzTheme="outline"
  ></span>
  <span nz-icon nzType="check-circle" nzTheme="outline" (click)="save()"></span> -->
  
</div>

<div class="ppl-mt20">
  <form nz-form nz-row [formGroup]="contactForm" class="ppl-p0">
    @if(isAttrAvailable('contactMethod')){
    <nz-form-item
      nz-col
      nzSpan="12"
      nzPaddingLeft="20"
      class="ppl-form-item nz-padding-left"
    >
      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="contactMethod"
          >{{ getAttributes("contactMethod").label }}
          @if(getAttributes('contactMethod').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label
        >
        <nz-form-control class="ppl-form-control" nzLabelAlign="left">
          <!-- <nz-radio-group
            nz-tooltip
            nzTooltipTitle="{{ getAttributes('contactMethod').tooltip }}"
            nzTooltipPlacement="topRight"
            formControlName="contactMethod"
            class="ppl-radio-group"
            id="contactMethod"
          >
            @for(opt of communicationType; track $index){
            <label nz-radio-button [nzValue]="opt.lkpValueCode">{{
              opt.lkpValueName
            }}</label>
            }
          </nz-radio-group> -->
          <nz-select 
          nzTooltipPlacement="topRight"
          formControlName="contactMethod" class="ppl-select" nzId="contactMethod" nzShowSearch nzAllowClear  [ngClass]="{'error': validationErrors['contactMethod'] }">
            @for(opt of communicationType; track $index){
            <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
            }
          </nz-select>

          @if (validationErrors['contactMethod']) {
            <span class="pl-1 error-message">{{validationErrors['contactMethod']}}</span>
          }
        </nz-form-control>
      </nz-row> </nz-form-item
    >} @if(isAttrAvailable('value')){
    <nz-form-item
      nz-col
      nzSpan="12"
      nzPaddingLeft="20"
      class="ppl-form-item nz-padding-left"
    >
      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="value"
          >{{ getAttributes("value").label }}
          @if(getAttributes('value').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control pl-2">
          <input
            nz-input
            nz-tooltip
            nzTooltipTitle="{{ getAttributes('value').tooltip }}"
            nzTooltipPlacement="topRight"
            formControlName="value"
            id="value"
            placeholder="{{ getAttributes('value').placeholder }}"
            [ngClass]="{'error': validationErrors['value'] }"
          />
          @if (validationErrors['value']) {
            <span class="pl-1 error-message">{{validationErrors['value']}}</span>
          }
        </nz-form-control>
      </nz-row>
    </nz-form-item>
    } @if(isAttrAvailable('preferred')){
    <nz-form-item
      nz-col
      nzSpan="12"
      nzPaddingLeft="20"
      class="ppl-form-item nz-padding-left"
    >
      <nz-row>
        <nz-form-label
          [nzSm]="8"
          [nzXs]="24"
          nzFor="preferred"
          class="ppl-form-label"
          >{{ getAttributes("preferred").label }}</nz-form-label
        >
        <nz-form-control class="ppl-form-control">
          <nz-switch nzId="preferred" formControlName="preferred" pplNzAriaLabel nzAriaLabel="preferred"></nz-switch>
        </nz-form-control>
      </nz-row>
    </nz-form-item>
    }
  </form>
</div>
} @else if(mode==="read"){
@if(leadStatus !== approvedStatus) {
  <div class="custom-row">
    <ppl-button 
    label="Add" 
    icon="fa fa-plus" 
    [customClass]="'ghost-button'" 
    [iconPosition]="'left'"
    (click)="add()"
    *authorize="[pageId, tabId, 'CommunicationMethods', 'section', 'edit']"
    ></ppl-button> 

    <!-- <span nz-icon nzType="plus-circle" nzTheme="outline" (click)="add()" *authorize="[pageId, tabId, 'CommunicationMethods', 'section', 'edit']"></span> -->
  </div>
}

@if(copyOfGridData.length > 0){
  <ppl-list-table
  [disableSort]="true"
  [disableSearch]="true"
  [enableActions]="isSectionEditable"
  [data]="copyOfGridData"
  [disableSelection]="true"
  [cols]="cols"
  (gridAction)="action($event)"
  [gridTitle]="false"
  [pagination]="false"
  [loadPage]="true"
  [actionButtons]="actionButtons"
>
</ppl-list-table>
}@else {
  <nz-row>
    <p>No Data found</p>
  </nz-row>
}



}@else if (mode==="read" && !copyOfGridData) {
  <nz-row>
    <p>No Data found</p>
  </nz-row>
}
<div class="custom-row" style="height: 0.3rem"></div>