/* src/app/components/lead-details/participant-information/lead-detail-eligibility-dates/lead-detail-eligibility-dates.component.css */
.nz-padding-left {
  padding-left: 1rem;
}
.custom-row {
  display: flex;
  justify-content: flex-end;
}
[nz-icon] {
  margin-right: 6px;
  font-size: 1.429rem;
  color: #3c86f5;
}
.ant-radio-group {
  display: block;
  align-items: left !important;
}
.ant-radio-button-wrapper {
  margin: 0px;
  width: 33%;
}
.mr-2 {
  margin-right: 10px !important;
}
/*# sourceMappingURL=lead-detail-eligibility-dates.component-ASB53BDL.css.map */
