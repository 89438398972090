/* src/app/shared/components/dropdown-button/dropdown-button.component.css */
.dropdown-container {
  position: relative;
  display: inline-block;
  z-index: 2;
}
.ppl-content-container {
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
  max-height: calc(100vh - 280px);
  overflow-y: auto;
  position: relative;
  z-index: 1;
}
.dropdown-toggle {
  display: flex;
  height: 2.571rem;
  padding: 8px;
  align-items: center;
  gap: 10px;
  border-radius: 0px 12px 12px 0px;
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
  border-left: 3px solid #11B8AE;
  background: #FFF;
  box-shadow: -4px 0px 18px 0px rgba(0, 0, 0, 0.15);
  transform: rotate(180deg);
  z-index: 1001;
}
.dropdown-toggle:hover {
  cursor: pointer;
  background: rgb(249, 234, 255);
  filter: invert(0);
  transition: background-color 0.3s ease;
}
img {
  filter: invert(0.5);
}
.dropdown-menu {
  transition: background-color 0.3s ease;
  position: absolute;
  top: 102%;
  left: -455px;
  display: flex;
  width: 489px;
  padding: 32px;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
  gap: 18px;
  flex-wrap: wrap;
  border-radius: 12px 0px 12px 12px;
  border: 4px solid #FFF;
  box-shadow: 0px 0px 104px 0px rgba(0, 0, 0, 0.10) inset, -8px 8px 24px 0px rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.459);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 1000;
  list-style: none;
}
.dropdown-menu li {
  padding: 0;
}
.dropdown-menu li a {
  text-decoration: none;
}
.dropdown-container .dropdown-toggle:focus {
  outline: none;
}
/*# sourceMappingURL=dropdown-button.component-SWB2VWGJ.css.map */
