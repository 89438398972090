import { Injectable } from '@angular/core';
import { LookupDomain, LookupValueItem } from '../interfaces/lookup.interface';
import { ENDPOINTS } from '../shared/constants/url.constants';
import { formatUrl } from '../shared/utils/format-url-util';
import { HttpRequestService } from '../shared/services/http-request.service';
import { ListService } from '../shared/services/list.service';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  constructor(private http: HttpRequestService, private listService: ListService){}

  fetchLookupValues() {
    const program = JSON.parse(localStorage.getItem("selected_program")!);
    const program_code = program.program_code;

    const payload = {
      "programCode": program_code,
      "payloadData": {
        "lkpdomainname":
          ["STATE", "Gender", "Address_Type", "Contact_Type", "Special_Accomdation", "Language", "Identifier_type", "Communication_Type", "Lead_Application_Status",
            "Lead_Application_Stage",
            "Lead_Reason_for_Denial",
            "Lead_Reason_for_Approval",
            "Participant_Enrollment_status",
            "Lead_Application_Source",
            "License_Type",
            "Service_Type",
            "service_details",
            "Waiver",
            "Participant_Register_Mode",
            "ProviderType",
            "Relationship",
            "Race",
            "Eye Color",
            "Hair Color",
            "Tax Exemption",
            "enr_status_reason",
            "process_code_cat",
            "Yes_No",
            "TS_DIS",
            'Rate_Type', 'Medicaid_Billing_Code', 'Service_Group', 'Increment_Units', 'Measurement_Units', 'County', 'City',
            'LDSS_Office',
            'CFI',
            'Utype'
          ]
      }
    }
    // return this.http.post<any>("http://localhost:8080/api/v1/getLookupValues", payload);
    return this.http.post(ENDPOINTS.GET_LOOKUP_VALUES, payload);

    //pending API changes, make this http.post<ApiResponse<LookupDomain>>
  };

  getOrgData(program_code: string) {
    const url = formatUrl(ENDPOINTS.GET_ORG_DATA, { program_code });
    return this.http.get(url);
  }

  getLookupValue(key: string): LookupValueItem[] {
    let lookupValues: LookupValueItem[] = [];
    const lookupResponse = JSON.parse(localStorage.getItem("lookup_values")!);
    const lookupDomains = lookupResponse?.responsedata ?? [] as LookupDomain[];
    const targetDomain = lookupDomains.find((value: LookupDomain) => value.lkpDomainName === key);

    if (targetDomain) {
      lookupValues = [...targetDomain.lookupValue];
    }
    return lookupValues;
  }

  getProviderTypeLookup() {
    const program = JSON.parse(localStorage.getItem("selected_program")!);
    const program_code = program.program_code;
    const url = formatUrl(ENDPOINTS.GET_PROGRAM_TYPES, { program_code });
    return this.http.get(url);
  }

  getProviderLookupValue() {
    const providers = JSON.parse(localStorage.getItem("provider_lookup_values")!);
    return providers.responsedata.providerTypes;
  }

  getLookupName(lookupArr: LookupValueItem[], valueCode: string): string | undefined {
    const lookup = lookupArr.find((gen) => {
      return gen.lkpValueCode === valueCode;
    });
    return lookup ? lookup.lkpValueName : undefined;
  }

  fetchTasLookupValues() {
    const program = JSON.parse(localStorage.getItem("selected_program")!);
    const program_code = program.program_code;

    const payload = {
      "programCode": program_code,
      "payloadData": {
        "lkpdomainname":
          ["ng_tas_modifier_detail",
            "ng_tas_servicecode_detail",
            "ng_tas_diagnosis_detail",
            "ng_pay_region",
            "ng_pay_region_county"
          ]
      }
    }
    return this.http.post(ENDPOINTS.GET_TAS_LOOKUP_VALUES, payload);

  };

  getTasLookupValue(key: string) {
    let lookupValues: any = [];
    const lookupResponse = JSON.parse(localStorage.getItem("tas_lookup_values")!);
    const lookupDomains = lookupResponse?.responsedata;
    lookupValues = lookupDomains[key];

    // if (targetDomain) {
    //   lookupValues = [...targetDomain.lookupValue];
    // }
    return lookupValues;
  }
}
