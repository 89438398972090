@if(isPageRestrictionLoaded){
<div class="ppl-container-body">
    <div class="ppl-container-head">
        <div style="display: flex; gap: 12px;">
            <!-- <div class="action">
                <ppl-button 
                label="" 
                icon="fa fa-angle-left" 
                [customClass]="'back-button'" 
                [iconPosition]="'left'"
                (handleClick)="returnClicked($event)"></ppl-button>               
            </div> -->

            Consumer Interest
        </div>
        <div class="action">
            <!-- <div id="dropBtn" class="drop-btn" (click)="toggleTempZ()">
              <ppl-dropdown-button />
            </div> -->
            <ppl-button label="Create New Consumer Interest" icon="fa fa-plus" [customClass]="'primary-button'"
                [iconPosition]="'left'" [authorizeButton]="[pageId, 'newParticipantLead']"
                (click)="onNewLead()"></ppl-button>
        </div>
    </div>
    <div class="ppl-container">
        <div class="ppl-content-container">
            <div class="table-style">
                <ppl-list-table [pageTitle]="title" [pageConfig]="programName" [sharedDataKey]="sharedDataKey"
                    (rowDetailEvent)="rowClicked($event)" [loadPage]="lazyLoad" [jsonList]="'list_participant_leads'"
                    [columnsToBeHidden]="columnsToBeHidden" [actionsToBeHidden]="actionsToBeHidden"
                    rowDataAccessibilityKey="lead_participant_id"
                >
                </ppl-list-table>
            </div>
        </div>
    </div>
</div>
}
<!-- <ng-container>
    <div class="ref-head-btn">
        <h2>Participant Leads</h2>
        <div class="button-sec">
            <button class="create-button btn-primary" [authorizeButton]="[pageId, 'newParticipantReferral']"
                (click)="onNewReferral()" style="margin-right:8px;">
                <span nz-icon nzType="layout"></span>
                Create New Referral
            </button>
            <button class="create-button btn-primary" [authorizeButton]="[pageId, 'newParticipantLead']"
                (click)="onNewLead()">
                <span nz-icon nzType="layout"></span>
                Create New Participant Lead
            </button>
        </div>
    </div>

    <div class="ppl-main-fullwidth">
        <ppl-list-table [pageTitle]="title" [pageConfig]="programName" [sharedDataKey]="sharedDataKey"
            (rowDetailEvent)="rowClicked($event)" [loadPage]="lazyLoad" [jsonList]="'list_participant_leads'"
            [columnsToBeHidden]="columnsToBeHidden" [actionsToBeHidden]="actionsToBeHidden">
        </ppl-list-table>
    </div>
</ng-container> -->