<ng-container>
  @if(isPageRestrictionLoaded){
  <div class="ppl-container-body">
    <div class="ppl-container-head">File Upload Notification</div>
    <div class="ppl-container">
      <div class="ppl-content-container">
        <div class="table-style">
          <ppl-list-table
            [pageConfig]="programName"
            [sharedDataKey]="sharedDataKey"
            (rowDetailEvent)="rowClicked($event)"
            [loadPage]="lazyLoad"
            [columnsToBeHidden]="columnsToBeHidden"
            [actionsToBeHidden]="actionsToBeHidden"
            [jsonList]="'list_file_upload_notification'"
            rowDataAccessibilityKey="fileName"
          />
        </div>
      </div>
    </div>
  </div>
  }
</ng-container>
