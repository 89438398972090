@if(mode==="edit" || mode==="add"){
<div class="custom-row">
  <ppl-button label="Cancel" icon="fa fa-ban" [customClass]="'alt-button'" [iconPosition]="'left'"
    (click)="cancelEdit()"></ppl-button>
  <ppl-button label="Save" icon="fa fa-floppy-o" [customClass]="'alt-button'" [iconPosition]="'left'"
    (click)="save()"></ppl-button>
</div>

<div class="ppl-mt20">
  <form nz-form nz-row [formGroup]="viewerForm" class="ppl-p0">
    @if(mode==="edit") {
    <div nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24">
          Association Type
        </nz-form-label>

        <nz-form-label [nzSm]="8" [nzXs]="24">
          {{selectedViewer.displayAssociationType}}
        </nz-form-label>
      </nz-row>
    </div>

    <div nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24">
          Entity
        </nz-form-label>

        <nz-form-label [nzSm]="8" [nzXs]="24">
          {{selectedViewer.viewerValue}}
        </nz-form-label>
      </nz-row>
    </div>
    }
    @if(mode==="add" && isAttrAvailable('mcoOrgName') && viewerType === 'MCO'){
    <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="mcoOrgName" class="ppl-form-label"
          [authorizeField]="[pageId, tabId, 'Demographics', 'mcoOrgName']">{{ getAttributes("mcoOrgName").label }}
          @if(getAttributes('mcoOrgName').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('mcoOrgName').tooltip}}"
          nzTooltipPlacement="topRight">
          <nz-select formControlName="mcoOrgName" class="ppl-select" nzId="mcoOrgName" nzPlaceHolder="Select MCO"
            (ngModelChange)="viewerValueChange($event)" [ngClass]="{'error': validationErrors['mcoOrgName'] }"
            nzShowSearch nzAllowClear>
            @for(opt of mcoEntries; track $index){
            <nz-option [nzLabel]="opt.orgName" [nzValue]="opt.orgName"></nz-option>
            }
          </nz-select>
          @if (validationErrors['mcoOrgName']) {
          <span class="pl-1 error-message">{{validationErrors['mcoOrgName']}}</span>
          }
        </nz-form-control>
      </nz-row>
    </nz-form-item>
    }
    @if(mode==="add" && isAttrAvailable('fiOrgName') && viewerType === 'FI'){
    <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="fiOrgName" class="ppl-form-label"
          [authorizeField]="[pageId, tabId, 'Demographics', 'fiOrgName']">{{ getAttributes("fiOrgName").label }}
          @if(getAttributes('fiOrgName').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('fiOrgName').tooltip}}"
          nzTooltipPlacement="topRight">
          <nz-select formControlName="fiOrgName" class="ppl-select" nzId="fiOrgName" nzPlaceHolder="Select CDPAP Facilitator"
            (ngModelChange)="viewerValueChange($event)" [ngClass]="{'error': validationErrors['fiOrgName'] }"
            nzShowSearch nzAllowClear>
            @for(opt of fiEntries; track $index){
            <nz-option [nzLabel]="opt.orgName" [nzValue]="opt.orgName"></nz-option>
            }
          </nz-select>
          @if (validationErrors['fiOrgName']) {
          <span class="pl-1 error-message">{{validationErrors['fiOrgName']}}</span>
          }
        </nz-form-control>
      </nz-row>
    </nz-form-item>
    }

    @if(mode==="add" && isAttrAvailable('userId') && viewerForm.get('associationType')){
    <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="userId" class="ppl-form-label"
          [authorizeField]="[pageId, tabId, 'Demographics', 'userId']">{{ getAttributes("userId").label }}
          @if(getAttributes('userId').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('userId').tooltip}}"
          nzTooltipPlacement="topRight">
          <nz-select formControlName="userId" class="ppl-select" nzId="userId" nzPlaceHolder="Select User"
            [nzDisabled]="!users || users.length === 0" [ngClass]="{'error': validationErrors['userId'] }" nzShowSearch
            nzAllowClear>
            @for(opt of users; track $index){
            <nz-option [nzLabel]="opt.identifier" [nzValue]="opt.userId"></nz-option>
            }
          </nz-select>
          @if (validationErrors['userId']) {
          <span class="pl-1 error-message">{{validationErrors['userId']}}</span>
          }
        </nz-form-control>
      </nz-row>
    </nz-form-item>
    }
    @if(mode==="add" && viewerType === 'State') {
      <div nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left"></div>
    }

    @if(isAttrAvailable('associationStartDate')){
    <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">

      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="associationStartDate"
          class="ppl-form-label">{{getAttributes('associationStartDate').label}}
          @if(getAttributes('associationStartDate').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('associationStartDate')?.tooltip || ''"
            nzTooltipPlacement="topRight">
            <nz-date-picker formControlName="associationStartDate" [id]="'associationStartDate'" style="height: 32px;" [nzDisabledDate]="disabledStartDate"
              [nzFormat]="'MM/dd/yyyy'" [nzPlaceHolder]="getAttributes('associationStartDate').placeholder"
              [ngClass]="{'error': validationErrors['associationStartDate'] }"></nz-date-picker>
          </div>
          @if (validationErrors['associationStartDate']) {
          <span class="pl-1 error-message">{{validationErrors['associationStartDate']}}</span>
          }
        </nz-form-control>
      </nz-row>
    </nz-form-item>
    }

    @if(isAttrAvailable('associationEndDate')){
    <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">

      <nz-row>

        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="associationEndDate"
          class="ppl-form-label">{{getAttributes('associationEndDate').label}}
          @if(getAttributes('associationEndDate').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('associationEndDate')?.tooltip || ''"
            nzTooltipPlacement="topRight">
            <nz-date-picker formControlName="associationEndDate" [id]="'associationEndDate'" style="height: 32px;"
              [nzFormat]="'MM/dd/yyyy'" [nzPlaceHolder]="getAttributes('associationEndDate').placeholder" [nzDisabledDate]="disabledEndDate"
              [ngClass]="{'error': validationErrors['associationEndDate'] }"></nz-date-picker>
          </div>
          @if (validationErrors['associationEndDate']) {
          <span class="pl-1 error-message">{{validationErrors['associationEndDate']}}</span>
          }
        </nz-form-control>
      </nz-row>
    </nz-form-item>
    }
  </form>
</div>
} @else if(mode==="read"){
@if(leadStatus !== approvedStatus) {
<div class="custom-row">
  <div class="mr-2">
    <ppl-button label="Add Case Manager" icon="fa fa-plus" [customClass]="'ghost-button'" [iconPosition]="'left'"
      (click)="add('MCO', 'Case Manager')" *authorize="[pageId, tabId, 'Viewer', 'section', 'edit']"></ppl-button>
  </div>

  <div class="ml-2">
    <ppl-button label="Add State Staff" icon="fa fa-plus" [customClass]="'ghost-button mr-2'" [iconPosition]="'left'"
      (click)="add('State', 'State Staff')" *authorize="[pageId, tabId, 'Viewer', 'section', 'edit']"></ppl-button>
  </div>

  <div class="ml-2">
    <ppl-button label="Add CDPAP Facilitator" icon="fa fa-plus" [customClass]="'ghost-button mr-2'" [iconPosition]="'left'"
      (click)="add('FI', 'FI Staff')" *authorize="[pageId, tabId, 'Viewer', 'section', 'edit']"></ppl-button>
  </div>
</div>
}

@if(copyOfGridData.length > 0){
<ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="isSectionEditable" [data]="copyOfGridData"
  [disableSelection]="true" [cols]="cols" (gridAction)="action($event)" [gridTitle]="false" [pagination]="false"
  [loadPage]="true" [actionButtons]="actionButtons">
</ppl-list-table>
}@else {
<nz-row>
  <p>No Data found</p>
</nz-row>
}



}@else if (mode==="read" && !copyOfGridData) {
<nz-row>
  <p>No Data found</p>
</nz-row>
}
<div class="custom-row" style="height: 0.3rem"></div>