import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ReferralDataService } from '../../../services/referral-data.service';
import { SectionsService } from '../../../services/sections.service';
import { ButtonComponent } from "../../../shared/components/button/button.component";
import { RoutingService } from '../../../shared/services/routing.service';
import { BaseComponent } from '../../../shared/utils/base.component';
import { performValidation } from '../../../shared/utils/validation.util';
import { NzAriaLabelDirective } from '../../../shared/directives/nz-aria-label.directive';

@Component({
  selector: 'ppl-referral-logic',
  standalone: true,
  imports: [
    NzCardModule,
    NzGridModule,
    NzIconModule,
    NzInputModule,
    NzButtonModule,
    NzSelectModule,
    FormsModule,
    NzFormModule,
    ReactiveFormsModule,
    CommonModule,
    ButtonComponent,
    NzAriaLabelDirective
],
  templateUrl: './referral-logic.component.html',
  styleUrl: './referral-logic.component.css'
})
export class ReferralLogicComponent extends BaseComponent implements OnInit {

  state: string | null = null;
  logicForm!: FormGroup;
  flow: string | null = '';
  buttonText = '';

  states: any[] = [];

  constructor(private fb: FormBuilder, private sectionsService: SectionsService,
    private router: Router, private referralDataService: ReferralDataService,
    private routingService: RoutingService
  ) {
    super();
  }

  ngOnInit(): void {
    this.logicForm = this.fb.group({
      state: ['']
    });
    const program = JSON.parse(localStorage.getItem("selected_program")!);
    this.secData = this.sectionsService.getStep1(program.program_code).pre_referral_step1;
    this.states = this.getLookup("STATE");
    this.flow = this.sectionsService.getFlow();
    this.buttonText = (this.flow == 'participants') ? 'Begin Consumer Interest Submission' :
      'Begin Personal Assistant Interest Submission'
  }

  onBegin() {
    this.referralDataService.setReferralFormData('state', this.logicForm.value);
    this.validationErrors = performValidation(this.logicForm.value, this.secData, 'Next');
    if(!this.validationErrors.state){
      this.router.navigate(['/newReferral'])
    }
  }

  returnClicked(): void {
    this.routingService.navigateBack();
  }
}
