<div class="custom-row">

  @if(mode==="edit"){
    <ppl-button 
    label="Cancel" 
    icon="fa fa-ban" 
    [customClass]="'alt-button'" 
    [iconPosition]="'left'"
    (click)="cancelEdit()"
    ></ppl-button>    

    <ppl-button 
    label="Save" 
    icon="fa fa-floppy-o" 
    [customClass]="'alt-button'" 
    [iconPosition]="'left'"
    (click)="save()"
    ></ppl-button>

  <!-- <span (click)="cancelEdit()" nz-icon nzType="close-circle" nzTheme="outline"></span>
  <span nz-icon nzType="check-circle" (click)="save()" nzTheme="outline"></span> -->
  }
  @if(mode === "read" && leadStatus !== approvedStatus){
    <ppl-button 
    label="Edit" 
    icon="fa fa-pencil" 
    [customClass]="'ghost-button'" 
    [iconPosition]="'left'"
    (click)="editPersonalInfo()"
    *authorize="[pageId, tabId, 'Demographics', 'section', 'edit']"
    ></ppl-button>    
  <!-- <span (click)="editPersonalInfo()" nz-icon nzType="edit" nzTheme="outline"
    *authorize="[pageId, tabId, 'Demographics', 'section', 'edit']"></span> -->
  }

</div>
@if(mode==="edit"){
<div class="ppl-mt20">
  <form nz-form nz-row [formGroup]="infoForm" class="ppl-p0">
    @if(isAttrAvailable('businessName')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label nzFor="businessName" [nzSm]="8" [nzXs]="24" class="ppl-form-label">{{ getAttributes("businessName").label }}
            @if(getAttributes('businessName').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control class="ppl-form-control">
            <input nz-tooltip nzTooltipTitle="{{ getAttributes('businessName').tooltip }}" nzTooltipPlacement="topRight"
              nz-input formControlName="businessName" nzId="businessName"
              [authorizeField]="[pageId, tabId, 'Demographics', 'businessName']" placeholder="{{ getAttributes('businessName').placeholder }}"
              [ngClass]="{'error': validationErrors['businessName'] }" />
            @if (validationErrors['businessName']) {
            <span class="pl-1 error-message">{{validationErrors['businessName']}}</span>
            }
  
          </nz-form-control>
        </nz-row>
  
      </nz-form-item>
      }
    @if(isAttrAvailable('fname')){
    <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
      <nz-row>
        <nz-form-label nzFor="fname" [nzSm]="8" [nzXs]="24" class="ppl-form-label">{{ getAttributes("fname").label }}
          @if(getAttributes('fname').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <input nz-tooltip nzTooltipTitle="{{ getAttributes('fname').tooltip }}" nzTooltipPlacement="topRight"
            nz-input formControlName="fname" nzId="fname"
            [authorizeField]="[pageId, tabId, 'Demographics', 'fname']" placeholder="{{ getAttributes('fname').placeholder }}"
            [ngClass]="{'error': validationErrors['fname'] }" />
          @if (validationErrors['fname']) {
          <span class="pl-1 error-message">{{validationErrors['fname']}}</span>
          }

        </nz-form-control>
      </nz-row>

    </nz-form-item>
    } @if(isAttrAvailable('mname')){
    <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="mname" class="ppl-form-label">{{ getAttributes("mname").label }}
          @if(getAttributes('mname').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <input nz-tooltip nzTooltipTitle="{{ getAttributes('mname').tooltip }}" nzTooltipPlacement="topRight"
            nz-input formControlName="mname" nzId="mname"
            [authorizeField]="[pageId, tabId, 'Demographics', 'mname']" placeholder="{{ getAttributes('mname').placeholder }}"
            [ngClass]="{'error': validationErrors['mname'] }" />
          @if (validationErrors['mname']) {
          <span class="pl-1 error-message">{{validationErrors['mname']}}</span>
          }
        </nz-form-control>
      </nz-row>

    </nz-form-item>
    } @if(isAttrAvailable('lname')){
    <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="lname" class="ppl-form-label">{{ getAttributes("lname").label }}
          @if(getAttributes('lname').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <input nz-tooltip nzTooltipTitle="{{ getAttributes('lname').tooltip }}" nzTooltipPlacement="topRight"
            nz-input formControlName="lname" nzId="lname"
            [authorizeField]="[pageId, tabId, 'Demographics', 'lname']" placeholder="{{ getAttributes('lname').placeholder }}"
            [ngClass]="{'error': validationErrors['lname'] }" />
          @if (validationErrors['lname']) {
          <span class="pl-1 error-message">{{validationErrors['lname']}}</span>
          }
        </nz-form-control>
      </nz-row>
    </nz-form-item>} @if(isAttrAvailable('maiden_name')){
    <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24" class="ppl-form-label" nzFor="maiden_name">{{
          getAttributes("maiden_name").label }}
          @if(getAttributes('maiden_name').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <input nz-tooltip nzTooltipTitle="{{ getAttributes('maiden_name').tooltip }}" nzTooltipPlacement="topRight"
            nz-input formControlName="maiden_name" nzId="maiden_name"
            [authorizeField]="[pageId, tabId, 'Demographics', 'maiden_name']"
            placeholder="{{ getAttributes('maiden_name').placeholder }}"
            [ngClass]="{'error': validationErrors['maiden_name'] }" />
          @if (validationErrors['maiden_name']) {
          <span class="pl-1 error-message">{{validationErrors['maiden_name']}}</span>
          }
        </nz-form-control>
      </nz-row>

    </nz-form-item>
    } @if(isAttrAvailable('dob')){
    <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="dob" class="ppl-form-label">{{ getAttributes("dob").label }}
          @if(getAttributes('dob').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control nzSpan="24" class="ppl-form-control">
          <nz-date-picker nzSpan="24" formControlName="dob" nzId="dob" nz-tooltip
            [authorizeField]="[pageId, tabId, 'Demographics', 'dob']" nzTooltipTitle="{{ getAttributes('dob').tooltip }}"
            nzTooltipPlacement="topRight" [nzPlaceHolder]="getAttributes('dob').placeholder"
            [ngClass]="{'error': validationErrors['dob'] }" [nzFormat]="'MM/dd/yyyy'"></nz-date-picker>
          @if (validationErrors['dob']) {
          <span class="pl-1 error-message">{{validationErrors['dob']}}</span>
          }
        </nz-form-control>
      </nz-row>
    </nz-form-item>} @if(isAttrAvailable('gender')){
    <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="gender" [authorizeField]="[pageId, tabId, 'Demographics', 'gender']"
          class="ppl-form-label">{{ getAttributes("gender").label }}
          @if(getAttributes('gender').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control" nzLabelAlign="left">
          <!-- <nz-radio-group
          nz-tooltip
          nzTooltipTitle="{{ getAttributes('gender').tooltip }}"
          nzTooltipPlacement="topRight"
          formControlName="gender"
          class="ppl-radio-group"
          nzId="gender"
        >

        @for(opt of genderList; track $index){
          <label nz-radio-button [nzValue]="opt.lkpValueCode">{{opt.lkpValueName}}</label>
          }
        </nz-radio-group> -->
          <nz-select formControlName="gender" class="ppl-select" nzId="gender" nzShowSearch nzAllowClear
            [ngClass]="{'error': validationErrors['gender'] }">
            @for(opt of genderList; track $index){
            <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
            }
          </nz-select>
        </nz-form-control>
      </nz-row>
    </nz-form-item>}
    @if(isAttrAvailable('state')){
    <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="state" class="ppl-form-label"
          [authorizeField]="[pageId, tabId, 'Demographics', 'state']">{{ getAttributes("state").label }}
          @if(getAttributes('state').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('state').tooltip}}">
          <nz-select formControlName="state" class="ppl-select" nzId="state" nzShowSearch nzAllowClear
            [ngClass]="{'error': validationErrors['state'] }">
            @for(opt of statesList; track $index){
            <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
            }
          </nz-select>
          @if (validationErrors['state']) {
          <span class="pl-1 error-message">{{validationErrors['state']}}</span>
          }
        </nz-form-control>
        
      </nz-row>

    </nz-form-item>
    }
    @if(isAttrAvailable('relationToParticipant')){
      <!-- <div nz-col [nzSm]="6" [nzXs]="24"> -->
        <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
          <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24"  zFor="relationToParticipant"
            class="ppl-form-label">{{getAttributes('relationToParticipant').label}}
            @if(getAttributes('relationToParticipant').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control  class="ppl-form-control" nz-tooltip
            nzTooltipTitle="{{getAttributes('relationToParticipant').tooltip}}" nzLabelAlign="left">
            <nz-select formControlName="relationToParticipant" class="ppl-select"
              [id]="'relationToParticipant' + sectionName" nzPlaceHolder="Select relation to Consumer"
              [ngClass]="{'error': validationErrors['relationToParticipant'] }" nzShowSearch nzAllowClear>
              @for(relation of relationshipOptions; track $index){
              <nz-option [nzLabel]="relation.lkpValueName" [nzValue]="relation.lkpValueCode"></nz-option>
              }
            </nz-select>
            @if (validationErrors['relationToParticipant']) {
            <span class="pl-1 error-message">{{validationErrors['relationToParticipant']}}</span>
            }
          </nz-form-control>
        </nz-row>
        </nz-form-item>
      <!-- </div> -->
      }

      @if(isAttrAvailable('nonProfitStatus')){
        <nz-form-label [nzSpan]="24" nzFor="nonProfitStatus" class="ppl-form-label"
          style="margin:0 0 0 16px;">{{getAttributes('nonProfitStatus').label}}
          @if(getAttributes('nonProfitStatus').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
          <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="taxExempt"
                class="ppl-form-label">{{getAttributes('taxExempt').label}}
                @if(getAttributes('taxExempt').mandatory === 'Y'){
                <span>*</span>
                }</nz-form-label>
              <nz-form-control class="ppl-form-control">

                <nz-select [nzMaxTagCount]="4" nzMode='default' formControlName="taxExempt" class="ppl-select"
            nzPlaceHolder="{{getAttributes('taxExempt').placeholder}}"
                [id]="'taxExempt' + sectionName"
                [ngClass]="{'error': validationErrors['taxExempt'] }" nzShowArrow>
                @for(opt of taxExemptOptions; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
              
                @if (validationErrors['taxExempt']) {
                <span class="pl-1 error-message">{{validationErrors['taxExempt']}}</span>
                }
              </nz-form-control>
            </nz-row>
            </nz-form-item>
      
        
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
              <nz-row>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="nprftDocumentationReceived"
                class="ppl-form-label">{{getAttributes('nprftDocumentationReceived').label}}
                @if(getAttributes('nprftDocumentationReceived').mandatory === 'Y'){
                <span>*</span>
                }</nz-form-label>
              <nz-form-control class="ppl-form-control">
                <nz-select [nzMaxTagCount]="4" nzMode='default' [ngClass]="{'error': validationErrors['nprftDocumentationReceived'] }"
                  nzPlaceHolder="{{getAttributes('nprftDocumentationReceived').placeholder}}" nzId="nprftDocumentationReceived"
                  formControlName="nprftDocumentationReceived" style="width:100%; margin-bottom: 16px" nzShowArrow>
                  <nz-option [nzValue]="'yes'" [nzLabel]="'Yes'"></nz-option>
                  <nz-option [nzValue]="'no'" [nzLabel]="'No'"></nz-option>
                </nz-select>
                @if (validationErrors['nprftDocumentationReceived']) {
                <span class="pl-1 error-message">{{validationErrors['nprftDocumentationReceived']}}</span>
                }
              </nz-form-control>
            </nz-row>
            </nz-form-item>
        }
      @if(isAttrAvailable('isShowOnDirectory')){
        <nz-form-item nz-col nzSpan="16" class="ppl-form-item nz-padding-left">
          <nz-row>
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="isShowOnDirectory" [authorizeField]="[pageId, tabId, 'Demographics', 'isShowOnDirectory']"
            class="ppl-form-label">{{getAttributes('isShowOnDirectory').label}}
            @if(getAttributes('isShowOnDirectory').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control  class="ppl-form-control"  [nzSm]="6" [nzXs]="24" nz-tooltip
          nzTooltipTitle="{{getAttributes('isShowOnDirectory').tooltip}}" nzLabelAlign="left">

            <nz-select formControlName="isShowOnDirectory" class="ppl-select" [id]="'isShowOnDirectory'  + sectionName" nzShowArrow
                [ngClass]="{'error': validationErrors['isShowOnDirectory'] }">
                @for(opt of radioOptions; track $index){
                <nz-option [nzLabel]="opt.label" [nzValue]="opt.value"></nz-option>
                }
              </nz-select>
              <!-- <nz-radio-group [ngClass]="{'error': validationErrors['isShowOnDirectory'] }" nz-tooltip [nzTooltipTitle]="getAttributes('isShowOnDirectory')?.tooltip || ''"
              nzTooltipPlacement="topRight" formControlName="isShowOnDirectory" class="ppl-radio-group" nzId="isShowOnDirectory"  nzLabelAlign="left">
                @for(opt of radioOptions; track $index){
                  <label nz-radio-button [nzValue]="opt.value">{{opt.label}}</label>
                }
              </nz-radio-group> -->
            @if (validationErrors['isShowOnDirectory']) {
            <span class="pl-1 error-message">{{validationErrors['isShowOnDirectory']}}</span>
            }
          </nz-form-control>
          </nz-row>
          
        </nz-form-item>
        }

        @if(isAttrAvailable('isProvidingServices')){
          <nz-form-item nz-col nzSpan="16" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
            <nz-row>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="isProvidingServices" [authorizeField]="[pageId, tabId, 'Demographics', 'isProvidingServices']"
              class="ppl-form-label">{{getAttributes('isProvidingServices').label}}
              @if(getAttributes('isProvidingServices').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-form-control class="ppl-form-control"  [nzSm]="6" [nzXs]="24" nz-tooltip
            nzTooltipTitle="{{getAttributes('isProvidingServices').tooltip}}" nzLabelAlign="left">

              <nz-select formControlName="isProvidingServices" class="ppl-select" [id]="'isProvidingServices'  + sectionName" nzShowArrow
                [ngClass]="{'error': validationErrors['isProvidingServices'] }">
                @for(opt of radioOptions; track $index){
                <nz-option [nzLabel]="opt.label" [nzValue]="opt.value"></nz-option>
                }
              </nz-select>
                <!-- <nz-radio-group [ngClass]="{'error': validationErrors['isProvidingServices'] }" nz-tooltip [nzTooltipTitle]="getAttributes('isProvidingServices')?.tooltip || ''"
                nzTooltipPlacement="topRight" formControlName="isProvidingServices" class="ppl-radio-group" nzId="isProvidingServices"  nzLabelAlign="left">
                  @for(opt of radioOptions; track $index){
                    <label nz-radio-button [nzValue]="opt.value">{{opt.label}}</label>
                  }
                </nz-radio-group> -->
              @if (validationErrors['isProvidingServices']) {
              <span class="pl-1 error-message">{{validationErrors['isProvidingServices']}}</span>
              }
            </nz-form-control>
            </nz-row>
            
          </nz-form-item>
          }

          @if(isAttrAvailable('yearsInState')){
            <nz-form-item nz-col nzSpan="16" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
              <nz-row>
                <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="yearsInState" [authorizeField]="[pageId, tabId, 'Demographics', 'yearsInState']"
                class="ppl-form-label">{{getAttributes('yearsInState').label}}
                @if(getAttributes('yearsInState').mandatory === 'Y'){
                <span>*</span>
                }
              </nz-form-label>
              <nz-form-control class="ppl-form-control"  [nzSm]="6" [nzXs]="24" nz-tooltip
              nzTooltipTitle="{{getAttributes('yearsInState').tooltip}}" nzLabelAlign="left">
              <input nz-tooltip nzTooltipTitle="{{ getAttributes('yearsInState').tooltip }}" nzTooltipPlacement="topRight"
              nz-input formControlName="yearsInState" nzId="yearsInState"
              [authorizeField]="[pageId, tabId, 'Demographics', 'yearsInState']"
              placeholder="{{ getAttributes('yearsInState').placeholder }}"
              [ngClass]="{'error': validationErrors['yearsInState'] }" />
                @if (validationErrors['yearsInState']) {
                <span class="pl-1 error-message">{{validationErrors['yearsInState']}}</span>
                }
              </nz-form-control>
              </nz-row>
              
            </nz-form-item>
            }
    @if(isAttrAvailable('additionalDemographics')){
      
      <nz-divider></nz-divider>
        <nz-form-label [nzSpan]="24" nzFor="additionalDemographics" class="ppl-form-label"
          style="margin:8px 0 24px 0;">{{getAttributes('additionalDemographics').label}}
          @if(getAttributes('additionalDemographics').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
     
          @if(isAttrAvailable('race')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
          <nz-row> 
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="race" class="ppl-form-label">{{getAttributes('race').label}}
                @if(getAttributes('race').mandatory === 'Y'){
                <span>*</span>
                }
              </nz-form-label>
              <nz-form-control class="ppl-form-control" nz-tooltip
                nzTooltipTitle="{{getAttributes('race').tooltip}}" nzTooltipPlacement="topRight">

                <nz-select formControlName="race" class="ppl-select" [id]="'race'  + sectionName" nzShowArrow
            [ngClass]="{'error': validationErrors['race'] }">
            @for(opt of raceOptions; track $index){
            <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
            }
          </nz-select>

                
                @if (validationErrors['race']) {
                <span class="pl-1 error-message">{{validationErrors['race']}}</span>
                }
              </nz-form-control>
            </nz-row>
            </nz-form-item>
          }
          @if(isAttrAvailable('height')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
              <nz-row> 
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="height" class="ppl-form-label">{{getAttributes('height').label}}
                @if(getAttributes('height').mandatory === 'Y'){
                <span>*</span>
                }
              </nz-form-label>
              <nz-form-control class="ppl-form-control">
                <div nz-tooltip [nzTooltipTitle]="getAttributes('height')?.tooltip || ''" nzTooltipPlacement="topRight"
                  class="height-input-container ppl-form-control">
                  <nz-row [nzGutter]="16">
                    <nz-col [nzSm]="12" [nzXs]="24">
                      <input nz-input formControlName="heightFeet" [id]="'heightFeet'  + sectionName" placeholder="Feet"
                        [ngClass]="{'error': validationErrors['heightFeet']}" />
                        @if (validationErrors['heightFeet']) {
                          <span class="pl-1 error-message">{{validationErrors['heightFeet']}}</span>
                          }
                    </nz-col>
                    <nz-col [nzSm]="12" [nzXs]="24">
                      <input nz-input formControlName="heightInches" [id]="'heightInches'  + sectionName" placeholder="Inches"
                        [ngClass]="{'error': validationErrors['heightInches']}" />
                        @if  (validationErrors['heightInches']) {
                        <span class="pl-1 error-message">{{validationErrors['heightInches']}}</span>
                        }
                      </nz-col>
                  </nz-row>
                </div>
              </nz-form-control>
            </nz-row>
            </nz-form-item>
         
          }
          @if(isAttrAvailable('weight')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
              <nz-row>
                <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="weight" class="ppl-form-label">
                  {{getAttributes('weight').label}}
                  @if(getAttributes('weight').mandatory === 'Y'){
                  <span>*</span>
                  }
                </nz-form-label>
                <nz-form-control  class="ppl-form-control">
                  <div nz-tooltip [nzTooltipTitle]="getAttributes('weight')?.tooltip || ''" nzTooltipPlacement="topRight"
                    class="weight-input-container ppl-form-control">
                    <input nz-input formControlName="weight" [id]="'weight'  + sectionName"
                      placeholder="{{getAttributes('weight').placeholder}}" [ngClass]="{'error': validationErrors['weight']}" />
                    <!-- <span class="unit-label">Pounds</span> -->
                  </div>
                  @if (validationErrors['weight']) {
                    <span class="pl-1 error-message">{{validationErrors['weight']}}</span>
                    }
                </nz-form-control>
              </nz-row>
              </nz-form-item>
            }
            @if(isAttrAvailable('eyeColor')){
              <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                <nz-form-label  [nzSm]="8" [nzXs]="24" nzFor="eyeColor" class="ppl-form-label">{{getAttributes('eyeColor').label}}
                  @if(getAttributes('eyeColor').mandatory === 'Y'){
                  <span>*</span>
                  }
                </nz-form-label>
                <nz-form-control class="ppl-form-control" nz-tooltip
                  nzTooltipTitle="{{getAttributes('eyeColor').tooltip}}" nzTooltipPlacement="topRight">

                  <nz-select formControlName="eyeColor" class="ppl-select" [id]="'eyeColor'  + sectionName" nzShowArrow
            [ngClass]="{'error': validationErrors['eyeColor'] }">
            @for(opt of eyeColors; track $index){
            <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
            }
          </nz-select>

                  @if (validationErrors['eyeColor']) {
                  <span class="pl-1 error-message">{{validationErrors['eyeColor']}}</span>
                  }
                </nz-form-control>
              </nz-row>
              </nz-form-item>
            }
          @if(isAttrAvailable('hairColor')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
              <nz-row>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="hairColor" class="ppl-form-label">{{getAttributes('hairColor').label}}
                @if(getAttributes('hairColor').mandatory === 'Y'){
                <span>*</span>
                }
              </nz-form-label>
              <nz-form-control class="ppl-form-control" nz-tooltip
                nzTooltipTitle="{{getAttributes('hairColor').tooltip}}" nzTooltipPlacement="topRight">
                <nz-select formControlName="hairColor" class="ppl-select" [id]="'hairColor'  + sectionName" nzShowArrow
                  [ngClass]="{'error': validationErrors['hairColor'] }">
                  @for(color of hairColors; track $index){
                  <nz-option [nzLabel]="color.lkpValueName" [nzValue]="color.lkpValueCode"></nz-option>
                  }
                </nz-select>
                @if (validationErrors['hairColor']) {
                <span class="pl-1 error-message">{{validationErrors['hairColor']}}</span>
                }
              </nz-form-control>
            </nz-row>
            </nz-form-item>
          
          }
          @if(isAttrAvailable('birthPlace')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
              <nz-row>

              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="birthPlace" class="ppl-form-label">{{getAttributes('birthPlace').label}}
                  @if(getAttributes('birthPlace').mandatory === 'Y'){
                  <span>*</span>
                  }
                </nz-form-label>
                <nz-form-control  class="ppl-form-control">
                  <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
                    <input nz-input formControlName="birthCity" [id]="'birthCity'  + sectionName" placeholder="City" />
                  </div>
                </nz-form-control>
                <nz-form-control  class="ppl-form-control" style="margin: 0px 4px;">
                  <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
                    <input nz-input formControlName="birthState" [id]="'state'  + sectionName" placeholder="State" />
                  </div>
                </nz-form-control>
                <nz-form-control  class="ppl-form-control">
                  <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
                    <input nz-input formControlName="birthCountry" [id]="'country'  + sectionName" placeholder="Country" />
                  </div>
                </nz-form-control>
              </nz-row>
              </nz-form-item>
            
            }
      }
    
        <!-- @if(isAttrAvailable('mobile')){
  
          <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
            <nz-form-label  [nzSm]="8" [nzXs]="24" nzFor="mobile" class="ppl-form-label"
              [ngStyle]="{ 'margin-bottom':'0' }">{{getAttributes('mobile').label}}
              @if(getAttributes('mobile').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-form-control>
              <input nz-tooltip nzTooltipTitle="{{getAttributes('mobile').tooltip}}" nzTooltipPlacement="topRight"
                nz-input [id]="'mobile'" formControlName="mobile"
                [ngClass]="{'error': validationErrors['mobile'] }"
                placeholder="{{getAttributes('mobile').placeholder}}" />
              @if (validationErrors['mobile']) {
              <span class="pl-1 error-message">{{validationErrors['mobile']}}</span>
              }
            </nz-form-control>
          </nz-row>
          </nz-form-item>
        } -->
        @if(isAttrAvailable('registerPref')){
          <nz-form-item nz-col nzSpan="16" class="ppl-form-item nz-padding-left">
            <nz-row>
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="registerPref"
            class="ppl-form-label">{{getAttributes('registerPref').label}}
            @if(getAttributes('registerPref').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
         
            <nz-form-control class="ppl-form-control" [nzSm]="6" [nzXs]="24"  nz-tooltip nzTooltipTitle="{{getAttributes('registerPref').tooltip}}"
              nzTooltipPlacement="topRight">
              <nz-select formControlName="registerPref" class="ppl-select" [id]="'registerPref'  + sectionName" nzShowArrow
                [ngClass]="{'error': validationErrors['registerPref'] }">
                @for (opt of participantRegisterMode; track $index) {
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
              @if (validationErrors['registerPref']) {
              <span class="pl-1 error-message">{{validationErrors['registerPref']}}</span>
              }
            </nz-form-control>
          </nz-row>
        </nz-form-item>
        }
        
     
    @if(isAttrAvailable('waiver')){
      <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="waiver" class="ppl-form-label"
          [authorizeField]="[pageId, tabId, 'Demographics', 'waiver']">{{ getAttributes("waiver").label }}
          @if(getAttributes('waiver').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('waiver').tooltip}}"
          nzTooltipPlacement="topRight">
          <nz-select formControlName="waiver" class="ppl-select" nzId="waiver" nzPlaceHolder="Select Service Administration"
            [ngClass]="{'error': validationErrors['waiver'] }" nzShowSearch nzAllowClear>
            @for(opt of waiver; track $index){
              <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
            }
          </nz-select>
          @if (validationErrors['waiver']) {
          <span class="pl-1 error-message">{{validationErrors['waiver']}}</span>
          }
        </nz-form-control>
      </nz-row>
      </nz-form-item>
      }
      @if(isAttrAvailable('ldssOffice')){
        <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
          <nz-row>
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="ldssOffice" class="ppl-form-label"
            [authorizeField]="[pageId, tabId, 'Demographics', 'ldssOffice']">{{ getAttributes("ldssOffice").label }}
            @if(getAttributes('ldssOffice').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('ldssOffice').tooltip}}"
            nzTooltipPlacement="topRight">
            <nz-select formControlName="ldssOffice" class="ppl-select" nzId="ldssOffice" nzPlaceHolder="Select LDSS Office"
              [ngClass]="{'error': validationErrors['ldssOffice'] }" nzShowSearch nzAllowClear>
              @for(opt of ldssOfficeValues; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
              }
            </nz-select>
            @if (validationErrors['ldssOffice']) {
            <span class="pl-1 error-message">{{validationErrors['ldssOffice']}}</span>
            }
          </nz-form-control>
        </nz-row>
        </nz-form-item>
        }
      @if(isAttrAvailable('mcoOrgName')){
        <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
          <nz-row>
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="mcoOrgName" class="ppl-form-label"
            [authorizeField]="[pageId, tabId, 'Demographics', 'mcoOrgName']">{{ getAttributes("mcoOrgName").label }}
            @if(getAttributes('mcoOrgName').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('mcoOrgName').tooltip}}"
            nzTooltipPlacement="topRight">
            <nz-select formControlName="mcoOrgName" class="ppl-select" nzId="mcoOrgName" nzPlaceHolder="{{getAttributes('mcoOrgName').tooltip}}"
              [ngClass]="{'error': validationErrors['mcoOrgName'] }" nzShowSearch nzAllowClear>
              @for(opt of mcoEntries; track $index){
                <nz-option [nzLabel]="opt.orgName" [nzValue]="opt.orgName"></nz-option>
              }
            </nz-select>
            @if (validationErrors['mcoOrgName']) {
            <span class="pl-1 error-message">{{validationErrors['mcoOrgName']}}</span>
            }
          </nz-form-control>
        </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('currentFI')){
          <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
            <nz-row>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="currentFI" class="ppl-form-label"
              [authorizeField]="[pageId, tabId, 'Demographics', 'currentFI']">{{ getAttributes("currentFI").label }}
              @if(getAttributes('currentFI').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('currentFI').tooltip}}"
              nzTooltipPlacement="topRight">
              <nz-select formControlName="currentFI" class="ppl-select" nzId="currentFI" nzPlaceHolder="Select current FI"
                [ngClass]="{'error': validationErrors['currentFI'] }" nzShowSearch nzAllowClear>
                @for(opt of currentFIValues; track $index){
                  <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
              @if (validationErrors['currentFI']) {
              <span class="pl-1 error-message">{{validationErrors['currentFI']}}</span>
              }
            </nz-form-control>
          </nz-row>
          </nz-form-item>
        }
        @if(isAttrAvailable('fiOrgName')){
          <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
            <nz-row>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="fiOrgName" class="ppl-form-label"
              [authorizeField]="[pageId, tabId, 'Demographics', 'fiOrgName']">{{ getAttributes("fiOrgName").label }}
              @if(getAttributes('fiOrgName').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('fiOrgName').tooltip}}"
              nzTooltipPlacement="topRight">
              <nz-select formControlName="fiOrgName" class="ppl-select" nzId="fiOrgName" nzPlaceHolder="{{getAttributes('fiOrgName').tooltip}}"
                [ngClass]="{'error': validationErrors['fiOrgName'] }" nzShowSearch nzAllowClear>
                @for(opt of fiEntries; track $index){
                  <nz-option [nzLabel]="opt.orgName" [nzValue]="opt.orgName"></nz-option>
                }
              </nz-select>
              @if (validationErrors['fiOrgName']) {
              <span class="pl-1 error-message">{{validationErrors['fiOrgName']}}</span>
              }
            </nz-form-control>
          </nz-row>
          </nz-form-item>
        }
      @if(isAttrAvailable('eligiblityStartDate')){
        <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
          <nz-row>
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="eligiblityStartDate" class="ppl-form-label">{{ getAttributes("eligiblityStartDate").label }}
              @if(getAttributes('eligiblityStartDate').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control nzSpan="24" class="ppl-form-control">
              <nz-date-picker nzSpan="24" formControlName="eligiblityStartDate" nzId="eligiblityStartDate" nz-tooltip
                [authorizeField]="[pageId, tabId, 'Demographics', 'eligiblityStartDate']" nzTooltipTitle="{{ getAttributes('eligiblityStartDate').tooltip }}"
                nzTooltipPlacement="topRight" [nzPlaceHolder]="getAttributes('eligiblityStartDate').placeholder"
                [ngClass]="{'error': validationErrors['eligiblityStartDate'] }" [nzFormat]="'MM/dd/yyyy'"></nz-date-picker>
              @if (validationErrors['eligiblityStartDate']) {
              <span class="pl-1 error-message">{{validationErrors['eligiblityStartDate']}}</span>
              }
            </nz-form-control>
          </nz-row>
        </nz-form-item>}
      @if(isAttrAvailable('eligiblityEndDate')){
        <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
          <nz-row>
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="eligiblityEndDate" class="ppl-form-label">{{ getAttributes("eligiblityEndDate").label }}
              @if(getAttributes('eligiblityEndDate').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control nzSpan="24" class="ppl-form-control">
              <nz-date-picker nzSpan="24" formControlName="eligiblityEndDate" nzId="eligiblityEndDate" nz-tooltip
                [authorizeField]="[pageId, tabId, 'Demographics', 'eligiblityEndDate']" nzTooltipTitle="{{ getAttributes('eligiblityEndDate').tooltip }}"
                nzTooltipPlacement="topRight" [nzPlaceHolder]="getAttributes('eligiblityEndDate').placeholder"
                [ngClass]="{'error': validationErrors['eligiblityEndDate'] }" [nzFormat]="'MM/dd/yyyy'"></nz-date-picker>
              @if (validationErrors['eligiblityEndDate']) {
              <span class="pl-1 error-message">{{validationErrors['eligiblityStartDate']}}</span>
              }
            </nz-form-control>
          </nz-row>
        </nz-form-item>}
      
  </form>
</div>
} @else if(mode==="read" && savedData && secData){
<div nz-row>
  @if(isAttrAvailable('businessName')){
    <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'businessName']">
      <div nz-row>
        <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
          {{ getAttributes("businessName").label }}
        </div>
        <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData.businessName }}</div>
      </div>
    </div>
    }
  @if(isAttrAvailable('fname')){
  <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'fname']">
    <div nz-row>
      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
        {{ getAttributes("fname").label }}
      </div>
      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData.fname }}</div>
    </div>
  </div>
  } @if(isAttrAvailable('mname')){

  <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'mname']">
    <div nz-row>
      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
        {{ getAttributes("mname").label }}
      </div>
      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData.mname }}</div>
    </div>
  </div>

  } @if(isAttrAvailable('lname')){
  <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'lname']">
    <div nz-row>
      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
        {{ getAttributes("lname").label }}
      </div>
      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData.lname }}</div>
    </div>
  </div>
  } @if(isAttrAvailable('maiden_name')){

  <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'maiden_name']">
    <div nz-row>
      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
        {{ getAttributes("maiden_name").label }}
      </div>
      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData.maiden_name }}</div>
    </div>
  </div>

  } @if(isAttrAvailable('dob')){

  <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'dob']">
    <div nz-row>
      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
        {{ getAttributes("dob").label }}
      </div>
      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData.dob | date : "MM/dd/yyyy"}}</div>
    </div>
  </div>
  } @if(isAttrAvailable('gender')){

  <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'gender']">
    <div nz-row>
      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
        {{ getAttributes("gender").label }}
      </div>
      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData['gender'] ? getLookupValue(genderList,
        savedData['gender']) :
        savedData['gender']}}</div>
    </div>
  </div>

  }@if(isAttrAvailable('state')){

  <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'state']">
    <div nz-row>
      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
        {{ getAttributes("state").label }}
      </div>
      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData.state ? getLookupValue(statesList, savedData.state) :
        savedData.state}}</div>
    </div>
  </div>
  }
  @if(isAttrAvailable('relationToParticipant')){
      <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'relationToParticipant']">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("relationToParticipant").label }}
          </div>          
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData.relationToParticipant ? getLookupValue(relationshipOptions, savedData['relationToParticipant']) : savedData.relationToParticipant}}</div>
        </div>
      </div>
    }
  @if(isAttrAvailable('waiver')){

    <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'waiver']">
      <div nz-row>
        <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
          {{ getAttributes("waiver").label }}
        </div>
        <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData['waiver'] ? getLookupValue(waiver,
          savedData['waiver']) :
          savedData['waiver']}}</div>
      </div>
    </div>
  }
  @if(isAttrAvailable('ldssOffice')){

    <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'ldssOffice']">
      <div nz-row>
        <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
          {{ getAttributes("ldssOffice").label }}
        </div>
        <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData['ldssOffice'] ? getLookupValue(ldssOfficeValues,
          savedData['ldssOffice']) :
          savedData['ldssOffice']}}</div>
      </div>
    </div>
  }
  @if(isAttrAvailable('mcoOrgName')){

    <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'mcoOrgName']">
      <div nz-row>
        <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
          {{ getAttributes("mcoOrgName").label }}
        </div>
        <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData.mcoOrgName }}</div>
      </div>
    </div>
  
    }
    @if(isAttrAvailable('currentFI')){

      <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'currentFI']">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("currentFI").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData['currentFI'] ? getLookupValue(currentFIValues,
            savedData['currentFI']) :
            savedData['currentFI']}}</div>
        </div>
      </div>
    }
    @if(isAttrAvailable('fiOrgName')){
  
      <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'fiOrgName']">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("fiOrgName").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData.fiOrgName }}</div>
        </div>
      </div>
    
      }
  @if(isAttrAvailable('eligiblityStartDate')){

    <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'eligiblityStartDate']">
      <div nz-row>
        <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
          {{ getAttributes("eligiblityStartDate").label }}
        </div>
        <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData.eligiblityStartDate | date : "MM/dd/yyyy"}}</div>
      </div>
    </div>
    }
  @if(isAttrAvailable('eligiblityEndDate')){

    <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'eligiblityEndDate']">
      <div nz-row>
        <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
          {{ getAttributes("eligiblityEndDate").label }}
        </div>
        <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData.eligiblityEndDate | date : "MM/dd/yyyy"}}</div>
      </div>
    </div>
    }
</div>
@if(isAttrAvailable('nonProfitStatus')){
  <div nz-row>
    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
      {{ getAttributes("nonProfitStatus").label }}
    </div>
  </div>
  <div nz-row>
      <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'taxExempt']">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("taxExempt").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2"> {{ savedData.taxExempt ? getLookupValue(taxExemptOptions, savedData['taxExempt']) : savedData.taxExempt}}</div>
        </div>
      </div>
      <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'nprftDocumentationReceived']">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("nprftDocumentationReceived").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData.nprftDocumentationReceived }}</div>
        </div>
      </div>
    </div>
  }

@if(isAttrAvailable('isShowOnDirectory')){
  <div nz-col nzSpan="14" *authorizeFieldView="[pageId, tabId, 'Demographics', 'isShowOnDirectory']">
    <div nz-row>
      <div nz-col nzSpan="10" ngClass="font-weight-600 pt-2 pb-2">
        {{getAttributes('isShowOnDirectory').label}}
      </div>
      <div nz-col nzSpan="8" ngClass="pt-2 pb-2"> {{filledInfo.isShowOnDirectory === true ? 'Yes' : 'No'}}</div>
    </div>
  </div>
}

@if(isAttrAvailable('isProvidingServices')){
  <div nz-col nzSpan="14" *authorizeFieldView="[pageId, tabId, 'Demographics', 'isProvidingServices']">
    <div nz-row>
      <div nz-col nzSpan="10" ngClass="font-weight-600 pt-2 pb-2">
        {{getAttributes('isProvidingServices').label}}
      </div>
      <div nz-col nzSpan="8" ngClass="pt-2 pb-2"> {{filledInfo.isProvidingServices === true ? 'Yes' : 'No'}}</div>
    </div>
  </div>
}

@if(isAttrAvailable('registerPref')){
  <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'registerPref']">
    <div nz-row>
      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
        {{ getAttributes("registerPref").label }}
      </div>
      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData['registerPref'] ? getLookupValue(prefList,
        savedData['registerPref']) :
        savedData['registerPref']}}</div>
    </div>
  </div>
}
@if(isAttrAvailable('yearsInState')){
  <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'yearsInState']">
    <div nz-row>
      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
        {{ getAttributes("yearsInState").label }}
      </div>
      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{savedData['yearsInState']}}</div>
    </div>
  </div>
}

<div nz-row>
  @if(isAttrAvailable('race')){  
      <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'race']">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("race").label }}
          </div>          
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData.race ? getLookupValue(raceOptions, savedData['race']) : savedData.race}}</div>
        </div>
      </div>
    }
  @if(isAttrAvailable('height')){
      <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'height']">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("height").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{(savedData.heightFeet || savedData.heightInches)? savedData.heightFeet + "'" + savedData.heightInches +"''" :savedData.heightFeet}}</div>
        </div>
      </div>
    }
    @if(isAttrAvailable('weight')){
      <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'weight']">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("weight").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData.weight }}</div>
        </div>
      </div>

      }
      @if(isAttrAvailable('eyeColor')){
        <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'eyeColor']">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("eyeColor").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ getLookupValue(eyeColors, savedData['eyeColor']) || savedData.eyeColor}}</div>
          </div>
        </div>
      }
    @if(isAttrAvailable('hairColor')){

      <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'hairColor']">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("hairColor").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ getLookupValue(hairColors, savedData['hairColor']) || savedData.hairColor}}</div>
        </div>
      </div>
    }
    @if(isAttrAvailable('birthPlace')){
      <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'Demographics', 'birthPlace']">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("birthPlace").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData.birthCity}} {{(savedData.birthState? " , "+ savedData.birthState :savedData.birthState) }} {{(savedData.birthCountry?" , " +savedData.birthCountry: savedData.birthCountry)}}</div>
        </div>
      </div>
      }
    </div>
} @else{
<nz-row>
  <p>No Data found</p>
</nz-row>
}
<div class="custom-row" style="height: 0.8rem"></div>