import { NotificationService } from '../../../../shared/services/notification.service';
import { Component, OnInit } from '@angular/core';
import { LeadDetailParticipantInfoComponent } from "../lead-detail-participant-info/lead-detail-participant-info.component";
import { LeadDetailCommMethodsComponent } from "../lead-detail-comm-methods/lead-detail-comm-methods.component";
import { LeadDetailAddressInfoComponent } from "../lead-detail-address-info/lead-detail-address-info.component";
import { LeadDetailCommunicationInfoComponent } from "../lead-detail-communication-info/lead-detail-communication-info.component";
import { BaseComponent } from '../../../../shared/utils/base.component';
import { SectionsService } from '../../../../services/sections.service';
import { CommonModule } from '@angular/common';
import { LeadDetailsService } from '../../../../services/lead-details.service';
import { STATIC_DATA, STATIC_JSON_DATA_PATH } from '../../../../shared/constants/static-data.constants';
import { LeadDetailIdentifiersComponent } from "../lead-detail-identifiers/lead-detail-identifiers.component";
import { LeadApplicationDetailsComponent } from "../lead-application-details/lead-application-details.component";
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { LoggerService } from '../../../../shared/services/logger.service';
import { AccordionComponent } from "../../../../shared/components/accordion/accordion.component";
import { AuthorizeDirective } from '../../../../shared/authorization/authorize.directive.';
import { MEMBER_VIEW_PAGE_IDS, PAGE_CONSTANTS } from '../../../../shared/constants/page.constants';
import { LeadDetailProfessionalLicensesComponent } from '../lead-detail-professional-licenses/lead-detail-professional-licenses.component';
import { LeadDetailServicesComponent } from '../lead-detail-services/lead-detail-services.component';
import { LeadDetailEligibilityDatesComponent } from '../lead-detail-eligibility-dates/lead-detail-eligibility-dates.component';
import { LeadDetailViewerComponent } from "../lead-detail-viewer/lead-detail-viewer.component";


@Component({
  selector: 'ppl-lead-information',
  standalone: true,
  imports: [NgZorroModule, LeadDetailParticipantInfoComponent,
    CommonModule, LeadDetailCommMethodsComponent, LeadDetailAddressInfoComponent,
    LeadDetailCommunicationInfoComponent, LeadDetailIdentifiersComponent, LeadDetailProfessionalLicensesComponent,
    LeadApplicationDetailsComponent, AuthorizeDirective, AccordionComponent, LeadDetailServicesComponent, LeadDetailEligibilityDatesComponent, LeadDetailViewerComponent],
  templateUrl: './lead-information.component.html',
  styleUrl: './lead-information.component.css'
})
export class LeadInformationComponent extends BaseComponent implements OnInit {
  filledInfo: any;
  steps: any;
  entry  = 'base';
  sectionDataPath: string = STATIC_JSON_DATA_PATH.LEAD_DETAILS_SECTION_DATA;
  providerEnrollmentSectionDataPath: string = STATIC_JSON_DATA_PATH.LEAD_DETAILS_PROVIDER_ENROLLMENT_SECTION_DATA;
  source!: string;
  pageId = '';
  tabId = 'MEMBER_INFORMATION';
  currentRoute = '';
  addressInfo: any;
  program: any;
  selectedLead: any;
  contactMethodsModifiedDate: any;
  contactDetailsInfo: any;
  identifiersModifiedDate = '';
  professionalLicense: any;
  professionalLicensePath: string = STATIC_DATA.LEAD_PROF_LICENCES;
  servicesRendered : string = STATIC_DATA.LEAD_SERVICES_RENDERED;
  status = '';
  title: any;
  enrollmentType: any;
  isParticipantFlow = true;

  constructor (
    private sectionsService: SectionsService, 
    private leadDetailsService: LeadDetailsService, 
    private notificationService: NotificationService, 
    private logger: LoggerService,
  ) {
    super();
  }

  ngOnInit() {
    this.pageId = MEMBER_VIEW_PAGE_IDS[localStorage.getItem(STATIC_DATA.URL_FIRST_SEGMENT)!];
    this.source = 'ppl-lead-information';
    this.entry = localStorage.getItem("lead_entry")!;
    this.title = this.entry == 'referral' ? 'Basic Information' : 'Demographics';
    this.isParticipantFlow = localStorage.getItem('lead_entry') == 'participantEnrollment' || localStorage.getItem('lead_submission_flow') == 'participantEnrollment';
    this.program = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_PROGRAM)!);

    try{
      if(this.entry === STATIC_DATA.PROVIDER_ENROLLMENT){
        this.enrollmentType = this.sectionsService.getEnrollmentType();
      }
    }catch(e){
      this.logger.info(this.source, e)
    }
    
    

    this.getSectionData();
    
    try {
      this.selectedLead = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!);
    } catch {
      this.notificationService.alert("error", "Error Fetching Data", "Error getting selected lead data");
    }
    //const selectedLead: any = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!);
    
    this.getLeadData(false);
    

    this.leadDetailsService.getUpdatedLeadData().subscribe((data) => {
      this.filledInfo =
        data?.responsedata?.infoData;
        this.leadDetailsService.setLeadStatus(this.filledInfo?.applicationStatus);
      });
  }

  async getSectionData(){
    try{
      if(this.entry){
        if(this.entry == STATIC_DATA.PROVIDER_ENROLLMENT){
          this.steps = await this.sectionsService.getJsonConfigurationData(this.providerEnrollmentSectionDataPath, this.entry, this.enrollmentType);
        } else{
          this.steps = await this.sectionsService.getJsonConfigurationData(this.sectionDataPath, this.entry, this.enrollmentType);
        }
      
      /* console.log(this.steps); */
      this.secData = this.steps[STATIC_DATA.PARTICIPANT_INFO];
      /* console.log(this.secData);
      this.logger.debug(this.source, "Logger test successful", this.steps); */
      }
    }
    catch(e){
      this.logger.debug("Error fetching lead details data", e);
    }
  }

  updatedLeadData = (event: boolean) => {

    this.logger.debug("Updated", event);
    if (event) {
      this.getLeadData(event);
    }
  };


  getLeadData(isAfterUpdate: boolean){
    console.log('i m called', this.entry, this.program.program_code, this.selectedLead)
    this.leadDetailsService.getLeadDetails(this.entry, this.program.program_code, this.selectedLead, isAfterUpdate).subscribe(
      (data:any)=>{

        if(this.entry === STATIC_DATA.PROVIDER_ENROLLMENT){
          this.filledInfo = data?.responsedata?.basicInfo;
          this.professionalLicense = data?.responsedata?.serviceDetails?.profLicenses;
        }else{
          this.filledInfo = data?.responsedata?.infoData;
          this.professionalLicense = data?.responsedata?.profLicenses;
        }

        /* this.filledInfo = data?.responsedata?.infoData; */
        this.status = this.filledInfo?.applicationStatus;
        this.leadDetailsService.setLeadStatus(this.status);
        this.addressInfo = data?.responsedata?.communicationData?.permanentAddress;
        // const contactMethods = data?.responsedata?.communicationData?.contactMethods;
        this.contactDetailsInfo = data?.responsedata?.contactDetailsData;
        
        
        
        
        /*this.contactMethodsModifiedDate = this.getLastModifiedDate(contactMethods);

         const identifiers = data?.responsedata?.infoData?.identifiers;
        this.identifiersModifiedDate = this.getLastModifiedDate(identifiers); */

      }
    );
  }

  getLastModifiedDate(data : any[] ): string {
    if(data?.length){
      return data?.reduce((latest:any, current:any) => {
        const currentDate = new Date(current.modifiedDate);
        const latestDate = new Date(latest.modifiedDate);
        
        return currentDate > latestDate ? current : latest;
    }).modifiedDate;
    }else{
      return '';
    }
    
  }

}
