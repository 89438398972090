/* src/app/shared/components/message/message.component.css */
.ppl-main-hlfwidth {
  width: 40%;
  margin: auto;
}
.ppl-main-halfwidth {
  width: 60%;
}
.btn-color {
  background-color: #1890ff;
  color: white;
  padding: 4px;
  border-radius: 4px;
  margin-right: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid lightgray;
}
.ant-card-body {
  padding: 36px !important;
}
.option-container {
  padding: 28px;
}
.options {
  font-size: 1.143rem;
  cursor: pointer;
  width: fit-content;
}
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
.main-container {
  margin-top: 50px;
}
/*# sourceMappingURL=message.component-4ILKHWV4.css.map */
