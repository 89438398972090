
export interface ColumnType {
  field: string,
  header: string,
  isDisplay?: string,
  header_label?: string;
}

export interface FilterType {
  label: string,
  value: string
}

export interface FilterRuleType {
  column: string,
  filtertype: string,
  value: any,
  isApplied: boolean;
}

export interface ListConfigData {
  columns: RefrralHeaderType[],
  buttons: ButtonConfig[],
  pageName: string;
  orderBy: string[];
  orderByColumn: string[];
}

export interface ButtonConfig {
  button_id: string,
  button_label: string,
  buttonAction: string,
  className: string
}


export interface RefrralHeaderType {
  header_id: string;
  header_label: string;
  jsonpath: string;
  isDisplayedAtHeader: string;
  isSearchable: string;
  header?: string;
}

export interface ListGridRequestPayload {
  pageName: string;
  programCode: string;
  condition: string;
  recordsPerPage: number;
  startingPageNumber: number;
  searchParam: SearchParams[];
  orderBy: any;
  orderByColumn: any
}

export interface UploadRequestPayload{
  file: any;
  json:{}
}


export interface SearchParams {
  jsonPath: string;
  value: string;
  operator: string;
}

export interface TableRowActionsModel {
  edit: boolean;
  delete: boolean;
  view: boolean;
  download: boolean;
  verify: boolean;
  add: boolean;
  refresh: boolean;
  mail: boolean;
}

export enum TableRowAction {
  Edit = 'edit',
  Delete = 'delete',
  View = 'view',
  Download = 'download',
  Verify = 'verify',
  Add = 'add',
  Refresh = 'refresh',
  Mail = 'mail'
}