import { Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AccordionComponent } from "../../../../shared/components/accordion/accordion.component";
import { ListTableComponent } from "../../../../shared/components/list-table/list-table.component";
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import { DialogConfig, DialogService } from '../../../../shared/services/dialog.service';
import { AuthorizeDirective } from '../../../../shared/authorization/authorize.directive.';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { DateTimeFormatterService } from '../../../../shared/utils/date-time-formatter.service';

@Component({
  selector: 'ppl-timesheets-add-entries',
  standalone: true,
  imports: [CommonModule, NgZorroModule, ButtonComponent, ReactiveFormsModule, NzDatePickerModule, FormsModule, TableModule, NzDatePickerModule, NzTimePickerModule ],
  templateUrl: './timesheets-add-entries.component.html',
  styleUrl: './timesheets-add-entries.component.css'
})
export class TimesheetNewEntriesComponent extends BaseComponent implements OnInit {

  pageId: string = '';
  timesheetForm!: FormGroup;

  consumers = [
    {
      id: "PRC-NY-10000802",
      name: "Nikhil Malhotra"
    },
    {
      id: "PRC-NY-10000801",
      name: "Henderson Branch"
    },
    {
      id: "PRC-NY-10000797",
      name: "Renata Laugherane"
    },
    {
      id: "	PRC-NY-10000796",
      name: "Torry Hunton"
    },
    {
      id: "PRC-NY-10000804",
      name: "Gwenni Malhotra"
    }
  ]

  services = [
    {
      id: "1",
      name: "Service 1"
    },
    {
      id: "2",
      name: "Service 2"
    },
    {
      id: "3",
      name: "Service 3"
    },
    {
      id: "4",
      name: "Service 4"
    },
    {
      id: "5",
      name: "Service 5"
    }
  ]

  cols = [
    {
      field: 'date',
      header: 'Date',
      jsonpath: 'date',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'hours',
      header: 'Hours',
      jsonpath: 'hours',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    }
  ];

  timesheet: any = [];

  constructor(
    private fb: FormBuilder,
    private dateService: DateTimeFormatterService
  ) {
    super();
  }
  
  ngOnInit(): void {
    this.createFormGroup();
  }

  createFormGroup() {
    this.timesheetForm = this.fb.group({
      consumer: [],
      service: [],
    });
  }

  viewerValueChange(event: any) {
    console.log('called');
  }

  onChange(event: any) {
    let fromDate = event[0];
    let toDate = event[1];

    this.timesheet = [];
    while(fromDate <= toDate){
      var str = this.dateFormatter.formatToString(fromDate);
      this.timesheet.push({
        date: str,
        service: '1',
        from: '',
        to: ''
      });

      var newDate = new Date(fromDate.setDate(fromDate.getDate() + 1));
      fromDate = newDate;
    }
  }

  onOk(event: import("ng-zorro-antd/date-picker").CompatibleDate | null) {
    console.log('called');
  }

  onCalendarChange(event: (Date | null)[]) {
    if (event.length > 1) {
      this.onChange(event);
    }
  }
}
