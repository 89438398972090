<div>
  <nz-card nz-col [nzSpan]="source !== 'enrollment' ? 20 : 24" class="ppl-p24 ppl-step-main" [nzBordered]="false">
    <form nz-form [formGroup]="preferncesForm" class="ppl-p0">
      @if(isAttrAvailable('emergencyContact')){
      <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
        <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="emergencyContact"
          class="ppl-form-label">{{getAttributes('emergencyContact').label}}
          @if(getAttributes('emergencyContact').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
            <input nz-input formControlName="emergencyContactFirstName" id="emergencyContactFirstName" placeholder="First Name" [readonly]="formReadonly" />
          </div>
        </nz-form-control>
        <nz-form-control class="ppl-form-control" style="margin: 0 8px;">
          <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
            <input nz-input formControlName="emergencyContactLastName" id="emergencyContactLastName" placeholder="Last Name" [readonly]="formReadonly" />
          </div>
        </nz-form-control>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
            <input nz-input formControlName="emergencyContactPhone" id="emergencyContactPhone"
              placeholder="Contact Number" [readonly]="formReadonly" />
          </div>
        </nz-form-control>
      </nz-form-item>
      }
      @if(isAttrAvailable('registerPref')){
      <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
        <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="registerPref"
          class="ppl-form-label">{{getAttributes('registerPref').label}}
          @if(getAttributes('registerPref').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-col [nzSm]="8" [nzXs]="24">
          <nz-form-control class="ppl-form-control" nz-tooltip
            nzTooltipTitle="{{getAttributes('registerPref').tooltip}}" nzTooltipPlacement="topRight">
            <nz-select formControlName="registerPref" class="ppl-select" id="registerPref" nzShowArrow
              [ngClass]="{'error': validationErrors['registerPref'] }" [nzDisabled]="formReadonly">
              @for (opt of participantRegisterMode; track $index) {
              <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
              }
            </nz-select>
            @if (validationErrors['registerPref']) {
            <span class="pl-1 error-message">{{validationErrors['registerPref']}}</span>
            }
          </nz-form-control>
        </nz-col>
      </nz-form-item>
      }
      @if(isAttrAvailable('nonProfitStatus')){
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="nonProfitStatus"
            class="ppl-form-label">{{getAttributes('nonProfitStatus').label}}
            @if(getAttributes('nonProfitStatus').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
            </nz-form-item>
        <nz-row>
            <nz-col [nzSm]="12" [nzXs]="24" >
            <nz-form-item class="ppl-form-item">
                <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="taxExempt"
                  class="ppl-form-label">{{getAttributes('taxExempt').label}}
                  @if(getAttributes('taxExempt').mandatory === 'Y'){
                  <span>*</span>
                  }</nz-form-label>
          <nz-form-control class="ppl-form-control">
            <nz-select [nzMaxTagCount]="4" nzMode='default' [ngClass]="{'error': validationErrors['taxExempt'] }"
              nzPlaceHolder="{{getAttributes('taxExempt').placeholder}}" id="taxExempt" formControlName="taxExempt"
              style="width:100%; margin-bottom: 16px" nzShowArrow [nzDisabled]="formReadonly">
              @for(opt of taxExemptOptions; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
            </nz-select>
            @if (validationErrors['services']) {
            <span class="pl-1 error-message">{{validationErrors['services']}}</span>
            }
          </nz-form-control>
          </nz-form-item>
          </nz-col>
          <nz-col [nzSm]="12" [nzXs]="24" >
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="nprftDocumentationReceived"
              class="ppl-form-label">{{getAttributes('nprftDocumentationReceived').label}}
              @if(getAttributes('nprftDocumentationReceived').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
      <nz-form-control class="ppl-form-control">
        <nz-select [nzMaxTagCount]="4" nzMode='default' [ngClass]="{'error': validationErrors['nprftDocumentationReceived'] }"
          nzPlaceHolder="{{getAttributes('nprftDocumentationReceived').placeholder}}" id="nprftDocumentationReceived" formControlName="nprftDocumentationReceived"
          style="width:100%; margin-bottom: 16px" nzShowArrow [nzDisabled]="formReadonly">
          <nz-option [nzValue]="'yes'" [nzLabel]="'Yes'"></nz-option>
          <nz-option [nzValue]="'no'" [nzLabel]="'No'"></nz-option>
        </nz-select>
        @if (validationErrors['nprftDocumentationReceived']) {
        <span class="pl-1 error-message">{{validationErrors['nprftDocumentationReceived']}}</span>
        }
      </nz-form-control>
      </nz-form-item>
    </nz-col>
    </nz-row>
        }
    </form>
  </nz-card>
</div>