<nz-drawer

      [nzVisible]="config.visible"
      nzPlacement="right"
      [nzTitle]="config.title"
      [nzBodyStyle]="{ overflow: 'auto' }"
    nzWidth="40%"
      [nzMaskClosable]="false" 
      (nzOnClose)="close()"
    >
      <ng-container *nzDrawerContent>
        <ng-content select="[body]">

        </ng-content>
      </ng-container>
    </nz-drawer>



    