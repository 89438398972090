/* src/app/shared/components/signature/signature.component.css */
#signature {
  --box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10) inset, -1px 1px 5px -3px rgba(0, 0, 0, 0.45);
  --signature-max-width: 500px;
}
.signature-container {
  width: 100%;
  padding: 0;
}
form,
img {
  max-width: var(--signature-max-width);
}
canvas {
  border: 1px solid rgba(234, 234, 234, 0.46);
  border-radius: 8px;
  background-color: #fff;
  box-shadow: var(--box-shadow);
}
.buttons {
  margin-top: 10px;
}
button {
  margin: 5px;
  padding: 5px 10px;
  font-size: 1.143rem;
}
.edit-cta {
  margin-bottom: 1rem;
  align-self: flex-end;
}
img {
  box-shadow: var(--box-shadow);
  background-color: #fff;
  border-radius: 8px;
}
/*# sourceMappingURL=signature.component-LB4O3ASE.css.map */
