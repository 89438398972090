import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { MainMenuItem, SubMenuLinkItem } from '../../interfaces/menu.interface';
import { MenuButtonComponent } from "../menu-button/menu-button.component";
import { RoutingService } from '../services/routing.service';
import { STATIC_DATA } from '../constants/static-data.constants';

@Component({
  selector: 'ppl-menu-drop-button',
  standalone: true,
  imports: [CommonModule, MenuButtonComponent],
  templateUrl: './menu-drop-button.component.html',
  styleUrl: './menu-drop-button.component.css'
})
export class MenuDropButtonComponent {
  @Input() label = 'Dropdown';
  @Input() icon?: string;
  @Input() iconPosition: 'left' | 'right' = 'right';
  @Input() type = 'button';
  @Input() disabled = false;
  @Input() customClass = '';
  @Input() menuItems: MainMenuItem[] = [];

  showDropdown = false;

  constructor(private eRef: ElementRef, private routerService : RoutingService) {
  }

  toggleDropdown(): void {
    if (!this.disabled) {
      this.showDropdown = !this.showDropdown;
    }
  }

  selectItem(): void {
    this.showDropdown = false;
  }

  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent): void {

    // clicked on a child navigation menu close the dropdown
    const target = event.target as HTMLElement;
    const clickedInside = target.classList.contains('dropdown-item');

    if (clickedInside && this.showDropdown) {
      this.showDropdown = false;
    }

    // clicked outside navigation menu close the dropdown
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showDropdown = false;
    }
  }  

  OnMenuClick(item: SubMenuLinkItem): void {
    console.log('Selected item:', item);
    this.showDropdown = false;
    const route = `/home/${item.landing_component}`
    // to define the page id based on the url
    localStorage.setItem(STATIC_DATA.URL_FIRST_SEGMENT, item.landing_component);
    this.routerService.navigate(route)
  }
}