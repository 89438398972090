import { Component } from '@angular/core';

@Component({
  selector: 'ppl-communication-details',
  standalone: true,
  imports: [],
  templateUrl: './communication-details.component.html',
  styleUrl: './communication-details.component.css'
})
export class CommunicationDetailsComponent {

}
