import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from '../../shared/components/card/card.component';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { MenuConfigResponse, Program, UserDataResponse, UserDetails } from '../../interfaces/user.interface';
import { Router } from '@angular/router';
import { MenuService } from '../../services/menu.service';
import { environment } from '../../../environments/environment';
import { ReferralDataService } from '../../services/referral-data.service';
import { ENROLLMENT_TYPE_BACKEND_MAPPINGS, STATIC_DATA } from '../../shared/constants/static-data.constants';
import { LeadDetailsService } from '../../services/lead-details.service';
import { SectionsService } from '../../services/sections.service';
import { LookupService } from '../../services/lookup.service';
import { MainMenuItem } from '../../interfaces/menu.interface';
import { AppStateService } from '../../services/app-state.service';
import { ProgramService } from '../../services/program.service';

@Component({
  selector: 'ppl-dashboard',
  standalone: true,
  imports: [CommonModule, CardComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit {
  userPrograms!: Program[];
  userData!: UserDetails;
  savedData: any;

  constructor(private userService: UserService, private authService: AuthService, private router: Router,
    private menuService: MenuService,
    private referralDataService: ReferralDataService,
    private leadDetailsService: LeadDetailsService,
    private sectionService: SectionsService,
    private lookupService: LookupService,
    private appStateService: AppStateService,
    private programService: ProgramService
  ) { }

  ngOnInit(): void {

    this.authService.getUsers().subscribe(
      (data: UserDataResponse) => {
        this.appStateService.setUserDetails(data.responsedata);
        this.userData = data.responsedata;
        this.userPrograms = data.responsedata.programs;
        this.userService.userPrograms(this.userPrograms);
      },
      (error) => {
        console.error(error);
      }
    );


    ///this.userService.programSubject.subscribe((data) => this.userPrograms = data);
  }


  onProgramSelection = (program: Program) => {
    this.programService.fetchMenuAuthorization(program.program_code, 'MENU').subscribe(
      (data: MenuConfigResponse) => {
        console.log('Selected Program:', program);
        const restrictedMenus = data.responsedata.length > 0 ? data.responsedata[0].restrictedMenus : [];
        localStorage.setItem("restricted_menus", JSON.stringify(restrictedMenus ?? []));
        localStorage.setItem("selected_program", JSON.stringify(program));
        this.userService.setSelectedProgram(program);

        const menu = this.menuService.getMenu(program.program_code);
        const menuItemstoDisplay = this.filterMenuItems(menu, restrictedMenus ?? []);

        if (!environment.isExternal) {
          const landingComponent = menuItemstoDisplay[0].landing_component;
          if (landingComponent != 'globalConsumerSearch') {
            localStorage.setItem(STATIC_DATA.URL_FIRST_SEGMENT, landingComponent ?? 'participantEnrollmentList');
          }
          this.router.navigate([`/home/${landingComponent}`]);
          return;
        }
        //default route to participant enrollment list
        this.handleExternalLandingPage(program, `/home/participantEnrollmentList`);
      }, (error) => {
        console.error(error);
      }
    );
  }

  filterMenuItems = (menu: MainMenuItem[], restrictedMenus: string[]) => {
    const menuItems = menu.filter((menuItem) => {
      return menuItem.display === "y"
    });

    const filterMenuItems = menuItems.filter((menuItem) => restrictedMenus.indexOf(menuItem.menu_id) == -1);
    return filterMenuItems;
  }

  handleExternalLandingPage(program: Program, defaultRoute: string) {
    if (this.userData) {

      // get lookup post program selection 
      this.getLookupValues();

      switch (this.userData.usertype_lkp?.toLowerCase()) {
        case 'participant':
          localStorage.setItem(STATIC_DATA.URL_FIRST_SEGMENT, STATIC_DATA.PARTICIPANT_ENROLLMENT_LIST);
          this.getEnrollmentAndNavigate(STATIC_DATA.PARTICIPANT_ENROLLMENT, program.program_code, program.referenceId);
          break;
        case 'provider':
          localStorage.setItem(STATIC_DATA.URL_FIRST_SEGMENT, STATIC_DATA.PROVIDER_ENROLLMENT_LIST);
          this.getEnrollmentAndNavigate(STATIC_DATA.PROVIDER_ENROLLMENT, program.program_code, program.referenceId);
          break;
        default:
          localStorage.setItem(STATIC_DATA.URL_FIRST_SEGMENT, STATIC_DATA.PARTICIPANT_ENROLLMENT_LIST);
          this.router.navigate([defaultRoute]);
      }
    }
  }

  // not used
  navigateToParticipant(referenceId: string, program_code: string) {
    localStorage.setItem('lead_entry', 'participantEnrollment');
    localStorage.setItem('selected_lead', referenceId);
    this.getEnrollmentAndNavigate(STATIC_DATA.PROVIDER_ENROLLMENT, program_code, referenceId)

  }

  // not used
  navigateToProvider(referenceId: string, program_code: string) {

    localStorage.setItem('selected_lead', referenceId);
    this.sectionService.setFlow('providerEnrollment');
    this.getEnrollmentAndNavigate(STATIC_DATA.PROVIDER_ENROLLMENT, program_code, referenceId);
  }

  getEnrollmentAndNavigate(entry: string, program_code: string, referenceId: string) {
    this.referralDataService.clearReferrralData();
    localStorage.setItem(STATIC_DATA.LEAD_ENTRY, entry);
    this.sectionService.setFlow(entry);
    localStorage.setItem(STATIC_DATA.SELECTED_LEAD, referenceId);



    this.leadDetailsService
      .fetchLeadDetailsApi(
        entry,
        program_code,
        Number(referenceId)
      )
      .subscribe((data) => {

        if (entry === STATIC_DATA.PROVIDER_ENROLLMENT) {
          this.savedData = data?.responsedata?.basicInfo;

          this.sectionService.setEnrollmentType(
            JSON.stringify([
              ENROLLMENT_TYPE_BACKEND_MAPPINGS[
              this.savedData?.providerType
              ],
            ])
          );

        } else {
          this.savedData = data?.responsedata?.infoData;
        }

        if (
          this.savedData?.applicationStatus === 'New Application' ||
          this.savedData?.applicationStatus === 'In Progress'
        ) {

          if (entry === STATIC_DATA.PROVIDER_ENROLLMENT) {

            localStorage.setItem('providerId', this.savedData?.providerId);
            localStorage.setItem('trackingNumber', this.savedData?.trackingNumber);
            localStorage.setItem('providerPPLId', this.savedData?.providerPPLId);

            this.referralDataService.setReferralDetails(data?.responsedata);
          }

          if (entry === STATIC_DATA.PARTICIPANT_ENROLLMENT) {
            this.referralDataService.setReferralDetails(data?.responsedata);
            localStorage.setItem('participantId', this.savedData?.participantId);
            localStorage.setItem('participantPPLId', this.savedData?.participantPPLId);
            localStorage.setItem('trackingNumber', this.savedData?.trackingNumber);

          }

          setTimeout(() => {
            this.router.navigate(['/newReferral']);
          }, 200);
        } else {
          // to define the page id based on the url
          localStorage.setItem(STATIC_DATA.URL_FIRST_SEGMENT, 'enrollmentDetails');
          setTimeout(() => {
            this.router.navigate(['/home/enrollmentDetails']);
          }, 200);
        }
      });
  }


  getLookupValues() {
    this.lookupService.fetchLookupValues().subscribe((lookupData) => {
      localStorage.setItem("lookup_values", JSON.stringify(lookupData));
    })
  }

}
