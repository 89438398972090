/* src/app/shared/components/toaster/toaster.component.css */
.alert-box {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 2050;
  width: auto;
  height: auto;
  box-sizing: border-box;
}
.overlay {
  display: none;
}
.content {
  display: flex;
  width: 400px;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid var(--Color-1, #ffffff);
  background:
    linear-gradient(
      173deg,
      rgba(232, 232, 232, 1) 0%,
      rgba(255, 255, 255, 0.9220063025210083) 23%,
      rgba(226, 226, 226, 1) 45%,
      rgba(246, 246, 246, 0.9248074229691877) 63%,
      rgba(232, 232, 232, 1) 100%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 27%), 0px 0px 0px 0px rgb(0 0 0);
  z-index: 2051;
  margin-top: 20px;
  margin-left: 20px;
  max-width: 400px;
  box-sizing: border-box;
}
.body {
  display: flex;
  height: auto;
  padding: 12px 12px 32px 12px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.footer {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.action-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
}
.header {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  font-size: 1.429rem;
  font-weight: bold;
  gap: 12px;
  width: 100%;
}
.body-content {
  align-self: stretch;
  color: var(--Color-9, #333);
  font-family: Outfit;
  font-size: 1.429rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
/*# sourceMappingURL=toaster.component-AHGFT6LJ.css.map */
