import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { APP_REGEX } from '../constants/static-data.constants';

@Injectable({
  providedIn: 'root'
})
export class DateTimeFormatterService {

  disabledEmergencyHours = {
    day: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
    night: [19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5]
  }

  constructor(private datePipe: DatePipe) { }

  formatDateToString(date: Date): string {
    if(date){
      const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${month}/${day}/${year}`; 
    }else{
      return '';
    }
  }

  formatToString(date: Date): string {
    if(date){
      const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`; 
    }else{
      return '';
    }
  }

  formatStringToDate(dateString: string): Date {
    const [month, day, year] = dateString.split('/');
    return new Date(+year, +month - 1, +day);
  }

  formatTimeToString(date: Date): string {
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    return `${hours}:${minutes}`;
  }

  formatStringToTime(timeString: string): Date {
    const [hours, minutes, seconds] = timeString.split(':');
    return new Date(0, 0, 0, +hours, +minutes, +seconds);
  }
 
  formatDateTimeToString(dateTime: Date): string {
    return `${this.formatDateToString(dateTime)} ${this.formatTimeToString(dateTime)}`;
  }

  formatDateTime(dateTimeString: string): string {
    return this.datePipe.transform(dateTimeString, 'short') as string
  }

  formatDate(date: Date, format: string): string {
    return this.datePipe.transform(date, format) || '';
  }

  //this will return in format M/d/yy HH:mm:ss
  formatDateTimeNoMs(dateTimeString: string): string {
    return this.datePipe.transform(dateTimeString, 'M/d/yy HH:mm:ss') as string;
  }


  parsePreferredDaysAndTime(input: string) {
    // Regular expression to match the time and days
    const regexDays = APP_REGEX.PREFERRED_DAYS;
    const regexTime = APP_REGEX.PREFERRED_TIME;  
    
    // Execute the regex on the input string
    const matchDays = input.match(regexDays);
    const matchTime = input.match(regexTime);
  
    // Extracted time and days
    let startTime = null;
    let endTime = null;
    let days: any[] = [];

    if (matchDays) {
        days = matchDays[1].split(',');
    }
    if (matchTime) {
        startTime = matchTime[1];
        endTime = matchTime[2];
    }
    return {
        startTime,
        endTime,
        days
    };
}


  getDateTimeFromTime(timeString: string | null): Date {
    if (!timeString) {
        throw new Error("Time string cannot be null or undefined.");
    }

    try{
      // Split the time string into time and AM/PM
    const [time, modifier] = timeString.split(' ');
    
    // Split the time into hours and minutes
    const timeSplit = time.split(':').map(Number);
    let hours = timeSplit[0]; 
    const minutes  = timeSplit[1];

    // Convert 12-hour format to 24-hour format
    if (modifier === 'PM' && hours !== 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }

    // Create a Date object with the current date and the parsed time
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0); // Set seconds to 0
    date.setMilliseconds(0); // Set milliseconds to 0

    return date;
    }catch(e){
      console.log(e);
      throw new Error("Invalid time format. Please use 'HH:mm' format.");
    }
}

getTimeFromDate(date: Date | null): string {
  if (!date) {
      throw new Error("Date cannot be null or undefined.");
  }

  console.log("datep-----", date);
  try {
      const hours: number = date.getHours();
      const minutes: number = date.getMinutes();
      let modifier = 'AM';

      // Convert hours from 24-hour format to 12-hour format
      const displayHours = hours % 12 || 12; // Converts 0 to 12 for midnight
      if (hours >= 12) {
          modifier = 'PM';
      }

      // Format minutes to always have two digits
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
      const formattedHrs = displayHours < 10 ? `0${displayHours}` : displayHours.toString();
      // Construct and return the formatted time string
      return `${formattedHrs}:${formattedMinutes} ${modifier}`;
  } catch (e) {
      console.log(e);
      throw new Error("Error extracting time in AM/PM format.");
  }

}

  getStartAndEndTimes(timeRange: string): { startTime: Date | null, endTime: Date | null } {

    //input format "06:30 AM - 06:30 PM"
    // Step 1: Split the string by ' - ' to get the start and end time strings
    const times = timeRange.split(' - ');
  
    // Step 2: Trim spaces to get clean time strings
    const startTimeString = times[0].trim();
    const endTimeString = times[1].trim();
  
    // Step 3: Convert the time strings to Date objects (optional)
    const startTime = this.getDateTimeFromTime(startTimeString);
    const endTime = this.getDateTimeFromTime(endTimeString);
    // const endTime = this.getDateTimeFromTime(endTimeString);
  
    // check valida date format
    const isValidDate = (date: Date): boolean => !isNaN(date.getTime());

    return {
        startTime: isValidDate(startTime) ? startTime : null,
        endTime: isValidDate(endTime) ? endTime : null
    };

  }

  formatDateToUSDateString(date: Date): string {
    // Returns date in MM/dd/yyyy
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    } as Intl.DateTimeFormatOptions;
    return date instanceof Date ? date.toLocaleDateString('en-US', options) : '';
  }
  
}
