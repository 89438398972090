<div [formGroup]="uploadForm" class="ppl-p0">
  <!-- @if(docTypeList?.length){ -->
  <nz-form-item class="ppl-form-item">
    <nz-form-control nz-tooltip nzTooltipPlacement="topRight">
      <nz-select formControlName="document_type" id="document_type" 
      pplNzAriaLabel
      nzAriaLabel="Document type"
        [nzPlaceHolder]="'Select document type'" [ngClass]="{'error': validationErrors['document_type'] }" nzShowSearch
        nzAllowClear>
        @for(opt of docTypeList; track $index){
        <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
        }
        <nz-option [nzLabel]="'Other'" [nzValue]="'other'"></nz-option>
      </nz-select>
      @if(uploadForm.get('document_type')?.value === 'other'){
      <nz-form-item class="ppl-form-item" style="margin-top: 4px;">
        <nz-form-control class="">
          <div nz-tooltip nzTooltipPlacement="topRight">
            <input class="type-input" nz-input placeholder="Document Name" formControlName="other_type" />
          </div>
        </nz-form-control>
      </nz-form-item>
      }
      @if (validationErrors['document_type']) {
      <span class="pl-1 error-message">{{validationErrors['document_type']}}</span>
      }
    </nz-form-control>
  </nz-form-item>
  <!-- } -->
  <!-- @else {
  <nz-form-item class="ppl-form-item">
    <nz-form-control class="ppl-form-control">
      <div nz-tooltip nzTooltipPlacement="topRight">
        <input class="type-input" nz-input placeholder="Document Name" formControlName="document_type" />
      </div>
    </nz-form-control>
  </nz-form-item>
  } -->
  <nz-form-item class="ppl-form-item">
    <nz-form-control>
      <nz-upload (nzChange)="handleChange($event)" [nzShowUploadList]="false" [nzCustomRequest]="customRequest" [nzAccept]="'.txt, .pdf, .doc, .docx, .xls, .xlsx, .csv, .png, .jpg, .jpeg'">
        <button nz-button class="primary-btn">
          <i class="fa fa-upload onemore" aria-hidden="true"></i>
          Upload
        </button>
      </nz-upload>
    </nz-form-control>
  </nz-form-item>
  </div>
  <div nz-row class="ppl-form-item">
    <p class="info-box">
      <i class="fa fa-info-circle" aria-hidden="true"></i> Allowable file types: txt, pdf, doc, docx, xls, xlsx, csv, png, jpg, jpeg.
    </p>
  </div>
  @if(showFileList) {
    <ul class="list-container">
      @for(file of fileDetailsArr; track $index){
      <li class="file-details">
        <span class="text-hidden"><strong>{{file.type}} :</strong></span>
        <span class="text-hidden"><span nz-icon nzType="paper-clip" nzTheme="outline"></span>{{ file.name }}</span>
        <div class="card flex flex-wrap flex-column align-items-center gap-2">
          <div class="flex flex-wrap justify-content-center gap-2">
            <button
              aria-label="Delete"
              nz-button 
              nzType="default" 
              (click)="confirmPosition('top',file)" 
              class="remove-btn"
            >
              <span nz-icon nzType="delete" nzTheme="outline"></span>
            </button>
          </div>
        </div>
      </li>
      }
    </ul>
  }
