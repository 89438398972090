import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';  // Import CommonModule
import { Program } from '../../../interfaces/user.interface';

@Component({
  selector: 'ppl-card',
  standalone: true,
  imports: [CommonModule],  // Include CommonModule in imports
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent {
  programData !: Program;

  @Input() set program(data :any) { 
    this.programData = data;
  };   
  
  @Output() emitSelectedProgram : EventEmitter<Program> = new EventEmitter<Program>();

  isDarkTheme = false;

  toggleBackground(event: Event, programName: string) {
    event.stopPropagation();
    this.isDarkTheme = !this.isDarkTheme;
  }

  selectProgram() {
    console.log(this.programData);
    this.emitSelectedProgram.emit(this.programData);
  }
  
}
