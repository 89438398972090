import { Component } from '@angular/core';
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { BaseComponent } from '../../../../shared/utils/base.component';



@Component({
  selector: 'ppl-auth-utilization',
  standalone: true,
  imports: [NgZorroModule],
  templateUrl: './auth-utilization.component.html',
  styleUrl: './auth-utilization.component.css'
})
export class AuthUtilizationComponent extends BaseComponent {
}
