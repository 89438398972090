import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { SharedDataService } from '../../shared/services/shared-data-service';
import { STATIC_DATA } from '../../shared/constants/static-data.constants';

@Component({
  selector: 'ppl-participant-leads',
  standalone: true,
  imports: [RouterModule, NzBreadCrumbModule],
  templateUrl: './participant-leads.component.html',
  styleUrl: './participant-leads.component.css'
})
export class ParticipantLeadsComponent {

  constructor(private sharedDataService: SharedDataService) {
    this.sharedDataService = sharedDataService;
  }

  clearData() {
    this.sharedDataService.clearData(STATIC_DATA.SHARED_PARTICIPANT_LEADS_DATA);
  }
}
