import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationUtility } from './auth.utility';

@Directive({
  standalone: true,
  selector: '[authorize]',
})
export class AuthorizeDirective {
  private hasView = false;
  private id!: string;
  private pageId!: string;
  private tabId!: string;
  private lookup!: string;
  private action!: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authUtility: AuthorizationUtility
  ) {
  }

  @Input() set authorize(config: string[]) {
    this.pageId = config[0];
    this.tabId = config[1];
    this.id = config[2];
    this.lookup = config[3];
    this.action = config[4];
    this.handleView();
  }

  private handleView() {
    if (!this.id || !this.lookup || !this.pageId || !this.tabId || this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }

    let hasAccess = false;
    switch (this.lookup) {
      case 'section':
        hasAccess = this.getSectionAccess(this.action ?? 'read');
        break;
      case 'button':
        hasAccess = this.getButtonAccess();
        break;
    }


    if (hasAccess && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }

  private getSectionAccess(action: string) {
    switch (action) {
      case 'read':
        return this.authUtility.isSectionVisible(this.pageId, this.tabId, this.id);
      case 'edit':
        return this.authUtility.isSectionEditable(this.pageId, this.tabId, this.id);
      case 'delete':
        return this.authUtility.isSectionDeletable(this.pageId, this.tabId, this.id);
    }

    console.log('invalid action', action)
    return false;
  }

  private getButtonAccess() {
    return this.authUtility.isButtonVisible(this.pageId, this.id);
  }
}