{
  "columns": [
    {
      "header_id": "paBillRateId",
      "header_label": "Bill Rate Id",
      "jsonpath": "paBillRateId",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "serviceAdminName",
      "header_label": "Service Administration",
      "jsonpath": "serviceAdminName",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "mcoName",
      "header_label": "MCO",
      "jsonpath": "mcoName",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "regionName",
      "header_label": "Region",
      "jsonpath": "regionName",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "countyName",
      "header_label": "County",
      "jsonpath": "countyName",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "serviceCode",
      "header_label": "Service Code",
      "jsonpath": "serviceCode",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "modifierCode",
      "header_label": "Modifier",
      "jsonpath": "modifierCode",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "rate",
      "header_label": "Rate",
      "jsonpath": "rate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "fromDate",
      "header_label": "From Date",
      "jsonpath": "fromDate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "toDate",
      "header_label": "To Date",
      "jsonpath": "toDate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    }
  ],
  "buttons": [],
  "apiDetails": "billableRatesList.json",
  "pageName": "pa-bill-rate-list",
  "orderByColumn": ["paBillRateId"],
  "orderBy": ["asc"]
}
