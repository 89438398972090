@if(entry === 'participantEnrollment') {

<form nz-form [formGroup]="newContactForm" class="ppl-p0">
    @if(isAttrAvailable('newEmployer')){
    <ppl-accordion [title]="'Employer Details'" [isOpen]="true" *authorize="[pageId, tabId, 'EmployerDetails', 'section']">
        <ppl-association-details [sectionName]="'employer'" [gridData]="getProviderData('EMP')" [tabId]="tabId" [pageId]="pageId" [sectionId]="'EmployerDetails'"
            [gridCols]="cols"></ppl-association-details>
    </ppl-accordion>
    }

    @if(isAttrAvailable('newAuthRep')){

    <ppl-accordion [title]="'Designated Representative'" [isOpen]="true" *authorize="[pageId, tabId, 'DesignatedRepresentative', 'section']">
        <ppl-association-details [sectionName]="'authorized_representative'" [gridData]="getProviderData('AR')" [tabId]="tabId" [pageId]="pageId" [sectionId]="'DesignatedRepresentative'"
            [gridCols]="cols"></ppl-association-details>
    </ppl-accordion>

    }
    @if(isAttrAvailable('newProvider')){

    <ppl-accordion [title]="'Personal Assistant'" [isOpen]="true" *authorize="[pageId, tabId, 'PersonalAssistant', 'section']">
        <ppl-association-details [sectionName]="'independent_provider'" [gridData]="getProviderData('IR')" [tabId]="tabId" [pageId]="pageId" [sectionId]="'PersonalAssistant'"
            [gridCols]="cols"></ppl-association-details>

    </ppl-accordion>
    }

    @if(isAttrAvailable('newVendor')){
    <ppl-accordion [title]="'Vendor'" [isOpen]="true" *authorize="[pageId, tabId, 'Vendor', 'section']">
        <ppl-association-details [sectionName]="'vendor'" [gridData]="getProviderData('Vendor')" [tabId]="tabId" [pageId]="pageId" [sectionId]="'Vendor'"
            [gridCols]="cols"></ppl-association-details>
    </ppl-accordion>
    }

    @if(isAttrAvailable('newContractor')){
    <ppl-accordion [title]="'Independent Contractor'" [isOpen]="true" *authorize="[pageId, tabId, 'IndependentContractor', 'section']">
        <ppl-association-details [sectionName]="'independent_contractor'" [gridData]="getProviderData('IC')" [tabId]="tabId" [pageId]="pageId" [sectionId]="'IndependentContractor'"
            [gridCols]="cols"></ppl-association-details>
    </ppl-accordion>
    }
    @if(isAttrAvailable('newLegalGuardian')){
    <ppl-accordion [title]="'Legal Guardian'" [isOpen]="true" *authorize="[pageId, tabId, 'LegalGuardian', 'section']">
        <ppl-association-details [sectionName]="'legal_guardian'" [gridData]="getProviderData('LG')" [tabId]="tabId" [pageId]="pageId" [sectionId]="'LegalGuardian'"
            [gridCols]="cols"></ppl-association-details>
    </ppl-accordion>
    }
    @if(isAttrAvailable('newPOA')){
    <ppl-accordion [title]="'POA'" [isOpen]="true" *authorize="[pageId, tabId, 'POA', 'section']">
        <ppl-association-details [sectionName]="'poa'" [gridData]="getProviderData('POA')" [tabId]="tabId" [pageId]="pageId" [sectionId]="'POA'"
            [gridCols]="cols"></ppl-association-details>
    </ppl-accordion>
    }

</form>
}
@else{
<ppl-accordion [title]="'Associations'" [isOpen]="true">
    <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="isAssociationsEditable" [data]="prvdrAssociations"
        [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false" [loadPage]="true"
        [actionButtons]="actions" (gridAction)="action($event)">
    </ppl-list-table>
</ppl-accordion>

<div class="filter-panel">
    <ppl-modal [showModal]="showModal" [title]="modalBody" [isOkText]="'Save'" (confirm)="handleCancel()"
        (cancel)="handleCancel()">

        @if (mode === 'read' && !disableAddStatus) {
        <div class="custom-row">
            <div class="mr-2">
                <ppl-button label="Add Status" icon="fa fa-plus" [customClass]="'ghost-button'" [iconPosition]="'left'"
                    (click)="addStatus()" *authorize="[pageId, tabId, 'AddCaseManager', 'section', 'edit']"></ppl-button>
            </div>
        </div>
        }

        @if (mode === 'read' && showMoveToInPPLReviewButton) {
            <div class="custom-row">
              <div class="mr-2">
                <ppl-button label="Move to PPL Review" icon="fa fa-arrow-circle-right" [customClass]="'ghost-button'" [iconPosition]="'left'"
                  (click)="moveToPPLReview()" *authorize="[pageId, tabId, 'MoveToInPPLReview', 'section', 'edit']"></ppl-button>
              </div>
            </div>
            }

        @if(mode === 'edit' || mode === 'add') {
        <div class="custom-row">
            <ppl-button label="Cancel" icon="fa fa-ban" [customClass]="'alt-button'" [iconPosition]="'left'"
                (click)="cancelEdit()"></ppl-button>
            <ppl-button label="Save" icon="fa fa-floppy-o" [customClass]="'alt-button'" [iconPosition]="'left'"
                (click)="save()"></ppl-button>
        </div>

        <div class="ppl-mt20">
            <form nz-form nz-row [formGroup]="enrollmentStatus" class="ppl-p0">
                @if(isAttrAvailable('status')){
                <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
                    <nz-row>
                        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="status" class="ppl-form-label"
                            [authorizeField]="[pageId, tabId, 'Demographics', 'status']">{{ getAttributes("status").label }}
                            @if(getAttributes('status').mandatory === 'Y'){
                            <span>*</span>
                            }</nz-form-label>
                        <nz-form-control class="ppl-form-control" nz-tooltip
                            nzTooltipTitle="{{getAttributes('status').tooltip}}" nzTooltipPlacement="topRight">
                            <nz-select formControlName="status" class="ppl-select" id="status"
                                nzPlaceHolder="Select Registration Status" [nzDisabled]="mode === 'edit' || disableStatus"
                                [ngClass]="{'error': validationErrors['status'] }" nzShowSearch nzAllowClear>
                                @for(opt of statusEntries; track $index){
                                <nz-option [nzLabel]="opt.status" [nzValue]="opt.status"></nz-option>
                                }
                            </nz-select>
                            @if (validationErrors['status']) {
                            <span class="pl-1 error-message">{{validationErrors['status']}}</span>
                            }
                        </nz-form-control>
                    </nz-row>
                </nz-form-item>
                }

                <div nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left"></div>


                @if(isAttrAvailable('startDate') && !disableStatus){
                <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">

                    <nz-row>
                        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="startDate"
                            class="ppl-form-label">{{getAttributes('startDate').label}}
                            @if(getAttributes('startDate').mandatory === 'Y'){
                            <span>*</span>
                            }</nz-form-label>
                        <nz-form-control class="ppl-form-control">
                            <div nz-tooltip [nzTooltipTitle]="getAttributes('startDate')?.tooltip || ''"
                                nzTooltipPlacement="topRight">
                                <nz-date-picker formControlName="startDate" [id]="'startDate'" style="height: 32px;"
                                    [nzDisabledDate]="disabledStartDate" [nzFormat]="'MM/dd/yyyy'"
                                    [nzPlaceHolder]="getAttributes('startDate').placeholder"
                                    [ngClass]="{'error': validationErrors['startDate'] }"></nz-date-picker>
                            </div>
                            @if (validationErrors['startDate']) {
                            <span class="pl-1 error-message">{{validationErrors['startDate']}}</span>
                            }
                        </nz-form-control>
                    </nz-row>
                </nz-form-item>
                }

                @if(isAttrAvailable('endDate') && !disableStatus){
                <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">

                    <nz-row>

                        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="endDate"
                            class="ppl-form-label">{{getAttributes('endDate').label}}
                            @if(getAttributes('endDate').mandatory === 'Y'){
                            <span>*</span>
                            }</nz-form-label>
                        <nz-form-control class="ppl-form-control">
                            <div nz-tooltip [nzTooltipTitle]="getAttributes('endDate')?.tooltip || ''"
                                nzTooltipPlacement="topRight">
                                <nz-date-picker formControlName="endDate" [id]="'endDate'" style="height: 32px;"
                                    [nzFormat]="'MM/dd/yyyy'" [nzDisabledDate]="disabledEndDate"
                                    [nzPlaceHolder]="getAttributes('endDate').placeholder"
                                    [ngClass]="{'error': validationErrors['endDate'] }"></nz-date-picker>
                            </div>
                            @if (validationErrors['endDate']) {
                            <span class="pl-1 error-message">{{validationErrors['endDate']}}</span>
                            }
                        </nz-form-control>
                    </nz-row>
                </nz-form-item>
                }

                @if(isAttrAvailable('reason')){
                <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
                    <nz-row>
                        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="reason" class="ppl-form-label"
                            [authorizeField]="[pageId, tabId, 'Demographics', 'reason']">{{ getAttributes("reason").label }}
                            @if(getAttributes('reason').mandatory === 'Y'){
                            <span>*</span>
                            }</nz-form-label>
                        <nz-form-control class="ppl-form-control" nz-tooltip
                            nzTooltipTitle="{{getAttributes('reason').tooltip}}" nzTooltipPlacement="topRight">
                            <nz-select formControlName="reason" class="ppl-select" id="reason"
                                nzPlaceHolder="Select Reason" [ngClass]="{'error': validationErrors['reason'] }"
                                nzShowSearch nzAllowClear>
                                @for(opt of statusReasons; track $index){
                                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                                }
                            </nz-select>
                            @if (validationErrors['reason']) {
                            <span class="pl-1 error-message">{{validationErrors['reason']}}</span>
                            }
                        </nz-form-control>
                    </nz-row>
                </nz-form-item>
                }

                @if(isAttrAvailable('comment')){
                <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item  nz-padding-left">
                    <nz-row>
                        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="comment"
                            class="ppl-form-label">{{getAttributes('comment').label}}
                            @if(getAttributes('comment').mandatory === 'Y'){
                            <span>*</span>
                            }
                        </nz-form-label>
                        <nz-form-control class="ppl-form-control" nz-tooltip
                            nzTooltipTitle="{{getAttributes('comment').tooltip}}" nzTooltipPlacement="topRight">
                            <textarea rows="4" cols="40" nz-input formControlName="comment" id="comment"
                                class="ppl-form-control" placeholder="{{getAttributes('comment').placeholder}}"
                                [ngClass]="{'error': validationErrors['comment'] }"></textarea>
                            @if (validationErrors['comment']) {
                            <span class="pl-1 error-message">{{validationErrors['comment']}}</span>
                            }
                        </nz-form-control>

                    </nz-row>

                </nz-form-item>
                }
            </form>
        </div>
        }

        @if(prvdrAssociationsStatus && prvdrAssociationsStatus.length > 0) {
        <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="isAssociationsEditable"
            [data]="prvdrAssociationsStatus" [disableSelection]="true" [cols]="statusCols" [gridTitle]="false"
            [pagination]="false" [loadPage]="true" [actionButtons]="actionButtons"
            (gridAction)="action($event)"></ppl-list-table>
        }
        @else {
        <nz-alert nzType="info" nzMessage="No data available for you." nzShowIcon></nz-alert>
        }
    </ppl-modal>
</div>
}