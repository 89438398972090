{
	"columns":[
		{
			"header_id": "ppl_id",
			"header_label": "PPL ID",
			"jsonpath":  "ppl_provider_id" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "tracking_number",
			"header_label": "Tracking Number",
			"jsonpath":  "tracking_number" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "lead_provider_id",
			"header_label": "Lead Id",
			"jsonpath":  "lead_provider_id" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "provider_id",
			"header_label": "Personal Assistant Id",
			"jsonpath":  "provider_id" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		},
		{
			"header_id": "first_name",
			"header_label": "First Name",
			"jsonpath":  "first_name" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "middle_name",
			"header_label": "Middle Name",
			"jsonpath":  "middle_name" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "last_name",
			"header_label": "Last Name",
			"jsonpath":  "last_name" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "date_of_birth",
			"header_label": "Date of Birth",
			"jsonpath":  "date_of_birth" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "maiden_previous",
			"header_label": "Maiden previous",
			"jsonpath":  "maiden_previous" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "final_applicant_disposition",
			"header_label": "Application Disposition",
			"jsonpath":  "final_applicant_disposition" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "program_code",
			"header_label": "Program Code",
			"jsonpath":  "program_code" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		},
		{
			"header_id": "decision_comments",
			"header_label": "Comments",
			"jsonpath":  "decision_comments" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "application_status_lkp",
			"header_label": "Application Status",
			"jsonpath":  "application_status_lkp" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "referral_id",
			"header_label": "Referral ID",
			"jsonpath":  "referral_id" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		},
		{
			"header_id": "application_source_lkp",
			"header_label": "Application Source",
			"jsonpath":  "application_source_lkp" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "application_assigned_name",
			"header_label": "Assigned To",
			"jsonpath":  "application_assigned_name" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "state_of_residence_lkp",
			"header_label": "Residence State",
			"jsonpath":  "state_of_residence_lkp" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "application_submission_date",
			"header_label": "Submission Date",
			"jsonpath":  "application_submission_date" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "application_submitted",
			"header_label": "Application Submitted",
			"jsonpath":  "application_submitted" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "providingcontractedservices",
			"header_label": "Providing Contractor Services",
			"jsonpath":  "providingcontractedservices" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		}
    ],
	"buttons": [
		{
			"button_id":"assign_to_user",
			"button_label":"Assign to User",
			"buttonAction" : "assign_to_user",
			"className": "primary-btn-color"
		},
		{
			"button_id":"assign_to_me",
			"button_label":"Assign to Me",
			"buttonAction" : "assign_to_me",
			"className": "primary-btn-color"
		},
		{
			"button_id":"send_sms",
			"button_label":"Send SMS",
			"buttonAction" : "sendSMS",
			"className": "primary-btn-color"
		},
		{
			"button_id":"send_email",
			"button_label":"Send Email",
			"buttonAction" : "sendEmail",
			"className": "primary-btn-color"
		},
		{
			"button_id": "send_registration",
			"button_label": "Resend Registration Link",
			"buttonAction": "send_registration",
			"className": "primary-btn-color"
		}
    ],
    "apiDetails": "providerenrollment.json",
	"pageName": "providerenrollmentlist",
	"orderByColumn": ["tracking_number"],
	"orderBy": ["desc"]
}