import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, map, of } from 'rxjs';
import { FileTriageData } from '../interfaces/fileTriage.interface';
import { ENDPOINTS } from '../shared/constants/url.constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FileTriageService {
  private selectedRowSubject = new BehaviorSubject<FileTriageData | null>(null);

  private openDialogFunction: ((checkedRows: any) => void) | null = null;
  constructor( private httpClient: HttpClient) {}

  setSelectedRow(row: FileTriageData): void {
    this.selectedRowSubject.next(row);
  }

  getSelectedRow(): Observable<FileTriageData | null> {
    return this.selectedRowSubject.asObservable();
  }

  getFileTriagePDF(filePath: string) : Observable<any> {
    const url = ENDPOINTS.ENROLLMENT.GET_FILE_TRIAGE_PDF
    try {      
      const pdf = this.httpClient.post(url, { filePath }, { responseType: 'blob' })
      return pdf
    } catch (err){
      console.error(err)
      return of(null)
    }

  }

  setOpenDialogFunction(openDialog: (null | ((checkedRows: any) => void))): void {
    this.openDialogFunction = openDialog;
  }

  callOpenDialogFunction(checkedRows: any): void {
    if (this.openDialogFunction) {
      this.openDialogFunction(checkedRows);
    } else {
      console.error('Open dialog function is not set.');
    }
  }
}