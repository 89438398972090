/* src/app/components/leads/enrollment/enrollment-contact-details/enrollment-contact-details.component.css */
.ant-input-affix-wrapper {
  margin-bottom: 0;
}
.address-section {
  width: 100%;
}
.ant-divider-horizontal {
  margin-bottom: 16px;
  margin-top: 0;
}
.address-control {
  margin-bottom: 0px;
}
.ant-form-item-label {
  margin-right: 8px;
}
.ant-radio-button-wrapper {
  margin: 0px;
  width: 100%;
}
.divider {
  border-top: 2px solid #cff1f04d;
}
.notes {
  font-size: 0.857rem;
  margin-top: 9px;
}
.notes p {
  margin: 0;
}
.ppl-form-item {
  margin: 4px 8px !important;
}
.ppl-step-main {
  padding: 0px !important;
  border-radius: 0 !important;
  background-color: #eaeaea;
}
::ng-deep .ant-card-body {
  padding: 0px !important;
}
::ng-deep .ant-form-horizontal .ant-form-item-label {
  margin: 0px !important;
}
.ppl-form-control {
  margin-bottom: 4px !important;
}
/*# sourceMappingURL=enrollment-contact-details.component-IANPTKJV.css.map */
