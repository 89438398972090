<div>
  <ppl-accordion [title]="'Application Details'" [isOpen]="true" 
  *authorize="[pageId, tabId, 'ApplicationDetails', 'section']">
    <ppl-lead-application-details [entry]="entry" [program]="program" [filledInfo]="filledInfo" [sectionData]="secData"
      [pageId]="pageId" [tabId]="tabId"></ppl-lead-application-details>
  </ppl-accordion>

  @if (isParticipantFlow && this.program?.program_code === 'NY') {
  <ppl-accordion [title]="'Registration Dates and Status'" [isOpen]="true"
    *authorize="[pageId, tabId, 'EligibilityDates', 'section']">
    <ppl-lead-detail-eligibility-dates [sectionData]="steps" (leadUpdated)="updatedLeadData($event)" [pageId]="pageId" [tabId]="tabId"
      [programCode]="program.program_code"></ppl-lead-detail-eligibility-dates>
  </ppl-accordion>

  <ppl-accordion [title]="'Support Team'" [isOpen]="true" 
  *authorize="[pageId, tabId, 'Viewer', 'section']">
    <ppl-lead-detail-viewer [sectionData]="steps" (leadUpdated)="updatedLeadData($event)" [pageId]="pageId" [tabId]="tabId"
      [programCode]="program.program_code"></ppl-lead-detail-viewer>
  </ppl-accordion>
  }

  <ppl-accordion [title]="title" [isOpen]="true" 
  *authorize="[pageId, tabId, 'Demographics', 'section']">
    <ppl-lead-detail-participant-info [sectionData]="steps" [entry]="entry" (leadUpdated)="updatedLeadData($event)"
      [pageId]="pageId" [tabId]="tabId" [programCode]="program.program_code" [selectedLead]="selectedLead"
      [enrollmentType]="enrollmentType"></ppl-lead-detail-participant-info>
  </ppl-accordion>

  <ppl-accordion [title]="'Address Information'" [isOpen]="true" 
  *authorize="[pageId, tabId, 'AddressInformation', 'section']">
    <ppl-lead-detail-address-info [sectionData]="steps" [entry]="entry" (leadUpdated)="updatedLeadData($event)"
      [selectedLeadId]="selectedLead" [pageId]="pageId" [tabId]="tabId"
      [programCode]="program.program_code"></ppl-lead-detail-address-info>
  </ppl-accordion>

  <ppl-accordion [title]="'Contact Details'" [isOpen]="true" 
  *authorize="[pageId, tabId, 'CommunicationMethods', 'section']">
    <ppl-lead-detail-comm-methods [sectionData]="steps" (leadUpdated)="updatedLeadData($event)" [pageId]="pageId" [tabId]="tabId"
      [programCode]="program.program_code"></ppl-lead-detail-comm-methods>
  </ppl-accordion>

  <ppl-accordion [title]="'Communication Information'" [isOpen]="true"
    *authorize="[pageId, tabId, 'CommunicationInformation', 'section']">
    <ppl-lead-detail-communication-info [sectionData]="steps" (leadUpdated)="updatedLeadData($event)" [pageId]="pageId" [tabId]="tabId"
      [selectedLeadId]="selectedLead" [programCode]="program.program_code"></ppl-lead-detail-communication-info>
  </ppl-accordion>


  <ppl-accordion [title]="'Identifiers'" [isOpen]="true" 
  *authorize="[pageId, tabId, 'Identifiers', 'section']">
    <ppl-lead-detail-identifiers [sectionData]="steps" (leadUpdated)="updatedLeadData($event)" [pageId]="pageId" [tabId]="tabId"
      [programCode]="program.program_code"></ppl-lead-detail-identifiers>
  </ppl-accordion>

  <!-- @if(steps && steps[professionalLicensePath]){
  <ppl-accordion [title]="'Professional Licenses'" [isOpen]="true"
    *authorize="[pageId, tabId, 'ProfessionalLicenses', 'section']">
    <ppl-lead-detail-professional-licenses [sectionData]="steps" [professionalLicense]="professionalLicense"
      [pageId]="pageId" [tabId]="tabId" (leadUpdated)="updatedLeadData($event)" [programCode]="program.program_code"
      [selectedLeadId]="selectedLead" [entry]="entry">
    </ppl-lead-detail-professional-licenses>
  </ppl-accordion>
  } -->

  @if(steps && steps[professionalLicensePath]){
  <ppl-accordion [title]="'Services'" [isOpen]="true" *authorize="[pageId, tabId, 'Services', 'section']">
    <ppl-lead-detail-services [sectionData]="steps" [entry]="entry" [selectedLeadId]="selectedLead" [pageId]="pageId" [tabId]="tabId"
      (leadUpdated)="updatedLeadData($event)" [programCode]="program.program_code"></ppl-lead-detail-services>
  </ppl-accordion>
  }


  <!--  <nz-collapse [nzExpandIconPosition]="'end'" class="custom-collapse">
    <nz-collapse-panel
      [nzHeader]="'Application Details'"
      nzActive="panel.active"
    >

    <ppl-lead-application-details [filledInfo]="filledInfo" [sectionData]="secData"></ppl-lead-application-details>   
      
    </nz-collapse-panel> -->

  <!-- <nz-collapse-panel
      [nzHeader]="'Demographics'"
      nzActive="panel.active"n
      [nzExtra]="extraTpl"
      class="custom-panel"
    >
  <nz-collapse [nzExpandIconPosition]="'end'">
    <nz-collapse-panel [nzHeader]="'Application Details'" nzActive="panel.active"
      *authorize="[pageId, tabId, 'ApplicationDetails', 'section']">
      <ppl-lead-application-details [filledInfo]="filledInfo" [sectionData]="secData"
        [pageId]="pageId" [tabId]="tabId"></ppl-lead-application-details>
    </nz-collapse-panel>

    <nz-collapse-panel [nzHeader]="'Demographics'" nzActive="panel.active" [nzExtra]="extraTpl"
      *authorize="[pageId, tabId, 'Demographics', 'section']">
      <ng-template #extraTpl>
        <div class="custom-row mb-0 mt-0">
          @if(filledInfo?.modifiedDate){
          <span class="font-weight-600">Last Modified Date :</span>
          <span>{{filledInfo?.modifiedDate | date:'short'}}</span>
          }
        </div>
      </ng-template>
      <ppl-lead-detail-participant-info [sectionData]="steps" [entry]="entry" (leadUpdated)="updatedLeadData($event)"
        [pageId]="pageId" [tabId]="tabId"></ppl-lead-detail-participant-info>
    </nz-collapse-panel>

    <nz-collapse-panel nzHeader="Address Information" nzActive="panel.active" [nzExtra]="extraTpl"
      *authorize="[pageId, tabId, 'AddressInformation', 'section']">
      <ng-template #extraTpl>
        <div class="custom-row mb-0 mt-0">
          @if(addressInfo?.modifiedDate){
          <span class="font-weight-600">Last Modified Date :</span>
          <span>{{addressInfo?.modifiedDate | date:'short'}}</span>
          }
        </div>
      </ng-template>
      <ppl-lead-detail-address-info [sectionData]="steps" [entry]="entry" (leadUpdated)="updatedLeadData($event)"
        [selectedLeadId]="selectedLead" [pageId]="pageId" [tabId]="tabId"></ppl-lead-detail-address-info>
    </nz-collapse-panel>

    <nz-collapse-panel nzHeader="Communication Methods" nzActive="panel.active" [nzExtra]="extraTpl"
      *authorize="[pageId, tabId, 'CommunicationMethods', 'section']">

      <ng-template #extraTpl>
        <div class="custom-row mb-0 mt-0">
          @if(contactMethodsModifiedDate){
          <span class="font-weight-600">Last Modified Date :</span> <span>{{contactMethodsModifiedDate |
            date:'short'}}</span>
          }
        </div>
      </ng-template>

      <ppl-lead-detail-comm-methods [sectionData]="steps" (leadUpdated)="updatedLeadData($event)"
        [pageId]="pageId" [tabId]="tabId"></ppl-lead-detail-comm-methods>
    </nz-collapse-panel>


    <nz-collapse-panel nzHeader="Communication Information" nzActive="panel.active" [nzExtra]="extraTpl"
      *authorize="[pageId, tabId, 'CommunicationInformation', 'section']">
      <ng-template #extraTpl>
        <div class="custom-row mb-0 mt-0">
          @if(contactDetailsInfo?.modifiedDate){
          <span class="font-weight-600">Last Modified Date :</span>
          <span>{{contactDetailsInfo?.modifiedDate | date:'short'}}</span>
          }
        </div>
      </ng-template>
      <ppl-lead-detail-communication-info [sectionData]="steps" (leadUpdated)="updatedLeadData($event)"
        [pageId]="pageId" [tabId]="tabId"></ppl-lead-detail-communication-info>
    </nz-collapse-panel>

    <nz-collapse-panel nzHeader="Identifiers" nzActive="panel.active" [nzExtra]="extraTpl"
      *authorize="[pageId, tabId, 'Identifiers', 'section']">

      <ng-template #extraTpl>
        <div class="custom-row mb-0 mt-0">
          @if(identifiersModifiedDate){
          <span class="font-weight-600">Last Modified Date :</span>
          <span>{{identifiersModifiedDate | date:'short'}}</span>
          }
        </div>
      </ng-template>
      <ppl-lead-detail-identifiers [sectionData]="steps" (leadUpdated)="updatedLeadData($event)"
        [pageId]="pageId" [tabId]="tabId"></ppl-lead-detail-identifiers>
    </nz-collapse-panel>



  </nz-collapse> -->
</div>