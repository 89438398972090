import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NzCardModule } from 'ng-zorro-antd/card';
import { SectionsService } from '../../../services/sections.service';
import { ReferralDataService } from '../../../services/referral-data.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { InnerPageLayoutComponent } from "../../core/layout/inner-page-layout/inner-page-layout.component";

@Component({
  selector: 'ppl-enrollment-list',
  standalone: true,
  imports: [NzCardModule, NzButtonModule, InnerPageLayoutComponent],
  templateUrl: './enrollment-list.component.html',
  styleUrl: './enrollment-list.component.css',
})
export class EnrollmentListComponentOld {
  constructor(
    private router: Router,
    private sectionService: SectionsService,
    private referralDataService: ReferralDataService
  ) {}

  newEnrollment() {
    this.router.navigate(['/home/newEnrollment']);
    this.sectionService.setFlow('newEnrollment');
    this.referralDataService.clearReferrralData();
  }

  goTo(flow: string) {
    this.router.navigate([`/home/newEnrollmentForm/${flow}`]);
  }
}
