/* src/app/components/participant-leads-list/participant-leads-list.component.css */
.ref-head-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-button {
  padding: 4px 5px;
}
.button-sec {
  margin-bottom: 8px;
}
.main {
  width: 96%;
  margin: auto;
}
h2 {
  font-size: 1.286rem;
}
.action {
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  font-style: italic;
  font-weight: 700;
  color: darkslategrey;
}
.table-style {
  width: 100%;
}

/* src/app/components/global-search/global-search/global-search.component.css */
/*# sourceMappingURL=global-search.component-AWDYNIKB.css.map */
