/* src/app/components/payroll/process-codes/add-process-code/add-process-code.component.css */
img {
  filter: invert(0.75);
}
.profile {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #000000;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
}
.line1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.line2 {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  color: var(--black-700-black, #121212);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Outfit;
  font-size: 2.286rem;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 24px;
}
.ppl-step-main {
  width: 100%;
  margin: auto;
}
.wiz-body {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
  padding: 12px;
}
.wiz-container {
  height: calc(100vh - 210px);
  width: 100%;
  display: flex;
  padding: 24px 28px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  border: 2px solid #FFF;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  box-sizing: border-box;
  overflow-y: scroll;
}
.wiz-container::-webkit-scrollbar {
  width: 4px;
}
.wiz-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  margin: 2px 4px;
}
.wiz-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
  margin: 2px 4px;
}
.ant-picker {
  width: 100%;
  border-radius: 8px;
  padding: 0 8px;
}
::ng-deep .ant-picker-input > input {
  border: none;
}
::ng-deep nz-input-group > .ant-input {
  height: 30px;
}
.read-text {
  word-wrap: break-word;
  white-space: normal;
}
/*# sourceMappingURL=add-process-code.component-AGLS5AL2.css.map */
