/* src/app/shared/components/side-drawer/side-drawer.component.css */
.side-drawer {
  position: fixed;
  top: -100px;
  right: -32px;
  height: calc(100vh - 8vh);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1050;
  box-sizing: border-box;
  margin: 16px;
}
.overlay {
  display: none;
}
.content {
  display: flex;
  width: 100%;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid var(--Color-1, #ffffff);
  flex-grow: 1;
  background-color: rgb(223 223 223 / 90%);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  z-index: 1051;
  box-sizing: border-box;
}
.header {
  position: sticky;
  top: 0;
  z-index: 1052;
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  font-size: 1.429rem;
  font-weight: bold;
  gap: 12px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #FFF;
  background:
    linear-gradient(
      343deg,
      #e6e6e60d -18.21%,
      #f3f3f34f 44.63%,
      #eaeaea 104.38%);
  box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.12);
}
.body {
  display: flex;
  flex-grow: 1;
  padding: 12px 12px 32px 12px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.body-content {
  align-self: stretch;
  color: var(--Color-9, #333);
  font-family: Outfit;
  font-size: 1.429rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  max-height: calc(100vh - 180px);
}
.body-content::-webkit-scrollbar {
  display: none;
}
.footer {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.action-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
}
/*# sourceMappingURL=side-drawer.component-GEJS6MME.css.map */
