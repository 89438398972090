{
    "sectionId": "authorizations",
    "header": "Authorizations",
    "available_actions": null,
    "attributes": [
      {
        "attribute_ID": "consumerFirstName",
        "fieldName": "consumerFirstName",
        "label": "First Name",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter consumer first name",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "consumerLastName",
        "fieldName": "consumerLastName",
        "label": "Last Name",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter consumer Last Name",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "medicaid",
        "fieldName": "medicaid",
        "label": "CIN",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter medicaid",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "consumerPPLId",
        "fieldName": "consumerPPLId",
        "label": "PPL ID",
        "type": "select",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter PPl Id",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "clientAuth",
        "fieldName": "clientAuth",
        "label": "Authorization number",
        "type": "input",
        "mandatory": "Y",
        "validator": null,
        "tooltip": "Please enter Client Auth Number",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "serviceCode",
        "fieldName": "serviceCode",
        "label": "Service Code",
        "type": "input",
        "mandatory": "Y",
        "validator": null,
        "tooltip": "Please select Service Code",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "diagnosisId",
        "fieldName": "diagnosisId",
        "label": "Primary Diagnosis code",
        "type": "input",
        "mandatory": "Y",
        "validator": null,
        "tooltip": "Please select Primary Diagnosis Code",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "hiosIdLkp",
        "fieldName": "hiosIdLkp",
        "label": "HIOS ID",
        "type": "select",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter HIOS Id",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "planId",
        "fieldName": "planId",
        "label": "Plan ID",
        "type": "select",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter Plan Id",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "svcAdministration",
        "fieldName": "svcAdministration",
        "label": "Service Administration",
        "type": "select",
        "mandatory": "Y",
        "validator": null,
        "tooltip": "Please enter PPl Id",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "modifierCode1",
        "fieldName": "modifierCode1",
        "label": "Modifier",
        "type": "select",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please select Modifier",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "units",
        "fieldName": "units",
        "label": "Units",
        "type": "input",
        "mandatory": "Y",
        "validator": null,
        "tooltip": "Please enter Units",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "unitType",
        "fieldName": "unitType",
        "label": "Unit Type",
        "type": "select",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter Unit Type",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "startDate",
        "fieldName": "startDate",
        "label": "Auth Start Date",
        "type": "input",
        "mandatory": "Y",
        "validator": null,
        "tooltip": "Please enter Auth Start Date",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "endDate",
        "fieldName": "endDate",
        "label": "Auth End Date",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter Auth End Date",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "orgName",
        "fieldName": "orgName",
        "label": "MCO/Agency Name",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter MCO/Agency ID",
        "isAvailable": "Y"
      }
    ],
    "validations": [
      {
        "whatToCheck": "atLeastXEntered",
        "clientOrServer": "C",
        "attributes": [
          {
              "validatorid": 1,
              "attribute_ID": ["clientAuth"],
              "functionParams": {
                "ErrMsg": "Please enter Client Authorization Number."
              }
            },
            {
              "validatorid": 2,
              "attribute_ID": ["serviceCode"],
              "functionParams": {
                "ErrMsg": "Please select Service Code"
              }
            },
            {
              "validatorid": 3,
              "attribute_ID": ["diagnosisId"],
              "functionParams": {
                "ErrMsg": "Please select Primary Diagnosis Code"
              }
            },
            {
              "validatorid": 4,
              "attribute_ID": ["units"],
              "functionParams": {
                "ErrMsg": "Please enter Units"
              }
            },
            {
              "validatorid": 5,
              "attribute_ID": ["startDate"],
              "functionParams": {
                "ErrMsg": "Please select Start Date"
              }
            },
            {
              "validatorid": 6,
              "attribute_ID": ["svcAdministration"],
              "functionParams": {
                "ErrMsg": "Please select Service Administration"
              }
            }
      ]
      },
      {
        "whatToCheck": "checkFieldValue",
        "clientOrServer": "C",
        "attributes": []
      },
      {
        "whatToCheck": "conditionalCheck",
        "clientOrServer": "C",
        "attributes": []
      },
      {
        "whatToCheck": "customValidator",
        "clientOrServer": "C",
        "attributes": []
      }
    ],
    "actions": [
      {
        "Name": "Previous",
        "Validate": null
      },
      {
        "Name": "Submit",
        "Validate": [
          {
            "checkType": "atLeastXEntered",
            "validatorid": [1,2,3,4,5,6]
          },
          {
            "checkType": "checkFieldValue",
            "validatorid": []
          },
          {
            "checkType": "conditionalCheck",
            "validatorid": []
          },
          {
            "checkType": "customValidator",
            "validatorid": []
          }
        ]
      }
    ]
  }
  