import { NotificationService } from './shared/services/notification.service';
import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef, HostListener } from '@angular/core';
/* import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar'; */
import { Router, RouterLink, RouterOutlet } from '@angular/router';

import {
  InteractionStatus,
  RedirectRequest,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import { AuthService } from './services/auth.service';
import { LoaderComponent } from "./shared/components/loader/loader.component";
import { HeaderComponent } from './components/core/layout/header/header.component';
import { FooterComponent } from './components/core/layout/footer/footer.component';
import { UserService } from './services/user.service';
import { AlertboxComponent } from "./shared/components/alertbox/alertbox.component";
import { AlertDetails, ErrorAlertService } from './shared/services/error-alert.service';
import { ToasterComponent } from './shared/components/toaster/toaster.component';
import { DialogComponent } from './shared/components/dialog/dialog.component';

@Component({
  selector: 'ppl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    MsalModule,
    RouterOutlet,
    RouterLink,
    /* MatToolbarModule,
    MatButtonModule,
    MatMenuModule, */
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    AlertboxComponent,
    ToasterComponent,
    DialogComponent
],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'PPL CareConnect';
  isIframe = false;
  isAuthenticated = false;
  showLoader = false;
  private readonly _destroying$ = new Subject<void>();
  isAlertVisible  = false;
  alertDetails: AlertDetails = {type: 'error', title: '', message: '', isVisible: false};
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private appAuthService : AuthService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private authorizationService: AuthService,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
    private userService : UserService,
    private errorAlertService: ErrorAlertService,
  ) { }

  ngOnInit(): void {
    this.showLoader = false;
    this.notificationService.loaderSubject$.pipe(
      tap((value) => {
        this.showLoader = value;
        this.cdr.detectChanges(); // trigger change detection and update the view.
      })
    ).subscribe();

    this.errorAlertService.getAlertDetails().subscribe((details) => {
      this.alertDetails = details;
      console.log('Alert Details', details);
    });

    this.authService.handleRedirectObservable().subscribe();
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.authService.instance.enableAccountStorageEvents();
    this.isLoggedIn();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        this.isLoggedIn();
        this.checkAndSetActiveAccount();
        const payload = result?.payload;
        const token = (!!payload && 'accessToken' in payload) ? payload.accessToken : '';
        //setting token here because b2c aquiring token having delay TODO: Fix it 
        localStorage.setItem("access_token", token);
        this.authorizationService.setToken();
        this.router.navigate(['/']);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        if (!this.isAuthenticated) {
          this.loginRedirect();
        }
      });
  }

  isLoggedIn() {
    this.isAuthenticated = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.authService.logoutRedirect();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  @HostListener('window:beforeunload', ['$event'])
      clearLocalStorage() {
        localStorage.clear();
        localStorage.setItem('clearing_local_storage_date_time', new Date().toLocaleString());
      }
}
