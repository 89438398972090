import { Injectable } from '@angular/core';
import { HttpRequestService } from '../shared/services/http-request.service';
import { formatUrl } from '../shared/utils/format-url-util';
import { ENDPOINTS } from '../shared/constants/url.constants';
import { ApiResponse } from '../shared/interfaces/response.interface';
import { catchError, Subject, throwError } from 'rxjs';
import { GlobalErrorHandlerService } from '../shared/services/global-error-handler.service';
import { MailForm, SendMailFormPostItem } from '../interfaces/mailform.interface';

@Injectable({
  providedIn: 'root'
})
export class MailFormService {

  private refreshSubject = new Subject<void>();

  constructor(
    private httpClient: HttpRequestService, 
    private errorHandler: GlobalErrorHandlerService
  ) {}

  getMailForms(program_code: string, id: string, entityType: string){
    const endpoint = formatUrl(ENDPOINTS.GET_MAIL_FORMS, {program_code, entityType, id});
    
    return this.httpClient.get<ApiResponse<MailForm>>(endpoint).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }
  
  sendMailForms(formData: SendMailFormPostItem[], program_code: string, id: string, entityType: string){
    const endpoint = formatUrl(ENDPOINTS.SEND_MAIL_FORMS, {program_code, entityType, id});
    return this.httpClient.post(endpoint, formData, true).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  // Expose the observable part of the Subject
  get refresh$() {
    return this.refreshSubject.asObservable();
  }

  // Method to trigger notifications
  triggerRefresh() {
    this.refreshSubject.next();
  }
}