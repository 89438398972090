/* src/app/shared/components/notifications/notifications.component.css */
.notification-dropdown {
  position: relative;
  display: inline-block;
  margin-right: 12px;
}
.notification-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
}
.notification-button .fa-bell {
  font-size: 1.286rem;
  color: #ffffff;
}
h4 {
  background: #ffffff;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
}
.ico {
  margin-right: 8px;
}
.notification-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.571rem;
}
.dropdown-menu {
  position: absolute;
  right: 0;
  display: flex;
  width: 425px;
  padding: 12px;
  flex-direction: column;
  align-items: start;
  gap: 12px;
  border-radius: 12px;
  border: 2px solid #ECECEC;
  background: rgba(234, 234, 234, 0.46);
  box-shadow: 0px 0px 104px 0px rgba(0, 0, 0, 0.10) inset, -4px 4px 24px 0px rgba(0, 0, 0, 0.45);
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  z-index: 1000;
}
.dropdown-header {
  font-weight: bold;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
li {
  padding: 12px;
  border-radius: 8px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #f9f9f9;
  margin-bottom: 0.5rem;
}
.notification-title {
  display: block;
  font-size: 1rem;
  font-weight: bold;
}
.notification-time {
  font-size: 0.857rem;
  color: #777;
}
.view-all-btn {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}
.view-all-btn:hover {
  background-color: #0056b3;
}
/*# sourceMappingURL=notifications.component-TIR57KKT.css.map */
