/* src/app/components/leads/pdf-form/pdf-form.component.css */
.left-panel {
  width: 20%;
}
.right-panel {
  width: 80%;
}
::ng-deep nz-collapse-panel {
  border: none !important;
}
::ng-deep .ant-collapse-header {
  background-color: #004c4a !important;
  color: white !important;
  border-radius: 8px !important;
}
.ppl-main-fullwidth {
  margin-top: 10px;
}
.collapsible .content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}
.collapsible .active .content {
  max-height: 100px;
}
.expand-collapse-container {
  box-sizing: border-box;
  padding: 8px;
  background-color: white;
  border-radius: 8px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: 9px;
  background: rgb(255 255 255);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
}
.nz-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: #f0f1f5;
  color: #005f73;
  padding: 16px;
  border-radius: 8px;
  font-weight: bold;
}
.nz-collapse > .ant-collapse-item-active > .ant-collapse-header {
  background-color: #00b4d8;
  color: #ffffff;
  border-radius: 8px 8px 0px 0px !important;
}
.nz-collapse .ant-collapse-arrow {
  float: right;
  color: #005f73;
}
.ant-collapse > .ant-collapse-item {
  border-radius: 8px !important;
}
.ant-collapse-content {
  color: rgb(0 0 0 / 85%) !important;
  background-color: #e0e0e0 !important;
  border-top: none !important;
  border-radius: 0px 0px 8px 8px !important;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  background-color: #00b4d8;
  color: #ffffff;
  border-radius: 4px 4px 0px 0px !important;
}
.btn {
  width: 110px;
  display: inline-block;
}
.btn-cancel {
  width: 80px;
  display: inline-block;
}
.ant-collapse {
  border: 2px solid #004c4a !important;
  border-radius: 8px !important;
}
.error-message {
  color: #272323;
  background: #f8d7da;
  padding: 6px 10px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
  width: 25%;
}
.left-panel {
  width: 20%;
}
.right-panel {
  width: 80%;
}
::ng-deep nz-collapse-panel {
  border: none !important;
}
::ng-deep .pending .ant-collapse-header {
  background-color: #6fa7e9d6 !important;
  color: white !important;
  border-radius: 8px !important;
  border-color: #6fa7e9d6 !important;
}
::ng-deep .completed .ant-collapse-header {
  background-color: #004c4a !important;
  color: white !important;
  border-radius: 8px !important;
  border-color: #004c4a !important;
}
.ppl-main-fullwidth {
  margin-top: 10px;
}
.collapsible .content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}
.collapsible .active .content {
  max-height: 100px;
}
.expand-collapse-container {
  box-sizing: border-box;
  padding: 8px;
  background-color: white;
  border-radius: 8px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: 9px;
  background: rgb(255 255 255);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
}
.nz-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: #f0f1f5;
  color: #005f73;
  padding: 16px;
  border-radius: 8px;
  font-weight: bold;
}
.nz-collapse > .ant-collapse-item-active > .ant-collapse-header {
  background-color: #00b4d8;
  color: #ffffff;
  border-radius: 8px 8px 0px 0px !important;
}
.nz-collapse .ant-collapse-arrow {
  float: right;
  color: #005f73;
}
.ant-collapse > .ant-collapse-item {
  border-radius: 8px !important;
}
.ant-collapse-content {
  color: rgb(0 0 0 / 85%) !important;
  background-color: #e0e0e0 !important;
  border-top: none !important;
  border-radius: 0px 0px 8px 8px !important;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  background-color: #00b4d8;
  color: #ffffff;
  border-radius: 4px 4px 0px 0px !important;
}
.btn {
  width: 110px;
  display: inline-block;
}
.btn-cancel {
  width: 80px;
  display: inline-block;
}
.completed-ant-collapse {
  border: 2px solid #004c4a !important;
  border-radius: 8px !important;
}
.pending-ant-collapse {
  border: 2px solid #6fa7e9d6 !important;
  border-radius: 8px !important;
}
.error-message {
  color: #272323;
  background: #f8d7da;
  padding: 6px 10px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
  width: 30%;
}
.checklist-question {
  padding: 6px 15px !important;
  border-radius: 5px;
}
.question-text {
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 6px !important;
  display: inline-block;
  width: 400px;
}
.ppl-radio-group {
  justify-content: left !important;
}
.question-form {
  width: 200px;
}
.question-body {
  display: inline-block;
  width: 350px;
}
.checklist-header-item {
  display: flex;
  justify-content: space-between;
}
.medium-icon-size {
  font-size: medium;
}
.action-panel {
  display: flex;
  padding: 0.5rem;
  justify-content: flex-end;
  gap: 0.5rem;
}
/*# sourceMappingURL=pdf-form.component-UP3E4WUS.css.map */
