import { Component, Input } from '@angular/core';
import { AddressComponent } from '../../../../shared/components/address/address.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

@Component({
  selector: 'ppl-enrollment-address',
  standalone: true,
  imports: [  NzFormModule,
    NzInputModule,
    NzSelectModule,
    NzIconModule,
    FormsModule,
    ReactiveFormsModule,
    NzSwitchModule,
    CommonModule],
  templateUrl: './enrollment-address.component.html',
  styleUrl: './enrollment-address.component.css'
})
export class EnrollmentAddressComponent extends AddressComponent{
  @Input() formReadonly = false;
}
