import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ListTableComponent } from "../../../../shared/components/list-table/list-table.component";
import { BaseComponent } from '../../../../shared/utils/base.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SectionsService } from '../../../../services/sections.service';
import { LookupService } from '../../../../services/lookup.service';
import { AddressComponent } from '../../../../shared/components/address/address.component';
import { LeadDetailsService } from '../../../../services/lead-details.service';
import { LeadDetailsAddressComponent } from "../../../../shared/components/lead-details-address/lead-details-address.component";
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { performValidation } from '../../../../shared/utils/validation.util';
import { AuthorizeDirective } from '../../../../shared/authorization/authorize.directive.';
import { RADIO_YES_NO_OPTIONS, STATIC_DATA } from '../../../../shared/constants/static-data.constants';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from "../../../../shared/components/button/button.component";

@Component({
  selector: 'ppl-lead-detail-address-info',
  standalone: true,
  imports: [ListTableComponent,
    FormsModule,
    CommonModule,
    NgZorroModule,
    ReactiveFormsModule,
    AddressComponent,
    LeadDetailsAddressComponent,
    AuthorizeDirective, ButtonComponent],
  templateUrl: './lead-detail-address-info.component.html',
  styleUrl: './lead-detail-address-info.component.css'
})
export class LeadDetailAddressInfoComponent extends BaseComponent implements OnInit, OnChanges {

  mode = "read";
  filledInfo!: any;
  contactForm!: FormGroup;
  communicationType: any[]=[];
  steps: any;
  statesList: any;
  validationErrorsPermanentAdd = {};
  validationErrorsMailingAdd = {};
  approvedStatus: string = STATIC_DATA.APPROVED;
  radioOptions = RADIO_YES_NO_OPTIONS;
  leadStatus = '';

  @Input() pageId = '';
  @Input() tabId = '';
  @Input() programCode  = '';

  @Input() set sectionData(data: any) {
    this.steps = data
  }

  @Input() entry!: string;
  @Input() selectedLeadId!: number; 
  
  @Output() leadUpdated = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private sectionService: SectionsService,
  private lookupService: LookupService, private leadDetailsService: LeadDetailsService, ) {
    super();
  }


ngOnInit(): void {
 

  this.createFormGroup();

  this.statesList = this.lookupService.getLookupValue('STATE');
  this.leadDetailsService.getLeadStatus().subscribe((status: string) => {
    this.leadStatus = status;
    console.log('Lead Status', status);
  });

  if (this.mode == "read") {
    this.leadDetailsService.getUpdatedLeadData().subscribe(
      (data)=> {
        this.filledInfo = data?.responsedata?.communicationData;
        if(this.filledInfo){
          this.filledInfo['residentGt18'] = data?.responsedata?.infoData?.residentGt18;
        }
        
        
      }
    )
  }

    this.communicationType = this.lookupService.getLookupValue("Communication_Type");

}

ngOnChanges(changes: SimpleChanges){
  try{
    if(changes['sectionData'] && changes['sectionData'].currentValue){
      this.secData = this.steps['communication_data_address'];
    }
  }catch(e){
    console.log('Error in getting section data and updating latest info', e);
  }
}

// ngOnDestroy(): void {
//   unsubscribe to ensure no memory leaks
// }


createFormGroup(): void {
  this.contactForm = this.fb.group({
    leadId : [],
    residentGt18: [null],
    permanentAddress: this.fb.group({
      leadId : [],
      street1: [],
        street2: [],
        city: [],
        state: [''],
        zipCode: [],
        countyOrRegion: [],
      psd_code: [''],
      mailing: [false]
    }),
    mailingAddress: this.fb.group({
      leadId : [],
      street1: [],
        street2: [],
        city: [],
        state: [],
        zipCode: [],
        countyOrRegion: [],
      psd_code: [''],
      mailing : [true]
    })
  });
}

action(event : any){
  console.log("acto", event)
}

cancelEdit(){
  this.mode = 'read';
  this.contactForm.reset();
}

getValue(code:string){
  return this.lookupService.getLookupName(this.communicationType, code);
}

getFormGroup(controlName: string): FormGroup {
  return this.contactForm.get(controlName) as FormGroup;
}


editInfo() {
  this.mode = 'edit';
  this.contactForm.patchValue(this.filledInfo);
  this.contactForm.controls['leadId'].setValue(this.filledInfo?.permanentAddress?.leadId || this.selectedLeadId);
}


save(){

  const formData = this.contactForm.getRawValue();

  if(formData.permanentAddress.mailing){
    delete formData.mailingAddress;
    this.validationErrorsMailingAdd = {};
  }

  try{
    this.validationErrorsPermanentAdd = performValidation(formData.permanentAddress, this.secData, 'Next');
    if(!formData.permanentAddress.mailing){
      this.validationErrorsMailingAdd = performValidation(formData.mailingAddress, this.secData, 'Next');
    }
  } catch {
    console.log("Error in validations");
  }
  
  // save data to server if no validation errors
  if(Object.keys(this.validationErrorsPermanentAdd).length == 0 && Object.keys(this.validationErrorsMailingAdd).length == 0){
    
    const payload = this.preparePayload(formData);
    // save data to server 
    this.leadDetailsService.updateLeadAddress(payload, this.entry).subscribe(
      {
        next: () => {
          this.leadUpdated.emit(true);
          this.cancelEdit();
        },
        error: () => {
          this.leadUpdated.emit(true);
          this.cancelEdit();
        }
      }
    )
  }
  
}


preparePayload(source:any) {
  
  let payload : any = {...source};

  payload = {...payload, 
              id: this.selectedLeadId, 
              programCode : this.programCode
            };
  
  if(this.filledInfo?.permanentAddress?.modifiedDate && payload?.permanentAddress) {
    payload =  {...payload,
                permanentAddress: {...payload.permanentAddress, 
                                    modifiedDate: this.filledInfo.permanentAddress.modifiedDate
                                  }
              };
  }
  if(this.filledInfo?.mailingAddress?.modifiedDate && payload?.mailingAddress) {
    payload =  {...payload,
                mailingAddress: {...payload.mailingAddress, 
                                    modifiedDate: this.filledInfo.mailingAddress.modifiedDate
                                  }
              };
  }

  return payload;

}

}
