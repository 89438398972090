import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { ERROR_MESSAGES } from '../shared/constants/error-message.constants';
import { STATIC_DATA } from '../shared/constants/static-data.constants';
import { ENDPOINTS } from '../shared/constants/url.constants';
import { EnrollmentSummaryResponse } from '../shared/interfaces/lead-details.service-types';
import { GlobalErrorHandlerService } from '../shared/services/global-error-handler.service';
import { HttpRequestService } from '../shared/services/http-request.service';
import { SharedDataService } from '../shared/services/shared-data-service';
import { formatUrl } from '../shared/utils/format-url-util';
import { NotificationService } from './../shared/services/notification.service';
import { DownloadServiceService } from './download-service.service';
import { SectionsService } from './sections.service';
import { SearchProvidersPayload, SearchProvidersResponse } from '../shared/interfaces/provider.model';
import { SearchParticipantsPayload, SearchParticipantsResponse } from '../shared/interfaces/participant.model';

@Injectable({
  providedIn: 'root',
})
export class LeadDetailsService {
  private selectedLeadId: number | null = null;

  private previousData$ = new BehaviorSubject<any>(null);
  private leadStatus$ = new BehaviorSubject<any>('');

  constructor(
    private http: HttpRequestService,
    private notificationService: NotificationService,
    private errorHandler: GlobalErrorHandlerService,
    private downloadServiceService: DownloadServiceService,
    private httpClient: HttpClient,
    private sharedService: SharedDataService,
    private sectionService: SectionsService,
  ) { }

  setLeadStatus(newStatus: string): void {
    this.leadStatus$.next(newStatus)
  }

  getLeadStatus(): Observable<any> {
    return this.leadStatus$.asObservable();
  }

  getLeadDetails(
    flow: string,
    program_code: string,
    id: number,
    isAfterUpdate = false
  ): Observable<any> {
    if (this.selectedLeadId === null || this.selectedLeadId != id || isAfterUpdate) {
      return this.fetchLeadDetailsApi(flow, program_code, id, isAfterUpdate);
    } else {
      return this.previousData$.asObservable();
    }
  }

  fetchLeadDetailsApi(
    entry: string,
    program_code: string,
    participant_lead_id: number,
    isAfterUpdate = false
  ): Observable<any> {
    const flow = entry.charAt(0).toUpperCase() + entry.slice(1).toLowerCase();
    /* console.log(flow); */

    let url;
    /* console.log(url); */
    if (entry == STATIC_DATA.REFERRAL) {
      url = formatUrl(
        ENDPOINTS.GET_REFERRAL_DATA,
        { program_code, participant_lead_id },
        this.notificationService
      );
      /* console.log(url); */
    } else if (entry == STATIC_DATA.PARTICIPANT_ENROLLMENT) {
      url = formatUrl(
        ENDPOINTS.GET_PARTICIPANT_ENROLLMENT_INFO,
        { program_code, participant_lead_id },
        this.notificationService
      );
    } else if (entry == STATIC_DATA.PROVIDER_ENROLLMENT) {
      const provider_lead_id = participant_lead_id;
      url = formatUrl(
        ENDPOINTS.GET_PROVIDER_ENROLLMENT_INFO,
        { program_code, provider_lead_id },
        this.notificationService
      );
    }
    else {
      url = formatUrl(
        ENDPOINTS.GET_LEAD_DATA,
        { flow, program_code, participant_lead_id },
        this.notificationService
      );
      /* console.log(url); */
    }
    return this.http.get(url, isAfterUpdate).pipe(
      tap((response) => {
        if (entry == STATIC_DATA.PROVIDER_ENROLLMENT) {
          const data: any = response;
          data.responsedata.infoData = data.responsedata.basicInfo;
          data.responsedata.communicationData = data.responsedata.contactDetails;
          data.responsedata.contactPrefernceData = data.responsedata.communicationPreferences;
          this.previousData$.next(response); // Cache the response
          this.selectedLeadId = participant_lead_id; // Update the previous ID
        } else {
          this.previousData$.next(response); // Cache the response
          this.selectedLeadId = participant_lead_id; // Update the previous ID
        }
      }),
      catchError((err) => {
        this.previousData$.next(null as any); // Clear the cache
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  fetchLeadSignatureDetailsApi(program_code: string, id: any, type: string, readReplica = false): Observable<any> {
    // const type = entry.charAt(0).toUpperCase() + entry.slice(1).toLowerCase();
    /* console.log(program_code, id); */
    const url =
      formatUrl(
        ENDPOINTS.GET_SIGNATURE, { program_code, type, id }
      );
    return this.http.get(url, readReplica).pipe(
      catchError(err => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  saveSignature(data: any, readReplica = false): Observable<any> {
    return this.http.post(ENDPOINTS.SAVE_SIGNATURE, data, readReplica);
  }

  fetchLeadProspectiveParticipants(
    program_code: string,
    id: number, rfrwRequest = false
  ): Observable<any> {
    const url = formatUrl(ENDPOINTS.GET_PROSPECTIVE_PARTICIPANTS, {
      program_code,
      id,
    });
    return this.http.get(url, rfrwRequest).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  getViewerData(
    program_code: string,
    id: string
  ): Observable<any> {
    const url = formatUrl(ENDPOINTS.GET_VIEWER_DATA, {
      program_code,
      id,
    });
    return this.http.get(url).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  getViewerUsers(
    program_code: string,
    orgName: string,
    orgType: string
  ): Observable<any> {
    const url = formatUrl(ENDPOINTS.GET_VIEWER_USERS, {
      program_code,
      orgName,
      orgType
    });
    return this.http.get(url).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  
  createViewer(data: any, program_code: string, lead_id: string, readReplica = false) {
    const url = formatUrl(ENDPOINTS.SAVE_PARTICIPANT_VIEWER, {program_code, lead_id});
    return this.http.post(url, data, readReplica).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_PARTICIPANT_VIEWER
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  updateViewer(data: any, program_code: string, record_id: string): Observable<any> {
    const url = formatUrl(ENDPOINTS.UPDATE_PARTICIPANT_VIEWER, { program_code, record_id });

    return this.http.patch(url, data).pipe(
      tap(() => { 
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_PARTICIPANT_VIEWER_UPDATE
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  updateInActivateViewer(data: any, program_code: string, record_id: string): Observable<any> {
    const url = formatUrl(ENDPOINTS.INACTIVE_PARTICIPANT_VIEWER, { program_code, record_id });

    return this.http.patch(url, data).pipe(
      tap(() => { 
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_PARTICIPANT_VIEWER_INACTIVE
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  getEnrollmentData(
    program_code: string,
    id: string
  ): Observable<any> {
    const url = formatUrl(ENDPOINTS.GET_ENROLLMENT_STATUS_DATA, {
      program_code,
      id,
    });
    return this.http.get(url).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  createEnrollmentStatus(data: any, program_code: string, lead_id: string, readReplica = false) {
    const url = formatUrl(ENDPOINTS.SAVE_ENROLLMENT_STATUS, {program_code, lead_id});
    return this.http.post(url, data, readReplica).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_PARTICIPANT_ENROLLMENT_STATUS_SAVE
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  updateEnrollmentStatus(data: any, program_code: string, participantId: string): Observable<any> {
    const url = formatUrl(ENDPOINTS.UPDATE_ENROLLMENT_DATA, { program_code, participantId });

    return this.http.patch(url, data).pipe(
      tap(() => { 
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_PARTICIPANT_ENROLLMENT_STATUS_UPDATE
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  addParticipantProviderAssociation(data: any, rfrwRequest = false) {
    return this.http.post(ENDPOINTS.ADD_PARTICIPANT_PROVIDER_ASSOCIATION, data, rfrwRequest).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_PARTICIPANT_PROVIDER_ASSOCIATION
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  getUpdatedLeadData(): Observable<any> {
    return this.previousData$.asObservable();
  }

  updateLeadDemographics(data: any, flow: string): Observable<any> {
    const url = formatUrl(ENDPOINTS.UPDATE_LEAD_DEMOGRAPHICS, { flow });

    return this.http.patch(url, data).pipe(
      tap(() => { 
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_UPDATE_LEAD_DEMOGRAPHICS
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  updateLeadAddress(data: any, flow: string): Observable<any> {
    const url = formatUrl(ENDPOINTS.UPDATE_LEAD_ADDRESS, { flow });
    return this.http.patch(url, data).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_UPDATE_LEAD_ADDRESS
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  createUpdateLeadCommunicationMethods(
    data: any,
    flow: string
  ): Observable<any> {
    const url = formatUrl(ENDPOINTS.CREATE_UPDATE_LEAD_COMMUNICATION_METHODS, {
      flow,
    });
    return this.http.patch(url, data).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_ADD_UPDATE_LEAD_COMM_METHODS
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  createUpdateLeadIdentifier(data: any, flow: string): Observable<any> {
    const url = formatUrl(ENDPOINTS.CREATE_UPDATE_LEAD_IDENTIFIERS, { flow });
    return this.http.patch(url, data).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_ADD_UPDATE_LEAD_IDENTIFIER
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  deleteLeadIdentifier(id: any, flow: string, program_code: string): Observable<any> {
    const url = formatUrl(ENDPOINTS.DELETE_LEAD_IDENTIFIER, { flow, program_code, id });
    return this.http.delete(url).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_DELETE_LEAD_IDENTIFIER
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  deleteLeadCommunicationMethod(id: any, flow: string, program_code: string): Observable<any> {
    const url = formatUrl(ENDPOINTS.DELETE_LEAD_COMMUNICATION_METHODS, {
      flow,
      program_code,
      id,
    });
    return this.http.delete(url).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_DELETE_LEAD_COMM_METHODS
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  updateLeadCommunicationInfo(data: any, flow: string): Observable<any> {
    const url = formatUrl(ENDPOINTS.UPDATE_LEAD_COMMUNICATION_INFO, { flow });
    return this.http.patch(url, data).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_UPDATE_LEAD_COMM_INFO
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  addLeadComments(data: any, flow: string): Observable<any> {
    const url = formatUrl(ENDPOINTS.CREATE_LEAD_COMMENTS, { flow });
    return this.http.patch(url, data).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_ADD_LEAD_COMMENTS
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  getLeadAuditHistory(
    program_code: string,
    flow: string,
    id: string
  ): Observable<any> {
    /* program_code = program_code == "Pre_Ref" ? 'ngdev' : program_code; */
    /* const flow = (type == 'referral') ? 'refferalparticipant' : type; */

    const url = formatUrl(ENDPOINTS.GET_AUDIT_COMMENTS, {
      program_code,
      flow,
      id,
    });
    return this.http.get(url).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  deletLeadDocumentFile(program_code: string,
    flow: string,
    id: string,
    createType: string): Observable<any> {
    console.log('Create Type', createType);
    // let url = ENDPOINTS.DELETE_LEAD_DOCUMENT_FILE;
    const url = formatUrl(ENDPOINTS.GET_AUDIT_COMMENTS, {
      program_code,
      flow,
      id,
      createType
    });
    console.log('URL in service', url);
    return this.http.get(url).pipe(
      tap(() => {
        this.notificationService.success("Success", ERROR_MESSAGES.SUCCESS_UPDATE_LEAD_COMM_INFO);
      }),
      catchError(err => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  getCommunicationLog(
    program_code: string,
    flow: string,
    id: number
  ): Observable<any> {
    
    const url = formatUrl(ENDPOINTS.GET_COMMUNICATION_LOGS, {
      program_code,
      flow,
      id,
    });
    return this.http.get(url).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  handleSuccessNotification(msg: string) {
    this.notificationService.alert('success', 'Success', msg);
  }

  searchProspectiveParticipantDetails(data: any, rfrwRequest = false) {
    const url = formatUrl(ENDPOINTS.SEARCH_PARTICIPANT_LEAD_DATA, {});
    return this.http.post(url, data, rfrwRequest).pipe(
      tap(() => {
        console.log('Search fetched');
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  addUpdateProspectiveParticipantDetails(data: any) {
    const url = formatUrl(ENDPOINTS.ADD_UPDATE_PROSPECTIVE_PARTICIPANT, {});
    return this.http.patch(url, data).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_PROSPECTIVE_PARTICIPANTS_ADD
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  deleteProspectiveParticipantDetails(id: number, program: string) {
    const url = formatUrl(ENDPOINTS.DELETE_PROSPECTIVE_PARTICIPANT, { id, program });
    return this.http.delete(url).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_PROSPECTIVE_PARTICIPANTS_DELETE
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  updateLeadServicesRendered(data: any, flow: string): Observable<any> {
    const url = formatUrl(ENDPOINTS.UPDATE_PROVIDER_SERVICES_RENDERED, { flow });
    return this.http.patch(url, data).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_UPDATE_SERVICES
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  updateLeadProfessionalLicences(data: any, flow: string): Observable<any> {
    const url = formatUrl(ENDPOINTS.UPDATE_PROVIDER_PROF_LICENCES, { flow });
    return this.http.patch(url, data).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_UPDATE_PROF_LICENCES
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  // TODO: fix types for error scenario
  getEnrollmentSummary(
    program_code: string,
    participant_lead_id: number,
    user_type: string
  ): Observable<EnrollmentSummaryResponse | object> {
    const url = formatUrl(ENDPOINTS.GET_ENROLLMENT_SUMMARY, {
      program_code,
      participant_lead_id,
      user_type
    });
    return this.http.get(url).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  getAdditionalAttributes(programCode: string,
    enrollmentType: string,
    id: number, isAfterUpdate = false): Observable<object>{
    const url = formatUrl(ENDPOINTS.GET_ADDITIONAL_ATTRIBUTES, {
      programCode,
      enrollmentType,
      id
    });
    return this.http.get(url, isAfterUpdate).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    ); 
  }

  saveUpdateAttribute(data: any): Observable<any> {
    const url = formatUrl(ENDPOINTS.SAVE_ADDITIONAL_ATTRIBUTES, { });
    return this.http.post(url, data, true).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_ADDITIONAL_ATTRIBUTE_ADD
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  deleteAdditionalAttributes(programCode: string, enrollmentType : string, id: number ) {
    const url = formatUrl(ENDPOINTS.DELETE_ADDITIONAL_ATTRIBUTES, {programCode, enrollmentType, id });
    return this.http.delete(url).pipe(
      tap(() => {
        this.notificationService.success(
          'Success',
          ERROR_MESSAGES.SUCCESS_ADDITIONAL_ATTRIBUTE_DELETE
        );
      }),
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }


  getParticipantAuthorizations(
    payload : any
  ): Observable<object> {
    const url = ENDPOINTS.GET_LIST_PAGE_DATA;
    return this.http.post(url, payload).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }
  

  getEntry(entry : string) : string{
    let userEntry = '';
    if(entry == STATIC_DATA.REFERRAL){
      userEntry = 'refferalparticipant';
    } else if(entry === STATIC_DATA.PARTICIPANT_ENROLLMENT){
      userEntry = 'prtcpntenrlmnt';
    } else if(entry === STATIC_DATA.PROVIDER_ENROLLMENT){
      userEntry = 'providerenrlmnt';
    }else{
       userEntry = entry;
    }
    return userEntry;
  }

  searchProvidersByQuery(query: SearchProvidersPayload): Observable<SearchProvidersResponse | object> {
    const url = ENDPOINTS.ENROLLMENT.GET_PROVIDERS_BY_SEARCH;
    return this.http.post(url, query).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  searchParticipantsByQuery(query: SearchParticipantsPayload): Observable<SearchParticipantsResponse | object> {
    const url = ENDPOINTS.ENROLLMENT.GET_PARTICIPANTS_BY_SEARCH;
    return this.http.post(url, query).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  fetchProviderDetailsById(providerPPLId: string) {
    
  }

}
