[
    {
      "stepname_id": 1,
      "step_name": "Registration Information",
      "isAvailable": "Y",
      "step_id": "participant_info"
    },
    {
      "stepname_id": 2,
      "step_name": "Service Details",
      "isAvailable": "N",
      "step_id": "service_details"
    },

    {
      "stepname_id": 3,
      "step_name": "Contact Details",
      "isAvailable": "Y",
      "step_id": "contact_details"
    },
    {
      "stepname_id": 4,
      "step_name": "Communication Preferences",
      "isAvailable": "Y",
      "step_id": "comm_preferences"
    },
    {
      "stepname_id": 5,
      "step_name": "/medicaid Documents",
      "isAvailable": "N",
      "step_id": "upload_docs"
    },
    {
      "stepname_id": 6,
      "step_name": "Associations",
      "isAvailable": "N",
      "step_id": "associations"
    },
    {
        "stepname_id": 7,
        "step_name": "Payment Details",
        "isAvailable": "Y",
        "step_id": "payment_details"
      },
    {
      "stepname_id": 8,
      "step_name": "Summary",
      "isAvailable": "Y",
      "step_id": "summary"
    }
  ]
  