import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'ppl-toaster',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './toaster.component.html',
  styleUrl: './toaster.component.css'
})
export class ToasterComponent implements OnInit {

  alert: { type: string; title: string; message: string } | null = null;

  constructor(private notificationService: NotificationService) {

  }

  ngOnInit() {
    this.notificationService.alert$.subscribe((alert) => {
      if (alert) {
        this.alert = alert;
        setTimeout(() => this.alert = null, 3000);
      }
    })
  }

  closeAlert(): void {
    this.alert = null;
  }
}
