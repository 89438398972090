import { Component, OnInit } from '@angular/core';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzInputModule } from 'ng-zorro-antd/input';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CommonModule } from '@angular/common';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { RADIO_YES_NO_OPTIONS } from '../../../../shared/constants/static-data.constants';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { SectionsService } from '../../../../services/sections.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ActivatedRoute, Router } from '@angular/router';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { AccordionComponent } from "../../../../shared/components/accordion/accordion.component";
import { ListTableComponent } from "../../../../shared/components/list-table/list-table.component";
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { UserService } from '../../../../services/user.service';
import { AddServiceCodeBasicDetailsComponent } from "../add-service-code-basic-details/add-service-code-basic-details.component";
import { AddServiceCodeRateDetailsComponent } from "../add-service-code-rate-details/add-service-code-rate-details.component";
import { ClaimsService } from '../../../../services/claims.service';

@Component({
  selector: 'ppl-add-service-code',
  standalone: true,
  imports: [
    ButtonComponent,
    NzFormModule,
    ReactiveFormsModule,
    FormsModule,
    NzCardModule,
    NzInputModule,
    NzToolTipModule,
    CommonModule,
    NzDatePickerModule,
    NzToolTipModule,
    NzSelectModule,
    NzButtonModule,
    NzCollapseModule,
    AccordionComponent,
    ListTableComponent,
    NgZorroModule,
    AddServiceCodeBasicDetailsComponent,
    AddServiceCodeRateDetailsComponent
],
  templateUrl: './add-service-code.component.html',
  styleUrl: './add-service-code.component.css',
})
export class AddServiceCodeComponent extends BaseComponent implements OnInit {

  pageTitle = 'Add New Service Code';
  rateDetailsmode = 'read';
  savedData: any = {};
  isNew = true;
  programName!: string;
  columnsToBeHidden: any;
  sharedDataKey: any;
  actionsToBeHidden: any;
  lazyLoad = true;
  isServiceCodeAdded = false;

  gridData :any

  constructor(
    private router: Router,
    private sectionsService: SectionsService,
    private route: ActivatedRoute,
    private claimsService: ClaimsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.sectionsService.setFlow('serviceCode');
    const program = JSON.parse(localStorage.getItem('selected_program')!);
    this.secData = this.sectionsService.getSectionData(
      program.program_code,
      'payroll'
    );
    const serviceCodes = this.claimsService.getFormData('service_code');
    this.route.paramMap.subscribe((params: any) => {
      const action = params.get('action')!;
      this.pageTitle =
      action == 'update' ? `Details for Service Code ${serviceCodes.code}` : this.pageTitle;
    });
  }

  serviceCodeAdded(value: any){
      this.isServiceCodeAdded = value;
  }

  goBack() {
    this.router.navigate(['/home/serviceCodeList']);
  }

}
