<div class="wiz-body">
  <div class="ppl-mt24">
    <div class="custom-row">
      @if(mode==="edit" && !this.isNew){
      <ppl-button label="Cancel" icon="fa fa-ban" [customClass]="'alt-button'" [iconPosition]="'left'"
        (click)="onCancel()"></ppl-button>
      <ppl-button label="Save" icon="fa fa-floppy-o" [customClass]="'alt-button'" [iconPosition]="'left'"
        (click)="onUpdate()"></ppl-button>
      }
      @if(mode==="read" && !this.isNew){
      <ppl-button label="Edit" icon="fa fa-pencil" [customClass]="'ghost-button'" [iconPosition]="'left'"
        (click)="onEdit()"></ppl-button>
      }
    </div>
    @if(mode==="edit"){
    <form nz-form nz-row [formGroup]="serviceCodeForm" class="ppl-p0">
      @if(isAttrAvailable('code')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="code" class="ppl-form-label">{{getAttributes('code').label}}
            @if(getAttributes('code').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('code')?.tooltip || ''" nzTooltipPlacement="topRight">
              <input nz-input formControlName="code" id="code" placeholder="{{getAttributes('code').placeholder}}"
                [ngClass]="{'error': validationErrors['code'] }" />
            </div>
            @if (validationErrors['code']) {
            <span class="pl-1 error-message">{{validationErrors['code']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }
      @if(isAttrAvailable('description')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="description"
            class="ppl-form-label">{{getAttributes('description').label}}
            @if(getAttributes('description').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('description')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <textarea rows="3" nz-input formControlName="description" id="description"
                [ngClass]="{'error': validationErrors['description'] }"
                placeholder="{{getAttributes('description').placeholder}}"></textarea>
            </div>
            @if (validationErrors['description']) {
            <span class="pl-1 error-message">{{validationErrors['description']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }
      @if(isAttrAvailable('mod1')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="mod1" class="ppl-form-label">{{getAttributes('mod1').label}}
            @if(getAttributes('mod1').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('mod1')?.tooltip || ''" nzTooltipPlacement="topRight">
              <nz-select formControlName="modifierDetailId1" nzMode='default' class="ppl-select" nzId="mod1" nzShowSearch
                nzAllowClear [ngClass]="{'error': validationErrors['mod1'] }" [nzDisabled]="">
                @for(opt of modifiers; track $index){
                  <nz-option [nzLabel]="opt.code" [nzValue]="opt.modifierDetailId"></nz-option>
                }
              </nz-select>
            </div>
            @if (validationErrors['mod1']) {
            <span class="pl-1 error-message">{{validationErrors['mod1']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }
      @if(isAttrAvailable('mod2')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="mod2" class="ppl-form-label">{{getAttributes('mod2').label}}
            @if(getAttributes('mod2').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('mod2')?.tooltip || ''" nzTooltipPlacement="topRight">
              <nz-select formControlName="modifierDetailId2" nzMode='default' class="ppl-select" nzId="mod2" nzShowSearch
                nzAllowClear [ngClass]="{'error': validationErrors['mod2'] }" [nzDisabled]="">
                @for(opt of modifiers; track $index){
                  <nz-option [nzLabel]="opt.code" [nzValue]="opt.modifierDetailId"></nz-option>
                }
              </nz-select>
            </div>
            @if (validationErrors['mod2']) {
            <span class="pl-1 error-message">{{validationErrors['mod2']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }
      @if(isAttrAvailable('mod3')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="mod3" class="ppl-form-label">{{getAttributes('mod3').label}}
            @if(getAttributes('mod3').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('mod3')?.tooltip || ''" nzTooltipPlacement="topRight">
              <nz-select formControlName="modifierDetailId3" nzMode='default' class="ppl-select" nzId="mod3" nzShowSearch
                nzAllowClear [ngClass]="{'error': validationErrors['mod3'] }" [nzDisabled]="">
                @for(opt of modifiers; track $index){
                  <nz-option [nzLabel]="opt.code" [nzValue]="opt.modifierDetailId"></nz-option>
                }
              </nz-select>
            </div>
            @if (validationErrors['mod3']) {
            <span class="pl-1 error-message">{{validationErrors['mod3']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }
      @if(isAttrAvailable('mod4')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="mod4" class="ppl-form-label">{{getAttributes('mod4').label}}
            @if(getAttributes('mod4').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('mod4')?.tooltip || ''" nzTooltipPlacement="topRight">
              <nz-select formControlName="modifierDetailId4" nzMode='default' class="ppl-select" nzId="mod4" nzShowSearch
                nzAllowClear [ngClass]="{'error': validationErrors['mod4'] }" [nzDisabled]="">
                @for(opt of modifiers; track $index){
                  <nz-option [nzLabel]="opt.code" [nzValue]="opt.modifierDetailId"></nz-option>
                }
              </nz-select>
            </div>
            @if (validationErrors['mod4']) {
            <span class="pl-1 error-message">{{validationErrors['mod4']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }
      @if(isAttrAvailable('effectiveFromDate')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="effectiveFromDate"
            class="ppl-form-label">{{getAttributes('effectiveFromDate').label}}
            @if(getAttributes('effectiveFromDate').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('effectiveFromDate')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <nz-date-picker formControlName="effectiveFromDate" id="effectiveFromDate" [nzFormat]="'MM/dd/yyyy'"
                [nzPlaceHolder]="getAttributes('effectiveFromDate').placeholder"  [nzDisabledDate]="disabledStartDate"
                [ngClass]="{'error': validationErrors['dob'] }"></nz-date-picker>
            </div>
            @if (validationErrors['effectiveFromDate']) {
            <span class="pl-1 error-message">{{validationErrors['effectiveFromDate']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }
      @if(isAttrAvailable('effectiveEndDate')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="effectiveEndDate"
            class="ppl-form-label">{{getAttributes('effectiveEndDate').label}}
            @if(getAttributes('effectiveEndDate').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('effectiveEndDate')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <nz-date-picker formControlName="effectiveEndDate" id="effectiveEndDate" [nzFormat]="'MM/dd/yyyy'"
                [nzPlaceHolder]="getAttributes('effectiveEndDate').placeholder" [nzDisabledDate]="disabledEndDate"
                [ngClass]="{'error': validationErrors['dob'] }"></nz-date-picker>
            </div>
            @if (validationErrors['effectiveEndDate']) {
            <span class="pl-1 error-message">{{validationErrors['effectiveEndDate']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }
      @if(isAttrAvailable('city_lkp')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="city_lkp"
            class="ppl-form-label">{{getAttributes('city_lkp').label}}
            @if(getAttributes('city_lkp').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('city_lkp')?.tooltip || ''" nzTooltipPlacement="topRight">
              <nz-select formControlName="cityLkp" nzMode='default' class="ppl-select" nzId="city_lkp" nzShowSearch
                nzAllowClear [ngClass]="{'error': validationErrors['city_lkp'] }" [nzDisabled]="">
                @for(opt of cities; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
            </div>
            @if (validationErrors['city_lkp']) {
            <span class="pl-1 error-message">{{validationErrors['city_lkp']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }
      @if(isAttrAvailable('county_lkp')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="county_lkp"
            class="ppl-form-label">{{getAttributes('county_lkp').label}}
            @if(getAttributes('county_lkp').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('county_lkp')?.tooltip || ''" nzTooltipPlacement="topRight">
              <nz-select formControlName="countyLkp" nzMode='default' class="ppl-select" nzId="county_lkp" nzShowSearch
                nzAllowClear [ngClass]="{'error': validationErrors['county_lkp'] }" [nzDisabled]="">
                @for(opt of counties; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
            </div>
            @if (validationErrors['county_lkp']) {
            <span class="pl-1 error-message">{{validationErrors['county_lkp']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }


      @if(isAttrAvailable('unit_measurement_lkp')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="unit_measurement_lkp"
            class="ppl-form-label">{{getAttributes('unit_measurement_lkp').label}}
            @if(getAttributes('unit_measurement_lkp').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('unit_measurement_lkp')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <nz-select formControlName="measureUnitLkp" nzMode='default' class="ppl-select" nzId="unit_measurement_lkp"
                nzShowSearch nzAllowClear [ngClass]="{'error': validationErrors['unit_measurement_lkp'] }"
                [nzDisabled]="">
                @for(opt of measureUnits; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
            </div>
            @if (validationErrors['unit_measurement_lkp']) {
            <span class="pl-1 error-message">{{validationErrors['unit_measurement_lkp']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }

      @if(isAttrAvailable('unit_increment_lkp')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="unit_increment_lkp"
            class="ppl-form-label">{{getAttributes('unit_increment_lkp').label}}
            @if(getAttributes('unit_increment_lkp').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('unit_increment_lkp')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <nz-select formControlName="unitIncrementLkp" nzMode='default' class="ppl-select" nzId="unit_increment_lkp"
                nzShowSearch nzAllowClear [ngClass]="{'error': validationErrors['unit_increment_lkp'] }"
                [nzDisabled]="">
                @for(opt of unitIncrements; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
            </div>
            @if (validationErrors['unit_increment_lkp']) {
            <span class="pl-1 error-message">{{validationErrors['unit_increment_lkp']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }

      @if(isAttrAvailable('is_group_service')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="is_group_service"
            class="ppl-form-label">{{getAttributes('is_group_service').label}}
            @if(getAttributes('is_group_service').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('is_group_service')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <nz-select formControlName="groupService" class="ppl-select" nzId="is_group_service" nzShowSearch
                nzAllowClear [ngClass]="{'error': validationErrors['is_group_service'] }" [nzDisabled]="">
                @for(opt of radioOptions; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
            </div>
            @if (validationErrors['is_group_service']) {
            <span class="pl-1 error-message">{{validationErrors['is_group_service']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }

      @if(isAttrAvailable('servicegroup_lkp')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="servicegroup_lkp"
            class="ppl-form-label">{{getAttributes('servicegroup_lkp').label}}
            @if(getAttributes('servicegroup_lkp').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('servicegroup_lkp')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <nz-select formControlName="servicegroupLkp" nzMode='default' class="ppl-select" nzId="servicegroup_lkp"
                nzShowSearch nzAllowClear [ngClass]="{'error': validationErrors['servicegroup_lkp'] }" [nzDisabled]="">
                @for(opt of serviceGroups; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
            </div>
            @if (validationErrors['servicegroup_lkp']) {
            <span class="pl-1 error-message">{{validationErrors['servicegroup_lkp']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }

      @if(isAttrAvailable('medicaid_billable')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="medicaid_billable"
            class="ppl-form-label">{{getAttributes('medicaid_billable').label}}
            @if(getAttributes('medicaid_billable').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('medicaid_billable')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <nz-select formControlName="medicaidBillable" class="ppl-select" nzId="medicaid_billable" nzShowSearch
                nzAllowClear [ngClass]="{'error': validationErrors['medicaid_billable'] }" [nzDisabled]="">
                @for(opt of radioOptions; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
            </div>
            @if (validationErrors['medicaid_billable']) {
            <span class="pl-1 error-message">{{validationErrors['medicaid_billable']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }

      @if(isAttrAvailable('medicaid_billing_code')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="medicaid_billing_code"
            class="ppl-form-label">{{getAttributes('medicaid_billing_code').label}}
            @if(getAttributes('medicaid_billing_code').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('medicaid_billing_code')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <nz-select formControlName="medicaidBillingCode" nzMode='default' class="ppl-select"
                nzId="medicaid_billing_code" nzShowSearch nzAllowClear
                [ngClass]="{'error': validationErrors['medicaid_billing_code'] }" [nzDisabled]="">
                @for(opt of medicaidBillingCodes; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
            </div>
            @if (validationErrors['medicaid_billing_code']) {
            <span class="pl-1 error-message">{{validationErrors['medicaid_billing_code']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }



      @if(isAttrAvailable('doc_eligible')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="doc_eligible"
            class="ppl-form-label">{{getAttributes('doc_eligible').label}}
            @if(getAttributes('doc_eligible').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('doc_eligible')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <nz-select formControlName="docEligible" class="ppl-select" nzId="doc_eligible" nzShowSearch nzAllowClear
                [ngClass]="{'error': validationErrors['doc_eligible'] }" [nzDisabled]="">
                @for(opt of radioOptions; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
            </div>
            @if (validationErrors['doc_eligible']) {
            <span class="pl-1 error-message">{{validationErrors['medicaid_billable']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }
      @if(isAttrAvailable('ot_eligible')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="ot_eligible"
            class="ppl-form-label">{{getAttributes('ot_eligible').label}}
            @if(getAttributes('ot_eligible').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('ot_eligible')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <nz-select formControlName="otEligible" class="ppl-select" nzId="ot_eligible" nzShowSearch nzAllowClear
                [ngClass]="{'error': validationErrors['ot_eligible'] }" [nzDisabled]="">
                @for(opt of radioOptions; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
            </div>
            @if (validationErrors['ot_eligible']) {
            <span class="pl-1 error-message">{{validationErrors['ot_eligible']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }
      @if(isAttrAvailable('providerType')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="providerType"
            class="ppl-form-label">{{getAttributes('providerType').label}}
            @if(getAttributes('providerType').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('providerType')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <nz-select formControlName="providerTypes" nzMode='multiple' class="ppl-select" nzId="providerType"
                nzShowSearch nzAllowClear [ngClass]="{'error': validationErrors['providerType'] }" [nzDisabled]="">
                @for(opt of providers; track $index){
                  <nz-option [nzLabel]="opt.name" [nzValue]="opt.code"></nz-option>
                }
              </nz-select>
            </div>
            @if (validationErrors['providerType']) {
            <span class="pl-1 error-message">{{validationErrors['providerType']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }

      @if(isAttrAvailable('max_billable_rate')){
      <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="max_billable_rate"
            class="ppl-form-label">{{getAttributes('max_billable_rate').label}}
            @if(getAttributes('max_billable_rate').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('max_billable_rate')?.tooltip || ''"
              nzTooltipPlacement="topRight">
              <input nz-input formControlName="maxBillableRate" id="max_billable_rate"
                placeholder="{{getAttributes('max_billable_rate').placeholder}}"
                [ngClass]="{'error': validationErrors['max_billable_rate'] }" />
            </div>
            @if (validationErrors['max_billable_rate']) {
            <span class="pl-1 error-message">{{validationErrors['max_billable_rate']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }
      @if(isAttrAvailable('comment')){
      <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item  nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="comment"
            class="ppl-form-label">{{getAttributes('comment').label}}
            @if(getAttributes('comment').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzSpan]="24" class="ppl-form-control" nz-tooltip
            nzTooltipTitle="{{getAttributes('comment').tooltip}}" nzTooltipPlacement="topRight">
            <textarea rows="3" cols="40" nz-input formControlName="comments" id="comment" class="ppl-form-control"
              placeholder="{{getAttributes('comment').placeholder}}"
              [ngClass]="{'error': validationErrors['comment'] }"></textarea>
            @if (validationErrors['comment']) {
            <span class="pl-1 error-message">{{validationErrors['comment']}}</span>
            }
          </nz-form-control>

        </nz-row>

      </nz-form-item>
      }

    </form>
    @if(isNew){
    <div class="custom-row">
      <ppl-button [label]="'Cancel'" [icon]="'fa fa-close'" style="margin-right:4px; font-size:0.857rem;"
       [customClass]="'ghost-button'" [iconPosition]="'right'" (click)="goBack()"></ppl-button>
      <ppl-button label="Submit" icon="fa fa-check-circle" [customClass]="'primary-button'" [iconPosition]="'right'"
        (click)="onAdd()"></ppl-button>
    </div>
    }
    }
    @else if (mode==="read") {
    <div nz-row>
      @if(isAttrAvailable('code')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("code").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.code }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('description')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("description").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.description }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('mod1')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("mod1").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ getTasLookupName(modifiers,savedData?.modifierDetailId1) }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('mod2')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("mod2").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ getTasLookupName(modifiers,savedData?.modifierDetailId2) }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('mod3')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("mod3").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ getTasLookupName(modifiers,savedData?.modifierDetailId3) }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('mod4')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("mod4").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ getTasLookupName(modifiers,savedData?.modifierDetailId4) }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('effectiveFromDate')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("effectiveFromDate").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.effectiveFromDate }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('effectiveEndDate')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("effectiveEndDate").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.effectiveEndDate }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('city_lkp')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("city_lkp").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ getLookupValue(cities,savedData?.cityLkp) }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('county_lkp')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("county_lkp").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ getLookupValue(counties,savedData?.countyLkp) }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('unit_measurement_lkp')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("unit_measurement_lkp").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ getLookupValue(measureUnits,savedData?.measureUnitLkp) }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('unit_increment_lkp')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("unit_increment_lkp").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ getLookupValue(unitIncrements,savedData?.unitIncrementLkp) }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('is_group_service')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("is_group_service").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.groupService }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('servicegroup_lkp')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("servicegroup_lkp").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ getLookupValue(serviceGroups,savedData?.servicegroupLkp) }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('medicaid_billable')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("medicaid_billable").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.medicaidBillable }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('medicaid_billing_code')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("medicaid_billing_code").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ getLookupValue(medicaidBillingCodes,savedData?.medicaidBillingCode) }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('doc_eligible')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("doc_eligible").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.docEligible }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('ot_eligible')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("ot_eligible").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.otEligible }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('providerType')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("providerType").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ getProvLookupValue(providers,savedData?.providerTypes) }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('maxBillableRate')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("max_billable_rate").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.max_billable_rate }}</div>
        </div>
      </div>
      }
      @if(isAttrAvailable('comment')){
      <div nz-col nzSpan="12">
        <div nz-row>
          <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("comment").label }}
          </div>
          <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.comments }}</div>
        </div>
      </div>
      }
    </div>
    }
  </div>
</div>