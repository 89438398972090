import { Component, OnInit } from '@angular/core';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzInputModule } from 'ng-zorro-antd/input';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CommonModule } from '@angular/common';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { RADIO_YES_NO_OPTIONS } from '../../../../shared/constants/static-data.constants';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { SectionsService } from '../../../../services/sections.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ActivatedRoute, Router } from '@angular/router';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { AccordionComponent } from '../../../../shared/components/accordion/accordion.component';
import { ProspectiveInfoComponent } from '../../../lead-details/prospective-participants/prospective-info/prospective-info.component';
import { PopupDialogComponent } from '../../../../shared/components/popup-dialog/popup-dialog.component';
import { ListTableComponent } from '../../../../shared/components/list-table/list-table.component';
import { LeadDetailsService } from '../../../../services/lead-details.service';
import { TableRowActionsModel } from '../../../../shared/interfaces/list.interface';
import { TableRowActions } from '../../../../shared/interfaces/actions';
import { EnrollmentDataService } from '../../../../services/enrollment-data.service';
import { ClaimsService } from '../../../../services/claims.service';
import { DateTimeFormatterService } from '../../../../shared/utils/date-time-formatter.service';
import { LookupService } from '../../../../services/lookup.service';
import { LookupValueItem } from '../../../../interfaces/lookup.interface';
import { County } from '../../../../interfaces/region-counties.interface';

@Component({
  selector: 'ppl-add-payable-provider-rates',
  standalone: true,
  imports: [
    ButtonComponent,
    NzFormModule,
    ReactiveFormsModule,
    FormsModule,
    NzCardModule,
    NzInputModule,
    NzToolTipModule,
    CommonModule,
    NzDatePickerModule,
    NzToolTipModule,
    NzSelectModule,
    NzButtonModule,
    NzCollapseModule,
    AccordionComponent,
  ],
  templateUrl: './add-payable-provider-rates.component.html',
  styleUrl: './add-payable-provider-rates.component.css',
})
export class AddPayableProviderRatesComponent
  extends BaseComponent
  implements OnInit {
  participantProviderRates!: FormGroup;
  radioOptions = RADIO_YES_NO_OPTIONS;
  pageTitle = 'Add Payable - Personal Assistant Rate';
  mode = 'edit';
  savedData: any = {};
  isNew = true;
  showDialog = false;
  showSearchResults = false;
  searchResultsList: any[] = [];
  isProviderDisabled = true;
  serviceCodes: any;
  program: any;
  modifiersList: any;
  regions: any = [];
  counties: County[] = [];

  cols = [
    {
      field: 'programCode',
      header: 'Program Name',
      jsonpath: 'programCode',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'modifiedDate',
      header: 'Modified Date',
      jsonpath: 'modifiedDate',
      isDisplayedAtHeader: 'n',
      isSearchable: 'n',
    },
    {
      field: 'isVerified',
      header: 'Consumer Verified',
      jsonpath: 'isVerified',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
  ];
  actionButtons: TableRowActionsModel = new TableRowActions();

  reselectAllowed = true;
  providers: any;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private sectionsService: SectionsService,
    private claimsService: ClaimsService,
    private dateTimeFormatterService: DateTimeFormatterService,
    private lookupService: LookupService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.participantProviderRates = this.fb.group({
      serviceCodeDetailId: [null],
      modifierDetailId: [null],
      fromDate: [null],
      toDate: [null],
      rate: [null],
      regionId: [null],
	    countyId: [null],
    });

    this.route.paramMap.subscribe((params: any) => {
      const action = params.get('action')!;
      this.pageTitle =
        action == 'update' ? 'Payable - Personal Assistant Rate Details' : this.pageTitle;
      this.isNew = action === 'add';
      this.mode = action == 'update' ? 'read' : 'edit';
    });

    this.sectionsService.setFlow('payablePARates');
    this.getLookupValues();
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    this.secData = this.sectionsService.getSectionData(
      this.program.program_code,
      'payroll'
    );
    const assocRatesData =
      this.claimsService.getFormData('association_rates');
    this.savedData = assocRatesData;
  }

  getLookupValues(): void {
    this.regions = this.lookupService.getTasLookupValue('regionDetailList');
    this.modifiersList = this.lookupService.getTasLookupValue('modifierDetailList');
    this.serviceCodes = this.lookupService.getTasLookupValue(
      'servicecodeDetailList'
    );
  }

  updateCountiesList(regionId: number): void {
    this.counties = this.lookupService.getTasLookupValue('regionCountyDetailList')
      .filter((county: County) => county.payRegionId === regionId)
  }

  returnClicked() {
    this.router.navigate(['/home/payablePersonalAssistantRates']);
  }

  goBack() {
    this.router.navigate(['/home/payablePersonalAssistantRates']);
  }

  onEdit() {
    this.mode = 'edit';
    this.isProviderDisabled = false;
    this.reselectAllowed = false;
    this.updateCountiesList(this.savedData.regionId);
    this.participantProviderRates.patchValue({
      ...this.savedData,
    });
    this.participantProviderRates.get('servicecodeDetailId')?.disable();
  }

  createPayload() {
    return {
      ...this.participantProviderRates.getRawValue(),
      fromDate: this.dateTimeFormatterService.formatToString(
        new Date(this.participantProviderRates.value.fromDate)
      ),
      toDate: this.dateTimeFormatterService.formatToString(
        new Date(this.participantProviderRates.value.toDate)
      ),
      programCode: this.program.program_code,
    };
  }

  onAdd() {
    const payload = this.createPayload();
    this.claimsService
      .addPayablePARates(payload, true)
      .subscribe((data: any) => {
        if (data.status == 200) {
          this.goBack();
        }
      });
  }

  getTasLookupName(lookupArr: any, valueCode: string): string | undefined {
    const lookup = lookupArr.find((gen: any) => {
      return gen.serviceCodeDetailId === valueCode;
    });
    return lookup ? lookup.code : undefined;
  }

  onCancel() {
    this.mode = 'read';
    this.validationErrors = {};
    this.participantProviderRates.reset();
  }

  onUpdate() {
    const assocRatesData =
      this.claimsService.getFormData('association_rates');
    const payload = {
      ...this.participantProviderRates.value,
      programCode: this.program.program_code,
      fromDate: this.dateTimeFormatterService.formatToString(
        new Date(this.participantProviderRates.value.fromDate)
      ),
      toDate: this.dateTimeFormatterService.formatToString(
        new Date(this.participantProviderRates.value.toDate)
      )
    }
    this.claimsService.updatePayablePARates(payload, assocRatesData.payRateId).subscribe((data) => {
      if (data.status == 200) {
        this.mode = 'read';
      }
    })
  }

  viewerValueChange(value: string) {
    const viewerValue = [value][0];
  }

  handleOk() {
    console.log('ok clicked');
  }

  handleCancel() {
    console.log('cancel clicked');
  }

  disabledStartDate = (startValue: Date): boolean => {
    const endDate = this.participantProviderRates.get('toDate')?.value;
    if (!startValue || !endDate || !(endDate instanceof Date)) {
      return false;
    }
    return startValue.getTime() > endDate.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    const startDate = this.participantProviderRates.get('fromDate')?.value;
    if (!endValue || !startDate || !(startDate instanceof Date)) {
      return false;
    }
    return endValue.getTime() <= startDate.getTime();
  };

  updatedLeadData(event: any) {
    console.log('cancel clicked', event);
  }

  add() {
    this.showDialog = true;
  }

  action(event: any): void {
    if (event.action === 'add') {
      this.showDialog = false;
      this.showSearchResults = false;
      this.isProviderDisabled = false;
    }
  }
}
