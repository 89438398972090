import { Injectable } from '@angular/core';
import { MainMenuItem } from '../interfaces/menu.interface';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  getMenu(code: string): MainMenuItem[] {
    return require(`../../assets/data/programs/${code}/main_menu.json`);
  }
}
