import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AddressComponent } from '../../../../shared/components/address/address.component';
import { SectionsService } from '../../../../services/sections.service';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { ListTableComponent } from '../../../../shared/components/list-table/list-table.component';
import { LeadDetailsService } from '../../../../services/lead-details.service';
import { LookupService } from '../../../../services/lookup.service';
import { performValidation } from '../../../../shared/utils/validation.util';
import { STATIC_DATA, STATIC_JSON_DATA_PATH } from '../../../../shared/constants/static-data.constants';
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { CommonModule } from '@angular/common';
import { LoggerService } from '../../../../shared/services/logger.service';
import { LeadDetailCommunicationUpdadeModel } from '../../../../interfaces/lead-detail.model';
import { TableRowActionsModel } from '../../../../shared/interfaces/list.interface';
import { TableRowActions } from '../../../../shared/interfaces/actions';
import { AuthorizeDirective } from '../../../../shared/authorization/authorize.directive.';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { AuthorizeFieldDirective } from '../../../../shared/authorization/authorizeField.directive';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { AuthorizationUtility } from '../../../../shared/authorization/auth.utility';

@Component({
  selector: 'ppl-lead-detail-viewer',
  standalone: true,
  imports: [
    NgZorroModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AddressComponent,
    ListTableComponent,
    AuthorizeDirective,
    AuthorizeFieldDirective,
    ButtonComponent,
    NzDatePickerModule,
  ],
  templateUrl: './lead-detail-viewer.component.html',
  styleUrl: './lead-detail-viewer.component.css',
})
export class LeadDetailViewerComponent
  extends BaseComponent
  implements OnInit, OnChanges {
  mode = 'read';
  filledInfo!: any;
  viewerForm!: FormGroup;
  cols: any;
  gridData: any[] = [];
  communicationType: any;
  copyOfGridData: any = [];
  steps: any;
  entry = 'base';
  target!: LeadDetailCommunicationUpdadeModel;
  associationRole = 0;
  actionButtons: TableRowActionsModel = new TableRowActions();
  approvedStatus: string = STATIC_DATA.APPROVED;
  leadStatus = '';
  leadId = '';
  program: any;
  fiEntries: any;
  mcoEntries: any;
  users: any;
  viewerType = '';
  selectedViewer: any = {};
  isSectionEditable: boolean = false;

  @Input() pageId = '';
  @Input() tabId = '';
  @Input() programCode = '';
  @Input() set sectionData(data: string) {
    this.steps = data;
  }

  @Output() leadUpdated = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private sectionService: SectionsService,
    private leadDetailsService: LeadDetailsService,
    private lookupService: LookupService,
    private cdr: ChangeDetectorRef,
    private logger: LoggerService,
    private authUtility: AuthorizationUtility
  ) {
    super();
  }

  ngOnInit(): void {
    this.entry = localStorage.getItem('lead_entry')!;
    this.leadId = localStorage.getItem('selected_lead')!;
    this.isSectionEditable = this.authUtility.isSectionEditable(this.pageId, this.tabId, 'Viewer');
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    this.communicationType =
      this.lookupService.getLookupValue('Communication_Type');

    this.associationRole = JSON.parse(
      localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!
    );
    this.leadDetailsService.getLeadStatus().subscribe((status: string) => {
      this.leadStatus = status;
      console.log('Lead Status', status);
      if (this.leadStatus === this.approvedStatus) {
        this.actionButtons.edit = false;
        this.actionButtons.delete = false;
      } else {
        this.actionButtons.edit = true;
        this.actionButtons.delete = true;
      }
    });
    this.createFormGroup();
    this.getFromData();
    this.lookupService.getOrgData(this.program.program_code,).subscribe((data: any) => {
      if (data.status == 200) {
        this.fiEntries = data.responsedata.filter((item: any) => item.orgTypeLookup === 'FI');
        this.mcoEntries = data.responsedata.filter((item: any) => item.orgTypeLookup === 'MCO');
      }
    })

    this.getViewerData();
  }

  getViewerData() {
    this.leadDetailsService.getViewerData(this.program?.program_code, this.leadId).subscribe((data: any) => {
      this.gridData = data?.responsedata?.data;
      this.copyAndTransformGridData(this.gridData);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes['sectionData'] && changes['sectionData'].currentValue) {
        this.secData = this.steps['participant_viewer'];
      }
    } catch (e) {
      this.logger.debug(
        'Error in getting section data and updating latest info',
        e
      );
    }
  }

  createFormGroup() {
    this.viewerForm = this.fb.group({
      id: [],
      participantId: [],
      associationType: [],
      mcoOrgName: [],
      fiOrgName: [],
      associationStartDate: [],
      associationEndDate: [],
      viewerType: [],
      viewerValue: [],
      userId: []
    });
  }

  async getFromData() {
    //table column information
    try {
      if (this.entry) {
        const tempCols = await this.sectionService.getJsonConfigurationData(
          STATIC_JSON_DATA_PATH.LEAD_DETAILS_VIEWER_HEADERS,
          this.entry
        );

        this.cols = tempCols?.enrollmentDates?.columns;
      }
    } catch (e) {
      this.logger.debug('Error in getting table headers', e);
    }
  }

  viewerValueChange(value: string) {
    const viewerValue = [value][0];

    this.leadDetailsService
      .getViewerUsers(this.program?.program_code, viewerValue, this.viewerType)
      .subscribe((data) => {
        const curerntUsers = data.responsedata;
        curerntUsers.forEach((user: any) => {
          user.identifier = `${user.firstName} ${user.lastName}`
        });

        this.users = curerntUsers;
      });
  }

  cancelEdit() {
    this.mode = 'read';
    this.validationErrors = {};
    this.viewerForm.reset();
    this.viewerType = '';
    this.selectedViewer = {};
    setTimeout(() => this.cdr.detectChanges(), 1000);
  }

  action(event: any) {
    console.log('acto', event);
    if (event && event.action === 'edit') {
      this.mode = 'edit';
      if (event?.data) {
        const data = JSON.parse(JSON.stringify(event?.data));
        data.associationStartDate = new Date(data.associationStartDate);
        if (data.associationEndDate) {
          data.associationEndDate = new Date(data.associationEndDate);
        }
        this.viewerForm.patchValue(data);
        this.selectedViewer = data;
      }
    } else if (event && event.action === 'delete') {
      this.delete(event.data);
    }
  }

  copyAndTransformGridData(gridData: any[]) {
    if (gridData) {
      const copyGridData = JSON.parse(JSON.stringify(gridData));
      copyGridData.forEach((data: any) => {
        if (data.associationType == 'FI') {
          data.displayAssociationType = 'CDPAP Facilitator';
        } else if(data.associationType == 'FI Staff') {
          data.displayAssociationType = 'CDPAP Facilitator Staff';
        } else {
          data.displayAssociationType = data.associationType;
        }
      })
      this.copyOfGridData = copyGridData;
    }
  }

  disabledStartDate = (startValue: Date): boolean => {
    const endDate = this.viewerForm.get('associationEndDate')?.value;
    if (!startValue || !endDate) {
      return false;
    }
    return startValue.getTime() > endDate.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    const startDate = this.viewerForm.get('associationStartDate')?.value;
    if (!endValue || !startDate) {
      return false;
    }
    return endValue.getTime() <= startDate.getTime();
  };

  add(viewerType: string, associationType: string) {
    this.mode = 'add';
    this.viewerForm.reset();
    this.viewerType = viewerType;
    this.viewerForm.controls['participantId'].setValue(this.associationRole);
    this.viewerForm.controls['associationType'].setValue(associationType);
    this.viewerForm.controls['viewerType'].setValue(viewerType);

    if (viewerType == 'State') {
      this.viewerValueChange('NY');
      this.secData.attributes.filter((e: any) => e.attribute_ID == 'userId')[0].mandatory = 'Y';
      this.secData.validations[0].attributes.push({
        "validatorid": 1,
        "attribute_ID": [
          "userId"
        ],
        "functionParams": {
          "ErrMsg": "Please select user"
        }
      });
      this.secData.validations[0].attributes = this.secData.validations[0].attributes.filter((e: any) => e.attribute_ID.indexOf("mcoOrgName") == -1)
      this.secData.validations[0].attributes = this.secData.validations[0].attributes.filter((e: any) => e.attribute_ID.indexOf("fiOrgName") == -1)
    }
    else if (viewerType == 'MCO') {
      this.secData.attributes.filter((e: any) => e.attribute_ID == 'userId')[0].mandatory = 'N';
      this.secData.validations[0].attributes = this.secData.validations[0].attributes.filter((e: any) => e.attribute_ID.indexOf("userId") == -1)
      this.secData.validations[0].attributes = this.secData.validations[0].attributes.filter((e: any) => e.attribute_ID.indexOf("fiOrgName") == -1)
      this.secData.validations[0].attributes.push({
        "validatorid": 1,
        "attribute_ID": [
          "mcoOrgName"
        ],
        "functionParams": {
          "ErrMsg": "Please select MCO"
        }
      });
    }
    else {
      this.secData.attributes.filter((e: any) => e.attribute_ID == 'userId')[0].mandatory = 'N';
      this.secData.validations[0].attributes = this.secData.validations[0].attributes.filter((e: any) => e.attribute_ID.indexOf("userId") == -1)
      this.secData.validations[0].attributes = this.secData.validations[0].attributes.filter((e: any) => e.attribute_ID.indexOf("mcoOrgName") == -1)
      this.secData.validations[0].attributes.push({
        "validatorid": 1,
        "attribute_ID": [
          "fiOrgName"
        ],
        "functionParams": {
          "ErrMsg": "Please select CDPAPFacilitator"
        }
      });
    }
  }

  save() {
    if (this.mode == 'add') {
      const data = { ...this.viewerForm.value };

      data.associationStartDate = this.dateFormatter.formatToString(data.associationStartDate);

      if (data.associationEndDate) {
        data.associationEndDate = this.dateFormatter.formatToString(data.associationEndDate);
      }

      if (data.viewerType == 'State') {
        data.associationType = 'State Staff';
        data.viewerValue = 'NY';
      } else if (data.viewerType == 'FI') {
        data.viewerValue = data.fiOrgName;
      } else {
        data.viewerValue = data.mcoOrgName;
      }

      try {
        this.validationErrors = performValidation(data, this.secData, 'Save');
      } catch (e) {
        this.logger.debug('Error in validations', e);
      }

      if (Object.keys(this.validationErrors).length === 0) {
        this.leadDetailsService
          .createViewer(data, this.program?.program_code, this.leadId, true)
          .subscribe(() => {
            this.logger.info('Viewer saved successfully');
            this.cancelEdit();
            this.getViewerData();
          });
      }
    } else if (this.mode == 'edit') {
      this.updateViewer();
    }
  }

  updateViewer() {
    const formValues = this.viewerForm.value;
    const data: any = {};
    data.id = formValues.id;
    data.associationStartDate = this.dateFormatter.formatToString(formValues.associationStartDate);
    if (formValues.associationEndDate) {
      data.associationEndDate = this.dateFormatter.formatToString(formValues.associationEndDate);
    }
    this.leadDetailsService
      .updateViewer(data, this.program?.program_code, formValues.id)
      .subscribe(() => {
        this.logger.info('Viewer updated successfully');
        this.cancelEdit();
        this.getViewerData();
      });
  }

  delete(record: any) {
    const data: any = {};
    data.id = record.id;
    data.participantId = this.leadId;
    this.leadDetailsService
      .updateInActivateViewer(data, this.program?.program_code, record.id)
      .subscribe(() => {
        this.logger.info('Viewer inactivated successfully');
        this.getViewerData();
      });
  }
}
