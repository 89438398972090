{
  "columns": [
    {
      "header_id": "code",
      "header_label": "Service Code",
      "jsonpath": "code",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "modifierDetailCode1",
      "header_label": "Modifier 1",
      "jsonpath": "modifierDetailCode1",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "modifierDetailCode2",
      "header_label": "Modifier 2",
      "jsonpath": "modifierDetailCode2",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "modifierDetailCode3",
      "header_label": "Modifier 3",
      "jsonpath": "modifierDetailCode3",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "modifierDetailCode4",
      "header_label": "Modifier 4",
      "jsonpath": "modifierDetailCode4",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "effectiveFromDate",
      "header_label": "From Date",
      "jsonpath": "effectiveFromDate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "effectiveEndDate",
      "header_label": "To Date",
      "jsonpath": "effectiveEndDate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "city",
      "header_label": "City",
      "jsonpath": "city",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "county",
      "header_label": "County",
      "jsonpath": "county",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "rate_type",
      "header_label": "Rate Type",
      "jsonpath": "rate_type",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "min_rate",
      "header_label": "Min Rate",
      "jsonpath": "min_rate",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "max_rate",
      "header_label": "Max Rate",
      "jsonpath": "max_rate",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "default_rate",
      "header_label": "Default Rate",
      "jsonpath": "default_rate",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    }
  ],
  "buttons": [],
  "apiDetails": "serviceCodesList.json",
  "pageName": "service-codes-list",
  "orderByColumn": ["serviceCodeDetailId"],
  "orderBy": ["desc"]
}
