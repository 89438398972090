import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { UserDetails } from '../../../interfaces/user.interface';
import { Router, RouterModule } from '@angular/router';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CommonModule } from '@angular/common';
import { MenuService } from '../../../services/menu.service';
import { LookupService } from '../../../services/lookup.service';
import { STATIC_DATA } from '../../../shared/constants/static-data.constants';

@Component({
  selector: 'ppl-home',
  standalone: true,
  imports: [
    RouterModule,
    NzLayoutModule,
    NzIconModule,
    CommonModule
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit {

  userDetails: UserDetails | undefined;
  isCollapsed = false;
  numberOfLinks = 1;

  constructor(private userService: UserService,
    private router: Router,
    private menuService: MenuService,
    private lookupService: LookupService) { }

  ngOnInit() {
    this.userDetails = this.userService.getUserDetails();

    this.lookupService.fetchLookupValues().subscribe((lookupData) => {
      localStorage.setItem("lookup_values", JSON.stringify(lookupData));
    });

    const program = JSON.parse(localStorage.getItem('selected_program')!);
    if(program.program_code !== STATIC_DATA.LEAD_PROGRAM){
      this.lookupService.fetchTasLookupValues().subscribe((lookupData) => {
        localStorage.setItem("tas_lookup_values", JSON.stringify(lookupData));
      });
      this.lookupService.getProviderTypeLookup().subscribe((lookupData) => {
        localStorage.setItem("provider_lookup_values", JSON.stringify(lookupData));
      });
    }
   /*  this.router.navigateByUrl(`/programs`); */
  }

  onLinkSelect = (link: string) => {
    this.router.navigateByUrl(`/home/${link}`);
  }

}

