<div class="ppl-container-body">
    <div class="ppl-container-head fake-revert">
        Welcome, {{userData?.first_name}}!
    </div>
    <div class="ppl-container">
        
        <div class="ppl-content-container">
            
            @for( userProgram of userPrograms ; track userProgram; let idx = $index ){
                <ppl-card  [program]="userProgram" (emitSelectedProgram)="onProgramSelection($event)"></ppl-card>
            }@empty {
                <div> There are no Programs assigned. Please reach out to PPL admin.</div>
              }
        </div>
    </div>
</div>