/* src/app/shared/components/card/card.component.css */
.card-container {
  display: flex;
  width: 260px;
  height: 210px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 20px;
  border: 2px solid #f3f3f3;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
}
.card-container:hover {
  cursor: pointer;
  border-radius: 20px;
  border: 2px solid #004C4A;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.24);
  transition: 0.3s ease-in-out;
}
.light-background {
  background-color: white;
  color: #323232;
}
.dark-background {
  background-color: #323232;
  color: white;
}
.light-switch {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 50px;
  background: #F2F2F2;
  transition: background-color 0.3s ease;
}
.light-switch:hover {
  cursor: pointer;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
  transition: background-color 0.3s ease;
}
.light-switch:focus {
  background-color: #dadada;
}
.dark-theme .light-switch {
  background-color: #fbff01;
}
.dark-theme .light-switch:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.24);
}
.light {
  filter: invert(1);
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  flex-direction: row;
}
.title {
  color: #004C4A;
  font-size: 1.429rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dark-theme .title {
  color: #fdfdfd;
}
.select-text {
  color: #323232;
  font-size: 0.714rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.dark-theme .select-text {
  color: #fdfdfd;
}
.arrow {
  filter: invert(1);
}
.action {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.dark-theme .arrow {
  filter: invert(0);
}
.card-action {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
/*# sourceMappingURL=card.component-KCBW2ZTW.css.map */
