import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChecklistGroup, ChecklistItem } from '../../../interfaces/checklist.model';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { ButtonModule } from 'primeng/button';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { ModalComponent } from '../../../shared/components/modal/modal.component';
import { EditpdfComponent } from '../../editpdf/editpdf.component';
import { CommPreferenceComponent } from '../comm-preference/comm-preference.component';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { RADIO_YES_NO_OPTIONS } from '../../../shared/constants/static-data.constants';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormChecklistComponent } from '../form-checklist/form-checklist.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'ppl-checklist-view',
  standalone: true,
  imports: [NzCollapseModule,
    CommPreferenceComponent,
    CommonModule, ButtonModule,
    NzToolTipModule,
    NzRadioModule,
    NzButtonModule,
    ButtonComponent,
    ModalComponent,
    EditpdfComponent,
    NzAlertModule,
    NzInputModule,
    NzDatePickerModule,
    FormChecklistComponent,
    NzSelectModule,
    FormsModule],
  templateUrl: './checklist-view.component.html',
  styleUrl: './checklist-view.component.css'
})
export class ChecklistViewComponent {
  @Input() checklistData: ChecklistGroup[] = [];
  @Input() showNoRecordsMesssage = true;
  @Output() viewPDF = new EventEmitter<ChecklistItem>();

  radioOptions = RADIO_YES_NO_OPTIONS;
  date = '';

  isPanelActive(group: ChecklistGroup): boolean {
    return group.isActive;
  }

  onChange(event: any, item: ChecklistItem) {
    item.fromDate = event[0] ? event[0].toISOString().split('T')[0] : '';
    item.toDate = event[1] ? event[1].toISOString().split('T')[0] : '';
  }

  handleCheckListClick(form: ChecklistItem) {
    this.viewPDF.emit(form);
  }
}
