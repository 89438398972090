import { Component, OnInit } from '@angular/core';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzInputModule } from 'ng-zorro-antd/input';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CommonModule } from '@angular/common';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { RADIO_YES_NO_OPTIONS } from '../../../../shared/constants/static-data.constants';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { SectionsService } from '../../../../services/sections.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ActivatedRoute, Router } from '@angular/router';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { AccordionComponent } from '../../../../shared/components/accordion/accordion.component';
import { ProspectiveInfoComponent } from '../../../lead-details/prospective-participants/prospective-info/prospective-info.component';
import { PopupDialogComponent } from '../../../../shared/components/popup-dialog/popup-dialog.component';
import { ListTableComponent } from '../../../../shared/components/list-table/list-table.component';
import { LeadDetailsService } from '../../../../services/lead-details.service';
import { TableRowActionsModel } from '../../../../shared/interfaces/list.interface';
import { TableRowActions } from '../../../../shared/interfaces/actions';
import { EnrollmentDataService } from '../../../../services/enrollment-data.service';
import { ClaimsService } from '../../../../services/claims.service';
import { DateTimeFormatterService } from '../../../../shared/utils/date-time-formatter.service';
import { LookupService } from '../../../../services/lookup.service';

@Component({
  selector: 'ppl-add-participant-provider-rates',
  standalone: true,
  imports: [
    ButtonComponent,
    NzFormModule,
    ReactiveFormsModule,
    FormsModule,
    NzCardModule,
    NzInputModule,
    NzToolTipModule,
    CommonModule,
    NzDatePickerModule,
    NzToolTipModule,
    NzSelectModule,
    NzButtonModule,
    NzCollapseModule,
    AccordionComponent,
    ListTableComponent,
  ],
  templateUrl: './add-participant-provider-rates.component.html',
  styleUrl: './add-participant-provider-rates.component.css',
})
export class AddParticipantProviderRatesComponent
  extends BaseComponent
  implements OnInit
{
  participantProviderRates!: FormGroup;
  prospectiveParticipant!: FormGroup;
  radioOptions = RADIO_YES_NO_OPTIONS;
  providerTypes: any;
  pageTitle = 'Add New Consumer - Personal Assistant Rate';
  mode = 'edit';
  savedData: any = {};
  isNew = true;
  isParticipantSelected = false;
  showDialog = false;
  showSearchResults = false;
  searchResultsList: any[] = [];
  selectedParticipantDetails: any = {};
  isProviderDisabled = true;
  serviceCodes: any;
  program: any;
  cols = [
    {
      field: 'programCode',
      header: 'Program Name',
      jsonpath: 'programCode',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'firstName',
      header: 'First Name',
      jsonpath: 'firstName',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'lastName',
      header: 'Last Name',
      jsonpath: 'lastName',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'participantPPLId',
      header: 'Consumer’s PPL Id',
      jsonpath: 'participantPPLId',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'modifiedDate',
      header: 'Modified Date',
      jsonpath: 'modifiedDate',
      isDisplayedAtHeader: 'n',
      isSearchable: 'n',
    },
    {
      field: 'isVerified',
      header: 'Consumer Verified',
      jsonpath: 'isVerified',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
  ];
  actionButtons: TableRowActionsModel = new TableRowActions();

  participants: any;
  reselectAllowed = true;
  providers: any;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private sectionsService: SectionsService,
    private leadDetailsService: LeadDetailsService,
    private enrollmentDataService: EnrollmentDataService,
    private claimsService: ClaimsService,
    private dateTimeFormatterService: DateTimeFormatterService,
    private lookupService: LookupService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.participantProviderRates = this.fb.group({
      servicecodeDetailId: [null],
      participantId: [{ value: null, disabled: true }],
      providerId: [null],
      fromDate: [null],
      toDate: [null],
      rate: [[]],
    });

    this.prospectiveParticipant = this.fb.group({
      firstName: [''],
      lastName: [''],
      participantPPLId: [''],
    });

    this.route.paramMap.subscribe((params: any) => {
      const action = params.get('action')!;
      this.pageTitle =
        action == 'update' ? 'Consumer - Personal Assistant Rate Details' : this.pageTitle;
      this.isNew = action === 'add';
      this.mode = action == 'update' ? 'read' : 'edit';
    });

    this.sectionsService.setFlow('participantProviderRates');
    this.serviceCodes = this.lookupService.getTasLookupValue(
      'servicecodeDetailList'
    );
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    this.secData = this.sectionsService.getSectionData(
      this.program.program_code,
      'payroll'
    );
    this.providerTypes = this.getLookup('ProviderType');
    const assocRatesData =
      this.claimsService.getFormData('association_rates');
    this.savedData = assocRatesData;
    if(this.isNew){
    this.participantProviderRates
      .get('participantId')
      ?.valueChanges.subscribe((value) => {
        this.enrollmentDataService
          .getAssociationsDetails(
            this.program.program_code,
            this.selectedParticipantDetails.participantId,
            true, false
          )
          .subscribe((data: any) => {
            this.providers = data.responsedata.IR.map((provider: any) => ({
              lkpValueName: `${provider.providerFirstName} ${provider.providerLastName}`,
              lkpValueCode: provider.providerId,
            }));
          });
      });
    }
  }

  returnClicked() {
    this.router.navigate(['/home/participantProviderRatesList']);
  }

  goBack() {
    this.router.navigate(['/home/participantProviderRatesList']);
  }

  onEdit() {
    this.mode = 'edit';
    this.isProviderDisabled = false;
    this.reselectAllowed = false;
    this.participantProviderRates.patchValue({
      ...this.savedData,
      participantId: `${this.savedData.consumerFirstName } ${this.savedData.consumerLastName}`,
      providerId: `${this.savedData.providerFirstName } ${this.savedData.providerLastName}`
    });
    this.participantProviderRates.get('participantId')?.disable();
    this.participantProviderRates.get('providerId')?.disable();
    this.participantProviderRates.get('servicecodeDetailId')?.disable();
  }

  createPayload() {
    return {
      ...this.participantProviderRates.getRawValue(),
      fromDate: this.dateTimeFormatterService.formatToString(
        new Date(this.participantProviderRates.value.fromDate)
      ),
      toDate: this.dateTimeFormatterService.formatToString(
        new Date(this.participantProviderRates.value.toDate)
      ),
      programCode: this.program.program_code,
      participantId: this.selectedParticipantDetails.participantId,
    };
  }

  onAdd() {
    const payload = this.createPayload();
    this.claimsService
      .addPartcicipantProviderRates(payload, true)
      .subscribe((data: any) => {
        if (data.status == 200) {
          this.goBack();
        }
      });
  }

  getTasLookupName(lookupArr: any, valueCode: string): string | undefined {
    const lookup = lookupArr.find((gen: any) => {
      return gen.serviceCodeDetailId === valueCode;
    });
    return lookup ? lookup.code : undefined;
  }

  onCancel() {
    this.mode = 'read';
    this.validationErrors = {};
    this.participantProviderRates.reset();
  }

  onUpdate() {
    const assocRatesData =
      this.claimsService.getFormData('association_rates');
    const payload = {
      ...this.participantProviderRates.value,
      participantId: assocRatesData.participantId,
      providerId:assocRatesData.providerId,
      servicecodeDetailId: assocRatesData.servicecodeDetailId,
      programCode: this.program.program_code,
      fromDate: this.dateTimeFormatterService.formatToString(
        new Date(this.participantProviderRates.value.fromDate)
      ),
      toDate: this.dateTimeFormatterService.formatToString(
        new Date(this.participantProviderRates.value.toDate)
      )
    }
    this.claimsService.updateParticipantProviderRates(payload, assocRatesData.ptcPrvdrXRatesId).subscribe((data)=>{
      if (data.status == 200) {
        this.claimsService.getParticipantProviderRates(this.program.program_code, assocRatesData.ptcPrvdrXRatesId, true)
          .subscribe((data: any) => {
            this.savedData = data.responsedata;
          });
        this.mode = 'read';
      }
    })
  }

  viewerValueChange(value: string) {
    const viewerValue = [value][0];
    this.isParticipantSelected = true;
  }

  handleOk() {
    console.log('ok clicked');
  }

  handleCancel() {
    console.log('cancel clicked');
  }

  disabledStartDate = (startValue: Date): boolean => {
    const endDate = this.participantProviderRates.get('toDate')?.value;
    if (!startValue || !endDate || !(endDate instanceof Date)) {
      return false;
    }
    return startValue.getTime() > endDate.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    const startDate = this.participantProviderRates.get('fromDate')?.value;
    if (!endValue || !startDate || !(startDate instanceof Date)) {
      return false;
    }
    return endValue.getTime() <= startDate.getTime();
  };

  updatedLeadData(event: any) {
    console.log('cancel clicked', event);
  }

  add() {
    this.showDialog = true;
  }

  action(event: any): void {
    if (event.action === 'add') {
      this.showDialog = false;
      this.showSearchResults = false;
      // this.showDialog = true;
      this.selectedParticipantDetails = event.data;
      const selectedParticipant =
        event.data.firstName + ' ' + event.data.lastName;
      this.participantProviderRates.patchValue({
        participantId: selectedParticipant,
      });
      this.isProviderDisabled = false;
    }
  }

  reselect() {
    this.showDialog = true;
    this.showSearchResults = false;
    this.isProviderDisabled = true;
    this.prospectiveParticipant.reset();
  }

  search() {
    this.actionButtons.add = true;
    this.actionButtons.edit = false;
    this.actionButtons.delete = false;
    this.actionButtons.verify = false;
    const formData = this.prospectiveParticipant.value;

    if (
      !formData.firstName &&
      !formData.lastName &&
      !formData.participantPPLId
    ) {
      console.log('enter at least one search criteria');
      // this.showSearchError = true;
    } else {
      // this.showSearchError = false;
      const payload = {
        ...formData,
        programCode: this.program.program_code,
      };
      this.leadDetailsService
        .searchProspectiveParticipantDetails(payload, true)
        .subscribe((data: any) => {
          this.showSearchResults = true;
          if (data?.responsedata?.prspctvPrtcpntDetails) {
            this.searchResultsList = data?.responsedata?.prspctvPrtcpntDetails;
          } else {
            this.searchResultsList = [];
          }
        });
    }
  }
}
