<div class="dropdown-container">
    <button [type]="type" [disabled]="disabled" (click)="toggleDropdown()" [ngClass]="customClass">
      <ng-container *ngIf="icon && iconPosition === 'left'">
        <i class="fa fa-chevron-down"></i>
      </ng-container>
      {{ label }}
      <ng-container *ngIf="icon && iconPosition === 'right'">
        <i class="fa fa-chevron-down"></i>
      </ng-container>
    </button>
    <div class="dropdown-list" *ngIf="showDropdown">
        <div class="dropdown-item">
          @for (menuItem of menuItems; track $index) {
            
            @if(menuItem && menuItem.links && menuItem.links.length > 1){
              @for (subMenuItem of menuItem.links; track $index) {
                <ppl-menu-button
                  [label]="subMenuItem.sub_menu_label" 
                  [customClass]="'fancy-button'" 
                  [iconPosition]="'right'" 
                  [item]="subMenuItem"
                  (click)="OnMenuClick(subMenuItem)"     
                  ></ppl-menu-button>
              }
              
            }@else{
              <ppl-menu-button
                [label]="menuItem.menu_label ?? ''" 
                [customClass]="'fancy-button'" 
                [iconPosition]="'right'" 
                [item]="menuItem"
                (click)="OnMenuClick(menuItem)"     
                ></ppl-menu-button>
            }
            
          }

            <!-- <ppl-menu-button
            label="Enrollment" 
            icon="/assets/add_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg" 
            [customClass]="'fancy-button'" 
            [iconPosition]="'right'"        
            ></ppl-menu-button>
            <ppl-menu-button
            label="Referall" 
            icon="/assets/add_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg" 
            [customClass]="'fancy-button'" 
            [iconPosition]="'right'"        
            ></ppl-menu-button>
            <ppl-menu-button
            label="Providers" 
            icon="/assets/add_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg" 
            [customClass]="'fancy-button'" 
            [iconPosition]="'right'"        
            ></ppl-menu-button>
            <ppl-menu-button
            label="ParticpANT" 
            icon="/assets/add_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg" 
            [customClass]="'fancy-button'" 
            [iconPosition]="'right'"        
            ></ppl-menu-button>
            <ppl-menu-button
            label="Claims" 
            icon="/assets/add_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg" 
            [customClass]="'fancy-button'" 
            [iconPosition]="'right'"        
            ></ppl-menu-button>
            <ppl-menu-button
            label="Authorzaiton" 
            icon="/assets/add_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg" 
            [customClass]="'fancy-button'" 
            [iconPosition]="'right'"        
            ></ppl-menu-button> -->
        </div>
    </div>
  </div>
  