<div class="custom-row">
  @if(mode==="edit"){
  <ppl-button label="Cancel" icon="fa fa-ban" [customClass]="'alt-button'" [iconPosition]="'left'"
    (click)="cancelEdit()"></ppl-button>

  <ppl-button label="Save" icon="fa fa-floppy-o" [customClass]="'alt-button'" [iconPosition]="'left'"
    (click)="save()"></ppl-button>
  <!-- <span
    (click)="cancelEdit()"
    nz-icon
    nzType="close-circle"
    nzTheme="outline"
  ></span>
  <span nz-icon nzType="check-circle" (click)="save()" nzTheme="outline"></span> -->
  }
  @if(mode==="read" && leadStatus !== approvedStatus){
  @if(isSectionEditable) {
  <ppl-button label="Edit" icon="fa fa-pencil" [customClass]="'ghost-button'" [iconPosition]="'left'"
    (click)="editInfo()"></ppl-button>
  }
  <!-- <span
    (click)="editInfo()"
    nz-icon
    nzType="edit"
    nzTheme="outline"
  ></span> -->
  }

</div>
@if(mode==="edit" ){
<form nz-form [formGroup]="servicesForm" class="ppl-p0">

  <nz-form-item class="ppl-form-item">
    <nz-form-label nzSpan="4" nzFor="servicesRendered"
      class="ppl-form-label">{{getAttributes('servicesRendered').label}}
      @if(getAttributes('servicesRendered').mandatory === 'Y'){
      <span>*</span>
      }</nz-form-label>
    <nz-form-control nzSpan="8">
      <nz-select [nzMaxTagCount]="4" nzMode='multiple' [ngClass]="{'error': validationErrors['servicesRendered'] }"
        nzPlaceHolder="{{getAttributes('servicesRendered').placeholder}}" id="servicesRendered"
        formControlName="servicesRendered" style="width:100%; margin-bottom: 16px" nzShowArrow>
        @for(opt of services; track $index){
        <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
        }
      </nz-select>
      @if (validationErrors['servicesRendered']) {
      <span class="pl-1 error-message">{{validationErrors['servicesRendered']}}</span>
      }
    </nz-form-control>
  </nz-form-item>
</form>
}

@else if(mode==="read" && secData){

@if(filledInfo){
<div nz-row>
  <div nz-col nzSpan="4" class="ppl-read-label">{{getAttributes('servicesRendered').label}}</div>
  <div nz-col>
    {{lookupTransformed}}
  </div>

</div>
}@else {
<nz-row>
  <p>No Data found</p>
</nz-row>
}
}