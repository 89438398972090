<div class="ppl-mt8">
  @for(header of extractValues('header'); track $index){
  <h2>{{ header }}</h2>
  } @for(text of extractValues('text'); track $index){
  <p class="ppl-description">{{ text }}</p>
  }
</div>
@if (!isLoading) {
<div class="signature--container ppl-mt24">
  <ppl-signature
    [showButtons]="true"
    (fieldsChanged)="fieldsChanged($event)"
    [base64Image]="imageUrl"
    [flow] = "flow"
  ></ppl-signature>
</div>
}
