<div class="notification-dropdown">
    <button class="notification-button" (click)="toggleDropdown()">
      <i class="fa fa-bell"></i>
      <span class="notification-count">{{ notifications.length }}</span>
    </button>
  
    <div *ngIf="isDropdownOpen" class="dropdown-menu">
      <h4 class="dropdown-header"><i class="fa fa-bell ico" aria-hidden="true"></i> Notifications</h4>
      <ul>
        <li tabindex="0" *ngFor="let notification of notifications" (click)="viewNotification(notification)" (keypress.enter)="viewNotification(notification)" >
          <span class="notification-title">{{ notification }}</span>
          <!-- <span class="notification-time">{{ notification.time }}</span> -->
        </li>
        <li *ngIf="notifications.length === 0">No new notifications</li>
      </ul>
      <!-- <div style="width: 100%;">
        <ppl-button 
        label="View All" 
        icon="fa fa-list-ul" 
        [customClass]="'primary-button'" 
        [iconPosition]="'left'"
        (click)="viewAllNotifications()"
        ></ppl-button>         
      </div> -->

    </div>
  </div>
  