{
	"columns":[
		
		{
			"header_id": "tracking_number",
			"header_label": "Tracking Number",
			"jsonpath":  "tracking_number" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "lead_participant_id",
			"header_label": "Lead Id",
			"jsonpath":  "lead_participant_id" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "participant_id",
			"header_label": "Participant Id",
			"jsonpath":  "participant_id" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		},
		{
			"header_id": "f_name",
			"header_label": "First Name",
			"jsonpath":  "first_name" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "middle_name",
			"header_label": "Middle Name",
			"jsonpath":  "middle_name" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "l_name",
			"header_label": "Last Name",
			"jsonpath":  "last_name" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "date_of_birth",
			"header_label": "Date of Birth",
			"jsonpath":  "date_of_birth" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "maiden_previous",
			"header_label": "Maiden previous",
			"jsonpath":  "maiden_previous" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "final_applicant_disposition",
			"header_label": "Application Disposition",
			"jsonpath":  "final_applicant_disposition" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "program_id",
			"header_label": "Program ID",
			"jsonpath":  "program_id" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		},
		{
			"header_id": "decision_comments",
			"header_label": "Comments",
			"jsonpath":  "decision_comments" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "application_status_lkp",
			"header_label": "Application Status",
			"jsonpath":  "application_status_lkp" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "referral_id",
			"header_label": "Referral ID",
			"jsonpath":  "referral_id" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		},
		{
			"header_id": "application_assigned_to",
			"header_label": "Assigned To",
			"jsonpath":  "application_assigned_to" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "state_of_residence_lkp",
			"header_label": "Residence State",
			"jsonpath":  "state_of_residence_lkp" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "application_submission_date",
			"header_label": "Submission Date",
			"jsonpath":  "application_submission_date" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		}
		
		

    ],
	"buttons": [
		{
			"button_id":"assign_to_user",
			"button_label":"Assign to User",
			"buttonAction" : "assign_to_user",
			"className": "primary-btn-color"
		},
		{
			"button_id":"assign_to_me",
			"button_label":"Assign to Me",
			"buttonAction" : "assign_to_me",
			"className": "primary-btn-color"
		},
		{
			"button_id":"Approve",
			"button_label":"Approve",
			"buttonAction" : "approve",
			"className": "primary-btn-color"
		},
		{
			"button_id":"Deny",
			"button_label":"Deny",
			"buttonAction" : "Deny",
			"className": "primary-btn-color"
		},
		{
			"button_id":"Resurrect",
			"button_label":"Resurrect",
			"buttonAction" : "Resurrect",
			"className": "primary-btn-color"
		}
    ],
    "apiDetails": "referral.json",
	"pageName": "leadlist",
	"orderByColumn": ["tracking_number"],
	"orderBy": ["desc"]
}
 

 
