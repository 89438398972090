import { Component, OnInit } from '@angular/core';
import { AccordionComponent } from '../../../../shared/components/accordion/accordion.component';
import { FileUploadComponent } from '../file-upload/file-upload/file-upload.component';
import { AuthorizeDirective } from '../../../../shared/authorization/authorize.directive.';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { NotificationService } from '../../../../shared/services/notification.service';
import { STATIC_DATA } from '../../../../shared/constants/static-data.constants';
import { MEMBER_VIEW_PAGE_IDS, PAGE_CONSTANTS, TABS } from '../../../../shared/constants/page.constants';
import { SharedService } from '../../../../shared/services/shared.service';
import { SectionsService } from '../../../../services/sections.service';
import { AuthorizationUtility } from '../../../../shared/authorization/auth.utility';

@Component({
  selector: 'ppl-documents',
  standalone: true,
  imports: [AccordionComponent,
    FileUploadComponent, AuthorizeDirective
  ],
  templateUrl: './documents.component.html',
  styleUrl: './documents.component.css'
})
export class DocumentsComponent  extends BaseComponent implements OnInit {

  pageId = MEMBER_VIEW_PAGE_IDS[localStorage.getItem(STATIC_DATA.URL_FIRST_SEGMENT)!];
  tabId = TABS.Documents;
  entry = 'base';
  selectedLead: any;
  program: any;
  filedInfo: any;
  source!: string;
  steps: any;
  readonly = false;

  constructor(
    private notificationService: NotificationService,
    private sharedService: SharedService,
    private sectionsService: SectionsService,
    private authUtility: AuthorizationUtility
  ) {
    super();
  }

  ngOnInit(): void {
    this.source = 'ppl-lead-documents';
    this.readonly = !this.authUtility.isSectionEditable(this.pageId, this.tabId, "FileUpload");
    // const getEntry = localStorage.getItem("lead_entry");
    this.entry = localStorage.getItem("lead_entry")!;
    this.entry = (this.entry === 'providers' ? 'provider' : (this.entry === 'participants' ? 'participant' : this.entry));
    // switch(getEntry) {
    //     case STATIC_DATA.PARTICIPANT_ENROLLMENT:
    //       this.entry = STATIC_DATA.DOCUMENT_PARTICIPANT_ENROLLMENT_TYPE;
    //       break;
    //     case STATIC_DATA.PROVIDER_ENROLLMENT:
    //         this.entry = STATIC_DATA.DOCUMENT_PROVIDER_ENROLLMENT_TYPE;
    //         break;
    //     default:
    //       this.entry = localStorage.getItem("lead_entry")!;
    // }
    // this.pageId = this.entry == 'participant' ? PAGE_CONSTANTS.PARTICIPANT_INFORMATION : PAGE_CONSTANTS.PROVIDER_INFORMATION;
    this.program = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_PROGRAM)!);
    try {
      this.selectedLead = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!);
    } catch {
      this.notificationService.alert("error", "Error Fetching Data", "Error getting selected lead data");
    }
    this.getDocumentsUploaded();
    /* this.leadDetailsService.fetchLeadDocumentDetailsApi().subscribe((data)=>{
      console.log(data);
    }) */

     // this.getDocumentsUploaded(this.entry, this.selectedLead);
  }
  filesUpdated() {
    console.log('Files List update');
    this.getDocumentsUploaded(true);
  }

  getDocumentsUploaded(hasUpdatedEntry?: boolean) {
    this.sharedService.getUploadedFileDetails(this.program.program_code, this.selectedLead, this.entry, hasUpdatedEntry).subscribe((data: any) => {
      console.log(data);
      this.filedInfo = data?.responsedata;
    })
  }

}
