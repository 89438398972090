@if(mode==='read' && data ){
<div>
    <div nz-row>
        <div nz-col nzSpan="4" class="font-weight-600 pt-2 pb-2 pt-2 pb-2">Address Line 1</div>
        <div nz-col nzSpan="8" class="pt-2 pb-2">{{ data?.street1}}</div>

        <!-- <div nz-col nzSpan="4" class="font-weight-600 pt-2 pb-2">Address Line 2</div>
        <div nz-col nzSpan="8" class="pt-2 pb-2">{{ data?.street2}}</div> -->

        <div nz-col nzSpan="4" class="font-weight-600 pt-2 pb-2">City</div>
        <div nz-col nzSpan="8" class="pt-2 pb-2">{{ data?.city}}</div>
    </div>
    <div nz-row>
        <div nz-col nzSpan="4" class="font-weight-600 pt-2 pb-2">State</div>
        <div nz-col nzSpan="8" class="pt-2 pb-2">{{ data?.state ? getValue(data?.state) : data?.state}}</div>

        <div nz-col nzSpan="4" class="font-weight-600 pt-2 pb-2">Zip Code</div>
        <div nz-col nzSpan="8" class="pt-2 pb-2">{{ data?.zipCode}}</div>

    </div>
    <div nz-row>
        <div nz-col nzSpan="4" class="font-weight-600 pt-2 pb-2">County</div>
        <div nz-col nzSpan="8" class="pt-2 pb-2">{{ data?.countyOrRegion}}</div>

        <!-- <div nz-col nzSpan="4" class="font-weight-600 pt-2 pb-2">PSD Code details</div>
        <div nz-col nzSpan="8" class="pt-2 pb-2">{{ data?.psd_code}}</div> -->
    </div>
    
    @if(is_permanent){
    <div nz-row style="margin-top:8px">
        <div nz-col [nzXs]="24" [nzSm]="16" [nzMd]="16" [nzLg]="10" [nzXXl]="4" class="ppl-read-label">Is the Mailing
            address the same as the Physical Address?</div>
        <div nz-col>{{data?.mailing ? "Yes" : "No"}}</div>
    </div>
    }
    </div>
    @if(is_permanent && !data?.mailing){
        <nz-divider class="divider"></nz-divider>
    }
}
@if(mode==='edit' && addressForm){

<div nzSpan="12" style="margin-top:8px;margin-bottom:25px">
    <nz-row>
        <nz-form-label nzFor="searchAddress" nz-col nzSpan="4" style="font-weight: 600;">Search Address</nz-form-label>
        <div nz-col nzSpan="8">
            <nz-select nzId="searchAddress" nzShowSearch nzServerSearch nzPlaceHolder="Type your address" [(ngModel)]="selectedValue"
                [nzShowArrow]="false" [nzFilterOption]="nzFilterOptions" (nzOnSearch)="onSearch($event)"
                (ngModelChange)="validateAddress($event)">
                @for(option of listOfOptions; track $index){
                <nz-option [nzValue]="option.value" [nzLabel]="option.text"></nz-option>
                }
            </nz-select>
        </div>
    </nz-row>

</div>

<div [formGroup]="addressForm" class="lead-details-address">
    <nz-row>
        <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="street1" class="ppl-form-label">Address Line
                    1*</nz-form-label>
                <nz-form-control class="ppl-form-control">
                    <input nz-input formControlName="street1" id="street1" />
                    <span class="pl-1 error-message">{{validationErrors['street1']}}</span>
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        <!-- <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="street2" class="ppl-form-label pl-5 ml-2">Address Line
                    2</nz-form-label>
                <nz-form-control class="ppl-form-control">
                    <input nz-input formControlName="street2" id="street2" />
                </nz-form-control>
            </nz-row>
        </nz-form-item> -->
        <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item pl-2">
            <nz-row>
                <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="city" class="ppl-form-label">City*</nz-form-label>
                <nz-form-control class="ppl-form-control">
                    <input nz-input formControlName="city" id="city" />
                    <span class="pl-1 error-message">{{validationErrors['city']}}</span>
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item">
            <nz-row>
                <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="state" class="ppl-form-label">State*</nz-form-label>
                <nz-form-control class="ppl-form-control" nzTooltipTitle="Select State">
                    
                        <nz-select formControlName="state" class="ppl-select" nzId="state" nzShowSearch nzAllowClear
                            [ngClass]="{'error': validationErrors['state'] }">
                            @for(opt of states; track $index){
                            <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                            }
                        </nz-select>
                        <!--  <input nz-input formControlName="state" id="state" /> -->
                        <span class="pl-1 error-message">{{validationErrors['state']}}</span>
                    
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item pl-2">
            <nz-row>
                <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="zipCode" class="ppl-form-label">Zip Code*</nz-form-label>
                <nz-form-control class="ppl-form-control">
                    <input nz-input formControlName="zipCode" id="zipCode" />
                    <span class="pl-1 error-message">{{validationErrors['zipCode']}}</span>
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item">
            <nz-row>
                <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="countyOrRegion" class="ppl-form-label">County</nz-form-label>
                <nz-form-control class="ppl-form-control">
                    <input nz-input formControlName="countyOrRegion" id="countyOrRegion" />
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        <!-- <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item pl-2">
            <nz-row>
                <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="psd_code" class="ppl-form-label">PSD Code
                    Details</nz-form-label>
                <nz-form-control class="ppl-form-control">
                    <input nz-input formControlName="psd_code" id="psd_code" />
                    <span class="pl-1 error-message">{{validationErrors['psd_code']}}</span>
                </nz-form-control>
            </nz-row>
        </nz-form-item> -->
    </nz-row>
    @if(is_permanent){
    <nz-row>
        <nz-col nzSpan="12">
            <nz-form-item class="ppl-form-item">
                <nz-form-label [nzXs]="24" [nzSm]="21" [nzLg]="10" [nzXl]="9" [nzXXl]="4" nzFor="mailing"
                    class="ppl-form-label">Is the Mailing address the same
                    as the Physical Address?</nz-form-label>
                <nz-form-control class="ppl-form-control">
                    <nz-switch nzId="mailing" formControlName="mailing" pplNzAriaLabel nzAriaLabel="mailing"></nz-switch>
                </nz-form-control>
            </nz-form-item>
        </nz-col>
    </nz-row>

    }
</div>

}