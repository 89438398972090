import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { ReferralDataService } from '../../../services/referral-data.service';
import { SectionsService } from '../../../services/sections.service';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { ListTableComponent } from '../../../shared/components/list-table/list-table.component';
import { LeadSignatureComponent } from '../../../shared/components/signature/signature.component';
import { BaseComponent } from '../../../shared/utils/base.component';
import { STATIC_DATA } from '../../../shared/constants/static-data.constants';

@Component({
  selector: 'ppl-upload-signatures',
  standalone: true,
  imports: [
    NzCardModule,
    NzGridModule,
    NzFormModule,
    ReactiveFormsModule,
    ListTableComponent, CommonModule, LeadSignatureComponent,
    ButtonComponent],
  templateUrl: './upload-signatures.component.html',
  styleUrl: './upload-signatures.component.css'
})
export class UploadSignaturesComponent extends BaseComponent implements OnInit {
  @Input() mode = 'edit';
  licences: any[] = [];
  fileList: any = [];
  formHasBeenFilled: any;
  filledInfo: any = {};
  cols: any[] = [];
  imageUrl: any;
  isSignAdded = false;
  id: any;
  type: any;
  isLoading = false;
  flow: any;
  constructor(
    private sectionsService: SectionsService,
    private referralService: ReferralDataService,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    const program = JSON.parse(localStorage.getItem('selected_program')!);
    const steps = this.sectionsService.getSectionData(program.program_code);
    this.flow = this.sectionsService.getFlow();
    this.secData = steps['upload_signatures'];
    this.id = (this.flow === 'participantEnrollment') ? localStorage.getItem('participantId') : localStorage.getItem('providerId');
    this.type = (this.flow === 'participantEnrollment') ? 'participant' : 'provider';
    this.getSignature(program.program_code, this.type, this.id, false);

    this.referralService.nextClicked.subscribe((val: any) => {
      if(val === STATIC_DATA.SIGNATURES){
      this.referralService.setStepValidation({step: STATIC_DATA.SIGNATURES, val: this.imageUrl? true: false});
      }
    });
  }

  getSignature(program_code: string, type: string, id: string | null, isWriteRequest = false) {
    this.isLoading = true;
    this.referralService.getSignature(program_code, type, id, isWriteRequest).subscribe(
      {
        next: (data: any) => {
          this.isLoading = false;
          if (data?.responsedata && data?.responsedata.length) {
            const activeSign = data?.responsedata.find((data: any) => data.isActive);
            this.isSignAdded = true;

            this.imageUrl = activeSign?.signatureImageBase64 ? `data:image/png;base64,${activeSign?.signatureImageBase64}` : undefined;
            // this.referralService.setReferralFormData(
            //   'isSignaturesFormDirty', true
            // );
            // this.referralService.setReferralFormData(
            //   'isSignaturesFormUpdated', true
            // );
          }
        },
        error: () => {
          this.isLoading = false;
        }
      })
  }

  updateSignature() {
    this.isSignAdded = !this.isSignAdded;
  }

  fieldsChanged(event: any) {
    const program = JSON.parse(localStorage.getItem('selected_program')!);
    if (event.isEntered) {
      const base64Data = event.dataURL.split(',')[1];
      const payload = {
        value: event.name,
        signatureImage: base64Data,
        id: this.id,
        type: this.type,
        programCode: program.program_code,
      }
      this.referralService.saveSignature(payload, true).subscribe(() => {
        this.isSignAdded = true;
        this.getSignature(program.program_code, this.type, this.id, true);
      })
    }
    else {
      this.isSignAdded = true;
      this.getSignature(program.program_code, this.type, this.id, false);
    }
  }

}
