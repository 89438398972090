import { Injectable } from '@angular/core';
import { StepInfo } from '../../interfaces/referralSections.interface';
import { LookupDomain, LookupValueItem } from '../../interfaces/lookup.interface';

@Injectable({
  providedIn: 'root'
})
export class DetailsHelperService {

  checkIfButtonAvaibale(secData: any, buttonName: any) {
    const action = secData.available_actions.find((action: any) => action.button_name === buttonName);
    return action ? action.isAvailable === "Y" : false;
  }

  checkIfAttributeAvaibale(secData: any, attributeId: any) {
    const action = secData?.attributes?.find((attribute: any) => attribute.attribute_ID === attributeId);
    return action ? action.isAvailable === "Y" : false;
  }

  checkIfAttributeMandatory(secData: any, attributeId: any) {
    const attribute = secData.attributes.find((attribute: any) => attribute.attribute_ID === attributeId);
    return attribute ? attribute.mandatory === "Y" : false;
  }

  getAttributes(secData: any, id: any) {
    return secData.attributes.find((attribute: any) => attribute.attribute_ID === id);
  }

  getSteps(data: StepInfo[], id: string) {
    return data.find((step: StepInfo) => step.step_id === id);
  }

  extractValues(data: any, keyToExtract: string): string[] {
    const values: string[] = [];
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key) && key.startsWith(keyToExtract)) {
        values.push(data[key]);
      }
    }
    return values;
  }

  getLookupValue(lookupArr: LookupValueItem[], valueCheck: string | string[]) {
    if (Array.isArray(valueCheck)) {
      return valueCheck.map(c => this.getLookupName(lookupArr, c));
    } else {
      return this.getLookupName(lookupArr, valueCheck);
    }
  }

  getLookupName(lookupArr: LookupValueItem[], valueCode: string): string | undefined {
    const lookup = lookupArr.find((gen: LookupValueItem) => {
      return gen.lkpValueCode === valueCode;
    });
    return lookup ? lookup.lkpValueName : undefined;
  }

  getLookup(key: string) {
    let lookupValues: LookupValueItem[] = [];
    const lookupResponse = JSON.parse(localStorage.getItem("lookup_values")!);
    const lookupDomains = lookupResponse?.responsedata ?? [] as LookupDomain[];
    const targetDomain = lookupDomains.find((value: LookupDomain) => value.lkpDomainName === key);
    if (targetDomain) {
      lookupValues = [...targetDomain.lookupValue];
    }
    return lookupValues;
  }
}
