@if(mode==='read'){
<div>
    <div nz-row>
        <div nz-col nzSpan="8">Address Line 1</div>
        <div nz-col nzSpan="16">{{ data?.street1}}</div>
    </div>
    <div nz-row>
        <div nz-col nzSpan="8">Address Line 2</div>
        <div nz-col nzSpan="16">{{ data?.street2}}</div>
    </div>
    <div nz-row>
        <div nz-col nzSpan="8">City</div>
        <div nz-col nzSpan="16">{{ data?.city}}</div>
    </div>
    <div nz-row>
        <div nz-col nzSpan="8">State</div>
        <div nz-col nzSpan="16">{{ data?.state}}</div>
    </div>
    <div nz-row>
        <div nz-col nzSpan="8">Zip Code</div>
        <div nz-col nzSpan="16">{{ data?.zipCode}}</div>
    </div>
    <div nz-row>
        <div nz-col nzSpan="8">County</div>
        <div nz-col nzSpan="16">{{ data?.countyOrRegion}}</div>
    </div>
    @if(is_permanent){
    <div nz-row style="margin-top:8px">
        <div nz-col nzSpan="11" class="ppl-read-label" style="margin-right:8px">Is the Mailing address the same as the
            Physical Address?</div>
        <div nz-col nzSpan="12">{{data?.mailing? "Yes" : "No"}}</div>
    </div>
    }
</div>
}
@else{
<div nz-row style="margin-top:4px;margin-bottom:10px">
    <nz-form-label nzFor="Search Address" nz-col nzSpan="24" [nzSm]="24" [nzXs]="24" style="font-weight: 600;">Search Your Address</nz-form-label>
    <div nz-col nzSpan="16">
        <nz-select nzId="Search Address" nzShowSearch nzServerSearch nzPlaceHolder="Type your address" [(ngModel)]="selectedValue"
            [nzShowArrow]="false" [nzFilterOption]="nzFilterOptions" (nzOnSearch)="onSearch($event)"
            (ngModelChange)="validateAddress($event)" [nzDisabled]="formReadonly">
            @for(option of listOfOptions; track $index){
            <nz-option [nzValue]="option.value" [nzLabel]="option.text"></nz-option>
            }
        </nz-select>
    </div>
</div>
<div nz-row [formGroup]="addressForm">
    <nz-col [nzSpan]="16">
        <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="street1" class="ppl-form-label">Address Line
                1</nz-form-label>
            <nz-form-control class="ppl-form-control">
                <input [ngClass]="{'error': (validationErrors['street1']) }"
                    nz-input formControlName="street1" id="street1" [readonly]="formReadonly" />
                @if (validationErrors['street1']) {
                <span class="pl-1 error-message">{{validationErrors['street1']}}</span>
                }
            </nz-form-control>
        </nz-form-item>
    </nz-col>

    <nz-col [nzSpan]="8">
        <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSpan]="24" nzFor="city" class="ppl-form-label" [ngStyle]="{ 'margin-bottom':'0' }">City
                </nz-form-label>
            <nz-form-control class="ppl-form-control">
                <input [ngClass]="{'error': (validationErrors['city']) }" nz-input
                    formControlName="city" id="city" [readonly]="formReadonly" />
                @if (validationErrors['city']) {
                <span class="pl-1 error-message">{{validationErrors['city']}}</span>
                }
            </nz-form-control>
        </nz-form-item>
    </nz-col>
    <nz-row>
        <nz-col [nzSpan]="8">
            <nz-form-item class="ppl-form-item">
                <nz-form-label [nzSpan]="24" nzFor="state" class="ppl-form-label"
                    [ngStyle]="{ 'margin-bottom':'0' }">State
                    </nz-form-label>
                <nz-form-control class="ppl-form-control">
                    <input [ngClass]="{'error': validationErrors['state']  }"
                        nz-input formControlName="state" id="state" [readonly]="formReadonly" />
                    @if (validationErrors['state']) {
                    <span class="pl-1 error-message">{{validationErrors['state']}}</span>
                    }
                </nz-form-control>
            </nz-form-item>
        </nz-col>
        <nz-col [nzSpan]="8">
            <nz-form-item class="ppl-form-item">
                <nz-form-label [nzSpan]="24" nzFor="zipCode" class="ppl-form-label"
                    [ngStyle]="{ 'margin-bottom':'0' }">Zip Code
                    </nz-form-label>
                <nz-form-control class="ppl-form-control">
                    <input [ngClass]="{'error': (validationErrors['zipCode'])  }"
                        nz-input formControlName="zipCode" id="zipCode" [readonly]="formReadonly" />
                    @if (validationErrors['zipCode']) {
                    <span class="pl-1 error-message">{{validationErrors['zipCode']}}</span>
                    }
                </nz-form-control>
            </nz-form-item>
        </nz-col>
        <nz-col [nzSpan]="8">
            <nz-form-item class="ppl-form-item">
                <nz-form-label [nzSpan]="24" nzFor="countyOrRegion" class="ppl-form-label"
                    [ngStyle]="{ 'margin-bottom':'0' }">County</nz-form-label>
                <nz-form-control class="ppl-form-control">
                    <input
                        [ngClass]="{'error': (validationErrors['countyOrRegion']) }"
                        nz-input formControlName="countyOrRegion" id="countyOrRegion" [readonly]="formReadonly" />
                    @if (validationErrors['countyOrRegion']) {
                    <span class="pl-1 error-message">{{validationErrors['countyOrRegion']}}</span>
                    }
                </nz-form-control>
            </nz-form-item>
        </nz-col>

    </nz-row>

</div>
}