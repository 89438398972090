<ng-container>
    @if(isPageRestrictionLoaded){
    <div class="ppl-container-body">
        <div class="ppl-container-head">
            Consumer Registration
            <div class="action">
                <ppl-button label="Create New Consumer Registration" icon="fa fa-plus" [customClass]="'primary-button'"
                    [iconPosition]="'left'" [authorizeButton]="[pageId, 'NewParticipantEnrollment']"
                    (click)="onNewEnrollment()"></ppl-button>
                <!-- <ppl-button label="Create New Vendor" icon="fa fa-plus" [customClass]="'primary-button'"
                    [iconPosition]="'left'" [authorizeButton]="[pageId, 'newProviderReferral']"
                    (click)="onNewReferral()" style="margin: 0 8px"></ppl-button>
                <ppl-button label="Create New Independent Contractor" icon="fa fa-plus" [customClass]="'primary-button'"
                    [iconPosition]="'left'" [authorizeButton]="[pageId, 'newProviderReferral']"
                    (click)="onNewReferral()"></ppl-button> -->
            </div>
        </div>
        <div class="ppl-container">
            <div class="ppl-content-container">
                <div class="table-style">
                    <ppl-list-table
                        [pageConfig]="programName"
                        [sharedDataKey]="sharedDataKey"
                        (rowDetailEvent)="rowClicked($event)"
                        [loadPage]="lazyLoad"
                        [columnsToBeHidden]="columnsToBeHidden"
                        [actionsToBeHidden]="actionsToBeHidden"
                        [jsonList]="'list_participant_enrollments'"
                        rowDataAccessibilityKey="referral_tracking_number"
                        >
                    </ppl-list-table>
                </div>
            </div>
        </div>
    </div>
    }

</ng-container>
