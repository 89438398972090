import { CanDeactivateFn } from "@angular/router";
import { ParticipantLeadsComponent } from "../components/participant-leads/participant-leads.component";
import { ProviderLeadsListComponent } from "../components/provider-leads-list/provider-leads-list.component";
import { ParticipantLeadsListComponent } from "../components/participant-leads-list/participant-leads-list.component";

export const clearParticipantLeadSharedDataGaurd: CanDeactivateFn<ParticipantLeadsComponent> = (component: ParticipantLeadsComponent): boolean => {
    component.clearData();
    return true;
}

export const clearListSharedDataGuard: CanDeactivateFn<ParticipantLeadsListComponent> = (component: ParticipantLeadsListComponent): boolean => {
    component.clearData();
    return true;
}

export const clearProviderLeadSharedDataGaurd: CanDeactivateFn<ProviderLeadsListComponent> = (component: ProviderLeadsListComponent): boolean => {
    component.clearData();
    return true;
}