import { Component, OnInit } from '@angular/core';
import { ListTableComponent } from "../../../../shared/components/list-table/list-table.component";

@Component({
  selector: 'ppl-auth-timesheets',
  standalone: true,
  imports: [ListTableComponent],
  templateUrl: './auth-timesheets.component.html',
  styleUrl: './auth-timesheets.component.css'
})
export class AuthTimesheetsComponent implements OnInit {
  mode = 'read'
  programName!: string;
  columnsToBeHidden: string[] = [];
  sharedDataKey!: string;
  actionsToBeHidden: string[] = [];
  lazyLoad = true;
  isPageRestrictionLoaded = false;

  //  Associated json: src\assets\data\programs\NY\list_pages\list_auth_timesheets.json
  // 👇 Dummy data, modify at your leisure
  tableData = [
    {
        timesheetNumber: '01',
        providerId: '<dummy provider id>',
        date: '11/8/2024',
        authAmount: '700'
    },
    {
      timesheetNumber: '02',
      providerId: '<dummy provider id>',
      date: '10/9/2024',
      authAmount: '1200'
  },
  ]

  ngOnInit() {
    const selectedProgram = JSON.parse(localStorage.getItem('selected_program')!);
    this.programName = selectedProgram.program_code;
    this.isPageRestrictionLoaded = true;
  }

  rowClicked(event: unknown){
    console.log('🔴 auth-timesheets table row clicked. |>', event)
  }
}
