import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeService, ThemeType } from '../../../../../services/theme.service';

@Component({
    selector: 'app-font-selector',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './font-selector.component.html',
    styleUrls: ['./font-selector.component.css']
})
export class FontSelectorComponent {
    fontSizes = [
        { label: 'Small', value: '14px' },
        { label: 'Medium', value: '16px' },
        { label: 'Large', value: '20px' }
    ];

    selectedFontSize = '14px'; // Default to Small

    constructor(private themeService: ThemeService) {
        this.setFontSize(this.selectedFontSize);
    }

    onFontSizeChange(event: any) {
        const fontSize = event.target.value;
        this.setFontSize(fontSize);
    }

    setFontSize(fontSize: string) {
        document.documentElement.style.fontSize = fontSize;
        document.body.style.fontSize = fontSize;
        this.themeService.setTheme(this.getThemeType(fontSize));
    }

    getThemeType(fontSize: string) {
        switch (fontSize) {
            case '14px':
                return ThemeType.small;
            case '16px':
                return ThemeType.medium;
            case '20px':
                return ThemeType.large;
            default:
                return ThemeType.small;
        }
    }
}