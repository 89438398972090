import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { GlobalErrorHandlerService } from '../shared/services/global-error-handler.service';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {

  const errorHandler = inject(GlobalErrorHandlerService);

  return next(req).pipe(
    catchError((error : HttpErrorResponse) => {
      return throwError(()=>errorHandler.handleError(error));
    })  
  );
};
