@if(mode==="edit"){
  @if(source !== 'enrollment'){
    <div class="ppl-mt8">
      @for(header of extractValues('header'); track $index){
      <h2>{{header}}</h2>
      }
      @for(text of extractValues('text'); track $index){
      <p class="ppl-description">{{text}}</p>
      }
    </div>
  }
<div [ngClass]="{'ppl-mt24': source !== 'enrollment'}">
  <nz-card nz-col [nzSpan]="source !== 'enrollment' ? 20 : 24" class="ppl-p24 ppl-step-main" [nzBordered]="false" >
    <form nz-form [formGroup]="servicesForm" class="ppl-p0">
      @if(isAttrAvailable('profLicences')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="profLicences"
          class="ppl-form-label">{{getAttributes('profLicences').label}}
          @if(getAttributes('profLicences').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('profLicences')?.tooltip || ''"
            nzTooltipPlacement="topRight">
            <nz-select [nzMaxTagCount]="4" nzMode='multiple' [ngClass]="{'error': validationErrors['profLicences'] }"
              nzPlaceHolder="{{getAttributes('profLicences').placeholder}}" id="profLicences"
              formControlName="profLicences" style="width:100%; margin-bottom: 16px" nzShowArrow>
              @for(opt of licences; track $index){
              <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
              }
            </nz-select>
          </div>
          @if (validationErrors['profLicences']) {
          <span class="pl-1 error-message">{{validationErrors['profLicences']}}</span>
          }
        </nz-form-control>
      </nz-form-item>
    }
      @if(isAttrAvailable('filesUploaded')){
      <nz-form-item class="ppl-form-item pt-2">
        <nz-form-label [nzSm]="9">
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <p style="font-size:0.929rem;">Please upload supporting documents</p>
          <ppl-upload (filesUploaded)="filesUploaded($event)" [fileList]="fileList"
          [docTypeList] = "licences" [flow]="'providerenrlmnt'" [leadId]="leadId" [showFileList]="true"></ppl-upload>
        </nz-form-control>
      </nz-form-item>
      }
      @if(isAttrAvailable('servicesRendered')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="servicesRendered"
          class="ppl-form-label">{{getAttributes('servicesRendered').label}}
          @if(getAttributes('servicesRendered').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control">
          <nz-select [nzMaxTagCount]="4" nzMode='multiple' [ngClass]="{'error': validationErrors['servicesRendered'] }"
            nzPlaceHolder="{{getAttributes('servicesRendered').placeholder}}" id="servicesRendered" formControlName="servicesRendered"
            style="width:100%; margin-bottom: 16px" nzShowArrow>
            @for(opt of services; track $index){
            <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
            }
          </nz-select>
          @if (validationErrors['servicesRendered']) {
          <span class="pl-1 error-message">{{validationErrors['servicesRendered']}}</span>
          }
        </nz-form-control>
      </nz-form-item>
      }
     @if(isAttrAvailable('participantInfo')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="participantInfo"
          class="ppl-form-label">{{getAttributes('participantInfo').label}}
          @if(getAttributes('participantInfo').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control">
          <div formArrayName="prospectiveParticipants">
            @for(info of prospectiveParticipants.controls; track $index){
            <div [formGroupName]="$index" class="row-container grid">
              <input nz-input formControlName="firstName" placeHolder="First Name" />
              <input nz-input formControlName="lastName" placeHolder="Last Name" />
              <input nz-input formControlName="phoneNum" placeHolder="Phone Number" />
              <!-- Code for Validation -->
              <!-- <input nz-input formControlName="phoneNum" placeHolder="Phone Number" [ngClass]="{'error': info.get('phoneNum')?.hasError('pattern') }"/> -->
              @if($index > 0){
              <button 
                aria-label="Delete"
                nz-button 
                nzType="default" 
                (click)="removeRow($index)" 
                class="remove-btn"
              >
                <span 
                  nz-icon
                  nzType="delete" 
                  nzTheme="outline"
                ></span>
              </button>
              }
              @if($index === 0){
              <button 
                aria-label="Add row"
                nz-button 
                nzType="primary" 
                (click)="addRow()" 
                class="add-btn"
              >
                <span 
                  nz-icon 
                  nzType="plus"
                  nzTheme="outline">
                </span>
            </button>
              }
            </div>
            @if(prospectiveParticipantsErrors?.length > 0){
            <span class="pl-1 error-message">{{prospectiveParticipantsErrors[$index].phoneNum}}</span>
            }
            <!-- Code for Validation -->
            <!-- @if(info.get('phoneNum')?.invalid ){
              @if(info.get('phoneNum')?.hasError('pattern')){
                
              }
            } -->
            }
          </div>
        </nz-form-control>
      </nz-form-item>
      }

      @if(isAttrAvailable('availabilityDays')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="availabilityDays"
          class="ppl-form-label">{{getAttributes('availabilityDays').label}}
          @if(getAttributes('availabilityDays').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control" nz-tooltip
          nzTooltipTitle="{{getAttributes('availabilityDays').tooltip}}" nzTooltipPlacement="topRight">
          <div [formGroup]="servicesForm">
            <div formArrayName="availabilityDays">
              <ng-container>
                @for(day of daysOfWeek; track $index){
                <label nz-checkbox [formControlName]="$index">
                  {{ day }}
                </label>
                }
              </ng-container>
            </div>
          </div>
          @if (validationErrors['availabilityDays']) {
          <span class="pl-1 error-message">{{validationErrors['availabilityDays']}}</span>
          }
        </nz-form-control>
      </nz-form-item>

      }
      @if(isAttrAvailable('availabilityTiming')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="availabilityTiming"
          class="ppl-form-label">{{getAttributes('availabilityTiming').label}}
          @if(getAttributes('availabilityTiming').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <div class="time-picker" [ngClass]="{'error': validationErrors['availabilityTiming'] }">
          <nz-time-picker [nzSize]="'small'" [nzUse12Hours]="true" [nzFormat]="'h:mm a'" [nzMinuteStep]="15"
            formControlName="availabilityTimingStart" id="availabilityTimingStart"
            [nzPlaceHolder]="getAttributes('availabilityTiming').placeholder" nzBorderless></nz-time-picker>
          <nz-time-picker [nzSize]="'small'" [nzUse12Hours]="true" [nzFormat]="'h:mm a'" [nzMinuteStep]="15"
            formControlName="availabilityTimingEnd" id="availabilityTimingEnd"
            [nzPlaceHolder]="getAttributes('availabilityTiming').placeholder" nzBorderless></nz-time-picker>
        </div>
        @if (validationErrors['availabilityTiming']) {
        <span class="pl-1 error-message">{{validationErrors['availabilityTiming']}}</span>
        }
      </nz-form-item>
      }
      @if(isAttrAvailable('distanceWillingToTravel')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="distanceWillingToTravel"
          class="ppl-form-label">{{getAttributes('distanceWillingToTravel').label}}
          @if(getAttributes('distanceWillingToTravel').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('distanceWillingToTravel')?.tooltip || ''"
            nzTooltipPlacement="topRight">
            <input nz-input formControlName="distanceWillingToTravel" id="distanceWillingToTravel"
              placeholder="{{getAttributes('distanceWillingToTravel').placeholder}}"
              [ngClass]="{'error': validationErrors['distanceWillingToTravel'] }" />
          </div>
          @if (validationErrors['distanceWillingToTravel']) {
          <span class="pl-1 error-message">{{validationErrors['distanceWillingToTravel']}}</span>
          }
        </nz-form-control>
      </nz-form-item>
      }
      @if(isAttrAvailable('isAvailableForEmergencies')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="isAvailableForEmergencies"
          class="ppl-form-label">{{getAttributes('isAvailableForEmergencies').label}}
          @if(getAttributes('isAvailableForEmergencies').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control [nzSm]="16" class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('isAvailableForEmergencies')?.tooltip || ''"
            nzTooltipPlacement="topRight">
            <nz-radio-group [ngClass]="{'error': validationErrors['isAvailableForEmergencies'] }"
              formControlName="isAvailableForEmergencies" class="ppl-radio-group" id="isAvailableForEmergencies">
              @for(opt of radioOptions; track $index){
                <label nz-radio-button [nzValue]="opt.value">{{opt.label}}</label>
              }
            </nz-radio-group>
          </div>
          @if (validationErrors['isAvailableForEmergencies']) {
          <span class="pl-1 error-message">{{validationErrors['isAvailableForEmergencies']}}</span>
          }
        </nz-form-control>
      </nz-form-item>
      }
      @if(servicesForm.get('isAvailableForEmergencies')?.value === 'Yes'){
      @if(isAttrAvailable('dayEmergencyAvailablity')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="dayEmergencyAvailablity"
          class="ppl-form-label">{{getAttributes('dayEmergencyAvailablity').label}}
          @if(getAttributes('dayEmergencyAvailablity').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <div class="time-picker" [ngClass]="{'error': validationErrors['dayEmergencyAvailablity'] }">
          <nz-time-picker [nzSize]="'small'" [nzFormat]="'h:mm a'" [nzMinuteStep]="15"
            formControlName="dayEmergencyAvailablityStart" id="dayEmergencyAvailablityStart"
            [nzDisabledHours]="disabledNightHours"
            [nzPlaceHolder]="getAttributes('dayEmergencyAvailablity').placeholder" nzBorderless></nz-time-picker>
          <nz-time-picker [nzSize]="'small'" [nzFormat]="'h:mm a'" [nzDisabledHours]="disabledNightHours"
            [nzMinuteStep]="15" formControlName="dayEmergencyAvailablityEnd" id="dayEmergencyAvailablityEnd"
            [nzPlaceHolder]="getAttributes('dayEmergencyAvailablity').placeholder" nzBorderless></nz-time-picker>
        </div>
        @if (validationErrors['dayEmergencyAvailablity']) {
        <span class="pl-1 error-message">{{validationErrors['dayEmergencyAvailablity']}}</span>
        }
      </nz-form-item>
      }
      @if(isAttrAvailable('nightEmergencyAvailablity')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="nightEmergencyAvailablity"
          class="ppl-form-label">{{getAttributes('nightEmergencyAvailablity').label}}
          @if(getAttributes('nightEmergencyAvailablity').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <div class="time-picker" [ngClass]="{'error': validationErrors['nightEmergencyAvailablity'] }">
          <nz-time-picker [nzSize]="'small'" [nzFormat]="'h:mm a'" [nzMinuteStep]="15"
            formControlName="nightEmergencyAvailablityStart" id="nightEmergencyAvailablityStart"
            [nzDisabledHours]="disabledDayHours"
            [nzPlaceHolder]="getAttributes('nightEmergencyAvailablity').placeholder" nzBorderless></nz-time-picker>
          <nz-time-picker [nzSize]="'small'" [nzDisabledHours]="disabledDayHours" [nzFormat]="'h:mm a'"
            [nzMinuteStep]="15" formControlName="nightEmergencyAvailablityEnd" id="nightEmergencyAvailablityEnd"
            [nzPlaceHolder]="getAttributes('nightEmergencyAvailablity').placeholder" nzBorderless></nz-time-picker>
        </div>
        @if (validationErrors['nightEmergencyAvailablity']) {
        <span class="pl-1 error-message">{{validationErrors['nightEmergencyAvailablity']}}</span>
        }
      </nz-form-item>
      }
      }
    </form>
  </nz-card>
</div>
}
@else if(mode==="read"){
@if(isAttrAvailable('profLicences')){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('profLicences').label}} :</div>
  <div nz-col nzSpan="16">{{getLookupValue(licences, filledInfo['profLicences'])}}</div>
</div>
}
@if(isAttrAvailable('filesUploaded')){
<div nz-row>
  <div nz-col nzSpan="6" class="ppl-read-label">Files Uploaded:</div>
  <div nz-col nzSpan="18">
    @if(hasUploadedFile() && cols.length > 0){
      <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="false" [data]="filledInfo['filesUploaded']"
          [disableSelection]="true" [cols]="cols" [tableWidth]="'40rem'" [gridTitle]="false" [pagination]="false" [loadPage]="true">
      </ppl-list-table>
      <!-- @for(file of filledInfo['filesUploaded']; track $index){
        <div><strong>{{ file.type }}</strong></div>
        <div>{{ file.name }}</div>
      } -->
     }
  </div>
  </div>
}
@if(isAttrAvailable('servicesRendered')){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{ getAttributes('servicesRendered').label }} :</div>
  <div nz-col nzSpan="16">{{ getLookupValue(services, filledInfo['servicesRendered'])}}</div>
</div>
}
@if(isAttrAvailable('participantInfo')){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{ getAttributes('participantInfo').label }} :</div>
  <div nz-col nzSpan="16">
    @if(hasFilledParticipantInfo()){
    @for(participant of filledInfo['prospectiveParticipants']; track $index){
    <div><strong>Consumer {{ $index + 1 }}:</strong></div>
    <div>First Name: {{ participant.firstName }}</div>
    <div>Last Name: {{ participant.lastName }}</div>
    <div>Phone Number: {{ participant.phoneNum }}</div>
    @if($index < filledInfo['prospectiveParticipants'].length - 1){ <hr />
    }
    }
    }
  </div>
</div>
}
@if(isAttrAvailable('availabilityDays')){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('availabilityDays').label}} :</div>
  <div nz-col nzSpan="16">{{filledInfo['availabilityDays']}}</div>
</div>
}
@if(isAttrAvailable('availabilityTiming')){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('availabilityTiming').label}} :</div>
  <div nz-col nzSpan="16">{{filledInfo['availabilityTimingStart'] | date:'h:mm a'}}
    {{filledInfo['availabilityTimingEnd'] |
    date:'h:mm a'}}</div>
</div>
}
@if(isAttrAvailable('distanceWillingToTravel')){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('distanceWillingToTravel').label}} :</div>
  <div nz-col nzSpan="16">{{ filledInfo['distanceWillingToTravel']}}</div>
</div>
}
@if(isAttrAvailable('isAvailableForEmergencies')){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('isAvailableForEmergencies').label}} :</div>
  <div nz-col nzSpan="16">{{ filledInfo['isAvailableForEmergencies']}}</div>
</div>
}
@if(servicesForm.get('isAvailableForEmergencies')?.value === 'Yes'){
@if(isAttrAvailable('dayEmergencyAvailablity')){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('dayEmergencyAvailablity').label}} :</div>
  <div nz-col nzSpan="16">{{filledInfo['dayEmergencyAvailablityStart'] | date:'h:mm a'}}
    {{filledInfo['dayEmergencyAvailablityEnd'] | date:'h:mm a'}}</div>
</div>
}
@if(isAttrAvailable('nightEmergencyAvailablity')){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('nightEmergencyAvailablity').label}} :</div>
  <div nz-col nzSpan="16">{{filledInfo['nightEmergencyAvailablityStart'] | date:'h:mm a'}}
    {{filledInfo['nightEmergencyAvailablityEnd'] | date:'h:mm a'}}</div>
</div>
}
}
}