import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/utils/base.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { UploadComponent } from '../../../shared/components/upload/upload.component';
import { RADIO_YES_NO_OPTIONS } from '../../../shared/constants/static-data.constants';
import { SectionsService } from '../../../services/sections.service';

@Component({
  selector: 'ppl-payment-details',
  standalone: true,
  imports: [
    NzFormModule,
    FormsModule,
    ReactiveFormsModule,
    NzGridModule,
    NzCardModule,
    NzRadioModule,
    NzDatePickerModule,
    CommonModule,
    NzToolTipModule,
    NzIconModule,
    NzInputModule,
    NzSelectModule,
    UploadComponent,
    NzDividerModule,
  ],
  templateUrl: './payment-details.component.html',
  styleUrl: './payment-details.component.css',
})
export class PaymentDetailsComponent extends BaseComponent implements OnInit {
  @Input() mode = 'edit';
  paymentDetailsForm!: FormGroup;
  radioOptions = RADIO_YES_NO_OPTIONS;

  constructor(
    private fb: FormBuilder,
    private sectionService: SectionsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.paymentDetailsForm = this.fb.group({
      paymentMethod: [null],
      bankName: [''],
      accountType: [''],
      routingNumber: [''],
      accountNumber: [''],
      payStub: [null],
    });

    const program = JSON.parse(localStorage.getItem('selected_program')!);
    const steps = this.sectionService.getSectionData(program.program_code, '');
    this.secData = steps['payment_details'];
  }
}
