import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {  NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import {  NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { Address } from '../../../interfaces/referralSections.interface';
import { NzAriaLabelDirective } from '../../directives/nz-aria-label.directive';

const key = 'TA93-MT53-WE43-EC19';

@Component({
  selector: 'ppl-address',
  standalone: true,
  imports: [
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    NzIconModule,
    FormsModule,
    ReactiveFormsModule,
    NzSwitchModule,
    CommonModule,
    NzAriaLabelDirective
  ],
  templateUrl: './address.component.html',
  styleUrl: './address.component.css'
})
export class AddressComponent{
  @Input() addressForm!: FormGroup;
  @Input() mode = 'edit';
  @Input() data: Address;
  @Input() is_permanent = true;
  @Input() showPSD = true;

  private _permanentValidationErrors =  {};

  @Input() set permanentValidationErrors(value) {
    this._permanentValidationErrors = value || {};
  }

  get permanentValidationErrors() {
    return this._permanentValidationErrors;
  }

  private _mailingValidationErrors=  {};

  @Input() set mailingValidationErrors(value) {
    this._mailingValidationErrors = value || {};
  }

  get mailingValidationErrors() {
    return this._mailingValidationErrors;
  }

  selectedValue = null;
  listOfOptions: { value: string; text: string }[] = [];
  nzFilterOptions = (): boolean => true;
  fullAddress = '';

  constructor(private httpClient: HttpClient) {
    this.data={
      street1: "",
      street2: "",
      city: "",
      state: "",
      zipCode: "",
      countyOrRegion: "",
      psd_code: "",
      mailing: false
  }
  }

  onSearch(value: string): void {
    if (value && value.length > 3) {
      this.httpClient.get(`https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws?Key=${key}&Text=${value}&IsMiddleware=True&Origin=44.182,-84.506&Countries=US,CA&Limit=10&Language=&Bias=&Filters=&GeoFence=`)
        .subscribe((data: any) => {
          const listOfOptions: { value: string; text: string }[] = [];
          data.Items.forEach((result: { Text: string }) => {
            listOfOptions.push({
              value: result.Text,
              text: result.Text
            });
          });

          this.listOfOptions = listOfOptions;
        });
    }
  }

  validateAddress(value: string) {
    const body = {
      "Key": `${key}`,
      "Options": {
        "Enhance": true
      },
      "Addresses": [
        {
          "Address1": `${value}`,
          "Address2": "",
          "Country": "US",
          "Locality": "",
          "PostalCode": ""
        }
      ]
    };
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.httpClient.post(`https://api.addressy.com/Cleansing/International/Batch/v1.00/json4.ws`, body, { headers: headers })
      .subscribe((data: any) => {
        if (data && data[0].Matches) {
          this.extractAddress(data[0].Matches[0]);
        }
      });
  }

  extractAddress(address: { Address1: string; PostalCode: string; AdministrativeArea: string; Locality: string; SubAdministrativeArea: string; Address: string; }) {
    this.addressForm.patchValue({
      'street1': address.Address1,
      'street2': '',
      'zipCode': address.PostalCode,
      'state': address.AdministrativeArea,
      'city': address.Locality,
      'countyOrRegion': address.SubAdministrativeArea
    });
    this.fullAddress = address.Address;
  }
}
