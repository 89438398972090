<ng-container>
    @if(isPageRestrictionLoaded){
    <div class="ppl-container-body">
        <div class="ppl-container-head">
            Global Consumer Search
            <div class="action">
                <!-- <ppl-button label="Create New Consumer Enrollment" icon="fa fa-plus" [customClass]="'primary-button'"
                    [iconPosition]="'left'" [authorizeButton]="[pageId, 'newParticipantEnrollment']"
                    (click)="onNewEnrollment()"></ppl-button> -->
                <!-- <ppl-button label="Create New Vendor" icon="fa fa-plus" [customClass]="'primary-button'"
                    [iconPosition]="'left'" [authorizeButton]="[pageId, 'newProviderReferral']"
                    (click)="onNewReferral()" style="margin: 0 8px"></ppl-button>
                <ppl-button label="Create New Independent Contractor" icon="fa fa-plus" [customClass]="'primary-button'"
                    [iconPosition]="'left'" [authorizeButton]="[pageId, 'newProviderReferral']"
                    (click)="onNewReferral()"></ppl-button> -->
            </div>
        </div>
        <div class="ppl-container">
            <div class="ppl-content-container">
                <div class="table-style row p-0">
                    <ppl-list-table [pageConfig]="programName"
                        [sharedDataKey]="sharedDataKey" (rowDetailEvent)="rowClicked($event)" [loadPage]="lazyLoad"
                        [columnsToBeHidden]="columnsToBeHidden" [actionsToBeHidden]="actionsToBeHidden" 
                        [jsonList]="'list_global_consumer_search'"
                        rowDataAccessibilityKey="id"    
                    >
                    </ppl-list-table>
                </div>
            </div>
        </div>
    </div>
    }

</ng-container>
