@if (flow === 'provider') { @if (mode === 'search') {
<ppl-provider-search-and-select
  [providerType]="'IR'"
  (providerSelected)="handleProviderSelected($event)"
  (cancel)="onCancel()"
  (addingNewProvider)="handleAddNewProvider()"
>
</ppl-provider-search-and-select>
} @if (mode === 'edit' || mode === 'add') {
<!-- <ppl-add-association-form
  [providerId]="providerId() ?? selectedProviderToAdd()?.providerId ?? null"
  (formUpdated)="handleFormUpdate($event)"
  [flowOrigin]="'create'"
  [providerType]="flow"
  [validationErrors]="validationErrors"
  [sectionName]="sectionNameFormType"
  [readonly]="mode === 'add'"
></ppl-add-association-form> -->
<ppl-enrollment-info [source]="'enrollment'"></ppl-enrollment-info>
<ppl-enrollment-contact-details
  [source]="'enrollment'"
></ppl-enrollment-contact-details>
<ppl-enrollment-comm-preferences
  [source]="'enrollment'"
></ppl-enrollment-comm-preferences>
<ppl-enrollment-service-details
  [source]="'enrollment'"
></ppl-enrollment-service-details>
<!-- <div class="hide-form">
  <ppl-enrollment-info
    [updatedFormValue]="associationFormValue"
    [source]="'enrollment'"
  ></ppl-enrollment-info>
  <ppl-enrollment-contact-details
    [updatedFormValue]="associationFormValue"
    [source]="'enrollment'"
  ></ppl-enrollment-contact-details>
  <ppl-enrollment-comm-preferences
    [updatedFormValue]="associationFormValue"
    [source]="'enrollment'"
  ></ppl-enrollment-comm-preferences>
  <ppl-enrollment-service-details
    [updatedFormValue]="associationFormValue"
    [source]="'enrollment'"
  ></ppl-enrollment-service-details>
</div> -->
<!-- <section class="action-buttons-container">
  <ppl-button
    [label]="'Cancel'"
    icon="fa fa-ban"
    [customClass]="'alt-button'"
    [iconPosition]="'left'"
    (click)="onCancel()"
  ></ppl-button>
  @if (mode !== 'add') {
    <ppl-button
    label="Save"
    icon="fa fa-floppy-o"
    [customClass]="'alt-button'"
    [iconPosition]="'left'"
    (click)="confirm.emit()"
  ></ppl-button>
  }
</section> -->
} } @else {
<ppl-enrollment-info [source]="'enrollment'"></ppl-enrollment-info>
<ppl-enrollment-contact-details
  [source]="'enrollment'"
></ppl-enrollment-contact-details>
<ppl-enrollment-comm-preferences
  [source]="'enrollment'"
></ppl-enrollment-comm-preferences>
<ppl-enrollment-service-details
  [source]="'enrollment'"
></ppl-enrollment-service-details>
}
