[
  {
    "menu_label": "Leads",
    "menu_id": "pre_referral",
    "display": "y",
    "landing_component": "participantLeadsList",
    "links": [
      {
          "sub_menu_label": "Consumer Interest",
          "sub_menu_id": "participant_leads",
          "landing_component": "participantLeadsList",
          "display": "y"
      },
      {
          "sub_menu_label": "Personal Assistant Interests",
          "sub_menu_id": "provider_leads",
          "landing_component":"providerLeadsList",
          "display": "y"
      }
  ]
  }
]