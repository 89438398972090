import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, Route, UrlSegment, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from './authorization.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {

    constructor(private router: Router, private authService: AuthorizationService) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const routePath = state.url;
        return this.checkAccess(routePath);
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        const routePath = segments.map(segment => segment.path).join('/');
        return this.checkAccess(routePath);
    }

    private checkAccess(routePath: string): boolean {
        const authorizationConfig = this.authService.getAuthorizationConfig();
        const permission = authorizationConfig.restrictedRoutes.filter(r => r === routePath);
        if (!permission || permission.length == 0) {
            return true; // Access is granted if restriction is not found
        }
        // Redirect to unauthorized or home page if access is denied
        this.router.navigate(['/']);
        return false;
    }
}