import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { MenuConfigResponse, PagesAuthConfigResponse } from '../interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {
  private apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    fetchPageAuthorization(programCode: string, pageNames: string[], pageType: string): Observable<PagesAuthConfigResponse> {
        const payload = {
            "programCode": `${programCode}`,
            "pageName": [`${pageNames}`],
            "pageType":`${pageType}`
        };
        return this.http.post<PagesAuthConfigResponse>(`${this.apiUrl}/api/v1/getPageAccess`, payload);
    };

    fetchMenuAuthorization(programCode: string, pageType: string): Observable<MenuConfigResponse> {
      const payload = {
          "programCode": `${programCode}`,
          "pageType":`${pageType}`
      };
      return this.http.post<MenuConfigResponse>(`${this.apiUrl}/api/v1/getPageAccess`, payload);
  };
}