/* src/app/components/fileTriage/file-triage-list/file-triage-list.component.css */
.table-style {
  width: 100%;
}
#fileTriagePreviewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#fileTriagePreview .actions-container {
  padding-bottom: 1rem;
  display: flex;
}
#fileTriagePreview .actions-container div {
  margin-right: 0;
  margin-left: auto;
  display: flex;
}
/*# sourceMappingURL=file-triage-list.component-MR6PCAFC.css.map */
