import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AddressComponent } from '../../../../shared/components/address/address.component';
import { SectionsService } from '../../../../services/sections.service';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { ListTableComponent } from '../../../../shared/components/list-table/list-table.component';
import { LeadDetailsService } from '../../../../services/lead-details.service';
import { LookupService } from '../../../../services/lookup.service';
import { performValidation } from '../../../../shared/utils/validation.util';
import {
  STATIC_DATA,
  STATIC_JSON_DATA_PATH,
} from '../../../../shared/constants/static-data.constants';
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { CommonModule } from '@angular/common';
import { LoggerService } from '../../../../shared/services/logger.service';
import {
  LeadDetailCommunicationUpdadeModel,
} from '../../../../interfaces/lead-detail.model';
import { TableRowActionsModel } from '../../../../shared/interfaces/list.interface';
import { TableRowActions } from '../../../../shared/interfaces/actions';
import { AuthorizeDirective } from '../../../../shared/authorization/authorize.directive.';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { AuthorizeFieldDirective } from '../../../../shared/authorization/authorizeField.directive';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

@Component({
  selector: 'ppl-lead-detail-eligibility-dates',
  standalone: true,
  imports: [
    NgZorroModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AddressComponent,
    ListTableComponent,
    AuthorizeDirective,
    AuthorizeFieldDirective,
    ButtonComponent,
    NzDatePickerModule,
  ],
  templateUrl: './lead-detail-eligibility-dates.component.html',
  styleUrl: './lead-detail-eligibility-dates.component.css',
})
export class LeadDetailEligibilityDatesComponent
  extends BaseComponent
  implements OnInit, OnChanges {
  mode = 'read';
  filledInfo!: any;
  enrollmentStatus!: FormGroup;
  cols: any;
  gridData: any[] = [];
  communicationType: any;
  copyOfGridData: any = [];
  steps: any;
  entry = 'base';
  target!: LeadDetailCommunicationUpdadeModel;
  associationRole = 0;
  actionButtons: TableRowActionsModel = new TableRowActions();
  enrollmentActive: string = STATIC_DATA.ENROLLMENT_ACTIVE;
  leadStatus = '';
  leadId = '';
  program: any;
  enrollmentType = '';
  selectedViewer: any = {};
  statusEntries: any[] = [];
  disableAddStatus = false;
  disableStatus = false;
  statusReasons: any[] = [];
  showMoveToInPPLReviewButton = false;

  @Input() pageId = '';
  @Input() tabId = '';
  @Input() programCode = '';
  @Input() set sectionData(data: string) {
    this.steps = data;
  }

  @Output() leadUpdated = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private sectionService: SectionsService,
    private leadDetailsService: LeadDetailsService,
    private lookupService: LookupService,
    private cdr: ChangeDetectorRef,
    private logger: LoggerService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.entry = localStorage.getItem('lead_entry')!;
    this.leadId = localStorage.getItem('selected_lead')!;
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    this.communicationType =
      this.lookupService.getLookupValue('Communication_Type');

    this.associationRole = JSON.parse(
      localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!
    );

    this.getLeadStatus();
    this.createFormGroup();
    this.getFromData();
    this.getViewerData();

    this.statusReasons = this.lookupService.getLookupValue(
      'enr_status_reason'
    );
  }

  getLeadStatus() {
    this.leadDetailsService.getLeadStatus().subscribe((status: string) => {
      this.leadStatus = status;
      console.log('Lead Status', status);
      this.showMoveToInPPLReviewButton = false;
      this.disableAddStatus = false;
      if (this.leadStatus === 'New Application' || this.leadStatus === 'In Progress' || this.leadStatus === 'Returned') {
        this.disableAddStatus = true;
      }

      if (this.leadStatus === 'Returned') {
        this.showMoveToInPPLReviewButton = true;
      }

      if (this.leadStatus === STATIC_DATA.IN_PPL_REVIEW || this.leadStatus == 'Paperwork Complete') {
        this.statusEntries = [
          {
            status: "Returned"
          },
          {
            status: "Paperwork Complete"
          },
          {
            status: "Registration Active"
          }
        ];
      }
      else if (this.leadStatus === STATIC_DATA.ENROLLMENT_ACTIVE || this.leadStatus === 'Registration Active with Gap') {
        this.statusEntries = [
          {
            status: "Registration Active"
          },
          {
            status: "On Hold"
          },
          {
            status: "Disenrolled"
          },
          {
            status: "Blocked"
          }
        ];
      }

    });
  }

  moveToPPLReview(): void {
    this.statusEntries = [
      {
        status: "In PPL Review"
      }
    ];

    this.add();
    this.enrollmentStatus.patchValue({
      startDate: new Date(),
      status: 'In PPL Review'
    });
    this.disableStatus = true;
  }

  getViewerData() {
    this.leadDetailsService.getEnrollmentData(this.program?.program_code, this.leadId).subscribe((data: any) => {
      this.gridData = data?.responsedata;
      this.copyAndTransformGridData(this.gridData);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes['sectionData'] && changes['sectionData'].currentValue) {
        this.secData = this.steps['enrollment_status'];
      }
    } catch (e) {
      this.logger.debug(
        'Error in getting section data and updating latest info',
        e
      );
    }
  }

  disabledStartDate = (startValue: Date): boolean => {
    const endDate = this.enrollmentStatus.get('endDate')?.value;
    if (!startValue || !endDate) {
      return false;
    }
    return startValue.getTime() > endDate.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    const startDate = this.enrollmentStatus.get('startDate')?.value;
    if (!endValue || !startDate) {
      return false;
    }
    return endValue.getTime() <= startDate.getTime();
  };

  createFormGroup() {
    this.enrollmentStatus = this.fb.group({
      statusId: [],
      participantId: [],
      startDate: [],
      endDate: [],
      status: [],
      comment: [],
      reason: []
    });
  }

  async getFromData() {
    //table column information
    try {
      if (this.entry) {
        const tempCols = await this.sectionService.getJsonConfigurationData(
          STATIC_JSON_DATA_PATH.LEAD_DETAILS_ENROLLMENT_HEADERS,
          this.entry
        );

        this.cols = tempCols?.enrollmentStatus?.columns;
      }
    } catch (e) {
      this.logger.debug('Error in getting table headers', e);
    }
  }

  cancelEdit() {
    this.mode = 'read';
    this.validationErrors = {};
    this.enrollmentStatus.reset();
    this.enrollmentType = '';
    this.selectedViewer = {};
    this.disableStatus = false;
    setTimeout(() => this.cdr.detectChanges(), 1000);
  }

  action(event: any) {
    console.log('acto', event);
    if (event && event.action === 'edit') {
      this.mode = 'edit';
      if (event?.data) {
        const data = JSON.parse(JSON.stringify(event?.data));
        data.startDate = new Date(data.startDate);
        if (data.endDate) {
          data.endDate = new Date(data.endDate);
        }
        this.enrollmentStatus.patchValue(data);
        this.selectedViewer = data;
      }
    }
  }

  copyAndTransformGridData(gridData: any[]) {
    if (gridData) {
      const copyGridData = JSON.parse(JSON.stringify(gridData));
      copyGridData.forEach((data: any) => {
        if(data.startDate && data.endDate) {
          if (new Date().getTime() < new Date(data.endDate).getTime() 
            &&
           new Date().getTime() > new Date(data.startDate).getTime()) {
          data.actions = {
            edit: true
          }
        }
        }

        if (data.startDate) {
          if (new Date().getTime() < new Date(data.startDate).getTime()) {
            data.actions = {
              edit: true
            }
          }
        }
      });
      this.copyOfGridData = copyGridData;
    }
  }

  add() {
    this.mode = 'add';
    this.enrollmentStatus.reset();
    this.enrollmentType = '';
    this.enrollmentStatus.controls['participantId'].setValue(this.associationRole);
  }

  save() {
    if (this.mode == 'add') {
      const data = { ...this.enrollmentStatus.value };

      data.startDate = this.dateFormatter.formatToString(data.startDate);

      if (data.endDate) {
        data.endDate = this.dateFormatter.formatToString(data.endDate);
      }

      try {
        this.validationErrors = performValidation(data, this.secData, 'Save');
      } catch (e) {
        this.logger.debug('Error in validations', e);
      }

      if (Object.keys(this.validationErrors).length === 0) {
        this.leadDetailsService
          .createEnrollmentStatus(data, this.program?.program_code, this.leadId, true)
          .subscribe(() => {
            this.logger.info('Status information saved successfully');
            this.cancelEdit();
            this.getViewerData();
            this.leadDetailsService.setLeadStatus(data.status);
            this.leadUpdated.emit(true);
            this.disableStatus = false;
          });
      }
    } else if (this.mode == 'edit') {
      this.updateViewer();
    }
  }

  updateViewer() {
    const data = { ...this.enrollmentStatus.value };
    data.startDate = this.dateFormatter.formatToString(data.startDate);
    if (data.endDate) {
      data.endDate = this.dateFormatter.formatToString(data.endDate);
    }
    this.leadDetailsService
      .updateEnrollmentStatus(data, this.program?.program_code, data.participantId)
      .subscribe(() => {
        this.logger.info('Status information updated successfully');
        this.cancelEdit();
        this.getViewerData();
        this.disableStatus = false;
      });
  }
}
