<div class="dropdown-container">
    <button (click)="toggleDropdown()" class="dropdown-toggle"><img src="/assets/images/arrow_forward_ios_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg" class="sort-icon" height="16px"></button>
    <ul *ngIf="isOpen" class="dropdown-menu">
        <li>
            <a href="#">          
                <ppl-button 
                label="Leads" 
                icon="/assets/images/add_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg" 
                [customClass]="'fancy-button'" 
                [iconPosition]="'left'"
                ></ppl-button>
            </a>
        </li>
        <li>
            <a href="#">          
                <ppl-button 
                label="Referral" 
                icon="/assets/images/add_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg" 
                [customClass]="'fancy-button'" 
                [iconPosition]="'left'"
                ></ppl-button>
            </a>
        </li>
        <li>
            <a href="#">          
                <ppl-button 
                label="Registration" 
                icon="/assets/images/add_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg" 
                [customClass]="'fancy-button'" 
                [iconPosition]="'left'"
                ></ppl-button>
            </a>
        </li>
        <li>
            <a href="#">          
                <ppl-button 
                label="Authentications" 
                icon="/assets/images/add_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg" 
                [customClass]="'fancy-button'" 
                [iconPosition]="'left'"
                ></ppl-button>
            </a>
        </li>
        <li>
            <a href="#">          
                <ppl-button 
                label="Vendor Pre-Registration" 
                icon="/assets/images/add_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg" 
                [customClass]="'fancy-button'" 
                [iconPosition]="'left'"
                ></ppl-button>
            </a>
        </li>
        <li>
            <a href="#">          
                <ppl-button 
                label="Dashboard" 
                icon="/assets/images/add_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg" 
                [customClass]="'fancy-button'" 
                [iconPosition]="'left'"
                ></ppl-button>
            </a>
        </li>
    </ul>
  </div>
  