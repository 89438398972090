import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ReferralDataService } from '../../services/referral-data.service';
import { SectionsService } from '../../services/sections.service';
import { AuthorizationUtility } from '../../shared/authorization/auth.utility';
import { AuthorizationService } from '../../shared/authorization/authorization.service';
import { AuthorizeButtonDirective } from '../../shared/authorization/authorizeButton.directive';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { ListTableComponent } from '../../shared/components/list-table/list-table.component';
import { PAGE_CONSTANTS } from '../../shared/constants/page.constants';
import { STATIC_DATA } from '../../shared/constants/static-data.constants';
import { SharedDataService } from '../../shared/services/shared-data-service';
import { ParticipantLeadsListComponent } from '../participant-leads-list/participant-leads-list.component';

@Component({
  selector: 'ppl-provider-leads-list',
  standalone: true,
  imports: [ListTableComponent, NzIconModule, NzButtonModule, RouterModule, AuthorizeButtonDirective, ButtonComponent],
  templateUrl: './provider-leads-list.component.html',
  styleUrl: './provider-leads-list.component.css'
})
export class ProviderLeadsListComponent extends ParticipantLeadsListComponent {
  override sharedDataKey: string = STATIC_DATA.SHARED_PROVIDER_LEADS_DATA;
  override pageId: string = PAGE_CONSTANTS.PROVIDER_LEADS_DATA_GRID_PAGE;

  constructor(
    router: Router,
    sectionService: SectionsService,
    referralDataService: ReferralDataService,
    sharedDataService: SharedDataService,
    authUtility: AuthorizationUtility,
    authService: AuthorizationService
  ) {
    super(router, sectionService, referralDataService, sharedDataService, authUtility, authService);
  }

  override onNewLead(): void {
    this.sectionService.setFlow('providers');
    this.referralDataService.clearReferrralData();
    this.router.navigate(['/referralLogic']);
  }

  override rowClicked(rowDetail: any) {
    console.log(rowDetail);
    
    localStorage.setItem('lead_entry','provider');
    this.sectionService.setFlow('provider');
    localStorage.setItem('selected_lead', rowDetail.lead_id);
    setTimeout(() => {
      this.router.navigate(['/home/providerLeadsList/leadDetails/']);
    }, 200)

    
  }
}
