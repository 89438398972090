/* src/app/components/lead-details/participant-information/lead-detail-identifiers/lead-detail-identifiers.component.css */
.nz-padding-left {
  padding-left: 1rem;
}
.ant-picker {
  padding: 0 8px;
  width: 100%;
}
.ant-picker .ant-picker-input {
  border: 2px solid orange !important;
  border-radius: 10%;
}
.ant-picker .ant-picker-input input {
  border: 2px solid red !important;
  border-radius: 10%;
}
.ppl-mt20 {
  margin-top: 20px;
}
.nz-form .ant-form-item {
  display: flex;
  align-items: center;
}
.nz-form .ant-form-item-control {
  flex: 1;
}
::ng-deep .ant-picker-input > input {
  border: none;
}
::ng-deep nz-input-group > .ant-input {
  height: 25px;
}
.custom-row {
  display: flex;
  justify-content: flex-end;
}
[nz-icon] {
  margin-right: 6px;
  font-size: 1.429rem;
  color: #3c86f5;
}
.ant-radio-group {
  display: block;
  align-items: left !important;
}
.ant-radio-button-wrapper {
  width: 122px !important;
}
.ssn-visible {
  color: inherit;
  background: inherit;
  border: inherit;
  padding: 0;
}
/*# sourceMappingURL=lead-detail-identifiers.component-UN44EUW7.css.map */
