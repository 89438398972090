<div class="ppl-container-body">
    <div class="ppl-container">
        <div class="ppl-content-container"
            style="padding: 8px; max-height: calc(100vh - 140px); box-sizing: border-box;">
            <div class="line1">
                <div class="action">
                    <ppl-button [label]="'Back'" icon="fa fa-chevron-left" (click)="goBack()"
                        [customClass]="'primary-button semi-rounded'" [iconPosition]="'left'"></ppl-button>
                </div>
            </div>
            <!-- <div class="line2">
                {{pageTitle}}
            </div> -->
            <div style="width: 100%">
                <ppl-accordion [title]="'Add Authorization'" [isOpen]="true">
                    <div class="wiz-body">
                        <div class="ppl-mt24">
                            <div class="custom-row">
                                @if(mode==="edit" && !this.isNew){
                                <ppl-button label="Cancel" icon="fa fa-ban" [customClass]="'alt-button'"
                                    [iconPosition]="'left'" (click)="onCancel()"></ppl-button>
                                <ppl-button label="Save" icon="fa fa-floppy-o" [customClass]="'alt-button'"
                                    [iconPosition]="'left'" (click)="onUpdate()"></ppl-button>
                                }
                                @if(mode==="read" && !this.isNew){
                                <ppl-button label="Edit" icon="fa fa-pencil" [customClass]="'ghost-button'"
                                    [iconPosition]="'left'" (click)="onEdit()"></ppl-button>
                                }
                            </div>
                            @if(mode==="edit"){
                                <ppl-auth-forms [formState]="'add'" (authSubmitted)="authSubmitted($event)"></ppl-auth-forms>
                            }
                            @else if (mode==="read") {
                                <div class="row justify-content-center">
                                    <div class="col-2">
<ppl-button label="Back to Authorizations" icon="fa fa-check-circle" [customClass]="'primary-button'"
                                        [iconPosition]="'right'" (click)="goBack()"></ppl-button>
                                    </div>
                                    <div class="col-2">
<ppl-button label="Add More Authorizations" icon="fa fa-check-circle" [customClass]="'primary-button'"
                                        [iconPosition]="'right'" (click)="addMore()"></ppl-button>
                                    </div>
                                    

                                    
                                </div>
                                
                            }
                        </div>
                    </div>
                </ppl-accordion>
            </div>
        </div>
    </div>
</div>