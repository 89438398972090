<ppl-modal [showModal]="showModal" [title]="title" [isOkText]="okText" (confirm)="handleOk()" (cancel)="handleCancel()">
     <form nz-form [formGroup]="notificationForm" nzLayout="vertical">
      <nz-form-item class="ppl-form-item">
        <p>Note that the {{type}} will be sent to the {{notificationType}} specified in Contact Details under the profile.</p>
        <nz-form-control>
          <nz-textarea-count [nzMaxCharacterCount]="100">
            <textarea rows="4" formControlName="comment" nz-input></textarea>
          </nz-textarea-count>
        </nz-form-control>
      </nz-form-item>
    </form>
</ppl-modal>