<!-- @if(communicationGridData.length > 0){
    



      @for (comm of communicationGridData; track $index) {
          <div class="mb-3">
              <nz-row>
                  <nz-form-label class="ppl-form-label font-weight-600" nzSpan="6">Type:&nbsp;</nz-form-label>
                  <nz-form-label nzSpan="18" class="ant-form-item-label-left">{{comm.cmctnTypeLkp}}</nz-form-label>
              </nz-row>
              <nz-row>
                  <nz-form-label class="ppl-form-label font-weight-600" nzSpan="6">Message:&nbsp;</nz-form-label>
                  <nz-form-label nzSpan="18" class="ant-form-item-label-left">{{comm.cmctnText}}</nz-form-label>
              </nz-row>

              <nz-row>
                <nz-form-label class="ppl-form-label font-weight-600" nzSpan="6">Sent to:&nbsp;</nz-form-label>
                <nz-form-label nzSpan="18" class="ant-form-item-label-left">{{comm.cmctnSentTo}}</nz-form-label>
            </nz-row>

            <nz-row>
              <nz-form-label class="ppl-form-label font-weight-600" nzSpan="6">Sent by:&nbsp;</nz-form-label>
              <nz-form-label nzSpan="18" class="ant-form-item-label-left">{{comm.cmctnSentByName}}</nz-form-label>
            </nz-row>
              
              <nz-row>
                  <nz-form-label class="ppl-form-label font-weight-600" nzSpan="6">Date:&nbsp;</nz-form-label>
                  <nz-form-label nzSpan="18" class="ant-form-item-label-left">{{comm.cmnctnCreatedDate | date : 'medium'}}</nz-form-label>
              </nz-row>
              <nz-row>
                <nz-form-label class="ppl-form-label font-weight-600" nzSpan="6">Status:&nbsp;</nz-form-label>
                <nz-form-label class="ant-form-item-label-left">{{comm.cmctnSentStatus === 'SUCCESSFULLY_COMPLETED' ? 'Success' : comm.cmctnSentStatus === 'IN_PROGRESS' ? 'In Progress' : 'Error' }}</nz-form-label>
            </nz-row>
              @if(!$last){
                  <div class="mt-3">
                      <nz-divider></nz-divider>
                  </div>
              }
              
          </div>

      }
  


    }@else {
    <div>
      <nz-form-label>No Data found</nz-form-label>
    </div>
    } -->
    <!-- <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="false" [data]="communicationGridData"
      [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false" >
    </ppl-list-table> -->

    @if(communicationGridData.length > 0){
      <ppl-list-table
      [disableSort]="true"
      [disableSearch]="true"
      [data]="copyOfGridData"
      [disableSelection]="true"
      [cols]="cols" 
      [gridTitle]="false"
      [pagination]="false"
      [loadPage]="true"
    >
    </ppl-list-table>
    }@else {
      <nz-row>
        <p>No Data found</p>
      </nz-row>
    }