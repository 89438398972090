/* src/app/components/leads/new-contact/new-contact.component.css */
.ant-collapse {
  background-color: transparent !important;
  border: none;
}
.sec-halfwidth {
  width: 50%;
}
.btn-hide {
  padding: 4px 8px;
}
::ng-deep nz-collapse-panel {
  margin: 8px 0;
  border: none !important;
}
::ng-deep .ant-collapse-header {
  background-color: #004c4a !important;
  color: white !important;
  border-radius: 8px !important;
}
.ppl-main-fullwidth {
  margin-top: 10px;
}
.custom-icon {
  position: absolute;
  right: 16px;
}
.btn-group {
  display: flex;
  justify-content: right;
}
.info-box {
  color: #000;
  text-align: justify;
  font-family: "outfit";
  font-size: 0.857rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: var(--Information-20, #E5F2F3);
  padding: 8px;
  border-radius: 8px;
  margin-top: 8px;
}
.fa {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  font-size: 1.071rem;
  color: #007C89;
}
.collapsible .content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}
.collapsible .active .content {
  max-height: 100px;
}
.expand-collapse-container {
  box-sizing: border-box;
  padding: 8px;
  background-color: white;
  border-radius: 8px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: 9px;
  background: rgb(255 255 255);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
}
.nz-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: #f0f1f5;
  color: #005f73;
  padding: 16px;
  border-radius: 8px;
  font-weight: bold;
}
.nz-collapse > .ant-collapse-item-active > .ant-collapse-header {
  background-color: #00b4d8;
  color: #ffffff;
  border-radius: 8px 8px 0px 0px !important;
}
.nz-collapse .ant-collapse-arrow {
  float: right;
  color: #005f73;
}
.ant-collapse > .ant-collapse-item {
  border: 2px solid #004c4a !important;
  border-radius: 8px !important;
}
.ant-collapse-content {
  color: rgb(0 0 0 / 85%) !important;
  background-color: #e0e0e0 !important;
  border-top: none !important;
  border-radius: 0px 0px 8px 8px !important;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  background-color: #00b4d8;
  color: #ffffff;
  border-radius: 4px 4px 0px 0px !important;
}
.actions-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 1rem;
}
/*# sourceMappingURL=new-contact.component-42DBZTWP.css.map */
