{
  "sectionId": "payablePARates",
  "header": "Payable - Personal Assistant Rates",
  "available_actions": null,
  "attributes": [
    {
      "attribute_ID": "rate",
      "fieldName": "rate",
      "label": "Enter the Rate",
      "type": "input",
      "mandatory": "Y",
      "validator": null,
      "tooltip": "Please enter Rate",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "code",
      "fieldName": "code",
      "label": "Please select the service code",
      "type": "select",
      "mandatory": "Y",
      "validator": null,
      "tooltip": "Please select service code",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "fromdate",
      "fieldName": "fromdate",
      "label": "Set the Effective Start Date",
      "type": "datepicker",
      "mandatory": "Y",
      "validator": null,
      "tooltip": "Please select from date",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "todate",
      "fieldName": "todate",
      "label": "Set the Effective End Date",
      "type": "datepicker",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select to date",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "region",
      "fieldName": "region",
      "label": "Region",
      "type": "text",
      "mandatory": "Y",
      "validator": null,
      "tooltip": "Please select region",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "county",
      "fieldName": "county",
      "label": "County",
      "type": "text",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select county",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "modifierCode1",
      "fieldName": "modifierCode1",
      "label": "Modifier",
      "type": "select",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select Modifier",
      "isAvailable": "Y"
    }
  ],
  "validations": [
    {
      "whatToCheck": "atLeastXEntered",
      "clientOrServer": "C",
      "attributes": [
        {
          "validatorid": 1,
          "attribute_ID": [
            "county"
          ],
          "functionParams": {
            "ErrMsg": "Please select County"
          }
        },
        {
          "validatorid": 2,
          "attribute_ID": [
            "rate"
          ],
          "functionParams": {
            "ErrMsg": "Please enter Rate"
          }
        },
        {
          "validatorid": 3,
          "attribute_ID": [
            "code"
          ],
          "functionParams": {
            "ErrMsg": "Please select Service Code"
          }
        },
        {
          "validatorid": 4,
          "attribute_ID": [
            "fromDate"
          ],
          "functionParams": {
            "ErrMsg": "Please select Start Date"
          }
        }
      ]
    },
    {
      "whatToCheck": "checkFieldValue",
      "clientOrServer": "C",
      "attributes": []
    },
    {
      "whatToCheck": "conditionalCheck",
      "clientOrServer": "C",
      "attributes": []
    },
    {
      "whatToCheck": "customValidator",
      "clientOrServer": "C",
      "attributes": []
    }
  ],
  "actions": [
    {
      "Name": "Previous",
      "Validate": null
    },
    {
      "Name": "Next",
      "Validate": [
        {
          "checkType": "atLeastXEntered",
          "validatorid": [1, 2, 3, 4]
        },
        {
          "checkType": "checkFieldValue",
          "validatorid": []
        },
        {
          "checkType": "conditionalCheck",
          "validatorid": []
        },
        {
          "checkType": "customValidator",
          "validatorid": []
        }
      ]
    }
  ]
}