@for (dialog of dialogSvc.dialogStack(); track $index) {
    <div class="popup-dialog position-dialog {{dialog.config.styleClass}}" [ngClass]="dialog.config.position">
        <div class="overlay"></div>
        <div class="content" [ngClass]="dialog.config.size">
          <div class="header">
            <div class="title">
                <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
                {{ dialog.config.title }}
            </div>
            @if(dialog.config.showButtons || dialog.config.showClose) {
              <div class="action">
                <ppl-button
                label="" 
                ariaLabel="Close"
                icon="fa fa-close" 
                [customClass]="'rounded'" 
                [iconPosition]="'left'"
                (click)="dialog.templateContext.cancel()">
                ></ppl-button>
            </div>        
            }
          </div>
          <div class="body" [ngClass]="{'fit-body': dialog.config.size === 'x-large'}">
            <div class="body-content">
                {{ dialog.config.message }}
                @if (isTemplateRef(dialog.content)) {
                    <ng-container *ngTemplateOutlet="dialog.content; context: dialog.templateContext"></ng-container>
                }
            </div>
          </div>
          @if(dialog.config.showButtons){
          <div class="action-footer" [ngClass]="{'right-aligned-buttons': dialog.config.rightAlignedButtons}">
            @if(!dialog.config.hideSaveButton) {
              <ppl-button 
              [label]="dialog.config.isOkText ?? ''" 
              [icon]="dialog.config.showButtonIcons ? 'fa fa-check-circle' : undefined" 
              [customClass]="'primary-button large'" 
              [iconPosition]="'left'"
              (click)="dialog.templateContext.confirm()"
              ></ppl-button>
            }
              <ppl-button 
              [label]="dialog.config.hideSaveButton? 'Close' :'Cancel'" 
              [icon]="dialog.config.showButtonIcons ? 'fa fa-ban' : undefined" 
              [customClass]="'ghost-button large'" 
              [iconPosition]="'left'"
              (click)="dialog.templateContext.cancel()"
              ></ppl-button>
          </div>  
        }
        @if(dialog.config.showOkButton){
          <div class="action-footer-button">
            <ppl-button 
            [label]="'Ok'" 
            icon="fa fa-check-circle" 
            [customClass]="'primary-button large'" 
            [iconPosition]="'left'"
            (click)="dialog.templateContext.confirm()"
            ></ppl-button>
          </div>  
        }
        </div>
    </div>
}