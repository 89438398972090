{
  "columns": [
    {
      "field": "participantFirstName",
      "header": "First Name",
      "jsonpath": "participantFirstName",
      "isDisplayedAtHeader": "y",
      "isSearchable": "n"
    },
    {
      "field": "participantLastName",
      "header": "Last Name",
      "jsonpath": "participantLastName",
      "isDisplayedAtHeader": "y",
      "isSearchable": "n"
    },
    {
      "field": "providerTypeLabel",
      "header": "Association Type",
      "jsonpath": "providerTypeLabel",
      "isDisplayedAtHeader": "y",
      "isSearchable": "n"
    },
    {
      "field": "associationStartDate",
      "header": "Start Date",
      "jsonpath": "associationStartDate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "n"
    },
    {
      "field": "associationEndDate",
      "header": "End Date",
      "jsonpath": "associationEndDate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "n"
    }
  ]
}
