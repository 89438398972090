import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { UserDetails } from '../interfaces/user.interface';

export enum AppStateKeys {
  UserDetails = 'user_details',
  SelectedProgram = 'selected_program'
}

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  private setItem(key: AppStateKeys, value: unknown) {
    this.localStorageService.set(key, value);
  }

  setUserDetails(userDetails: UserDetails) {
    this.setItem(AppStateKeys.UserDetails, userDetails);
  }

  getUserDetails(): UserDetails | null {
    return this.localStorageService.get(AppStateKeys.UserDetails);
  }
}
