/* src/app/shared/components/upload/upload.component.css */
.input {
  padding: 2px !important;
  height: 32px !important;
  margin-right: 8px;
  margin-bottom: 8px;
}
::ng-deep .p-fileupload-files {
  display: none !important;
}
::ng-deep .p-overlaypanel {
  width: 30% !important;
  overflow: auto !important;
  height: auto !important;
  margin: auto !important;
  top: 35% !important;
  left: 45% !important;
  border: 1px solid #cdc7c7;
}
li {
  list-style-type: none;
}
textarea {
  width: 100%;
}
.primary-btn {
  width: 100%;
  height: 2.571rem;
  color: #303030;
  background: #c7bcff;
  border-radius: 8px;
  border: 2px solid var(--Backgrounds-Primary, #3d0072);
  display: flex;
  padding: 4px 10px;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  font-size: 0.857rem;
  font-style: normal;
  font-weight: 700;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
}
.primary-btn:hover {
  transition: background-color 0.3s ease;
  cursor: pointer;
  background-color: #303030;
  color: #ffffff;
  filter: invert(0);
}
.primary-btn:hover > .onemore {
  transition: background-color 0.3s ease;
  cursor: pointer;
  color: #ffffff;
}
.file-details {
  font-size: 0.929rem;
  display: grid;
  grid-template-columns: 25% 60% auto;
  gap: 4px;
  margin-bottom: 4px;
  font-size: 0.929rem;
}
.text-hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-container {
  padding: 8px 0 0 0;
}
.filter-action-btn {
  display: flex;
  justify-content: end;
  margin-top: 18px;
}
.filter-action-btn button {
  padding-left: 46px;
  border: 1px solid #cdcdd0;
  border-radius: 5px;
  padding-right: 45px;
  cursor: pointer;
  background-color: white;
}
.primary-bgclr {
  background-color: #3b82f6 !important;
  color: white;
}
.type-input {
  width: 100%;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  padding: 8px;
}
.info-box {
  color: #000;
  text-align: justify;
  font-family: "outfit";
  font-size: 0.857rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: var(--Information-20, #E5F2F3);
  padding: 8px;
  border-radius: 8px;
  margin-top: 8px;
}
.fa {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  font-size: 1.071rem;
  color: #007C89;
}
.onemore {
  color: black;
}
.ppl-p0 {
  padding: 0;
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 800px;
}
.ppl-form-item {
  margin-bottom: 0;
  width: 100%;
}
/*# sourceMappingURL=upload.component-HIYZFXVK.css.map */
