<div class="ppl-container-body">
  <div class="ppl-container">
    <div class="ppl-content-container" style="padding: 8px; max-height: calc(100vh - 140px); box-sizing: border-box;">
      <div class="line1">
        <div class="action">
          <ppl-button [label]="'Back'" icon="fa fa-chevron-left" (click)="goBack()"
            [customClass]="'primary-button semi-rounded'" [iconPosition]="'left'"></ppl-button>
        </div>
      </div>
      <div class="line2">
        {{pageTitle}}
      </div>
      <div style="width: 100%">
        <ppl-accordion [title]="'Basic Information'" [isOpen]="true">
          <div class="wiz-body">
            <div class="ppl-mt24">
              <div class="custom-row">
                @if(mode==="edit" && !this.isNew){
                <ppl-button label="Cancel" icon="fa fa-ban" [customClass]="'alt-button'" [iconPosition]="'left'"
                  (click)="onCancel()"></ppl-button>
                <ppl-button label="Save" icon="fa fa-floppy-o" [customClass]="'alt-button'" [iconPosition]="'left'"
                  (click)="onUpdate()"></ppl-button>
                }
                @if(mode==="read" && !this.isNew){
                <ppl-button label="Edit" icon="fa fa-pencil" [customClass]="'ghost-button'" [iconPosition]="'left'"
                  (click)="onEdit()"></ppl-button>
                }
              </div>
              @if(mode==="edit"){
              <form nz-form nz-row [formGroup]="processCodeForm" class="ppl-p0">
                @if(isAttrAvailable('code')){
                <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                  <nz-row>
                    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="code"
                      class="ppl-form-label">{{getAttributes('code').label}}
                      @if(getAttributes('code').mandatory === 'Y'){
                      <span>*</span>
                      }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                      <div nz-tooltip [nzTooltipTitle]="getAttributes('code')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="code" id="code"
                          placeholder="{{getAttributes('code').placeholder}}"
                          [ngClass]="{'error': validationErrors['code'] }" />
                      </div>
                      @if (validationErrors['code']) {
                      <span class="pl-1 error-message">{{validationErrors['code']}}</span>
                      }
                    </nz-form-control>
                  </nz-row>
                </nz-form-item>
                }
                @if(isAttrAvailable('subcategoryLkp')){
                <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                  <nz-row>
                    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="subcategoryLkp"
                      class="ppl-form-label">{{getAttributes('subcategoryLkp').label}}
                      @if(getAttributes('subcategoryLkp').mandatory === 'Y'){
                      <span>*</span>
                      }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                      <div nz-tooltip [nzTooltipTitle]="getAttributes('subcategoryLkp')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="subcategoryLkp" nzMode='default' class="ppl-select"
                          nzId="subcategoryLkp" nzShowSearch nzAllowClear
                          [ngClass]="{'error': validationErrors['subcategoryLkp'] }" [nzDisabled]="">
                          @for(opt of codeCategories; track $index){
                          <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                          }
                        </nz-select>
                      </div>
                      @if (validationErrors['subcategoryLkp']) {
                      <span class="pl-1 error-message">{{validationErrors['subcategoryLkp']}}</span>
                      }
                    </nz-form-control>
                  </nz-row>
                </nz-form-item>
                }
                @if(isAttrAvailable('description')){
                <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                  <nz-row>
                    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="description"
                      class="ppl-form-label">{{getAttributes('description').label}}
                      @if(getAttributes('description').mandatory === 'Y'){
                      <span>*</span>
                      }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                      <div nz-tooltip [nzTooltipTitle]="getAttributes('description')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <textarea rows="3" nz-input formControlName="description" id="description"
                          [ngClass]="{'error': validationErrors['description'] }"
                          placeholder="{{getAttributes('description').placeholder}}"></textarea>
                      </div>
                      @if (validationErrors['description']) {
                      <span class="pl-1 error-message">{{validationErrors['description']}}</span>
                      }
                    </nz-form-control>
                  </nz-row>
                </nz-form-item>
                }
                @if(isAttrAvailable('internalMessage')){
                <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                  <nz-row>
                    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="internalMessage"
                      class="ppl-form-label">{{getAttributes('internalMessage').label}}
                      @if(getAttributes('internalMessage').mandatory === 'Y'){
                      <span>*</span>
                      }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                      <div nz-tooltip [nzTooltipTitle]="getAttributes('internalMessage')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <textarea rows="3" nz-input formControlName="internalMessage" id="internalMessage"
                          [ngClass]="{'error': validationErrors['internalMessage'] }"
                          placeholder="{{getAttributes('internalMessage').placeholder}}"></textarea>
                      </div>
                      @if (validationErrors['internalMessage']) {
                      <span class="pl-1 error-message">{{validationErrors['internalMessage']}}</span>
                      }
                    </nz-form-control>
                  </nz-row>
                </nz-form-item>
                }
                @if(isAttrAvailable('externalMessage')){
                <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                  <nz-row>
                    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="externalMessage"
                      class="ppl-form-label">{{getAttributes('externalMessage').label}}
                      @if(getAttributes('externalMessage').mandatory === 'Y'){
                      <span>*</span>
                      }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                      <div nz-tooltip [nzTooltipTitle]="getAttributes('externalMessage')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <textarea rows="3" nz-input formControlName="externalMessage" id="externalMessage"
                          [ngClass]="{'error': validationErrors['externalMessage'] }"
                          placeholder="{{getAttributes('externalMessage').placeholder}}"></textarea>
                      </div>
                      @if (validationErrors['externalMessage']) {
                      <span class="pl-1 error-message">{{validationErrors['externalMessage']}}</span>
                      }
                    </nz-form-control>
                  </nz-row>
                </nz-form-item>
                }
                @if(isAttrAvailable('resolutionText')){
                  <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                    <nz-row>
                      <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="resolutionText"
                        class="ppl-form-label">{{getAttributes('resolutionText').label}}
                        @if(getAttributes('resolutionText').mandatory === 'Y'){
                        <span>*</span>
                        }
                      </nz-form-label>
                      <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        <div nz-tooltip [nzTooltipTitle]="getAttributes('resolutionText')?.tooltip || ''"
                          nzTooltipPlacement="topRight">
                          <textarea rows="3" nz-input formControlName="resolutionText" id="resolutionText"
                            [ngClass]="{'error': validationErrors['resolutionText'] }"
                            placeholder="{{getAttributes('resolutionText').placeholder}}"></textarea>
                        </div>
                        @if (validationErrors['resolutionText']) {
                        <span class="pl-1 error-message">{{validationErrors['resolutionText']}}</span>
                        }
                      </nz-form-control>
                    </nz-row>
                  </nz-form-item>
                  }
                @if(isAttrAvailable('effectiveFromDate')){
                <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                  <nz-row>
                    <nz-form-label [nzSm]="8" [nzXs]="24" zFor="effectiveFromDate"
                      class="ppl-form-label">{{getAttributes('effectiveFromDate').label}}
                      @if(getAttributes('effectiveFromDate').mandatory === 'Y'){
                      <span>*</span>
                      }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                      <div nz-tooltip [nzTooltipTitle]="getAttributes('effectiveFromDate')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-date-picker formControlName="effectiveFromDate" id="effectiveFromDate" [nzDisabledDate]="disabledStartDate"
                          [nzFormat]="'MM/dd/yyyy'" [nzPlaceHolder]="getAttributes('effectiveFromDate').placeholder"
                          [nzDisabledDate]="" [ngClass]="{'error': validationErrors['effectiveFromDate'] }"></nz-date-picker>
                      </div>
                      @if (validationErrors['effectiveFromDate']) {
                      <span class="pl-1 error-message">{{validationErrors['effectiveFromDate']}}</span>
                      }
                    </nz-form-control>
                  </nz-row>
                </nz-form-item>
                }
                @if(isAttrAvailable('effectiveEndDate')){
                <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                  <nz-row>
                    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="effectiveEndDate"
                      class="ppl-form-label">{{getAttributes('effectiveEndDate').label}}
                      @if(getAttributes('effectiveEndDate').mandatory === 'Y'){
                      <span>*</span>
                      }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                      <div nz-tooltip [nzTooltipTitle]="getAttributes('effectiveEndDate')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-date-picker formControlName="effectiveEndDate" id="effectiveEndDate" [nzDisabledDate]="disabledEndDate"
                          [nzFormat]="'MM/dd/yyyy'" [nzPlaceHolder]="getAttributes('effectiveEndDate').placeholder"
                          [nzDisabledDate]="" [ngClass]="{'error': validationErrors['dob'] }"></nz-date-picker>
                      </div>
                      @if (validationErrors['effectiveEndDate']) {
                      <span class="pl-1 error-message">{{validationErrors['effectiveEndDate']}}</span>
                      }
                    </nz-form-control>
                  </nz-row>
                </nz-form-item>
                }
                @if(isAttrAvailable('providerTypes')){
                <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                  <nz-row>
                    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="providerTypes"
                      class="ppl-form-label">{{getAttributes('providerTypes').label}}
                      @if(getAttributes('providerTypes').mandatory === 'Y'){
                      <span>*</span>
                      }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                      <div nz-tooltip [nzTooltipTitle]="getAttributes('providerTypes')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="providerTypes" nzMode='multiple' class="ppl-select" nzId="providerTypes"
                          nzShowSearch nzAllowClear [ngClass]="{'error': validationErrors['providerTypes'] }"
                          [nzDisabled]="">
                          @for(opt of providerTypes; track $index){
                          <nz-option [nzLabel]="opt.name" [nzValue]="opt.code"></nz-option>
                          }
                        </nz-select>
                      </div>
                      @if (validationErrors['providerTypes']) {
                      <span class="pl-1 error-message">{{validationErrors['providerTypes']}}</span>
                      }
                    </nz-form-control>
                  </nz-row>
                </nz-form-item>
                }
                @if(isAttrAvailable('serviceCodes')){
                <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                  <nz-row>
                    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="serviceCodes"
                      class="ppl-form-label">{{getAttributes('serviceCodes').label}}
                      @if(getAttributes('serviceCodes').mandatory === 'Y'){
                      <span>*</span>
                      }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                      <div nz-tooltip [nzTooltipTitle]="getAttributes('serviceCodes')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="serviceCodes" nzMode='multiple' class="ppl-select" nzId="serviceCodes"
                          nzShowSearch nzAllowClear [ngClass]="{'error': validationErrors['serviceCodes'] }"
                          [nzDisabled]="">
                          @for(opt of serviceCodes; track $index){
                            <nz-option [nzLabel]="opt.code" [nzValue]="opt.serviceCodeDetailId"></nz-option>
                            }
                        </nz-select>
                      </div>
                      @if (validationErrors['serviceCodes']) {
                      <span class="pl-1 error-message">{{validationErrors['serviceCodes']}}</span>
                      }
                    </nz-form-control>
                  </nz-row>
                </nz-form-item>
                }
                @if(isAttrAvailable('allowOverride')){
                <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                  <nz-row>
                    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="allowOverride"
                      class="ppl-form-label">{{getAttributes('allowOverride').label}}
                      @if(getAttributes('allowOverride').mandatory === 'Y'){
                      <span>*</span>
                      }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                      <div nz-tooltip [nzTooltipTitle]="getAttributes('allowOverride')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="allowOverride" class="ppl-select" nzId="allowOverride" nzShowSearch
                          nzAllowClear [ngClass]="{'error': validationErrors['allowOverride'] }" [nzDisabled]="">
                          @for(opt of radioOptions; track $index){
                          <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                          }
                        </nz-select>
                      </div>
                      @if (validationErrors['allowOverride']) {
                      <span class="pl-1 error-message">{{validationErrors['allowOverride']}}</span>
                      }
                    </nz-form-control>
                  </nz-row>
                </nz-form-item>
                }
                @if(isAttrAvailable('applicableForAuth')){
                <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                  <nz-row>
                    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="applicableForAuth"
                      class="ppl-form-label">{{getAttributes('applicableForAuth').label}}
                      @if(getAttributes('applicableForAuth').mandatory === 'Y'){
                      <span>*</span>
                      }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                      <div nz-tooltip [nzTooltipTitle]="getAttributes('applicableForAuth')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="applicableForAuth" class="ppl-select" nzId="applicableForAuth"
                          nzShowSearch nzAllowClear [ngClass]="{'error': validationErrors['applicableForAuth'] }"
                          [nzDisabled]="">
                          @for(opt of radioOptions; track $index){
                          <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                          }
                        </nz-select>
                      </div>
                      @if (validationErrors['applicableForAuth']) {
                      <span class="pl-1 error-message">{{validationErrors['applicableForAuth']}}</span>
                      }
                    </nz-form-control>
                  </nz-row>
                </nz-form-item>
                }
                @if(isAttrAvailable('applicableForTs')){
                <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                  <nz-row>
                    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="applicableForTs"
                      class="ppl-form-label">{{getAttributes('applicableForTs').label}}
                      @if(getAttributes('applicableForTs').mandatory === 'Y'){
                      <span>*</span>
                      }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                      <div nz-tooltip [nzTooltipTitle]="getAttributes('applicableForTs')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="applicableForTs" class="ppl-select" nzId="applicableForTs"
                          nzShowSearch nzAllowClear [ngClass]="{'error': validationErrors['applicableForTs'] }"
                          [nzDisabled]="">
                          @for(opt of radioOptions; track $index){
                          <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                          }
                        </nz-select>
                      </div>
                      @if (validationErrors['applicableForTs']) {
                      <span class="pl-1 error-message">{{validationErrors['applicableForTs']}}</span>
                      }
                    </nz-form-control>
                  </nz-row>
                </nz-form-item>
                }
                @if(isAttrAvailable('disposition')){
                  <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                    <nz-row>
                      <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="disposition"
                        class="ppl-form-label">{{getAttributes('disposition').label}}
                        @if(getAttributes('disposition').mandatory === 'Y'){
                        <span>*</span>
                        }
                      </nz-form-label>
                      <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        <div nz-tooltip [nzTooltipTitle]="getAttributes('disposition')?.tooltip || ''"
                          nzTooltipPlacement="topRight">
                          <nz-select formControlName="dispositionLkp" class="ppl-select" id="disposition" nzShowSearch
                            nzAllowClear [ngClass]="{'error': validationErrors['disposition'] }" [nzDisabled]="">
                            @for(opt of dispositions; track $index){
                            <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                            }
                          </nz-select>
                        </div>
                        @if (validationErrors['disposition']) {
                        <span class="pl-1 error-message">{{validationErrors['disposition']}}</span>
                        }
                      </nz-form-control>
                    </nz-row>
                  </nz-form-item>
                  }
              </form>
              @if(isNew){
              <div class="custom-row">
                <ppl-button [label]="'Cancel'" [icon]="'fa fa-close'" style="margin-right:4px; font-size:0.857rem;"
                [customClass]="'ghost-button'" [iconPosition]="'right'" (click)="goBack()"></ppl-button>
                <ppl-button label="Submit" icon="fa fa-check-circle" [customClass]="'primary-button'"
                  [iconPosition]="'right'" (click)="onAdd()"></ppl-button>
              </div>
              }
              }
              @else if (mode==="read") {
              <div nz-row>
                @if(isAttrAvailable('code')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("code").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.code }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('subcategoryLkp')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("subcategoryLkp").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.subcategoryLkp }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('description')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("description").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2 read-text">{{ savedData?.description }}</div>
                  </div>
                </div>
                } @if(isAttrAvailable('internalMessage')){

                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("internalMessage").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2 read-text">{{ savedData?.internalMessage }}</div>
                  </div>
                </div>

                } @if(isAttrAvailable('externalMessage')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("externalMessage").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2 read-text">{{ savedData?.externalMessage }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('resolutionText')){
                  <div nz-col nzSpan="12">
                    <div nz-row>
                      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("resolutionText").label }}
                      </div>
                      <div nz-col nzSpan="16" ngClass="pt-2 pb-2 read-text">{{ savedData?.resolutionText }}</div>
                    </div>
                  </div>
                  }
                @if(isAttrAvailable('effectiveFromDate')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("effectiveFromDate").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.effectiveFromDate }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('effectiveEndDate')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("effectiveEndDate").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.effectiveEndDate }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('providerTypes')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("providerTypes").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2 read-text">{{ getProvLookupValue(providerTypes,savedData?.providerTypes) }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('serviceCodes')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("serviceCodes").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2 read-text">{{ getTasLookup(serviceCodes,savedData?.serviceCodes)}}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('allowOverride')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("allowOverride").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.allowOverride }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('applicableForAuth')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("applicableForAuth").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.applicableForAuth }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('applicableForTs')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("applicableForTs").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.applicableForTs }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('disposition')){
                  <div nz-col nzSpan="12">
                    <div nz-row>
                      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("disposition").label }}
                      </div>
                      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ getLookupValue(dispositions,savedData?.dispositionLkp)}}</div>
                    </div>
                  </div>
                  }
              </div>
              }
            </div>
          </div>
        </ppl-accordion>
      </div>
    </div>
  </div>
</div>