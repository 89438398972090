import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { BaseComponent } from '../../../shared/utils/base.component';
import { AddressComponent } from '../../../shared/components/address/address.component';
import { SectionsService } from '../../../services/sections.service';
import { Router } from '@angular/router';
import { ReferralDataService } from '../../../services/referral-data.service';
import { Subscription } from 'rxjs';
import { ContactDetailsData } from '../../../interfaces/referralSections.interface';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { LookupService } from '../../../services/lookup.service';
import { performValidation } from '../../../shared/utils/validation.util';
import { CommonModule } from '@angular/common';
import { RADIO_YES_NO_OPTIONS, STATIC_DATA } from '../../../shared/constants/static-data.constants';

@Component({
  selector: 'ppl-contact-details',
  standalone: true,
  imports: [
    NzFormModule,
    FormsModule,
    ReactiveFormsModule,
    NzGridModule,
    NzCardModule,
    NzSwitchModule,
    NzToolTipModule,
    AddressComponent,
    NzDividerModule,
    NzRadioModule,
    CommonModule
  ],
  templateUrl: './contact-details.component.html',
  styleUrl: './contact-details.component.css',
})
export class ContactDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  private navigationSubscription: Subscription | undefined;
  @Input() mode = "edit";
  @Input() source?:string = "";
  filledInfo: ContactDetailsData = {};
  contactForm!: FormGroup;
  communicationType: any[] = [];
  formHasBeenFilled = false;
  radioOptions = RADIO_YES_NO_OPTIONS;
  showPSD = true;
  isMailing = true;
  flow: string|null="";
  permanentValidationErrors: any;
  mailingValidationErrors: any;
  formReadonly = false;

  constructor(private fb: FormBuilder, private sectionService: SectionsService,
    private router: Router, private referralDataService: ReferralDataService,
    private lookupService: LookupService) {
    super();
  }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      preferredMethod: [''],
      email: [''],
      altPhone: [''],
      mobile: [''],
      residentGt18: [''],
      yearsInState: [''],
      permanentAddress: this.fb.group({
        street1: [''],
        street2: [''],
        countyOrRegion: [''],
        state: [''],
        zipCode: [''],
        city: [''],
        psd_code: [''],
        mailing: [true]
      }),
      mailingAddress: this.fb.group({
        countyOrRegion: [''],
        street2: [''],
        city: [''],
        state: [''],
        zipCode: [''],
        street1: [''],
        psd_code: ['']
      })
    });

    const program = JSON.parse(localStorage.getItem("selected_program")!);
    this.flow = this.sectionService.getFlow();
    const associationFlows = ['employer', 'authRepresentative', 'provider', 'vendor', 'contractor', 'legalGuardian', 'poa' ];
    this.formReadonly = this.referralDataService.getAssociationsEditable();

    if(this.formReadonly){
      this.contactForm.disable()
    } else {
      this.contactForm.enable()
    }

    let steps : any;
    if(this.flow === 'providerEnrollment') {
      steps = this.sectionService.getProviderSectionData(
        program.program_code,
        STATIC_DATA.CONTACT_DETAILS
      );
      this.secData = steps;
    } else {
      steps = this.sectionService.getSectionData(
        program.program_code,
        this.source
      );
      this.secData = steps[STATIC_DATA.CONTACT_DETAILS];
    }

    this.showPSD = program.program_code !== 'NY';
    const validFlows = ["referral", "providers", "participants", "add_independentContractor", "add_vendor", "add_individualProvider"];
    this.isMailing = this.flow !== null && validFlows.includes(this.flow);

    this.navigationSubscription = this.contactForm.valueChanges.subscribe(() => {
      const hasValue = this.hasAnyValueInForm(this.contactForm);
      this.contactForm.markAsDirty();
      console.log(hasValue);
      this.formHasBeenFilled = hasValue;

      if(!associationFlows.includes(this.flow ?? '')) {
        this.referralDataService.setReferralFormData(
          "isContactFormUpdated", this.formHasBeenFilled
        );
      } else {
        this.referralDataService.setAssociationFormData(
          "isContactFormUpdated", this.formHasBeenFilled
        );
      }
      // this.referralDataService.setReferralFormData("isContactFormUpdated", this.formHasBeenFilled);

      console.log( this.contactForm.value);
      if(!associationFlows.includes(this.flow ?? '')) {
        if (this.contactForm.get('permanentAddress')?.value.mailing) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { mailing, ...mailingAddressValue } = this.contactForm.get('permanentAddress')?.value;
          this.contactForm.value.mailingAddress = mailingAddressValue;
          /* console.log(mailingAddressValue, 'mailing address');
          console.log(this.contactForm.value.mailingAddress); */
  
        }
        this.referralDataService.setReferralFormData(
          'contactDetailsData', this.contactForm.value
        );
      } else {
        this.referralDataService.setAssociationFormData(
          'contactDetailsData', this.contactForm.value
        );
      }
      this.referralDataService.setChanged( !hasValue ? false : this.contactForm.dirty)
      if(!associationFlows.includes(this.flow ?? '')) {
        this.referralDataService.setReferralFormData(
         'isContactFormDirty', !hasValue? false : this.contactForm.dirty
        );
      } else {
        this.referralDataService.setAssociationFormData(
          'isContactFormDirty', !hasValue? false : this.contactForm.dirty
        );
      }
    });
  
    this.communicationType = this.getLookup("Communication_Type");
    if(associationFlows.includes(this.flow ?? '')) {
      const filledData = this.referralDataService.getAssociationDetails(
        'contactDetailsData'
      )!;
      if (filledData) {
        this.contactForm.patchValue(filledData);
      }
    }
    if (((this.flow == 'referral' && localStorage.getItem('lead_entry') == 'referral') || this.flow === 'participantEnrollment' || this.flow === 'providerEnrollment' )  && !this.referralDataService.getReferralFormData().contactDetailsData) {
      const filledData =
      this.referralDataService.getReferralDetails('contactDetailsData', this.flow)!;
      if (filledData) {
        this.contactForm.patchValue(filledData);
      }
    }

    if (!associationFlows.includes(this.flow ?? '')) {
      if (this.referralDataService.getReferralFormData().contactDetailsData) {
        this.filledInfo = this.referralDataService.getReferralFormData().contactDetailsData;
        this.contactForm.patchValue(this.filledInfo);
      }
    } else {
      if (this.referralDataService.getAssociationFormData().contactDetailsData) {
        this.filledInfo = this.referralDataService.getAssociationFormData().contactDetailsData;
        this.contactForm.patchValue(this.filledInfo);
      }
    }

    if (associationFlows.includes(this.flow ?? '')) {
      this.referralDataService.associationSaveClicked.subscribe(() => {
        let mailingAddressErrors: any = {};
        const permanentAddressErrors = performValidation(this.contactForm.get('permanentAddress')?.value, this.secData, 'Next');
        if (permanentAddressErrors) {
          delete permanentAddressErrors?.email;
          delete permanentAddressErrors?.mobile;
          this.permanentValidationErrors = permanentAddressErrors
        }
        if (!this.contactForm.get('permanentAddress')?.value.mailing) {
          mailingAddressErrors = performValidation(this.contactForm.get('mailingAddress')?.value, this.secData, 'Next');
          if (mailingAddressErrors) {
            delete mailingAddressErrors?.email;
            delete mailingAddressErrors?.mobile;
            this.mailingValidationErrors = mailingAddressErrors;
          }
        }
  
        const formErrors = performValidation(this.contactForm.value, this.secData, 'Next');
        delete formErrors.city;
        delete formErrors.countyOrRegion;
        delete formErrors.state;
        delete formErrors.street1;
        delete formErrors.zipCode;
        this.validationErrors = { ...permanentAddressErrors, ...mailingAddressErrors, ...formErrors };
        this.referralDataService.setError(this.validationErrors);
        this.referralDataService.setAssociationFormData(
          'isContactFormValid',
          Object.keys(this.validationErrors).length == 0
        );
      });
    }

    this.referralDataService.nextClicked.subscribe((val: any) => {
      if(val === STATIC_DATA.CONTACT_DETAILS){
      let mailingAddressErrors: any = {};
      const permanentAddressErrors = performValidation(this.contactForm.get('permanentAddress')?.value, this.secData, 'Next');
      if (permanentAddressErrors) {
        delete permanentAddressErrors?.email;
        delete permanentAddressErrors?.mobile;
        this.permanentValidationErrors = permanentAddressErrors
      }
      if (!this.contactForm.get('permanentAddress')?.value.mailing) {
        mailingAddressErrors = performValidation(this.contactForm.get('mailingAddress')?.value, this.secData, 'Next');
        if (mailingAddressErrors) {
          delete mailingAddressErrors?.email;
          delete mailingAddressErrors?.mobile;
          this.mailingValidationErrors = mailingAddressErrors;
        }
      }

      const formErrors = performValidation(this.contactForm.value, this.secData, 'Next');
      delete formErrors.city;
      delete formErrors.countyOrRegion;
      delete formErrors.state;
      delete formErrors.street1;
      delete formErrors.zipCode;
      this.validationErrors = { ...permanentAddressErrors, ...mailingAddressErrors, ...formErrors };
      this.referralDataService.setError(this.validationErrors);
    }
    })
  }

  // hasAnyValue(form: FormGroup): boolean {
  //   return Object.values(form.controls).some(control => control.value);
  // }

  getFormGroup(controlName: string): FormGroup {
    return this.contactForm.get(controlName) as FormGroup;
  }

  getNzValue(valueName: string): string | undefined {
    const contact = this.communicationType.find((contact: any) => {
      return contact.lkpValueName === valueName;
    });
    return contact ? contact.lkpValueCode : undefined;
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
