import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { CommonModule } from '@angular/common';
import { AuthorizeFieldViewDirective } from '../../../../shared/authorization/authorizeFieldView.directive';
import { PROVIDER_TYPES, STATIC_DATA } from '../../../../shared/constants/static-data.constants';
import { Program } from '../../../../interfaces/user.interface';

@Component({
  selector: 'ppl-lead-application-details',
  standalone: true,
  imports: [NgZorroModule, CommonModule, AuthorizeFieldViewDirective],
  templateUrl: './lead-application-details.component.html',
  styleUrl: './lead-application-details.component.css'
})
export class LeadApplicationDetailsComponent extends BaseComponent implements OnInit, OnChanges {
 

  @Input() filledInfo!: any;
  @Input() sectionData!: any;
  @Input() pageId = '';
  @Input() tabId = '';
  @Input() entry = '';
  @Input() program !: Program;
  id!: string;
  programCode!: string;
  providerType!: string;

  ngOnInit(): void {
    this.secData = this.sectionData;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.secData = this.sectionData;

    try{

      if(changes['sectionData'] && changes['sectionData'].currentValue){
        this.secData = this.sectionData;
      }

      if (changes['filledInfo']) {
        this.filledInfo = changes['filledInfo'].currentValue;
        /* this.id = (this.entry === 'referral') ? this.filledInfo?.referralId : this.filledInfo?.leadId; */

        this.id = this.getId();
        this.programCode =  this.getProgramCode();
        this.providerType =  PROVIDER_TYPES[this.filledInfo?.providerType];
      }

    }catch(e){
      console.log('Error in getting section data and updating latest info', e);
    }
  }
  

  getProgramCode(): string {

    let programCode = '';
    if(this.entry === STATIC_DATA.PROVIDER) {
      programCode = this.filledInfo?.providerProgramcode
    }else if(this.entry === STATIC_DATA.PARTICIPANT){
      programCode = this.filledInfo?.participantProgramCode
    }else if(this.entry === STATIC_DATA.REFERRAL || this.entry === STATIC_DATA.PARTICIPANT_ENROLLMENT || this.entry === STATIC_DATA.PROVIDER_ENROLLMENT){
      programCode = this.program.program_code
    }else{
      programCode = '-' 
    }

    return programCode;
  }

  getId() : string{
    let id = '';
    if(this.entry === STATIC_DATA.PROVIDER || this.entry === STATIC_DATA.PARTICIPANT) {
      id = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!);
    }else if(this.entry === STATIC_DATA.REFERRAL){
      id = this.filledInfo?.referralId 
    }else if(this.entry === STATIC_DATA.PARTICIPANT_ENROLLMENT){
      id = this.filledInfo?.participantPPLId
    }else{
      id = this.filledInfo?.providerPPLId
    }
    return id
  }

}
