import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  private isJSONValid(value: string): boolean {
    try {
      JSON.parse(value);
      return true;
    } catch (err) {
      return false;
    }
  }

  get<T>(key: string): T | null {
    const item = localStorage.getItem(key);

    if (!item) return null;

    return this.isJSONValid(item)
      ? (JSON.parse(item) as T)
      : (item as T);
  }

  set(key: string, value: unknown): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}
