import { HttpInterceptorFn } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { from, switchMap } from 'rxjs';
import { v7 as uuid } from 'uuid';
import { environment } from '../../environments/environment';

export const authInterceptor: HttpInterceptorFn = (req: any, next: any) => {
  const authService = inject(AuthService);
  const token = localStorage.getItem("access_token");
  const _uuid = uuid();

  if (token && authService.isTokenExpired(token)) {
    return from(authService.refreshToken()).pipe(
      switchMap(() => {
        const newToken = localStorage.getItem("access_token");
        const clonedReq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${newToken}`).set('Content-Type', 'application/json').set('x-request-id', _uuid).set('Ocp-Apim-Subscription-Key', environment.apimSubScriptionKey)
        });
        return next(clonedReq);
      })
    );
  } else {
    if (token) {
      const isMultiPart = req.body instanceof FormData;
      if (!isMultiPart) {

        req = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`).set('Content-Type', 'application/json').set('x-request-id', _uuid).set('Ocp-Apim-Subscription-Key', environment.apimSubScriptionKey)
        });
      } else {

        req = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`).set('x-request-id', _uuid).set('Ocp-Apim-Subscription-Key', environment.apimSubScriptionKey)
        });
      }
    }
    return next(req);
  }
}