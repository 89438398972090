/* src/app/components/lead-details/participant-information/lead-detail-communication-info/lead-detail-communication-info.component.css */
[nz-icon] {
  margin-right: 6px;
  font-size: 1.429rem;
  color: #3c86f5;
}
.time-picker {
  border: 1px solid lightgray;
}
nz-form-control {
  margin-top: 0.5em;
}
.time-picker {
  border: 1px solid lightgray;
}
::ng-deep .ant-picker-input > input {
  border: none;
}
.ppl-radio-group {
  justify-content: start;
}
.ppl-form-label {
  margin-right: 8px;
}
.ant-form-item-label {
  margin-right: 8px;
}
::ng-deep nz-input-group > .ant-input {
  height: 30px;
}
.ant-radio-button-wrapper {
  margin: 0px;
  width: 100%;
}
.ant-input-affix-wrapper {
  margin-bottom: 0;
}
/*# sourceMappingURL=lead-detail-communication-info.component-3V7PAMWG.css.map */
