/* src/app/components/lead-details/provider-search-and-select/provider-search-and-select.component.css */
.form-actions-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.form-actions-container div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.form-actions-container div h6 {
  display: inline-block;
  margin: 0;
  font-weight: normal;
}
#providerSearchResults {
  margin-top: 2rem;
}
.ppl-form-label {
  margin-right: 8px;
}
:is() .ant-picker-input > input {
  border: none;
}
.ant-form-item {
  margin-bottom: 1rem !important;
}
/*# sourceMappingURL=provider-search-and-select.component-TRM6EJ2S.css.map */
