{
  "columns": [
    {
      "header_id": "fromdate",
      "header_label": "From Date",
      "jsonpath": "fromdate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "todate",
      "header_label": "To Date",
      "jsonpath": "todate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "rate_type",
      "header_label": "Rate Type",
      "jsonpath": "rate_type",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "min_rate",
      "header_label": "Min Rate",
      "jsonpath": "min_rate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "max_rate",
      "header_label": "Max Rate",
      "jsonpath": "max_rate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "default_rate",
      "header_label": "Default Rate",
      "jsonpath": "default_rate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    }
  ],
  "buttons": [],
  "apiDetails": "participantProviderRatesList.json",
  "pageName": "participantProviderRatesList",
  "orderByColumn": ["code"],
  "orderBy": ["desc"]
}
