import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/utils/base.component';
import { NgZorroModule } from '../../../shared/modules/ng-zorro/ng-zorro.module';
import { LookupService } from '../../../services/lookup.service';

@Component({
  selector: 'ppl-lead-details-header',
  standalone: true,
  imports: [NgZorroModule],
  templateUrl: './lead-details-header.component.html',
  styleUrl: './lead-details-header.component.css'
})
export class LeadDetailsHeaderComponent extends BaseComponent implements OnInit {
  filledInfo: any;
  applicationStatusList: any;
  applicationStageList: any;

  @Input() set setSecData(data: any){
    this.secData = data;
  }

  @Input() set leadDetails(data: any) {
    this.filledInfo = data;
  }

  constructor(private lookupService : LookupService) {
    super();
  }

  ngOnInit(): void {
    this.applicationStatusList = this.lookupService.getLookupValue('Lead_Application_Status');
    this.applicationStageList = this.lookupService.getLookupValue('Lead_Application_Stage');
  }

  

}
