/* src/app/components/leads/enrollment/enrollment-address/enrollment-address.component.css */
::ng-deep .ant-card-body {
  padding: 0px !important;
}
::ng-deep .ant-form-horizontal .ant-form-item-label {
  margin: 0px !important;
}
.ppl-form-control {
  margin-bottom: 4px !important;
}
.ppl-form-item {
  margin: 4px 8px !important;
}
/*# sourceMappingURL=enrollment-address.component-WV4GIQRO.css.map */
