<ng-container>
    @if(isPageRestrictionLoaded){


        <div class="ppl-container-body">
        <div class="ppl-container-head">
            Personal Assistant Interest
            <div class="action">
                <!-- <div id="dropBtn" class="drop-btn" (click)="toggleTempZ()">
                  <ppl-dropdown-button />
                </div> --> 
                <ppl-button 
                label="Create New Personal Assistant Interest" 
                icon="fa fa-plus" 
                [customClass]="'primary-button'" 
                [iconPosition]="'left'"
                [authorizeButton]="[pageId, 'newProviderLead']"
                (click)="onNewLead()"
                ></ppl-button>
                
                
                <!-- <button class="create-button btn-primary" [authorizeButton]="[pageId, 'newProviderReferral']"
                (click)="onNewReferral()" style="margin-right:8px;">
                <span nz-icon nzType="layout"></span>
                Create New Referral
            </button>
            <button class="create-button btn-primary" [authorizeButton]="[pageId, 'newProviderLead']"
                (click)="onNewLead()">
                <span nz-icon nzType="layout"></span>
                Create New Provider Lead
            </button> -->


            </div>          
        </div>



    <!-- <div class="ref-head-btn">
        <h2>Providers Leads</h2>
        <div class="button-sec">
            <button class="create-button btn-primary" [authorizeButton]="[pageId, 'newProviderReferral']"
                (click)="onNewReferral()" style="margin-right:8px;">
                <span nz-icon nzType="layout"></span>
                Create New Referral
            </button>
            <button class="create-button btn-primary" [authorizeButton]="[pageId, 'newProviderLead']"
                (click)="onNewLead()">
                <span nz-icon nzType="layout"></span>
                Create New Provider Lead
            </button>
        </div>
    </div> -->
    <div class="ppl-container">
        <div class="ppl-content-container">
            <div class="table-style">
    <!-- <div class="ppl-main-fullwidth"> -->
        <ppl-list-table [pageTitle]="'List of all Personal Assistant Interests'" [pageConfig]="programName"
            [sharedDataKey]="sharedDataKey" (rowDetailEvent)="rowClicked($event)" [loadPage]="lazyLoad"
            [columnsToBeHidden]="columnsToBeHidden" [actionsToBeHidden]="actionsToBeHidden"
            [jsonList]="'list_providers_leads'"
            rowDataAccessibilityKey="lead_id"    
        >
        </ppl-list-table>
    <!-- </div> -->
    </div>
    </div>
    </div>
    </div>
    }

</ng-container>