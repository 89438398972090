import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from "../button/button.component";

@Component({
  selector: 'ppl-side-drawer',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './side-drawer.component.html',
  styleUrl: './side-drawer.component.css'
})
export class SideDrawerComponent {
  @Input() isSideDrawerVisible = false;
  @Output() isSideDrawerVisibleChange = new EventEmitter<boolean>();
  @Input() title = '';
  @Input() message = '';
  @Input() width = '600px';
  @Output() close = new EventEmitter<void>();

  closeAlert(): void {
    this.isSideDrawerVisible = false;
    this.isSideDrawerVisibleChange.emit(this.isSideDrawerVisible); // Emit changes when closing the alert
    this.close.emit();
  }
}
