import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AuthorizationUtility } from './auth.utility';

@Directive({
  standalone: true,
  selector: '[authorizeField]',
})
export class AuthorizeFieldDirective implements OnInit{

  // input fields
  private id!: string;
  private section!: string;
  private pageId!: string;
  private tabId!: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private authUtility: AuthorizationUtility,
  ) {
  }

  ngOnInit(): void {
    this.updateViewState();
  }

  @Input() set authorizeField(config: string[]) {
    this.pageId = config[0];
    this.tabId = config[1];
    this.section = config[2];
    this.id = config[3];
  }

  private updateViewState() {
    const element = this.el.nativeElement;

   // const hasReadAccess = this.authUtility.isFieldReadable(this.pageId + '', this.section, this.id);
    const hasEditAccess = this.authUtility.isFieldEditable(this.pageId, this.tabId, this.section, this.id);

    if(!hasEditAccess) {
      if(element.tagName === 'NZ-DATE-PICKER') {
        element.nzDisabled = true;
      } else {
        this.renderer.setAttribute(element, 'readonly', !hasEditAccess + '');
        this.renderer.setAttribute(element, 'disabled', !hasEditAccess + '');
      }

      this.renderer.addClass(element, 'disabled');
    }
  }
}