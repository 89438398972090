<div id="providerSearchForm" class="ppl-mt20">
  <form nz-form [formGroup]="searchQueryFormGroup">
    <div nz-row nzGutter="48">
      <div nz-col nzSpan="8">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="pplId" [nzXs]="24" [nzSm]="6" class="ppl-form-label"
            >PPL ID *</nz-form-label
          >
          <nz-form-control [nzXs]="24" [nzSm]="18" class="ppl-form-control">
            <input nz-input formControlName="pplId" id="pplId" placeholder="Enter PPL ID" />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSpan="8">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="ssn" [nzXs]="24" [nzSm]="6" class="ppl-form-label"
            >SSN *</nz-form-label
          >
          <nz-form-control [nzXs]="24" [nzSm]="18" class="ppl-form-control">
            <input nz-input formControlName="ssn" id="ssn" placeholder="Enter SSN" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="dob" [nzXs]="24" [nzSm]="6" class="ppl-form-label"
            >Date of Birth</nz-form-label
          >
          <nz-form-control [nzXs]="24" [nzSm]="18" class="ppl-form-control">
            <nz-date-picker
              class="ppl-date-picker"
              [nzDropdownClassName]="'ppl-date-picker-dropdown'"
              [nzFormat]="'MM/dd/yyyy'"
              formControlName="dob"
              id="dob"
              type="date"
            ></nz-date-picker>
            <!-- <input nz-input formControlName="dob" id="dob" type="date" /> -->
          </nz-form-control>
        </nz-form-item>
      </div>

    </div>
    <div nz-row nzGutter="48">
      <div nz-col nzSpan="8">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="firstName" [nzXs]="24" [nzSm]="6" class="ppl-form-label"
            >First Name</nz-form-label
          >
          <nz-form-control [nzXs]="24" [nzSm]="18" class="ppl-form-control">
            <input nz-input formControlName="fname" id="firstName" placeholder="Enter First Name" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="lastName" [nzXs]="24" [nzSm]="6" class="ppl-form-label"
            >Last Name</nz-form-label
          >
          <nz-form-control [nzXs]="24" [nzSm]="18" class="ppl-form-control">
            <input nz-input formControlName="lname" id="lastName" placeholder="Enter Last Name" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row nzGutter="48">
      <div class="col-12 pl-6">
        Either PPL ID, SSN is required to perform the search.
      </div>
    </div>
      <div class="d-flex gap-8 justify-content-end">
        <ppl-button
          label="Cancel"
          type="button"
          [customClass]="'ghost-button'"
          (handleClick)="cancel.emit()"
        ></ppl-button>
        <ppl-button
          label="Search"
          type="submit"
          icon="fa fa-check-circle"
          [customClass]="'primary-button'"
          [iconPosition]="'right'"
          (handleClick)="search()"
          [disabled]="searchQueryFormGroup.invalid"
        ></ppl-button>
      </div>
    
  </form>

  <div class="d-flex align-item-center justify-content-center pt-3">
    
      @if (providers() && providers()?.length === 0) {
      <h6>No results found with the provided search criteria</h6>
      <!-- <ppl-button
        label="Add New Provider"
        type="submit"
        icon="fa fa-plus"
        [customClass]="'primary-button'"
        [iconPosition]="'right'"
        (handleClick)="addingNewProvider.emit()"
      ></ppl-button> -->
      }
  </div>
</div>

@if (showResultsList()) {
<section id="providerSearchResults">
  <ppl-list-table
    [disableSort]="true"
    [disableSearch]="true"
    [enableActions]="true"
    [data]="providers() ?? []"
    [disableSelection]="true"
    [cols]="cols"
    [gridTitle]="false"
    [pagination]="true"
    [rowsToDisplay]="10"
    [loadPage]="false"
    (gridAction)="action($event)"
    [actionButtons]="actionButtons"
  >
  </ppl-list-table>
</section>
}
