import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import { DialogModule } from 'primeng/dialog';
@Component({
  selector: 'ppl-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.css'],
  standalone: true,
  imports: [CommonModule, ButtonComponent, DialogModule]
})
export class PopupDialogComponent {
  @Input() isVisible = false;
  @Input() title = '';  // Input for the title
  @Input() message = '';  // Input for the message
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Input() showClose = false;
  @Output() cancel = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();
  @Input() isOkText = 'OK';
  @Input() showButtons = true;
  @Input() showOkButton = false;
  @Input() showButtonIcons = true;
  @Input() rightAlignedButtons = false;
  @Input({ required: true }) size : 'medium' | 'large' | 'x-large' | 'xx-large' = 'medium';
  @Input({ required: true }) position: 'top' | 'center' | 'middle' = 'top';
  @Input() styleClass!: string;
  @Input() hideSaveButton = false;


  

  handleOk(): void {
    this.confirm.emit();
  }

  closeModal(): void {
    this.cancel.emit();
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }
}
