import { ErrorHandler, inject, Injectable } from '@angular/core';
import { catchError, Observable, of, throwError } from 'rxjs';
import { ApiResponse } from '../shared/interfaces/response.interface';
import {
  AcknowledgeFileUploadNotificationResponse,
  AcknowledgeItem,
  FileUploadNotificationData,
} from '../interfaces/file-upload-notification.interface';
import { formatUrl } from '../shared/utils/format-url-util';
import { ENDPOINTS } from '../shared/constants/url.constants';
import { HttpRequestService } from '../shared/services/http-request.service';

@Injectable({
  providedIn: 'root',
})
export class FileUploadNotificationService {
  private httpRequestService = inject(HttpRequestService);
  private errorHandler = inject(ErrorHandler);

  public getFileNotificationList(
    program_code: string,
    isWriteRequest?: boolean
  ): Observable<ApiResponse<FileUploadNotificationData[]>> {
    const url = formatUrl(ENDPOINTS.ENROLLMENT.GET_FILE_UPLOAD_NOTIFICATION, {
      program_code,
    });
    return this.httpRequestService
      .get<ApiResponse<FileUploadNotificationData[]>>(url, isWriteRequest)
      .pipe(
        catchError((err) => {
          return throwError(() => this.errorHandler.handleError(err));
        })
      );
  }

  public acknowledgeFiles(
    files: FileUploadNotificationData[],
    programCode: string
  ): Observable<AcknowledgeFileUploadNotificationResponse> {
    const acknowledgments = this.getAcknowledgePayloadItems(files);
    const payload = {
      programCode,
      acknowledgments
    };

    const url = formatUrl(ENDPOINTS.ENROLLMENT.ACKNOWLEDGE_FILE_UPLOAD_NOTIFICATION, {});

    return this.httpRequestService.post<AcknowledgeFileUploadNotificationResponse>
    (url, payload, true).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }

  private getAcknowledgePayloadItems(
    files: FileUploadNotificationData[]
  ): AcknowledgeItem[] {
    return files.map(file => ({
      fileId: file.fileId,
      userType: file.userType
     }))
  }
}
