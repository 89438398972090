{
  "sectionId": "participantProviderRates",
  "header": "Consumer - Personal Assistant Rates",
  "available_actions": null,
  "attributes": [
    {
      "attribute_ID": "participant_lkp",
      "fieldName": "participant_lkp",
      "label": "Select a Consumer",
      "type": "select",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Select a Consumer",
      "isAvailable": "Y",
      "placeholder": "Consumer"
    },
    {
      "attribute_ID": "provider_lkp",
      "fieldName": "provider_lkp",
      "label": "Choose the Personal Assistant",
      "type": "select",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select the Personal Assistant",
      "isAvailable": "Y",
      "placeholder": "Personal Assistant"
    },
    {
      "attribute_ID": "rate",
      "fieldName": "rate",
      "label": "Enter the Rate",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please enter Rate",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "code",
      "fieldName": "code",
      "label": "Please select the service code",
      "type": "select",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select service code",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "fromdate",
      "fieldName": "fromdate",
      "label": "Set the Effective Start Date",
      "type": "datepicker",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select from date",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "todate",
      "fieldName": "todate",
      "label": "Set the Effective End Date",
      "type": "datepicker",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select to date",
      "isAvailable": "Y"
    }
  ],
  "validations": [
    {
      "whatToCheck": "atLeastXEntered",
      "clientOrServer": "C",
      "attributes": []
    },
    {
      "whatToCheck": "checkFieldValue",
      "clientOrServer": "C",
      "attributes": []
    },
    {
      "whatToCheck": "conditionalCheck",
      "clientOrServer": "C",
      "attributes": []
    },
    {
      "whatToCheck": "customValidator",
      "clientOrServer": "C",
      "attributes": []
    }
  ],
  "actions": [
    {
      "Name": "Previous",
      "Validate": null
    },
    {
      "Name": "Next",
      "Validate": [
        {
          "checkType": "atLeastXEntered",
          "validatorid": []
        },
        {
          "checkType": "checkFieldValue",
          "validatorid": []
        },
        {
          "checkType": "conditionalCheck",
          "validatorid": []
        },
        {
          "checkType": "customValidator",
          "validatorid": []
        }
      ]
    }
  ]
}
