{
    "columns": [
      {
        "header_id": "fileName",
        "header_label": "File Name",
        "jsonpath": "fileName",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "createdDate",
        "header_label": "Upload Date",
        "jsonpath": "createdDate",
        "isDisplayedAtHeader": "y",
        "isSearchable": "n"
      },
      {
        "header_id": "pplId",
        "header_label": "PPL ID",
        "jsonpath": "pplId",
        "isDisplayedAtHeader": "y",
        "isSearchable": "n"
      },
      {
        "header_id": "firstName",
        "header_label": "First Name",
        "jsonpath": "firstName",
        "isDisplayedAtHeader": "y",
        "isSearchable": "n"
      },
      {
        "header_id": "lastName",
        "header_label": "Last Name",
        "jsonpath": "lastName",
        "isDisplayedAtHeader": "y",
        "isSearchable": "n"
      }
    ],
    "buttons": [
      {
        "button_id":"file_upload_notification",
        "button_label":"Acknowledge",
        "buttonAction" : "fileUploadNotificationAcknowledge",
        "className": "primary-btn-color"
      }
    ],
    "apiDetails": "fileUploadNotificationList.json",
    "pageName": "fileUploadNotificationList",
    "orderByColumn": ["fileName"],
    "orderBy": ["desc"]
  }
  