import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgZorroModule } from '../../modules/ng-zorro/ng-zorro.module';
import { AuditModel } from '../../../interfaces/audit.model';

@Component({
  selector: 'ppl-timeline',
  standalone: true,
  imports: [CommonModule, NgZorroModule],
  templateUrl: './timeline.component.html',
  styleUrl: './timeline.component.css'
})
export class TimelineComponent {
  auditData: AuditModel[] = [];

  @Input() set data(data : AuditModel[]){
    this.auditData = data;
  };



}
