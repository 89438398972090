<div class="popup-dialog position-dialog {{styleClass}}" [ngClass]="position" *ngIf="isVisible">
    <div class="overlay"></div>
    <div class="content" [ngClass]="size">
      <div class="header">
        <div class="title">
            <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
            {{ title }}
        </div>
        @if(showButtons || showClose) {
          <div class="action">
            <ppl-button
              ariaLabel="Close"
              label=""
              icon="fa fa-close" 
              [customClass]="'rounded'" 
              [iconPosition]="'left'"
              (click)="closeModal()"
            ></ppl-button>
        </div>        
        }
      </div>
      <div class="body" [ngClass]="{'fit-body': size === 'x-large'}">
        <div class="body-content">
            {{ message }}
            <ng-content></ng-content>
        </div>
      </div>
      @if(showButtons){
      <div class="action-footer" [ngClass]="{'right-aligned-buttons': rightAlignedButtons}">
        @if(!hideSaveButton) {
          <ppl-button 
          [label]="isOkText" 
          [icon]="showButtonIcons ? 'fa fa-check-circle' : undefined" 
          [customClass]="'primary-button large'" 
          [iconPosition]="'left'"
          (click)="handleOk()"
          ></ppl-button>
        }
          <ppl-button 
          [label]="hideSaveButton? 'Close' :'Cancel'" 
          [icon]="showButtonIcons ? 'fa fa-ban' : undefined" 
          [customClass]="'ghost-button large'" 
          [iconPosition]="'left'"
          (click)="closeModal()"
          ></ppl-button>
      </div>  
    }
    @if(showOkButton){
      <div class="action-footer-button">
        <ppl-button 
        [label]="'Ok'" 
        icon="fa fa-check-circle" 
        [customClass]="'primary-button large'" 
        [iconPosition]="'left'"
        (click)="closeModal()"
        ></ppl-button>
      </div>  
    }
    </div>
</div>