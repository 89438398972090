import { Component } from '@angular/core';
import { NgZorroModule } from '../../modules/ng-zorro/ng-zorro.module';

@Component({
  selector: 'ppl-loader',
  standalone: true,
  imports: [NgZorroModule],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.css'
})
export class LoaderComponent {

}
