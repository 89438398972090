/* src/app/components/fileTriage/search-and-select-provider/search-and-select-provider.component.css */
.form-actions-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.form-actions-container div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.form-actions-container div h6 {
  display: inline-block;
  margin: 0;
  font-weight: normal;
}
#providerSearchResults {
  margin-top: 0.25rem;
}
.provider-search-form .ant-form-item {
  margin-bottom: 20px;
}
.select-doc-type {
  margin-top: 1.75rem;
}
.select-doc-type .ant-form-item {
  margin-bottom: 5px;
}
/*# sourceMappingURL=search-and-select-provider.component-45UUY674.css.map */
