<div class="ppl-container-body">
  <div class="ppl-container">
    <div class="ppl-content-container" style="padding: 8px; max-height: calc(100vh - 140px); box-sizing: border-box;">
      <div class="line1">
        <div class="action">
          <ppl-button [label]="'Back'" icon="fa fa-chevron-left" (click)="goBack()"
            [customClass]="'primary-button semi-rounded'" [iconPosition]="'left'"></ppl-button>
        </div>
      </div>
      <div class="line2">
        {{pageTitle}}
      </div>
      <div style="width: 100%">
        <ppl-accordion [title]="'Basic Information'" [isOpen]="true">
          <ppl-add-service-code-basic-details (serviceCodeAdded)="serviceCodeAdded($event)"></ppl-add-service-code-basic-details>
        </ppl-accordion>
        @if(isServiceCodeAdded){
          <ppl-accordion [title]="'Rate Details'" [isOpen]="true">
            <ppl-add-service-code-rate-details></ppl-add-service-code-rate-details>
          </ppl-accordion>
        }
      </div>
    </div>
  </div>
</div>