/* src/app/shared/components/alertbox/alertbox.component.css */
.alert-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1050;
  background: rgba(234, 234, 234, 0.46);
  box-shadow: 0px 0px 104px 0px rgba(0, 0, 0, 0.10) inset, -4px 4px 24px 0px rgba(0, 0, 0, 0.45);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  box-sizing: border-box;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.content {
  display: flex;
  width: 50%;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid var(--Color-1, #FBFBFB);
  background:
    linear-gradient(
      94deg,
      #F6F6F6 -8.92%,
      #E6E6E6 76.53%,
      #F8F8F8 115.1%);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.06), -2px -2px 9px 0px rgba(0, 0, 0, 0.06);
  z-index: 1051;
  box-sizing: border-box;
  margin-top: 60px;
  margin-right: 20px;
  max-width: 450px;
}
.body {
  display: flex;
  height: auto;
  padding: 12px 12px 32px 12px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 3px solid #FFF;
  background:
    linear-gradient(
      343deg,
      #E6E6E6 -18.21%,
      #F3F3F3 44.63%,
      #EAEAEA 104.38%);
  box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.12);
}
.footer {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.action-footer {
  width: 100%;
}
.header {
  display: flex;
  padding: 8px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  border: 3px solid #FFF;
  background:
    linear-gradient(
      343deg,
      #E6E6E6 -18.21%,
      #F3F3F3 44.63%,
      #EAEAEA 104.38%);
  box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  font-size: 1.429rem;
  font-weight: bold;
  gap: 12px;
  width: 100%;
}
.body-content {
  align-self: stretch;
  color: var(--Color-9, #333);
  font-family: Outfit;
  font-size: 1.429rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
/*# sourceMappingURL=alertbox.component-WOW7BIFG.css.map */
