<div class="ppl-mt8">
    <h2>Consumer Checklist</h2>
</div>

@if(checklistData && checklistData.length > 0) {
<div class="ppl-mt16 btn-group d-flex flex-row-reverse" style="display: flex; gap: 8px;">

    <ppl-button label="Expand All" icon="fa fa-expand" [customClass]="'alt-button'" [iconPosition]="'right'"
        (click)="expandAll()"></ppl-button>
    <ppl-button label="Collapse All" icon="fa fa-compress" [customClass]="'alt-button'" [iconPosition]="'right'"
        (click)="hideAll()"></ppl-button>

    @if(isEditable) {
    <ppl-button label="Save Checklist" icon="fa fa-floppy-o" [customClass]="'primary-button'" [iconPosition]="'right'"
        (click)="saveChecklist()"></ppl-button>
    }
</div>
}

<div class="ppl-mt16 flex-col" style="gap: 8px;">
    <ppl-checklist-view [checklistData]="checklistData" (viewPDF)="handleCheckListClick($event)"></ppl-checklist-view>
</div>

<div class="filter-panel">

    <ng-template #dialogContent let-confirm="confirm" let-cancel="cancel">
        @if (pdfConfig && pdfFile) {
        <div>
            <ppl-editpdf (fieldsUpdated)="fieldsUpdated($event)" [readonly]="true" [pdfConfig]="pdfConfig"
                [pdfFile]="pdfFile" [pdfBlob]="pdfBlob" [signatureId]="signatureId"
                [signature]="signature"></ppl-editpdf>
        </div>
        }
    </ng-template>
</div>