import { Component, OnInit } from '@angular/core';
import { AccordionComponent } from "../../../../shared/components/accordion/accordion.component";
import { AuthorizeDirective } from '../../../../shared/authorization/authorize.directive.';
import { STATIC_DATA } from '../../../../shared/constants/static-data.constants';
import { Program } from '../../../../interfaces/user.interface';
import { LeadDetailsService } from '../../../../services/lead-details.service';
import { STATIC_JSON_DATA_PATH } from '../../../../shared/constants/static-data.constants';
import { SectionsService } from '../../../../services/sections.service';
import { EnrollmentSummaryResponseItem, SummaryItem } from '../../../../shared/interfaces/lead-details.service-types';
import {ChartModule} from 'primeng/chart';
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { MEMBER_VIEW_PAGE_IDS } from '../../../../shared/constants/page.constants';

@Component({
  selector: 'ppl-lead-detail-summary',
  standalone: true,
  imports: [AccordionComponent, AuthorizeDirective, ChartModule, NgZorroModule],
  templateUrl: './lead-detail-summary.component.html',
  styleUrl: './lead-detail-summary.component.css'
})
export class LeadDetailSummaryComponent implements OnInit {
  pageId = '';
  tabId = 'Summary';
  entry: string | null = '';
  selectedLead = 0;
  program!: Program;
  staticSummaryItems: SummaryItem[] = [];
  summaryItems: SummaryItem[] = [];
  staticAuthorizationItems : SummaryItem[] = [];
  authorizationItems : SummaryItem[] = [];
  staticApplicationInfoItems: SummaryItem[] = [];
  applicationInfoItems: SummaryItem[] = [];
  chartData: any;
  chartOptions: any;
  totalEarnings !: number;
  services = [
    {
      label : 'Support Broker Services',
      value : 'Support Broker Services'
    }
  ]

  participants = [
    {
      label : 'Nikhil',
      value : 'Nikhil'
    }
  ]

  payperiods = [
    {
      label : 'Nov 2, 2024 - Nov 14, 2024',
      value : 'Nov 2, 2024 - Nov 14, 2024'
    }
  ]

  constructor(
    private leadDetailsService: LeadDetailsService,
    private sectionService: SectionsService
  ) { }


  ngOnInit(): void {
    this.pageId = MEMBER_VIEW_PAGE_IDS[localStorage.getItem(STATIC_DATA.URL_FIRST_SEGMENT)!];
    this.entry = localStorage.getItem('lead_entry');
    this.selectedLead = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!);
    this.program = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_PROGRAM)!);
    this.getStaticSummaryItems();

    this.fetchSummaryItemCountData();


    this.chartData = {
      labels: ['Submitted', 'Approved', 'Processing for Payment', 'Paid'],
      datasets: [
        {
          data: [0, 0, 1628.8, 254.5],
          backgroundColor: ['#b44bff', '#ffbd3e', '#4abaff', '#ff5f47'],
          hoverBackgroundColor: ['#b44bff', '#ffbd3e', '#4abaff', '#ff5f47']
        }
      ]
    };

    // Calculate total earnings
    this.totalEarnings = this.chartData.datasets[0].data.reduce((a: number, b: number) => a + b, 0);

    // Chart options configuration
    this.chartOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: '#000', // Set label color
          font: {
            weight: 'bold',
            size: 14 // Customize font size as needed
          },
          formatter: (value : any, context : any) => `${context.chart.data.labels[context.dataIndex]}: $${value.toFixed(2)}`
        },
        legend: {
          position: 'right',
          labels: {
            font: {
              size: 14
            },
            color: '#666',
            formatter: (value : any, context : any) => `${context.chart.data.labels[context.dataIndex]}: $${value.toFixed(2)}`
          }
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem: any) => {
              const label = this.chartData.labels[tooltipItem.dataIndex];
              const value = this.chartData.datasets[0].data[tooltipItem.dataIndex];
              return `$${value} ${label}`;
            }
          }
        }
      }
    };
    
  }

  fetchSummaryItemCountData() {
    const userType = this.entry === 'participantEnrollment' ? 'participant' : 'provider';
    this.leadDetailsService.getEnrollmentSummary(this.program.program_code, this.selectedLead, userType).subscribe({
      next: (data: any) => {
        const associationItems: EnrollmentSummaryResponseItem[] = data?.responsedata?.associations;
        const authorizationItems: EnrollmentSummaryResponseItem[] = data?.responsedata?.authorizations;

        const applicationInfoItems: EnrollmentSummaryResponseItem[] = data?.responsedata?.applicationInfo;

        let associationValueMap: Record<string, string> = {};

        let authorizationValueMap: Record<string, string> = {};
        
        let applicationValueMap: Record<string, string> = {};

        associationValueMap = associationItems.reduce((acc, cur) => {
          acc[cur.typeCode] = cur.typeValue;
          return acc;
        }, associationValueMap);

        this.summaryItems = this.staticSummaryItems.map(item => {
          return {
            ...item,
            summaryItemValue: associationValueMap[item.summaryItemKey] || 0
          }
        });

        // participant specific properties
        if(authorizationItems){
          authorizationValueMap = authorizationItems.reduce((acc, cur) => {
            acc[cur.typeName] = cur.typeValue;
            return acc;
          }, authorizationValueMap);
          
          this.authorizationItems = this.staticAuthorizationItems.map(item => {
            return {
              ...item,
              summaryItemValue: authorizationValueMap[item.summaryItemDisplayName] || 0
            }
          });
        }
        
        // participant specific properties
        if(applicationInfoItems){
          applicationValueMap = applicationInfoItems.reduce((acc, cur) => {
            acc[cur.typeName] = cur.typeValue;
            return acc;
          }, applicationValueMap);

          this.applicationInfoItems = this.staticApplicationInfoItems.map(item => {
            return {
              ...item,
              summaryItemValue: applicationValueMap[item.summaryItemDisplayName] || '-'
            }
          })
      }

      },
      error: () => {
        this.summaryItems = [...this.staticSummaryItems];
      }
    })
  }

  async getStaticSummaryItems() {  
    const summaryItemsData = await this.sectionService.getJsonConfigurationData(
      STATIC_JSON_DATA_PATH.ENROLLMENT_SUMMARY_ITEMS,
      this.entry
    );
    this.staticSummaryItems = summaryItemsData.summaryItems as SummaryItem[];
    this.staticAuthorizationItems = summaryItemsData.authorizationsItems as SummaryItem[];
    this.staticApplicationInfoItems = summaryItemsData.applicationInfoItems as SummaryItem[];
    
  }
}
