import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgZorroModule } from '../../modules/ng-zorro/ng-zorro.module';

@Component({
  selector: 'ppl-drawer',
  standalone: true,
  imports: [CommonModule, NgZorroModule],
  templateUrl: './drawer.component.html',
  styleUrl: './drawer.component.css'
})
export class DrawerComponent {

  config: any ={
    width : 720,
    title : "Create",
    visible : false,
    footer : false,
  }

  @Input() set visible(data : boolean){
    this.config.visible = data;
  }

  @Input() set title(data : string){
    this.config.title = data;
  }

  @Input() set width(data :  number) {
    this.config.width = data;
  }

  @Input() set footer(data : boolean) {
    this.config.footer = data;
  }

  @Output() onClose = new EventEmitter<boolean>(false);

  close(): void {
    this.config.visible = false;
    this.onClose.emit(false);
  }

}
