import { MENU_BASE_URLS } from "./url.constants"

export const PAGE_CONSTANTS = {
    GLOBAL_PARTICIPANT_DATA_GRID_PAGE: `GLOBAL_PARTICIPANT_GRID`,
    PARTICIPANT_REFERRAL_DATA_GRID_PAGE: `PARTICIPANT_REFERRAL_GRID`,
    PARTICIPANT_ENROLLMENT_DATA_GRID_PAGE: `PARTICIPANT_ENROLLMENT_GRID`,
    PROVIDER_ENROLLMENT_DATA_GRID_PAGE: `PROVIDER_ENROLLMENT_GRID`,
    FILE_TRIAGE_DATA_GRID_PAGE: `FILE_TRIAGE_GRID`,
    FILE_UPLOAD_NOTIFICATION_DATA_GRID_PAGE: `FILE_UPLOAD_NOTIFICATION_GRID`,
    PARTICIPANT_LEADS_DATA_GRID_PAGE: `PARTICIPANT_LEAD_GRID`,
    PROVIDER_LEADS_DATA_GRID_PAGE: `PROVIDER_LEAD_GRID`,

    ENROLLMENT_DATA_GRID_PAGE: `ENROLLMENT_GRID`,
    PROCESS_CODE_GRID_PAGE: `PROCESS_CODE_GRID`,
    SERVICE_CODE_GRID_PAGE: `SERVICE_CODE_GRID`,
    PARTICIPANT_PROVIDER_RATES_GRID_PAGE: `PARTICIPANT_PROVIDER_RATES_GRID`,

    MEMBER_INFORMATION: `MEMBER_INFORMATION`,

    TIMESHEET: 'TIMESHEET'
}


export const MEMBER_VIEW_PAGE_IDS = {
    [MENU_BASE_URLS.PROVIDER_LEADS] : 'PROVIDER_MEMBER_VIEW',
  [MENU_BASE_URLS.PARTICIPANT_LEADS] : 'PARTICIPANT_MEMBER_VIEW',
  [MENU_BASE_URLS.REFERRAL] : "REFERRAL_MEMBER_VIEW",
  [MENU_BASE_URLS.PARTICIPANT_ENROLLMENT] : "ENROLLED_PARTICIPANT_MEMBER_VIEW",
  [MENU_BASE_URLS.PROVIDER_ENROLLMENT] : "ENROLLED_PROVIDER_MEMBER_VIEW",
  [MENU_BASE_URLS.GLOBAL_PARTICIPANT_SEARCH] : "GLOBAL_PARTICIPANT_SEARCH_MEMBER_VIEW",
  [MENU_BASE_URLS.EXTERNAL_MEMBERS] : "EXTERNAL_MEMBER_VIEW",
  [MENU_BASE_URLS.AUTHORIZATIONS] : "AUTHORIZATION_MEMBER_VIEW",
}


export const TABS = {
  TIMESHEET: 'TIMESHEET',
  ProspectiveInformation: 'ProspectiveInformation',
  Associations: 'Associations',
  Authorization: 'Authorization',
  Checklist: 'Checklist',
  Documents: 'Documents',
  Signature: 'Signature',
  Forms: 'Forms',
  AdditionalAttributes: 'AdditionalAttributes',
}