@if(mode==="edit" || mode==="add"){
<div class="custom-row">
  <ppl-button label="Cancel" icon="fa fa-ban" [customClass]="'alt-button'" [iconPosition]="'left'"
    (click)="cancelEdit()"></ppl-button>
  <ppl-button label="Save" icon="fa fa-floppy-o" [customClass]="'alt-button'" [iconPosition]="'left'"
    (click)="save()"></ppl-button>
</div>

<div class="ppl-mt20">
  <form nz-form nz-row [formGroup]="enrollmentStatus" class="ppl-p0">
    @if(isAttrAvailable('status')){
    <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="status" class="ppl-form-label"
          [authorizeField]="[pageId, tabId, 'Demographics', 'status']">{{ getAttributes("status").label }}
          @if(getAttributes('status').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('status').tooltip}}"
          nzTooltipPlacement="topRight">
          <nz-select formControlName="status" class="ppl-select" nzId="status" nzPlaceHolder="Select Registration Status"
            [nzDisabled]="mode === 'edit'" [ngClass]="{'error': validationErrors['status'] }" nzShowSearch nzAllowClear>
            @for(opt of statusEntries; track $index){
            <nz-option [nzLabel]="opt.status" [nzValue]="opt.status"></nz-option>
            }
          </nz-select>
          @if (validationErrors['status']) {
          <span class="pl-1 error-message">{{validationErrors['status']}}</span>
          }
        </nz-form-control>
      </nz-row>
    </nz-form-item>
    }

    <div nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left"></div>


    @if(isAttrAvailable('startDate') && !disableStatus){
    <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">

      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="startDate"
          class="ppl-form-label">{{getAttributes('startDate').label}}
          @if(getAttributes('startDate').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('startDate')?.tooltip || ''" nzTooltipPlacement="topRight">
            <nz-date-picker formControlName="startDate" [id]="'startDate'" style="height: 32px;"
              [nzDisabledDate]="disabledStartDate" [nzFormat]="'MM/dd/yyyy'"
              [nzPlaceHolder]="getAttributes('startDate').placeholder"
              [ngClass]="{'error': validationErrors['startDate'] }"></nz-date-picker>
          </div>
          @if (validationErrors['startDate']) {
          <span class="pl-1 error-message">{{validationErrors['startDate']}}</span>
          }
        </nz-form-control>
      </nz-row>
    </nz-form-item>
    }

    @if(isAttrAvailable('endDate') && !disableStatus){
    <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">

      <nz-row>

        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="endDate" class="ppl-form-label">{{getAttributes('endDate').label}}
          @if(getAttributes('endDate').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('endDate')?.tooltip || ''" nzTooltipPlacement="topRight">
            <nz-date-picker formControlName="endDate" [id]="'endDate'" style="height: 32px;" [nzFormat]="'MM/dd/yyyy'"
              [nzDisabledDate]="disabledEndDate" [nzPlaceHolder]="getAttributes('endDate').placeholder"
              [ngClass]="{'error': validationErrors['endDate'] }"></nz-date-picker>
          </div>
          @if (validationErrors['endDate']) {
          <span class="pl-1 error-message">{{validationErrors['endDate']}}</span>
          }
        </nz-form-control>
      </nz-row>
    </nz-form-item>
    }

    @if(isAttrAvailable('reason')){
      <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item nz-padding-left">
        <nz-row>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="reason" class="ppl-form-label"
            [authorizeField]="[pageId, tabId, 'Demographics', 'reason']">{{ getAttributes("reason").label }}
            @if(getAttributes('reason').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('reason').tooltip}}"
            nzTooltipPlacement="topRight">
            <nz-select formControlName="reason" class="ppl-select" nzId="reason" nzPlaceHolder="Select Reason" [ngClass]="{'error': validationErrors['reason'] }" nzShowSearch nzAllowClear>
              @for(opt of statusReasons; track $index){
              <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
              }
            </nz-select>
            @if (validationErrors['reason']) {
            <span class="pl-1 error-message">{{validationErrors['reason']}}</span>
            }
          </nz-form-control>
        </nz-row>
      </nz-form-item>
      }

    @if(isAttrAvailable('comment')){
    <nz-form-item nz-col nzSpan="12" nzPaddingLeft="20" class="ppl-form-item  nz-padding-left">
      <nz-row>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="comment"
          class="ppl-form-label">{{getAttributes('comment').label}}
          @if(getAttributes('comment').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('comment').tooltip}}"
          nzTooltipPlacement="topRight">
          <textarea rows="4" cols="40" nz-input formControlName="comment" nzId="comment" class="ppl-form-control"
            placeholder="{{getAttributes('comment').placeholder}}"
            [ngClass]="{'error': validationErrors['comment'] }"></textarea>
          @if (validationErrors['comment']) {
          <span class="pl-1 error-message">{{validationErrors['comment']}}</span>
          }
        </nz-form-control>

      </nz-row>

    </nz-form-item>
    }
  </form>
</div>
} @else if(mode==="read"){
@if (!disableAddStatus) {
<div class="custom-row">
  <div class="mr-2">
    <ppl-button label="Add Status" icon="fa fa-plus" [customClass]="'ghost-button'" [iconPosition]="'left'"
      (click)="add()" *authorize="[pageId, tabId, 'EligibilityDates', 'section', 'edit']"></ppl-button>
  </div>
</div>
}

@if (showMoveToInPPLReviewButton) {
  <div class="custom-row">
    <div class="mr-2">
      <ppl-button label="Move to PPL Review" icon="fa fa-arrow-circle-right" [customClass]="'ghost-button'" [iconPosition]="'left'"
        (click)="moveToPPLReview()" *authorize="[pageId, tabId, 'MoveToInPPLReview', 'section', 'edit']"></ppl-button>
    </div>
  </div>
  }

@if(copyOfGridData.length > 0){
<ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true" [data]="copyOfGridData"
  [disableSelection]="true" [cols]="cols" (gridAction)="action($event)" [gridTitle]="false" [pagination]="false"
  [loadPage]="true" [actionButtons]="actionButtons">
</ppl-list-table>
}@else {
<nz-row>
  <p>No Data found</p>
</nz-row>
}



}@else if (mode==="read" && !copyOfGridData) {
<nz-row>
  <p>No Data found</p>
</nz-row>
}
<div class="custom-row" style="height: 0.3rem"></div>