/* src/app/components/leads/contact-details/contact-details.component.css */
.ant-input-affix-wrapper {
  margin-bottom: 0;
}
.address-section {
  width: 100%;
}
.ant-divider-horizontal {
  margin-bottom: 16px;
  margin-top: 0;
}
.address-control {
  margin-bottom: 0px;
}
.ant-form-item-label {
  margin-right: 8px;
}
.ant-radio-button-wrapper {
  margin: 0px;
  width: 100%;
}
.divider {
  border-top: 2px solid #cff1f04d;
}
.notes {
  font-size: 0.929rem;
  margin-top: 4px;
}
.notes p {
  margin: 0;
}
/*# sourceMappingURL=contact-details.component-XCR6G27P.css.map */
