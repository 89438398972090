import { Injectable } from "@angular/core";
import { ChecklistResponse, ChecklistItem, ChecklistGroup } from "../interfaces/checklist.model";

@Injectable({
    providedIn: 'root'
})
export class ChecklistHelperService {

    buildChecklistDataForParticipant(checklists: ChecklistItem[], isForms: boolean) {
        const associations: Record<string, ChecklistItem[]> = {};

        if (checklists && checklists.length > 0) {
            checklists.forEach(checklist => {
                let key = 'Consumer';
                if (!isForms || (isForms && checklist.formMasterId != 0)) {
                    if (checklist.details && checklist.details.length > 0) {
                        if (checklist.details[0].associationId != -1) {
                            checklist.details.forEach(detail => {
                                key += '/' + detail.typeDesc + ' (' + detail.firstName + ' ' + detail.lastName + ' - ' + detail.pplId + ')';
                            });
                        }
                    }

                    if (!associations[key]) {
                        associations[key] = [];
                    }

                    associations[key].push(checklist);
                }
            });

            if (isForms) {
                return this.buildChecklistForForms(associations);
            } else {
                return this.buildChecklistFromAssocaitions(associations);
            }
        }
        return [];
    }

    buildChecklistDataForProvider(checklists: ChecklistItem[], data: any, isForms: boolean, isSelf: boolean) {
        const associations: Record<string, ChecklistItem[]> = {};

        if (checklists && checklists.length > 0) {
            checklists.forEach(checklist => {
                if (!isForms || (isForms && checklist.formMasterId != 0)) {
                    let key = isSelf ? 'Self' : '';
                    if (checklist.details && checklist.details.length > 0) {
                        checklist.details.forEach(detail => {
                            if (!isSelf) {
                                key += (key.length > 0 ? '/' : '') + detail.typeDesc + ' (' + detail.firstName + ' ' + detail.lastName + ' - ' + detail.pplId +  ')';
                            } else {
                                key += isSelf ? '/' + detail.typeDesc : ' ' + detail.typeDesc;
                            }
                        });
                    }

                    if (!isSelf) {
                        checklist.details.push({
                            firstName: '',
                            lastName: '',
                            pplId: data.providerPPLID,
                            type: data.providerType,
                            typeDesc: data.providerTypeLabel,
                            associationId: data.participantProviderAssociationId
                        });
                    }

                    if (!associations[key]) {
                        associations[key] = [];
                    }

                    associations[key].push(checklist);
                }
            });

            if (isForms) {
                return this.buildChecklistForForms(associations);
            } else {
                return this.buildChecklistFromAssocaitions(associations);
            }
        }

        return [];
    }

    buildChecklistFromAssocaitions(associations: Record<string, ChecklistItem[]>) {
        const associationGroups: ChecklistGroup[] = [];
        let groups: ChecklistGroup[] = [];
        let subGroups: ChecklistGroup[] = [];

        Object.entries(associations).forEach(([key, values], index) => {
            const categoryGroup = this.groupByKey(values, 'category');
            groups = [];
            Object.entries(categoryGroup).forEach(([group, categoryItems], groupIndex) => {
                const subCategory = this.groupByKey(categoryItems, 'subCategory');

                subGroups = [];
                let sameAsCategory: ChecklistItem[] = [];
                Object.entries(subCategory).forEach(([subGroup, subCategoryItems], subGroupIndex) => {
                    const items = subCategoryItems as ChecklistItem[];
                    sameAsCategory = sameAsCategory.concat(items.filter(e => e.sameAsCategory));
                    const newSubCategories = items.filter(e => !e.sameAsCategory);
                    if (newSubCategories.length > 0) {
                        const totalItemsCompleted = newSubCategories.filter(e => e.checkListStatus == 'C').length;
                        const sortedSubCategories = newSubCategories.sort((a, b) => a.checklistOrder - b.checklistOrder);
                        sortedSubCategories.forEach(item => {
                            const data = item.data;
                            const labelValue = data.filter(e => e.type == "label");
                            const textValue = data.filter(e => e.type == "text");
                            const fromData = data.filter(e => e.type == "fromDate");
                            const toDate = data.filter(e => e.type == "toDate");

                            if (labelValue.length > 0) {
                                item.readonly = true;
                                item.value = labelValue[0].value
                            } else {
                                item.readonly = false;
                                item.value = textValue.length > 0 ? textValue[0].value : '';
                            }
                            item.fromDate = fromData.length > 0 ? fromData[0].value : '';
                            item.toDate = toDate.length > 0 ? toDate[0].value : '';

                            if (item.fromDate && item.toDate) {
                                item.fromDate = new Date(item.fromDate);
                                item.toDate = new Date(item.toDate);
                                item.dateRange = [item.fromDate, item.toDate];
                            }
                        });
                        subGroups.push({
                            checklistName: subGroup,
                            children: [],
                            items: sortedSubCategories,
                            isActive: false,
                            totalItemsCompleted: totalItemsCompleted,
                            isCompleted: totalItemsCompleted == newSubCategories.length,
                            totalItems: newSubCategories.length
                        });
                    }
                });

                const totalSubGroupsCompleted = subGroups.filter(e => e.isCompleted).length + sameAsCategory.filter(e => e.checkListStatus == 'C').length;
                const sortedCategoryItems = sameAsCategory.sort((a, b) => a.checklistOrder - b.checklistOrder);
                sortedCategoryItems.forEach(item => {
                    const data = item.data;
                    const labelValue = data.filter(e => e.type == "label");
                    const textValue = data.filter(e => e.type == "text");
                    const fromData = data.filter(e => e.type == "fromDate");
                    const toDate = data.filter(e => e.type == "toDate");
                    if (labelValue.length > 0) {
                        item.readonly = true;
                        item.value = labelValue[0].value
                    } else {
                        item.readonly = false;
                        item.value = textValue.length > 0 ? textValue[0].value : '';
                    }
                    item.fromDate = fromData.length > 0 ? fromData[0].value : '';
                    item.toDate = toDate.length > 0 ? toDate[0].value : '';

                    if (item.fieldType == "label") {
                        const valueData = data.filter(e => e.type == "label");
                        item.value = valueData.length > 0 ? valueData[0].value : '';
                    }

                    if (item.fromDate && item.toDate) {
                        item.fromDate = new Date(item.fromDate);
                        item.toDate = new Date(item.toDate);
                        item.dateRange = [item.fromDate, item.toDate];
                    }
                });
                groups.push({
                    checklistName: group,
                    children: subGroups,
                    items: sortedCategoryItems,
                    isActive: false,
                    totalItemsCompleted: totalSubGroupsCompleted,
                    totalItems: subGroups.length + sameAsCategory.length,
                    isCompleted: totalSubGroupsCompleted == (subGroups.length + sameAsCategory.length)
                });
            });

            const totalAssociatedGroupsCompleted = groups.filter(e => e.isCompleted).length;
            associationGroups.push({
                checklistName: key,
                children: groups,
                items: [],
                isActive: false,
                totalItemsCompleted: totalAssociatedGroupsCompleted,
                isCompleted: totalAssociatedGroupsCompleted == groups.length,
                totalItems: groups.length
            });
        });


        this.sortByKey(associationGroups, 'checklistName');
        return associationGroups;
    }

    buildChecklistForForms(associations: Record<string, ChecklistItem[]>) {
        const associationGroups: ChecklistGroup[] = [];

        Object.entries(associations).forEach(([key, values], index) => {
            values.forEach(v => {
                v.status = v.formStatus == 'C' ? 'Completed' : 'Not Completed';
            });
            const totalItemsCompleted = values.filter(e => e.formStatus == 'C').length;
            const sortedItems = values.sort((a, b) => a.checklistOrder - b.checklistOrder);
            associationGroups.push({
                checklistName: key,
                items: sortedItems,
                children: [],
                isActive: false,
                totalItemsCompleted: totalItemsCompleted,
                isCompleted: totalItemsCompleted == values.length,
                totalItems: values.length
            });
        });


        this.sortByKey(associationGroups, 'checklistName');
        return associationGroups;
    }

    buildToDoChecklist(checklists: ChecklistItem[], isParticipantFlow: boolean, isSelf: boolean) {
        const pendingToDoItems: any = [];
        const associations: Record<string, ChecklistItem[]> = {};

        if (checklists && checklists.length > 0) {
            checklists.forEach(checklist => {
                let key = !isParticipantFlow && isSelf ? 'Self' : '';
                if (checklist.details && checklist.details.length > 0) {
                    checklist.details.forEach(detail => {
                        key += key.length > 0 ? '/' : '';
                        if (isParticipantFlow) {
                            key += detail.typeDesc + ' (' + detail.firstName + ' ' + detail.lastName + ' - ' + detail.pplId + ')';
                        } else {
                            key += detail.typeDesc;
                        }
                    });
                }

                if (!associations[key]) {
                    associations[key] = [];
                }

                associations[key].push(checklist);
            });

            Object.entries(associations).forEach(([key, values], index) => {
                const categoryGroup = this.groupByKey(values, 'category');

                Object.entries(categoryGroup).forEach(([subGroup, subCategoryItems]) => {
                    pendingToDoItems.push({ name: subGroup, for: key });
                });
            });
        }

        return pendingToDoItems;
    }

    sortByKey(array: any, key: string) {
        array.sort((a: any, b: any) => {
            const titleA = a[key].toLowerCase();
            const titleB = b[key].toLowerCase();
            if (titleA < titleB) {
                return -1;
            }
            if (titleA > titleB) {
                return 1;
            }

            // names must be equal
            return 0;
        });
    }

    groupByKey(array: any, key: string) {
        return array
            .reduce((hash: any, obj: any) => {
                if (obj[key] === undefined) return hash;
                return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
            }, {})
    }
}