<header class="header-container">
  <button class="header-left" (click)="homeRedirection()">
    <img src="/assets/images/PPLhome_logo.png" alt="PPL First Logo" class="logo" />
    <!-- <div class="cpyname">PPL Home</div> -->
  </button>

  <div class="header-right">
    @if (selectedProgram) {
    <div class="city">
      <img src="/assets/images/location_on_24dp_E8EAED_FILL1_wght400_GRAD0_opsz24.svg" alt="" height="24px"/>
      <div class="cityName">{{selectedProgram}}</div>
    </div>
    }

    <div>
      <!-- <button (click)="isSideDrawerVisible = true">Show Side Drawer</button>
      <ppl-side-drawer 
      [(isSideDrawerVisible)]="isSideDrawerVisible" 
      [message]="'This is a Side Drawer!'" 
      [title]="'Side Drawer'"
      (onClose)="handleSideDrawerClose()"></ppl-side-drawer> -->

      <!-- <button (click)="isToasterVisible = true">Show Toaster</button>
      <ppl-toaster-backup 
      [(isToasterVisible)]="isToasterVisible" 
      [message]="'This is a toaster!'" 
      [title]="'Success / Fail'"
      [success]="true"
      (onClose)="handleToasterClose()"></ppl-toaster-backup>

      <button (click)="isAlertVisible = true">Show Alert</button>
      <ppl-alertbox 
      [(isAlertVisible)]="isAlertVisible" 
      [message]="'This is an important alert!'" 
      [title]="'Success / Fail'"
      [success]="false"
      (onClose)="handleClose()"></ppl-alertbox> -->

      <!-- <button (click)="isVisible = true">Show Dialog</button>
      <ppl-popup-dialog 
      [(isVisible)]="isVisible" 
      [title]="'Example Title'" 
      [message]="'This is an example message inside the dialog.'"
      (onClose)="isVisible = false">
      <div 
        style="align-self: stretch; 
        color: var(--Color-9, #333); 
        font-family: Outfit;
        font-size: 1.429rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;"
      >
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      </div>
      </ppl-popup-dialog> -->
      @if(selectedProgram) {
        <ppl-notifications [userType]="userDetails?.usertype_lkp" [program]="selProgramCode"></ppl-notifications>
      }
      @if(menu.length && !disableMenu){
      <ppl-menu-drop-button [menuItems]="menuItemstoDisplay" label="Menu"
        icon="/assets/images/add_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg" [customClass]="'menu-drop-button'"
        [iconPosition]="'right'"></ppl-menu-drop-button>
      }

    </div>
    <button class="profileClick" (click)="toggleDropdown()" aria-label="Profile">
      <img src="/assets/images/account_circle_24dp_E8EAED_FILL1_wght400_GRAD0_opsz24.svg" alt="" height="24px" />
      <img src="/assets/images/arrow_drop_down_24dp_E8EAED_FILL1_wght400_GRAD0_opsz24.svg" alt="" height="24px" />
      <div *ngIf="dropdownOpen" class="dropdown-menu">
        <div class="profile">
          <!-- <div>
            <img src="/assets/images/PPL-Logo-white.png" alt="PPL First Logo" class="profilelogo" />
          </div> -->
          <div class="profileNameDes">
            <div class="profileName">{{userDetails?.last_name ? userDetails?.last_name+',': ''}}
              {{userDetails?.first_name}}</div>
              @if(!isInternalApp){
                <div class="profileDes">{{userDetails?.usertype_lkp}}</div>
              }@else {
                <div class="profileDes">PPL Staff</div>
              }
            
          </div>
        </div>
        <div class="line"></div>
        <div class="action">
          <!-- <ppl-button
            label="Manage Profile"
            icon="/assets/images/settings_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg"
            (click)="manageProfile()"
          ></ppl-button> -->
          <ppl-button label="Logout" icon="/assets/images/power_settings_new_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg"
            (click)="logout()"></ppl-button>
        </div>
      </div>
    </button>
  </div>
</header>