import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { ListTableComponent } from '../../../../shared/components/list-table/list-table.component';
import { AccordionComponent } from '../../../../shared/components/accordion/accordion.component';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';

@Component({
  selector: 'ppl-provider-associations',
  standalone: true,
  imports: [ListTableComponent, 
    CommonModule, 
    NgZorroModule, 
    ReactiveFormsModule, 
    AccordionComponent,
    ListTableComponent,
    ButtonComponent],
  templateUrl: './provider-associations.component.html',
  styleUrl: './provider-associations.component.css'
})
export class ProviderAssociationsComponent extends BaseComponent implements OnChanges {

  @Input() gridData: any;
  @Input() gridCols: any;
  associationsData: any = [];



  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes['gridData'] && changes['gridData'].currentValue) {
        this.associationsData = changes['gridData'].currentValue;
      }
    } catch (e) {
      console.log('Error in getting section data and updating latest info', e);
    }
  }
    
}