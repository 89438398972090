/* src/app/components/lead-details/to-do-list/to-do-list/to-do-list.component.css */
h1 {
  text-align: center;
  margin: 20px 0;
  color: #333;
}
.todo-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.todo-item {
  background-color: #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  font-size: 1.143rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s ease;
}
.todo-item:hover {
  background-color: #c8e1e0;
}
.todo-item-title {
  color: #01796F;
}
.todo-status {
  color: #666;
  font-size: 1rem;
  background-color: white;
  padding: 8px 16px;
  border-radius: 100px;
}
/*# sourceMappingURL=to-do-list.component-N56YF43Z.css.map */
